import {
  CREATE_SEARCH_ADDRESS,
  DELETE_SEARCH_ADDRESS,
  GET_COUNT_HISTORY,
  GET_SEARCH_ADDRESS
} from "../graphql"

export const fetchSearchHistory = async (client, skip: number, take: number | null) => {
  try {
    const { data } = await client.query({
      query: GET_SEARCH_ADDRESS,
      fetchPolicy: "network-only",
      variables: {
        skip,
        take
      }
    })
    return data
  } catch (err) {
    console.log("Error", err)
  }
}

export const saveSearchHistory = async (client, data) => {
  try {
    return await client.mutate({
      mutation: CREATE_SEARCH_ADDRESS,
      variables: {
        WhereInput: data
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const deleteSearchHistory = async (client, id: string) => {
  try {
    return await client.mutate({
      mutation: DELETE_SEARCH_ADDRESS,
      variables: {
        WhereInput: { id }
      }
    })
  } catch (error) {
    console.log("Error", error)
  }
}

export const fetchTotalSearchHistory = async client => {
  return await client.query({
    query: GET_COUNT_HISTORY
  })
}
