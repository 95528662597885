import gql from "graphql-tag"

export const DELETE_BUILDING = gql`
  mutation deleteBuilding($id: String!) {
    deleteBuilding(id: $id) {
      id
    }
  }
`

export const CREATE_BUILDING = gql`
  mutation createListing($data: ListingCreateInput!) {
    createListing(data: $data) {
      id
      title
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_UNIT = gql`
  mutation updateUnit($where: UnitWhereUniqueInput!, $data: UnitUpdateInput!) {
    updateUnit(where: $where, data: $data) {
      id
    }
  }
`

export const UPDATE_PROPERTY = gql`
  mutation activeListing($where: where!, $data: ListingUpdateInput!) {
    activeListing(where: $where, data: $data) {
      id
    }
  }
`
export const UPDATE_LISTING = gql`
  mutation deactivateSearchListing($where: where!, $data: ListingUpdateInput!) {
    deactivateSearchListing(where: $where, data: $data) {
      id
    }
  }
`

export const PREMIMUM_LISTING = gql`
  mutation premimumListing($id: String!, $premiumName: String,$isPremium: Boolean) {
    premimumListing(id: $id, premiumName: $premiumName,isPremium:$isPremium) {
      id
    }
  }
`
export const PREMIMUM_NOTIFICATION = gql`
  mutation premiumNotificationToAdmin($where: PremiumNotificationWhereInput!) {
    premiumNotificationToAdmin(where: $where) {
      id
    }
  }
`

export const PREMIMUM_NOTIFICATION_LANDLORD = gql`
  mutation premiumNotificationToAdminFromLandlord($where: PremiumNotificationWhereInput!) {
    premiumNotificationToAdminFromLandlord(where: $where) {
      id
    }
  }
`

export const LISTING_ENQUIRY = gql`
  mutation listingEnquiry($where: listingEnquiryArgs!) {
    listingEnquiry(where: $where) {
      id
    }
  }
`

// premiumNotificationToAdmin
export const UPDATE_BUILDING = gql`
  mutation updateBuilding($where: ListingWhereUniqueInput!, $data: ListingUpdateInput!) {
    updateBuilding(where: $where, data: $data) {
      id
      title
      description
      buildingConstructionYear
      totalUnitsInBuilding
      storiesInBuilding
      developmentCompany
      location {
        id
        lat
        lng
        tags {
          id
          students
          closeToTransit
          purposeBuiltRentals
          nightlife
          parksAndWater
          luxuryRentals
          shortTermRentals
          professionallyManaged
          onlinePayments
          onlineApplications
          furnishedRentals
          petFriendly
        }
      }
      utilities {
        id
        electricity
        heating
        airConditioning
        water
        garbageRemoval
        cableTv
        internet
        maintenanceFees
        gas
      }
      pictures {
        id
        filename
        url
      }
      amenities {
        id
        onSiteLaundry
        yard
        concierge
        elevator
        dryCleaning
        gym
        swimmingPool
        storage
        guestSuites
        beanfieldWifi
        publicWifi
        bbqPermitted
        visitorsParking
        partyRoom
        bikeStorage
        wheelchairAccess
        rooftopDeck
        hotTub
        sauna
        gameRoom
        theatre
        businessCenter
        communalGarden
        petSpa
        mediaRoom
        basketballCourt
        yogaRoom
        tennisCourt
        rooftopTerrace
        coworkingSpace
        conferenceRoom
        patio
        lounge
      }
      pets
    }
  }
`

export const UPDATE_PREMIUM = gql`
  mutation updatePremium($where: PremiumWhereInput!, $data: PremiumWhereData!) {
    updatePremium(where: $where, data: $data) {
      value
    }
  }
`

export const DELETE_PICTURE = gql`
  mutation deletePicture($where: PictureWhereUniqueInput!) {
    deletePicture(where: $where) {
      id
      value
    }
  }
`
