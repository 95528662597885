import {
  USERS,
  UPDATE_USERS,
  CREATE_USER,
  USER,
  USER_FOR_TENANT,
  SEARCH_USER,
  DELETE_USER,
  PERMISSION,
  CREATE_PERMISSION,
  SUB_ADMIN_PERMISSION,
  UPDATE_USER_TYPE
} from "src/services/graphql"
import { UPDATE_USER_TYPE_LIST_WITH_US } from "../graphql/users/mutation"

export const fetchUsers = async (client, skip: number, isDashboard: boolean = true) => {
  try {
    return await client.query({
      query: USERS,
      fetchPolicy: "network-only",
      variables: {
        data: {
          skip,
          isDashboard
        }
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const createDashboardUser = async (client, data) => {
  try {
    return await client.mutate({
      mutation: CREATE_USER,
      variables: {
        data: {
          ...data
        }
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const createPermission = async (client, data, userId) => {
  try {
    return await client.mutate({
      mutation: CREATE_PERMISSION,
      variables: {
        data: {
          userId: userId,
          permissionData: {
            ...data
          }
        }
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const updateDashboardUser = async (client, id, data) => {
  try {
    const { email, __typename, ...rest } = data
    return await client.mutate({
      mutation: UPDATE_USERS,
      variables: {
        data: {
          id,
          ...rest
        }
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}
export const updateUserType = async (client, data) => {
  try {
    const { __typename, ...rest } = data
    return await client.mutate({
      mutation: UPDATE_USER_TYPE,
      variables: {
        data: {
          ...rest
        }
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const updateUserTypeListWithUs = async (client, data) => {
  try {
    const { __typename, ...rest } = data
    return await client.mutate({
      mutation: UPDATE_USER_TYPE_LIST_WITH_US,
      variables: {
        data: {
          ...rest
        }
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const deleteUser = async (client, id) => {
  try {
    return await client.mutate({
      mutation: DELETE_USER,
      variables: {
        id
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const fetchUser = async (client, id) => {
  try {
    return await client.query({
      query: USER,
      fetchPolicy: "network-only",
      variables: {
        id
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const fetchSubAdminPermission = async (client, id) => {
  try {
    return await client.query({
      query: SUB_ADMIN_PERMISSION,
      fetchPolicy: "network-only",
      variables: {
        data: {
          id
        }
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const fetchUserPermission = async (client, id) => {
  try {
    return await client.query({
      query: PERMISSION,
      fetchPolicy: "network-only",
      variables: {
        id
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const fetchUserForTenant = async (client, id) => {
  try {
    return await client.query({
      query: USER_FOR_TENANT,
      fetchPolicy: "network-only",
      variables: {
        id: id
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const searchUser = async (client, search, skip, isDashboard: boolean = true) => {
  try {
    return await client.query({
      query: SEARCH_USER,
      variables: {
        data: {
          search,
          skip,
          take: 10,
          isDashboard
        }
      }
    })
  } catch (err) {
    console.log("Erorr", err)
  }
}
