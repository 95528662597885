import gql from "graphql-tag"

export const USER = gql`
  query user($id: String!) {
    user(id: $id) {
      email
      firstName
      lastName
      userType
      role
      lu_from
      rental_properties
      rental_properties_permonth
      city
      phone
      householdIncome
      searchLog{
        id
       prevLog
       currentLog
       createdAt
      }
      searchProperty{
        id
       location{
        id
        address
       }
       createdAt
      }
    }
  }
`
export const USERS = gql`
  query users($data: Data!) {
    userCount(data: $data) {
      count
    }
    users(data: $data) {
      id
      firstName
      lastName
      createdAt
      phone
      email
      verified
      userType
      listing {
        id
      }
    }
  }
`
export const PERMISSION = gql`
  query permission($data: Data!) {
    permission(data: $data) {
      id
      listings
      users
      plans
      features
      requestedListings
      boostListings
      inbox
      blogs
      articles
      helpCenter
      permissionType {
        id
        create
        read
        update
        delete
      }
    }
  }
`

export const USER_FOR_TENANT = gql`
  query user($id: String!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      userType
      phone
      credit_score
      flatmates
      budget
      min_budget
      moveIn
      notify
      occupation
      householdIncome
      searchProperty{
        id
       location{
        id
        address
       }
      }
      bio
      view_property
      zip_code
      pet {
        id
        cat
        dog
        other
      }
      pre_viewing_city {
        id
        city {
          id
          name
        }
      }
      furnish {
        id
        furnished
        unFurnished
      }
      bedroom {
        id
        one
        two
        three
        fourplus
        room
        studio
      }
      laundaryType {
        id
        inUnit
        inBuilding
        none
      }
    }
  }
`

export const SEARCH_USER = gql`
  query searchUser($data: Data!) {
    userCount(data: $data) {
      count
    }
    searchUser(data: $data) {
      id
      firstName
      lastName
      phone
      createdAt
      email
      verified
      userType
      listing {
        id
      }
    }
  }
`

export const SUB_ADMIN_PERMISSION = gql`
  query subAdminPermission($data: PermissionWhereInput!) {
    subAdminPermission(data: $data) {
      id
      permissions {
        id
        permissions {
          name
        }
        permissiontypes {
          name
        }
      }
    }
  }
`
