import {
  GET_ALL_LISTING,
  GET_ALL_USER,
  GET_UNREAD_MESSAGE,
  GUEST_USER_TOTAL,
  PVF_ANALYTICS,
  GET_ALL_LANDLORD,
  GET_ALL_TENANT,
  RENTED_BY_LU,
  RENTED_BY_TOLOBI,
  GET_LISTING_STATUS
} from "src/services/graphql"

export const fetchAnalyticCounts = async client => {
  try {
    const resp = await Promise.all([
      client.query({
        query: GET_ALL_LISTING
      }),
      client.query({
        query: GET_ALL_USER,
        variables: {
          data: {
            isDashboard: true
          }
        }
      }),
      client.query({
        query: GET_UNREAD_MESSAGE
      }),
      client.query({
        query: GET_ALL_LANDLORD
      }),
      client.query({
        query: GET_ALL_TENANT
      }),
      client.query({
        query: GUEST_USER_TOTAL
      }),
      client.query({
        query: PVF_ANALYTICS
      }),
      client.query({
        query: RENTED_BY_LU
      }),
      client.query({
        query: RENTED_BY_TOLOBI
      })
    ])
    return resp
  } catch (err) {
    console.log("error", err)
    return err
  }
}

export const getCreateListingStatus = async client => {
  try {
    const resp = client.query({
      query: GET_LISTING_STATUS
    })
    return resp
  } catch (err) {
    console.log("error", err)
    return err
  }
}
