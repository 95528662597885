import gql from "graphql-tag"

export const CREATE_HELP_CENTER = gql`
  mutation createHelpCenter($WhereInput: HelpWhereInput!) {
    createHelpCenter(WhereInput: $WhereInput) {
      id
    }
  }
`

export const UPDATE_HELP_CENTER = gql`
  mutation updateHelpCenter($WhereInput: HelpWhereInput!) {
    updateHelpCenter(WhereInput: $WhereInput) {
      id
    }
  }
`

export const DELETE_HELP_CENTER = gql`
  mutation deleteHelpCenter($WhereInput: HelpWhereInput!) {
    deleteHelpCenter(WhereInput: $WhereInput) {
      id
    }
  }
`
