import styled from "styled-components"
import { width, space, color } from "styled-system"
import { Colors } from "../../../theme"
import theme from "../../../theme"
import Box from "../../../components/box"

interface Props {
  format?: string
  maxWidth?: number
  padding?: string
  height?: number
}

export const FormContainerField = styled.div`
  min-height: 45vh;
  margin: 2rem 0.5rem;
  background: #fff;
  box-shadow: 1px 2px 7px 1px #ccc;
  border-radius: 10px;
  padding: 1rem 1rem 2rem 1rem;
`

export const FormPersonalInfoField = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`

export const ProfileAvatar = styled.div`
  margin-top: 3.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  @media (max-width: 900px) {
    margin-top: 1.8rem;
  }
`

export const InputComponent = styled.input<Props>`
  flex-shrink: 0;
  max-width: ${props => (props.maxWidth ? props.maxWidth + "px" : "")};
  height: ${props => (props.height ? props.height + "px" : "")};
  padding: ${props => (props.padding ? props.padding : "")};
  color: ${Colors.black};
  width: 100%;
  padding: 0px 15px;
  border-radius: 5.5px;
  border: solid 1.4px rgba(51, 51, 51, 0.2);
  font-size: 18px;
  line-height: 28px;
  box-sizing: border-box;
  background-color: ${Colors.bodyBackground};
  transition: 250ms linear;
  &::placeholder {
    opacity: 0.3;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:focus {
    background-color: #fff;
    border: solid 1.4px rgba(51, 51, 51, 0.75);
    outline: none;
  }
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
  ${space};
  ${width};
  ${color};
  &[disabled] {
    opacity: 0.4;
    cursor: no-drop;
  }
  &:placeholder {
    color: #3c4240;
  }
`

export const TextNotify = styled.div`
  height: 23px;
  opacity: 0.8;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #3c4240;
  margin-bottom: 8px;
  margin-top: 24px;
  white-space: nowrap;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  &:focus {
    background-color: #fff;
    border: solid 1.4px rgba(51, 51, 51, 0.75);
    outline: none;
  }
  @media (max-width: ${theme.breakpoints[0]}) {
    width: auto;
  }
`

export const DateField = styled.div`
  & .SingleDatePicker {
    width: 100%;
    height: 40px;
    border: none;
    background-color: #fafafa;
  }
  & .DateInput_input {
    border-radius: 5.5px;
    border: solid 1.4px rgba(51, 51, 51, 0.2);
  }
  & .DateInput_input::placeholder {
    opacity: 0.3;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }
  & .DateInput_input:focus {
    background-color: #fff;
    border: solid 1.4px rgba(51, 51, 51, 0.75);
    outline: none;
  }
  & .DateInput_input:hover {
    border-color: hsl(0, 0%, 70%);
  }
  & .DateInput,
  .DateInput_input,
  .SingleDatePickerInput {
    width: 100%;
    height: 40px;
  }
`
export const InnerWrapper = styled.div`
  margin-top: 10px;
  font-weight: 600;
  width: 100%;
  flex-direction: column;
  padding: 30px 40px;
  box-sizing: border-box;
  @media (max-width: 700px) {
    padding: 30px 10px;
  }
`

export const ButtonWrapper = styled.div`
display: flex;
align-items: center;
@media (max-width: 900px) {
 justify-content:center
 margin-top:1rem
}
`

export const FormItemFull = styled(Box as any)`
  width: 100%;
  padding: 0 8px;
  flex-wrap: wrap;
  align-items: center;
`
export const FormItemFullBio = styled(Box as any)`
  width: 100%;
  padding: 0 8px;
  @media (max-width: 700px) {
    padding: 0 0px;
  }
`

export const CancelBtn = styled.button`
    align-items: center;
    border:2px solid red !important
    margin-left:1.5rem
    padding: 8px 20px;
    display: flex;
    flex:1;
    background:transparent;
    color:red;
    cursor: pointer;
    border-radius:6px;
    letter-spacing: 0.6px
`

export const FormItemHalf = styled(Box as any)`
  width: ${props => (props.maxWidth ? props.maxWidth + "%" : "100%")};
  margin: 0 8px;
  @media (max-width: 700px) {
    width: 100%;
    margin: 0;
  }
`

export const FormRow = styled(Box as any)`
  display: flex;
  @media (max-width: 700px) {
    flex-direction: column;
    width: 100%;
  }
`
