import React from "react"

type Props = {
  showModal: () => void
  isHidden: boolean
  activeListing: (value: boolean) => void
}

const ActiveStatus = (props: Props) => {
  const { activeListing, isHidden, showModal } = props
  return (
    <>
      {!isHidden ? (
        <span className="available-true" onClick={showModal}>
          Available
        </span>
      ) : (
        <span className="available-false2" onClick={() => activeListing(false)}>
          Unavailable
        </span>
      )}
    </>
  )
}

export default ActiveStatus
