export const INDUSTRY_OPTIONS = [
  { value: "NONE", label: "None" },
  { value: "ACCOUNTING", label: "Accounting" },
  { value: "AUTOMOTIVE", label: "Automotive" },
  { value: "BEAUTY_AND_FASHION", label: "Beauty & Fashion" },
  { value: "CONSTRUCTION", label: "Construction" },
  { value: "CONSULTING", label: "Consulting" },
  { value: "EDUCATION", label: "Education" },
  { value: "FINANCE", label: "Finance" },
  { value: "FOOD_AND_BEVERAGES", label: "Food & Beverages" },
  { value: "GOVERNMENT", label: "Government" },
  { value: "HEALTH_AND_FITNESS", label: "Health & Fitness" },
  { value: "HEALTHCARE", label: "Healthcare" },
  { value: "HOSPITALITY", label: "Hospitality" },
  { value: "INSURANCE", label: "Insurance" },
  { value: "LEGAL", label: "Legal" },
  { value: "MANUFACTURING", label: "Manufacturing" },
  { value: "MARKETING", label: "Marketing" },
  { value: "MEDIA", label: "Media" },
  { value: "NATURAL_RESOURCES", label: "Natural Resources" },
  { value: "NOT_FOR_PROFIT", label: "Not-for-Profit" },
  { value: "PHARMACEUTICAL", label: "Pharmaceutical" },
  { value: "REAL_ESTATE", label: "Real Estate" },
  { value: "RECRUITING", label: "Recruiting" },
  { value: "RETAIL", label: "Retail" },
  { value: "TELECOMMUNICATION", label: "Telecommunication" },
  { value: "TRANSPORTATION", label: "Transportation" },
  { value: "TRAVEL", label: "Travel" }
]

export const bedrooms = [
  { value: "Room/Shared Home", label: "Room/Shared Home" },
  { value: "STUDIO", label: "Bachelor/Studio" },
  { value: "ONE_BEDROOM", label: "One Bedroom" },
  { value: "TWO_BEDROOMS", label: "Two Bedrooms" },
  { value: "THREE_BEDROOMS", label: "Three Bedrooms" },
  { value: "FOUR_OR_MORE_BEDROOMS", label: "Four Or More Bedrooms" }
]

export const You_Are = [
  { value: "Landlord/Owner", label: "Landlord/Owner" },
  { value: "Real_Estate_Agent", label: "Real Estate Agent" },
  { value: "Lease_Assignor", label: "Lease Assignor" },
  { value: "Sub-Lessor", label: "Sub-lessor" },
  { value: "Property_Manager", label: "Property Manager" }
]
export const laundry = [
  { value: "In-unit laundry", label: "In-unit Laundry" },
  { value: "In-building laundry", label: "In-building Laundry" },
  { value: "None", label: "None" }
]

export const notifyCriteria = [
  { value: true, label: "Yes, please!" },
  { value: false, label: "No, thanks" }
]

export const furnished = [
  { value: "true", label: "Yes, furnished" },
  { value: "false", label: "No, unfurnished" }
]

export const View_Option = [
  { value: "In-person", label: "In Person" },
  { value: "Virtually", label: "Virtually" }
]

export const userType = [
  { value: "RENTER", label: "Renter" },
  { value: "LANDLORD", label: "Landlord" }
]

export const Credit_score = [
  { value: "Exceeds 650", label: "Exceeds 650" },
  { value: "Is not available", label: "Is not available" },
  { value: "Other", label: "Other" }
]
export const pets = [
  { value: "CATS", label: "Cats" },
  { value: "DOGS", label: "Dogs" },
  { value: "OTHER", label: "Other" }
]

export const industries = {
  NONE: "NONE",
  ACCOUNTING: "Accounting",
  AUTOMOTIVE: "Automotive",
  BEAUTY_AND_FASHION: "Beauty & Fashion",
  CONSTRUCTION: "Construction",
  CONSULTING: "Consulting",
  EDUCATION: "Education",
  FINANCE: "Finance",
  FOOD_AND_BEVERAGES: "Food & Beverages",
  GOVERNMENT: "Government",
  HEALTH_AND_FITNESS: "Health & Fitness",
  HEALTHCARE: "Healthcare",
  HOSPITALITY: "Hospitality",
  INSURANCE: "Insurance",
  LEGAL: "Legal",
  MANUFACTURING: "Manufacturing",
  MARKETING: "Marketing",
  MEDIA: "Media",
  NATURAL_RESOURCES: "Natural Resources",
  NOT_FOR_PROFIT: "Not",
  PHARMACEUTICAL: "Pharmaceutical",
  REAL_ESTATE: "Real Estate",
  RECRUITING: "Recruiting",
  RETAIL: "Retail",
  TELECOMMUNICATION: "Telecommunication",
  TRANSPORTATION: "Transportation",
  TRAVEL: "Travel"
}

export const BUDGET = [
  { label: "Not available", value: "Not available" },
  { label: "$1,000", value: "$1,000" },
  { label: "$1,500", value: "$1,500" },
  { label: "$2,000", value: "$2,000" },
  { label: "$2,500", value: "$2,500" },
  { label: "$3,000", value: "$3,000" },
  { label: "$3,500", value: "$3,500" },
  { label: "$4,000", value: "$4,000" },
  { label: "$4,001+", value: "$4,001+" }
]
