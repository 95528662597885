import * as React from "react"
import styled from "styled-components"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

const antdCssDelay = () => import("antd/dist/antd.css")
setTimeout(() => {
  antdCssDelay()
}, 0)

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />

const Wrapper = styled.div`
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  justify-content: center;
`

// have to add firefox specific margin
// for some reason styled components + % in firefox doesn't work
const LoaderWrapper = styled.div`
  margin-top: 20%;
  @-moz-document url-prefix() {
    margin-top: 200px;
  }
`
class TolobiLoader extends React.PureComponent<any> {
  render() {
    return (
      <Wrapper>
        <LoaderWrapper>
          <Spin indicator={antIcon} />
        </LoaderWrapper>
      </Wrapper>
    )
  }
}

export default TolobiLoader
