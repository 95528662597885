import styled from "styled-components"
import colors from "src/theme/colors"

export const Text = styled.div<any>`
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: ${props => props.color};
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.fontWeight};
  margin: ${props => (props.margin ? props.margin : 0)};
`

export const Logo = styled.div<any>`
  width: ${props => props.width};
  height: ${props => props.height};
  background-image: url("https://s3.amazonaws.com/tb-app-assets/Misc/tolobi-web-logo.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 5px;
  border-radius: 100%;
`

export const Image = styled.img<any>`
  width: ${props => props.width};
  height: ${props => props.height};
  object-fit: contain;
  margin-right: 20px;
  cursor: pointer;
`

export const HeaderMain = styled.div<any>`
  display: flex;
  min-height: 150px;
  background: ${colors.primary};
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
`
