import React, { useState } from "react"
import { FormControl } from "@material-ui/core"
import SelectComponent from "src/dashboards/components/form-container-field/select-component"
import Text from "src/components/text"

const FormDropDown = props => {
  const {
    form,
    field,
    defaultVal,
    placeholder,
    mb,
    labelTxt,
    styleInput,
    options = [],
    ...rest
  } = props

  const [select, setSelect] = useState(field.value || "")
  const [windowDimenion, detectHW] = useState(window.innerWidth)

  const detectSize = () => {
    detectHW(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener("resize", detectSize)
    return () => {
      window.removeEventListener("resize", detectSize)
    }
  }, [windowDimenion])

  React.useEffect(() => {
    setSelect(field.value)
  }, [field.value])

  const handleChange = ({ value }) => {
    setSelect(value)
    form.setFieldValue(field.name, value)
  }

  const handleBlur = () => {
    form.setTouched({ ...form.touched, [field.name]: true })
  }
  const isErrorForStorage = field.name === "storage" || field.name === "maintenance"
  const errorClassName = isErrorForStorage ? "errorClassName" : "error-text";
  return (
    <FormControl id="signup-profile-select" style={{ marginBottom: 15 }} fullWidth>
      {labelTxt && (
        <Text genre="labelText" mb={mb && windowDimenion < 1200 ? mb : 10}>
          {labelTxt}
        </Text>
      )}
      <SelectComponent
        onChange={handleChange}
        name={field.name}
        id={field.name}
        select={select}
        value={select}
        options={options}
        onBlur={handleBlur}
        height={40}
        placeholder={options.find(option => select === option.value)?.label ?? placeholder}
      />
      {form.errors[field.name] && form.touched[field.name] && (
        <div className={errorClassName}>
          {rest.create_err_msg ? rest.create_err_msg(field.value) : form.errors[field.name]}
        </div>
      )}
    </FormControl>
  )
}

export default FormDropDown
