import React, { useEffect } from "react"
import FooterComponent from "../../components/footer"
import ReactHelmet from "src/components/react-helmet"

const EmbeddedPrivacyPolicy = () => {
  useEffect(() => {
    try {
      var script = document.createElement("script")
      script.src = "https://paperform.co/__embed"
      script.async = true
      document.body.appendChild(script)
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <>
      <ReactHelmet
        url="privacy-policy"
        title="Privacy Policy"
        description="Tolobi - Rental Made Easy"
      />
      <div data-paperform-id="tolobi-privacy-policy"></div>
      <FooterComponent />
    </>
  )
}

export default EmbeddedPrivacyPolicy
