import gql from "graphql-tag"

export const GET_ARTICLES = gql`
query articles($where: ArticleData) {
  countArticle(where: $where) {
    count
  }
  articles(where: $where) {
      id
      title
      createdAt
      subtitle
      articleType
      status
      note
      tag {
        id
        name
      }
  }
}
`

export const GET_COUNT_ARTICLE = gql`
  query countArticle {
    count
  }
`

export const GET_SINGLE_ARTICLE = gql`
  query singleArticle($id: String) {
    singleArticle(id: $id) {
      id
      title
      subtitle
      description
      articleUrl
      articleType
      imageUrl
      status
      note
      tag {
        id
        name
      }
    }
  }
`
export const SEARCH_ARTICLES_LIST = gql`
  query searchArticles($where:ArticleData) {
    countArticle(where: $where) {
      count
    }
    searchArticles(where:$where) {
      id
      title
      subtitle
      description
      articleUrl
      articleType
      imageUrl
      status
      createdAt
      note
      tag{
        name
      }
    }
  }
`

export const ARTICLE_TAGS = gql`
query getArticleTags {
  getArticleTags {
    id
    name
  }
}`

export const SEARCH_ARTICLE_TAG = gql`
  query searchArticleTags($where: ArticleTagWhereInput!) {
    searchArticleTags(where: $where) {
      name
      id
    }
  }
`