import gql from "graphql-tag"

export const CREATE_HELP_CENTER = gql`
  mutation createHelpCenter($WhereInput: HelpWhereInput!) {
    createHelpCenter(WhereInput: $WhereInput) {
      id
    }
  }
`

export const CREATE_BLOG = gql`
  mutation createBlog($where: BlogWhereInput!) {
    createBlog(where: $where) {
      id
    }
  }
`

export const UPDATE_TAG = gql`
  mutation updateTag($where: BlogWhereInput!) {
    updateTag(where: $where) {
      id
    }
  }
`

export const UPDATE_BLOG_AUTHOR = gql`
  mutation updateBlogAuthor($where: BlogWhereInput!) {
    updateBlogAuthor(where: $where) {
      id
    }
  }
`

export const DELETE_TAG = gql`
  mutation deleteTag($where: TagWhereInput!) {
    deleteTag(where: $where) {
      id
    }
  }
`

export const UPDATE_HELP_CENTER = gql`
  mutation updateHelpCenter($WhereInput: HelpWhereInput!) {
    updateHelpCenter(WhereInput: $WhereInput) {
      id
    }
  }
`
export const UPLOAD_IMAGE = gql`
  mutation uploadImage($where: BlogWhereInput!) {
    uploadImage(where: $where) {
      thumbnailUrl
    }
  }
`

export const UPDATE_BLOG = gql`
  mutation updateBlog($where: BlogWhereInput!) {
    updateBlog(where: $where) {
      id
    }
  }
`

export const DELETE_BLOG = gql`
  mutation deleteBlog($where: BlogWhereInput) {
    deleteBlog(where: $where) {
      id
    }
  }
`
