import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-client"
import { ApolloLink, concat, Operation, split } from "apollo-link"
import { onError } from "apollo-link-error"
import { WebSocketLink } from "apollo-link-ws"
import { getMainDefinition } from "apollo-utilities"
import { createUploadLink } from "apollo-upload-client";

const getToken = () => {
  const idToken = window.localStorage.getItem("id_token")
  return idToken ? `Bearer ${idToken}` : ``
}

const cache = new InMemoryCache()

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URI
})

const authMiddleware = new ApolloLink((operation: Operation, forward: any) => {
  operation.setContext({
    headers: {
      authorization: getToken()
    }
  })
  return forward(operation)
})


// Replaced httpLink with uploadLink reference link(https://github.com/jaydenseric/apollo-upload-client/issues/89#issuecomment-384923295)
// const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI })

// ** commented for now until we get subscription + websocket running

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_SUBSCRIPTION_WS_URI}`,
  options: {
    reconnect: true,
    connectionParams: () => {
      return { Authorization: getToken() }
    },
    connectionCallback: (err: any) => {
      if (err) {
        console.error(`Error Connecting to Subscriptions Server - ${err}`)
      }
    }
  }
})

const combinedLinks = split(
  ({ query }: any) => {
    const { kind, operation }: any = getMainDefinition(query)
    return kind === "OperationDefinition" && operation === "subscription"
  },
  wsLink,
  uploadLink
)

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error: { message: string }) => {
      console.error(`Unexpected error: ${error.message}`)
    })
  }
  if (networkError) {
    console.error(`Network error: ${networkError}`)
  }
})

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, concat(authMiddleware, combinedLinks)]),
  connectToDevTools: process.env.NODE_ENV === "development"
})
