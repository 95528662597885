import React, { ChangeEvent, memo } from "react"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"

import { radioGroupOptions } from "src/utils/auth-utils"
import TextArea from "src/components/text-area"

type Props = {
  setFormValues: any
}

function DisableForm({ setFormValues }: Props) {
  const [input, setInput] = React.useState("")
  const [radioInput, setRadioInput] = React.useState("")

  const onRadioChange = (value: string, key: number) => {
    setRadioInput(value)
    setFormValues(prev => ({ ...prev, ["message"]: value }))
  }

  const onChangeInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    setInput(value)
    setFormValues(prev => ({ ...prev, ["message"]: value }))
  }

  return (
    <>
      {/* <Text genre="profile-label" mb={0}>
    Disabling a listing hides it from renters.
  </Text> */}
      <div className="disable-radio">
        <FormControl component="fieldset">
          <RadioGroup aria-label="gender" name="radio-buttons-group">
            {radioGroupOptions.map((radio, index) => (
              <FormControlLabel
                key={index}
                value={radio.value}
                control={<Radio />}
                label={radio.label}
                onChange={() => onRadioChange(radio.value, radio.key!)}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {radioInput === radioGroupOptions[3].value && (
          <TextArea
            id="bio"
            name="bio"
            placeholder="Why are disabling this Listing ?"
            onChange={onChangeInput}
            defaultValue={input}
          />
        )}
      </div>
    </>
  )
}

export default memo(DisableForm)
