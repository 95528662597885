import React, { memo } from "react"
import Row from "src/components/row"
import { BlogCard } from "src/dashboards/components"

export type AnalyticsStatsType = {
  totalPublished: number
  totalPerDayPublished: number
  totalSeen: number
  totalAvgSeen: number
  totalCount: number
  totalReadingAvg: number
}

interface Props {
  data: AnalyticsStatsType
  loading: boolean
}

const AnalyticStats = (props: Props) => {
  const { data, loading } = props
  return (
    <Row justifyContent="center" alignItems="center" flexWrap="wrap">
      <BlogCard count={data?.totalCount} title="Total Articles" loading={loading} />
      <BlogCard count={data?.totalPublished} title="Total Articles Published" loading={loading} />
      <BlogCard
        count={data?.totalPerDayPublished}
        title="Articles Published / Day"
        loading={loading}
      />
      <BlogCard count={data?.totalSeen} title="Total Views" loading={loading} />
      <BlogCard count={data?.totalAvgSeen} title="Views / Article" loading={loading} />
      <BlogCard count={data?.totalReadingAvg} title="Average Reading Time" loading={loading} />
    </Row>
  )
}

export default memo(AnalyticStats)
