import { createObject } from "src/dashboards/components/landlord-create-listing/utils/createObject"
import { ParseType } from "src/dashboards/screens/landlord-access-building/types/index"
import {
    UNIT_AMENITIES as unitamt,
    UTILITIES as ut,
    AMENITIES as amt,
    TAGS as tag,
    TENANT_REQUIREMENTS as tenant,
    PETS,
    UNIT_APPLIANCES as appl
} from "src/dashboards/components/constants/constants"
import moment from "moment"
import { UserType } from "src/generated/graphql"

export const parseReqData = (
    {
        buildingTitle,
        address,
        des,
        utilities,
        amenities,
        unitamenities,
        pets,
        units,
        contractions,
        floors,
        pictures,
        tags,
        bathrooms,
        bedrooms,
        den,
        deposit,
        furnished,
        monthlyPrice,
        available,
        number,
        title,
        unitSize,
        leaseTerm,
        startDateAvailability,
        parking,
        parkingSpots,
        tenantRequirements,
        floorplan,
        propertyType,
        picture,
        unitAppliances,
        moveInBonuses,
        // plan,
        // planPrice,
        // price_id,
        // unit,
        advertiseUnit,
        existingUrl,
        advertiseDuration,
        currentlyTenanted,
        desiredRentalPrice,
        minimumRentalPrice,
        extraInfo,
        showingUnit,
        customiseLease,
        storage,
        maintenance,
        smokeAlarm,
        basement,
        isPremium,
        premiumName,
        payment_status,
        subscriptionId,
        subscriptions,
        charge_id,
        amount,
        govtProof,
        signature,
        ownershipProof,
        propertyRealtor,
        partnershipStatus,
        spousePartner,
        lockbox,
        commission,
        region,
        isBasicFree,
        isViewManagement,
        isRentalOrTenantLeads,
        isConcierge,
        isLeadGeneartor,
        isRentalAssistance,
        role
    }: ParseType,
    edit = false,
    neighbourId,
    user
) => {

    const utObj = createObject(utilities, ut)
    const amtObj = createObject(amenities, amt)
    const applObj = createObject(unitAppliances, appl)
    const tagObj = createObject(tags, tag)
    const unitObj = createObject(unitamenities, unitamt)
    const tenantObj = createObject(tenantRequirements, tenant)
    const petObj = createObject(pets, PETS)

    if (edit) {
        return {
            role,
            address,
            buildingTitle,
            number,
            des,
            pets: petObj,
            bathrooms,
            bedrooms,
            den,
            // deposit,
            deposit: deposit === undefined || deposit === "" ? 0 : deposit,
            furnished,
            // monthlyPrice,
            monthlyPrice: monthlyPrice === undefined || monthlyPrice === "" ? 0 : monthlyPrice,
            propertyType,
            available,
            title,
            unitSize,
            leaseTerm,
            parking,
            parkingSpots: parkingSpots ? parkingSpots : null,
            storage,
            maintenance,
            smokeAlarm,
            startDateAvailability,
            picture: picture?.map(({ url, id }: { url: string; id: string }) => ({ url, id })),
            utilities: utObj,
            tenantRequirements: tenantObj,
            unitamenities: unitObj,
            amenities: amtObj,
            pictures: pictures.map(({ url, id }: { url: string; id: string }) => ({ url, id })),
            govtProof: govtProof.map(({ url, id }: { url: string; id: string }) => ({ url, id })),
            ownershipProof: ownershipProof,
            signature: signature,
            propertyRealtor,
            partnershipStatus,
            spousePartner,
            lockbox,
            commission,
            tags: tagObj,
            basement,
            contractions,
            floors,
            units,
            floorplan,
            unitAppliances: applObj,
            moveInBonuses: moveInBonuses,
            // plan: parseInt(plan),
            // planPrice: parseInt(planPrice),
            // price_id,
            // unit,
            premiumName,
            payment_status,
            subscriptionId,
            advertiseUnit,
            existingUrl,
            advertiseDuration,
            currentlyTenanted,
            desiredRentalPrice,
            minimumRentalPrice,
            extraInfo,
            showingUnit,
            customiseLease,
            isPremium,
            region: region ? region.toLowerCase() : "",
            isBasicFree,
            isViewManagement,
            isRentalOrTenantLeads,
            isConcierge,
            isLeadGeneartor,
            isRentalAssistance
        }
    }

    //run when edit false
    delete tenantObj?.none
    return {
        role,
        title: buildingTitle,
        description: des,
        // plan: parseInt(plan),
        // planPrice: parseInt(planPrice),
        premiumName,
        subscriptions: subscriptions.map(({ id, price, subTitle, title, type, plan_group }: { id: number, price: string, plan_group: string, subTitle: string, type: string, title: string, unit: string }) => ({ plan_id: id, plan_name: title, plan_price: price, plan_group, plan_unit: subTitle, plan_type: type })),
        payment_status,
        charge_id,
        amount: amount,
        subscriptionId,
        isPremium,
        advertiseUnit,
        existingUrl,
        advertiseDuration,
        currentlyTenanted,
        desiredRentalPrice,
        minimumRentalPrice,
        extraInfo,
        showingUnit,
        customiseLease,
        basement,
        location: {
            lat: address.geometry.lats,
            lng: address.geometry.lngs,
            address: address.title && address.title,
            tags: tagObj,
            neighbourHood: { connect: { id: neighbourId?.toString() } }
        },
        utilities: utObj,
        amenities: amtObj,
        propertyType: propertyType !== undefined ? propertyType : "OTHER",
        pets: petObj,
        isapproved:
            user && (user.userType === UserType.Admin || user.userType === UserType.SubAdmin)
                ? true
                : false,
        buildingConstructionYear: contractions ? contractions.toString() : "",
        totalUnitsInBuilding: units,
        storiesInBuilding: floors !== undefined ? floors : null,
        developmentCompany: "",
        tenantRequirements: tenantObj,
        unitAppliances: applObj,
        pictures: pictures.map(({ url, id }: { url: string; id: string }) => ({ url, id })),
        govtProof: govtProof ? govtProof.map(({ url, id }: { url: string; id: string }) => ({ url, id })) : [],
        signature: signature ? signature.map(({ url, id }: { url: string; id: string }) => ({ url, id })) : [],
        ownershipProof: ownershipProof,
        propertyRealtor,
        partnershipStatus,
        spousePartner,
        lockbox,
        commission,
        region: region ? region.toLowerCase() : "",
        isBasicFree,
        isViewManagement,
        isRentalOrTenantLeads,
        isConcierge,
        isLeadGeneartor,
        isRentalAssistance,
        units: [
            {
                title: title,
                number: number,
                den: den,
                available: true,
                startDateAvailability:
                    startDateAvailability && startDateAvailability?.length > 12
                        ? moment(startDateAvailability).format()
                        : new Date(),
                unitSize: unitSize,
                bedrooms: bedrooms,
                // monthlyPrice: monthlyPrice,
                monthlyPrice: monthlyPrice === undefined || monthlyPrice === "" ? 0 : monthlyPrice,
                bathrooms: bathrooms,
                floorplan: floorplan,
                furnished: furnished === true ? true : false,
                deposit: deposit === undefined || deposit === "" ? 0 : deposit,
                // deposit: deposit,
                leaseTerm: leaseTerm,
                parking: parking,
                parkingSpots: parkingSpots ? parkingSpots : null,
                storage,
                maintenance,
                smokeAlarm,
                amenities: unitObj,
                unitAppliances: applObj,
                pictures: picture.map(({ url, id }: { url: string; id: string }) => ({ url, id })),
            }
        ]
    }
}



