import React from "react"
import PriceField from "src/components/FormComponents/Field/pricing-input-field"
import { FieldType } from "src/components/FormComponents/types"
import YearPicker from "src/components/FormComponents/year-picker"
import PicturePicker from "src/components/FormComponents/picture-picker"
import SelectMulti from "src/components/FormComponents/select-multi"
import SearchMapAdd from "src/components/FormComponents/mapBox-address"
import FloorPlanUpload from "src/components/FormComponents/pdf-uploader"
import DatePick from "src/components/FormComponents/year-picker/date-picker"
import PriceDecimalField from "src/components/FormComponents/Field/price-input-decimal"
import PhoneInputs from "src/components/FormComponents/phoneInput/signup-phone-input"
import {
  UTILITIES,
  TAGS,
  PETS,
  AMENITIES,
  UNIT_AMENITIES,
  UNIT_APPLIANCES,
  TENANT_REQUIREMENTS,
  BEDROOMS,
  PROPERTY_TYPE,
  AVAILABLE,
  PARKING,
  DEN,
  BASEMENT,
  SMOKE_ALARM,
  MAINTENANCE,
  IDEAL_LEASE_TEARM,
  STORAGE,
  ADVERTISE,
  CURRENTLY_TENANTED,
  SHOWING_UNIT,
  CUSTOMISE_LEASE,
  ADVERTISE_DURATION,
  COMMISSION,
  LOCKBOX,
  INTERESTED_IN_REALATOR
} from "src/dashboards/components/constants"
import Select from "src/components/FormComponents/select"
import Field from "src/components/FormComponents/Field/signup-profile-Field"
import TextArea from "src/components/FormComponents/textArea/decriptionText"
import DropDown from "src/components/FormComponents/select/dropdown"
import TextAreas from "src/components/FormComponents/textArea/decriptionText"
import Signature from "src/dashboards/components/landlord-create-listing/signature"
import { You_Are } from "src/constants/industry-constants"
import PriceDecimalWrapper from "src/dashboards/components/landlord-create-listing/utils/PriceDecimalWrapper"
import PriceRadioButton from "src/dashboards/components/landlord-create-listing/utils/radio-antd"
import { BATHROOMS } from "src/dashboards/components/constants/constants"
// import CheckboxField from "./checkbox"

const FURNISHED = [
  { value: true, label: "Yes, furnished" },
  { value: false, label: "No, unfurnished" }
]

const fields: FieldType[] = [
  {
    labelTxt: "You are",
    name: "role",
    placeholder: "Select",
    type: "selects",
    required: true,
    component: Select,
    grid: 12,
    options: You_Are
  },
  {
    id: "phoneNumber",
    labelTxt: "Contact Number",
    name: "phone",
    placeholder: "Contact Number",
    type: "text",
    component: PhoneInputs,
    required: true,
    grid: 12,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Property Type",
    name: "propertyType",
    placeholder: "Select",
    type: "selects",
    component: Select,
    required: true,
    grid: 12,
    options: PROPERTY_TYPE
  },
  {
    labelTxt: "Address",
    name: "address",
    placeholder: "Address",
    type: "text",
    required: true,
    component: SearchMapAdd,
    styleInput: {
      maxWidth: "100%"
    }
  },

  {
    labelTxt: "Listing Title",
    name: "buildingTitle",
    placeholder: "Title",
    type: "text",
    required: true,
    component: Field,
    styleInput: {
      maxWidth: "100%"
    }
  },
  // {
  //   labelTxt: "Property Description",
  //   name: "des",
  //   placeholder: "Description",
  //   type: "text",
  //   component: TextArea,
  //   multiline: true,
  //   rows: 4,
  //   required: true,
  //   styleInput: {
  //     maxWidth: "100%",
  //     maxHeight: "15vh"
  //   }
  // },
  {
    labelTxt: "Number of Units",
    name: "units",
    placeholder: "Number of Units",
    type: "number",
    component: Field,
    required: false,
    grid: 4,
    rows: 4,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Number of Floors",
    name: "floors",
    placeholder: "Number of Floors",
    type: "number",
    required: false,
    component: Field,
    grid: 4,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Year of Construction",
    name: "contractions",
    placeholder: "Year of Construction",
    type: "number",
    required: false,
    component: YearPicker,
    grid: 4,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    dependent: true,
    dependentOn: "propertyType",
    dependentValue: "HOUSE_OR_TOWNHOUSE",
    labelTxt: "Does your unit have a basement?",
    name: "basement",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 12,
    options: BASEMENT
  }
]

const fields2: FieldType[] = [
  {
    labelTxt: "Utilities",
    name: "utilities",
    placeholder: "Utilities",
    type: "select-multi",
    required: false,
    component: SelectMulti,
    grid: 4,
    options: UTILITIES,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Amenities",
    name: "amenities",
    placeholder: "Amenities",
    type: "select-multi",
    component: SelectMulti,
    required: false,
    grid: 4,
    options: AMENITIES
  },
  {
    labelTxt: "Pets Allowed",
    name: "pets",
    placeholder: "Pets Allowed",
    type: "selects",
    component: SelectMulti,
    required: false,
    grid: 4,
    options: PETS
  },
  {
    labelTxt: "Tags",
    name: "tags",
    placeholder: "Tags",
    type: "select-multi",
    required: false,
    component: SelectMulti,
    grid: 4,
    options: TAGS
  },
  {
    labelTxt: "Tenant Requirements",
    name: "tenantRequirements",
    placeholder: "Amenities (you can add multiple)",
    type: "select-multi",
    component: SelectMulti,
    required: false,
    grid: 4,
    options: TENANT_REQUIREMENTS
  },
  // {
  //   labelTxt: "Move-in Bonuses",
  //   name: "moveInBonuses",
  //   placeholder: "Move-in Bonuses",
  //   type: "text",
  //   required: false,
  //   component: Field,
  //   disabled: true,
  //   styleInput: {
  //     maxWidth: "100%"
  //   }
  // },
  {
    labelTxt: "Pictures",
    name: "pictures",
    placeholder: "Pictures",
    type: "picture",
    required: true,
    component: PicturePicker,
    styleInput: {
      maxWidth: "100%",
      minWidth: "100%"
    }
  }
]

const unitfields: FieldType[] = [
  {
    labelTxt: "Unit Title",
    name: "title",
    placeholder: "Title",
    type: "text",
    required: true,
    component: Field,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Availability Date",
    name: "startDateAvailability",
    placeholder: "",
    type: "selects",
    component: DatePick,
    required: false,
    grid: 4
  },
  {
    labelTxt: "Bedrooms",
    name: "bedrooms",
    placeholder: "Bedrooms",
    type: "selects",
    component: Select,
    required: true,
    grid: 4,
    options: BEDROOMS
  },
  {
    labelTxt: "Bathrooms",
    name: "bathrooms",
    placeholder: "Bathrooms",
    type: "selects",
    component: Select,
    required: true,
    grid: 4,
    options: BATHROOMS,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Den",
    name: "den",
    placeholder: "Den",
    type: "number",
    component: Field,
    required: false,
    grid: 4,
    options: DEN,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Unit Size (sq. ft.)",
    name: "unitSize",
    placeholder: "Unit Size ",
    type: "number",
    required: true,
    component: Field,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Furnished",
    name: "furnished",
    placeholder: "Select",
    type: "selects",
    component: DropDown,
    required: false,
    grid: 4,
    options: FURNISHED
  },
  {
    labelTxt: "",
    name: "priceEnquiry",
    grid: 12,
    type: "text",
    required: true,
    component: PriceRadioButton,
    styleInput: {
      maxWidth: "100%",
      minWidth: "100%"
    }
  },
  {
    dependent: true,
    dependentOn: "priceEnquiry",
    dependentValue: false,
    labelTxt: "Monthly Price (CAD)",
    name: "monthlyPrice",
    placeholder: "Monthly Price (CAD)",
    type: "number",
    max: 200000,
    min: 0,
    step: 0.01,
    required: false,
    component: PriceDecimalWrapper,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50,
      dollor: "dollor",
      backgroundColor: "#fafafa"
    }
  },
  {
    dependent: true,
    dependentOn: "priceEnquiry",
    dependentValue: false,
    labelTxt: "Deposit (CAD)",
    name: "deposit",
    placeholder: "Deposit (CAD)",
    type: "number",
    max: 200000,
    min: 0,
    step: 0.01,
    required: false,
    component: PriceDecimalWrapper,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50,
      dollor: "dollor",
      backgroundColor: "#fafafa"
    }
  }
]

const unitEditfields: FieldType[] = [
  {
    labelTxt: "Unit Title",
    name: "title",
    placeholder: "Title",
    type: "text",
    required: true,
    component: Field,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Availability Date",
    name: "startDateAvailability",
    placeholder: "",
    type: "selects",
    component: DatePick,
    required: false,
    grid: 4
  },
  {
    labelTxt: "Bedrooms",
    name: "bedrooms",
    placeholder: "Bedrooms",
    type: "selects",
    component: Select,
    required: true,
    grid: 4,
    options: BEDROOMS
  },
  {
    labelTxt: "Bathrooms",
    name: "bathrooms",
    placeholder: "Bathrooms",
    type: "selects",
    component: Select,
    required: true,
    grid: 4,
    options: BATHROOMS,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Den",
    name: "den",
    placeholder: "Den",
    type: "number",
    component: Field,
    required: false,
    grid: 4,
    options: DEN,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50,
      fontSize: "18px"
    }
  },
  {
    labelTxt: "Unit Size (sq. ft.)",
    name: "unitSize",
    placeholder: "Unit Size ",
    type: "number",
    required: true,
    component: Field,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Furnished",
    name: "furnished",
    placeholder: "Select",
    type: "selects",
    component: DropDown,
    required: false,
    grid: 4,
    options: FURNISHED
  },
  {
    labelTxt: "Available",
    name: "available",
    placeholder: "Select",
    type: "selects",
    component: Select,
    required: false,
    grid: 4,
    options: AVAILABLE
  },
  {
    dependent: true,
    dependentOn: "priceEnquiry",
    dependentValue: false,
    labelTxt: "Monthly Price (CAD)",
    name: "monthlyPrice",
    placeholder: "Monthly Price (CAD)",
    type: "number",
    max: 200000,
    min: 0,
    step: 0.01,
    required: false,
    component: PriceDecimalWrapper,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50,
      dollor: "dollor",
      backgroundColor: "#fafafa"
    }
  },

  {
    dependent: true,
    dependentOn: "priceEnquiry",
    dependentValue: false,
    labelTxt: "Deposit (CAD)",
    name: "deposit",
    placeholder: "Deposit (CAD)",
    type: "number",
    max: 200000,
    min: 0,
    step: 0.01,
    required: false,
    component: PriceDecimalWrapper,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50,
      dollor: "dollor",
      backgroundColor: "#fafafa"
    }
  }
]

const unitfields2: FieldType[] = [
  {
    labelTxt: "Unit Number",
    name: "number",
    placeholder: "Number",
    type: "text",
    component: Field,
    required: false,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Lease Term (Months)",
    name: "leaseTerm",
    placeholder: "Lease Term",
    type: "number",
    min: 0,
    max: 120,
    component: Field,
    required: false,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Parking",
    name: "parking",
    placeholder: "Parking",
    type: "selects",
    component: Select,
    required: true,
    grid: 4,
    options: PARKING,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Parking Spots",
    name: "parkingSpots",
    placeholder: "Parking Spots",
    type: "number",
    min: 0,
    max: 20,
    alignment: "flex-end",
    component: Field,
    required: false,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Which storage/locker does the property come with?",
    name: "storage",
    placeholder: "Select",
    type: "selects",
    required: true,
    component: Select,
    grid: 4,
    options: STORAGE,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Who will be responsible for exterior maintenance?",
    name: "maintenance",
    placeholder: "Select",
    type: "selects",
    required: true,
    component: Select,
    grid: 4,
    options: MAINTENANCE,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt:
      "Do you have working smoke and carbon monoxide alarms installed in the unit as legally required?",
    name: "smokeAlarm",
    placeholder: "Select",
    type: "selects",
    required: true,
    component: Select,
    grid: 12,
    options: SMOKE_ALARM,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Picture",
    name: "picture",
    placeholder: "Picture",
    type: "picture",
    required: true,
    component: PicturePicker,
    styleInput: {
      maxWidth: "100%",
      minWidth: "100%"
    }
  }
]

const unitfields3: FieldType[] = [
  {
    labelTxt: "Unit Appliances",
    name: "unitAppliances",
    placeholder: "Unit Appliances (you can add multiple)",
    type: "select-multi",
    component: SelectMulti,
    required: false,
    grid: 6,
    options: UNIT_APPLIANCES
  },
  {
    labelTxt: "Unit Features", //Name change to Unit Features
    name: "unitamenities",
    placeholder: "Amenities (you can add multiple)",
    type: "select-multi",
    component: SelectMulti,
    required: false,
    grid: 6,
    options: UNIT_AMENITIES
  },
  {
    labelTxt: "Floor Plan",
    name: "floorplan",
    placeholder: "Pictures",
    type: "picture",
    required: false,
    component: FloorPlanUpload
  }
]

const editFields2: FieldType[] = [
  {
    labelTxt: "Utilities",
    name: "utilities",
    placeholder: "Utilities",
    type: "select-multi",
    required: false,
    component: SelectMulti,
    grid: 4,
    options: UTILITIES,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Amenities",
    name: "amenities",
    placeholder: "Amenities",
    type: "select-multi",
    component: SelectMulti,
    required: false,
    grid: 4,
    options: AMENITIES
  },
  {
    labelTxt: "Pets Allowed",
    name: "pets",
    placeholder: "Pets Allowed",
    type: "selects",
    component: SelectMulti,
    required: false,
    grid: 4,
    options: PETS
  },
  {
    labelTxt: "Tags",
    name: "tags",
    placeholder: "Tags",
    type: "select-multi",
    required: false,
    component: SelectMulti,
    grid: 4,
    options: TAGS
  },
  {
    labelTxt: "Tenant Requirements",
    name: "tenantRequirements",
    placeholder: "Amenities (you can add multiple)",
    type: "select-multi",
    component: SelectMulti,
    required: false,
    grid: 4,
    options: TENANT_REQUIREMENTS
  },
  {
    labelTxt: "Move-in Bonuses",
    name: "moveInBonuses",
    placeholder: "Move-in Bonuses",
    type: "text",
    required: false,
    component: Field,
    grid: 4
  },
  {
    labelTxt: "Pictures",
    name: "pictures",
    placeholder: "Pictures",
    type: "picture",
    required: true,
    component: PicturePicker,
    styleInput: {
      maxWidth: "100%",
      minWidth: "100%"
    }
  }
]

const landlordFields: FieldType[] = [
  // {
  //   labelTxt: "Desired Rental Price",
  //   name: "desiredRentalPrice",
  //   placeholder: "Desired Rental Price",
  //   type: "number",
  //   required: true,
  //   component: PriceField,
  //   grid: 6,
  //   styleInput: {
  //     maxWidth: "100%",
  //     maxHeight: 50,
  //     dollor: "dollor",
  //     backgroundColor: "#fafafa"
  //   }
  // },
  {
    labelTxt: (
      <>
        <div>Minimum Rental Price</div>
        <div style={{ display: "flex", flexDirection: "column", opacity: 0.4, fontSize: "14px" }}>
          <span>This is being requested for the Tolobi Rental Team to better assist you, and will not be shared publicly.</span>
        </div>
      </>
    ),
    name: "minimumRentalPrice",
    placeholder: "Minimum Rental Price",
    type: "number",
    required: true,
    component: PriceField,
    grid: 12,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50,
      dollor: "dollor",
      backgroundColor: "#fafafa"
    }
  },
  {
    labelTxt: "Are you already advertising this unit online?",
    name: "advertiseUnit",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 6,
    options: ADVERTISE
  },
  {
    dependent: true,
    dependentOn: "advertiseUnit",
    dependentValue: "Yes",
    labelTxt: "Your Existing Listing URL",
    name: "existingUrl",
    placeholder: "e.g. URL of your listing on Kijiji, Realtor.ca, etc",
    type: "url",
    required: false,
    component: Field,
    grid: 6
  },
  {
    dependent: true,
    dependentOn: "advertiseUnit",
    dependentValue: "Yes",
    labelTxt: "How long have you been advertising for?",
    name: "advertiseDuration",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 6,
    options: ADVERTISE_DURATION
  },
  {
    labelTxt: "Is your property currently tenanted?",
    name: "currentlyTenanted",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 6,
    options: CURRENTLY_TENANTED
  },
  {
    labelTxt: "Anything else you want to tell us?",
    name: "extraInfo",
    placeholder: "About yourself, your rental property, its neighbourhood, ideal tenants, etc.?",
    type: "text",
    required: false,
    component: TextAreas,
    grid: 12
  }
]

const landlordFieldwithLRG: FieldType[] = [
  // {
  //   labelTxt: "Desired Rental Price",
  //   name: "desiredRentalPrice",
  //   placeholder: "Desired Rental Price",
  //   type: "number",
  //   required: true,
  //   component: PriceField,
  //   grid: 6,
  //   styleInput: {
  //     maxWidth: "100%",
  //     maxHeight: 50,
  //     dollor: "dollor",
  //     backgroundColor: "#fafafa"
  //   }
  // },
  {
    labelTxt: (
      <>
        <div>Minimum Rental Price</div>
        <div style={{ display: "flex", flexDirection: "column", opacity: 0.4, fontSize: "14px" }}>
          <span>This is being requested for the Tolobi Rental Team to better assist you, and will not be shared publicly.</span>
        </div>
      </>
    ),
    name: "minimumRentalPrice",
    placeholder: "Minimum Rental Price",
    type: "number",
    required: true,
    component: PriceField,
    grid: 12,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50,
      dollor: "dollor",
      backgroundColor: "#fafafa"
    }
  },
  {
    labelTxt: "Are you already advertising this unit online?",
    name: "advertiseUnit",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 6,
    options: ADVERTISE
  },
  {
    dependent: true,
    dependentOn: "advertiseUnit",
    dependentValue: "Yes",
    labelTxt: "Your Existing Listing URL",
    name: "existingUrl",
    placeholder: "e.g. URL of your listing on Kijiji, Realtor.ca, etc",
    type: "url",
    required: false,
    component: Field,
    grid: 6
  },
  {
    dependent: true,
    dependentOn: "advertiseUnit",
    dependentValue: "Yes",
    labelTxt: "How long have you been advertising for?",
    name: "advertiseDuration",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 6,
    options: ADVERTISE_DURATION
  },
  {
    labelTxt: "Is your property currently tenanted?",
    name: "currentlyTenanted",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 6,
    options: CURRENTLY_TENANTED
  },
  {
    labelTxt: "Anything else you want to tell us?",
    name: "extraInfo",
    placeholder: "About yourself, your rental property, its neighbourhood, ideal tenants, etc.",
    type: "text",
    required: false,
    component: TextAreas,
    grid: 12
  },
  {
    labelTxt: "Who will be showing the unit?",
    name: "showingUnit",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 6,
    options: SHOWING_UNIT
  },
  {
    labelTxt: " Would you like us to customise the lease for you?",
    name: "customiseLease",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 6,
    options: CUSTOMISE_LEASE
  },
  {
    labelTxt: (
      <>
        <div>Would you like us to list your property with Realtor.ca (formerly MLS.ca)?</div>
        <div style={{ display: "flex", flexDirection: "column", opacity: 0.4, fontSize: "14px" }}>
          <span>You will be required to provide:</span>
          <span>
            1) A copy of written consent from you allowing us to list the property on your behalf
          </span>
          <span> 2) A copy of the property tax bill to verify your ownership of the property</span>
          <span> 3) A copy of your government-issued ID</span>
          <span>4) On-site key access (e.g. lockbox)</span>
        </div>
        <div style={{ fontWeight: "bold", fontSize: "14px", marginTop: "10px" }}>
          You also must not advertise your property on your own or engage another agent, while we
          have your property listed on Realtor.ca (formerly MLS.ca) on your behalf.
        </div>
      </>
    ),
    name: "propertyRealtor",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 12,
    options: INTERESTED_IN_REALATOR
  },
  {
    dependent: true,
    dependentOn: "propertyRealtor",
    dependentValue: "Yes",
    labelTxt: (
      <>
        <div>
          How much commission are you willing to offer to cooperating agents (representing tenants)?
        </div>
        <div style={{ display: "flex", flexDirection: "column", opacity: 0.4, fontSize: "14px" }}>
          <span>
            Required by Realtor.ca for us to list your unit. Industry rate is 1/2 month's rent +
            HST.
          </span>
        </div>
      </>
    ),
    name: "commission",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 12,
    options: COMMISSION
  },
  {
    dependent: true,
    dependentOn: "propertyRealtor",
    dependentValue: "Yes",
    labelTxt: (
      <>
        <div>Spousal Consent: Are you married or party to a domestic partnership agreement?</div>
        <div style={{ display: "flex", flexDirection: "column", opacity: 0.4, fontSize: "14px" }}>
          <span>Required by Realtor.ca for us to list your unit.</span>
        </div>
      </>
    ),
    name: "partnershipStatus",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 12,
    options: CUSTOMISE_LEASE
  },
  {
    dependent: true,
    dependentOn: "propertyRealtor",
    dependentValue: "Yes",
    labelTxt: (
      <>
        <div>
          Have either you or your spouse/domestic partner at any time during your marriage lived on
          the Property above?
        </div>
        <div style={{ display: "flex", flexDirection: "column", opacity: 0.4, fontSize: "14px" }}>
          <span>
            Required by Realtor.ca for us to list your unit If only one individual is on title and
            the answer to both questions is yes, then the Property Owner will be unable to complete
            a sale of the Property without the legal consent of the spouse. The Property Owner is
            urged to obtain legal advice respecting this issue prior to the acceptance of any offer
            to purchase.
          </span>
        </div>
      </>
    ),
    name: "spousePartner",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 12,
    options: CUSTOMISE_LEASE
  },
  {
    dependent: true,
    dependentOn: "propertyRealtor",
    dependentValue: "Yes",
    labelTxt: (
      <>
        <div>Do you have a lockbox installed on-site?</div>
        <div style={{ display: "flex", flexDirection: "column", opacity: 0.4, fontSize: "14px" }}>
          <span>
            For cooperating/tenant's agents to access the keys on their own, and show the unit
          </span>
        </div>
      </>
    ),
    name: "lockbox",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 12,
    options: LOCKBOX
  },
  {
    dependent: true,
    dependentOn: "propertyRealtor",
    dependentValue: "Yes",
    labelTxt: "Upload a copy of your government-issued ID",
    name: "govtProof",
    placeholder: "Upload a copy of your government-issued ID",
    type: "picture",
    required: false,
    component: PicturePicker,
    grid: 12,
    styleInput: {
      maxWidth: "100%",
      minWidth: "100%"
    }
  },
  {
    dependent: true,
    dependentOn: "propertyRealtor",
    dependentValue: "Yes",
    labelTxt: (
      <>
        <div>
          Please sign here to provide Tolobi Rental Team with permission to list your property with
          Realtor.ca(formerly MLS.ca)on your behalf.
        </div>
        <div style={{ display: "flex", flexDirection: "column", opacity: 0.4, fontSize: "14px" }}>
          <span>Required by Realtor.ca for us to list your unit</span>
        </div>
      </>
    ),
    name: "signature",
    placeholder: "Select",
    required: false,
    component: Signature,
    grid: 12,
    offsetWidth: 10
  }
]

const landlordFieldswithOntario: FieldType[] = [
  // {
  //   labelTxt: "Desired Rental Price",
  //   name: "desiredRentalPrice",
  //   placeholder: "Desired Rental Price",
  //   type: "number",
  //   required: true,
  //   component: PriceField,
  //   grid: 6,
  //   styleInput: {
  //     maxWidth: "100%",
  //     maxHeight: 50,
  //     dollor: "dollor",
  //     backgroundColor: "#fafafa"
  //   }
  // },
  {
    labelTxt: (
      <>
        <div>Minimum Rental Price</div>
        <div style={{ display: "flex", flexDirection: "column", opacity: 0.4, fontSize: "14px" }}>
          <span>This is being requested for the Tolobi Rental Team to better assist you, and will not be shared publicly.</span>
        </div>
      </>
    ),
    name: "minimumRentalPrice",
    placeholder: "Minimum Rental Price",
    type: "number",
    required: true,
    component: PriceField,
    grid: 12,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50,
      dollor: "dollor",
      backgroundColor: "#fafafa"
    }
  },
  // {
  //   labelTxt: "Viewing Management Deposit",
  //   name: "view_management_deposit",
  //   grid: 12,
  //   type: "text",
  //   required: true,
  //   component: CheckboxField,
  //   styleInput: {
  //     maxWidth: "100%",
  //     minWidth: "100%"
  //   }
  // },
  // {
  //   labelTxt: "Upload proof of your property ownership",
  //   name: "ownership",
  //   placeholder: "ownership",
  //   type: "picture",
  //   required: true,
  //   component: PicturePicker,
  //   styleInput: {
  //     maxWidth: "100%",
  //     minWidth: "100%"
  //   }
  // },
  {
    labelTxt: "Are you already advertising this unit online?",
    name: "advertiseUnit",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 6,
    options: ADVERTISE
  },
  {
    dependent: true,
    dependentOn: "advertiseUnit",
    dependentValue: "Yes",
    labelTxt: "Your Existing Listing URL",
    name: "existingUrl",
    placeholder: "e.g. URL of your listing on Kijiji, Realtor.ca, etc",
    type: "url",
    required: false,
    component: Field,
    grid: 6
  },
  {
    dependent: true,
    dependentOn: "advertiseUnit",
    dependentValue: "Yes",
    labelTxt: "How long have you been advertising for?",
    name: "advertiseDuration",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 6,
    options: ADVERTISE_DURATION
  },
  {
    labelTxt: "Is your property currently tenanted?",
    name: "currentlyTenanted",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 6,
    options: CURRENTLY_TENANTED
  },
  {
    labelTxt: "Anything else you want to tell us?",
    name: "extraInfo",
    placeholder: "About yourself, your rental property, its neighbourhood, ideal tenants, etc.",
    type: "text",
    required: false,
    component: TextAreas,
    grid: 12
  },
  {
    labelTxt: "Who will be showing the unit?",
    name: "showingUnit",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 6,
    options: SHOWING_UNIT
  },
  {
    labelTxt: " Would you like us to customise the lease for you?",
    name: "customiseLease",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 6,
    options: CUSTOMISE_LEASE
  }
]

export {
  fields,
  unitEditfields,
  fields2,
  unitfields,
  unitfields2,
  unitfields3,
  editFields2,
  landlordFields,
  landlordFieldswithOntario,
  landlordFieldwithLRG
}
