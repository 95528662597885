import * as React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import TagManager from "react-gtm-module"
import LinksSection from "./links-section.component"
import EmailSection from "./email-section.component"
import theme, { Colors } from "../../theme"
import Row from "../row"
import Box from "../box"
import Text from "../text"
const INSTAGRAM_ICON = "/images/instagram.svg"
const FACEBOOK_ICON = "/images/facebook.svg"
const MEDIUM_ICON = "/images/medium.svg"
const YOUTUBE_ICON = "/images/youtube.svg"

const Footer = styled(Box as any)<{ shortPage: boolean }>`
  flex-direction: column;
  width: 100%;
  background-color: ${Colors.darkGrey};
  position: ${props => (props.shortPage ? "absolute" : "relative")};
  bottom: 0;
  left: 0;
  @media (max-width: ${theme.breakpoints[1]}) {
    position: relative;
  }
`
const UpperFooter = styled(Box as any)`
  flex-direction: row;
  justify-content: space-between;
  height: 236px;
  margin-left: 82px;
  @media (max-width: ${theme.breakpoints[1]}) {
    flex-direction: column;
    justify-content: space-between;
    height: 680px;
    margin-left: 24px;
    padding-bottom: 8px;
  }
`
const HR = styled.div`
  height: 2px;
  margin: 0 8px;
  margin-bottom: 20px;
  background-color: ${Colors.textLightGrey};
  @media (max-width: ${theme.breakpoints[1]}) {
    height: 1px;
    margin-bottom: 32px;
  }
`
const LowerFooter = styled(Box as any)`
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 60px;
  @media (max-width: ${theme.breakpoints[1]}) {
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 24px;
    height: 180px;
  }
`
const RightsAndTerms = styled(Box as any)`
  flex-direction: row;
  justify-content: space-between;
  margin-left: 24px;
  width: 100%;
  flex-shrink: 1;
  font-weight: 600;
  padding-top: 4px;

  @media (max-width: ${theme.breakpoints[1]}) {
    padding-top: 0;
    flex-direction: column-reverse;
    margin: 16px 0;
    & > :first-child {
      margin-top: 16px;
    }
  }

  @media (min-width: ${theme.breakpoints[1]}) {
    width:100%;
  }
`

const Icon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 24px;
`

interface Props {
  shortPage?: boolean
}

class FooterComponent extends React.PureComponent<Props, {}> {
  componentDidMount() {
    const tagManagerArgs = {
      dataLayer: {
        path: "/",
        page: "home"
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }
  render() {
    return (
      <Footer shortPage={this.props.shortPage || false}>
        <UpperFooter>
          <LinksSection />
          <EmailSection />
        </UpperFooter>
        <HR />
        <LowerFooter>
          <Row  >
            <a
              href="https://www.instagram.com/tolobirentals/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <Icon src={INSTAGRAM_ICON} alt="instagram" />
            </a>
            <a href="https://www.facebook.com/tolobirentals/" target="_blank" rel="noreferrer">
              <Icon src={FACEBOOK_ICON} alt="facebook" />
            </a>
            <a href="https://medium.com/tolobi" target="_blank" rel="noreferrer">
              <Icon src={MEDIUM_ICON} alt="medium" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCaiXWzVBMaLo3pBxaKlROzQ"
              target="_blank"
              rel="noreferrer"
            >
              <Icon src={YOUTUBE_ICON} alt="youtube" />
            </a>
          </Row>

          <RightsAndTerms>
            <Text color={Colors.textLightGrey} fontSize={14}>
              {new Date().getFullYear()} Tolobi Group Inc.
            </Text>
            <Row width="214px" justifyContent="space-between" mr="10px" >
              <Link to="/terms" target="_blank">
                <Text color={Colors.lightGrey} fontSize={14}>
                  Terms of Service
                </Text>
              </Link>
              <Link to="/privacy" target="_blank" >
                <Text color={Colors.lightGrey} fontSize={14}>
                  Privacy Policy
                </Text>
              </Link>
            </Row>
            <Text
              width={"70%"}
              color={Colors.lightGrey} fontSize={14}
            >
              All Rights Reserved. Protected by reCAPTCHA the Google <a href="https://policies.google.com/privacy?hl=en"  target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms?hl=en"  target="_blank">Terms of
              Service</a> apply.
            </Text>
          </RightsAndTerms>
        </LowerFooter>
      </Footer>
    )
  }
}

export default FooterComponent
