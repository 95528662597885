export const USER_TYPE = [
  { value: "ADMIN", label: "Admin" },
  { value: "GUEST", label: "Tenant-Guest" },
  { value: "LANDLORD", label: "Landlord/Agent" },
  { value: "RENTER", label: "Renter" },
  { value: "UNVERIFIED", label: "Unverified" }
]

export const RENTAL_USER_TYPE = [
  { value: "LANDLORD", label: "Landlord" },
  { value: "RENTER", label: "Renter" }
]

export const RENTAL_LOCATED = [
  { value: "your property-located-in", label: "Your Property Located In" },
  { value: "you-currently-live-in", label: "You Currently Live In" }
]
export const SUBADMIN_USER_TYPE = [
  { value: "GUEST", label: "Tenant-Guest" },
  { value: "LANDLORD", label: "Landlord" },
  { value: "RENTER", label: "Renter" },
  { value: "UNVERIFIED", label: "Unverified" }
]

export const USER_ROLE = [
  { value: "ADMIN", label: "Admin" },
  { value: "SUB_ADMIN", label: "Sub-Admin" }
]

export const UTILITIES = [
  { value: "AirConditioning", label: "Air Conditioning" },
  { value: "CableTv", label: "Cable TV" },
  { value: "Electricity", label: "Electricity" },
  { value: "GarbageRemoval", label: "Garbage Removal" },
  { value: "Heating", label: "Heating" },
  { value: "Internet", label: "Internet" },
  { value: "MaintenanceFees", label: "Maintenance Fees" },
  { value: "Gas", label: "Gas" },
  { value: "Water", label: "Water" }
]

export const PREMIUM_TYPE = {
  Free: "FREE",
  Paid: "PAID"
}

export const PREMIUM_VALUE = {
  Premium: "PREMIUM"
}

export const CREDIT_OPTION = [
  { value: "Exceeds 650", label: "Exceeds 650", disabled: false },
  { value: "Is not available", disabled: false, label: "Is not available" },
  { value: "Other", disabled: false, label: "Other" }
]

export const YOU_ARE = [
  { value: "Landlord/Owner", label: "Landlord/Owner" },
  { value: "Real_Estate_Agent", label: "Real Estate Agent" },
  { value: "Lease_Assignor", label: "Lease Assignor" },
  { value: "Sub-Lessor", label: "Sub-lessor" }
]

export const VIEW_OPTION = [
  { value: "In-person", label: "In Person", disabled: false },
  { value: "Virtually", label: "Virtually", disabled: false }
]

export const TAGS = [
  { value: "CloseToTransit", label: "Close To Transit" },
  { value: "Nightlife", label: "Nightlife" },
  { value: "PurposeBuiltRentals", label: "Purpose Built Rentals" },
  { value: "ParksAndWater", label: "Parklands" },//name change to Parklands
  { value: "Students", label: "Students" },
  { value: "LuxuryRentals", label: "Luxury Rentals" },
  { value: "ShortTermRentals", label: "Short-term Rentals" },
  { value: "ProfessionallyManaged", label: "Professionally Managed" },
  { value: "OnlinePayments", label: "Online Payments" },
  { value: "OnlineApplications", label: "Online Applications" },
  { value: "PetFriendly", label: "Pet-friendly" },
  { value: "FurnishedRentals", label: "Furnished Rentals" },
]

export const LAUNDRY = [
  { value: "inUnit", label: "In-unit Laundry" },
  { value: "inBuilding", label: "In-building Laundry" },
  { value: "none", label: "None" }
]

export const NOTIFY_CRITARIA = [
  { value: true, label: "Yes please!" },
  { value: false, label: "No, thanks" }
]

export const PETS = [
  { value: "cat", label: "Cat" },
  { value: "dog", label: "Dog" },
  { value: "other", label: "Other" },
  { value: "none", label: "None" }
]

export const AMENITIES = [
  { value: "BbqPermitted", label: "Bbq Permitted" },
  { value: "BeanfieldWifi", label: "Beanfield Wifi" },
  { value: "BikeStorage", label: "Bike Storage" },
  { value: "BusinessCenter", label: "Business Center" },
  { value: "CommunalGarden", label: "Communal Garden" },
  { value: "Concierge", label: "Concierge" },
  { value: "DryCleaning", label: "Dry Cleaning" },
  { value: "Elevator", label: "Elevator" },
  { value: "GameRoom", label: "Game Room" },
  { value: "GuestSuites", label: "Guest Suites" },
  { value: "Gym", label: "Gym" },
  { value: "HotTub", label: "HotTub" },
  { value: "OnSiteLaundry", label: "On-Site Laundry" },
  { value: "PartyRoom", label: "Party Room" },
  { value: "PublicWifi", label: "PublicWifi" },
  { value: "RooftopDeck", label: "Rooftop Deck" },
  { value: "Storage", label: "Storage" },
  { value: "Sauna", label: "Sauna" },
  { value: "SwimmingPool", label: "Swimming Pool" },
  { value: "Theatre", label: "Theatre" },
  { value: "VisitorsParking", label: "Visitors Parking" },
  { value: "WheelchairAccess", label: "Wheel Chair Access" },
  { value: "Yard", label: "Yard" },
  { value: "PetSpa", label: "Pet Spa" },
  { value: "MediaRoom", label: "Media Room" },
  { value: "BasketballCourt", label: "Basketball Court" },
  { value: "YogaRoom", label: "Yoga Room" },
  { value: "TennisCourt", label: "Tennis Court" },
  { value: "RooftopTerrace", label: "Rooftop Terrace" },
  { value: "CoworkingSpace", label: "Coworking Space" },
  { value: "ConferenceRoom", label: "Conference Room" },
  { value: "Patio", label: "Patio" },
  { value: "Lounge", label: "Lounge" }
]

export const UNIT_AMENITIES = [
  { value: "Ac", label: "Air Conditioner" },
  { value: "BathroomFan", label: "Bathroom Fan" },
  { value: "Balcony", label: "Balcony" },
  { value: "CentralHeat", label: "Central Heating" },
  { value: "Carpet", label: "Carpet" },
  { value: "CeilingFan", label: "Ceiling Fan" },
  { value: "ElectricalHeating", label: "Electrical Heating" },
  { value: "Fireplace", label: "Fireplace" },
  { value: "HardwoodFloor", label: "Hardwood Floor" },
  { value: "HighCeilings", label: "High Ceilings" },
  { value: "RadiatorHeating", label: "Radiator Heating" },
  { value: "WalkInCloset", label: "Walk-In Closet" }
]

export const UNIT_APPLIANCES = [
  { value: "Fridge", label: "Fridge" },
  { value: "stove", label: "Stove" },
  { value: "Rangehood", label: "Range Hood" },
  { value: "Dishwasher", label: "Dishwasher" },
  { value: "Microwave", label: "Microwave" },
  { value: "inunitwasher", label: "In-unit Washer" },
  { value: "inunitdryer", label: "In-unit Dryer" },
  { value: "wineFridge", label: "Wine Fridge" },
  { value: "Dehumidifier", label: "Dehumidifier" },
  { value: "firePlaceElectric", label: "Fireplace-Electric" },
  { value: "firePlaceGas", label: "Fireplace-Gas" },
  { value: "Jacuzzi", label: "Jacuzzi" }
]

export const TENANT_REQUIREMENTS = [
  { value: "exceptionalCredit", label: "Credit Check" },  // name change to Credit Check
  // { value: "extraDocumentation", label: "Extra Documentation" },
  { value: "letterOfEmployment", label: "Proof of Income" },// name change to Proof of Income
  { value: "noSmoking", label: "No Smoking" },
  { value: "pastReferences", label: "Landlord Reference" },// name change to Landlord Reference
  { value: "photoId", label: "Photo ID" },
  // { value: "payStub", label: "Pay Stub" }
]

export const BEDROOMS = [
  { value: "ROOM_SHARED_HOME", label: "Room/Shared Home" },
  { value: "STUDIO", label: "Bachelor/Studio" },
  { value: "ONE_BEDROOM", label: "1" },
  { value: "TWO_BEDROOMS", label: "2" },
  { value: "THREE_BEDROOMS", label: "3" },
  { value: "FOUR_OR_MORE_BEDROOMS", label: "4" },
  { value: "FIVE_BEDROOMS", label: "5" },
  { value: "SIX_BEDROOMS", label: "6" },
  { value: "SEVEN_BEDROOMS", label: "7" },
  { value: "EIGHT_BEDROOMS", label: "8" },
  { value: "NINE_BEDROOMS", label: "9" },
  { value: "TEN_BEDROOMS", label: "1O" }
]

export const BATHROOMS = [
  { value: 1, label: "1" },
  { value: 1.5, label: "1.5" },
  { value: 2, label: "2" },
  { value: 2.5, label: "2.5" },
  { value: 3, label: "3" },
  { value: 3.5, label: "3.5" },
  { value: 4, label: "4" },
  { value: 4.5, label: "4.5" },
  { value: 5, label: "5" },
  { value: 5.5, label: "5.5" },
  { value: 6, label: "6" },
  { value: 6.5, label: "6.5" },
  { value: 7, label: "7" },
  { value: 7.5, label: "7.5" },
  { value: 8, label: "8" },
]

export const PROPERTY_TYPE = [
  { value: "APARTMENT", label: "Apartment" },
  { value: "BASEMENT", label: "Basement" },
  { value: "CONDO", label: "Condo" },
  { value: "HOUSE_OR_TOWNHOUSE", label: "House" },
  { value: "TOWNHOUSE", label: "Townhouse" },
  { value: "ROOM", label: "Room" },
  { value: "OTHER", label: "Other" }
]

export const DEN = [
  { value: true, label: "Yes" },
  { value: false, label: "None" }
]

export const AVAILABLE = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }
]

export const FURNISHED = [
  { value: "unFurnished", label: "No, unfurnished" },
  { value: "furnished", label: "Yes, furnished" }
]

export const STATUS_OF_PROPERTY = [
  { value: true, label: "True" },
  { value: false, label: "False" }
]

export const PARKING = [
  { value: "ASSIGNED", label: "Assigned" },
  { value: "SHARED", label: "Shared" },
  { value: "GARAGE", label: "Garage" },
  { value: "OTHER", label: "Other" },
  { value: "NONE", label: "None" }
]

export const ARTICLE_TYPE = [
  { value: "TRENDING", label: "Trending" },
  { value: "ESTATE_SCOOP", label: "Estate Scoop" }
]

export const TU_FOUND = [
  {
    value: "Airbnb",
    label: "Airbnb"
  },
  {
    value: "Currenttenant",
    label: "Current Tenant"
  },
  {
    value: "Facebookmarketplace",
    label: "Facebook Marketplace"
  },
  {
    value: "Kijiji",
    label: "Kijiji"
  },
  {
    value: "Offlinemarketing",
    label: "Offline Marketing"
  },

  {
    value: "Realtor.ca/mls",
    label: "Realtor.ca/MLS"
  },
  {
    value: "Reference",
    label: "Reference"
  },
  {
    value: "Rentfaster",
    label: "RentFaster"
  },
  {
    value: "Torontorentals.com/rentals.ca",
    label: "TorontoRentals.com/Rentals.ca"
  },

  {
    value: "Viewit.ca",
    label: "Viewit.ca"
  },
  {
    value: "Zillow",
    label: "Zillow"
  },
  {
    value: "Zumper/padmapper",
    label: "Zumper/PadMapper"
  }
]

export const STATUS = [
  { value: true, label: "True" },
  { value: false, label: "False" }
]

export const REGION = {
  Ontorio: "Ontario"
}
