import ApolloClient from "apollo-client"
import { GET_LISTINGS, SEND_EMAIL, REQUEST_LISITNG_COUNT } from "../graphql/query"
import { NormalizedCacheObject } from "apollo-cache-inmemory"

export const fetchListing = async (skipNo, client: ApolloClient<NormalizedCacheObject>) => {
  try {
    const { data } = await client.query({
      query: GET_LISTINGS,
      fetchPolicy: "no-cache",
      variables: {
        first: 10,
        skip: skipNo
      }
    })
    return data
  } catch (err) {
    console.log("Error", err)
  }
}
export const fetchRequestListingCount = async client => {
  try {
    const { data } = await client.query({
      query: REQUEST_LISITNG_COUNT
    })
    return data
  } catch (err) {
    console.log("Error", err)
  }
}

export const sendEmailToLandlord = async (id, query, value) => {
  try {
    const { data } = await query({
      query: SEND_EMAIL,
      variables: {
        data: {
          value,
          id
        }
      }
    })
    return data
  } catch (err) {
    console.log("Error", err)
  }
}
