import React, { useCallback, useState } from "react"
import { Link } from "react-router-dom"
import { UserType } from "src/generated/graphql"
import { timeStamp } from "src/dashboards/components/stamptime-converter"
import styled from "styled-components"
import { Modal } from "antd"
import "antd/lib/modal/style/css"
import SelectComponent from "src/dashboards/components/form-container-field/select-component"
import { updateUserType } from "src/services"
import { AsYouType } from "libphonenumber-js"

function getCountryCode(input: string) {
  const defaultCountryCode = input?.substr(0, 1) !== "+" ? "US" : "IN"
  let formatted = new AsYouType(defaultCountryCode)?.input(input)
  let countryCode: undefined | string = ""
  let withoutCountryCode = formatted
  if (defaultCountryCode === "US") {
    countryCode = "+1"
    formatted = "+" + formatted
  } else {
    const parts = formatted.split("")
    countryCode = parts.length > 1 ? parts.shift() : ""
    withoutCountryCode = parts.join("")
  }
  return {
    formatted,
    withoutCountryCode,
    countryCode
  }
}

const USER_TYPE = [
  { value: "ADMIN", label: "Admin" },
  { value: "SUB_ADMIN", label: "Sub Admin" },
  { value: "LANDLORD", label: "Landlord" },
  { value: "RENTER", label: "Renter" },
  { value: "UNVERIFIED", label: "Unverified" }
]

export const RenderList = styled.div<any>`
  margin-left: ${props => props.marginLeft}px;
  cursor: ${props => (props.cursor ? props.cursor : "auto")};
`

const Text = styled.h1<any>`
  font-size: 22px;
  text-align: center;
  line-height: 25px;
  font-family: "Source Sans Pro", sans-serif;
  margin-bottom: 2rem;
`

export const formatPhoneNumber = phone => {
  const number = phone?.substring(1)
  const cleaned = ("" + number)?.replace(/\D/g, "")
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3]
  }

  return number
}

export const CreateDateCell = props => {
  const creatAtDate = timeStamp(props.createdAt)
  return <RenderList marginLeft={12}>{creatAtDate}</RenderList>
}

export const OfInquirieCell = props => {
  return (
    <>
      {!props.verified ? (
        <span className="available-false">
          <span className="available-false">Not Verified</span>
        </span>
      ) : (
        <span className="available-true">
          <span className="available-true">Verified</span>
        </span>
      )}
    </>
  )
}

export const UserTypeList = props => {
  const { userType, email, client, fetchUsers, user } = props

  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [value, setValue] = useState<string>("")

  const renderUserType = useCallback((userType: string) => {
    switch (userType) {
      case UserType.Admin:
        return "Admin"
      case UserType.Landlord:
        return "Landlord"
      case UserType.Renter:
        return "Tenant"
      case UserType.Guest:
        return "Tenant-Guest"
      default:
        return "Unverified"
    }
  }, [])

  const onChangeUserType = (event: { value: string; label: string }) => {
    setValue(event.value)
  }

  const handleOk = async () => {
    setLoading(true)

    const resp = await updateUserType(client, {
      email,
      userType: value
    })
    if (resp && resp.data) {
      setVisible(false)
      if (fetchUsers) fetchUsers()
    }
    setLoading(false)
  }

  const openModal = () => {
    if (UserType.Admin === user.userType) setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }
  return (
    <>
      <RenderList marginLeft={8} cursor={"pointer"} onClick={openModal}>
        {renderUserType(userType)}
      </RenderList>

      <Modal
        title={"Update User Type"}
        centered
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Confirm"
        okType="danger"
        okButtonProps={{ disabled: loading, loading }}
        cancelButtonProps={{ disabled: loading }}
        closeIcon={<></>}
      >
        <>
          <Text>Are you sure? you want to change the user type</Text>
          <SelectComponent
            id="userType"
            name="userType"
            defaultValue={{
              label: renderUserType(userType),
              value: userType
            }}
            onChange={onChangeUserType}
            placeholder="Select user type"
            options={USER_TYPE}
          />
        </>
      </Modal>
    </>
  )
}

export const FirstName = props => {
  const { firstName } = props
  return <RenderList marginLeft={12}>{firstName && firstName}</RenderList>
}

export const PhoneNo = props => {
  const { phone } = props
  let phoneNo: { formatted: string } = { formatted: "" }
  if (phone) {
    phoneNo = getCountryCode(phone)
  }
  return <span>{phoneNo?.formatted!}</span>
}

export const LastName = props => {
  const { lastName } = props
  return <RenderList marginLeft={12}>{lastName ?? ""}</RenderList>
}

export const Listings = props => {
  const {
    params: {
      row: { userType, firstName, lastName, id, listing }
    }
  } = props

  return (
    <div className="admin-see-listing">
      {userType === UserType.Landlord && listing && listing.length > 0 && (
        <Link
          to={{
            pathname: `/users/listing/${id}`,
            state: {
              firstName: firstName,
              lastName: lastName
            }
          }}
        >
          <span style={{ color: "green" }}>View Listings</span>
        </Link>
      )}
      {userType === UserType.Landlord && listing && listing.length === 0 && (
        <span style={{ color: "red", cursor: "auto" }}>No Listings Found</span>
      )}
      {userType === UserType.Admin && listing && listing.length > 0 && (
        <Link
          to={{
            pathname: `/users/listing/${id}`,
            state: {
              firstName: firstName,
              lastName: lastName
            }
          }}
        >
          <span style={{ color: "green" }}>View Listings</span>
        </Link>
      )}

      {userType === UserType.Admin && listing === 0 && (
        <span style={{ color: "red", cursor: "auto" }}>No Listings Found</span>
      )}
    </div>
  )
}
