import React from "react"
import { Formik, Form, Field, FormikProps } from "formik"
import { Button, Grid } from "@material-ui/core"
import "./styles.scss"
import { FieldType } from "./types"
import { formProps } from "./types"
import { createSchema } from "./utils"

const FormFieldsContainer = ({ fields, renderCustomSubmit, btnText, giveButtonsFormikActions = false, ...rest }: formProps & FormikProps<any>) => {
  React.useEffect(() => {
    if (rest.values && rest.onSelectField) rest.onSelectField(rest.values)
  }, [rest])

  let CustomButton = <Button type="submit">{btnText}</Button>;

  if (renderCustomSubmit) {
    CustomButton = renderCustomSubmit;

    // in case we imperatively need to call submit event from a button that doesn't have type === 'submit'
    // see LandlordForm for example
    if (giveButtonsFormikActions)
      CustomButton = React.cloneElement(renderCustomSubmit, {
        __formikActions: rest,
      })
  }

  const renderField = (props: FieldType, handleChange) => {
    let { client, edit, values, } = rest

    // hide dependent field completely in case matches and disableDependentFieldInsteadOfHiding isn't set.
    if (props.dependent && props.dependentOn && values[props.dependentOn] !== props.dependentValue && !props.disableDependentFieldInsteadOfHiding)
      return null;

    // else show it disabled in case condition matches
    const isDisabled = Boolean(props.disableDependentFieldInsteadOfHiding && props.dependent && props.dependentOn && values[props.dependentOn] !== props.dependentValue);
    return (
      <Grid item xs={props.grid || 12} key={props.name} alignContent={props.alignment} container={Boolean(props.alignment)}>
        <Field
          key={props.name}
          {...props}
          client={client}
          disabled={isDisabled}
          style={isDisabled ? {
            cursor: "not-allowed", color: "transparent",
            backgroundColor: "transparent"
          } : null}
          edit={edit}
          onChange={e => {
            if (rest.onFormChange) rest.onFormChange()
            handleChange(e)
          }}
        />
      </Grid>
    )
  }

  return (
    <Form>
      <React.Fragment>
        <Grid container spacing={1}>
          {fields.map(value => renderField(value, rest.handleChange))}
        </Grid>
        {CustomButton}
      </React.Fragment>
    </Form>
  )
}

class FormContainer extends React.PureComponent<formProps & FormikProps<any>> {
  renderForm = (props) => {
    const { fields, renderCustomSubmit, btnText, giveButtonsFormikActions = false } = this.props
    return (
      <FormFieldsContainer
        {...this.props}
        {...props}
        fields={fields}
        renderCustomSubmit={renderCustomSubmit}
        btnText={btnText}
        giveButtonsFormikActions={giveButtonsFormikActions}
      />
    )
  }

  render() {
    let { onSubmit, maxWidth, fields } = this.props
    const schema = createSchema(fields)
    return (
      <div style={{ maxWidth: maxWidth || "auto", justifyContent: "center" }}>
        <Formik
          initialValues={this.props.initialValues}
          onSubmit={onSubmit}
          validationSchema={schema}
          enableReinitialize
        >
          {this.renderForm}
        </Formik>
      </div>
    )
  }
}

export default FormContainer
