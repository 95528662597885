import gql from "graphql-tag"

export const BLOG_LIST = gql`
  query blogs($where: BlogWhereInput) {
    blogCount(where: $where) {
      count
    }
    blogs(where: $where) {
      id
      title
      seen
      thumbnailUrl
      tag {
        id
        name
      }
      description
      createdAt
      updatedAt
      isPublished
      avgReadingTime
      user{
        id
      phone
      firstName
      lastName
      email
      avatarUrl
      city
      industry
      bio
      createdAt
      role
      userType
      }
    }
  }
`

export const SEARCH_BLOG_LIST = gql`
  query searchBlogs($where: BlogWhereInput) {
    blogCount(where: $where) {
      count
    }
    searchBlogs(where: $where) {
      id
      title
      tag {
        name
      }
      subtitle
      createdAt
      updatedAt
      thumbnailUrl
      seen
      description
      isPublished
      avgReadingTime
    }
  }
`

export const TAGS = gql`
  query getTags($where: BlogWhereInput) {
    getTags(where: $where) {
      tags
    }
  }
`

export const ANALATIC_BLOG_VIEW = gql`
  query analyticBlogView {
    analyticBlogView {
      totalPublished
      totalPerDayPublished
      totalSeen
      totalAvgSeen
      totalReadingAvg
      totalPopularTag {
        name
        id
        mostUsed
      }
      totalPopularTagClick {
        name
        id
        mostClick
      }
    }
  }
`

export const SEARCH_TAG = gql`
  query searchTags($where: TagWhereInput!) {
    searchTags(where: $where) {
      name
      id
    }
  }
`
