import styled from "styled-components"
const DEFAULT_PROFILE_PICTURE: string = "/images/default-profile-picture.png"

interface Props {
  size?: string
  type?: string
  imageURL?: null | string
}

const mapSizeToProps = ({ size }: Props) => {
  switch (size) {
    case "x-large":
      return `
      width: 200px;
      height: 200px;
      border-radius: 5000px;
      `
    case "large":
      return `
        width: 128px;
        height: 128px;
        border-radius: 5000px;
      `
    case "small":
      return `
        width: 48px;
        height: 48px;
        border-radius: 5000px;
        cursor: pointer;
      `
    case "blog":
      return `
        height: 35px;
        border-radius: 5000px;
        cursor: pointer;
        border:none !important
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat ;
      `
    case "author":
      return `
      width: 100%;
      height: 100%;
      border-radius: 5000px;
      cursor: initial;
      `
    default:
      return `
        width: 64px;
        height: 64px;
        border-radius: 5000px;
        
      `
  }
}

const withBorder = ({ type }: Props) => {
  switch (type) {
    case "withBorder":
      return `
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
        border: solid 2px #ffffff;
      `

    default:
      return `
        border: none;
      `
  }
}

const Avatar = styled.div<Props>`
  ${mapSizeToProps};
  ${withBorder};
  background-image: url(${props => (props.imageURL ? props.imageURL : DEFAULT_PROFILE_PICTURE)});
  background-size: cover;
  background-position: center center;
  border: 2px solid #ffffff;
`

export default Avatar
