import gql from "graphql-tag"

export const SEARCH_BUILDING = gql`
  query searchBuilding($search: String!, $skip: Int, $take: Int) {
    searchBuilding(search: $search, skip: $skip, take: $take) {
      id
      title
      seen
      createdAt
      isHidden
      activate
      isapproved
      updatedAt
      premiumStart
      premiumEnd
      premiumName
      user {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
      units {
        id
        title
        number
        available
      }
      location {
        id
        neighbourHood {
          id
          city {
            id
            name
          }
        }
      }
    }
  }
`
