import React, { memo, useCallback } from "react"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"
import EditIcon from "src/dashboards/images/edit.png"
import { Add, ArrowForwardIos } from "@material-ui/icons"
import { images } from "src/assets"
import DeleteIcon from "src/dashboards/images/delete.png"
import { useHistory } from "react-router"
import ConfirmModal from "src/components/confirmModal"
import { deleteHelpCenter } from "src/dashboards/screens/help-center/utils/service"
import { notify } from "src/components/notification-toast"
import { MESSAGE, NOTIFICATION_MSG } from "src/constants/notification-mesage"
import { Tooltip } from "antd"
import { Link } from "react-router-dom"
import Image from "src/components/image"

interface Props {
  openModal: (id: string, edit?: string, collectionId?: string) => void
  id: string
  client: ApolloClient<NormalizedCacheObject>
  init: () => void
  title: string
  subAdminAccess: any
}

const ActionTab = (props: Props) => {
  const { openModal, id, client, init, title, subAdminAccess } = props
  const history = useHistory()

  const saveToStore = () => {
    localStorage.setItem("title", title)
  }

  const onOk = useCallback(async () => {
    const resp = await deleteHelpCenter(client, id)
    if (resp && resp.data) {
      if (typeof init === "function") init()
      notify(NOTIFICATION_MSG.Success, "Deleted Successfully!")
    } else {
      notify(NOTIFICATION_MSG.error, MESSAGE.SomethingWrong)
    }
  }, [id])

  const gotoHelpCenterGroup = () => {
    history.push({
      pathname: `/admin-help-center/${id}`,
      state: {
        title
      }
    })
  }
  const handleNoAccess = () => {
    notify(NOTIFICATION_MSG.error, MESSAGE.NoAccess)
  }

  const handleEditOpenModel = (id, Action) => {
    if (subAdminAccess?.["edit"]) openModal(id, Action)
    else notify(NOTIFICATION_MSG.error, MESSAGE.NoAccess)
  }

  const handleAddOpenModel = (id, Action, ids) => {
    if (subAdminAccess?.["add"]) openModal(id, Action, ids)
    else notify(NOTIFICATION_MSG.error, MESSAGE.NoAccess)
  }
  return (
    <>
      <div onClick={() => handleEditOpenModel(id, "EDIT")} className="div-edit">
        <img loading="lazy" src={EditIcon} alt="edit" />
      </div>
      <Tooltip placement="bottom" title={"View help center"}>
        <Link to={`/help-center/collection/${id}`} target="_blank">
          <div className="div-view" onClick={saveToStore}>
            <Image src={images.ViewIcon} />
          </div>
        </Link>
      </Tooltip>

      {subAdminAccess?.["delete"] ? (
        <ConfirmModal onOk={onOk} title="Are you sure to delete this article?">
          <div className="div-view">
            <Image src={DeleteIcon} />
          </div>
        </ConfirmModal>
      ) : (
        <div className="div-view" onClick={handleNoAccess}>
          <Image src={DeleteIcon} />
        </div>
      )}

      <div onClick={() => handleAddOpenModel("2", "ADD", id)} className="div-addmore">
        <Add />
        Add more
      </div>
      <Tooltip placement="bottomLeft" title={"Go help center group"}>
        <div className="div-addmore" style={{ border: "none" }} onClick={gotoHelpCenterGroup}>
          <ArrowForwardIos />
        </div>
      </Tooltip>
    </>
  )
}

export default memo(ActionTab)
