import React from "react"
import { FormControl } from "@material-ui/core"
import Text from "../../text"
import TextArea from "../../../components/text-area"

const TextAreas = ({
  icon,
  form: { touched, errors, setFieldValue },
  field,
  labelTxt,
  styleInput = {},
  ...rest
}) => {

  return (
    <FormControl id="custom-form" fullWidth style={{ marginBottom: 15 }}>
      <Text genre="labelText">{labelTxt}</Text>
      <TextArea
        id={field.name}
        required={true}
        height={40}
        padding="0 0 0 10px"
        {...field}
        {...rest}
        {...styleInput}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="error-text">
          {rest.create_err_msg ? rest.create_err_msg(field.value) : errors[field.name]}
        </div>
      )}
    </FormControl>
  )
}

export default TextAreas
