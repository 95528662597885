import React from "react"
import { useState } from "react"
import TextField from "@material-ui/core/TextField"
import { Autocomplete } from "@material-ui/lab"
import "./style.scss"
import Text from "src/components/text"
import { makeStyles } from "@material-ui/core"

const ACCESS_TOKEN = process.env.REACT_APP_AUTOCOMPLETE_MAPBOX_TOKEN
const BASE_URL = "https://api.mapbox.com/geocoding/v5/mapbox.places/"
var preventApis = false

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      fontSize: "16px",
      fontWeight: 500
    }
  }
})

const SearchMap = ({
  form: { touched, errors, setFieldValue, setTouched },
  field,
  labelTxt,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState("")
  const [features, setFeatures] = useState([])
  const classes = useStyles()

  const searchRegion = query => {
    if (preventApis) return (preventApis = false)
    if (!query) return setFeatures([])
    const url = `${BASE_URL}${query}.json?access_token=${ACCESS_TOKEN}&limit=6&country=CA`

    fetch(url)
      .then(res => res.json())
      .then(res => {
        setFeatures(res?.features || [])
      })
      .catch(err => {
        console.log("error", err)
      })
  }

  const onInputChange = evt => {
    setInputValue(evt.target.value)
    searchRegion(evt.target.value)
  }

  const onSelect = address => {
    let lats = parseFloat(address.center[1])
    let lngs = parseFloat(address.center[0])
    const neighborhood = address.context.find(item => item.id.split(".")[0] === "neighborhood")
    const locality = address.context.find(item => item.id.split(".")[0] === "locality")
    const region = address.context.find(item => item.id.split(".")[0] === "region")
    const city = address.context.find(item => item.id.split(".")[0] === "place")
    setFieldValue(field.name, {
      title: address.place_name,
      geometry: { lats, lngs },
      city: city?.text,
      region: region?.text,
      neighborhood: neighborhood
        ? neighborhood["text"]
        : locality
          ? locality["text"]
          : region["text"]
    })
    preventApis = true
    setInputValue(address.place_name)
    setFeatures([])
  }

  const handleAutoChange = (event, value) => {
    const filterList = features.find((list: any) => list.place_name === value)
    onSelect(filterList)
  }

  return (
    <>
      <div id="place-picker">
        {labelTxt && <Text genre="labelText">{labelTxt}</Text>}
        <div style={{ height: 50 }}>
          <Autocomplete
            disableClearable
            loadingText={"Loading..."}
            openOnFocus={true}
            onChange={handleAutoChange}
            value={field?.value?.address ? field?.value?.address : inputValue}
            options={features.map((option: any) => option.place_name)}
            renderInput={params => {
              return <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                placeholder={"Address"}
                onChange={onInputChange}
                classes={{ root: classes.customTextField }}
                required
                onBlur={() => {
                  if (!inputValue) {
                    preventApis = true
                    setFeatures([])
                    setTouched({ ...touched, [field.name]: true })
                  }
                }}
                InputProps={{ ...params.InputProps, type: "search" }}
              />
            }
            }
          />
        </div>
        {errors[field.name] && touched[field.name] && (
          <div className="error-text">
            {rest.create_err_msg ? rest.create_err_msg(field.value) : errors[field.name]}
          </div>
        )}
      </div>
    </>
  )
}

export default SearchMap
