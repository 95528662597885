import React from "react"
import styled from "styled-components"
import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"
import { TagsType } from "src/dashboards/screens/blogs/analytics-views"
import Row from "src/components/row"

export const Tags = styled.p`
  font-size: 16px;
  background: var(--third-color);
  color: #fff;
  border-radius: 9px;
  padding: 0px 10px;
  margin-inline: 4px;
  font-weight: bold;
  cursor: pointer;
`

const CardComponent = styled.div<any>`
    min-width:180px
    min-height:140px;
    height:100%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border: solid 2px #ffffff;
    border-radius:8px;
    background-color:#fff;
    display: flex
    justify-content:center;
    align-items: center;
    flex-direction:column;
    margin-block:1rem;
    margin-left:1rem;
    padding:10px
`

const CountHeading = styled.h2`
  font-size: 30px;
  color: var(--third-color);
`

const CountTag = styled.p`
  margin-top: 0;
  margin-bottom: 0.5em;
  text-align: center;
`

const TitleHeading = styled.h4`
  font-size: 18px;
  color: var(--ten-color);
`

interface Props {
  count?: number
  title: string
  loading: boolean
  isTag?: boolean
  tags?: TagsType[]
  isMostUsed?: boolean
}

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "green" }} spin />

const BlogCard = (props: Props) => {
  const { count, title, loading, isTag, tags, isMostUsed = false } = props
  return (
    <CardComponent>
      {!loading ? (
        <>
          {!isTag ? (
            <CountHeading>{count || 0}</CountHeading>
          ) : (
            <Row flexWrap="wrap" justifyContent="center">
              {tags?.map((item: TagsType) => (
                <>
                  <Tags key={item.id}>
                    {item.name}
                    <CountTag>{isMostUsed ? item?.mostUsed : item?.mostClick}</CountTag>
                  </Tags>
                </>
              ))}
            </Row>
          )}
        </>
      ) : (
        <Spin indicator={antIcon} />
      )}
      <TitleHeading>{title}</TitleHeading>
    </CardComponent>
  )
}

export default BlogCard
