import React from "react"
import { Link } from "react-router-dom"
import { History } from "history"

import AuthenticationState from "../../hoc/authentication/authentication.state"
import { loginRequest } from "../../hoc/authentication/lock"
import MobileMenu from "../mobile-menu"
import { MenuLink } from "./menu-link"
import { UserType } from "src/generated/graphql"

interface MobileNotLoggedInProps {
  authState: AuthenticationState
  handleMenuToggle: () => void
  history: History
  handleSearchClick: () => any
  handleListWithUs?: () => void
}

export const MobileNotLoggedIn: React.FC<MobileNotLoggedInProps> = ({
  authState,
  handleMenuToggle,
  history,
  handleSearchClick,
  handleListWithUs
}) => {
  return (
    <MobileMenu>
      <Link onClick={handleListWithUs} to="/list-with-us">
        <MenuLink>List Your Property</MenuLink>
      </Link>
      <Link onClick={handleMenuToggle} to="/knowledge-center">
        <MenuLink>Knowledge Center</MenuLink>
      </Link>
      <MenuLink onClick={handleSearchClick}>Explore Homes</MenuLink>
      <Link onClick={handleMenuToggle} to="/services">
        <MenuLink>Services</MenuLink>
      </Link>
      <MenuLink onClick={async () => await loginRequest(true, false, authState)}>Log In</MenuLink>
      <MenuLink onClick={() => loginRequest(true, true, authState)}>Sign Up</MenuLink>
    </MobileMenu>
  )
}
