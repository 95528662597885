import React, { useState, useRef, useEffect } from "react"
import { NavLink } from "react-router-dom"
import { images, icons } from "src/assets"
import { FetchCurrentUserMe, UserType } from "src/generated/graphql"
import { Tooltip } from "antd"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"
import { fetchRequestListingCount } from "src/dashboards/screens/requestListing/utils/services"
import {
  dashboardActive,
  dashboardInActive,
  buildingActive,
  buildingInActive,
  userActive,
  userInActive,
  planActive,
  planInActive,
  feature,
  article,
  profile,
  profileInActive,
  message,
  HelpCenter,
  EnvelopMsg,
  MapImg,
  RentalLawsImg,
  Search
} from "./image"
import "./style.scss"

interface Props {
  user: FetchCurrentUserMe | null
  client: ApolloClient<NormalizedCacheObject>
}

const SideBar = ({ user, client }: Props) => {
  const [sidebar, setSidebar] = useState(false)
  const [cursorInSidebar, setCursorInSidebar] = useState(false)
  const [count, setCount] = useState(0)

  const showSidebar = isOpen => {
    setSidebar(!isOpen)
    setCursorInSidebar(false)
  }

  async function fetchMyAPI() {
    if (user && (user?.userType === UserType.Admin || user?.userType === UserType.SubAdmin)) {
      const resp = await fetchRequestListingCount(client)
      if (resp) setCount(resp.requestLisitngCount?.count)
    }
  }

  useEffect(() => {
    fetchMyAPI()
  }, [])

  const sidebarRef = useRef(null)
  const enterSideBar = () => {
    setCursorInSidebar(true)
  }

  const leaveSideBar = () => {
    setCursorInSidebar(false)
  }

  const isOpen = sidebar || cursorInSidebar
  const url = window.location.pathname.split("/")[1]

  const adminSidebarRoute = [
    {
      id: "id1",
      path: "dashboards",
      name: "Dashboard",
      image: dashboardInActive,
      inActive: dashboardActive,
      userType: UserType.Admin || UserType.SubAdmin
    },
    {
      id: "id2",
      path: "listings",
      name: "Properties",
      image: buildingInActive,
      inActive: buildingActive,
      userType: UserType.Admin || UserType.SubAdmin
    },
    {
      id: "id3",
      path: "requestedListing",
      name: "Requested Properties",
      count: count,
      image: buildingInActive,
      inActive: buildingActive,
      userType: UserType.Admin
    },
    {
      id: "id4",
      count: 0,
      path: "boostListing",
      name: "Boost Properties",
      image: buildingInActive,
      inActive: buildingActive,
      userType: UserType.Admin
    },
    {
      id: "id5",
      path: "internal-properties",
      count: 0,
      name: "Properties Map Tracker",
      image: MapImg,
      inActive: MapImg,
      userType: UserType.Admin
    },
    {
      id: "id6",
      path: "users",
      count: 0,
      name: "Users",
      image: userInActive,
      inActive: userActive,
      userType: UserType.Admin
    },
    {
      id: "id7",
      path: "plans",
      count: 0,
      name: "Plans",
      image: planInActive,
      inActive: planActive,
      userType: UserType.Admin
    },
    {
      id: "id8",
      path: "features",
      name: "Features",
      count: 0,
      image: feature,
      inActive: feature,
      userType: UserType.Admin
    },

    {
      id: "id9",
      path: "articles",
      name: "Articles",
      count: 0,
      image: article,
      inActive: article,
      userType: UserType.Admin
    },
    {
      id: "id10",
      path: "admin-rental-laws",
      name: "Rental Laws",
      count: 0,
      image: RentalLawsImg,
      inActive: RentalLawsImg,
      userType: UserType.Admin
    },
    {
      id: "id11",
      path: "adminInbox",
      name: "Messages",
      count: 0,
      image: message,
      inActive: message,
      userType: UserType.Admin
    },
    {
      id: "id12",
      path: "profile",
      count: 0,
      name: "Profile",
      image: profileInActive,
      inActive: profile,
      userType: UserType.Admin
    },
    {
      id: "id13",
      path: "admin-help-center",
      name: "Help Center",
      image: HelpCenter,
      count: 0,
      inActive: HelpCenter,
      userType: UserType.Admin
    },
    {
      id: "id14",
      path: "admin-blogs",
      name: "Blogs",
      count: 0,
      image: images.GettingStartIcon,
      inActive: images.GettingStartIcon,
      userType: UserType.Admin
    },
    {
      id: "id15",
      path: "search-history",
      name: "Property Search History",
      count: 0,
      image: Search,
      inActive: Search,
      userType: UserType.Admin
    },
    {
      id: "id15",
      path: "rental-application-details",
      name: "Rental Application",
      count: 0,
      image: icons.vector,
      inActive: icons.vector,
      userType: UserType.Admin
    },
    {
      id: "id16",
      path: "e-mail-configuration",
      name: "E-Mail Configuration",
      count: 0,
      image: EnvelopMsg,
      inActive: EnvelopMsg,
      userType: UserType.SubAdmin
    },
    {
      id: "id17",
      path: "lease-details-table",
      name: "Lease Details",
      count: 0,
      image: icons.vector,
      inActive: icons.vector,
      userType: UserType.SubAdmin
    }
  ]

  const landlordSidebarRoute = [
    {
      id: "id2",
      path: "building",
      name: "Properties",
      image: buildingInActive,
      inActive: buildingActive,
      userType: UserType.Landlord
    },
    {
      id: "id12",
      path: "message",
      name: "Message",
      image: message,
      inActive: message,
      userType: UserType.Landlord
    },
    {
      id: "id12",
      path: "profile",
      count: 0,
      name: "Profile",
      image: profileInActive,
      inActive: profile,
      userType: UserType.Landlord
    }
  ]

  const rentalSidebarRoute = [
    {
      id: "id12",
      path: "message",
      name: "Message",
      image: message,
      inActive: message,
      userType: UserType.Renter
    },
    {
      id: "id12",
      path: "profile",
      count: 0,
      name: "Profile",
      image: profileInActive,
      inActive: profile,
      userType: UserType.Renter
    }
  ]

  return (
    <div id="drawer-sidebar">
      <div className="drawer-sidebar-main">
        <nav
          ref={sidebarRef}
          style={{
            position: cursorInSidebar && !sidebar ? "relative" : "relative"
          }}
          className={isOpen ? "sidebar inactive" : "sidebar"}
          data-sidebaropen={isOpen}
          onMouseEnter={enterSideBar}
          onMouseLeave={leaveSideBar}
        >
          <div className="sidebar-content">
            <ul data-open={isOpen} onClick={() => showSidebar(isOpen)}>
              {user &&
                (user.userType === UserType.Admin || user.userType === UserType.SubAdmin) &&
                adminSidebarRoute.map(route => (
                  <NavLink to={`/${route.path}`} key={route.path}>
                    <Tooltip placement="bottomRight" title={route.name}>
                      <div className={`slider-item ${url === route.path && url}`} key={route.id}>
                        {url === route.path ? (
                          <div className="listing-indicator">
                            <img loading="lazy" src={route.inActive} alt="" />
                            {isOpen && route.count !== 0 && <span> {route.count}</span>}
                          </div>
                        ) : (
                          <div className="listing-indicator">
                            <img loading="lazy" src={route.image} alt="" />
                            {isOpen && route.count !== 0 && <span> {route.count}</span>}
                          </div>
                        )}
                        <h3 className="slider-item-open">{route.name}</h3>
                      </div>
                    </Tooltip>
                  </NavLink>
                ))}
              {user &&
                user.userType === UserType.Landlord &&
                landlordSidebarRoute.map(route => (
                  <NavLink to={`/${route.path}`} key={route.path}>
                    <Tooltip placement="bottom" title={route.name}>
                      <div className={`slider-item ${url === route.path && url}`}>
                        {url === route.path ? (
                          <img loading="lazy" src={route.inActive} alt="" />
                        ) : (
                          <img loading="lazy" src={route.image} alt="" />
                        )}
                        <h3 className="slider-item-open">{route.name}</h3>
                      </div>
                    </Tooltip>
                  </NavLink>
                ))}
              {user &&
                user.userType === UserType.Renter &&
                rentalSidebarRoute.map(route => (
                  <NavLink to={`/${route.path}`} key={route.path}>
                    <Tooltip placement="bottom" title={route.name}>
                      <div className={`slider-item ${url === route.path && url}`}>
                        {url === route.path ? (
                          <img loading="lazy" src={route.inActive} alt="" />
                        ) : (
                          <img loading="lazy" src={route.image} alt="" />
                        )}
                        <h3 className="slider-item-open">{route.name}</h3>
                      </div>
                    </Tooltip>
                  </NavLink>
                ))}
            </ul>

            <ul onClick={() => showSidebar(!isOpen)} data-open={!isOpen}>
              {user &&
                (user.userType === UserType.Admin || user.userType === UserType.SubAdmin) &&
                adminSidebarRoute.map(route => (
                  <NavLink to={`/${route.path}`} key={route.path}>
                    <Tooltip placement="bottom" title={route.name}>
                      <div className={`slider-item ${url === route.path && url}`} key={route.id}>
                        {url === route.path ? (
                          <div className="listing-indicator">
                            <img loading="lazy" src={route.inActive} alt="" />
                            {!isOpen && route.count !== 0 && <span> {route.count}</span>}
                          </div>
                        ) : (
                          <div className="listing-indicator">
                            <img loading="lazy" src={route.image} alt="" />
                            {!isOpen && route.count !== 0 && <span> {route.count}</span>}
                          </div>
                        )}
                      </div>
                    </Tooltip>
                  </NavLink>
                ))}
              {user &&
                user.userType === UserType.Landlord &&
                landlordSidebarRoute.map(route => (
                  <NavLink to={`/${route.path}`} key={route.path}>
                    <Tooltip placement="bottom" title={route.name}>
                      <div className={`slider-item ${url === route.path && url}`}>
                        {url === route.path ? (
                          <img loading="lazy" src={route.inActive} alt="" />
                        ) : (
                          <img loading="lazy" src={route.image} alt="" />
                        )}
                      </div>
                    </Tooltip>
                  </NavLink>
                ))}
              {user &&
                user.userType === UserType.Renter &&
                rentalSidebarRoute.map(route => (
                  <NavLink to={`/${route.path}`} key={route.path}>
                    <Tooltip placement="bottom" title={route.name}>
                      <div className={`slider-item ${url === route.path && url}`}>
                        {url === route.path ? (
                          <img loading="lazy" src={route.inActive} alt="" />
                        ) : (
                          <img loading="lazy" src={route.image} alt="" />
                        )}
                      </div>
                    </Tooltip>
                  </NavLink>
                ))}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default SideBar
