import { createObject } from "./createObject"
import { ParseType } from "../../../screens/landlord-access-building/types/index"
import {
  UNIT_AMENITIES as unitamt,
  UTILITIES as ut,
  AMENITIES as amt,
  TAGS as tag,
  TENANT_REQUIREMENTS as tenant,
  PETS,
  UNIT_APPLIANCES as appl
} from "../../constants/constants"
import moment from "moment"
import { UserType } from "src/generated/graphql"

export const parseReqData = (
  {
    buildingTitle,
    address,
    des,
    utilities,
    amenities,
    unitamenities,
    pets,
    units,
    contractions,
    floors,
    pictures,
    tags,
    bathrooms,
    bedrooms,
    den,
    deposit,
    furnished,
    monthlyPrice,
    available,
    number,
    title,
    unitSize,
    leaseTerm,
    startDateAvailability,
    parking,
    parkingSpots,
    tenantRequirements,
    floorplan,
    propertyType,
    picture,
    unitAppliances,
    moveInBonuses
  }: ParseType,
  edit = false,
  neighbourId,
  user
) => {
  const utObj = createObject(utilities, ut)
  const amtObj = createObject(amenities, amt)
  const applObj = createObject(unitAppliances, appl)
  const tagObj = createObject(tags, tag)
  const unitObj = createObject(unitamenities, unitamt)
  const tenantObj = createObject(tenantRequirements, tenant)
  const petObj = createObject(pets, PETS)

  if (edit) {
    return {
      address,
      buildingTitle,
      number,
      des,
      pets: petObj,
      bathrooms,
      bedrooms,
      den,
      deposit,
      furnished,
      monthlyPrice,
      propertyType,
      available,
      title,
      unitSize,
      leaseTerm,
      parking,
      parkingSpots: parkingSpots ? parkingSpots : null,
      startDateAvailability,
      picture: picture?.map(({ url, id }: { url: string; id: string }) => ({ url, id })),
      utilities: utObj,
      tenantRequirements: tenantObj,
      unitamenities: unitObj,
      amenities: amtObj,
      pictures: pictures.map(({ url, id }: { url: string; id: string }) => ({ url, id })),
      tags: tagObj,
      contractions,
      floors,
      units,
      floorplan,
      unitAppliances: applObj,
      moveInBonuses: moveInBonuses
    }
  }

  //run when edit false
  delete tenantObj?.none
  return {
    title: buildingTitle,
    description: des,
    location: {
      lat: address.geometry.lats,
      lng: address.geometry.lngs,
      address: address.title && address.title,
      tags: tagObj,
      neighbourHood: { connect: { id: neighbourId?.toString() } }
    },
    utilities: utObj,
    amenities: amtObj,
    propertyType: propertyType !== undefined ? propertyType : "OTHER",
    pets: petObj,
    isapproved:
      user && (user.userType === UserType.Admin || user.userType === UserType.SubAdmin)
        ? true
        : false,
    buildingConstructionYear: contractions ? contractions.toString() : "",
    totalUnitsInBuilding: units,
    storiesInBuilding: floors !== undefined ? floors : null,
    developmentCompany: "",
    tenantRequirements: tenantObj,
    unitAppliances: applObj,
    pictures: pictures.map(({ url, id }: { url: string; id: string }) => ({ url, id })),
    units: [
      {
        title: title,
        number: number,
        den: den,
        available: true,
        startDateAvailability:
          startDateAvailability && startDateAvailability?.length > 12
            ? moment(startDateAvailability).format()
            : new Date(),
        unitSize: unitSize,
        bedrooms: bedrooms,
        monthlyPrice: monthlyPrice,
        bathrooms: bathrooms,
        floorplan: floorplan,
        furnished: furnished === true ? true : false,
        deposit: deposit,
        leaseTerm: leaseTerm,
        parking: parking,
        parkingSpots: parkingSpots ? parkingSpots : null,
        amenities: unitObj,
        unitAppliances: applObj,
        pictures: picture.map(({ url, id }: { url: string; id: string }) => ({ url, id }))
      }
    ]
  }
}
