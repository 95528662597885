import React, { memo } from "react"
import RichTextEditor from "src/components/text-editor/rich-text-editor"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"

interface Props {
  onChangeForm: (value: object) => void
  helpCenterId: string
  client: ApolloClient<NormalizedCacheObject>
}
const FormThreeComponent = (props: Props) => {
  const { onChangeForm, helpCenterId, client } = props
  const onEditorStateChange = value => {
    const initiObj = {
      description: value,
      category: helpCenterId
    }
    onChangeForm(initiObj)
  }

  return (
    <div>
      <RichTextEditor onEditorStateChange={onEditorStateChange} editorState="" client={client} />
    </div>
  )
}

export default memo(FormThreeComponent)
