import styled from "styled-components"
import { fontSize, space, color, fontWeight, textAlign, width } from "styled-system"
import { Colors } from "../../theme/index"
import { ColorTypes } from "../../theme/colors"

interface Props {
  genre?: string
  color?: ColorTypes
  size?: number
  mt?: number
  mb?: number
  ml?: number
  marginBottom?: number
}

const mapGenreToProps = ({ genre, size, mt, mb, ml, marginBottom }: Props) => {
  switch (genre) {
    case "profile-title":
      return `
        font-family: 'Source Sans Pro', sans-serif;
        font-size: ${size ? size + "px" : 32 + "px"};
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #3c4240;
      `
    case "unit-info":
      return `
          font-family: 'Source Sans Pro', sans-serif;
          font-size: ${size ? size + "px" : 17 + "px"};
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          list-style-type: disc;
          color: #a09e9e;
          margin-top:${mt ? mt + "px" : 3 + "px"};
        `
    case "unit-price":
      return `
          font-family: 'Source Sans Pro', sans-serif;
          font-size: ${size ? size + "px" : 17 + "px"};
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          list-style-type: disc;
          color: #000;
          margin-top:${mt ? mt + "px" : 3 + "px"};
          margin-left: ${ml ? ml + "px" : 0 + "px"};
        `
    case "signup-title":
      return `
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 32px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          color: #3c4240;
        `
    case "permissionText":
      return `
          font-family: 'Source Sans Pro', sans-serif;
          font-size: ${size ? size + "px" : 15 + "px"};
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          color: rgb(61, 60, 60);
          `
    case "labelText":
      return `
          font-family: 'Source Sans Pro', sans-serif;
          font-size: ${size ? size + "px" : 15 + "px"};
          font-weight: 500;
          font-style: normal;
          margin-bottom: ${mb ? mb + "px" : 10 + "px"}
           @media (max-width: 900px) {
            margin-bottom: ${10 + "px"}
          }
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          color: rgb(61, 60, 60);
          `
    case "mapText":
      return `
                font-family: 'Source Sans Pro', sans-serif;
                font-size: ${size ? size + "px" : 15 + "px"};
                font-weight: 500;
                font-style: normal;
                margin-bottom: ${mb ? mb + "px" : 10 + "px"}
                 @media (max-width: 900px) {
                  margin-bottom: ${10 + "px"}
                }
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.3px;
                color: ${Colors.primary};
                `
    case "link":
      return `
                    font-family: 'Source Sans Pro', sans-serif;
                font-size: ${size ? size + "px" : 15 + "px"};
                font-weight: 500;
                font-style: normal;
                margin-bottom: ${mb ? mb + "px" : 10 + "px"}
                 @media (max-width: 900px) {
                  margin-bottom: ${10 + "px"}
                }
                font-stretch: normal;
                line-height: normal;
                letter-spacing: 0.3px;
                color: ${Colors.blue};
                `
    case "signup-title-info":
      return `
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 15px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.3px;
          color: #a09e9e;
          margin-top:4px
        `
    case "listing-info":
      return `
            font-family: 'Source Sans Pro', sans-serif;
            font-size: ${size ? size + "px" : 17 + "px"};
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.3px;
            color: #a09e9e;
            margin-top:${mt ? mt + "px" : 3 + "px"};
          `
    case "available-time":
      return `
              font-family: 'Source Sans Pro', sans-serif;
              font-size: ${size ? size + "px" : 17 + "px"};
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.3px;
              margin-left:5px
              color: #00a857;
              margin-top:${mt ? mt + "px" : 3 + "px"};
            `
    case "personal-info":
      return `
          opacity: 0.8;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 20px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.2px;
          color:#00a857;
        `
    case "location-info":
      return `
          opacity: 0.8;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 18px;
          text-transform: capitalize;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.2px;
          margin-right:10px;
          color:#00a857;
          &:hover {
            text-decoration:underline
          }

        `
    case "city-info":
      return `
          opacity: 0.8;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 18px;
          text-transform: capitalize;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.2px;
          margin-right:10px;
          color:#00a857;
        `
    case "inbox-title":
      return `
          opacity: 1;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 16px;
          text-transform: capitalize;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.2px;
          color:#a2a2a2;
          margin:10px 10px 10px 5px;
          &:hover {
            text-decoration:underline
          }

        `
    case "profile-label":
      return `
        height: 23px;
        opacity: 0.8;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 18px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #3c4240;
        margin-bottom: 8px;
        margin-top: 24px;
      `
    case "user-profile-label":
      return `
        height: 23px;
        opacity: 0.8;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #3c4240;
        margin-bottom: ${mb ? mb + "px" : 8 + "px"}
        @media (max-width: 900px) {
          margin-bottom: ${marginBottom ? marginBottom + "px" : 8 + "px"}
          }
        margin-top: 24px;
      `
    case "landlord-user-profile-label":
      return `
        height: 23px;
        opacity: 0.8;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #3c4240;
        margin-bottom: 8px;
        margin-top: 24px;
        @media (max-width: 1000px) {
        // margin-bottom: 28px;
          
        }
      `
    default:
      return `
    `
  }
}

const Text: any = styled.div<Props>`
  color: ${Colors.black};
  text-decoration: none;
  ${space};
  ${color};
  ${fontSize};
  ${fontWeight};
  ${textAlign};
  ${mapGenreToProps};
  ${width}
`
export const Text1: any = styled.div<Props>`
  color: ${Colors.black};
  ${space};
  ${color};
  ${fontSize};
  ${fontWeight};
  ${textAlign};
  ${mapGenreToProps}
`
export const H2Text: any = styled.h2<Props>`
  color: ${Colors.black};
  text-decoration: none;
  ${space};
  ${color};
  ${fontSize};
  ${fontWeight};
  ${textAlign};
  ${mapGenreToProps}
`

export default Text
