import React, { useRef, useMemo, useEffect, useState } from "react"
import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"
import { toolbar, autoDetectUrl, size } from "./util"
import "./style.scss"
import { Loader } from "src/assets/images"
import { uploadImage } from "src/services"
import ImageResize from "quill-image-resize-module-react"
import QuillAutoDetectUrl from "quill-auto-detect-url"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"

interface Props {
  editorState: any
  onEditorStateChange: (value: any) => void
  client: ApolloClient<NormalizedCacheObject>
}

const ReactEditor = (props: Props) => {
  const { editorState, onEditorStateChange, client } = props
  const [editorHtml, setEditorHtml] = useState("")

  const quillRef = useRef<any>(null)

  // Add size to whitelist
  var Size = Quill.import("attributors/style/size")
  Size.whitelist = size

  Quill.register({
    "modules/autoDetectUrl": QuillAutoDetectUrl,
    "modules/imageResize": ImageResize
  })
  Quill.register(Size, true)

  const handleChange = (content, delta, source, editor) => {
    let value = editor.getHTML()
    setEditorHtml(value)
    onEditorStateChange(value)
  }

  useEffect(() => {
    if (editorState) setEditorHtml(editorState)
  }, [editorState])

  const uploadFiles = async (file: any) => {
    const editor = quillRef?.current?.getEditor()
    const range = editor.getSelection(true)
    const reader = new FileReader()
    reader.onload = async (e: any) => {
      // add placeholder image into editor to current cursor position until s3 server image not get
      editor.insertEmbed(
        range.index,
        "image",
        `${window.location.origin}${Loader}`
        // `${window.location.origin}/images/loaders/placeholder.gif`
      )
      editor.formatText(range.index, 1, "width", "80px") //used to set the width of placeholder image
      editor.setSelection(range.index + 1) // move cursor position to the next line
      try {
        // pass base64 image and get s3 image url from server
        const resp = await uploadImage(client, reader && reader?.result)
        if (resp && resp.data) {
          const { uploadImage } = resp.data
          // Remove placeholder image
          editor.deleteText(range.index, 1)
          // Insert uploaded image
          editor.insertEmbed(range.index, "image", uploadImage?.thumbnailUrl)
        }
      } catch (err) {
        // Remove placeholder image
        editor.deleteText(range.index, 1)
        console.log("upload err:", err)
      }
    }
    reader.readAsDataURL(file)
  }

  const imageHandler = () => {
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute("accept", "image/*")
    input.click()
    input.onchange = async () => {
      const file = input && input.files ? input.files[0] : null
      if (file && /^image\//.test(file.type)) {
        await uploadFiles(file)
      }
    }
  }

  const modules = useMemo(
    () => ({
      toolbar: {
        container: toolbar,
        handlers: {
          image: imageHandler
        }
      },
      autoDetectUrl: autoDetectUrl,
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"]
      }
    }),
    []
  )

  return (
    <div id="quill-editor-container">
      <ReactQuill
        ref={quillRef}
        theme="snow"
        onChange={handleChange}
        value={editorHtml}
        placeholder="Write something..."
        modules={modules}
      />
    </div>
  )
}

export default ReactEditor
