import React, { useState } from "react"
import moment from "moment"

import {
  InputComponent,
  DateField
} from "src/dashboards/components/form-container-field/form-container-field"
import DatePickerWrapper from "src/components/single-date-picker"
import Text from "src/components/text"
import SelectMulti from "src/dashboards/components/profile-formik-component/multiple-select"
import { TU_FOUND } from "src/dashboards/components/constants/constants"
import Column from "src/components/column"
import { createOptions } from "src/dashboards/components/create-listing/utils/createOptions"
import { SelectObjType } from "src/dashboards/screens/landlord-access-building/types"

interface Props {
  setFormValues: (value: any) => void
}
function TenantForm(props: Props) {
  const { setFormValues } = props
  const [rentedPrice, setRentedPrice] = useState<string>("")
  const [date, setDate] = useState<Date>(new Date())
  const [tuList, setTuList] = useState<SelectObjType[]>([])

  const inputChange = e => {
    const { value } = e.target
    setFormValues(prev => ({ ...prev, ["rentedPrice"]: value }))
    setRentedPrice(value)
  }

  const dateChange = e => {
    setDate(e)
    setFormValues(prev => ({ ...prev, ["date"]: e?._d }))
  }

  const selectChange = value => {
    const selectedOption = createOptions(TU_FOUND, value)
    setTuList(selectedOption)
    setFormValues(prev => ({ ...prev, ["tuList"]: selectedOption }))
  }

  return (
    <>
      <Column>
        <Text genre="user-profile-label" mb={0}>
          Rented Price
        </Text>
        <InputComponent
          id="rentedPrice"
          name="rentedPrice"
          required={false}
          type="number"
          placeholder="Enter Rented Price"
          defaultValue={rentedPrice}
          onChange={inputChange}
          height={40}
          padding="0 0 0 10px"
        />
      </Column>
      <Column>
        <Text mb={0} genre="user-profile-label">
          Signed Lease Date
        </Text>

        <DateField>
          <DatePickerWrapper
            placeholder="Enter Signed Lease Date"
            id="moveIn"
            date={moment(date)}
            onDateChange={dateChange}
          />
        </DateField>
      </Column>
      <Column>
        <Text mb={0} genre="user-profile-label">
          TU Found From{" "}
        </Text>
        <SelectMulti
          selectUtilites={tuList}
          option={TU_FOUND}
          placeholder="Select TU Found From"
          onChange={selectChange}
        />
      </Column>
    </>
  )
}
export default TenantForm
