import * as React from "react"
import styled from "styled-components"
import Spin from "antd/lib/spin"
import "antd/lib/spin/style/css"
import * as Yup from "yup"
import { Formik, Form, FormikActions, FormikProps, Field, FieldProps } from "formik"
import MailChimpSubscribe from "react-mailchimp-subscribe"
import { MAIL_URI } from "../../mailchimp.config"
import Row from "../row"
import Box from "../box"
import Button from "../button"
import theme from "../../theme"
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid"
import MailOutlineIcon from "@material-ui/icons/MailOutline"

const EmailSection = styled(Box as any)`
  flex-direction: column-reverse;
  height: calc(100% - 1rem);
  margin-right: 16px;
  @media (max-width: ${theme.breakpoints[1]}) {
    height: auto;
    margin-right: 8px;
  }
`

const Input = styled.input`
  width: 199px;
  margin-right: 8px;
  border-radius: 8px;
  max-height: 40px;
  font-size: 14px;
  padding-left: 20px;
  @media (max-width: ${theme.breakpoints[1]}) {
    width: 188px;
  }
`
const ContactWrapper = styled.div`
  height: 120px;
`
const ContactSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom:1rem
  font-size:14px;
  color: #c4c6c5;

`

const Link = styled.a`
  margin-left: 10px;
  color: #c4c6c5;
  font-weight: 600;
`

interface FormValues {
  email: string
}

const EmailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required()
})

type Props = {
  onValidated: (data: any) => void
  status: string | null
  message: string | null
}

const EmailSectionComponent: React.FC<Props> = ({ status, message, onValidated }) => {
  if (status === "error") console.log(message)
  return (
    <EmailSection>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={EmailValidationSchema}
        onSubmit={async (values: FormValues, formikAction: FormikActions<any>) => {
          onValidated({ EMAIL: values.email })
        }}
      >
        {({ values, errors, isValid }: FormikProps<FormValues>) => {
          return (
            <Form style={{ display: "flex", justifyContent: "flex-end" }}>
              <Row>
                <Field
                  name="email"
                  render={({ field }: FieldProps<FormValues>) => (
                    <Input type="text" {...field} placeholder="Your Email" />
                  )}
                />
                <Spin spinning={status === "sending"}>
                  <Button
                    type="submit"
                    disabled={!isValid || status === "success"}
                    style={{
                      minWidth: "144px",
                      maxWidth: "144px",
                      padding: "7px 10px"
                    }}
                  >
                    {status === "success" ? "Subscribed!" : "Keep me posted"}
                  </Button>
                </Spin>
              </Row>
              {status === "error" && (
                <div style={{ color: "red", padding: 5 }}>You have already subscribed!😳</div>
              )}
            </Form>
          )
        }}
      </Formik>
      <ContactWrapper>
        <ContactSection>
          <PhoneAndroidIcon />
          <Link href="tel:+1 (647) 250-7371" target="_blank" rel="noopener noreferrer">
            (647) 250-7371
          </Link>
        </ContactSection>
        <ContactSection>
          <MailOutlineIcon />
          <Link href="mailto:rent@tolobi.com" target="_blank" rel="noopener noreferrer">
            rent@tolobi.com
          </Link>
        </ContactSection>
      </ContactWrapper>
    </EmailSection>
  )
}

const MailChimpComponent: React.FC<{}> = () => {
  return (
    <MailChimpSubscribe
      url={MAIL_URI}
      render={({ subscribe, status, message }) => (
        <EmailSectionComponent
          status={status}
          message={message}
          onValidated={data => subscribe(data)}
        />
      )}
    />
  )
}

export default MailChimpComponent
