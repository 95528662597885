import React, { useState, useEffect } from "react"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"

import "./style.scss"
import Card from "./card"
import { data } from "./data"
import { parseData } from "./utils"
import { featureAndPlan } from "src/dashboards/screens/plans/api/getFeatureAndPlan"

type Props = {
  handleSubscription: (subscriptions: { id: number, type: string, price: string, name: string, plan_group: string, unit: string, title?: string }[]) => void
  client: ApolloClient<NormalizedCacheObject>
  region: string
  step6?: any
  isUpdate?: boolean
  updateData?: any

}

export type SelectedPlans = Parameters<Props['handleSubscription']>['0'];

const BasicPlan = ({ handleSubscription, client, region, step6, isUpdate, updateData }: Props) => {
  const [datas, setData] = useState([])
  const [isLoading, setLoading] = useState(false);
  const initialSelectedPlans = isUpdate ? updateData : [];
  let [selectedPlans, setSelectedPlans] = useState<SelectedPlans>(initialSelectedPlans);

  const handleSelectedSubscriptions = (subscription: SelectedPlans[number], isSelected: boolean) => {

    let finalizedPlans = selectedPlans;

    if (isSelected) {
      finalizedPlans = selectedPlans.filter(plan => plan.plan_group !== subscription.plan_group);
      setSelectedPlans(finalizedPlans)
    } else {
      const validPlans = selectedPlans.filter(plan => plan.plan_group !== subscription.plan_group);
      finalizedPlans = [...validPlans, subscription];
      setSelectedPlans(finalizedPlans);
    }

    handleSubscription(finalizedPlans);
  };

  useEffect(() => {

    const fetchBoostPrice = async () => {
      setLoading(true)
      const featureResp = await featureAndPlan(client)
      if (featureResp && featureResp.data) {
        const { plans } = featureResp.data
        const respData = parseData(plans)
        let visiblePlans = respData
        if (
          [
            "alberta",
            "british columbia",
            "manitoba",
            "northwest territories",
            "nunavut",
            "quebec",
            "saskatchewan",
            "yukon"
          ].includes(region.toLowerCase()) &&
          !isUpdate
        ) {
          visiblePlans = visiblePlans.filter(item => item.id.toString() !== "4" && item.id.toString() !== "3");
        }
        if (region.toLowerCase() !== "ontario" && !isUpdate) {
          visiblePlans = visiblePlans.filter(item =>
            item.id.toString() !== "7" && item.id.toString() !== "4",

          )

        }
        setData(visiblePlans)
      } else {
        setData([])
      }
      setLoading(false)
    }
    fetchBoostPrice()
  }, [])


  const planGroup3 = datas.filter(item => item.plan_group === "2");
  planGroup3.sort((a, b) => a.price - b.price);
  const otherPlanGroups = datas.filter(item => item.plan_group !== "2");

  return (
    <>
      <div >
        <h1 style={{ fontWeight: "bold", textAlign: "center" }}>Select one Plan</h1>
        <div style={{
          display: "flex", flexWrap: "wrap", alignItems: "center",
          justifyContent: "center"
        }}>
          {planGroup3?.map((item, index) => {
            const isSelected = selectedPlans.some(plan => plan.id === item.id);
            return (
              <Card
                handleSubscription={handleSelectedSubscriptions}
                key={index}
                {...item}
                selected={isSelected}
                handleTheme={() => { }}
                isLoading={isLoading}
              />
            );
          })}
        </div>
      </div>

      <div>
        <h1 style={{ fontWeight: "bold", textAlign: "center" }}>{otherPlanGroups?.length > 0 ? "Optional Plans" : ""}</h1>
        <div style={{
          display: "flex", flexWrap: "wrap", alignItems: "center",
          justifyContent: "center"
        }}>
          {otherPlanGroups?.map((item, index) => {
            const isSelected = selectedPlans.some(plan => plan.id === item.id);
            return (
              <Card
                handleSubscription={handleSelectedSubscriptions}
                key={index}
                {...item}
                selected={isSelected}
                handleTheme={() => { }}
                isLoading={isLoading}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default BasicPlan

