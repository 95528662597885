import moment from "moment"
import estMoment from "moment-timezone"

export const timeStamp = (date, format = "MM-DD-YYYY") => {
  return moment
    .unix(date / 1000)
    .local()
    .format(format)
}
export const dateTimeStamp = date => {
  return moment
    .unix(date / 1000)
    .local()
    .format("MM-DD-YYYY hh:mm:ss")
}

export const DTimeStamp = date => {
  return moment
    .unix(date / 1000)
    .local()
    .format("MM-DD-YYYY hh:mmA")
}
export const timeStampTo_M_Day = date => {
  return moment
    .unix(date / 1000)
    .local()
    .format("MMM DD")
}

export const timeStampEst = date => {
  return estMoment
    .unix(date / 1000)
    .tz("US/Eastern")
    .format("MM-DD-YYYY hh:mm")
}
