import {
  CREATE_RENTAL,
  DELETE_RENTAL,
  GET_RENTAL_LAW,
  GET_RENTAL_LAW_COLLECTION,
  SINGLE_RENTAL,
  UPDATE_RENTAL,
  GET_RENTAL_LAWS
} from "../graphql"
import { ANALAYTIC_RENTAL_LAW } from "../graphql"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"

export const getAnalyticRentalLaw = async (client: ApolloClient<NormalizedCacheObject>) => {
  try {
    return await client.query({
      query: ANALAYTIC_RENTAL_LAW
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const fetchAdminRentalLaw = async (client, category) => {
  try {
    const { data } = await client.query({
      query: GET_RENTAL_LAW,
      fetchPolicy: "network-only",
      variables: {
        WhereInput: {
          category: category
        }
      }
    })
    return data
  } catch (err) {
    console.log("Error", err)
  }
}

export const createRental = async (client, data) => {
  try {
    return await client.mutate({
      mutation: CREATE_RENTAL,
      variables: {
        WhereInput: {
          ...data
        }
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const deleteRentalLaw = async (client, id: string) => {
  try {
    return await client.mutate({
      mutation: DELETE_RENTAL,
      variables: {
        WhereInput: { id }
      }
    })
  } catch (error) {
    console.log("Error", error)
  }
}

export const updatedRentalLaw = async (client, data) => {
  try {
    return await client.mutate({
      mutation: UPDATE_RENTAL,
      variables: {
        WhereInput: data
      }
    })
  } catch (error) {
    console.log("Error", error)
  }
}

export const getByIDRental = async (client, data) => {
  try {
    return await client.query({
      query: SINGLE_RENTAL,
      variables: {
        WhereInput: data
      }
    })
  } catch (error) {
    console.log("Error", error)
  }
}

export const fetchRentalLawCollection = async (client: ApolloClient<NormalizedCacheObject>, id) => {
  try {
    return await client.query({
      query: GET_RENTAL_LAW_COLLECTION,
      variables: {
        WhereInput: {
          category: id,
          isPublished: true
        }
      }
    })
  } catch (error) {
    console.log("Error:", { error })
    return []
  }
}

export const fetchRentalLaws = async (client, category, province, usertype) => {
  try {
    const { data } = await client.query({
      query: GET_RENTAL_LAWS,
      fetchPolicy: "network-only",
      variables: {
        WhereInput: {
          category: category,
          province: province,
          usertype: usertype
        }
      }
    })
    return data
  } catch (err) {
    console.log("Error", err)
  }
}
