import {
  CREATE_BUILDING,
  UPDATE_BUILDING,
  CREATE_NEIGHBORHOOD,
  CREATE_CITY,
  UPDATE_PROPERTY,
  CREATE_LIST_WITH_US_BUILDING
} from "../graphql/mutation"
import moment from "moment"

export const createProperty = async (req, client, createdFrom: string = "LISTING") => {
  try {
    return await client.mutate({
      mutation: CREATE_BUILDING,
      variables: {
        data: {
          ...req,
          createdFrom
        }
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const createListWithUsProperty = async (req, client, createdFrom: string = "LISTING") => {
  try {
    return await client.mutate({
      mutation: CREATE_LIST_WITH_US_BUILDING,
      variables: {
        data: {
          ...req,
          createdFrom
        }
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const createCity = async (data, client) => {
  try {
    const {
      data: { createCity }
    } = await client.mutate({
      mutation: CREATE_CITY,
      variables: {
        data: {
          ...data
        }
      }
    })
    return createCity
  } catch (err) {
    console.log("Error", err)
  }
}

export const createNeighborhood = async (client, name, id) => {
  try {
    const {
      data: { createNeighbourhood }
    } = await client.mutate({
      mutation: CREATE_NEIGHBORHOOD,
      variables: {
        data: {
          name,
          featured: false,
          popularity: 0,
          city: { id: id }
        }
      }
    })
    return createNeighbourhood
  } catch (err) {
    console.log("Error", err)
  }
}

export const updateProperty1 = async (id, client, req) => {
  try {
    return await client.mutate({
      mutation: UPDATE_PROPERTY,
      variables: {
        where: { id: id },
        data: {
          title:
            req.address.title?.split(",")[0] === undefined
              ? req.address.address
              : req.address.title?.split(",")[0],
          description: req.des,
          pets: req.pets,
          buildingConstructionYear: req.contractions?.toString(),
          totalUnitsInBuilding: req.units,
          storiesInBuilding: req.floors
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
  }
}

export const updateProperty = async (
  id,
  client,
  req,
  newImages,
  unitId,
  unitnewImages,
  negId,
  neighbourhood,
  govtProofnewImages,
  signaturenewImages,
) => {
  try {
    return await client.mutate({
      mutation: UPDATE_BUILDING,
      variables: {
        where: { id: id },
        data: {
          role: req.role,
          region: req.region,
          title: req.buildingTitle,
          moveInBonuses: req.moveInBonuses,
          description: req.des,
          pets: req.pets,
          premiumName: req.premiumName,
          plan: req.plan,
          planPrice: req.planPrice,
          isPremium: req.isPremium,
          advertiseUnit: req.advertiseUnit,
          existingUrl: req.existingUrl,
          advertiseDuration: req.advertiseDuration,
          currentlyTenanted: req.currentlyTenanted,
          desiredRentalPrice: req.desiredRentalPrice,
          minimumRentalPrice: req.minimumRentalPrice,
          extraInfo: req.extraInfo,
          showingUnit: req.showingUnit,
          customiseLease: req.customiseLease,
          govtProof: govtProofnewImages,
          signature: signaturenewImages,
          ownershipProof: req.ownershipProof,
          propertyRealtor: req.propertyRealtor,
          partnershipStatus: req.partnershipStatus,
          spousePartner: req.spousePartner,
          lockbox: req.lockbox,
          commission: req.commission,
          isBasicFree: req.isBasicFree,
          isViewManagement: req.isViewManagement,
          isConcierge: req.isConcierge,
          isLeadGeneartor: req.isLeadGeneartor,
          isRentalAssistance: req.isRentalAssistance,
          basement: req.basement,
          buildingConstructionYear: req.contractions?.toString(),
          totalUnitsInBuilding: req.units,
          storiesInBuilding: req.floors,
          propertyType: req.propertyType,
          tenantRequirements: req.tenantRequirements,
          utilities: req.utilities,
          location: {
            lat: req.address.geometry.lats,
            lng: req.address.geometry.lngs,
            address: req.address.title !== undefined ? req.address.title : req.address.address,
            tags: req.tags,
            neighbourHood: { id: negId !== undefined ? negId : neighbourhood?.id }
          },
          amenities: req.amenities,
          pictures: newImages,
          units: {
            id: unitId,
            title: req.title,
            number: req.number,
            den: req.den,
            available:
              typeof req.available !== "boolean"
                ? req.available === "false"
                  ? false
                  : true
                : req.available,
            unitSize: req.unitSize,
            monthlyPrice: req.monthlyPrice,
            startDateAvailability: moment(req.startDateAvailability).format(),
            bathrooms: req.bathrooms,
            bedrooms: req.bedrooms,
            storage: req.storage,
            maintenance: req.maintenance,
            smokeAlarm: req.smokeAlarm,
            deposit: req.deposit,
            furnished:
              typeof req.furnished !== "boolean"
                ? req.furnished === "false"
                  ? false
                  : true
                : req.furnished,
            leaseTerm: req.leaseTerm,
            parking: req.parking,
            floorplan: req.floorplan,
            amenities: req.unitamenities,
            pictures: unitnewImages,
            parkingSpots: req.parkingSpots,
            unitAppliances: req.unitAppliances
          }
        }
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}
