import gql from "graphql-tag"

export const INTERNAL_LISTING = gql`
  query mapListings($first: Int, $skip: Int) {
    internalMapListing(first: $first, skip: $skip) {
      id
      isHidden
      disableListing
      location {
        id
        lng
        lat
      }
    }
  }
`

export const SINGLE_LISTING = gql`
  query getSingleMapListing($id: String!, $isMap: Boolean!) {
    getSingleBuilding(id: $id, isMap: $isMap) {
      id
      rentedPrice
      tuDetails
      leadsTolobi
      leadsZumper
      leadsRentals
      leadsZillow
      leadsMarketplace
      leadsKijiji
      leadsCraigslist
      leadsRealtor
      leadsTolobiEnabled
      leadsZumperEnabled
      leadsRentalsEnabled
      leadsZillowEnabled
      leadsMarketplaceEnabled
      leadsKijijiEnabled
      leadsCraigslistEnabled
      leadsRealtorEnabled
      leadsTolobiDisabled
      leadsZumperDisabled
      leadsRentalsDisabled
      leadsZillowDisabled
      leadsMarketplaceDisabled
      leadsKijijiDisabled
      leadsCraigslistDisabled
      leadsRealtorDisabled
      signedLeaseDate
      updatedAt
      pictures {
        id
        url
      }
      units {
        id
        bedrooms
        monthlyPrice
        bathrooms
      }
      location {
        id
        lng
        lat
        address
      }
      tuListings {
        id
        tenantForm {
          id
          value
        }
      }
    }
  }
`

export const SEARCH_MAP_LISTING = gql`
  query searchMapListings($address: String) {
    searchMapListings(address: $address) {
      id
      title
      location {
        id
        lng
        lat
        address
      }
      isHidden
      disableListing
    }
  }
`
