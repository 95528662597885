import * as React from "react"
import styled, { keyframes } from "styled-components"
import {
  width,
  WidthProps,
  space,
  SpaceProps,
  FlexDirectionProps,
  alignItems,
  AlignItemsProps,
  justifyContent,
  JustifyContentValue,
  flex,
  FlexProps,
  color,
  ColorProps,
  textAlign,
  TextAlignProps,
  flexWrap,
  FlexWrapProps
} from "styled-system"

interface Props {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  height?: string
  onClick?: any
  key?: any
  flexWrap?: any
  alignItems?: any
  flex?: number
}

interface JustifyContentProps {
  justifyContent?: JustifyContentValue | Array<JustifyContentValue>
}

type BoxProps = Props &
  WidthProps &
  SpaceProps &
  FlexDirectionProps &
  AlignItemsProps &
  JustifyContentProps &
  FlexProps &
  ColorProps &
  TextAlignProps &
  FlexWrapProps

const displayAsLoading = (isLoading: any) => {
  if (isLoading) {
    const loadingAnimation = keyframes`
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(125%);
      }
    `
    return `
      overflow: hidden;
      background: #e8e8e8;
      border-radius: 3px;
      &::after {
        background: linear-gradient(90deg,#e8e8e8,#d0d0d0,#e8e8e8);
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        animation: ${loadingAnimation} 1.25s cubic-bezier(.36,1.14,.45,.22) infinite forwards;
      }
    `
  }
  return
}

const Row = ({ children, ...props }: BoxProps) => (
  <div className={props.className} onClick={props.onClick}>
    {children}
  </div>
)

const StyledRow = styled(Row) <Props>`
  display: flex;
  flex-direction: row;
  position: relative;
  box-sizing: border-box;
  ${flex};
  ${({ height }: any) => height && `height: ${height}`};
  ${width};
  ${space};
  ${justifyContent};
  ${alignItems};
  ${flex};
  ${color};
  ${flexWrap};
  ${textAlign};
  ${({ isLoading }: any) => displayAsLoading(isLoading)};
`
export default StyledRow
