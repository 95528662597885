import gql from "graphql-tag"

const GET_ALL_LISTING = gql`
  query totalListing {
    totalListing
  }
`

const GET_LISTING_STATUS = gql`
  query getCreateListingStatus {
    getCreateListingStatus {
      created
    }
  }
`

const GET_ALL_USER = gql`
  query userCount($data: Data) {
    userCount(data: $data) {
      count
    }
  }
`
const GET_UNREAD_MESSAGE = gql`
  query totalUnreadMsg {
    totalUnreadMsg
  }
`

export const GUEST_USER_TOTAL = gql`
  query guestUserTotal {
    guestUserTotal {
      count
    }
  }
`

export const PVF_ANALYTICS = gql`
  query pvfAnalytics {
    pvfAnalytics {
      id
      pvfSubmit
      pvfOpen
    }
  }
`

const GET_ALL_LANDLORD = gql`
  query totalLandlordUser {
    totalLandlordUser
  }
`

const GET_ALL_TENANT = gql`
  query totalRenterUser {
    totalRenterUser
  }
`

export const RENTED_BY_LU = gql`
  query totalRentedByLu {
    totalRentedByLu
  }
`
export const RENTED_BY_TOLOBI = gql`
  query totalRentedByTolobi {
    totalRentedByTolobi
  }
`

export {
  GET_ALL_LISTING,
  GET_ALL_USER,
  GET_ALL_TENANT,
  GET_ALL_LANDLORD,
  GET_UNREAD_MESSAGE,
  GET_LISTING_STATUS
}
