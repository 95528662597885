import React, { useState, useEffect } from "react"
import { FormControl } from "@material-ui/core"
import { PropType } from "../types"
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes"
import "../styles.scss"
import Text from "../../text"

const MultiSelect = ({
  form: { touched, errors, setFieldValue },
  field,
  labelTxt,
  options = [],
  ...rest
}: PropType) => {
  useEffect(() => {
    setOpts(options)
  }, [options.length, options])

  const [selectUtilites, setSelectUtilites] = useState(field.value)
  const [opts, setOpts] = useState(options)

  const handleMultipleValue = option => {
    let petCopyOption = []
    if (field.name === "pets") {
      if (option?.length === 0) {
        setSelectUtilites([])
        setFieldValue(field.name, [])
      } else {
        for (let i = 0; i < option.length; i++) {
          if (option[i].value === "none") {
            setSelectUtilites(options[options.length - 1])
            setFieldValue(field.name, [options[options.length - 1]])
          } else {
            petCopyOption = option
            if (petCopyOption.find((option: { value: string }) => option.value === "none")) {
              const result = option.filter((option: { value: string }) => option.value !== "none")

              setSelectUtilites(result)
              setFieldValue(field.name, result)
            } else {
              setSelectUtilites(option)
              setFieldValue(field.name, option)
            }
          }
        }
      }
    } else {
      setSelectUtilites(option)
      setFieldValue(field.name, option)
    }
  }

  return (
    <FormControl id="custom-form" fullWidth style={{ marginBottom: 15 }}>
      {labelTxt && (
        <Text genre="labelText" mb={0}>
          {labelTxt}
        </Text>
      )}
      <div className="react-checkbox">

        <ReactMultiSelectCheckboxes
          value={selectUtilites}
          options={opts}
          placeholderButtonLabel="None"
          onChange={handleMultipleValue}
          defaultValue={"None"}
        />
      </div>

      {errors[field.name] && touched[field.name] && (
        <div className="error-text">
          {rest.create_err_msg ? rest.create_err_msg(field.value) : errors[field.name]}
        </div>
      )}
    </FormControl>
  )
}

export default MultiSelect
