import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"
import { UPLOAD_FILES } from "../graphql"

export const uploadFiles = async (
  client: ApolloClient<NormalizedCacheObject>,
  data: UploadDataType
) => {
  try {
    return await client.mutate({
      mutation: UPLOAD_FILES,
      variables: {
        where: data
      }
    })
  } catch (err) {
    console.log("Error", err)
    return null
  }
}

export interface UploadDataType {
  files: { key: string; value: FileList[] }[]
  pathType: string
}
