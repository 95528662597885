import React, { PureComponent, ReactNode } from "react"
import BuildingPicture from "./building-picture-input"
import BuildingInput from "./building-info-input"
import { Title } from "src/dashboards/components"
import Dialog from "@material-ui/core/Dialog"
import { ActionBtn } from "./utils/actionBtn"
import { parseStep1 } from "./utils/parseStep1"
import { parseStep2 } from "./utils/parseStep2"
import { parseStep3 } from "./utils/parseStep3"
import { parseStep4 } from "./utils/parseStep4"
import { parseStep5 } from "./utils/parseStep5"
import { parseReqData } from "./utils/parseReqData"
import { getDeletedImages, getDeletedUnitImages } from "./utils/deleteImage"
import "./style.css"
import { fetchSingleListing } from "../../screens/landlord-access-building/utils/getSingleAndListingProperty"
import {
  createProperty,
  updateProperty,
  createCity,
  createNeighborhood
} from "../../screens/landlord-access-building/utils/createUpdateProperty"
import { StateType } from "../../screens/landlord-access-building/types/index"
import FirstUnitForm from "./unit-first-form"
import SecondUnitForm from "./unit-second-form"
import ThirdUnitForm from "./unit-third-form"
import { withRouter, RouteComponentProps } from "react-router-dom"
import BasicPlan from "../basic-plan"
import ModalComopnent from "src/components/modal-component"
import { FetchCurrentUserMe, UserType } from "src/generated/graphql"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"
import { SUBSCRIPTION } from "../constants"
import Modal from "src/dashboards/components/modalComponent"
import { updateListing } from "src/dashboards/screens/admin-access-building/utils/services"
import { notificationToAdmin } from "src/dashboards/screens/admin-access-building/utils/services"
import openNotification from "./notification-box"
import { notify } from "src/components/notification-toast"
import { MESSAGE, NOTIFICATION_MSG } from "src/constants/notification-mesage"
import { checkAllAccess } from "src/utils/comman-methods"
import { createPortal, unstable_batchedUpdates } from "react-dom"
import { AddButton } from "src/containers/home/UI"
import Loader from "src/dashboards/components/loader"
const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY as string
const premiumListingSku = process.env.REACT_APP_STRIPE_PREMIUM_LISTING_SKU as string
const premiumListingTaxSku = process.env.REACT_APP_STRIPE_PREMIUM_LISTING_TAX_SKU as string

type CityType = {
  name: string
  lat: number
  lng: number
}
interface Props {
  client: ApolloClient<NormalizedCacheObject>
  id?: string
  edit?: boolean
  child?: ReactNode
  user?: FetchCurrentUserMe
  fetchListings?: () => void
  showBtn?: boolean
  authState?: any
  createdFrom?: string
}

var saveNContinue = false
var isEdit = false
class CreateListingRoot extends PureComponent<Props & RouteComponentProps, StateType> {
  state = {
    updatedListingId: "",
    open: false,
    isEditListing: this.props.edit,
    fullWidth: true,
    step: 1,
    step1: {
      propertyType: "",
      address: "",
      buildingTitle: "",
      des: "",
      units: null,
      floors: null,
      contractions: ""
    },
    step2: {
      utilities: [],
      amenities: [],
      pets: [],
      tenantRequirements: [],
      tags: [],
      pictures: []
    },
    step3: {
      title: "",
      startDateAvailability: "",
      bathrooms: "",
      bedrooms: "",
      den: 0,
      deposit: "",
      monthlyPrice: "",
      furnished: false
    },
    step4: {
      number: "",
      parking: "",
      leaseTerm: null,
      parkingSpots: 0,
      picture: []
    },
    step5: {
      floorPlan: "",
      unitamenities: [],
      unitAppliances: []
    },
    title: "",
    unitId: "",
    neighbourhood: {},
    updateState1: {},
    loading: false,
    picture: [],
    confirOpen: false,
    unitPicture: [],
    listingsData: {},
    openAlert: false,
    step6: {},
    saveChanges: false,
    isUpdateListing: false,
    listingId: "",
    allAccess: {}
  }

  async componentDidUpdate(preProps: Props, preState: StateType) {
    const { id, client, edit, location } = this.props
    const listingID = location?.search.split("=")[1]
    const editparam = location.search?.split("=")[1]
    const editVal = editparam?.split("/")[0]

    const buiIds = location?.search?.split("=")[2]
    const { open, isUpdateListing } = this.state
    if (editVal == "true") {
      this.setState({
        updatedListingId: buiIds,
        isEditListing: editVal ? true : edit
      })
    } else {
      this.setState({
        updatedListingId: listingID ? listingID : id,
        isEditListing: editVal ? true : edit
      })
    }
    const { open: preOpen } = preState
    if (preOpen !== open) {
      if (edit && open && isUpdateListing) {
        this.setState({ loading: true })
        const resp = await fetchSingleListing(id, client)
        if (resp?.getSingleBuilding) {
          const { getSingleBuilding } = resp
          this.setState({ loading: false })
          const { pictures, units, title } = getSingleBuilding
          if (getSingleBuilding) {
            const {
              location: { neighbourHood }
            } = getSingleBuilding
            const stepOneData = parseStep1(getSingleBuilding)
            const stepTwoData = parseStep2(getSingleBuilding)
            const stepThreeData = parseStep3(getSingleBuilding)
            const stepFourData = parseStep4(getSingleBuilding)
            const stepFiveData = parseStep5(getSingleBuilding)
            unstable_batchedUpdates(() => {
              this.setState({
                step1: stepOneData,
                title: title,
                neighbourhood: neighbourHood,
                step2: stepTwoData,
                step3: stepThreeData,
                step4: stepFourData,
                step5: stepFiveData,
                listingsData: getSingleBuilding,
                unitId: units[0]?.id,
                picture: pictures,
                unitPicture: units[0]?.pictures
              })
            })
          }
        }
        this.setState({ loading: false })
      }
    }
  }

  checkAccessValid = user => {
    let allAccess = {}
    if (user && user.userType === UserType.SubAdmin) {
      allAccess = checkAllAccess([], "listings")
      this.setState({ allAccess })
    } else if (
      (user && user.userType === UserType.Admin) ||
      (user && user.userType === UserType.Landlord)
    )
      this.setState({ allAccess: { add: true, edit: true, delete: true, view: true } })
  }

  componentDidMount = async () => {
    const { search } = this.props.location
    const { user } = this.props
    const listingID = search.split("=")[1]
    this.setState({ updatedListingId: listingID })
    const { client, edit } = this.props

    if (search && listingID && !isEdit && edit) {
      isEdit = true
      this.setState({ open: true, loading: true })
      const resp = await fetchSingleListing(listingID, client)
      this.setState({ loading: false })
      if (resp) {
        const { getSingleBuilding } = resp
        const { pictures, units, title } = getSingleBuilding
        if (getSingleBuilding) {
          const {
            location: { neighbourHood }
          } = getSingleBuilding
          const stepOneData = parseStep1(getSingleBuilding)
          const stepTwoData = parseStep2(getSingleBuilding)
          const stepThreeData = parseStep3(getSingleBuilding)
          const stepFourData = parseStep4(getSingleBuilding)
          const stepFiveData = parseStep5(getSingleBuilding)
          unstable_batchedUpdates(() => {
            this.setState({
              step1: stepOneData,
              title: title,
              neighbourhood: neighbourHood,
              step2: stepTwoData,
              step3: stepThreeData,
              step4: stepFourData,
              step5: stepFiveData,
              listingsData: getSingleBuilding,
              unitId: units[0]?.id,
              picture: pictures,
              unitPicture: units[0]?.pictures
            })
          })
        }
      }
    }

    const editparam = search?.split("=")[1]
    const editVal = editparam?.split("/")[0]
    if (editVal == "true") {
      const buiIds = search?.split("=")[2]
      this.setState({ updatedListingId: buiIds, isEditListing: editVal ? true : false })
      if (search && !isEdit && editVal === "true") {
        isEdit = true
        this.setState({ open: true, loading: true })
        const resp = await fetchSingleListing(buiIds, client)
        this.setState({ loading: false })
        if (resp) {
          const { getSingleBuilding } = resp
          const { pictures, units, title } = getSingleBuilding
          if (getSingleBuilding) {
            const {
              location: { neighbourHood }
            } = getSingleBuilding
            const stepOneData = parseStep1(getSingleBuilding)
            const stepTwoData = parseStep2(getSingleBuilding)
            const stepThreeData = parseStep3(getSingleBuilding)
            const stepFourData = parseStep4(getSingleBuilding)
            const stepFiveData = parseStep5(getSingleBuilding)
            unstable_batchedUpdates(() => {
              this.setState({
                step1: stepOneData,
                title: title,
                neighbourhood: neighbourHood,
                step2: stepTwoData,
                step3: stepThreeData,
                step4: stepFourData,
                step5: stepFiveData,
                listingsData: getSingleBuilding,
                unitId: units[0]?.id,
                picture: pictures,
                unitPicture: units[0]?.pictures
              })
            })
          }
        }
      }
    }

    this.checkAccessValid(user)
  }

  handleClickOpen = async () => {
    const { showBtn } = this.props
    if (showBtn || this.state.allAccess?.["add"])
      this.setState({ open: true, isUpdateListing: true })
    else notify(NOTIFICATION_MSG.error, MESSAGE.NoAccess)
  }

  handleEditClick = async () => {
    const { showBtn } = this.props
    if (showBtn || this.state.allAccess?.["edit"])
      this.setState({ open: true, isUpdateListing: true })
    else notify(NOTIFICATION_MSG.error, MESSAGE.NoAccess)
  }

  handleNext = step => {
    step = step + 1
    this.setState({ step })
  }

  handleBack = step => {
    step = step - 1
    this.setState({ step })
  }

  onSaveNContinue = () => {
    saveNContinue = true
    setTimeout(async () => {
      saveNContinue = false
      this.setState({ loading: true })
      const { step1, step2, step3, step4, step6 } = this.state
      const { client, user, createdFrom } = this.props
      const data: any = { ...step1, ...step2, ...step3, ...step4, ...step6 }
      if (!this.props.edit) {
        const { address } = data
        const cityReqData: CityType = {
          name: address.city,
          lat: address?.geometry.lats,
          lng: address?.geometry.lngs
        }
        const respData = await createCity(cityReqData, client)
        if (respData)
          var resp = await createNeighborhood(
            client,
            data?.address.title.split(",")[2],
            respData?.id
          )
      }
      const req = parseReqData(data, this.props.edit, resp?.id, user)
      const {
        data: { createListing }
      } = await createProperty(req, client, createdFrom)
      if (this.props.history)
        this.props.history.push({
          pathname: `/unit/${createListing.id}`,
          state: {
            showModal: true
          }
        })
      this.setState({ loading: false })
    }, 400)
  }

  updateListingEveryPage = async (data: any) => {
    const { updatedListingId, isEditListing, picture, unitPicture } = this.state
    const { user, client, history } = this.props
    let neighborhoodId: any = null
    if (data?.address?.city) {
      const { address } = data
      const cityReqData: CityType = {
        name: address.city,
        lat: address?.geometry.lats,
        lng: address?.geometry.lngs
      }
      let respData = await createCity(cityReqData, client)
      if (respData) {
        neighborhoodId = await createNeighborhood(client, data.address.neighborhood, respData?.id)
      }
    }
    const req = parseReqData(data, isEditListing, neighborhoodId?.id, user)

    if (picture?.length > 0) getDeletedImages(picture, req.pictures, client)
    if (unitPicture.length > 0) getDeletedUnitImages(unitPicture, req.picture, client)

    const newImages = [] as any
    const unitnewImages = [] as any

    req.pictures?.map(img => {
      if (!img.id) {
        delete img["id"]
        newImages?.push(img)
      }
      return null
    })
    req.picture?.map(img => {
      if (!img.id) {
        delete img["compress"]
        unitnewImages.push(img)
      }
      return null
    })
    const { unitId, neighbourhood } = this.state
    const updateResp = await updateProperty(
      updatedListingId,
      client,
      req,
      newImages,
      unitId,
      unitnewImages,
      neighborhoodId?.id,
      neighbourhood,
      [],
      [],
      []
    )

    if (updateResp && updateResp.data) {
      unstable_batchedUpdates(() => {
        this.closeModal()
        this.setState({
          saveChanges: false
        })
      })
      if (user && user.userType === UserType.Landlord)
        history.push({
          pathname: "/building"
        })
      else if (user && user.userType === UserType.SubAdmin)
        history.push({
          pathname: "/listings"
        })
      else {
        history.push({
          pathname: isEdit ? "/building" : "/listings"
        })
      }
      isEdit = false
      notify(NOTIFICATION_MSG.Success, "Building has been updated successfully")
      if (this.props.fetchListings) this.props.fetchListings()
    } else {
      notify(NOTIFICATION_MSG.error, updateResp?.toString() || MESSAGE.SomethingWrong)
    }
    this.setState({ loading: false })
  }

  handleSaveOnEveryPage = (formikActions) => {
    if (formikActions) formikActions.submitForm();

    this.setState({ loading: true })
    saveNContinue = true
    setTimeout(async () => {
      saveNContinue = false
      const { step2, step3, step4, step5, updateState1, step1, step } = this.state
      let data: any = null
      switch (step) {
        case 1:
          data = { ...updateState1, ...step2, ...step3, ...step4, ...step5 }
          this.updateListingEveryPage(data)
          break
        case 2:
          data = { ...updateState1, ...step1, ...step3, ...step4, ...step5 }
          this.updateListingEveryPage(data)
          break
        case 3:
          data = { ...updateState1, ...step1, ...step2, ...step4, ...step5 }
          this.updateListingEveryPage(data)
          break
        case 4:
          data = { ...updateState1, ...step1, ...step2, ...step3, ...step5 }
          this.updateListingEveryPage(data)
          break
      }
    })
  }

  handleClose = () => {
    if (!this.state.saveChanges) {
      this.setState({ confirOpen: false, open: false, step: 1 })
    } else {
      this.setState({ confirOpen: true })
    }
  }

  onClose = () => {
    this.setState({ confirOpen: false })
  }

  handleOk = () => {
    this.setState({
      open: false,
      step1: {},
      step2: {},
      step3: {},
      step4: {},
      step5: {},
      step: 1,
      confirOpen: false
    })
  }

  handleStepOne = (payload: any, resetForm: any) => {
    this.setState({ step1: payload, step: 2 })
  }

  handleStepTwo = (payload: any, resetForm: any) => {
    this.setState({ step2: payload, step: 3 })
  }

  handleStepThree = (payload: any, resetForm: any) => {
    this.setState({ step3: payload, step: 4 })
  }

  handleStepFour = (payload: any, resetForm: any) => {
    this.setState({ step4: payload, step: 5 })
  }

  submitListing = async (payload: any, resetForm: any) => {
    const { step1, step2, step3, step4, isEditListing } = this.state
    const { client, user, createdFrom } = this.props
    this.setState({ step5: payload, loading: true })
    const data = { ...step1, ...step2, ...step3, ...step4, ...payload }
    if (isEditListing) this.updateListingEveryPage(data)
    else {
      let neighborhoodId: any = null
      if (data?.address?.city) {
        const { address } = data
        const cityReqData: CityType = {
          name: address.city,
          lat: address?.geometry.lats,
          lng: address?.geometry.lngs
        }
        let respData = await createCity(cityReqData, client)
        if (respData) {
          neighborhoodId = await createNeighborhood(client, data.address.neighborhood, respData?.id)
        }
      }
      const req = parseReqData(data, isEditListing, neighborhoodId?.id, user)
      const resp = await createProperty(req, client, createdFrom)
      if (resp && resp.data) {
        const { id } = resp.data.createListing
        if (user && (user.userType === UserType.Admin || user.userType === UserType.SubAdmin)) {
          if (this.props.fetchListings) this.props.fetchListings()
          notify(NOTIFICATION_MSG.Success, "Building has been created successfully")
          this.closeModal()
        } else {
          this.setState({ step: 6, listingId: id })
        }
      } else notify(NOTIFICATION_MSG.error, resp?.tostring() || MESSAGE.SomethingWrong)
      this.setState({ loading: false })
    }
    // console.log("submitListing loading false")
    // this.setState({ loading: false })
  }

  onFormChange = () => {
    if (!this.state.saveChanges) {
      this.setState({ saveChanges: true })
    }
  }

  goPremium = async () => {
    this.closeModal()
    const { listingId } = this.state
    if (listingId) {
      const stripe = Stripe(stripeApiKey)
      try {
        return await stripe.redirectToCheckout({
          successUrl: `${window.location.origin}/redirect/premium`,
          cancelUrl: window.location.href,
          customerEmail: this.props.user?.email,
          clientReferenceId: listingId,
          items: [
            { sku: premiumListingSku, quantity: 1 },
            { sku: premiumListingTaxSku, quantity: 1 }
          ]
        })
      } catch (error) {
        console.log({ error })

        notify(NOTIFICATION_MSG.error, MESSAGE.SomethingWrong)
        return { error }
      }
    } else {
      return null
    }
  }

  updateBoostListing = () => {
    this.closeModal()
    notify(
      NOTIFICATION_MSG.Success,
      "Premium Listing",
      "Congratulations! Your listing has been boosted. You have 7 days remaining. Now just sit back and let the leads come to you."
    )
  }

  handleSubscription = async (value, price, name: string) => {
    const { client } = this.props
    if (value === SUBSCRIPTION.Basic) {
      notify(NOTIFICATION_MSG.Success, "Building has been created successfully")
      this.closeModal()
    } else if (value === SUBSCRIPTION.Boost) {
      const payloadData = {
        id: this.state.listingId,
        isPremium: true,
        premiumName: name
      }
      await updateListing(client, payloadData)

      if (price == "$ 0.00") {
        this.updateBoostListing()
      } else {
        await this.goPremium()
      }
    } else {
      const { step1 } = this.state
      openNotification()
      this.closeModal()
      const address: any = step1.address
      const payloadData = {
        address: address?.title,
        premiumName: name
      }
      notificationToAdmin(client, payloadData)
    }
    if (this.props.fetchListings) this.props.fetchListings()
  }

  renderHeader = (step: number, edit: boolean) => {
    if (!edit) {
      switch (step) {
        case 1 || 2 || 3:
          return <Title lable="Add Property" />
        case 4 || 5:
          return <Title lable="Add Units" />
        default:
          return <Title lable="Add Units" />
      }
    } else {
      switch (step) {
        case 1 || 2 || 3:
          return <Title lable="Update Property" />
        case 4 || 5:
          return <Title lable="Update Units" />
        default:
          return <Title lable="Update Units" />
      }
    }
  }

  renderForms = (step: number, Actions) => {
    const { step1, step2, step3, step4, step5, isEditListing } = this.state
    const { client } = this.props
    const region: any = step1.address

    switch (step) {
      case 1:
        return (
          <BuildingInput
            initialValues={step1}
            actionBtn={<Actions />}
            onSubmit={(data, payload) => {
              if (saveNContinue) {
                this.setState({ updateState1: data })
              } else {
                this.handleStepOne(data, payload)
              }
            }}
            client={client}
            onFormChange2={this.onFormChange}
          />
        )
      case 2:
        return (
          <BuildingPicture
            initialValues={step2}
            actionBtn={<Actions />}
            onSubmit={(data, payload) => {
              if (saveNContinue) {
                this.setState({ updateState1: data })
              } else {
                this.handleStepTwo(data, payload)
              }
            }}
            onFormChange2={this.onFormChange}
            editMode={isEditListing}
          />
        )
      case 3:
        return (
          <FirstUnitForm
            initialValues={step3}
            actionBtn={<Actions />}
            onSubmit={(data, payload) => {
              if (saveNContinue) {
                this.setState({ updateState1: data })
              } else {
                this.handleStepThree(data, payload)
              }
            }}
            edit={isEditListing}
            onFormChange2={this.onFormChange}
          />
        )

      case 4:
        return (
          <SecondUnitForm
            initialValues={step4}
            actionBtn={<Actions />}
            onSubmit={(data, payload) => {
              if (saveNContinue) {
                this.setState({ updateState1: data })
              } else {
                this.handleStepFour(data, payload)
              }
            }}
            onFormChange2={this.onFormChange}
          />
        )
      case 5:
        return (
          <ThirdUnitForm
            initialValues={step5}
            actionBtn={<Actions />}
            onSubmit={(data, payload) => {
              if (saveNContinue) {
                this.setState({ step6: data })
              } else {
                this.submitListing(data, payload)
              }
            }}
            edit={isEditListing}
            onFormChange2={this.onFormChange}
          />
        )

      default:
        return (
          <ModalComopnent open={true} handleClose={this.closeModal}>
            <BasicPlan
              handleSubscription={this.handleSubscription}
              region={region?.region}
              client={client}
            />
          </ModalComopnent>
        )
    }
  }

  closeModal = () => {
    this.setState({
      open: false,
      step1: {},
      step2: {},
      step3: {},
      step4: {},
      step5: {},
      step: 1
    })
  }

  render() {
    const { fullWidth, step, loading, open, confirOpen, isEditListing } = this.state
    const { child } = this.props
    const Actions = ({ __formikActions }) => (
      <ActionBtn
        step={step}
        handleBack={this.handleBack}
        edit={isEditListing}
        onSaveNContinue={this.onSaveNContinue}
        onSaveContinueFirst={this.handleSaveOnEveryPage.bind(this, __formikActions)}
        handleClose={this.handleClose}
        loading={loading}

      />
    )

    return (
      <>
        {!isEditListing ? (
          <AddButton margin={"10px"} maxWidth={"100%"} onClick={this.handleClickOpen}>
            Add Properties
          </AddButton>
        ) : (
          <span onClick={this.handleEditClick}>{child}</span>
        )}

        <Dialog
          fullWidth={fullWidth}
          maxWidth={"lg"}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <div className="buildings-form">
            <div className="buildings-form-title">
              {this.renderHeader(step, isEditListing!)}
              <p>
                Enter all the details and click on <span className="next-btn-blod">NEXT</span> to
                proceed.
              </p>
            </div>
            {this.renderForms(step, Actions)}
          </div>
        </Dialog>
        <Modal
          isModalVisible={confirOpen}
          disabled={false}
          isSubmitting={false}
          handleCancel={this.onClose}
          handleOk={this.handleOk}
          btnText={"Confirm"}
          title={""}
        >
          <h4 style={{ textAlign: "center", fontSize: 20 }}>
            If you would like to cancel, all your changes will be discarded. Continue?
          </h4>
        </Modal>
        {createPortal(<Loader loader={loading} />, document.body)}
      </>
    )
  }
}
export default withRouter(CreateListingRoot)
