import { useState, useCallback, useEffect, useMemo } from "react"
import React from "react"
import { AddButton } from "src/containers/home/UI"
import Row from "src/components/row"
import { Colors } from "src/theme"
import styled from "styled-components"
import ProgressBar from "@ramonak/react-progress-bar"
import { DISABLE } from "./const"
import TextareaAutosize from 'react-textarea-autosize';

const Header = styled.div`
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LoaderBox = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
`

const Title = styled.label`
    font-weight: 600;
    font-size: 18px;
    margin: 0 10px;
`

var interval, i = 0

const DisplayLdg = ({
    loading,
    aiDesc,
    children,
    onPrev,
    nearby,
    updateValue,
    editMode,
    reGenerate
}) => {

    const [display, setDisplay] = useState(DISABLE ? false : true)
    const [progress, setProgress] = useState(0)

    const onNext = useCallback(() => {
        setDisplay(false)
    }, [])

    const showDisplay = useCallback(() => {
        setDisplay(true)
    }, [])



    const startCount = useCallback(() => {
        if (interval) {
            clearInterval(interval)
        }
        i = 0
        setProgress(10)
        interval = setInterval(() => {
            i++
            if (i === 5) {
                setProgress(40)
            }
            else if (i == 8) {
                setProgress(60)
            }
            else if (i == 12) {
                setProgress(80)
            }
            else if (i == 16) {
                setProgress(95)
                clearInterval(interval)
            }
        }, 1000)
    }, [nearby])

    useEffect(() => {
        if (loading) {
            setProgress(0)
            startCount()
        }
        else if (aiDesc && !loading) {
            setProgress(100)
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [loading, aiDesc])

    const getMessage = useMemo(() => {
        return ``
        // switch (progress) {
        //     case 10:
        //         return "Finding nearby landmarks"
        //     case 60:
        //         return "Analysing the property images"
        //     case 80:
        //         return "Our AI-powered Listing Description Generator is generating a SEO-optimized, editable description for your listing so that you don’t have to! This won’t take more than 10 seconds."
        //     default: return "Please wait"
        // }
    }, [progress])

    return display ? (
        <div>
            <Header>
                {
                    !loading && (<Title>Description</Title>)
                }
                {
                    editMode && !loading && (
                        <AddButton disabled={loading} width={160} onClick={() => {
                            reGenerate()
                            startCount()
                        }}>
                            Regenerate
                        </AddButton>
                    )
                }

            </Header>
            {
                loading ? (
                    <>
                        <LoaderBox>
                            Our AI-powered Listing Description Generator is generating a SEO-optimized, editable description for your listing so that you don’t have to! This will take just a few seconds.
                        </LoaderBox>
                        <ProgressBar bgColor="rgba(0,168,87,0.87)" completed={progress} />
                        <LoaderBox>
                            {getMessage}
                        </LoaderBox>
                    </>
                ) : (
                    <TextareaAutosize className="info-box" value={aiDesc} onChange={updateValue} />
                )
            }
            <Row
                my={3}
                width={"auto"}
                justifyContent="space-between"
                flexWrap="wrap-reverse"
            >
                <AddButton
                    genre="add-new"
                    width={130}
                    border={`1px solid ${Colors.primary}`}
                    color={Colors.primary}
                    mx={0}
                    backgroundColor="transparent"
                    onClick={onPrev}
                    disabled={loading}
                >
                    Previous
                </AddButton>

                <AddButton disabled={loading} width={160} onClick={onNext}>
                    Next
                </AddButton>
            </Row>
        </div>
    ) : children(DISABLE ? onPrev : showDisplay)




}

export default DisplayLdg