import gql from "graphql-tag"

export const SINGLE_HELP_CENTER = gql`
  query singleHelpCenter($WhereInput: HelpWhereInput!) {
    singleHelpCenter(WhereInput: $WhereInput) {
      id
      title
      description
    }
  }
`
