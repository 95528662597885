import gql from "graphql-tag"

export const SEARCH_BUILDING_BY_LANDLORD = gql`
  query searchBuildingByLandlord($search: String!, $skip: Int, $take: Int) {
    luSearchCount(search: $search) {
      count
    }
    searchBuildingByLandlord(search: $search, skip: $skip, take: $take) {
      id
      title
      role
      seen
      createdAt
      isHidden
      isapproved
      premiumStart
      premiumName
      premiumEnd
      updatedAt
      isPremium
      location {
        id
        neighbourHood {
          id
          city {
            id
            name
          }
        }
      }
      units {
        id
        title
      }
    }
  }
`

export const GET_AVAILABLE_LISTINGS = gql`
  query landlordActiveListing($first: Int, $skip: Int, $isHidden: Boolean) {
    landlordActiveListing(first: $first, skip: $skip, isHidden: $isHidden) {
      id
      title
      role
      premiumStart
      premiumEnd
      isHidden
      isapproved
      createdAt
      updatedAt
      premiumName
      isPremium
      location {
        id
        neighbourHood {
          id
          city {
            id
            name
          }
        }
      }
      units {
        id
        title
      }
    }
  }
`
export const GET_UNAVAILABLE_LISTINGS = gql`
  query landlordInactiveListing($first: Int, $skip: Int) {
    landlordInactiveListing(first: $first, skip: $skip) {
      id
      title
      role
      isapproved
      isHidden
      premiumStart
      premiumEnd
      isPremium
      createdAt
      premiumName
      updatedAt
      location {
        id
        neighbourHood {
          id
          city {
            id
            name
          }
        }
      }
      units {
        id
        title
      }
    }
  }
`

export const GET_LISTINGS = gql`
  query landlordListing($first: Int!, $skip: Int!) {
    landlordListing(first: $first, skip: $skip) {
      id
      title
      role
      isapproved
      isHidden
      createdAt
      updatedAt
      isPremium
      premiumName
      premiumStart
      premiumEnd
      advertiseUnit
      existingUrl
      advertiseDuration
      currentlyTenanted
      desiredRentalPrice
      minimumRentalPrice
      extraInfo
      showingUnit
      customiseLease
      checkout_Id
      payment_status
      region
      plan{
        id
        name
        price
      }
      basement
      units {
        id
        title
      }
      location {
        id
        neighbourHood {
          id
          city {
            id
            name
          }
        }
      }
    }
    landlordListingCount {
      count
    }
    landlordActiveListingCount {
      count
    }
  }
`
