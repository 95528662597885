import React from "react"
import Form from "../../../components/FormComponents";
import { unitfields, unitEditfields } from "./fields"

interface Props {
  actionBtn: any;
  onSubmit: (payload: any, resetForm: any) => void;
  initialValues: { [x: string]: any };
  edit?: boolean;
  onFormChange2?: () => void
}

const FirstUnitForm = React.memo(function FirstUnitForm({ actionBtn, onFormChange2, onSubmit, initialValues, edit }: Props) {
  const onFormChange = () => {
    if (onFormChange2 !== undefined)
      onFormChange2()
  }
  return (
    <Form
      fields={!edit ? unitfields : unitEditfields}
      initialValues={initialValues}
      btnText={"submit"}
      onSubmit={onSubmit}
      renderCustomSubmit={actionBtn}
      edit={edit}
      onFormChange={onFormChange}
      giveButtonsFormikActions
    />

  )
})

export default FirstUnitForm
