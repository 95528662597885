import React from "react"
import Row from "src/components/row"
import { AddButton } from "src/containers/home/UI"
import { Colors } from "src/theme"
import { useMediaQuery } from "react-responsive"

export const ActionBtn = ({
  step,
  handleBack,
  handleClose,
  onSaveContinueFirst,
  edit,
  onSaveNContinue,
  loading
}: {
  step: number
  edit: boolean | undefined
  handleBack: (step: number) => void
  handleClose: () => void
  onSaveNContinue: () => void
  onSaveContinueFirst: () => void
  loading: boolean
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" })
  return (
    <Row my={3} justifyContent="space-between" alignItems={"center"} flexWrap="wrap-reverse">
      <AddButton
        width={100}
        mx={0}
        genre="add-new"
        border={`1px solid ${Colors.red}`}
        color={Colors.red}
        backgroundColor="transparent"
        onClick={handleClose}
      >
        Close
      </AddButton>
      <Row
        my={3}
        width={isTabletOrMobile ? "100%" : "auto"}
        justifyContent="space-between"
        flexWrap="wrap-reverse"
      >
        {step !== 1 && (
          <AddButton
            genre="add-new"
            width={120}
            border={`1px solid ${Colors.primary}`}
            color={Colors.primary}
            mx={0}
            backgroundColor="transparent"
            onClick={() => handleBack(step)}
          >
            Previous
          </AddButton>
        )}
        {edit && step !== 5 && (
          <AddButton width={120} type="click" onClick={onSaveContinueFirst}>
            Save
          </AddButton>
        )}
        {step === 5 && !edit && (
          <AddButton disabled={loading} onClick={onSaveNContinue}>
            Add Another Unit
          </AddButton>
        )}
        <AddButton width={120} type="submit" disabled={loading}>
          {step !== 5 ? "Next" : "Save"}
        </AddButton>
      </Row>
    </Row>
  )
}
