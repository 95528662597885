import * as React from "react"
import { NormalizedCacheObject } from "apollo-cache-inmemory"
import ApolloClient from "apollo-client"
import { History } from "history"
import styled from "styled-components"
import { compose } from "react-apollo"
import { withRouter } from "react-router"
import withSizes from "react-sizes/lib/withSizes"
import { Subscribe } from "unstated"
import qs from "qs"

import AuthenticationState from "../../hoc/authentication/authentication.state"
import { removeStoredAuthState } from "../../utils/auth-utils"
import LogoSearchSection from "./logo-search-section.component"
import NotLoggedInSection from "./not-logged-in-section.component"
import LoggedInSection from "./logged-in-section.component"
import TabletDown from "./tablet-down.component"
import Box from "../box"
import { MobileNotLoggedIn } from "./mobile-not-logged-in"
import { MobileLoggedIn } from "./mobile-logged-in"

const Navigation = styled(Box as any)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px 0px 24px;
  width: 100%;
  height: 80px;
  background-color: white;
  position: sticky;
  border-bottom: 1px solid #e0e0e0;
  top: 0px;
  left: 0;
  ${props =>
    props.isSticky
      ? `box-shadow: 0 16px 16px rgba(103, 110, 144, 0.05), 0 8px 8px rgba(103, 110, 144, 0.05),
  0 4px 4px rgba(103, 110, 144, 0.05), 0 2px 2px rgba(103, 110, 144, 0.05);`
      : null}
  z-index: 100;
`

interface Props {
  isTabletDown: boolean
  client: ApolloClient<NormalizedCacheObject>
  history: History
  location: {
    pathname: string
  }
}
interface State {
  isMenuOpen: boolean
}
class NavigationBar extends React.PureComponent<Props, State> {
  state: State = {
    isMenuOpen: false
  }

  handleMenuToggle = () => {
    const { isMenuOpen } = this.state
    this.setState({ isMenuOpen: !isMenuOpen })
  }

  handleListWithUs = () => {
    const { isMenuOpen } = this.state
    this.setState({ isMenuOpen: !isMenuOpen })
  }


  handleSearchClick = () => {
    const { history } = this.props
    const { isMenuOpen } = this.state
    this.setState({ isMenuOpen: !isMenuOpen })
    let query: any = qs.parse(history.location.search, {
      ignoreQueryPrefix: true
    })
    if (!query.city) {
      query.city = "Toronto"
    }

    history.push({
      pathname: "/search",
      search: qs.stringify(query)
    })
  }

  render() {
    const {
      isTabletDown,
      location: { pathname }
    } = this.props
    const { isMenuOpen } = this.state

    let isSticky = false
    if (pathname.includes("/listing/")) {
      isSticky = true
    }

    return (
      <Subscribe to={[AuthenticationState]}>
        {(authState: AuthenticationState) => {
          return (
            <Navigation isSticky={isSticky}>
              <LogoSearchSection handleSearchClick={this.handleSearchClick} />
              {authState.state && authState.state.user ? (
                <React.Fragment>
                  {isTabletDown ? (
                    <React.Fragment>
                      {isMenuOpen && (
                        <MobileLoggedIn
                          authState={authState}
                          handleMenuToggle={this.handleMenuToggle}
                      
                          handleSearchClick={this.handleSearchClick}
                        />
                      )}
                      <TabletDown
                        isMenuOpen={isMenuOpen}
                        handleMenuToggle={this.handleMenuToggle}
                      />
                    </React.Fragment>
                  ) : (
                    <LoggedInSection
                      authState={authState}
                      handleSearchClick={this.handleSearchClick}
                    />
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {isTabletDown ? (
                    <React.Fragment>
                      {isMenuOpen && (
                        <MobileNotLoggedIn
                          authState={authState}
                          handleMenuToggle={this.handleMenuToggle}
                          history={this.props.history}
                          handleSearchClick={this.handleSearchClick}
                          handleListWithUs={this.handleListWithUs}
                        />
                      )}
                      <TabletDown
                        isMenuOpen={isMenuOpen}
                        handleMenuToggle={this.handleMenuToggle}
                      />
                    </React.Fragment>
                  ) : (
                    <NotLoggedInSection
                      authState={authState}
                      history={this.props.history}
                      handleSearchClick={this.handleSearchClick}
                    />
                  )}
                </React.Fragment>
              )}
            </Navigation>
          )
        }}
      </Subscribe>
    )
  }
}

const mapSizesToProps = ({ width }: any) => ({
  isTabletDown: width <= 900
})

export default compose(withSizes(mapSizesToProps), withRouter)(NavigationBar)
