import React, { useEffect, useState, memo } from "react"
import { FormControl } from "@material-ui/core"
import { PropType } from "./types"
import ImageUploading from "react-images-uploading"
import "../styles.scss"
import imageIcon from "./image-upload.png"
import cancelIcon from "./cancel.png"
import Text from "../../text"
import Compressor from "compressorjs"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { NOTIFICATION_MSG } from "src/constants/notification-mesage"
import { notify } from "src/components/notification-toast"
import { picReorder } from "src/services"
import { client } from "src/apollo"

let compressedImages: any = []
const PicturePicker = ({
  icon,
  form: { touched, errors, setFieldValue },
  field,
  labelTxt,
  styleInput,
  ...rest
}: PropType) => {
  const [images, setImages] = useState(field.value || [])
  const maxNumber = 60

  useEffect(() => {
    compressedImages = [...(field.value || [])]
    return () => {
      compressedImages = []
    }
  }, [field.value])

  const handleImageCompression = async image => {
    new Compressor(image["file"], {
      quality: 0.6,
      convertSize: 3000000,
      success: async compressedResult => {
        let reader = new FileReader()
        reader.readAsDataURL(compressedResult)
        reader.onload = (e: any) => {
          compressedImages = [
            ...compressedImages,
            { file: compressedResult, url: e.target?.result, compress: true }
          ]
          setImages(compressedImages)
          setFieldValue(field.name, compressedImages)
        }
      },
      error(err) {
        console.log(err.message)
      }
    })
  }
  const onChange = async (imageList: any) => {
    for (let i = 0; i < imageList.length; i++) {
      if (!("compress" in imageList[i])) {
        await handleImageCompression(imageList[i])
      }
    }
  }

  const handleDeleteImage = index2 => {
    const data = images.filter((item, index) => index !== index2)
    compressedImages = [...data]
    setImages(data)
    setFieldValue(field.name, data)
  }

  let dragId = ""
  const imageDragOver = event => event.preventDefault()
  const imageDragStart = event => (dragId = event.target.id)

  const dropImage = event => {
    event.preventDefault()
    const dragElement = dragId.split("-")
    let dragIndex: any = ""
    if (dragElement.length > 1) {
      dragIndex = dragElement[0]
    }
    const dropElement = event.target.id.split("-")
    let dropIndex: any = ""
    if (dropElement.length > 1) {
      dropIndex = dropElement[0]
    }
    if (dragIndex !== "" && dropIndex !== "") {
      const dragObject = images[dragIndex]
      images.splice(dragIndex, 1)
      images.splice(dropIndex, 0, dragObject)

      for (let i = 0; i < images.length; i++) {
        if (!("compress" in images[i])) {
          handleImageCompression(images[i])
        }
      }
    }
  }

  var acceptFile = ["jpg", "jpeg", "png"]

  const handleDrop = async droppedItem => {
    if (!droppedItem.destination) return
    const { destination } = droppedItem
    const updatedList = images
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)
    updatedList.splice(destination.index, 0, reorderedItem)
    const orderList = updatedList.map(
      ({ url, filename, createdAt, updatedAt, __typename, reorderPic, ...rest }) => {
        return rest
      }
    )

    const resp = await picReorder(orderList, client)
    if (resp) {
      notify(NOTIFICATION_MSG.Success, "Update successfully!")

    } else {
      notify(NOTIFICATION_MSG.error, "Something went wrong ")
    }
  }


  return (
    <FormControl id="custom-form" fullWidth style={{ marginBottom: 15 }}>
      <div className="buildings-picture-container">
        {labelTxt && (
          <Text genre="labelText" mb={0}>
            {labelTxt}
          </Text>
        )}
        <div className="buildings-picture-imgae" style={styleInput}>
          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="url"
            acceptType={acceptFile}
          >
            {({ onImageUpload, isDragging, dragProps }) => (
              <div className="upload__image-wrapper" {...dragProps} onClick={onImageUpload}>
                <img
                  src={imageIcon}
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                  alt=""
                />
                <p>Drag images or click here to choose and upload</p>
                <p>Supported formats: PNG, JPEG, JPG</p>
              </div>
            )}
          </ImageUploading>
        </div>
        {/* preview Image  */}
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-container" direction="horizontal">
            {provided => (
              <div className="buildings-image-previews"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                {images &&
                  images.map((image, index) => (
                    <Draggable key={image.id} draggableId={image.id ?? index.toString()} index={index}>
                      {provided => (
                        <div
                          key={image.id}
                          className="buildings-image-preview"
                          id={`${index}-div`}
                          onDrop={dropImage}
                          onDragOver={imageDragOver}
                          draggable={true}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <a href={image.url} target="_blank" rel="noopener noreferrer">
                            <img
                              src={image.url}
                              alt=""
                              id={`${index}-img`}
                              draggable={true}
                              data-holder-rendered="true"
                              onDragStart={imageDragStart}
                              className="buildings-imageIcon"
                            />
                          </a>

                          <img
                            src={cancelIcon}
                            className="buildings-cancelIcon"
                            alt=""
                            onClick={() => handleDeleteImage(index)}
                          />
                          <div
                            className="buildings-between-margin"
                            id={`${index}-div`}
                            onDrop={dropImage}
                            onDragOver={imageDragOver}
                            draggable={true}
                          ></div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {errors[field.name] && (
        <div className="error-text">
          {rest.create_err_msg ? rest.create_err_msg(field.value) : errors[field.name]}
        </div>
      )}
    </FormControl>
  )
}

export default memo(PicturePicker)
