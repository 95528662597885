import gql from "graphql-tag"

export const FetchRecommendedListingsDocument = gql`
  query recommendListings($where: ListingWhereInput) {
    recommendListings(where: $where) {
      id
      title
      units {
        id
        monthlyPrice
      }

      pictures {
        id
        url
      }
    }
  }
`

export const FetchAllListings = gql`
  query adminAccessListing {
    adminAccessListing {
      id
      title
      moveInBonuses
      description
      buildingConstructionYear
      totalUnitsInBuilding
      storiesInBuilding
      developmentCompany
      propertyType
      rentedPrice
      tuDetails
      leadsTolobi
      leadsZumper
      leadsRentals
      leadsZillow
      leadsMarketplace
      leadsKijiji
      leadsCraigslist
      leadsRealtor
      leadsCraigslistEnabled,
      leadsKijijiEnabled,
      leadsMarketplaceEnabled,
      leadsRealtorEnabled,
      leadsRentalsEnabled,
      leadsTolobiEnabled,
      leadsZillowEnabled,
      leadsZumperEnabled,
      leadsCraigslistDisabled,
      leadsKijijiDisabled,
      leadsMarketplaceDisabled,
      leadsRealtorDisabled,
      leadsRentalsDisabled,
      leadsTolobiDisabled,
      leadsZillowDisabled,
      leadsZumperDisabled
      units {
        id
        title
        number
        den
        available
        startDateAvailability
        unitSize
        bedrooms
        monthlyPrice
        bathrooms
        floorplan
        furnished
        parkingSpots
        deposit
        leaseTerm
        parking
      }
    }
  }
`


export const REORDER_PIC = gql`
  mutation picReorder($where: PicReorderType) {
    picReorder(where: $where) {
      id
    }
  }
`