import React, { useState, useRef, useEffect } from "react"
import { CREATE_RENTAL } from "./rental-application.mutation"
import { graphql, compose } from "react-apollo"
import "./style.scss"
import moment from "moment"
import { notify } from "src/components/notification-toast"
import PhoneInput from "react-phone-input-2"
import { withStyles } from "@material-ui/core/styles"
import SignaturePad from "react-signature-canvas"
import Button from "@material-ui/core/Button"
import CloseIcon from "@material-ui/icons/Close"
import { makeStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ReplayIcon from "@material-ui/icons/Replay"
// import EditIcon from "@material-ui/icons/Edit"
import SaveIcon from "@material-ui/icons/Save"
import InfoIcon from "@material-ui/icons/Info"
import styled from "styled-components"
// import SearchMapAdd from "src/components/FormComponents/mapBox-address"
import TextField from "@material-ui/core/TextField"
import { NOTIFICATION_MSG } from "src/constants/notification-mesage"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Grid } from "@material-ui/core"
import { SimpleFileUpload } from "src/components/FileUpload/NativeFileUpload"
import { isValidEmail, isValidUrl } from "src/utils/comman-methods"
import { AddressField } from "./components/input-components"
import { isEmpty } from "lodash"
import { getNumberWithOrdinal } from "src/utils/comman-methods"
import AddressAutoComplete from "src/dashboards/components/rental-address-autocomplete/address-autocomplete"
import { uploadFiles } from "src/services/requests/file-upload"
import { client } from "src/apollo"
import { ErrorModal, SuccessModal } from "src/components/static-modals"
import { useHistory } from "react-router-dom"
import { MuiLoader } from "src/dashboards/components/loader/loader"
import { Tooltip } from "antd"
import ICurrencyMask from "src/dashboards/components/input-fields/currency-mask-input"

const antdCssDelay = () => import("antd/dist/antd.css")
const delay = () => import("react-phone-input-2/lib/style.css")
setTimeout(() => {
  delay()
  antdCssDelay()
}, 0)

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

const FormComponent = styled.div<any>`
  margin: 10px 0;
  width: 50%;
`

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green"
    },
    "& .MuiInput-underline:after": {
      color: "#00A857",
      border: "1px solid #D9D9D9",
      borderRadius: "1px"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: "#00A857",
        border: "1px solid #D9D9D9",
        borderRadius: "1px"
      },
      "&:hover fieldset": {
        color: "#00A857",
        border: "1px solid #D9D9D9",
        borderRadius: "1px"
      },
      "&.Mui-focused fieldset": {
        color: "#00A857",
        border: "1px solid #D9D9D9",
        borderRadius: "1px"
      }
    }
  }
})(TextField)

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div className="">{children}</div>}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
    disableRipple: true,
    disableFocusRipple: true,
    style: {
      cursor: "default",
      opacity: 1,
      whiteSpace: "nowrap"
    }
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    overFlowX: "hidden",
    "& .MuiTab-textColorInherit": {
      opacity: "1 !important",
      minHeight: "20px !important"
    }
  },
  root1: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabs: {
    display: "flex",
    marginTop: "20px",
    marginLeft: "100px !important",
    marginRight: "30px",
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start"
    },
    "&.MuiTab-textColorInherit": {
      opacity: 1
    }
  },
  tabs2: {
    padding: "10px",
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start"
    },
    "&.MuiTab-textColorInherit": {
      opacity: 1
    }
  },
  indicator: {
    "&.MuiTab-textColorInherit": {
      opacity: 1
    },
    color: "#00A857 !important"
  },
  textFieldBorder: {
    border: "1px solid #D9D9D9",
    minWidth: "444px",
    width: "100%",
    padding: "15px"
  },
  textFieldBorder1: {
    border: "1px solid #D9D9D9",
    minWidth: "auto",
    width: "100%",
    padding: "15px"
  },
  radioGroup: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between"
  },
  radioGroupMulti: {
    display: "flex",
    flexFlow: "column nowrap"
  },
  formGroup: {
    display: "flex",
    flexFlow: "row wrap"
  },
  formControlLabel: {
    borderRadius: "2px",
    paddingRight: "10px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D9D9D9",
    margin: 0,
    padding: "10px 0"
  },
  formControlLabelRadio: {
    borderRadius: "2px",
    padding: "10px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D9D9D9 !important",
    margin: 0,
    height: "100%"
  },
  formControlLabelSwitch: {
    borderRadius: "2px",
    height: "100%",
    width: "100%",
    padding: "10px 0",
    // backgroundColor: " #FFFFFF",
    border: "1px solid #D9D9D9",
    marginLeft: "0px !important",
    marginRight: "0px !important"
  },
  selected: {
    backgroundColor: "#00A857",
    color: "#fff"
  },
  formControl: {
    margin: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0)
  },
  btn: {
    color: "white",
    margin: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "12px 32px",
    gap: "8px",
    width: "130px",
    height: "47px",
    background: "#00A857",
    borderRadius: "64px",
    border: "none",
    "&:hover": {
      backgroundColor: "#00A857"
    }
  },
  draftBtnOut: {
    color: "#000000",
    border: "1px solid #909392",
    padding: "8px 15px",
    borderRadius: "2px",
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "none",
    width: "170px"
  },
  draftBtn: {
    backgroundColor: "#00A857",
    color: "#fff",
    padding: "8px 15px",
    borderRadius: "2px",
    fontSize: "14px",
    textTransform: "none",
    fontWeight: 600,
    width: "170px",
    "&:hover": {
      backgroundColor: "#00A857"
    }
  },
  action__buttons: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginLeft: 100,
    marginTop: 10
  },
  draftBtn1: {
    backgroundColor: "#00A857",
    color: "white",
    padding: "8px 15px",
    borderRadius: "0px",
    fontSize: "14px",
    fontWeight: 600,
    position: "relative",
    "&:hover": {
      backgroundColor: "#00A857"
    }
  },
  borderClass: {
    "&.react-tel-input .form-control:focus": {
      borderBottom: "1px solid rgba(111, 214, 33, 0.7411764706) !important"
    },
    "& .selected-flag:hover, & .selected-flag, & .selected-flag:focus, & .flag-dropdown.open": {
      backgroundColor: "transparent !important"
    },
    border: "1px solid #D9D9D9",
    padding: "10px"
  },
  borderClass2: {
    "&.react-tel-input .form-control:focus": {
      borderBottom: "1px solid rgba(111, 214, 33, 0.7411764706) !important"
    },
    "& .selected-flag:hover, & .selected-flag, & .selected-flag:focus, & .flag-dropdown.open": {
      backgroundColor: "transparent !important"
    },
    border: "1px solid #D9D9D9"
  },
  container: {
    width: "100%",
    height: "250px",
    top: "10%",
    left: "10%"
  },
  sigContainer: {
    width: "100%",
    height: "100%",
    margin: "0 auto",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px"
  },
  sigPad: {
    width: "100%",
    height: "100%"
  },
  buttons: {
    width: "100%",
    height: "30px"
  },
  sigImage: {
    maxHeight: "100%",
    objectFit: "cover",
    backgroundColor: "white"
  }
}))

const WhiteRadio = withStyles({
  root: {
    color: "#00A857",
    "&$checked": {
      color: "white"
    }
  },
  checked: {}
})(((props: any) => <Radio color="default" {...props} />) as any) as any

const ColorButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: "#00A857",
    borderRadius: "25px",
    width: "100%",
    maxWidth: 300,
    display: "flex",
    margin: "auto",
    marginTop: "20px",
    padding: "10px",
    "&:hover": {
      backgroundColor: "#00A857"
    }
  }
}))(Button as any)

export const UploadButton = withStyles(() => ({
  root: {
    color: "#00A857",
    backgroundColor: "white",
    boxShadow: "0 2px 6px -2px rgba(0, 0, 0, 0.5)",
    borderRadius: "25px",
    width: "150px",
    marginTop: "20px",
    padding: "10px"
  }
}))(Button as typeof Button)

const Error = styled.div`
  color: red;
  text-transform: none;
  text-align: left;
  width: 100%;
  padding: 0;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  margin-left: 8px;
  display: flex;
  align-items: center;
`
export const CustomTextField = withStyles({
  root: {
    "& label": {
      color: "#ffffff7f",
      opacity: 0.8,
      fontWeight: 400,
      fontSize: "12px"
    },
    "& .MuiTextField-root": {
      borderBottomColor: "white"
    },
    "& .MuiAutocomplete-input": {
      color: "#00A857"
    },
    "& label.Mui-focused": {
      color: "white"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "1px solid red !important"
      // display:'none'
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00A857"
      },
      "&:hover fieldset": {
        borderColor: "#00A857"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#00A857"
      }
    },
    border: "1px solid #D9D9D9 !important",
    // minWidth: "650px",
    width: "100%",
    padding: "10px"
  }
})(TextField) as any

// const MAX_COUNT = 5

const FIRST_APP_INIT_STATE = {
  applicationDate: moment.utc().format(),
  commencementDate: moment().format("YYYY-MM-DD"),
  applyingAs: "",
  rentalPropertyAddress: "",
  unitId: "",
  listingId: "",
  guaranteeFullName: "",
  propertySeen: {
    viewing: true,
    scheduled: false,
    providedPictures: false,
    other: false
  },
  otherPropertySeen: "",
  yourOffer: "",
  leaseTerm: "",
  error: false
}

const SECOND_APP_INIT_STATE = {
  fName: "",
  lName: "",
  dob: moment()
    .subtract(12, "years")
    .format("YYYY-MM-DD"),
  phone: "",
  userEmail: "",
  confirmEmail: "",
  address: {
    street: "",
    city: "",
    province: "",
    postal: "",
    country: "Canada"
  },
  livingDate: moment().format("YYYY-MM-DD"),
  renting: false,
  landlordName: "",
  landlordEmail: "",
  landlordConfirmEmail: "",
  landlordPhone: "",
  reachingTime: {
    weekdayMorning: true,
    weekdayAfternoon: false,
    weekendMorning: false,
    weekendAfternoon: false,
    notSure: false
  },
  leaseAgreementFile: [],
  reasonLeaving: "",
  previousAddress: {
    street: "",
    city: "",
    province: "",
    postal: "",
    country: "Canada"
  },
  previousLivingDate: moment().format("YYYY-MM-DD"),
  previousRenting: false,
  previousLandlordName: "",
  previousLandlordPhone: "",
  previousLandlordEmail: "",
  previousLandlordConfirmEmail: "",
  smoker: false,
  error: false
}

const THIRD_APP_INIT_STATE = {
  grossIncome: "",
  selfEmployed: false,
  website: "",
  expenseFigures: "",
  revenueSalesFigures: "",
  selfEmployedDocs: {
    bankStatement: false,
    taxReturns: false,
    accountantContact: false,
    invoices: false
  },
  bankStatementFiles: [],
  taxReturnsFiles: [],
  invoicesFiles: [],
  accountantEmail: "",
  accountantPhone: "",
  occupation: "",
  currentEmployer: "",
  currentEmployerAddress: {
    street: "",
    city: "",
    province: "",
    postal: "",
    country: "Canada"
  },
  currentEmployerPhone: "",
  employmentTerm: "",
  employmentStatus: {
    temporary: false,
    permanent: false,
    partTime: false,
    fullTime: false,
    contract: false
  },
  reachingTime: {
    weekdayMorning: false,
    weekdayAfternoon: false,
    weekendMorning: false,
    weekendAfternoon: false,
    notSure: false
  },
  currentEmploymentEndDate: moment().format("YYYY-MM-DD"),
  employmentReference: "",
  referenceStatus: "",
  employmentReferencePhone: "",
  employmentReferenceEmail: "",
  employmentReferenceConfirmEmail: "",
  firstEmployer: false,
  previousEmployer: "",
  linkedIn: "",
  error: false,
  previousEmployerAddress: {
    street: "",
    city: "",
    province: "",
    postal: "",
    country: ""
  },
  previousPositionTitle: ""
}

const FOURTH_APP_INIT_STATE = {
  financialObligation: {
    none: false,
    house: false,
    vehicles: false,
    medical: false,
    education: false
  },
  monthlyPayment: "",
  entities: "",
  referenceName: "",
  referencePhone: "",
  referenceEmail: "",
  referenceRelationship: {
    friend: true,
    neighbour: false,
    colleague: false,
    relative: false
  },
  reachingTime: {
    weekdayMorning: true,
    weekdayAfternoon: false,
    weekendMorning: false,
    weekendAfternoon: false,
    notSure: false
  },
  pets: false,
  petType: {
    dog: false,
    cat: false,
    fish: false,
    bird: false,
    other: false
  },
  otherPetType: "",
  breed: "",
  weight: "",
  petDescription: "",
  petFiles: [],
  error: false
}

const FIFTH_APP_INIT_STATE = {
  vehicle: false,
  makeOne: "",
  modelOne: "",
  anotherVehicle: false,
  makeTwo: "",
  modelTwo: "",
  coOccupants: "0",
  occupantsNumber: 0,
  occupant_1_name: "",
  occupant_2_name: "",
  occupant_3_name: "",
  occupant_4_name: "",
  occupant_5_name: "",
  occupant_1_error: false,
  occupant_2_error: false,
  occupant_3_error: false,
  occupant_4_error: false,
  occupant_5_error: false,
  error: false
}
const SIXTH_APP_INIT_STATE = {
  creditScoreFile: [],
  sin: "",
  proofOfIncomeFile: [],
  govIdsFile: [],
  supplementaryDocFile: [],
  intro: "",
  error: false
}

const SEVENTH_APP_INIT_STATE = {
  signature: null,
  error: false
}
const encode = string => {
  if (!string) return ""
  let [integer, fraction] = string.split(".")
  integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return fraction ? `${integer}.${fraction}` : integer
}

const decode = string => string.replace(/,/g, "")

export const splitAddress = (value: string) => value?.split("#")?.filter(Boolean)?.[0]

const EmailComponent = props => {
  const { required, error, ...rest } = props

  const [emailError, setEmailError] = useState("")

  const handleEmailBlur = () => {
    if (!rest.value && required) {
      setEmailError("*This field is required")
    } else if (!isValidEmail(rest.value) && rest.value) {
      setEmailError("Invalid email address")
    } else {
      setEmailError("")
    }
  }
  useEffect(() => {
    if (error) {
      handleEmailBlur()
    }
  }, [error])

  return (
    <>
      <input type="email" className="inputField full" onBlur={handleEmailBlur} {...rest} />
      {!!emailError && <Error>{emailError}</Error>}
    </>
  )
}

const EmailAndConfirmEmailComponent = props => {
  const {
    emailName,
    confirmEmailName,
    emailValue,
    confirmEmailValue,
    handleEmailChange,
    handleConfirmEmailChange,
    required = false,
    error = false
  } = props

  const [emailError, setEmailError] = useState("")

  const handleEmailBlur = () => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailValue && required) {
      setEmailError("*This field is required")
    } else if (!isValidEmail(emailValue) && emailValue) {
      setEmailError("Invalid email address")
    } else if (confirmEmailValue && emailValue !== confirmEmailValue) {
      setEmailError("Email addresses do not match")
    } else {
      setEmailError("")
    }
  }

  const handleConfirmEmailBlur = () => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!confirmEmailValue && required) {
      setEmailError("*This field is required")
    } else if (!isValidEmail(confirmEmailValue) && confirmEmailValue) {
      setEmailError("Invalid email address")
    } else if (emailValue !== confirmEmailValue) {
      setEmailError("Email addresses do not match")
    } else {
      setEmailError("")
    }
  }

  useEffect(() => {
    if (error) {
      handleEmailBlur()
      handleConfirmEmailBlur()
    }
  }, [error])

  return (
    <>
      <input
        type="email"
        className="inputField full"
        placeholder="Email"
        value={emailValue}
        onChange={handleEmailChange}
        name={emailName}
        onBlur={handleEmailBlur}
      />
      <input
        type="email"
        className="inputField full"
        placeholder="Confirm email"
        value={confirmEmailValue}
        onChange={handleConfirmEmailChange}
        name={confirmEmailName}
        style={{ marginTop: 16 }}
        onBlur={handleConfirmEmailBlur}
      />
      {!!emailError && <Error>{emailError}</Error>}
    </>
  )
}

const ApplicationFirst = props => {
  const { firstFormState, setFirstFormState, error } = props

  const handlePropertySeenChange = event => {
    setFirstFormState({
      ...firstFormState,
      propertySeen: { ...firstFormState.propertySeen, [event.target.name]: event.target.checked }
    })
  }

  const { viewing, scheduled, providedPictures, other } = firstFormState.propertySeen
  const classes = useStyles()

  const handleRadioChange = event => {
    setFirstFormState({ ...firstFormState, applyingAs: event.target.value })
  }

  const handleCommencementDateChange = event => {
    setFirstFormState({ ...firstFormState, commencementDate: event?.target.value })
  }

  const handleTextInputChange = (event, type = "text") => {
    if (type === "number") {
      if (/^[0-9]*$/.test(event.target.value)) {
        setFirstFormState({ ...firstFormState, [event.target.name]: event.target.value })
      }
    } else if (type === "money") {

      setFirstFormState({ ...firstFormState, [event.target.name]: event.target.value });
    } else if (type === "float" && /^[+-]?(\d*\.)?\d+$/.test(event.target.value)) {
      setFirstFormState({ ...firstFormState, [event.target.name]: event.target.value })
    } else {
      setFirstFormState({ ...firstFormState, [event.target.name]: event.target.value })
    }
  }
  return (
    <div className="rental-application ">
      <div className="form">
        <h2 className="h2"> Rental Offer</h2>
        <div className="field-container">
          <div>
            <p>Application date</p>
          </div>
          <p className="date__container"> {moment().format("YYYY-MM-DD")}</p>
        </div>
        <div className="field-container">
          <div>
            <p>I am applying as a*</p>
          </div>
          <RadioGroup
            aria-label="quiz"
            name="quiz"
            value={firstFormState.applyingAs}
            onChange={handleRadioChange}
            className={`${classes.radioGroup} mediaCss`}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  value="tenant"
                  control={<WhiteRadio />}
                  label="Tenant/Occupant"
                  className={`${classes.formControlLabelRadio} ${firstFormState.applyingAs ===
                    "tenant" && classes.selected}`}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  value="guarantor"
                  control={<WhiteRadio />}
                  label="Guarantor/Co-signor"
                  className={`${classes.formControlLabelRadio} ${firstFormState.applyingAs ===
                    "guarantor" && classes.selected}`}
                />
              </Grid>
            </Grid>
          </RadioGroup>
          {error && firstFormState.applyingAs === "" && <Error>*This field is required</Error>}
        </div>
        {firstFormState.applyingAs === "guarantor" && (
          <div className="field-container">
            <div>
              <p>Full name of the person you're guaranteeing/co-signing for?*</p>
            </div>
            <input
              className="inputField full"
              value={firstFormState.guaranteeFullName}
              onChange={handleTextInputChange}
              name="guaranteeFullName"
            />
            {error && (
              <Error>{!firstFormState.guaranteeFullName && "*This field is required"}</Error>
            )}
          </div>
        )}
        <div className="field-container">
          <div>
            <p>Rental property address*</p>
            <label>Address of the rental property you're applying for!</label>
          </div>
          <div>
            <AddressAutoComplete
              handleChangeInput={value =>
                setFirstFormState(prev => ({
                  ...prev,
                  rentalPropertyAddress: value.title,
                  unitId: value.unitId,
                  listingId: value.listingId
                }))
              }
              inputValue={{
                title: firstFormState.rentalPropertyAddress,
                unitId: firstFormState.unitId,
                listingId: firstFormState.listingId
              }}
            />
          </div>
          {error && firstFormState.rentalPropertyAddress === "" && (
            <Error>*This field is required</Error>
          )}
        </div>
        {firstFormState.applyingAs === "tenant" && (
          <>
            <div className="field-container">
              <div>
                <p>Have you seen the above property in person?*</p>
              </div>
              <FormGroup className={classes.formGroup}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={viewing}
                          onChange={handlePropertySeenChange}
                          name="viewing"
                          className="theme-color"
                        />
                      }
                      label="Yes, I attended the viewing"
                      className={`${classes.formControlLabelSwitch} ${viewing && classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={scheduled}
                          onChange={handlePropertySeenChange}
                          name="scheduled"
                          className="theme-color"
                        />
                      }
                      label="No, but I have a viewing scheduled"
                      className={`${classes.formControlLabelSwitch} ${scheduled &&
                        classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={providedPictures}
                          onChange={handlePropertySeenChange}
                          name="providedPictures"
                          className="theme-color"
                        />
                      }
                      label="No, I am fine with pictures/videos provided"
                      className={`${classes.formControlLabelSwitch} ${providedPictures &&
                        classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={other}
                          onChange={handlePropertySeenChange}
                          name="other"
                          className="theme-color"
                        />
                      }
                      label="Other"
                      className={`${classes.formControlLabelSwitch} ${other && classes.selected}`}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
              {other && (
                <input
                  type="text"
                  className="inputField full margin-top"
                  placeholder="Specify other..."
                  value={firstFormState.otherPropertySeen}
                  onChange={handleTextInputChange}
                  name="otherPropertySeen"
                />
              )}
              {error &&
                firstFormState.applyingAs === "tenant" &&
                firstFormState.propertySeen.viewing === false &&
                firstFormState.propertySeen.scheduled === false &&
                firstFormState.propertySeen.providedPictures === false &&
                firstFormState.propertySeen.other === false && (
                  <Error>*This field is required</Error>
                )}
              {error &&
                firstFormState.propertySeen.other === true &&
                firstFormState.otherPropertySeen === "" && <Error>*This field is required</Error>}
            </div>
            <div className="field-container">
              <div>
                <p>Your offer*</p>
                <label>Monthly rent</label>
              </div>
              <ICurrencyMask
                className="inputField full"
                type="text"
                unmask='typed'
                name="yourOffer"
                value={firstFormState.yourOffer}
                onChange={event => handleTextInputChange(event, "money")}
              />
              {error && firstFormState.yourOffer === "" && <Error>*This field is required</Error>}
            </div>
          </>
        )}
        <div className="field-container">
          <div>
            <p>Preferred commencement date*</p>
          </div>
          <CssTextField
            type="date"
            variant="outlined"
            fullWidth
            value={firstFormState.commencementDate}
            onChange={handleCommencementDateChange}
            InputProps={{
              inputProps: {
                style: {
                  color: "#00A857"
                }
              }
            }}
          />
          {error && firstFormState.commencementDate === "" && (
            <Error>*This field is required</Error>
          )}
        </div>
        <div className="field-container">
          <div>
            <p>Preferred lease term*</p>
            <label>How many months?</label>
          </div>
          <input
            className="inputField full"
            placeholder="12"
            type="number"
            value={firstFormState.leaseTerm}
            onChange={event => handleTextInputChange(event, "float")}
            name="leaseTerm"
          />
          {error && (
            <Error>
              {firstFormState.leaseTerm === ""
                ? "*This field is required"
                : (+firstFormState.leaseTerm < 0.1 || +firstFormState.leaseTerm > 100) &&
                "Use numbers between 0.1 and 100."}
            </Error>
          )}
        </div>
      </div>
    </div>
  )
}

const ApplicationSecond = props => {
  const { secondFormState, setSecondFormState, error, applyingAs } = props

  const classes = useStyles()

  const handleChange = event => {
    setSecondFormState({
      ...secondFormState,
      reachingTime: { ...secondFormState.reachingTime, [event.target.name]: event.target.checked }
    })
  }
  const handleDateChange = event => {
    setSecondFormState({ ...secondFormState, dob: event?.target?.value })
  }

  const handleLivingDate = event => {
    setSecondFormState({ ...secondFormState, livingDate: event?.target?.value })
  }
  const handlePreviousLivingDate = event => {
    setSecondFormState({ ...secondFormState, previousLivingDate: event?.target.value })
  }

  const rentingHandler = rent => {
    return () => setSecondFormState({ ...secondFormState, renting: rent })
  }

  const previousRentingHandler = rent => {
    return () => setSecondFormState({ ...secondFormState, previousRenting: rent })
  }
  const smokingHandler = smoke => {
    return () => setSecondFormState({ ...secondFormState, smoker: smoke })
  }

  const handleTextInputChange = (event, type = "text", name = "phone") => {
    if (type === "phone") {
      setSecondFormState({ ...secondFormState, [name]: event })
    } else {
      setSecondFormState({ ...secondFormState, [event.target.name]: event.target.value })
    }
  }

  const handleCurrentAddressChange = (name: string, value: string) => {
    setSecondFormState({
      ...secondFormState,
      address: {
        ...secondFormState.address,
        [name]: value
      }
    })
  }
  const handlePreviousAddressChange = (name: string, value: string) => {
    setSecondFormState({
      ...secondFormState,
      previousAddress: {
        ...secondFormState.previousAddress,
        [name]: value
      }
    })
  }

  const {
    weekdayMorning,
    weekdayAfternoon,
    weekendMorning,
    weekendAfternoon,
    notSure
  } = secondFormState.reachingTime

  return (
    <div className="rental-application">
      <div className="form">
        <h2 className="h2">Applicant</h2>
        <Grid container spacing={2} className="field-container">
          <Grid item xs={12} md={6} style={{ marginBottom: 0 }}>
            <div>
              <p style={{ marginBottom: 18 }}>First name*</p>
              <input
                type="text"
                className="inputField full"
                name="fName"
                value={secondFormState.fName}
                onChange={handleTextInputChange}
              />
              {error && !secondFormState.fName?.trim() && <Error>*This field is required</Error>}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <p style={{ marginBottom: 18 }}>Last name*</p>
              <input
                type="text"
                className="inputField full"
                name="lName"
                value={secondFormState.lName}
                onChange={handleTextInputChange}
              />
              {error && !secondFormState.lName?.trim() && <Error>*This field is required</Error>}
            </div>
          </Grid>
        </Grid>
        <div className="field-container">
          <div>
            <p>Date of birth*</p>
          </div>
          <CssTextField
            type="date"
            variant="outlined"
            fullWidth
            value={secondFormState.dob}
            onChange={handleDateChange}
            InputProps={{
              inputProps: {
                style: {
                  color: "#00A857"
                },
                max: moment()
                  .subtract(12, "years")
                  .format("YYYY-MM-DD")
              }
            }}
          />
          {error && secondFormState.dob === "" && <Error>*This field is required</Error>}
        </div>
        <div className="field-container">
          <div>
            <p>Your primary phone number*</p>
          </div>
          <div style={{ width: "100%" }}>
            <PhoneInput
              countryCodeEditable={false}
              placeholder="(123) 456-7890"
              value={secondFormState.phone}
              onChange={phone => handleTextInputChange(phone, "phone", "phone")}
              country="ca"
              onlyCountries={["ca"]}
              priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
              containerClass={classes.borderClass}
              inputProps={{
                required: true,
                disableUnderline: true
              }}
              containerStyle={{
                height: "fit-content",
                position: "relative",
                width: "100%",
                padding: 0
              }}
              inputStyle={{
                height: "100%",
                width: "100%",
                boxShadow: "none",
                border: "none",
                outline: "none",
                // borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
                color: "#00A857",
                fontSize: "18px",
                letterSpacing: "0.5px",
                backgroundColor: "transparent",
                transition: "all 0.1s linear",
                borderRadius: 0,
                padding: "14px 42px"
              }}
              buttonStyle={{
                border: "none",
                outline: "none",
                color: "#00A857",
                // fontSize: "18px",
                // letterSpacing: "0.5px",
                backgroundColor: "transparent",
                transition: "all 0.1s linear",
                borderRadius: 0
              }}
            />
          </div>
          {console.log({ error })}
          {error && secondFormState.phone?.length !== 11 && (
            <Error>Please enter a valid phone number</Error>
          )}
        </div>
        <div className="field-container">
          <div>
            <p>Your email address*</p>
          </div>
          <EmailAndConfirmEmailComponent
            emailName={"userEmail"}
            confirmEmailName={"confirmEmail"}
            emailValue={secondFormState.userEmail}
            confirmEmailValue={secondFormState.confirmEmail}
            handleEmailChange={handleTextInputChange}
            handleConfirmEmailChange={handleTextInputChange}
            required
            error={error}
          />
        </div>
        <div className="field-container">
          <div>
            <p>Your current home address*</p>
          </div>
          <AddressField
            {...secondFormState.address}
            handleStreetChange={(value: string) => handleCurrentAddressChange("street", value)}
            handleCityChange={(value: string) => handleCurrentAddressChange("city", value)}
            handleProvinceChange={(value: string) => handleCurrentAddressChange("province", value)}
            handlePostalChange={(value: string) => handleCurrentAddressChange("postal", value)}
            handleCountryChange={(value: string) => handleCurrentAddressChange("country", value)}
          />
          {error &&
            (!secondFormState.address.street ||
              !secondFormState.address.province ||
              !secondFormState.address.postal ||
              !secondFormState.address.country ||
              !secondFormState.address.city) && <Error>*This field is required</Error>}
        </div>
        <div className="field-container">
          <div>
            <p>
              Since when have you been living at{" "}
              {secondFormState.address.street &&
                secondFormState.address.province &&
                secondFormState.address.postal &&
                secondFormState.address.country
                ? `${secondFormState.address.street}, ${secondFormState.address.city ? `${secondFormState.address.city}, ` : ""
                }${secondFormState.address.province}, ${secondFormState.address.postal}, ${secondFormState.address.country
                }`
                : ""}
              ?
            </p>
          </div>
          <CssTextField
            type="date"
            variant="outlined"
            fullWidth
            value={secondFormState.livingDate}
            onChange={handleLivingDate}
            InputProps={{
              inputProps: {
                style: {
                  color: "#00A857"
                },
                max: moment().format("YYYY-MM-DD")
              }
            }}
          />
        </div>
        <div className="field-container">
          <div>
            <p>Are you renting currently?*</p>
          </div>
          <div className="bool-btn">
            <div
              onClick={rentingHandler(true)}
              className={secondFormState.renting ? classes.selected : undefined}
            >
              Yes
            </div>
            <div
              onClick={rentingHandler(false)}
              className={!secondFormState.renting ? classes.selected : undefined}
            >
              No
            </div>
          </div>
        </div>
        {secondFormState.renting && (
          <>
            <div className="field-container">
              <div>
                <p>Current landlord's name*</p>
              </div>
              <input
                type="text"
                className="inputField full"
                value={secondFormState.landlordName}
                onChange={handleTextInputChange}
                name="landlordName"
              />
              {error && secondFormState.landlordName === "" && (
                <Error>*This field is required</Error>
              )}
            </div>
            <div className="field-container">
              <div>
                <p>{secondFormState.landlordName}'s email address</p>
                <label>
                  We ask this for landlord reference checks. Your landlord will receive an email
                  from us requesting for reference checks immediately after you submit this
                  application.
                </label>
              </div>
              <EmailAndConfirmEmailComponent
                emailName={"landlordEmail"}
                confirmEmailName={"landlordConfirmEmail"}
                emailValue={secondFormState.landlordEmail}
                confirmEmailValue={secondFormState.landlordConfirmEmail}
                handleEmailChange={handleTextInputChange}
                handleConfirmEmailChange={handleTextInputChange}
                error={error}
              />
            </div>
            <div className="field-container">
              <div>
                <p>{secondFormState.landlordName}'s phone number*</p>
                <label>We ask this for landlord reference checks.</label>
              </div>
              <PhoneInput
                countryCodeEditable={false}
                placeholder="(123) 456-7890"
                value={secondFormState.landlordPhone}
                onChange={phone => handleTextInputChange(phone, "phone", "landlordPhone")}
                country="ca"
                onlyCountries={["ca"]}
                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                containerClass={classes.borderClass}
                containerStyle={{
                  height: "fit-content",
                  position: "relative",
                  width: "100%",
                  padding: 0
                }}
                inputProps={{
                  disableUnderline: true
                }}
                inputStyle={{
                  height: "100%",
                  width: "100%",
                  boxShadow: "none",
                  border: "none",
                  outline: "none",
                  // borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
                  color: "#00A857",
                  fontSize: "18px",
                  letterSpacing: "0.5px",
                  backgroundColor: "transparent",
                  transition: "all 0.1s linear",
                  borderRadius: 0,
                  padding: "14px 42px"
                }}
                buttonStyle={{
                  border: "none",
                  outline: "none",
                  color: "#00A857",
                  // fontSize: "18px",
                  // letterSpacing: "0.5px",
                  backgroundColor: "transparent",
                  transition: "all 0.1s linear",
                  borderRadius: 0
                }}
              />
              {error && secondFormState.landlordPhone?.length !== 11 && (
                <Error>Please enter a valid phone number</Error>
              )}
            </div>
            <div className="field-container">
              <div>
                <p>When is the best time to reach {secondFormState.landlordName}?</p>
              </div>
              <FormGroup className={classes.formGroup}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={weekdayMorning}
                          onChange={handleChange}
                          name="weekdayMorning"
                        />
                      }
                      label="Weekday morning"
                      className={`${classes.formControlLabelSwitch} ${weekdayMorning &&
                        classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={weekdayAfternoon}
                          onChange={handleChange}
                          name="weekdayAfternoon"
                        />
                      }
                      label="Weekday afternoon"
                      className={`${classes.formControlLabelSwitch} ${weekdayAfternoon &&
                        classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={weekendMorning}
                          onChange={handleChange}
                          name="weekendMorning"
                        />
                      }
                      label="Weekend morning"
                      className={`${classes.formControlLabelSwitch} ${weekendMorning &&
                        classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={weekendAfternoon}
                          onChange={handleChange}
                          name="weekendAfternoon"
                        />
                      }
                      label="Weekend afternoon"
                      className={`${classes.formControlLabelSwitch} ${weekendAfternoon &&
                        classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={notSure}
                          onChange={handleChange}
                          name="notSure"
                        />
                      }
                      label="Not sure"
                      className={`${classes.formControlLabelSwitch} ${notSure && classes.selected}`}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </div>
            {applyingAs === "tenant" && (
              <>
                <div className="field-container">
                  <div>
                    <p>Upload a copy of your current lease agreement</p>
                    <label>
                      You may upload your current lease agreement if you wish to speed up the
                      landlord reference check process.
                    </label>
                  </div>
                  <div style={{ marginTop: "12px" }}>
                    <SimpleFileUpload
                      files={secondFormState?.leaseAgreementFile?.filter(item => item && item.name)}
                      labelName="Upload"
                      listTitle=""
                      handleFileChange={files =>
                        setSecondFormState({ ...secondFormState, leaseAgreementFile: files })
                      }
                      uploadButtonContainerProps={{
                        style: {
                          marginLeft: 0
                        }
                      }}
                      acceptedFiles=".jpg,.jpeg,.png,.pdf"
                      maxFiles={10}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {applyingAs === "tenant" && (
          <div className="field-container">
            <div>
              <p>Reasons for leaving your current residence?*</p>
            </div>
            <textarea
              className="textarea-field full"
              value={secondFormState.reasonLeaving}
              onChange={handleTextInputChange}
              name="reasonLeaving"
            />
            {error && !secondFormState.reasonLeaving && <Error>*This field is required</Error>}
          </div>
        )}
        {applyingAs === "tenant" && (
          <>
            <div className="field-container">
              <div>
                <p>Your previous address</p>
                <label>Where do you live</label>
              </div>
              <AddressField
                {...secondFormState.previousAddress}
                handleStreetChange={(value: string) => handlePreviousAddressChange("street", value)}
                handleCityChange={(value: string) => handlePreviousAddressChange("city", value)}
                handleProvinceChange={(value: string) =>
                  handlePreviousAddressChange("province", value)
                }
                handlePostalChange={(value: string) => handlePreviousAddressChange("postal", value)}
                handleCountryChange={(value: string) =>
                  handlePreviousAddressChange("country", value)
                }
              />
            </div>
            <div className="field-container">
              <div>
                <p>
                  Since when have you been living at{" "}
                  {secondFormState.previousAddress.street && secondFormState.previousAddress.street}
                  {secondFormState.previousAddress.province &&
                    `, ${secondFormState.previousAddress.province}`}
                  {secondFormState.previousAddress.city &&
                    `, ${secondFormState.previousAddress.city}`}
                  {secondFormState.previousAddress.postal &&
                    `, ${secondFormState.previousAddress.postal}`}
                  {secondFormState.previousAddress.country &&
                    `, ${secondFormState.previousAddress.country}`}
                  ?
                </p>
              </div>
              <CssTextField
                type="date"
                variant="outlined"
                fullWidth
                value={secondFormState.previousLivingDate}
                onChange={handlePreviousLivingDate}
                InputProps={{
                  inputProps: {
                    style: {
                      color: "#00A857"
                    },
                    max: moment().format("YYYY-MM-DD")
                  }
                }}
              />
            </div>
          </>
        )}
        <div className="field-container">
          <div>
            <p>
              Were you renting at{" "}
              {secondFormState.previousAddress.street && secondFormState.previousAddress.street}
              {secondFormState.previousAddress.province &&
                `, ${secondFormState.previousAddress.province}`}
              {secondFormState.previousAddress.city && `, ${secondFormState.previousAddress.city}`}
              {secondFormState.previousAddress.postal &&
                `, ${secondFormState.previousAddress.postal}`}
              {secondFormState.previousAddress.country &&
                `, ${secondFormState.previousAddress.country}`}
              ?
            </p>
          </div>
          <div className="bool-btn">
            <div
              onClick={previousRentingHandler(true)}
              className={secondFormState.previousRenting ? classes.selected : undefined}
            >
              Yes
            </div>
            <div
              onClick={previousRentingHandler(false)}
              className={!secondFormState.previousRenting ? classes.selected : undefined}
            >
              No
            </div>
          </div>
        </div>
        {secondFormState.previousRenting && (
          <>
            <div className="field-container">
              <div>
                <p>Previous landlord's name</p>
              </div>
              <input
                type="text"
                className="inputField full"
                value={secondFormState.previousLandlordName}
                onChange={handleTextInputChange}
                name="previousLandlordName"
              />
            </div>
            <div className="field-container">
              <div>
                <p>{secondFormState.previousLandlordName}'s email address</p>
                <label>
                  Your landlord will receive an email from us requesting for reference checks
                  immediately after you submit this application.
                </label>
              </div>
              <EmailAndConfirmEmailComponent
                emailName={"previousLandlordEmail"}
                confirmEmailName={"previousLandlordConfirmEmail"}
                emailValue={secondFormState.previousLandlordEmail}
                confirmEmailValue={secondFormState.previousLandlordConfirmEmail}
                handleEmailChange={handleTextInputChange}
                handleConfirmEmailChange={handleTextInputChange}
                error={error}
              />
            </div>
            <div className="field-container">
              <div>
                <p>{secondFormState.previousLandlordName}'s phone number</p>
              </div>
              <PhoneInput
                countryCodeEditable={false}
                placeholder="(123) 456-7890"
                value={secondFormState.previousLandlordPhone}
                onChange={phone => handleTextInputChange(phone, "phone", "previousLandlordPhone")}
                country="ca"
                onlyCountries={["ca"]}
                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                containerClass={classes.borderClass}
                containerStyle={{
                  height: "fit-content",
                  position: "relative",
                  width: "100%",
                  padding: 0
                }}
                inputStyle={{
                  height: "100%",
                  width: "100%",
                  boxShadow: "none",
                  border: "none",
                  outline: "none",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
                  color: "#00A857",
                  fontSize: "18px",
                  letterSpacing: "0.5px",
                  backgroundColor: "transparent",
                  transition: "all 0.1s linear",
                  borderRadius: 0,
                  padding: "14px 42px"
                }}
                buttonStyle={{
                  border: "none",
                  outline: "none",
                  color: "#00A857",
                  backgroundColor: "transparent",
                  transition: "all 0.1s linear",
                  borderRadius: 0
                }}
                inputProps={{
                  disableUnderline: true
                }}
              />
            </div>
          </>
        )}
        {applyingAs === "tenant" && (
          <div className="field-container">
            <div>
              <p>Are you a smoker?</p>
            </div>
            <div className="bool-btn">
              <div
                onClick={smokingHandler(true)}
                className={secondFormState.smoker ? classes.selected : undefined}
              >
                Yes
              </div>
              <div
                onClick={smokingHandler(false)}
                className={!secondFormState.smoker ? classes.selected : undefined}
              >
                No
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const ApplicationThird = props => {
  const {
    thirdFormState,
    setThirdFormState,
    checkSelfEmployedDocs,
    isValidUrl,
    isValidEmail,
    error,
    matches
  } = props

  const classes = useStyles()

  const { bankStatement, taxReturns, accountantContact, invoices } = thirdFormState.selfEmployedDocs

  const { temporary, permanent, partTime, fullTime, contract } = thirdFormState.employmentStatus

  const {
    weekdayMorning,
    weekdayAfternoon,
    weekendMorning,
    weekendAfternoon,
    notSure
  } = thirdFormState.reachingTime

  const handleTiming = event => {
    setThirdFormState({
      ...thirdFormState,
      reachingTime: { ...thirdFormState.reachingTime, [event.target.name]: event.target.checked }
    })
  }

  const handleChange = event => {
    setThirdFormState({
      ...thirdFormState,
      selfEmployedDocs: {
        ...thirdFormState.selfEmployedDocs,
        [event.target.name]: event.target.checked
      }
    })
  }

  const handleChangeCurrentEmploymentStatus = event => {
    setThirdFormState({
      ...thirdFormState,
      employmentStatus: {
        ...thirdFormState.employmentStatus,
        [event.target.name]: event.target.checked
      }
    })
  }

  const firstEmployerHandler = employer => {
    return () => setThirdFormState({ ...thirdFormState, firstEmployer: employer })
  }

  const businessHandler = self => {
    return () => setThirdFormState({ ...thirdFormState, selfEmployed: self })
  }

  const handleTextInputChange = (event, type = "text", name = "phone") => {
    if (type === "phone") {
      setThirdFormState({ ...thirdFormState, [name]: event })
    } else if (type === "money") {

      setThirdFormState({ ...thirdFormState, [event.target.name]: event.target.value });
      // }
    } else {
      setThirdFormState({ ...thirdFormState, [event.target.name]: event.target.value })
    }
  }

  const handleCurrentEmployerAddress = (name: string, value: string) => {
    setThirdFormState({
      ...thirdFormState,
      currentEmployerAddress: {
        ...thirdFormState.currentEmployerAddress,
        [name]: value
      }
    })
  }

  const handleRadioChange = event => {
    setThirdFormState({ ...thirdFormState, employmentTerm: event.target.value })
  }

  const handleRadioChangeReferenceStatus = event => {
    setThirdFormState({ ...thirdFormState, referenceStatus: event.target.value })
  }

  const handleCurrentEmploymentEndDateChange = event => {
    setThirdFormState({ ...thirdFormState, currentEmploymentEndDate: event?.target?.value })
  }

  const handlePreviousEmployerAddressChange = (name: string, value: string) => {
    setThirdFormState(prevData => ({
      ...prevData,
      previousEmployerAddress: {
        ...prevData.previousEmployerAddress,
        [name]: value
      }
    }))
  }

  return (
    <div className="rental-application">
      <div className="">
        <div className="form">
          <div className="field-container">
            <h2 className="h2">Income</h2>
            <div style={{ padding: matches ? "0 20px" : "", marginBottom: 18 }}>
              <p>
                Approximate <u>monthly</u> gross income*
              </p>
              <label>Including any allowances and public assistance.</label>
            </div>

            <ICurrencyMask
              className="inputField full"
              type="text"
              unmask='typed'
              name="grossIncome"
              value={thirdFormState.grossIncome}
              onChange={event => handleTextInputChange(event, "money")}
            />
            {error && thirdFormState.grossIncome === "" && <Error>*This field is required</Error>}
          </div>
          <div className="field-container">
            <div>
              <p>Are you self-employed?*</p>
              <label>Do you own your business?</label>
            </div>
            <div className="bool-btn">
              <div
                onClick={businessHandler(true)}
                className={thirdFormState.selfEmployed ? classes.selected : undefined}
              >
                Yes
              </div>
              <div
                onClick={businessHandler(false)}
                className={!thirdFormState.selfEmployed ? classes.selected : undefined}
              >
                No
              </div>
            </div>
          </div>
          {thirdFormState.selfEmployed && (
            <>
              <div className="field-container">
                <div>
                  <p>Website of your business</p>
                </div>
                <input
                  type="text"
                  className="inputField full"
                  name="website"
                  value={thirdFormState.website}
                  onChange={e => handleTextInputChange(e, "url")}
                />
                {error &&
                  thirdFormState.selfEmployed &&
                  thirdFormState.website !== "" &&
                  !isValidUrl(thirdFormState.website) && (
                    <Error>
                      Please enter a valid URL.
                      <Tooltip
                        placement="bottom"
                        title="Please ensure the url contains https:// or http://"
                      >
                        <InfoIcon fontSize="small" />
                      </Tooltip>
                    </Error>
                  )}
              </div>
              <div className="field-container">
                <div>
                  <p>
                    Your <u>monthly</u> revenue/sales figures
                  </p>
                </div>

                <ICurrencyMask
                  className="inputField full"
                  type="text"
                  unmask='typed'
                  name="revenueSalesFigures"
                  value={thirdFormState.revenueSalesFigures}
                  onChange={event => handleTextInputChange(event, "money")}
                />

              </div>
              <div className="field-container">
                <div>
                  <p>
                    Your <u>monthly</u> business expense figures
                  </p>
                </div>
                <ICurrencyMask
                  className="inputField full"
                  type="text"
                  unmask='typed'
                  name="expenseFigures"
                  value={thirdFormState.expenseFigures}
                  onChange={event => handleTextInputChange(event, "money")}
                />
              </div>
              <div className="field-container">
                <div>
                  <p>
                    As you're self-employed, please provide at least <u>two</u> of the following:*
                  </p>
                </div>
                <FormGroup className={classes.formGroup}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={bankStatement}
                            onChange={handleChange}
                            name="bankStatement"
                          />
                        }
                        label="Past 3 months of bank statements"
                        className={`${classes.formControlLabelSwitch} ${bankStatement &&
                          classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={taxReturns}
                            onChange={handleChange}
                            name="taxReturns"
                          />
                        }
                        label="Past 2 years' tax returns"
                        className={`${classes.formControlLabelSwitch} ${taxReturns &&
                          classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={accountantContact}
                            onChange={handleChange}
                            name="accountantContact"
                          />
                        }
                        label="Your accountant's contact details"
                        className={`${classes.formControlLabelSwitch} ${accountantContact &&
                          classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={invoices}
                            onChange={handleChange}
                            name="invoices"
                          />
                        }
                        label="Recent invoices to your customers"
                        className={`${classes.formControlLabelSwitch} ${invoices &&
                          classes.selected}`}
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
                {error && thirdFormState.selfEmployed && checkSelfEmployedDocs() && (
                  <Error>Choose 2 or more options</Error>
                )}
              </div>
              {bankStatement && (
                <div className="field-container">
                  <div>
                    <p>Past three (3) months of bank statements, highlighting your income</p>
                  </div>
                  <SimpleFileUpload
                    files={thirdFormState?.bankStatementFiles?.filter(item => item && item.name)}
                    labelName="Upload"
                    listTitle=""
                    handleFileChange={(files: any[]) =>
                      setThirdFormState({ ...thirdFormState, bankStatementFiles: files })
                    }
                    uploadButtonContainerProps={{
                      style: {
                        marginLeft: 0
                      }
                    }}
                    maxFiles={5}
                    acceptedFiles=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.xls"
                  />
                  {error && bankStatement && thirdFormState.bankStatementFiles === null && (
                    <Error>Please upload at least one file</Error>
                  )}
                </div>
              )}
              {taxReturns && (
                <div className="field-container">
                  <div>
                    <p>Past two (2) years' tax returns, highlighting your income</p>
                  </div>
                  <SimpleFileUpload
                    files={thirdFormState?.taxReturnsFiles?.filter(item => item && item.name)}
                    labelName="Upload"
                    listTitle=""
                    handleFileChange={(files: any[]) =>
                      setThirdFormState({ ...thirdFormState, taxReturnsFiles: files })
                    }
                    uploadButtonContainerProps={{
                      style: {
                        marginLeft: 0
                      }
                    }}
                    maxFiles={5}
                    acceptedFiles=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.xls"
                  />
                  {error && taxReturns && thirdFormState.taxReturnsFiles === null && (
                    <Error>Please upload at least one file</Error>
                  )}
                </div>
              )}
              {accountantContact && (
                <>
                  <div className="field-container">
                    <div>
                      <p>Your accountant's email</p>
                      <label>Your accountant will be contacted for income verification</label>
                    </div>
                    <input
                      type="text"
                      className="inputField full"
                      placeholder="email"
                      name="accountantEmail"
                      value={thirdFormState.accountantEmail}
                      onChange={handleTextInputChange}
                    />
                    {error &&
                      accountantContact &&
                      thirdFormState.accountantEmail !== "" &&
                      !isValidEmail(thirdFormState.accountantEmail) && (
                        <Error>Invalid email address</Error>
                      )}
                  </div>
                  <div className="field-container">
                    <div>
                      <p>Your accountant's phone number</p>
                      <label>Your accountant will be contacted for income verification</label>
                    </div>
                    <PhoneInput
                      countryCodeEditable={false}
                      placeholder="(123) 456-7890"
                      value={thirdFormState.accountantPhone}
                      onChange={phone => handleTextInputChange(phone, "phone", "accountantPhone")}
                      onlyCountries={["ca"]}
                      country="ca"
                      priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                      containerClass={classes.borderClass}
                      inputProps={{
                        required: true
                      }}
                      containerStyle={{
                        height: "fit-content",
                        position: "relative",
                        width: "100%",
                        padding: 0
                      }}
                      inputStyle={{
                        height: "100%",
                        width: "100%",
                        boxShadow: "none",
                        border: "none",
                        outline: "none",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
                        color: "#00A857",
                        fontSize: "18px",
                        letterSpacing: "0.5px",
                        backgroundColor: "transparent",
                        transition: "all 0.1s linear",
                        borderRadius: 0,
                        padding: "14px 42px"
                      }}
                      buttonStyle={{
                        border: "none",
                        outline: "none",
                        color: "#00A857",
                        // fontSize: "18px",
                        // letterSpacing: "0.5px",
                        backgroundColor: "transparent",
                        transition: "all 0.1s linear",
                        borderRadius: 0
                      }}
                    />
                  </div>
                </>
              )}
              {invoices && (
                <div className="field-container">
                  <div>
                    <p>Invoices to your most recent customers</p>
                    <label>Your customers may be contacted for reference checks</label>
                  </div>
                  <SimpleFileUpload
                    files={thirdFormState?.invoicesFiles?.filter(item => item && item.name)}
                    labelName="Upload"
                    listTitle=""
                    handleFileChange={(files: any[]) =>
                      setThirdFormState({ ...thirdFormState, invoicesFiles: files })
                    }
                    uploadButtonContainerProps={{
                      style: {
                        marginLeft: 0
                      }
                    }}
                    maxFiles={5}
                    acceptedFiles=".jpg,.jpeg,.png,.pdf,.xlsx,.csv,.xls"
                  />
                  {error && invoices && thirdFormState.invoicesFiles === null && (
                    <Error>Please upload at least one file</Error>
                  )}
                </div>
              )}
            </>
          )}
          <div className="field-container">
            <div>
              <p>Your occupation/position title</p>
            </div>
            <input
              type="text"
              className="inputField full"
              name="occupation"
              value={thirdFormState.occupation}
              onChange={handleTextInputChange}
            />
          </div>
          {!thirdFormState.selfEmployed && (
            <div className="field-container">
              <div>
                <p>Current employer</p>
                <label>If applicable</label>
              </div>
              <input
                type="text"
                className="inputField full"
                name="currentEmployer"
                value={thirdFormState.currentEmployer}
                onChange={handleTextInputChange}
              />
            </div>
          )}
          {thirdFormState.currentEmployer !== "" && (
            <>
              <div className="field-container">
                <div>
                  <p>{`${thirdFormState.currentEmployer}'s`} address</p>
                </div>
                <AddressField
                  {...thirdFormState.currentEmployerAddress}
                  handleStreetChange={(value: string) =>
                    handleCurrentEmployerAddress("street", value)
                  }
                  handleCityChange={(value: string) => handleCurrentEmployerAddress("city", value)}
                  handleProvinceChange={(value: string) =>
                    handleCurrentEmployerAddress("province", value)
                  }
                  handlePostalChange={(value: string) =>
                    handleCurrentEmployerAddress("postal", value)
                  }
                  handleCountryChange={(value: string) =>
                    handleCurrentEmployerAddress("country", value)
                  }
                />
              </div>
              <div className="field-container">
                <div>
                  <p>{`${thirdFormState.currentEmployer}'s`} phone number</p>
                </div>
                <PhoneInput
                  countryCodeEditable={false}
                  placeholder="(123) 456-7890"
                  value={thirdFormState.currentEmployerPhone}
                  onChange={phone => handleTextInputChange(phone, "phone", "currentEmployerPhone")}
                  onlyCountries={["ca"]}
                  country="ca"
                  priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                  containerClass={classes.borderClass}
                  inputProps={{
                    required: true
                  }}
                  containerStyle={{
                    height: "fit-content",
                    position: "relative",
                    width: "100%",
                    padding: 0
                  }}
                  inputStyle={{
                    height: "100%",
                    width: "100%",
                    boxShadow: "none",
                    border: "none",
                    outline: "none",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
                    color: "#00A857",
                    fontSize: "18px",
                    letterSpacing: "0.5px",
                    backgroundColor: "transparent",
                    transition: "all 0.1s linear",
                    borderRadius: 0,
                    padding: "14px 42px"
                  }}
                  buttonStyle={{
                    border: "none",
                    outline: "none",
                    color: "#00A857",
                    // fontSize: "18px",
                    // letterSpacing: "0.5px",
                    backgroundColor: "transparent",
                    transition: "all 0.1s linear",
                    borderRadius: 0
                  }}
                />
              </div>
              <div className="field-container">
                <div>
                  <p>Current employment term</p>
                  <label>
                    How long have you been working with {thirdFormState.currentEmployer}?
                  </label>
                </div>
                <RadioGroup
                  aria-label="quiz"
                  name="quiz"
                  value={thirdFormState.employmentTerm}
                  onChange={handleRadioChange}
                  className={classes.radioGroupMulti}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        value="lessThanSixMonths"
                        control={<WhiteRadio />}
                        label="Less than 6 months"
                        className={`${classes.formControlLabel} ${thirdFormState.employmentTerm ===
                          "lessThanSixMonths" && classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        value="moreThanSixMonths"
                        control={<WhiteRadio />}
                        label="More than 6 months"
                        className={`${classes.formControlLabel} ${thirdFormState.employmentTerm ===
                          "moreThanSixMonths" && classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        value="moreThanOneYear"
                        control={<WhiteRadio />}
                        label="More than a year"
                        className={`${classes.formControlLabel} ${thirdFormState.employmentTerm ===
                          "moreThanOneYear" && classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        value="moreThanThreeYear"
                        control={<WhiteRadio />}
                        label="More than 3 years"
                        className={`${classes.formControlLabel} ${thirdFormState.employmentTerm ===
                          "moreThanThreeYear" && classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        value="moreThanFiveYear"
                        control={<WhiteRadio />}
                        label="More than 5 years"
                        className={`${classes.formControlLabel} ${thirdFormState.employmentTerm ===
                          "moreThanFiveYear" && classes.selected}`}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </div>
              <div className="field-container">
                <div>
                  <p>Current employment status</p>
                </div>
                <FormGroup className={classes.formGroup}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={temporary}
                            onChange={handleChangeCurrentEmploymentStatus}
                            name="temporary"
                          />
                        }
                        label="Temporary"
                        className={`${classes.formControlLabelSwitch} ${temporary &&
                          classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={permanent}
                            onChange={handleChangeCurrentEmploymentStatus}
                            name="permanent"
                          />
                        }
                        label="Permanent"
                        className={`${classes.formControlLabelSwitch} ${permanent &&
                          classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={partTime}
                            onChange={handleChangeCurrentEmploymentStatus}
                            name="partTime"
                          />
                        }
                        label="Part-time"
                        className={`${classes.formControlLabelSwitch} ${partTime &&
                          classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={fullTime}
                            onChange={handleChangeCurrentEmploymentStatus}
                            name="fullTime"
                          />
                        }
                        label="Full-time"
                        className={`${classes.formControlLabelSwitch} ${fullTime &&
                          classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={contract}
                            onChange={handleChangeCurrentEmploymentStatus}
                            name="contract"
                          />
                        }
                        label="Contract"
                        className={`${classes.formControlLabelSwitch} ${contract &&
                          classes.selected}`}
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </div>
              {(contract || temporary) && (
                <div className="field-container">
                  <div>
                    <p>Current employment end date</p>
                  </div>
                  <FormComponent>
                    <CssTextField
                      type="date"
                      variant="outlined"
                      fullWidth
                      value={thirdFormState.currentEmploymentEndDate}
                      onChange={handleCurrentEmploymentEndDateChange}
                      InputProps={{
                        inputProps: {
                          style: {
                            color: "#00A857"
                          },
                          min: moment().format("YYYY-MM-DD")
                        }
                      }}
                    />
                  </FormComponent>
                </div>
              )}

              <div className="field-container">
                <div>
                  <p>Full name of your employment reference*</p>
                  <label>
                    Can be your supervisor or someone from your company's human resources team. We
                    ask this for our standard employment reference checks.
                  </label>
                </div>
                <input
                  type="text"
                  className="inputField full"
                  name="employmentReference"
                  value={thirdFormState.employmentReference}
                  onChange={handleTextInputChange}
                />
                {error && thirdFormState.employmentReference === "" && (
                  <Error>*This field is required</Error>
                )}
              </div>
              <div className="field-container">
                <div>
                  <p>{thirdFormState.employmentReference} is*</p>
                </div>
                <RadioGroup
                  aria-label="referenceStatus"
                  name="referenceStatus"
                  value={thirdFormState.referenceStatus}
                  onChange={handleRadioChangeReferenceStatus}
                  className={classes.radioGroupMulti}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        value="supervisor"
                        control={<WhiteRadio />}
                        label="Your supervisor"
                        className={`${classes.formControlLabel} ${thirdFormState.referenceStatus ===
                          "supervisor" && classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        value="hr"
                        control={<WhiteRadio />}
                        label="From your company's human resources team"
                        className={`${classes.formControlLabel} ${thirdFormState.referenceStatus ===
                          "hr" && classes.selected}`}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
                {error && thirdFormState.referenceStatus === "" && (
                  <Error>*This field is required</Error>
                )}
              </div>
              <div className="field-container">
                <div>
                  <p>{`${thirdFormState.employmentReference}'s`} telephone</p>
                  <label>We ask this for standard employment reference checks.</label>
                </div>
                <PhoneInput
                  countryCodeEditable={false}
                  placeholder="(123) 456-7890"
                  value={thirdFormState.employmentReferencePhone}
                  onChange={phone =>
                    handleTextInputChange(phone, "phone", "employmentReferencePhone")
                  }

                  country="ca"
                  onlyCountries={["ca"]}
                  priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                  containerClass={classes.borderClass}
                  inputProps={{
                    required: true
                  }}
                  containerStyle={{
                    height: "fit-content",
                    position: "relative",
                    width: "100%",
                    padding: 0
                  }}
                  inputStyle={{
                    height: "100%",
                    width: "100%",
                    boxShadow: "none",
                    border: "none",
                    outline: "none",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
                    color: "#00A857",
                    fontSize: "18px",
                    letterSpacing: "0.5px",
                    backgroundColor: "transparent",
                    transition: "all 0.1s linear",
                    borderRadius: 0,
                    padding: "14px 42px"
                  }}
                  buttonStyle={{
                    border: "none",
                    outline: "none",
                    color: "#00A857",
                    backgroundColor: "transparent",
                    transition: "all 0.1s linear",
                    borderRadius: 0
                  }}
                />
              </div>
              <div className="field-container">
                <div>
                  <p>{`${thirdFormState.employmentReference}'s`} email*</p>
                  <label>
                    We ask this for standard employment reference checks. This contact provided here
                    will receive an email from us requesting for reference checks immediately after
                    you submit this application.
                  </label>
                </div>
                <EmailAndConfirmEmailComponent
                  emailName={"employmentReferenceEmail"}
                  confirmEmailName={"employmentReferenceConfirmEmail"}
                  emailValue={thirdFormState.employmentReferenceEmail}
                  confirmEmailValue={thirdFormState.employmentReferenceConfirmEmail}
                  handleEmailChange={handleTextInputChange}
                  handleConfirmEmailChange={handleTextInputChange}
                  required
                  error={error}
                />
              </div>
              <div className="field-container">
                <div>
                  <p>What is the best time to reach {thirdFormState.employmentReference}?</p>
                </div>
                <FormGroup className={classes.formGroup}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={weekdayMorning}
                            onChange={handleTiming}
                            name="weekdayMorning"
                          />
                        }
                        label="Weekday morning"
                        className={`${classes.formControlLabelSwitch} ${weekdayMorning &&
                          classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={weekdayAfternoon}
                            onChange={handleTiming}
                            name="weekdayAfternoon"
                          />
                        }
                        label="Weekday afternoon"
                        className={`${classes.formControlLabelSwitch} ${weekdayAfternoon &&
                          classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={weekendMorning}
                            onChange={handleTiming}
                            name="weekendMorning"
                          />
                        }
                        label="Weekend morning"
                        className={`${classes.formControlLabelSwitch} ${weekendMorning &&
                          classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={weekendAfternoon}
                            onChange={handleTiming}
                            name="weekendAfternoon"
                          />
                        }
                        label="Weekend afternoon"
                        className={`${classes.formControlLabelSwitch} ${weekendAfternoon &&
                          classes.selected}`}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="theme-color"
                            checked={notSure}
                            onChange={handleTiming}
                            name="notSure"
                          />
                        }
                        label="Not sure"
                        className={`${classes.formControlLabelSwitch} ${notSure &&
                          classes.selected}`}
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </div>
              <div className="field-container">
                <div>
                  <p>Is this your first employer?</p>
                </div>
                <div className="bool-btn">
                  <div
                    onClick={firstEmployerHandler(true)}
                    className={thirdFormState.firstEmployer ? classes.selected : undefined}
                  >
                    Yes
                  </div>
                  <div
                    onClick={firstEmployerHandler(false)}
                    className={!thirdFormState.firstEmployer ? classes.selected : undefined}
                  >
                    No
                  </div>
                </div>
              </div>
            </>
          )}
          {!thirdFormState.firstEmployer && (
            <div className="field-container">
              <div>
                <p>Previous employer</p>
              </div>
              <input
                type="text"
                className="inputField full"
                name="previousEmployer"
                value={thirdFormState.previousEmployer}
                onChange={handleTextInputChange}
              />
            </div>
          )}
          {!!(
            !thirdFormState.firstEmployer &&
            thirdFormState.currentEmployer &&
            thirdFormState.previousEmployer
          ) && (
              <>
                <div className="field-container">
                  <div>
                    <p>Previous position title</p>
                  </div>
                  <input
                    type="text"
                    className="inputField full"
                    name="previousEmployer"
                    value={thirdFormState?.previousPositionTitle}
                    onChange={event =>
                      setThirdFormState(prev => ({
                        ...prev,
                        previousPositionTitle: event?.target?.value
                      }))
                    }
                  />
                </div>
                <div className="field-container">
                  <div>
                    <p>Previous employer's address</p>
                  </div>
                  <AddressField
                    {...thirdFormState.previousEmployerAddress}
                    handleStreetChange={(value: string) =>
                      handlePreviousEmployerAddressChange("street", value)
                    }
                    handleCityChange={(value: string) =>
                      handlePreviousEmployerAddressChange("city", value)
                    }
                    handleProvinceChange={(value: string) =>
                      handlePreviousEmployerAddressChange("province", value)
                    }
                    handlePostalChange={(value: string) =>
                      handlePreviousEmployerAddressChange("postal", value)
                    }
                    handleCountryChange={(value: string) =>
                      handlePreviousEmployerAddressChange("country", value)
                    }
                  />
                </div>
              </>
            )}
          <div className="field-container">
            <div>
              <p>
                Your{" "}
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                  LinkedIn
                </a>{" "}
                profile
              </p>
            </div>
            <input
              type="text"
              className="inputField full"
              name="linkedIn"
              value={thirdFormState.linkedIn}
              onChange={handleTextInputChange}
            />
            {error && thirdFormState.linkedIn !== "" && !isValidUrl(thirdFormState.linkedIn) && (
              <Error>
                Please enter a valid URL.
                <Tooltip
                  placement="bottom"
                  title="Please ensure the url contains https:// or http://"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Error>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const ApplicationFourth = props => {
  const { fourthFormState, setFourthFormState, isValidEmail, error, firstFormState } = props
  const { none, house, vehicles, medical, education } = fourthFormState.financialObligation

  const {
    weekdayMorning,
    weekdayAfternoon,
    weekendMorning,
    weekendAfternoon,
    notSure
  } = fourthFormState.reachingTime

  const { friend, neighbour, colleague, relative } = fourthFormState.referenceRelationship
  const { dog, cat, fish, bird, other } = fourthFormState.petType

  const classes = useStyles()

  const handlePetTypeChange = event => {
    setFourthFormState({
      ...fourthFormState,
      petType: { ...fourthFormState.petType, [event.target.name]: event.target.checked }
    })
  }

  const petsHandler = pet => {
    return () => setFourthFormState({ ...fourthFormState, pets: pet })
  }

  const handleTiming = event => {
    setFourthFormState({
      ...fourthFormState,
      reachingTime: { ...fourthFormState.reachingTime, [event.target.name]: event.target.checked }
    })
  }
  const handleChange = event => {
    if (event.target.name === "none" && event.target.checked) {
      setFourthFormState({
        ...fourthFormState,
        financialObligation: {
          ...FOURTH_APP_INIT_STATE.financialObligation,
          none: true
        }
      })
    } else {
      setFourthFormState({
        ...fourthFormState,
        financialObligation: {
          ...fourthFormState.financialObligation,
          [event.target.name]: event.target.checked,
          none: false
        }
      })
    }
  }
  const handleRelationship = event => {
    setFourthFormState({
      ...fourthFormState,
      referenceRelationship: {
        ...fourthFormState.referenceRelationship,
        [event.target.name]: event.target.checked
      }
    })
  }

  const handleTextInputChange = (event, type = "text", name = "phone") => {
    if (type === "weight") {
      if (/^((\d+\.?|\.(?=\d))?\d{0,2})$/.test(event.target.value)) {
        let val = event.target.value
        setFourthFormState({ ...fourthFormState, [event.target.name]: val })
      }
    } else if (type === "phone") {
      setFourthFormState({ ...fourthFormState, [name]: event })
    } else if (type === "money") {

      setFourthFormState({ ...fourthFormState, [event.target.name]: event.target.value });

    } else {
      setFourthFormState({ ...fourthFormState, [event.target.name]: event.target.value })
    }
  }

  return (
    <div className="rental-application">
      <div className="rental-application-fourth-form">
        <div className="form">
          <div className="field-container">
            <h2 className="h2">Financial Obligations</h2>
            <div style={{ marginBottom: 18 }}>
              <p>Do you currently have any financial obligations?*</p>
            </div>
            <FormGroup className={classes.formGroup}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="theme-color"
                        checked={none}
                        onChange={handleChange}
                        name="none"
                      />
                    }
                    label="None"
                    className={`${classes.formControlLabelSwitch} ${none && classes.selected}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="theme-color"
                        checked={house}
                        onChange={handleChange}
                        name="house"
                      />
                    }
                    label="House"
                    className={`${classes.formControlLabelSwitch} ${house && classes.selected}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="theme-color"
                        checked={vehicles}
                        onChange={handleChange}
                        name="vehicles"
                      />
                    }
                    label="Vehicles"
                    className={`${classes.formControlLabelSwitch} ${vehicles && classes.selected}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="theme-color"
                        checked={medical}
                        onChange={handleChange}
                        name="medical"
                      />
                    }
                    label="Medical"
                    className={`${classes.formControlLabelSwitch} ${medical && classes.selected}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="theme-color"
                        checked={education}
                        onChange={handleChange}
                        name="education"
                      />
                    }
                    label="Education"
                    className={`${classes.formControlLabelSwitch} ${education && classes.selected}`}
                  />
                </Grid>
              </Grid>
            </FormGroup>
            {error && !none && !house && !vehicles && !medical && !education && (
              <Error>*This field is required</Error>
            )}
          </div>
          {!none && (
            <>
              <div className="field-container">
                <div>
                  <p>
                    Approximate total <u>monthly</u> payments for the financial obligation(s) above
                  </p>
                </div>

                <ICurrencyMask
                  className="inputField full"
                  type="text"
                  unmask='typed'
                  name="monthlyPayment"
                  value={fourthFormState.monthlyPayment}
                  onChange={event => handleTextInputChange(event, "money")}
                />
              </div>
              <div className="field-container">
                <div>
                  <p>Entities that payments are made to</p>
                </div>
                <input
                  type="text"
                  className="inputField full"
                  name="entities"
                  value={fourthFormState.entities}
                  onChange={handleTextInputChange}
                />
              </div>
            </>
          )}
        </div>
        {firstFormState.applyingAs !== "guarantor" && (
          <div className="form">
            <div className="field-container">
              <div>
                <h2 className="h2">Personal Reference</h2>
                <p>Full name of your personal reference*</p>
              </div>
              <input
                type="text"
                className="inputField full"
                name="referenceName"
                value={fourthFormState.referenceName}
                onChange={handleTextInputChange}
              />
              {error && fourthFormState.referenceName === "" && (
                <Error>*This field is required</Error>
              )}
            </div>
            <div className="field-container">
              <div>
                <p>Phone number of your personal reference</p>
              </div>
              <PhoneInput
                countryCodeEditable={false}
                placeholder="(123) 456-7890"
                value={fourthFormState.referencePhone}
                onChange={phone => handleTextInputChange(phone, "phone", "referencePhone")}
                onlyCountries={["ca"]}
                country="ca"
                priority={{ ca: 0, us: 1, kz: 0, ru: 1 }}
                containerClass={classes.borderClass2}
                inputProps={{
                  required: true
                }}
                containerStyle={{
                  height: "fit-content",
                  position: "relative",
                  width: "100%",
                  padding: 0
                }}
                inputStyle={{
                  height: "100%",
                  width: "100%",
                  boxShadow: "none",
                  border: "none",
                  outline: "none",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
                  color: "#00A857",
                  fontSize: "18px",
                  letterSpacing: "0.5px",
                  backgroundColor: "transparent",
                  transition: "all 0.1s linear",
                  borderRadius: 0,
                  padding: "14px 42px"
                }}
                buttonStyle={{
                  border: "none",
                  outline: "none",
                  color: "#00A857",
                  // fontSize: "18px",
                  // letterSpacing: "0.5px",
                  backgroundColor: "transparent",
                  transition: "all 0.1s linear",
                  borderRadius: 0
                }}
              />
            </div>
            <div className="field-container">
              <div>
                <p>Email of your personal reference*</p>
              </div>
              <EmailComponent
                name="referenceEmail"
                value={fourthFormState.referenceEmail}
                onChange={handleTextInputChange}
                required
                error={error}
              />
            </div>
            <div className="field-container">
              <div>
                <p>Personal reference's relationship to you</p>
              </div>
              <FormGroup className={classes.formGroup}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={friend}
                          onChange={handleRelationship}
                          name="friend"
                        />
                      }
                      label="Friend"
                      className={`${classes.formControlLabelSwitch} ${friend && classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={neighbour}
                          onChange={handleRelationship}
                          name="neighbour"
                        />
                      }
                      label="Neighbour"
                      className={`${classes.formControlLabelSwitch} ${neighbour &&
                        classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={colleague}
                          onChange={handleRelationship}
                          name="colleague"
                        />
                      }
                      label="Colleague"
                      className={`${classes.formControlLabelSwitch} ${colleague &&
                        classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={relative}
                          onChange={handleRelationship}
                          name="relative"
                        />
                      }
                      label="Relative"
                      className={`${classes.formControlLabelSwitch} ${relative &&
                        classes.selected}`}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </div>
            <div className="field-container">
              <div>
                <p>What is the best time to reach your personal preference</p>
              </div>
              <FormGroup className={classes.formGroup}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={weekdayMorning}
                          onChange={handleTiming}
                          name="weekdayMorning"
                        />
                      }
                      label="Weekday morning"
                      className={`${classes.formControlLabelSwitch} ${weekdayMorning &&
                        classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={weekdayAfternoon}
                          onChange={handleTiming}
                          name="weekdayAfternoon"
                        />
                      }
                      label="Weekday afternoon"
                      className={`${classes.formControlLabelSwitch} ${weekdayAfternoon &&
                        classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={weekendMorning}
                          onChange={handleTiming}
                          name="weekendMorning"
                        />
                      }
                      label="Weekend morning"
                      className={`${classes.formControlLabelSwitch} ${weekendMorning &&
                        classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={weekendAfternoon}
                          onChange={handleTiming}
                          name="weekendAfternoon"
                        />
                      }
                      label="Weekend afternoon"
                      className={`${classes.formControlLabelSwitch} ${weekendAfternoon &&
                        classes.selected}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="theme-color"
                          checked={notSure}
                          onChange={handleTiming}
                          name="notSure"
                        />
                      }
                      label="Not sure"
                      className={`${classes.formControlLabelSwitch} ${notSure && classes.selected}`}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </div>
          </div>
        )}
        {firstFormState.applyingAs !== "guarantor" && (
          <div className="form">
            <div className="field-container">
              <div>
                <h2 className="h2">Pets</h2>
                <p>Do you have any pets?*</p>
              </div>
              <div className="bool-btn">
                <div
                  onClick={petsHandler(true)}
                  className={fourthFormState.pets ? classes.selected : undefined}
                >
                  Yes
                </div>
                <div
                  onClick={petsHandler(false)}
                  className={!fourthFormState.pets ? classes.selected : undefined}
                >
                  No
                </div>
              </div>
            </div>
            {fourthFormState.pets && (
              <>
                <div className="field-container">
                  <div>
                    <p>Pet type*</p>
                  </div>
                  <FormGroup className={classes.formGroup}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={dog}
                              onChange={handlePetTypeChange}
                              name="dog"
                              className="theme-color"
                            />
                          }
                          label="Dog"
                          className={`${classes.formControlLabelSwitch} ${dog && classes.selected}`}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={cat}
                              onChange={handlePetTypeChange}
                              name="cat"
                              className="theme-color"
                            />
                          }
                          label="Cat"
                          className={`${classes.formControlLabelSwitch} ${cat && classes.selected}`}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={fish}
                              onChange={handlePetTypeChange}
                              name="fish"
                              className="theme-color"
                            />
                          }
                          label="Fish"
                          className={`${classes.formControlLabelSwitch} ${fish &&
                            classes.selected}`}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={bird}
                              onChange={handlePetTypeChange}
                              name="bird"
                              className="theme-color"
                            />
                          }
                          label="Bird"
                          className={`${classes.formControlLabelSwitch} ${bird &&
                            classes.selected}`}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={other}
                              onChange={handlePetTypeChange}
                              name="other"
                              className="theme-color"
                            />
                          }
                          label="Other"
                          className={`${classes.formControlLabelSwitch} ${other &&
                            classes.selected}`}
                        />
                      </Grid>
                    </Grid>
                  </FormGroup>
                  {other && (
                    <input
                      type="text"
                      className="inputField full margin-top"
                      placeholder="Specify other..."
                      value={fourthFormState.otherPetType}
                      onChange={handleTextInputChange}
                      name="otherPetType"
                    />
                  )}
                  {error &&
                    fourthFormState.pets &&
                    !fourthFormState.petType.dog &&
                    !fourthFormState.petType.cat &&
                    !fourthFormState.petType.fish &&
                    !fourthFormState.petType.bird &&
                    !fourthFormState.petType.other && <Error>*This field is required</Error>}
                  {error &&
                    fourthFormState.petType.other === true &&
                    fourthFormState.otherPetType === "" && <Error>*This field is required</Error>}
                </div>
                <div className="field-container">
                  <div>
                    <p>Breed</p>
                  </div>
                  <input
                    type="text"
                    className="inputField full"
                    name="breed"
                    value={fourthFormState.breed}
                    onChange={handleTextInputChange}
                  />
                </div>
                <div className="field-container">
                  <div>
                    <p>Weight (pounds)</p>
                  </div>
                  <input
                    type="text"
                    className="inputField full"
                    name="weight"
                    value={fourthFormState.weight}
                    onChange={e => handleTextInputChange(e, "weight")}
                    onBlur={event => {
                      if (event.target.value[event.target.value.length - 1] === ".") {
                        let val = event.target.value.slice(0, event.target.value.length - 1)
                        setFourthFormState({
                          ...fourthFormState,
                          weight: val
                        })
                      }
                      if (event.target.value === "") {
                        setFourthFormState({
                          ...fourthFormState,
                          weight: ""
                        })
                      }
                    }}
                  />
                </div>
                <div className="field-container">
                  <div>
                    <p>Describe your pet(s) here</p>
                  </div>
                  <textarea
                    className="textarea-field full"
                    name="petDescription"
                    value={fourthFormState.petDescription}
                    onChange={handleTextInputChange}
                  />
                </div>
                <div className="field-container">
                  <div>
                    <p>Picture of your pet(s)</p>
                  </div>
                  <SimpleFileUpload
                    files={fourthFormState?.petFiles?.filter(item => item && item.name)}
                    labelName="Upload"
                    listTitle=""
                    handleFileChange={(files: any[]) =>
                      setFourthFormState({ ...fourthFormState, petFiles: files })
                    }
                    uploadButtonContainerProps={{
                      style: {
                        marginLeft: 0
                      }
                    }}
                    maxFiles={10}
                    acceptedFiles="image/*"
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const ApplicationFifth = props => {
  const classes = useStyles()

  const { fifthFormState, setFifthFormState, error, firstFormState } = props

  const vehicleHandler = veh => {
    return () => setFifthFormState({ ...fifthFormState, vehicle: veh })
  }
  const anotherVehicleHandler = veh => {
    return () => setFifthFormState({ ...fifthFormState, anotherVehicle: veh })
  }

  const handleTextInputChange = event => {
    setFifthFormState({ ...fifthFormState, [event.target.name]: event.target.value })
  }

  return (
    <div className="rental-application">
      <div className="">
        <div className="form">
          {firstFormState.applyingAs !== "guarantor" && (
            <div className="field-container">
              <div>
                <h2 className="h2">Vehicles</h2>
                <p>Do you own or lease a vehicle?*</p>
              </div>
              <div className="bool-btn">
                <div
                  onClick={vehicleHandler(true)}
                  className={fifthFormState.vehicle ? classes.selected : undefined}
                >
                  Yes
                </div>
                <div
                  onClick={vehicleHandler(false)}
                  className={!fifthFormState.vehicle ? classes.selected : undefined}
                >
                  No
                </div>
              </div>
            </div>
          )}
          {fifthFormState.vehicle && (
            <>
              <div className="field-container">
                <div>
                  <p>Make*</p>
                  <label>Vehicle #1</label>
                </div>
                <input
                  type="text"
                  className="inputField full"
                  name="makeOne"
                  value={fifthFormState.makeOne}
                  onChange={handleTextInputChange}
                />
                {error && fifthFormState.vehicle && fifthFormState.makeOne === "" && (
                  <Error>*This field is required</Error>
                )}
              </div>
              <div className="field-container">
                <div>
                  <p>Model*</p>
                  <label>Vehicle #1</label>
                </div>
                <input
                  type="text"
                  className="inputField full"
                  name="modelOne"
                  value={fifthFormState.modelOne}
                  onChange={handleTextInputChange}
                />
                {error && fifthFormState.vehicle && fifthFormState.modelOne === "" && (
                  <Error>*This field is required</Error>
                )}
              </div>
              <div className="field-container">
                <div>
                  <p>Do you own another vehicle?*</p>
                </div>
                <div className="bool-btn">
                  <div
                    onClick={anotherVehicleHandler(true)}
                    className={fifthFormState.anotherVehicle ? classes.selected : undefined}
                  >
                    Yes
                  </div>
                  <div
                    onClick={anotherVehicleHandler(false)}
                    className={!fifthFormState.anotherVehicle ? classes.selected : undefined}
                  >
                    No
                  </div>
                </div>
              </div>
              {fifthFormState.anotherVehicle && (
                <>
                  <div className="field-container">
                    <div>
                      <p>Make*</p>
                      <label>Vehicle #2</label>
                    </div>
                    <input
                      type="text"
                      className="inputField full"
                      name="makeTwo"
                      value={fifthFormState.makeTwo}
                      onChange={handleTextInputChange}
                    />
                    {error &&
                      fifthFormState.vehicle &&
                      fifthFormState.anotherVehicle &&
                      fifthFormState.makeTwo === "" && <Error>*This field is required</Error>}
                  </div>
                  <div className="field-container">
                    <div>
                      <p>Model*</p>
                      <label>Vehicle #2</label>
                    </div>
                    <input
                      type="text"
                      className="inputField full"
                      name="modelTwo"
                      value={fifthFormState.modelTwo}
                      onChange={handleTextInputChange}
                    />
                    {error &&
                      fifthFormState.vehicle &&
                      fifthFormState.anotherVehicle &&
                      fifthFormState.modelTwo === "" && <Error>*This field is required</Error>}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const ApplicationFifthCo = props => {
  const classes = useStyles()

  const { fifthFormState, setFifthFormState, error, firstFormState } = props

  const handleRadioChange = event => {
    setFifthFormState({
      ...fifthFormState,
      coOccupants: event.target.value
    })
  }

  const handleTextInputChange = event => {
    setFifthFormState({ ...fifthFormState, [event.target.name]: event.target.value })
  }

  return (
    <div className="rental-application">
      <div className="">
        <div className="form">
          <div className="field-container">
            <div>
              <h2 className="h2">Co-occupants</h2>
              <p>Will there be any co-occupants?*</p>
              <label>
                <b>Co-occupant</b> is a person who will live with you in your next rental home. If
                anyone listed here will be a <b>co-applicant</b>, whose name will be on the lease as
                well, then we request that person to prepare a separate rental application.
              </label>
              <br />
            </div>
            <RadioGroup
              aria-label="quiz"
              name="quiz"
              value={fifthFormState.coOccupants}
              onChange={handleRadioChange}
              className={classes.radioGroupMulti}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="0"
                    control={<WhiteRadio />}
                    label="None, I'll be occupying the unit by myself"
                    className={`${classes.formControlLabel} ${fifthFormState.coOccupants === "0" &&
                      classes.selected}`}
                  />
                </Grid>
                {firstFormState.applyingAs !== "tenant" && (
                  <Grid item xs={6}>
                    <FormControlLabel
                      value="guarantor"
                      control={<WhiteRadio />}
                      label="None, I'm applying as a guarantor/co-signor"
                      className={`${classes.formControlLabel} ${fifthFormState.coOccupants ===
                        "guarantor" && classes.selected}`}
                    />
                  </Grid>
                )}

                <Grid item xs={4} md>
                  <FormControlLabel
                    value="1"
                    control={<WhiteRadio />}
                    label="1"
                    className={`${classes.formControlLabel} ${fifthFormState.coOccupants === "1" &&
                      classes.selected}`}
                  />
                </Grid>
                <Grid item xs={4} md>
                  <FormControlLabel
                    value="2"
                    control={<WhiteRadio />}
                    label="2"
                    className={`${classes.formControlLabel} ${fifthFormState.coOccupants === "2" &&
                      classes.selected}`}
                  />
                </Grid>
                <Grid item xs={4} md>
                  <FormControlLabel
                    value="3"
                    control={<WhiteRadio />}
                    label="3"
                    className={`${classes.formControlLabel} ${fifthFormState.coOccupants === "3" &&
                      classes.selected}`}
                  />
                </Grid>
                <Grid item xs={4} md>
                  <FormControlLabel
                    value="4"
                    control={<WhiteRadio />}
                    label="4"
                    className={`${classes.formControlLabel} ${fifthFormState.coOccupants === "4" &&
                      classes.selected}`}
                  />
                </Grid>
                <Grid item xs={4} md>
                  <FormControlLabel
                    value="5"
                    control={<WhiteRadio />}
                    label="5+"
                    className={`${classes.formControlLabel} ${fifthFormState.coOccupants === "5" &&
                      classes.selected}`}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </div>
          {[...new Array(+fifthFormState.coOccupants || 0).keys()].map(el => {
            return (
              <div className="field-container" key={el}>
                <div>
                  <p>{getNumberWithOrdinal(el + 1)} co-occupant's name*</p>
                </div>
                <input
                  type="text"
                  className="inputField full"
                  name={`occupant_${el + 1}_name`}
                  value={fifthFormState[`occupant_${el + 1}_name`]}
                  onChange={handleTextInputChange}
                />
                {error &&
                  +fifthFormState.coOccupants > 0 &&
                  !fifthFormState[`occupant_${el + 1}_name`] && (
                    <Error>*This field is required</Error>
                  )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const ApplicationSixth = props => {
  const { sixthFormState, setSixthFormState, error, isSelfEmployed } = props

  const handleTextInputChange = (event, type = "text") => {
    if (type === "number") {
      if (/^[0-9]*$/.test(event.target.value)) {
        if (event.target.value.length <= 9)
          setSixthFormState({ ...sixthFormState, [event.target.name]: event.target.value })
      }
    } else {
      setSixthFormState({ ...sixthFormState, [event.target.name]: event.target.value })
    }
  }
  return (
    <div className="rental-application">
      <div className="">
        <div className="form">
          <div className="field-container">
            <div>
              <h2 className="h2">Supplementary Documents</h2>
              <p>Upload your most recent credit score*</p>
              <label>
                Tip: you can pull you credit check from various sources, including{" "}
                <a
                  href="https://www.consumer.equifax.ca/personal/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Equifax
                </a>
                ,{" "}
                <a href="https://www.transunion.ca/" target="_blank" rel="noopener noreferrer">
                  TransUnion
                </a>
                ,{" "}
                <a href="https://www.creditkarma.ca/" target="_blank" rel="noopener noreferrer">
                  Credit Karma
                </a>
                , and{" "}
                <a href="https://borrowell.com/" target="_blank" rel="noopener noreferrer">
                  Borrowell
                </a>
                .
              </label>
            </div>
            <SimpleFileUpload
              files={sixthFormState?.creditScoreFile?.filter(item => item && item.name)}
              labelName="Upload"
              listTitle=""
              handleFileChange={(files: any[]) =>
                setSixthFormState({ ...sixthFormState, creditScoreFile: files })
              }
              uploadButtonContainerProps={{
                style: {
                  marginLeft: 0
                }
              }}
              maxFiles={5}
            />
            {error && isEmpty(sixthFormState.creditScoreFile) && (
              <Error>Please upload at least one file</Error>
            )}
          </div>
          <div className="field-container">
            <div>
              <p>Your Social Insurance Number (SIN)</p>
            </div>
            <input
              type="text"
              className="inputField full"
              name="sin"
              value={sixthFormState.sin}
              onChange={e => handleTextInputChange(e, "number")}
            />
          </div>
          <div className="field-container">
            <div>
              <p>Upload a scan or photo of your proof of income*</p>
              <label>
                {isSelfEmployed
                  ? "You can provide tax assessment and/or monthly bank statements with your income clearly highlighted."
                  : "Such as employment letter and/or paystubs (of last 3 months if available)"}
              </label>
            </div>
            <SimpleFileUpload
              files={sixthFormState?.proofOfIncomeFile?.filter(item => item && item.name)}
              labelName="Upload"
              listTitle=""
              handleFileChange={(files: any[]) =>
                setSixthFormState({ ...sixthFormState, proofOfIncomeFile: files })
              }
              uploadButtonContainerProps={{
                style: {
                  marginLeft: 0
                }
              }}
              maxFiles={5}
            />
            {error && isEmpty(sixthFormState.proofOfIncomeFile) && (
              <Error>Please upload at least one file</Error>
            )}
          </div>
          <div className="field-container">
            <div>
              <p>Upload copies of two pieces of your government-issued photo ID*</p>
              <label>Such as driver's license or passport.</label>
            </div>
            <SimpleFileUpload
              files={sixthFormState?.govIdsFile?.filter(item => item && item.name)}
              labelName="Upload"
              listTitle=""
              handleFileChange={(files: any[]) =>
                setSixthFormState({ ...sixthFormState, govIdsFile: files })
              }
              uploadButtonContainerProps={{
                style: {
                  marginLeft: 0
                }
              }}
              maxFiles={5}
            />
            {error && isEmpty(sixthFormState.govIdsFile) && (
              <Error>Please upload at least one file</Error>
            )}
          </div>
          <div className="field-container">
            <div>
              <p>For any additional supplementary document(s) you would like to upload</p>
            </div>
            <SimpleFileUpload
              files={sixthFormState?.supplementaryDocFile?.filter(item => item && item.name)}
              labelName="Upload"
              listTitle=""
              handleFileChange={(files: any[]) =>
                setSixthFormState({ ...sixthFormState, supplementaryDocFile: files })
              }
              uploadButtonContainerProps={{
                style: {
                  marginLeft: 0
                }
              }}
              maxFiles={10}
            />
          </div>
          <div className="field-container">
            <div>
              <p>Anything else about yourself you want to tell the future landlord?</p>
            </div>
            <textarea
              className="textarea-field full"
              name="intro"
              value={sixthFormState.intro}
              onChange={handleTextInputChange}
            />
          </div>
          <div className="helper-text">
            <p>
              For any documents you could not upload or any questions you have, please email them to{" "}
              <span>rent@tolobi.com</span> with the subject property address clearly stated in the
              email title.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const ApplicationSeventh = props => {
  const {
    firstFormState,
    secondFormState,
    thirdFormState,
    fourthFormState,
    fifthFormState,
    sixthFormState,
    seventhFormState,
    setSeventhFormState,
    createRental
  } = props
  const classes = useStyles()

  let sigPad = useRef(null)
  const [isLoading, setIsLoading] = useState("")
  const history = useHistory()

  const clear = () => {
    if (seventhFormState.signature === null) {
      sigPad.current.clear()
    } else {
      setSeventhFormState({
        ...seventhFormState,
        signature: null
      })
    }
  }

  const trim = () => {
    if (seventhFormState.signature === null) {
      const getCanvas = sigPad.current
      if (!getCanvas.isEmpty()) {
        setSeventhFormState({
          ...seventhFormState,
          signature: getCanvas.getTrimmedCanvas().toDataURL("image/png"),
          error: false
        })
      }
    }
  }

  const submitHandler = async () => {
    if (seventhFormState.signature === null) {
      setSeventhFormState({ ...seventhFormState, error: true })
      return
    }

    setIsLoading("Uploading documents...")

    const filterFiles = file => {
      if (file) {
        return file?.filter(item => item && item.name)
      }
      return []
    }

    const rentalFiles = [
      {
        key: "leaseAgreementFile",
        value: filterFiles(secondFormState.leaseAgreementFile)
      },
      {
        key: "bankStatementFiles",
        value: filterFiles(thirdFormState.bankStatementFiles)
      },
      {
        key: "taxReturnsFiles",
        value: filterFiles(thirdFormState.taxReturnsFiles)
      },
      {
        key: "invoicesFiles",
        value: filterFiles(thirdFormState.invoicesFiles)
      },
      {
        key: "petFiles",
        value: filterFiles(fourthFormState.petFiles)
      },
      {
        key: "proofOfIncomeFile",
        value: filterFiles(sixthFormState.proofOfIncomeFile)
      },
      {
        key: "govIdsFile",
        value: filterFiles(sixthFormState.govIdsFile)
      },
      {
        key: "supplementaryDocFile",
        value: filterFiles(sixthFormState.supplementaryDocFile)
      },
      {
        key: "creditScoreFile",
        value: filterFiles(sixthFormState.creditScoreFile)
      }
    ]
    const rentalValues = {
      applicationDate: moment.utc().format(),
      commencementDate: firstFormState.commencementDate,
      rentalPropertyAddress: firstFormState.rentalPropertyAddress,
      unitId: firstFormState.unitId,
      listingId: firstFormState.listingId,
      propertySeen_viewing: firstFormState.propertySeen.viewing,
      propertySeen_scheduled: firstFormState.propertySeen.scheduled,
      propertySeen_providedPictures: firstFormState.propertySeen.providedPictures,
      propertySeen_other: firstFormState.propertySeen.other,
      applyingAs: firstFormState.applyingAs,
      otherPropertySeen: firstFormState.otherPropertySeen,
      guaranteeFullName: firstFormState.guaranteeFullName,
      yourOffer: firstFormState.yourOffer,
      leaseTerm: firstFormState.leaseTerm,
      firstName: secondFormState.fName,
      lastName: secondFormState.lName,
      dateOfBirth: secondFormState.dob,
      phone: secondFormState.phone,
      userEmail: secondFormState.userEmail,
      address_street: secondFormState.address.street,
      address_city: secondFormState.address.city,
      address_province: secondFormState.address.province,
      address_postal: secondFormState.address.postal,
      address_country: secondFormState.address.country,
      livingDate: secondFormState.livingDate,
      renting: secondFormState.renting,
      currentLandlordName: secondFormState.landlordName,
      currentLandlordPhone: secondFormState.landlordPhone,
      currentLandlordEmail: secondFormState.landlordEmail,
      reachingTime_weekdayMorning: secondFormState.reachingTime.weekdayMorning,
      reachingTime_weekdayAfternoon: secondFormState.reachingTime.weekdayAfternoon,
      reachingTime_weekendMorning: secondFormState.reachingTime.weekendMorning,
      reachingTime_weekendAfternoon: secondFormState.reachingTime.weekendAfternoon,
      reachingTime_notSure: secondFormState.reachingTime.notSure,
      reasonLeaving: secondFormState.reasonLeaving,
      previousAddress_street: secondFormState.previousAddress.street,
      previousAddress_city: secondFormState.previousAddress.city,
      previousAddress_province: secondFormState.previousAddress.province,
      previousAddress_postal: secondFormState.previousAddress.postal,
      previousAddress_country: secondFormState.previousAddress.country,
      previousLivingDate: secondFormState.previousLivingDate,
      previousRenting: secondFormState.previousRenting,
      previousLandlordName: secondFormState.previousLandlordName,
      previousLandlordPhone: secondFormState.previousLandlordPhone,
      previousLandlordEmail: secondFormState.previousLandlordEmail,
      smoker: secondFormState.smoker,
      grossIncome: thirdFormState.grossIncome,
      selfEmployed: thirdFormState.selfEmployed,
      website: thirdFormState.website,
      expenseFigures: thirdFormState.expenseFigures,
      revenueSalesFigures: thirdFormState.revenueSalesFigures,
      selfEmployedDocs_bankStatement: thirdFormState.selfEmployedDocs.bankStatement,
      selfEmployedDocs_taxReturns: thirdFormState.selfEmployedDocs.taxReturns,
      selfEmployedDocs_accountantContact: thirdFormState.selfEmployedDocs.accountantContact,
      selfEmployedDocs_invoices: thirdFormState.selfEmployedDocs.invoices,
      accountant_phone: thirdFormState.accountantPhone,
      accountant_email: thirdFormState.accountantEmail,
      occupation: thirdFormState.occupation,
      currentEmployer: thirdFormState.currentEmployer,
      currentEmployerAddress_street: thirdFormState.currentEmployerAddress.street,
      currentEmployerAddress_city: thirdFormState.currentEmployerAddress.city,
      currentEmployerAddress_province: thirdFormState.currentEmployerAddress.province,
      currentEmployerAddress_postal: thirdFormState.currentEmployerAddress.postal,
      currentEmployerAddress_country: thirdFormState.currentEmployerAddress.country,
      currentEmployer_phone: thirdFormState.currentEmployerPhone,
      employmentTerm: thirdFormState.employmentTerm || "",
      employmentStatus_temporary: thirdFormState.employmentStatus.temporary,
      employmentStatus_permanent: thirdFormState.employmentStatus.permanent,
      employmentStatus_partTime: thirdFormState.employmentStatus.partTime,
      employmentStatus_fullTime: thirdFormState.employmentStatus.fullTime,
      employmentStatus_contract: thirdFormState.employmentStatus.contract,
      currentEmploymentEndDate: thirdFormState.currentEmploymentEndDate,
      employmentReference_name: thirdFormState.employmentReference,
      referenceStatus: thirdFormState.referenceStatus,
      employmentReference_phone: thirdFormState.employmentReferencePhone,
      employmentReference_email: thirdFormState.employmentReferenceEmail,
      firstEmployer: thirdFormState.firstEmployer,
      previousEmployer: thirdFormState.previousEmployer,
      previousEmployerAddress_street: thirdFormState.previousEmployerAddress?.street || "",
      previousEmployerAddress_city: thirdFormState.previousEmployerAddress?.city || "",
      previousEmployerAddress_province: thirdFormState.previousEmployerAddress?.province || "",
      previousEmployerAddress_postal: thirdFormState.previousEmployerAddress?.postal || "",
      previousEmployerAddress_country: thirdFormState.previousEmployerAddress?.country || "",
      previousPositionTitle: thirdFormState.previousPositionTitle || "",
      linkedIn: thirdFormState.linkedIn,
      financialObligation_none: fourthFormState.financialObligation.none,
      financialObligation_house: fourthFormState.financialObligation.house,
      financialObligation_vehicles: fourthFormState.financialObligation.vehicles,
      financialObligation_medical: fourthFormState.financialObligation.medical,
      financialObligation_education: fourthFormState.financialObligation.education,
      monthlyPayment: fourthFormState.monthlyPayment,
      entities: fourthFormState.entities,
      personalReference_name: fourthFormState.referenceName,
      personalReference_phone: fourthFormState.referencePhone,
      personalReference_email: fourthFormState.referenceEmail,
      referenceRelationship_friend: fourthFormState.referenceRelationship.friend,
      referenceRelationship_neighbour: fourthFormState.referenceRelationship.neighbour,
      referenceRelationship_colleague: fourthFormState.referenceRelationship.colleague,
      referenceRelationship_relative: fourthFormState.referenceRelationship.relative,
      referenceReachingTime_weekdayMorning: fourthFormState.reachingTime.weekdayMorning,
      referenceReachingTime_weekdayAfternoon: fourthFormState.reachingTime.weekdayAfternoon,
      referenceReachingTime_weekendMorning: fourthFormState.reachingTime.weekendMorning,
      referenceReachingTime_weekendAfternoon: fourthFormState.reachingTime.weekendAfternoon,
      referenceReachingTime_notSure: fourthFormState.reachingTime.notSure,
      pets: fourthFormState.pets,
      breed: fourthFormState.breed,
      weight: fourthFormState.weight,
      petDescription: fourthFormState.petDescription,
      petType_dog: fourthFormState.petType.dog,
      petType_cat: fourthFormState.petType.cat,
      petType_fish: fourthFormState.petType.fish,
      petType_bird: fourthFormState.petType.bird,
      petType_other: fourthFormState.petType.other,
      otherPetType: fourthFormState.otherPetType,
      vehicle: fifthFormState.vehicle,
      makeOne: fifthFormState.makeOne,
      modelOne: fifthFormState.modelOne,
      anotherVehicle: fifthFormState.anotherVehicle,
      makeTwo: fifthFormState.makeTwo,
      modelTwo: fifthFormState.modelTwo,
      co_occupants: fifthFormState.coOccupants,
      occupant_one_name: fifthFormState.occupant_1_name,
      occupant_two_name: fifthFormState.occupant_2_name,
      occupant_three_name: fifthFormState.occupant_3_name,
      occupant_four_name: fifthFormState.occupant_4_name,
      occupant_five_name: fifthFormState.occupant_5_name,
      sin: sixthFormState.sin,
      intro: sixthFormState.intro,
      signature: seventhFormState.signature,
      createdAt: moment.utc().format()
    }

    uploadFiles(client, { files: rentalFiles, pathType: "rentalApp" })
      .then(res => {
        const uploadedFiles = res?.data?.MultipleFileUpload
        console.log(uploadedFiles)
        if (uploadedFiles?.error) {
          ErrorModal({
            content: uploadedFiles?.message
          })
          setIsLoading("")
        } else {
          setIsLoading("Submitting your form... Almost there!")
          const parseFiles = JSON.parse(uploadedFiles?.files)
          const rentalVariables = {
            variables: {
              ...rentalValues,
              ...parseFiles
            }
          }
          createRental(rentalVariables)
            .then((rentalResp: any) => {
              const rentalResponse = rentalResp?.data?.CreateRental
              setIsLoading("")
              if (rentalResponse?.error) {
                ErrorModal({
                  content: rentalResponse?.message
                })
              } else {
                SuccessModal({
                  content:
                    "We have received your rental application. You will be contacted if any additional information is required. Thank you for your interest!",
                  onOk() {
                    localStorage.removeItem("rental-application-draft")
                    history.push("/")
                  }
                })
              }
            })
            .catch(() => {
              setIsLoading("")
              ErrorModal()
            })
        }
      })
      .catch(() => {
        setIsLoading("")
        ErrorModal({
          content: "Error while uploading documents, Try after sometime."
        })
      })
  }

  return (
    <>
      <div className="rental-application">
        <div className="">
          <h2 className="h2">Signature</h2>
          <p>
            <em>
              The Applicant consents to the collection, use and disclosure of the Applicant's
              personal information by the Landlord and/or agent of the Landlord, Tolobi Group Inc., from time
              to time, for the purpose of determining the creditworthiness of the Applicant for the
              leasing of the premises or the real property, or making such other use of the personal
              information as the Landlord and/or agent of the Landlord deems appropriate.
              <br />
              <br />
              The Applicant represents that all statements made above are true and correct. The
              Applicant is hereby notified that a consumer report containing credit and/or personal
              information may be referred to in connection with this rental. The Applicant
              authorizes the verification of the information contained in this application and
              information obtained from personal references. This application is not a Rental or
              Lease Agreement.
            </em>
          </p>
          <div className="form">
            <div className="field-container">
              <div>
                <p>
                  I confirm that the information submitted in this rental application is true and
                  correct to the best of my knowledge.*
                </p>
              </div>
              <div className={classes.container}>
                <div className={classes.sigContainer}>
                  {seventhFormState.signature === null ? (
                    <SignaturePad canvasProps={{ className: classes.sigPad }} ref={sigPad} />
                  ) : (
                    <img
                      className={classes.sigImage}
                      alt="signature"
                      src={seventhFormState.signature}
                    />
                  )}
                </div>
              </div>
              <div className="sig-btn-group">
                <IconButton aria-label="clear" className={classes.btn} onClick={clear}>
                  <ReplayIcon />
                </IconButton>
                {seventhFormState.signature === null && (
                  <IconButton aria-label="save" className={classes.btn} onClick={trim}>
                    <SaveIcon />
                  </IconButton>
                )}
              </div>
              {seventhFormState.error && (
                <Error>Please save your signature before proceeding</Error>
              )}
            </div>
            <ColorButton
              onClick={event => {
                event.preventDefault()
                submitHandler()
              }}
            >
              Submit!
            </ColorButton>
          </div>
        </div>
      </div>
      <MuiLoader isLoading={!!isLoading} text={isLoading} />
    </>
  )
}

const RentalApplication = props => {
  const [currentPage, setCurrentPage] = useState(-1)
  const [firstFormState, setFirstFormState] = useState(FIRST_APP_INIT_STATE)
  const [secondFormState, setSecondFormState] = useState(SECOND_APP_INIT_STATE)
  const [thirdFormState, setThirdFormState] = useState(THIRD_APP_INIT_STATE)
  const [fourthFormState, setFourthFormState] = useState(FOURTH_APP_INIT_STATE)
  const [fifthFormState, setFifthFormState] = useState(FIFTH_APP_INIT_STATE)
  const [sixthFormState, setSixthFormState] = useState(SIXTH_APP_INIT_STATE)
  const [seventhFormState, setSeventhFormState] = useState(SEVENTH_APP_INIT_STATE)

  useEffect(() => {
    let draft = localStorage.getItem("rental-application-draft")
    if (draft) {
      let draftData = JSON.parse(draft)
      setFirstFormState(draftData.firstFormState)
      setSecondFormState(draftData.secondFormState)
      setThirdFormState(draftData.thirdFormState)
      setFourthFormState(draftData.fourthFormState)
      setFifthFormState(draftData.fifthFormState)
      setSixthFormState(draftData.sixthFormState)
      setSeventhFormState(draftData.seventhFormState)
    }
  }, [])

  const checkSelfEmployedDocs = () => {
    let count = 0
    Object.entries(thirdFormState.selfEmployedDocs).forEach(([key, value]) => {
      if (value === true) {
        count++
      }
    })
    if (count >= 2) {
      return false
    } else {
      return true
    }
  }

  const validator = (pageNo, dir) => {
    switch (pageNo) {
      case 0:
        const {
          applicationDate,
          commencementDate,
          applyingAs,
          rentalPropertyAddress,
          propertySeen,
          otherPropertySeen,
          yourOffer,
          leaseTerm,
          guaranteeFullName
        } = firstFormState
        if (
          !applicationDate ||
          !commencementDate ||
          !applyingAs ||
          !rentalPropertyAddress ||
          (applyingAs === "tenant" &&
            propertySeen.viewing === false &&
            propertySeen.scheduled === false &&
            propertySeen.providedPictures === false &&
            propertySeen.other === false) ||
          (applyingAs === "guarantor" && !guaranteeFullName) ||
          (applyingAs === "tenant" && propertySeen.other === true && otherPropertySeen === "") ||
          (applyingAs === "tenant" && yourOffer === "") ||
          +leaseTerm < 0.1 ||
          +leaseTerm > 100
        ) {
          setFirstFormState({ ...firstFormState, error: true })
        } else {
          setFirstFormState({ ...firstFormState, error: false })
          setCurrentPage(currentPage + 1)
        }
        break
      case 1:
        const {
          fName,
          lName,
          dob,
          phone,
          userEmail,
          confirmEmail,
          address,
          renting,
          landlordName,
          landlordEmail,
          landlordConfirmEmail,
          reasonLeaving,
          previousLandlordEmail,
          previousLandlordConfirmEmail,
          landlordPhone
        } = secondFormState

        if (
          !fName?.trim() ||
          !lName?.trim() ||
          dob === null ||
          !phone?.trim() ||
          phone.length !== 11 ||
          !userEmail ||
          (confirmEmail === "" &&
            userEmail !== "" &&
            confirmEmail !== "" &&
            userEmail !== confirmEmail) ||
          (secondFormState.userEmail !== "" && !isValidEmail(secondFormState.userEmail)) ||
          (userEmail !== "" && userEmail !== confirmEmail) ||
          (secondFormState.previousLandlordEmail !== "" &&
            !isValidEmail(secondFormState.previousLandlordEmail)) ||
          (previousLandlordEmail !== "" &&
            previousLandlordEmail !== previousLandlordConfirmEmail) ||
          !address.street?.trim() ||
          !address.province?.trim() ||
          !address.postal?.trim() ||
          !address.country?.trim() ||
          (renting === true && !landlordName) ||
          (firstFormState.applyingAs === "tenant" && !reasonLeaving) ||
          (landlordEmail !== "" && landlordEmail !== landlordConfirmEmail) ||
          (renting && landlordPhone?.length !== 11)
        ) {
          setSecondFormState({ ...secondFormState, error: true })
        } else {
          setSecondFormState({ ...secondFormState, error: false })
          if (dir === "next") {
            setCurrentPage(currentPage + 1)
          } else {
            setCurrentPage(currentPage - 1)
          }
        }
        break
      case 2:
        const {
          grossIncome,
          selfEmployed,
          selfEmployedDocs,
          bankStatementFiles,
          taxReturnsFiles,
          invoicesFiles,
          website,
          linkedIn,
          accountantEmail,
          currentEmployer,
          employmentReference,
          referenceStatus,
          employmentReferenceEmail,
          employmentReferenceConfirmEmail
        } = thirdFormState
        if (
          grossIncome === "" ||
          (selfEmployed && checkSelfEmployedDocs()) ||
          (selfEmployed && selfEmployedDocs.bankStatement && bankStatementFiles === null) ||
          (selfEmployed && selfEmployedDocs.taxReturns && taxReturnsFiles === null) ||
          (selfEmployed && selfEmployedDocs.invoices && invoicesFiles === null) ||
          (selfEmployed && website !== "" && !isValidUrl(website)) ||
          (selfEmployed &&
            selfEmployedDocs.accountantContact &&
            accountantEmail !== "" &&
            !isValidEmail(accountantEmail)) ||
          (linkedIn !== "" && !isValidUrl(linkedIn)) ||
          (currentEmployer !== "" && employmentReference === "") ||
          (currentEmployer !== "" && referenceStatus === "") ||
          (currentEmployer !== "" && employmentReferenceEmail === "") ||
          (currentEmployer !== "" &&
            employmentReferenceEmail !== "" &&
            !isValidEmail(employmentReferenceEmail)) ||
          (currentEmployer !== "" &&
            employmentReferenceEmail !== "" &&
            employmentReferenceEmail !== employmentReferenceConfirmEmail)
        ) {
          setThirdFormState({ ...thirdFormState, error: true })
        } else {
          setThirdFormState({ ...thirdFormState, error: false })
          if (dir === "next") {
            setCurrentPage(currentPage + 1)
          } else {
            setCurrentPage(currentPage - 1)
          }
        }
        break
      case 3:
        const {
          financialObligation,
          referenceName,
          referenceEmail,
          pets,
          petType,
          otherPetType
        } = fourthFormState
        if (
          (!financialObligation.none &&
            !financialObligation.house &&
            !financialObligation.medical &&
            !financialObligation.education &&
            !financialObligation.vehicles) ||
          (firstFormState.applyingAs === "tenant" &&
            (!referenceName ||
              !referenceEmail ||
              !isValidEmail(referenceEmail) ||
              (pets &&
                (!Object.values(petType || {}).some(item => item) ||
                  (petType.other && !otherPetType)))))
        ) {
          setFourthFormState({ ...fourthFormState, error: true })
        } else {
          setFourthFormState({ ...fourthFormState, error: false })
          if (dir === "next") {
            if (firstFormState.applyingAs === "guarantor") {
              setCurrentPage(currentPage + 3)
            } else {
              setCurrentPage(currentPage + 1)
            }
          } else {
            setCurrentPage(currentPage - 1)
          }
        }
        break
      case 4:
        const { vehicle, makeOne, modelOne, anotherVehicle, makeTwo, modelTwo } = fifthFormState
        if (
          (vehicle && (!makeOne || !modelOne)) ||
          (vehicle && anotherVehicle && (!makeTwo || !modelTwo))
        ) {
          setFifthFormState({ ...fifthFormState, error: true })
        } else {
          setFifthFormState({ ...fifthFormState, error: false })
          if (dir === "next") {
            setCurrentPage(currentPage + 1)
          } else {
            setCurrentPage(currentPage - 1)
          }
        }
        break
      case 5:
        const { coOccupants } = fifthFormState
        if (dir !== "next") {
          setCurrentPage(currentPage - 1)
        }
        if (+coOccupants > 0) {
          const newArrKeys = [...new Array(+coOccupants || 0).keys()]
          const err = newArrKeys.some((key): any => {
            if (!fifthFormState[`occupant_${key + 1}_name`]) {
              setFifthFormState({ ...fifthFormState, error: true })
              return true
            }
          })
          if (err) {
            return
          }
        }
        setCurrentPage(currentPage + 1)
        break
      case 6:
        if (
          isEmpty(sixthFormState.creditScoreFile) ||
          isEmpty(sixthFormState.proofOfIncomeFile) ||
          isEmpty(sixthFormState.govIdsFile)
        ) {
          setSixthFormState({ ...sixthFormState, error: true })
        } else {
          setSixthFormState({ ...sixthFormState, error: false })
          if (dir === "next") {
            setCurrentPage(currentPage + 1)
          } else {
            setCurrentPage(currentPage - 1)
          }
        }
        break
      case 7:
        if (dir === "next") {
          setCurrentPage(currentPage + 1)
        } else {
          setCurrentPage(currentPage - 1)
        }
        break
    }
  }

  const saveDraftHandler = () => {
    let draft = {
      firstFormState,
      secondFormState,
      thirdFormState,
      fourthFormState,
      fifthFormState,
      sixthFormState,
      seventhFormState
    }
    setTimeout(() => {
      localStorage.setItem("rental-application-draft", JSON.stringify(draft))
    }, 0)

    notify(NOTIFICATION_MSG.Success, "Draft saved")
  }

  return (
    <>
      <Sidepannel
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        firstFormState={firstFormState}
        setFirstFormState={setFirstFormState}
        error={firstFormState.error}
        //second
        secondFormState={secondFormState}
        setSecondFormState={setSecondFormState}
        applyingAs={firstFormState.applyingAs}
        isValidEmail={isValidEmail}
        //third
        thirdFormState={thirdFormState}
        setThirdFormState={setThirdFormState}
        checkSelfEmployedDocs={checkSelfEmployedDocs}
        isValidUrl={isValidUrl}
        //fourth
        fourthFormState={fourthFormState}
        setFourthFormState={setFourthFormState}
        //fifth
        fifthFormState={fifthFormState}
        setFifthFormState={setFifthFormState}
        //sixth
        sixthFormState={sixthFormState}
        setSixthFormState={setSixthFormState}
        //seven
        createRental={props.CreateRental}
        seventhFormState={seventhFormState}
        setSeventhFormState={setSeventhFormState}
        saveDraftHandler={saveDraftHandler}
        validator={validator}
      />
    </>
  )
}

export const Sidepannel = (props: any) => {
  const {
    firstFormState,
    setFirstFormState,
    error,
    currentPage,
    setCurrentPage,
    secondFormState,
    setSecondFormState,
    isValidEmail,
    thirdFormState,
    setThirdFormState,
    checkSelfEmployedDocs,
    isValidUrl,
    fourthFormState,
    setFourthFormState,
    fifthFormState,
    setFifthFormState,
    sixthFormState,
    setSixthFormState,
    seventhFormState,
    createRental,
    setSeventhFormState,
    saveDraftHandler,
    validator
  } = props
  const classes = useStyles()
  const matches = useMediaQuery("(min-width:700px)")

  React.useEffect(() => {
    const mainElement = document.getElementsByTagName("main")[0]
    if (mainElement) {
      mainElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
    }
  }, [currentPage])

  const stickyStyles: Record<string, any> = matches
    ? {
      position: "sticky",
      height: "calc(100vh - 80px)",
      top: 80
    }
    : {}

  return (
    <div className={matches ? classes.root : classes.root1}>
      <div
        style={{
          borderRight: matches ? " 1px solid rgba(0, 0, 0, 0.12)" : "",
          padding: "10px",
          ...stickyStyles
        }}
      >
        <Tabs
          orientation={matches ? "vertical" : "horizontal"}
          variant="scrollable"
          value={currentPage}
          // onChange={handleChange}
          // style={{ marginLeft: matches ? "100px !important" : "" }}
          aria-label="Vertical tabs example"
          className={matches ? classes.tabs : classes.tabs2}
          classes={{
            indicator: classes.indicator
          }}
          selectionFollowsFocus
          TabIndicatorProps={{
            style: { background: "inherit", zIndex: -1, opacity: 1, width: "100%" }
          }}
        >
          <Tab
            label={
              <span
                style={{
                  color: currentPage >= -1 ? "rgb(0, 168, 87)" : "#909392",
                  borderBottom: !matches && currentPage === -1 ? "2px solid #000000" : "",
                  textAlign: "left"
                }}
                className={currentPage === -1 ? "tab-label-active" : "tab-label-normal"}
              >
                <div
                  style={{
                    color: matches && currentPage === -1 ? "" : "rgb(0, 168, 87)"
                  }}
                  className={matches && currentPage === -1 ? "dot" : ""}
                />
                Information
              </span>
            }
            {...a11yProps(-1)}
          />
          <Tab
            label={
              <span
                style={{
                  color: currentPage >= 0 ? "rgb(0, 168, 87)" : "#909392",
                  borderBottom: !matches && currentPage === 0 ? "2px solid #000000" : "",
                  textAlign: "left"
                }}
                className={currentPage === 0 ? "tab-label-active" : "tab-label-normal"}
              >
                <div className={matches && currentPage === 0 ? "dot" : ""} />
                Rental Offer
              </span>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <span
                style={{
                  color: currentPage >= 1 ? "rgb(0, 168, 87)" : "#909392",
                  borderBottom: !matches && currentPage === 1 ? "2px solid #000000" : "",
                  textAlign: "left"
                }}
                className={currentPage === 1 ? "tab-label-active" : "tab-label-normal"}
              >
                <div className={matches && currentPage === 1 ? "dot" : ""} />
                Applicant
              </span>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <span
                style={{
                  color: currentPage >= 2 ? "rgb(0, 168, 87)" : "#909392",
                  borderBottom: !matches && currentPage === 2 ? "2px solid #000000" : "",
                  textAlign: "left"
                }}
                className={currentPage === 2 ? "tab-label-active" : "tab-label-normal"}
              >
                <div className={matches && currentPage === 2 ? "dot" : ""} />
                Income
              </span>
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <span
                style={{
                  color: currentPage >= 3 ? "rgb(0, 168, 87)" : "#909392",
                  borderBottom: !matches && currentPage === 3 ? "2px solid #000000" : "",
                  textAlign: "left"
                }}
                className={currentPage === 3 ? "tab-label-active" : "tab-label-normal"}
              >
                <div className={matches && currentPage === 3 ? "dot" : ""} />
                Financial Obligations
              </span>
            }
            {...a11yProps(3)}
          />
          {firstFormState.applyingAs !== "guarantor" && (
            <>
              <Tab
                label={
                  <span
                    style={{
                      color: currentPage >= 4 ? "rgb(0, 168, 87)" : "#909392",
                      borderBottom: !matches && currentPage === 4 ? "2px solid #000000" : "",
                      textAlign: "left"
                    }}
                    className={currentPage === 4 ? "tab-label-active" : "tab-label-normal"}
                  >
                    <div className={matches && currentPage === 4 ? "dot" : ""} />
                    Vehicles
                  </span>
                }
                {...a11yProps(4)}
              />
              <Tab
                label={
                  <span
                    style={{
                      color: currentPage >= 5 ? "rgb(0, 168, 87)" : "#909392",
                      borderBottom: !matches && currentPage === 5 ? "2px solid #000000" : "",
                      textTransform: "none",
                      textAlign: "left"
                    }}
                    className={currentPage === 5 ? "tab-label-active" : "tab-label-normal"}
                  >
                    <div className={matches && currentPage === 5 ? "dot" : ""} />
                    Co-occupants
                  </span>
                }
                {...a11yProps(5)}
              />
            </>
          )}
          <Tab
            label={
              <span
                style={{
                  color: currentPage >= 6 ? "rgb(0, 168, 87)" : "#909392",
                  borderBottom: !matches && currentPage === 6 ? "2px solid #000000" : "",
                  textAlign: "left"
                }}
                className={currentPage === 6 ? "tab-label-active" : "tab-label-normal"}
              >
                <div className={matches && currentPage === 6 ? "dot" : ""} />
                Supplementary Documents
              </span>
            }
            {...a11yProps(6)}
          />
          <Tab
            label={
              <span
                style={{
                  color: currentPage >= 7 ? "rgb(0, 168, 87)" : "#909392",
                  borderBottom: !matches && currentPage === 7 ? "2px solid #000000" : "",
                  textAlign: "left"
                }}
                className={currentPage === 7 ? "tab-label-active" : "tab-label-normal"}
              >
                <div className={matches && currentPage === 7 ? "dot" : ""} />
                Signature
              </span>
            }
            {...a11yProps(7)}
          />
        </Tabs>
        {matches && (
          <div className={classes.action__buttons}>
            <Button
              aria-label="save-draft"
              className={classes.draftBtn}
              onClick={saveDraftHandler}
              startIcon={<SaveIcon style={{ fontSize: "16px", marginRight: 5 }} />}
            >
              Save as Draft
            </Button>

            <Button
              aria-label="cancel-draft"
              className={classes.draftBtnOut}
              onClick={() => {
                localStorage.removeItem("rental-application-draft")
                notify(NOTIFICATION_MSG.Success, "Removed From Draft")
              }}
              startIcon={<CloseIcon style={{ fontSize: "16px", marginRight: 5 }} />}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
      <div className="tab-pannel1" id="tab-pannel-container-pdf">
        <TabPanel value={currentPage} index={-1}>
          <div className="rental-application-container">
            <div className="">
              <h2 className="h2">Rental Application</h2>
              <p>
                As per our standard procedure, you and your co-applicants (the "<b>Applicants</b>")
                will be required to provide:
              </p>
              <p className="tag-p">
                <ul>1. Credit check</ul>
                <ul>2. Proof of income</ul>
                <ul>3. 2 Pieces of Photo ID</ul>
                <ul>4. References</ul>
              </p>
              <p>
                Rental Application is required per applicant. For example, three Applicants whose
                names will be on the Standard Form of Lease must submit three separate rental
                applications.
                <br />
              </p>
              <p>
                As we typically receive high volume of rental applications, please ensure that you
                complete the below as much as possible:
              </p>
              <div
                className="rowss"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: 20
                }}
              >
                <div className="btnicon">
                  <button
                    aria-label="arrowForward"
                    className={classes.btn}
                    onClick={() => {
                      setCurrentPage(0)
                    }}
                    style={{
                      fontWeight: 600
                    }}
                  >
                    Start
                    <ArrowForwardIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={currentPage} index={0}>
          <ApplicationFirst
            firstFormState={firstFormState}
            setFirstFormState={setFirstFormState}
            error={error}
          />
        </TabPanel>
        <TabPanel value={currentPage} index={1}>
          <ApplicationSecond
            secondFormState={secondFormState}
            setSecondFormState={setSecondFormState}
            error={secondFormState.error}
            applyingAs={firstFormState.applyingAs}
            isValidEmail={isValidEmail}
          />
        </TabPanel>
        <TabPanel value={currentPage} index={2}>
          <ApplicationThird
            thirdFormState={thirdFormState}
            setThirdFormState={setThirdFormState}
            error={thirdFormState.error}
            checkSelfEmployedDocs={checkSelfEmployedDocs}
            isValidUrl={isValidUrl}
            isValidEmail={isValidEmail}
          />
        </TabPanel>
        <TabPanel value={currentPage} index={3}>
          <ApplicationFourth
            fourthFormState={fourthFormState}
            setFourthFormState={setFourthFormState}
            isValidEmail={isValidEmail}
            error={fourthFormState.error}
            firstFormState={firstFormState}
          />
        </TabPanel>
        <TabPanel value={currentPage} index={4}>
          <ApplicationFifth
            fifthFormState={fifthFormState}
            setFifthFormState={setFifthFormState}
            error={fifthFormState.error}
            firstFormState={firstFormState}
          />
        </TabPanel>
        <TabPanel value={currentPage} index={5}>
          <ApplicationFifthCo
            fifthFormState={fifthFormState}
            setFifthFormState={setFifthFormState}
            error={fifthFormState.error}
            firstFormState={firstFormState}
          />
        </TabPanel>
        <TabPanel value={currentPage} index={6}>
          <ApplicationSixth
            sixthFormState={sixthFormState}
            setSixthFormState={setSixthFormState}
            error={sixthFormState.error}
            isSelfEmployed={!!thirdFormState.selfEmployed}
          />
        </TabPanel>
        <TabPanel value={currentPage} index={7}>
          <ApplicationSeventh
            firstFormState={firstFormState}
            secondFormState={secondFormState}
            thirdFormState={thirdFormState}
            fourthFormState={fourthFormState}
            fifthFormState={fifthFormState}
            sixthFormState={sixthFormState}
            seventhFormState={seventhFormState}
            setSeventhFormState={setSeventhFormState}
            createRental={createRental}
          />
        </TabPanel>
        <div className="rental-application">
          <div className="">
            <div className="btn-group" style={{ marginTop: 0 }}>
              {currentPage !== 0 && currentPage !== -1 && (
                <IconButton
                  aria-label="arrowForward"
                  className={classes.btn}
                  onClick={() => {
                    if (firstFormState.applyingAs === "guarantor" && currentPage == 5) {
                      setCurrentPage(currentPage - 3)
                    } else {
                      setCurrentPage(currentPage - 1)
                    }
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
              {currentPage !== 7 && currentPage !== -1 && (
                <IconButton
                  aria-label="arrowForward"
                  className={classes.btn}
                  onClick={() => {
                    validator(currentPage, "next")
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              )}
            </div>
            {!!(currentPage !== -1 && !matches) && (
              <div className="btn-head2">
                <IconButton
                  aria-label="save-draft"
                  className={classes.draftBtn1}
                  onClick={saveDraftHandler}
                >
                  <SaveIcon style={{ fontSize: "16px", marginRight: 5 }} /> Save as Draft
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default compose(graphql(CREATE_RENTAL, { name: "CreateRental" }))(RentalApplication)
// export default RentalApplication
