import * as React from "react"
import styled, { keyframes, CSSObject, css } from "styled-components"
import { Colors } from "../../theme/index"

import Box from "../box"

const Wrapper = styled(Box as any)<any>`

${({ duration, animation }) => {
  const keyFrame = keyframes`${animation}`

  const animationProperty = css`
    animation: ${duration} ${keyFrame} ease-out;
  `
  return animationProperty
}}
  background: ${Colors.white};
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 3;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 40px;
  text-align: left;
  overflow-y: scroll;
`

const ContentBox = styled(Box as any)`
  flex: 1;
`

const ModalWrapper = (props: {
  animate: CSSObject
  duration: string
  restProps?: any
  children: React.ReactElement
}) => {
  const { duration, animate, restProps, children } = props
  return (
    <Wrapper {...restProps} duration={duration} animation={animate.type}>
      <ContentBox>{children}</ContentBox>
    </Wrapper>
  )
}

export default ModalWrapper
