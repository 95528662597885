import { FormControl } from "@material-ui/core"
import React from "react"
import PhoneInput from "react-phone-input-2"
import Text from "../../text"
import "./style.css"

const delay = () => import("react-phone-input-2/lib/style.css")
setTimeout(() => {
  delay()
}, 0)

export default function SignUpPhoneInputs(props) {
  const { labelTxt, field, form, ...rest } = props

  const handleChangePhone = phone => {
    form.setFieldValue(field.name, phone)
  }

  const handleBlur = () => {
    form.setTouched({ ...form.touched, [field.name]: true })
  }

  return (
    <FormControl id="custom-forms" style={{ marginBottom: 15 }} fullWidth>
      <Text genre="labelText">{labelTxt}</Text>
      <PhoneInput
        country={"ca"}
        placeholder="(123) 456-7890"
        value={field.value || undefined}
        onChange={phone => handleChangePhone(phone)}
        onBlur={handleBlur}
        inputProps={{
          required: true
        }}
        containerStyle={{
          height: 40
        }}
        inputStyle={{
          height: 40,
          fontSize: 16,
          width: "100%",
          boxShadow: "none",
          background: "#fafafa"
        }}
      />
      {form.errors[field.name] && (
        <div className="error-text">
          {rest.create_err_msg ? rest.create_err_msg(field.value) : form.errors[field.name]}
        </div>
      )}
    </FormControl>
  )
}
