import { CITIES } from "src/services/graphql"

export const fetchCities = async client => {
  try {
    return await client.query({
      query: CITIES
    })
  } catch (err) {
    console.log("error", err)
  }
}
