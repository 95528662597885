import { SelectedPlans } from "."
import { PREMIUM_TYPE } from "../constants"
import { REGION } from "../constants/constants"

export const parseData = (planData: (SelectedPlans[number] & { feature: string })[]) => {

  const BgColor = ["#a6a5a6", "#514BA8", "#68CF7E", "#6e2772", "#d35353", "#fbf31b"]
  // const SubTitle = [" ", "per lease", "per lease per month"]
  const Type = ["BASIC", "PREMIUM"]

  function addSpaceBeforeParentheses(str) {
    return str.replace(/(\()/g, ' $1');
  }

  const respData =
    planData &&
    planData.map((plan, index) => {
      return {
        id: plan.id,
        title: addSpaceBeforeParentheses(plan.name),
        price: `${plan.price}`,
        bgColor: BgColor[index],
        subTitle: plan.unit,
        plan_group: plan.plan_group,
        type: `${+plan.price === 0 ? Type[0] : Type[1]}`,
        recommended: plan?.id?.toString() === "6" ? true : false,
        // region: Region[index],
        features: plan.feature
      }
    })
  return respData
}
