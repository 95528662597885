import React, { useState, useEffect } from "react"
import { IconButton } from "@material-ui/core"
import ReplayIcon from "@material-ui/icons/Replay"
import SignaturePad from "react-signature-canvas"
import SaveIcon from "@material-ui/icons/Save"
import { FormControl } from "@material-ui/core"
import Text from "src/components/text/index"
import "./style.css"

const Signature = ({
    icon,
    form: { touched, errors, setFieldValue },
    field,
    labelTxt,
    styleInput = {},
    offsetWidth = 0,
    ...rest
}) => {
    const [containerWidth, setContainerWidth] = useState(0);

    const updateContainerDimensions = () => {
        const { clientWidth } = document.getElementById("custom-form")!;
        setContainerWidth(clientWidth);
    };

    const [trimmedDataURL, setTrimmedDataURL] = useState<({ url: string | null })[]>(field.value ?? []);
    const signatureRef = React.useRef<SignaturePad | null>(null);
    const handleConfirmSignature = () => {
        const sigObj = [{ url: signatureRef.current?.getTrimmedCanvas()?.toDataURL("image/png") ?? null }]
        setFieldValue(field.name, sigObj)
        setTrimmedDataURL(sigObj)
    };
    const handleClearCanvas = () => {
        signatureRef.current?.clear()
        // formikProps.setFieldValue(name, "")
    }
    useEffect(() => {
        const observer = new ResizeObserver(updateContainerDimensions);
        observer.observe(document.getElementById("custom-form")!);
        return () => {
            observer.disconnect();
        };
    }, []);
    return (

        <FormControl id="custom-form" fullWidth style={{ marginBottom: 15 }}>
            <Text genre="labelText">{labelTxt}</Text>
            <div style={{ display: "flex", justifyContent: "space-between", objectFit: "contain", flexWrap: "wrap", alignItems: "center" }}>
                <div>
                    {/* @ts-expect-error */}
                    <SignaturePad
                        ref={signatureRef}
                        canvasProps={{
                            width: containerWidth - offsetWidth,
                            height: '200px',
                            className: "sigCanvas"
                        }}
                    />

                    <IconButton onClick={handleClearCanvas} >
                        <ReplayIcon />
                    </IconButton>
                    < IconButton onClick={handleConfirmSignature} >
                        <SaveIcon />
                    </IconButton>
                </div>

                {trimmedDataURL[0]?.url && <img src={trimmedDataURL[0].url} className="sigImg" alt='signature' />}
            </div>
            {errors[field.name] && touched[field.name] && (
                <div className="error-text">
                    {rest.create_err_msg ? rest.create_err_msg(field.value) : errors[field.name]}
                </div>
            )}
        </FormControl>

    )
}

export default Signature