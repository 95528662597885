import React, { memo } from "react"
import { RightIcon } from "src/assets/images"
import FeatureIcon from "src/assets/icons/feature.png"
import Spinner from "src/components/spinner"

import { icons } from "src/assets"
import { Tooltip } from "antd"
import TriangleIcon from "./triangle.png"

type Props = {
  id?: number
  title?: string
  subTitle?: string
  price?: string
  bgColor?: string
  features?: Array<any>
  selected?: boolean
  recommended?: boolean
  handleTheme: (id: number) => void
  handleSubscription: (subscriptions: { id: number, type: string, price: string, name: string, price_id: string, subTitle }, isCurrentPlanSelected: boolean) => void
  isLoading?: boolean
  type?: string,
  plan_group?: string
}

function Card({
  id,
  title,
  handleTheme,
  handleSubscription,
  selected,
  subTitle,
  price,
  bgColor,
  features,
  recommended,
  isLoading,
  type,
  plan_group
}: Props) {
  return (
    <>
      <div
        onClick={() => handleTheme(id)}
        id="plan-card"
        style={{
          borderColor: selected ? "#68CF7E" : "#fff"
        }}
      >
        <div className="card-wrapper">
          <div
            className="card-tag"
            style={{
              background: bgColor,
              textAlign: "center"
            }}
          >
            {title}
            <div className="card-triangle">
              <img alt="" src={TriangleIcon} />
            </div>
          </div>

          <div className="plan-info">
            {isLoading ? <Spinner height={20} /> : <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <h5>{price === "0" ? "Free" : `$${price}`} </h5>
              <span>{subTitle ? `/${subTitle.toLowerCase()}` : ""}</span>
            </div>}
            <div className="plan-list">
              {id?.toString() === "7" ? <div className="feature-list">
                <img src={RightIcon} alt="" />
                <span>{"Tolobi currently offers the Viewings Management service, available in select cities and towns of the Greater Toronto Area, for an additional fee of $600 + HST. A non-refundable deposit of $300 + HST is required upfront."}</span></div> :
                features?.map((item, index) => (
                  <div className="feature-list" key={item.id}>
                    <img src={RightIcon} alt="" />
                    <span>{item.name}</span>
                    {!recommended && (
                      <Tooltip placement="bottomRight" title={item.information}>
                        <span data-tip data-for={index} className="tool-tip">
                          <img src={icons.alert} alt="" />
                        </span>
                      </Tooltip>
                    )}
                  </div>
                ))}
            </div>
            <div
              onClick={() => handleSubscription({ id, type, price, title, plan_group, subTitle }, selected)}
              className="select-btn"
              style={{
                background: selected ? "#65CE7B" : "#fff",
                color: selected ? "#fff" : "#65CE7B"
              }}
              defaultChecked={selected ? true : false}
            >

              <div className="btn">{selected ? "Selected" : "Select"}</div>
            </div>
          </div>
        </div>
        {recommended && (
          <div className="plan-recommended">
            <img src={FeatureIcon} alt="" />
            <span>Most Popular</span>
          </div>
        )}
      </div>
    </>
  )
}

export default memo(Card)




// import React, { memo } from "react"
// import { RightIcon } from "src/assets/images"
// import FeatureIcon from "src/assets/icons/feature.png"
// import Spinner from "src/components/spinner"

// import { icons } from "src/assets"
// import { Tooltip } from "antd"
// import TriangleIcon from "./triangle.png"

// type Props = {
//   id?: number
//   title?: string
//   subTitle?: string
//   price?: string
//   bgColor?: string
//   features?: Array<any>
//   selected?: number
//   recommended?: boolean
//   handleTheme?: (id: number) => void
//   handleSubscription?: (id: number, type: string, price: string, name: string, price_id: string, subTitle: string) => void
//   isLoading?: boolean
//   type?: string,
//   price_id?: string
// }

// function Card({
//   id,
//   title,
//   handleTheme,
//   handleSubscription,
//   selected,
//   subTitle,
//   price,
//   bgColor,
//   features,
//   recommended,
//   isLoading,
//   type,
//   price_id
// }: Props) {
//   return (
//     <>
//       <div
//         onClick={() => handleTheme(id)}
//         id="plan-card"
//         style={{
//           borderColor: selected === id ? "#68CF7E" : "#fff"
//         }}
//       >
//         <div className="card-wrapper">
//           <div
//             className="card-tag"
//             style={{
//               background: bgColor
//             }}
//           >
//             {title}
//             <div className="card-triangle">
//               <img alt="" src={TriangleIcon} />
//             </div>
//           </div>

//           <div className="plan-info">
//             {isLoading ? <Spinner height={20} /> : <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//               <h5>{price === "0" ? "Free" : `$${price}`} </h5>
//               <span>{subTitle ? `/${subTitle.toLowerCase()}` : ""}</span>
//             </div>}
//             <div className="plan-list">
//               {features?.map((item, index) => (
//                 <div className="feature-list" key={item.id}>
//                   <img src={RightIcon} alt="" />
//                   <span>{item.name}</span>
//                   {!recommended && (
//                     <Tooltip placement="bottomRight" title={item.information}>
//                       <span data-tip data-for={index} className="tool-tip">
//                         <img src={icons.alert} alt="" />
//                       </span>
//                     </Tooltip>
//                   )}
//                 </div>
//               ))}
//             </div>
//             <div
//               onClick={() => handleSubscription(id, type, price, title, price_id, subTitle)}
//               className="select-btn"
//               style={{
//                 background: selected === id ? "#65CE7B" : "#fff",
//                 color: selected === id ? "#fff" : "#65CE7B"
//               }}
//               defaultChecked={selected === id ? true : false}
//             >

//               <div className="btn">{selected === id ? "Selected" : "Select"}</div>
//             </div>
//           </div>
//         </div>
//         {recommended && (
//           <div className="plan-recommended">
//             <img src={FeatureIcon} alt="" />
//             <span>Recommended</span>
//           </div>
//         )}
//       </div>
//     </>
//   )
// }

// export default memo(Card)
