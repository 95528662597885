import React, { useEffect } from "react"
import FooterComponent from "../../components/footer"
import ReactHelmet from "src/components/react-helmet"

const JamesStreet = () => {
  useEffect(() => {
    try {
      var script = document.createElement("script")
      script.src = "https://paperform.co/__embed"
      script.async = true
      document.body.appendChild(script)
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <>
      <ReactHelmet
        url="etobicoke-james-street"
        title={`Tolobi - Etobicoke James Street`}
        description="Tolobi - Rental Made Easy"
      />
      <div data-paperform-id="starlight-toronto-js90"></div>
      <FooterComponent />
    </>
  )
}
export default JamesStreet
