import styled from "styled-components"
import { width, space, color } from "styled-system"

import { Colors } from "../../theme"

interface Props {
  size?: string
  genre?: string
}

const mapSizeToProps = ({ size }: Props) => {
  switch (size) {
    default:
      return `
    `
  }
}

const mapGenreToProps = ({ genre }: Props) => {
  switch (genre) {
    default:
      return `
    `
  }
}

const TextArea = styled.textarea<Props>`
  flex-shrink: 0;
  width: 100%;
  height: 147px;
  border-radius: 8px;
  border: solid 1.4px rgba(51, 51, 51, 0.2);
  background-color: ${Colors.bodyBackground};
  padding: 24px;
  box-sizing: border-box;
  font-size: 18px;
  transition: 250ms linear;
  font-family: "Source Sans Pro", sans-serif;
  color: ${Colors.black};
  line-height: 23px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.1px;
  resize: none;
  ${mapSizeToProps};
  ${mapGenreToProps};
  ${space};
  ${width};
  ${color};
  &[disabled] {
    opacity: 0.4;
    cursor: no-drop;
  }
  &:focus {
    background-color: #fff;
    outline: none;
  }
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
  &::placeholder {
    letter-spacing: 0.1px;
    font-family: "Source Sans Pro", sans-serif;
    opacity: 0.3;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }
`

export default TextArea
