import * as React from "react"
import { CSSKeyframes } from "styled-components"
import { slideInDown, slideInUp, slideInLeft, slideInRight } from "react-animations"

import Box from "../box"
import ModalWrapper from "../modal-wrapper"
// import { Colors } from "../../theme"

const ANIMATIONS: CSSKeyframes = {
  BOTTOM_TO_TOP: { type: slideInUp },
  TOP_TO_BOTTOM: { type: slideInDown },
  LEFT_TO_RIGHT: { type: slideInRight },
  RIGHT_TO_LEFT: { type: slideInLeft }
}

interface Props {}

const MobileMenu: React.FC<Props> = ({ children }) => {
  return (
    <ModalWrapper animate={ANIMATIONS.BOTTOM_TO_TOP} duration="0.2s">
      <Box flexDirection="column">{children}</Box>
    </ModalWrapper>
  )
}

export default MobileMenu
