import React from "react"
import { FormControl } from "@material-ui/core"
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes"
import deleteIcon from "../../../components/FormComponents/select-multi/delete.png"
import "../../../components/FormComponents/select-multi/index.scss"
import "../../../components/FormComponents/styles.scss"
import { createObject } from "../create-listing/utils/createObject"

const MultiSelect = ({ selectUtilites, option, placeholder, onChange }) => {
  const handleChangeUtilities = e => {
    onChange(createObject(e, option))
  }

  const onDeleteSelected = value => {
    const filterdData = selectUtilites.filter(item => item.value !== value)
    onChange(createObject(filterdData, option))
  }

  return (
    <FormControl id="custom-form" fullWidth>
      <div className="">
        <ReactMultiSelectCheckboxes
          value={selectUtilites}
          options={option}
          placeholderButtonLabel={placeholder}
          onChange={handleChangeUtilities}
          defaultValue={"None"}
        />
      </div>
      <div className="selected-item">
        {selectUtilites &&
          selectUtilites?.map((item, index) => (
            <div key={index}>
              <div className="selected-item-value" key={item}>
                <div>{item.label}</div>
                <div className="selected-delete" onClick={() => onDeleteSelected(item.value)}>
                  <img loading="lazy" src={deleteIcon} alt="" />
                </div>
              </div>
            </div>
          ))}
      </div>
    </FormControl>
  )
}

export default MultiSelect
