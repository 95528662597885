import gql from "graphql-tag"

export const GET_RENTAL_LAW = gql`
  query rentalAdminLaw($WhereInput: HelpWhereInput) {
    rentalAdminLaw(WhereInput: $WhereInput) {
      id
      title
      count
      seen
      isPublished
      createdAt
      description
      province
      audience
      updatedAt
    }
  }
`

export const ANALAYTIC_RENTAL_LAW = gql`
  query rentalLawAnalytic {
    rentalLawAnalytic {
      totalPublished
      totalCount
      totalSeen
      totalAvgSeen
      totalPerDayPublished
      totalReadingAvg
    }
  }
`

export const SINGLE_RENTAL = gql`
  query singleRentalLaw($WhereInput: HelpWhereInput!) {
    singleRentalLaw(WhereInput: $WhereInput) {
      id
      title
      description
      province
      audience
    }
  }
`

export const GET_RENTAL_LAW_COLLECTION = gql`
  query rentalLawCollection($WhereInput: HelpWhereInput) {
    rentalLawCollection(WhereInput: $WhereInput) {
      id
      title
      description
      updatedAt
    }
  }
`

export const GET_RENTAL_LAWS = gql`
  query rentalLaws($WhereInput: HelpWhereInput) {
    rentalLaws(WhereInput: $WhereInput) {
      id
      title
      count
      seen
      isPublished
      createdAt
      description
      province
      updatedAt
      usertype
    }
  }
`
