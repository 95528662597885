import React, { useEffect } from "react"
import FooterComponent from "../../components/footer"

const PreViewingForm = () => {
  useEffect(() => {
    try {
      var script = document.createElement("script")
      script.src = "https://paperform.co/__embed"
      script.async = true
      document.body.appendChild(script)
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <>
      <div data-paperform-id="tolobi-pre-viewing"></div>
      <FooterComponent />
    </>
  )
}

export default PreViewingForm
