import {
  Dashboard,
  AdminBlog,
  AdminBuilding,
  Users,
  BoostListing,
  Articles,
  Plans,
  Features,
  AdminHelpCenter,
  RequestedListing,
  PendingListing,
  AdminInbox,
  AdminHelpGroup,
  CreateBlog,
  UserPermission,
  UserPermissionForm,
  PerListingMessage,
  DataGridTable,
  DataGridTableView,
  EMailConfiguration,
  InternalListing,
  RentalLawsListing,
  AdminRentalLawGroup,
  SearchHistory,
  UserSearchProfile,
  LeaseDetailsTable
} from "src/routes"

export const adminRoute = [
  {
    path: "/dashboards",
    component: Dashboard
  },
  {
    path: "/e-mail-configuration",
    component: EMailConfiguration
  },
  {
    path: "/rental-application-details",
    component: DataGridTable
  },
  {
    path: "/rental-application-table-view",
    component: DataGridTableView
  },
  {
    path: "/admin-blogs/create",
    component: CreateBlog
  },

  {
    path: "/adminInbox",
    component: AdminInbox
  },
  {
    path: "/adminInbox/:id/listing",
    component: PerListingMessage
  },
  {
    path: "/admin-blogs",
    component: AdminBlog
  },
  {
    path: "/listings",
    component: AdminBuilding
  },
  {
    path: "/admin-help-center",
    component: AdminHelpCenter
  },
  {
    path: "/admin-help-center/:id",
    component: AdminHelpGroup
  },
  {
    path: "/articles",
    component: Articles
  },

  {
    path: "/requestedListing",
    component: RequestedListing
  },

  {
    path: "/boostListing",
    component: BoostListing
  },
  {
    path: "/internal-properties",
    component: InternalListing
  },
  {
    path: "/search-history",
    component: SearchHistory
  },
  {
    path: "/search-history/:id/:userName",
    component: UserSearchProfile
  },
  {
    path: "/admin-rental-laws",
    component: RentalLawsListing
  },
  {
    path: "/admin-rental-laws/:id",
    component: AdminRentalLawGroup
  },

  {
    path: "/users",
    component: Users
  },

  {
    path: "/plans",
    component: Plans
  },
  {
    path: "/users/listing/:id",
    component: PendingListing
  },
  {
    path: "/features",
    component: Features
  },
  {
    path: "/admins",
    component: UserPermission
  },
  {
    path: "/admins/:id",
    component: UserPermissionForm
  },
  {
    path: "/lease-details-table",
    component: LeaseDetailsTable
  }
]
