import bgImage from "./images/hero-bg.jpg"
import logo from "./images/logo.svg"
import imgCard from "./images/img-card.png"
import clientImage from "./images/clientImage.jpeg"

import documentTime from "./icons/documentTime.svg"
import cost from "./icons/cost.svg"
import people from "./icons/people.svg"
import tick from "./icons/tick.svg"
import alert from "./icons/alert.svg"
import cross from "./icons/cross.svg"
import nonActiveTick from "./icons/nonActiveTick.svg"
import ActiveAlert from "./icons/ActiveAlert.svg"
import timelineIcon from "./icons/timelineIcon.svg"
import workStyle from "./icons/workStyle.svg"
import plusIcon from "./icons/plusIcon.svg"
import minusIcon from "./icons/minusIcon.svg"
import dollorBag from "./icons/dollorBag.svg"
import peopleIcon from "./icons/peopleIcon.svg"
import messageIcon from "./icons/messageIcon.svg"
import downArrow from "./icons/downArrow.svg"
import circle from "./icons/circle.svg"
import exclusivity from "./icons/exclusivity.svg"
import focusIcon from "./icons/focusIcon.svg"
import checklistIcon from "./icons/checklistIcon.svg"
import BottomArrow from "./icons/icon-bottom-arrow.svg"
import IconHouse from "./icons/icon-house.svg"
import IconKeyHand from "./icons/icon-key-hand.svg"
import IconDoc from "./icons/icon-doc.svg"
import IconTarget from "./icons/icon-target.svg"
import signTheLeaseIcon from "./icons/signTheLeaseIcon.svg"
import leadsManagementIcon from "./icons/leadsManagementIcon.svg"
import customizedLeaseDraftingIcon from "./icons/customizedLeaseDraftingIcon.svg"
import focusIcon2 from "./icons/focusIcon2.svg"
import FeatureIcon from "./icons/feature.png"
import bgWhoUseSection from "./images/bg-who-use-section.webp"
import bgSubscriptionSection from "./images/bg-subscription-section.webp"
import GettingStartIcon from "./icons/gettingStart.png"
import AdministrationIcon from "./icons/administration.png"
import CompliantIcon from "./icons/compliant.png"
import PolicyIcon from "./icons/policy.png"
import ServiceIocn from "./icons/service.png"
import TipsIocn from "./icons/tips.png"
import ViewIcon from "./icons/viewIcon.png"
import PremiumBedgeIcon from "./icons/premiumBedge.png"
import saved from "./icons/saved .png"
import bookmark from "./icons/bookmark.png"
import twitter from "./icons/twitter.png"
import linkedIn from "./icons/linkedIn.png"
import facebook from "./icons/facebook.png"
import dog from "./icons/dog.png"
import cat from "./icons/cat.png"
import none from "./icons/none.png"
import collar from "./icons/collar.svg"
import vector from "./icons/Vector.svg"
import helpCenter from "./images/helpcenter.png"
import rentalLaws from "./images/rentallaws.png"
import blogs from "./images/blogs.png"

const images = {
  PremiumBedgeIcon,
  ViewIcon,
  TipsIocn,
  ServiceIocn,
  PolicyIcon,
  CompliantIcon,
  AdministrationIcon,
  GettingStartIcon,
  bgImage,
  bgWhoUseSection,
  bgSubscriptionSection,
  logo,
  imgCard,
  clientImage,
  helpCenter,
  blogs,
  rentalLaws
}

const icons = {
  cost,
  people,
  tick,
  cross,
  alert,
  vector,
  nonActiveTick,
  ActiveAlert,
  timelineIcon,
  workStyle,
  plusIcon,
  minusIcon,
  dollorBag,
  FeatureIcon,
  peopleIcon,
  messageIcon,
  downArrow,
  circle,
  exclusivity,
  focusIcon,
  documentTime,
  checklistIcon,
  BottomArrow,
  IconDoc,
  IconHouse,
  IconKeyHand,
  IconTarget,
  signTheLeaseIcon,
  leadsManagementIcon,
  customizedLeaseDraftingIcon,
  focusIcon2,
  saved,
  bookmark,
  facebook,
  linkedIn,
  twitter,
  dog,
  cat,
  none,
  collar
}

export { images, icons }
