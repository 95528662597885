import * as React from "react"
import { Switch, Route, withRouter, Redirect } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import subscribe from "unstated-subscribe-hoc"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"
import { compose, withApollo } from "react-apollo"
import AuthenticationState from "src/hoc/authentication/authentication.state"

import { removeStoredAuthState } from "src/utils/auth-utils"
import FETCH_CURRENT_USER from "src/hoc/authentication/fetch-user"
import theme from "src/theme"
import AppErrorBoundary from "src/components/app-error-boundary"
import NavigationBar from "src/components/navigation-bar"
import { History } from "history"
import RentalApplication from "src/containers/paperforms/rental-application"
import RentalApplicationClientForm from "src/containers/paperforms/rental-application-client-form"
import LeaseDetailsForm from "src/containers/paperforms/lease-details"
import ClientFeedbackForm from "src/containers/paperforms/client-feedback"
import JamesStreet from "src/containers/paperforms/etobicoke-james-st"
import StClairAve from "src/containers/paperforms/toronto-st-clair-ave"
import EmbeddedPrivacyPolicy from "src/containers/paperforms/privacy-policy"
import RegalRoad from "src/containers/paperforms/regal-road"
import ListOnRealtor from "src/containers/paperforms/list-on-realtor"
import LearnMore from "src/containers/paperforms/learn-more"
import ClientFormConcierge from "src/containers/paperforms/client-form-concierge"
import McgregorParkTownhomes from "src/containers/paperforms/mcgregor-park-townhomes"
import PreViewingForm from "src/containers/paperforms/pre-viewing"
import RentalApplicationInbuilt from "./dashboards/screens/rental-application"
import {
  Home,
  Blogs,
  ConfirmPassword,
  Search,
  HelpCenter,
  HelpColllection,
  Leases,
  Help,
  SingleHelpArticle,
  SingleListing,
  SignupModal,
  PreViewingFormBeta,
  FilterListing,
  Booking,
  TermPolicy,
  TermService,
  Tenant,
  Building,
  Units,
  UserProfile,
  ReportRedirect,
  PremiumRedirect,
  LandlordPremiumRedirect,
  Message,
  ServicesPage,
  BlogsDetail,
  RssFeed,
  KnowledgeCenter,
  RentalLaws,
  RentalEnquiry,
  RentalCollection,
  RentalCollectionSingle,
  SingleRentalLaw,
  LeaseForm
} from "src/routes"
import PrivateRoute from "src/private-route"
import { adminRoute } from "./routes/admin-routes"
import RentalApplicationDetails from "./dashboards/screens/rental-application-table"
import ListWithUs from "./components/list-with-us/index"

interface Props {
  authState: AuthenticationState
  client: ApolloClient<NormalizedCacheObject>
  history: History
  location: any
}

interface State {
  isHydratingUser: boolean
}

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
)

class App extends React.Component<Props, State> {
  state: State = {
    isHydratingUser: false
  }

  async componentWillMount() {
    const { authState, client } = this.props
    const userId = window.localStorage.getItem("user_id")
    if (userId) {
      this.setState({
        isHydratingUser: true
      })
      try {
        const {
          data: { currentUser }
        }: any = await client.query({
          query: FETCH_CURRENT_USER,
          variables: {
            data: { isDashboard: false }
          }
        })
        if (currentUser.userType === "UNVERIFIED") {
          await authState.setFirstLogin(true)
        }
        await authState.setUser(currentUser)
        this.setState({
          isHydratingUser: false
        })
      } catch (error) {
        removeStoredAuthState()
        this.setState({
          isHydratingUser: false
        })
      }
    }
  }

  render() {
    const { isHydratingUser } = this.state
    const { authState } = this.props
    const user = authState.state.user
    if (isHydratingUser) {
      return null
    }

    // const intercomAppId = process.env.REACT_APP_INTERCOM_APPID || ""
    const {
      location: { pathname }
    } = this.props
    return (
      <main>
        <AppErrorBoundary>
          <ThemeProvider theme={theme}>
            <React.Suspense fallback={<Loader />}>
              {pathname == "/feed" ? <></> : <NavigationBar />}
              <Switch>
                <Route exact path="/blogs">
                  <Blogs {...this.props} />
                </Route>

                <Route exact path="/knowledge-center">
                  <KnowledgeCenter {...this.props} />
                </Route>
                <Route exact path="/rental-laws">
                  <RentalLaws {...this.props} />
                </Route>

                <Route exact path="/rental-enquiry/:userType">
                  <RentalEnquiry {...this.props} />
                </Route>

                <Route exact path="/rental-enquiry/:userType/:located">
                  <RentalCollection {...this.props} />
                </Route>

                <Route exact path="/rental-enquiry/:userType/:located/:id">
                  <RentalCollectionSingle {...this.props} />
                </Route>

                <Route
                  exact
                  path="/rental-enquiry/:userType/:located/:id/:id"
                  component={() => <SingleRentalLaw {...this.props} />}
                ></Route>

                <Route exact path="/feed">
                  <RssFeed {...this.props} />
                </Route>

                <Route exact path="/blog">
                  <Redirect to="/blogs" />
                </Route>

                <Route exact path="/request-for-viewing">
                  <FilterListing {...this.props} />
                </Route>
                <Route exact path="/blog/:id/:title">
                  <BlogsDetail {...this.props} />
                </Route>

                <Route exact path="/search">
                  <Search {...this.props} />
                </Route>
                <Route exact path="/building">
                  <Building {...this.props} />
                </Route>

                <Route exact path="/unit/:id">
                  <Units {...this.props} />
                </Route>

                <Route exact path="/leases" component={Leases} />
                <Route exact path="/viewing-form-beta">
                  <PreViewingFormBeta {...this.props} />
                </Route>
                <Route
                  exact
                  path="/help-center"
                  component={() => <HelpCenter {...this.props} />}
                ></Route>

                <Route
                  exact
                  path="/help-center/collection/:id"
                  component={() => <HelpColllection {...this.props} />}
                ></Route>

                <Route
                  exact
                  path="/help-center/collection/article/:id"
                  component={() => <SingleHelpArticle {...this.props} />}
                ></Route>

                <Route exact path="/viewing-form">
                  <PreViewingForm />
                </Route>

                <Route exact path="/message">
                  <Message {...this.props} />
                </Route>
                {/* <Route exact path="/rental-application-details">
                  <RentalApplicationDetails {...this.props} />
                </Route> */}

                <Route exact path="/profile">
                  <UserProfile {...this.props} />
                </Route>
                <Route exact path="/confirm-password">
                  <ConfirmPassword />
                </Route>

                <Route exact path="/contact-us" component={Help} />
                <Route exact path="/list-with-us">
                  <ListWithUs {...this.props} />
                </Route>
                <Route exact path="/rental-application" component={RentalApplication} />
                <Route exact path="/rental-application-form" component={RentalApplicationInbuilt} />
                <Route exact path="/lease-details-form" component={LeaseForm} />
                <Route exact path="/lease-details" component={LeaseDetailsForm} />
                <Route exact path="/client-feedback" component={ClientFeedbackForm} />
                <Route exact path="/client-form" component={RentalApplicationClientForm} />
                <Route exact path="/etobicoke-james-st-90-92" component={JamesStreet} />
                <Route exact path="/toronto-st-clair-ave" component={StClairAve} />
                <Route exact path="/privacy-policy" component={EmbeddedPrivacyPolicy} />
                <Route exact path="/toronto-regal-road-2" component={RegalRoad} />
                <Route exact path="/list-on-realtor" component={ListOnRealtor} />
                <Route exact path="/learn-more" component={LearnMore} />
                <Route exact path="/client-form-concierge" component={ClientFormConcierge} />
                <Route exact path="/list-with-concierge" component={ClientFormConcierge} />
                <Route exact path="/mcgregor-park-townhomes" component={McgregorParkTownhomes} />

                <Route exact path="/signup-modal">
                  <SignupModal {...this.props} />
                </Route>
                <Route exact path="/services">
                  <ServicesPage {...this.props} />{" "}
                </Route>
                <Route exact path="/listing/:id" component={SingleListing} />
                <Route exact path="/listing/:id/contact" component={Booking} />
                <Route exact path="/tenant/:id" component={Tenant} />
                <Route exact path="/redirect/premium" component={PremiumRedirect} />
                <Route exact path="/redirect/landlord/premium" component={LandlordPremiumRedirect} />
                <Route exact path="/redirect/report" component={ReportRedirect} />
                <Route exact path="/privacy" component={TermPolicy} />
                <Route exact path="/terms" component={TermService} />

                {/*start admin routes*/}
                {/* <PrivateRoute user={user}> */}
                {adminRoute.map((route, index) => {
                  return (
                    <Route exact path={route.path} key={index}>
                      <PrivateRoute user={user}>
                        <route.component {...this.props} />
                      </PrivateRoute>
                    </Route>
                  )
                })}
                {/* </PrivateRoute> */}
                {/* end admin routes */}

                <Route exact path="/">
                  <Home {...this.props} />
                </Route>

                <Route exact path="*" component={() => <Redirect to="/" />} />
              </Switch>
            </React.Suspense>
          </ThemeProvider>
        </AppErrorBoundary>
      </main>
    )
  }
}

export default compose(withApollo, withRouter)(subscribe(App, { authState: AuthenticationState }))
