import React from "react"

type Props = {
  isapproved?: boolean
  showModal: () => void
  isHidden: boolean
  activeListing: (value: boolean) => void
}

const LandlordActiveStatus = (props: Props) => {
  const { activeListing, isHidden, showModal, isapproved } = props
  return (
    <>
      {isapproved ? (
        <>
          {!isHidden ? (
            <span className="available-true" onClick={showModal}>
              Active
            </span>
          ) : (
            <span className="available-false2" onClick={() => activeListing(false)}>
              Inactive
            </span>
          )}
        </>
      ) : (
        <span className="available-false2" style={{ marginRight: 10 }}>
          Pending Approval
        </span>
      )}
    </>
  )
}

export default LandlordActiveStatus
