import gql from "graphql-tag"

export default gql`
  mutation authenticate($idToken: String!) {
    authenticate(idToken: $idToken) {
      id
      auth0id
      firstName
      lastName
      email
      phone
      userType
      avatarUrl
      city
      industry
      bio
      locale
      gender
      budget
      moveIn
      roommate
    }
  }
`
