import * as ANTD from 'antd';
import styles from './styles.module.scss';

const ErrorMSG = "Oops! Something went wrong. Try after sometime";
const SuccessMSG = "Submitted successfully";

export const ErrorModal = (props?: ANTD.ModalFuncProps) => {
    const conditionalProps = props || {}
    return (
        ANTD.Modal.error({
            title: 'Failed',
            closable: true,
            centered: true,
            className: styles.modal__container,
            ...(props || {}),
            content: conditionalProps.content || ErrorMSG,
        }))
}

export const SuccessModal = (props?: ANTD.ModalFuncProps) => {
    const conditionalProps = props || {}
    return (
        ANTD.Modal.success({
            title: 'Success',
            closable: true,
            centered: true,
            className: styles.modal__container,
            ...(props || {}),
            content: conditionalProps.content || SuccessMSG,
        })
    )
}