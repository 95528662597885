import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Modal, Space } from "antd"
import "antd/lib/modal/style/css"
import "./style.scss"
import React, { ReactNode } from "react"
const { confirm } = Modal

type Props = {
  title: string
  content?: string
  onOk: () => void
  okText?: string
  cancelText?: string
  btnText?: string
  children?: ReactNode
}

const ConfirmModal = ({
  title = "Do you want to boost this listing?",
  content,
  onOk,
  okText = "Confirm",
  cancelText = "Cancel",
  btnText = "Boost",
  children
}: Props) => {
  const showConfirm = () => {
    confirm({
      title: title,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: content,
      okText: okText,
      okType: "danger",
      cancelText: cancelText,
      onOk: onOk,
      onCancel() {
        console.log("Cancel")
      }
    })
  }

  return (
    <Space wrap id="confirm-main">
      <div className="listing-boost" onClick={showConfirm}>
        {children ? children : <span className="view-building edit-units-btn">{btnText}</span>}
      </div>
    </Space>
  )
}

export default ConfirmModal
