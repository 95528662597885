import * as React from "react"
import styled from "styled-components"
import withSizes from "react-sizes"
import { Link } from "react-router-dom"
import theme, { Colors } from "../../theme"
import Box from "../box"
import Column from "../column/column.component"
import Text from "../text"

const LinkSection = styled(Box as any)`
  flex-direction: row;
  justify-content: space-evenly;
  @media (max-width: ${theme.breakpoints[0]}) {
    height: 462px;
    flex-direction: column;
  }
`

const StyledA = styled.a`
  text-decoration: none;
  color: ${Colors.lightGrey};
`

const LinkGroup = styled(Column as any)`
  margin-top: 32px;
  margin-right: 60px;
  align-items: baseline;
  & > * {
    font-weight: 600;
  }
`

interface Props {
  isMobile: boolean
}

const LinksSectionComponent: React.SFC<Props> = props => {
  return (
    <LinkSection>
      <LinkGroup>
        <Text color={Colors.textLightGrey}>Areas</Text>
        <Link to="/search?city=Toronto&price_gte=0&price_lte=4500&studio=true&one_bedroom=true&two_bedrooms=true&three_bedrooms=true&four_or_more_bedrooms=true">
          <Text color={Colors.lightGrey}>Toronto</Text>
        </Link>
        <Link to="/search?city=Peel&price_gte=0&price_lte=4500&studio=true&one_bedroom=true&two_bedrooms=true&three_bedrooms=true&four_or_more_bedrooms=true">
          <Text color={Colors.lightGrey}>Peel</Text>
        </Link>
        <Link to="/search?city=York&price_gte=0&price_lte=4500&studio=true&one_bedroom=true&two_bedrooms=true&three_bedrooms=true&four_or_more_bedrooms=true">
          <Text color={Colors.lightGrey}>York</Text>
        </Link>
        <Link to="/search?city=Hamilton%20%26%20Halton&price_gte=0&price_lte=4500&studio=true&one_bedroom=true&two_bedrooms=true&three_bedrooms=true&four_or_more_bedrooms=true">
          <Text color={Colors.lightGrey}>Hamilton & Halton</Text>
        </Link>
        <Link to="/search?city=Durham&price_gte=0&price_lte=4500&studio=true&one_bedroom=true&two_bedrooms=true&three_bedrooms=true&four_or_more_bedrooms=true">
          <Text color={Colors.lightGrey}>Durham</Text>
        </Link>
        <Link to="/search?city=Waterloo&price_gte=0&price_lte=4500&studio=true&one_bedroom=true&two_bedrooms=true&three_bedrooms=true&four_or_more_bedrooms=true">
          <Text color={Colors.lightGrey}>Waterloo</Text>
        </Link>
      </LinkGroup>

      <LinkGroup>
        <Text color={Colors.textLightGrey}>Our Company</Text>
        <StyledA
          href="https://intercom.help/tolobi/en/collections/1647250-getting-started-with-tolobi"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text color={Colors.lightGrey}>What is Tolobi?</Text>
        </StyledA>

        <Link to="/services" target="_blank">
          <Text color={Colors.lightGrey}>Rental Assistance</Text>
        </Link>

        <StyledA href="/blogs" target="_blank" rel="noopener noreferrer">
          <Text color={Colors.lightGrey}>Blog</Text>
        </StyledA>

        <Link to="/rental-application-form">
          <Text color={Colors.lightGrey}>Submit a Rental Application</Text>
        </Link>

        <Link to="/help-center" target="_blank">
          <Text color={Colors.lightGrey}>Help Center</Text>
        </Link>

        <Link to="/contact-us" target="_blank">
          <Text color={Colors.lightGrey}>Contact Us</Text>
        </Link>
      </LinkGroup>
    </LinkSection>
  )
}

const mapSizesToProps = ({ width }: any) => ({
  isMobile: width < 901
})

export default withSizes(mapSizesToProps)(LinksSectionComponent)
