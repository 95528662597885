import React from "react"
import styled from "styled-components"
import { width, space, color } from "styled-system"

import { Colors } from "../../theme"

const mapSizeToProps = ({ size }: Props) => {
  switch (size) {
    case "small":
      return `
        font-size: 13px;
        padding: 7px 15px;
        font-weight: 600;
      `
    case "large":
      return `
        font-size: 16px;
        padding: 10px 21px;
        font-weight: 600;
      `
    case "input-alike":
      return `
        font-size: 21.9px;
        border-radius: 5.5px;
        padding: 12.6px 0px 11.6px 14.6px;
        text-align: left;
        font-weight: 600;
        line-height: 28px;
      `
    default:
      return `
      font-size: 16px;
      padding: 10px 21px;
      font-weight: 600;
    `
  }
}

const mapGenreToProps = ({ genre }: Props) => {
  switch (genre) {
    case "CTA":
      return `
        background: ${Colors.primary};
        color: white;
        border: none;
        &:hover {
          background: ${Colors.primaryHover}
        }
        &:disabled {
          opacity: 0.5;
          background: ${Colors.darkGrey};
        }
      `
    case "user-profile":
      return `
        background: ${Colors.primary};
        color: white;
        border: none;
        padding:10px 30px;
        letter-spacing: 0.5px;
        border-radius:6px;
        &:disabled {
          opacity: 0.5;
          padding:8px 30px !important;
          background: ${Colors.darkGrey};
        }
      `
    case "secondary":
      return `
        background: none;
        border: 2px solid ${Colors.primary};
        color: ${Colors.primary}
        &:hover {
          border-color: hsl(120, 100%, 37%);
        }
      `
    case "explore-here":
      return `
        background: none;
        border: 2px solid ${Colors.primary};
        color: ${Colors.black}
        &:hover {
          border-color: hsl(120, 100%, 37%);
        }
      `
    case "secondary-text":
      return `
      background-color: transparent;
      border:none;
      color: ${Colors.lightGrey};
      &:hover {
        border-color: hsl(0, 0%, 70%);
        color: ${Colors.darkGrey};
      }
      `
    case "add-new":
      return `
      width: "100%"
        &:hover {
          background:none
        }`
    case "tertiary-text":
      return `
      background: ${Colors.white};
      border: 1px solid ${Colors.fadedGrey};
      color: ${Colors.black};
      font-weight: 600;
      `

    case "tenant-scanning":
      return `
      background: ${Colors.white};
      border: 1px solid ${Colors.fadedGrey};
      color: ${Colors.black};
      font-weight: 600;
      padding:7px !important;
      @media (max-width:340px){
        margin-bottom:10px
        width:100%
        margin-left:12px
      }

      `
    case "primary-invert":
      return `
        color: ${Colors.primary};
        border: none;
        background: none;
      `
    case "text":
      return `
        color: ${Colors.black};
        border: none;
        background: none;
        font-weight: 400;        
        &:hover {
          text-decoration:underline;
          cursor:pointer;
        }
      `
    case "link":
      return `
        color: #0051a8;
        font-size: 18px;
        font-weight: 600;
        padding:0;
        border:0;
        margin:0;
        box-shadow:0;
        margin-left:auto;
        background: transparent;
        &:hover {
          background: transparent;
          text-decoration:underline;
          cursor:pointer;
        }
      `
    case "invert":
      return `
        color: #ffffff;
        border: 2px solid #ffffff;
        background: ${Colors.primary};
        &:hover {
          color: ${Colors.primary};
          border: 2px solid #ffffff;
          background: #ffffff;
        }
      `
    case "link-button":
      return `
        color:#0051a8;
        font-size:18px;
        font-weight:600;
        text-decoration:underline;
        border:none;
        padding:0;
        margin:0;
        background-color:transparent;
        margin-top:4px;
        margin-bottom:0;
        &:first-of-type, :nth-of-type(1) {
          margin-bottom:0;
        }
    `
    case "link-":
      return `
          color:#0051a8;
          font-size:18px;
          font-weight:600;
          text-decoration:underline;
          border:none;
          padding:0;
          margin:0;
          background-color:transparent;
          margin-top:4px;
          margin-bottom:0;
          &:first-of-type, :nth-of-type(1) {
            margin-bottom:0;
          }
        `
    case "CTA-white":
      return `
        color: ${Colors.primary};
        background: ${Colors.white};
        border-color:transparent;
      `
    case "transparent":
      return `
        background-color:transparent;
        color: ${Colors.black};
        font-weight: 600;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      `
    default:
      return `
      background: ${Colors.primary};
      color: white;
      border: none;
      &:hover {
        background: ${Colors.primaryHover}
      }
    `
  }
}

interface Props {
  size?: string
  genre?: string
  icon?: string
  link?: boolean
  href?: string
  target?: string
  type?: "submit" | "button"
  children: React.ReactNode
  className?: string
  disabled?: boolean
  style?: React.CSSProperties
  onClick?: any
  mb?: any
  mt?: any
  ml?: any
  mr?: any
  width?: any
  padding?: any
}

const mapIconToProps = ({ icon }: Props) => {
  if (icon) {
    return `
        &:before {
          content: "";
          mask: url(${icon});
          width: 20px;
          height: 100%;
          display: block;
          mask-size: 20px;
          position: absolute;
          top: 0;
          left: 0;
          background: ${Colors.lightGrey};
          mask-position: center;
          mask-repeat: no-repeat;
        }
      `
  }
  return
}

const ButtonComponent = ({ children, ...props }: Props) =>
  props.link ? (
    <a
      href={props.href}
      className={props.className}
      {...props}
      style={{
        boxSizing: "border-box",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {children}
    </a>
  ) : (
    <button className={props.className} {...props}>
      {children}
    </button>
  )

const Button = styled(ButtonComponent)`
  width: 100%;
  font-weight: 500;
  border-radius: 4px;
  display: block;
  cursor: pointer;
  position: relative;
  transition: 250ms linear;
  white-space: nowrap;
  ${mapSizeToProps};
  ${mapGenreToProps};
  ${space};
  ${width};
  ${color};
  overflow-x: hidden;
  text-overflow: ellipsis;
  &.disabled,
  &[disabled] {
    border: 2px solid ${Colors.darkGrey};
    color: ${Colors.darkGrey};
    opacity: 0.5;
    background: ${Colors.lightGrey};
    cursor: no-drop;
  }
  ${mapIconToProps};
`

export default Button
