import React from "react"
import { timeStamp } from "src/dashboards/components/stamptime-converter"
import { RenderList } from "src/dashboards/screens/users/user/dateCell"

export const CreateDate = ({ createdAt }) => {
  const creatAtDate = timeStamp(createdAt && createdAt, "MM-DD-YYYY hh:mm")
  return <RenderList marginLeft={-6}>{creatAtDate}</RenderList>
}

export const UpdatedDate = ({ updatedAt }) => {
  const updatedAtDate = timeStamp(updatedAt && updatedAt, "MM-DD-YYYY hh:mm")
  return <RenderList marginLeft={-6}>{updatedAtDate}</RenderList>
}

export const Seen = ({ seen }) => {
  return <RenderList marginLeft={8}>{seen}</RenderList>
}

export const City = ({ city }) => {
  return <RenderList marginLeft={8}>{city}</RenderList>
}
