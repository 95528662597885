import { REORDER_CONTENT, UPDATE_FEATURE_ORDER } from "src/services/graphql/features/mutation"

export const updateFeatureOrder = async (id: String, order: Number, client) => {
  try {
    return await client.mutate({
      mutation: UPDATE_FEATURE_ORDER,
      variables: {
        data: {
          id,
          order
        }
      }
    })
  } catch (err) {
    return err
  }
}

export const reorderContent = async (req, client) => {
  try {
    return await client.mutate({
      mutation: REORDER_CONTENT,
      variables: {
        where: { orders: req }
      }
    })
  } catch (err) {
    console.log("Error", err)
    return err
  }
}
