import moment from "moment"

function parseStep3({ units }) {  
  let unit = units[0]  
  let updatedAtDate=moment.unix(unit?.startDateAvailability/1000).format("YYYY MM DD HH:mm:ss") 

  return {
    title: unit?.title,
    den: unit?.den,
    unitSize: unit?.unitSize,
    monthlyPrice: unit?.monthlyPrice,
    bathrooms: unit?.bathrooms,
    bedrooms: unit?.bedrooms,
    deposit: unit?.deposit,
    available:unit?.available,
    startDateAvailability:updatedAtDate,
    furnished: unit?.furnished,
  }
}

export { parseStep3 }
