import React from "react"
import { FormControl } from "@material-ui/core"
import { PropType } from "./types"
import "../styles.scss"
import { InputComponent } from "src/dashboards/components/form-container-field/form-container-field"
import Text from "src/components/text"

const SignInputField = ({
  icon,
  form: { touched, errors },
  field,
  labelTxt,
  disabled = false,
  styleInput = {},
  ...rest
}: PropType) => {
  return (
    <FormControl id="singup-profile-container" style={{ marginBottom: 15 }} fullWidth>
      <Text genre="labelText">{labelTxt}</Text>
      <InputComponent
        id={field.name}
        height={40}
        padding="0 0 0 10px"
        disabled={disabled}
        {...field}
        {...rest}
        {...styleInput}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="error-text">
          {rest.create_err_msg ? rest.create_err_msg(field.value) : errors[field.name]}
        </div>
      )}
    </FormControl>
  )
}

export default SignInputField
