import {
  CREATE_HELP_CENTER,
  DELETE_BLOG,
  BLOG_LIST,
  SEARCH_BLOG_LIST,
  UPLOAD_IMAGE,
  CREATE_BLOG,
  TAGS,
  UPDATE_BLOG,
  DELETE_TAG,
  SEARCH_TAG,
  ANALATIC_BLOG_VIEW,
  UPDATE_TAG,
  UPDATE_BLOG_AUTHOR
} from "src/services/graphql"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"

export const createHelpCenter = async (client: ApolloClient<NormalizedCacheObject>, data) => {
  try {
    return await client.mutate({
      mutation: CREATE_HELP_CENTER,
      variables: {
        WhereInput: data
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const createBlog = async (client: ApolloClient<NormalizedCacheObject>, data) => {
  try {
    return await client.mutate({
      mutation: CREATE_BLOG,
      variables: {
        where: {
          ...data
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const updateBlog = async (client: ApolloClient<NormalizedCacheObject>, data) => {
  try {
    return await client.mutate({
      mutation: UPDATE_BLOG,
      variables: {
        where: {
          ...data
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const getAnalyticBlogView = async (client: ApolloClient<NormalizedCacheObject>) => {
  try {
    return await client.query({
      query: ANALATIC_BLOG_VIEW
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const fetchSearchBlogList = async (
  client: ApolloClient<NormalizedCacheObject>,
  title: string
) => {
  try {
    return await client.query({
      query: SEARCH_BLOG_LIST,
      variables: {
        where: {
          title
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const deleteBlog = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
  isPublished: boolean
) => {
  try {
    return await client.mutate({
      mutation: DELETE_BLOG,
      variables: {
        where: { id, isPublished }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const uploadImage = async (
  client: ApolloClient<NormalizedCacheObject>,
  thumbnailUrl: string
) => {
  try {
    return await client.mutate({
      mutation: UPLOAD_IMAGE,
      variables: {
        where: {
          thumbnailUrl
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const fetchBlogList = async (
  client: ApolloClient<NormalizedCacheObject>,
  skipNo,
  isPublished,
  take,
  sortField, 
  sortOrder
) => {
  try {
    return await client.query({
      query: BLOG_LIST,
      fetchPolicy: "network-only",
      variables: {
        where: {
          skip: skipNo,
          take,
          isPublished,
          sortField,
          sortOrder
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const fetchTags = async (client: ApolloClient<NormalizedCacheObject>) => {
  try {
    return await client.query({
      query: TAGS
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const searchTags = async (client: ApolloClient<NormalizedCacheObject>, name) => {
  try {
    return await client.query({
      query: SEARCH_TAG,
      variables: {
        where: {
          name
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const deleteTag = async (client: ApolloClient<NormalizedCacheObject>, id) => {
  try {
    return await client.mutate({
      mutation: DELETE_TAG,
      variables: {
        where: {
          id
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const updateTag = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: number,
  tagValue: string,
  isDashboard: boolean = false
) => {
  try {
    return await client.mutate({
      mutation: UPDATE_TAG,
      variables: {
        where: {
          id,
          isDashboard,
          title: tagValue
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const updateBlogAuthor = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: number,
  userId: string,
) => {
  try {
    return await client.mutate({
      mutation: UPDATE_BLOG_AUTHOR,
      variables: {
        where: {
          id,
          userId
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}


