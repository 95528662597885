import * as React from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import styled from "styled-components"
import { Colors } from "src/theme"
type Props = WithTranslation & {
  t?: any
}

const ErrorWrapper = styled.div<any>`
    padding: 30px
    display: flex
    justify-content: center
    align-items: center
    height: 100vh
    flex-direction:column
`

const Text = styled.span<any>`
  font-size: 25px;
  font-weight: 600;
  margin: 10px 0;
  @media (max-width: 500px) {
    font-size: 18px;
  }
`
const Button = styled.button<any>`
  font-size: 24px;
  background: ${Colors.primary};
  border: none;
  color: ${Colors.white};
  padding: 6px 20px;
  width: 130px;
`

const ImageSection = styled.img<any>`
  height: auto;
  width: auto;
  @media (max-width: 500px) {
    width: 100%;
  }
`
class ErrorHandler extends React.Component<Props> {
  state = { error: null }
  componentDidCatch(error: any) {
    console.log("error", error)
    this.setState({ error })
  }
  onRefresh = () => {
    this.setState({ error: null })
    window.location.reload()
  }

  render() {
    const { t } = this.props
    if (this.state.error) {
      // Render fallback UI
      return (
        <ErrorWrapper>
          <ImageSection
            src="https://cdni.iconscout.com/illustration/premium/thumb/website-maintenance-4615795-3822063.png"
            alt=""
          />
          <Text>
            <p>{t(" OOPS! SOMETHING WENT WRONG!")}</p>
          </Text>
          <Button onClick={this.onRefresh}>{t("Refresh")}</Button>
        </ErrorWrapper>
      )
    }
    // When there's not an error, render the children untouched
    return this.props.children
  }
}

export default withTranslation()(ErrorHandler)
