import gql from "graphql-tag"

export default gql`
  query fetchCurrentUser($data: Data) {
    currentUser(data: $data) {
      id
      auth0id
      firstName
      lastName
      email
      userType
      avatarUrl
      permissions {
        id
        permissions {
          name
        }
        permissiontypes {
          name
        }
      }
    }
  }
`
