import { DELETE_PICTURE } from "../../../screens/landlord-access-building/graphql/mutation"

export const getDeletedImages = async (allImages, savedImages, client) => {
  function comparer(otherArray) {
    return function(current) {
      return (
        otherArray?.filter(function(other) {
          return other.id === current.id && other.url === current.url
        }).length === 0
      )
    }
  }
  const onlyInA = allImages?.filter(await comparer(savedImages))
  const onlyInB = savedImages?.filter(await comparer(allImages))
  const result = onlyInA?.concat(onlyInB)
  
  if(result&&result.length>0){
  result?.map(async imgId => {
    try {
      await client.mutate({
        mutation: DELETE_PICTURE,
        variables: {
          where: {
            id: imgId.id
          }
        }
      })
    } catch (err) {
      console.log("error", err)
    }
  })
}
}

export const getDeletedUnitImages = async (allImages, savedImages, client) => {  
  function comparer(otherArray) {
    return function(current) {
      return (
        otherArray?.filter(function(other) {
          return other.id === current.id && other.url === current.url
        }).length === 0
      )
    }
  }
  const onlyInA = allImages?.filter(await comparer(savedImages))
  const onlyInB = savedImages?.filter(await comparer(allImages))
  const result = onlyInA?.concat(onlyInB)  
  result?.map(async imgId => {
    try {
      await client.mutate({
        mutation: DELETE_PICTURE,
        variables: {
          where: {
            id: imgId.id
          }
        }
      })
    } catch (err) {
      // console.log("error", err)
    }
  })
}
