import React from "react";
import { FormControl } from "@material-ui/core";
import { PropType } from "./types";
import "../styles.scss";
import Text from "../../text"

const FormField = ({
  icon,
  form: { touched, errors },
  field,
  labelTxt,
  styleInput = {},
  ...rest
}: PropType) => {

  return (
    <FormControl id="custom-form" fullWidth style={{ marginBottom: 15 }}>
      {labelTxt && (
        <Text genre="labelText" mb={0}>
          {labelTxt}
        </Text>
      )}
      <div className="pricing-input">
        <span>$</span>
        <input
          style={styleInput}
          margin="normal"
          className="primary-pricing"
          autoComplete="off"
          inputProps={{ min: "0" }}
          {...field}
          {...rest}
        />
      </div>
      {errors[field.name] && touched[field.name] && (
        <div className="error-text">
          {
            rest.create_err_msg
              ? rest.create_err_msg(field.value)
              : errors[field.name]}
        </div>
      )}

    </FormControl>
  );
};

export default FormField;