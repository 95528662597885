import React from "react"
import { Link } from "react-router-dom"
import AuthenticationState from "src/hoc/authentication/authentication.state"
// import MessageNotificationWrapper from "../message-notification-wrapper"
import { UserType } from "src/generated/graphql"
import MobileMenu from "../mobile-menu"
import { MenuLink, adminLinks } from "./menu-link"
import { logoutHandler } from "src/hoc/authentication/lock"
interface MobileLoggedInProps {
  authState: AuthenticationState
  handleMenuToggle: () => void
  handleSearchClick: () => void
}

export const MobileLoggedIn: React.FC<MobileLoggedInProps> = ({
  authState,
  handleMenuToggle,
  handleSearchClick
}) => {
  return (
    <MobileMenu>
      <MenuLink onClick={handleSearchClick}>Explore here</MenuLink>
      <Link onClick={handleMenuToggle} to="/profile">
        <MenuLink>Profile</MenuLink>
      </Link>
      <Link onClick={handleMenuToggle} to="/knowledge-center">
        <MenuLink>Knowledge Center</MenuLink>
      </Link>
      {authState.state.user &&
        authState.state.user.userType !== UserType.Admin &&
        authState.state.user.userType !== UserType.SubAdmin && (
          <Link onClick={handleMenuToggle} to="/message" style={{ position: "relative" }}>
            <MenuLink>Messages</MenuLink>
          </Link>
        )}
      {authState.state.user &&
        (authState.state.user.userType === UserType.Admin ||
          authState.state.user.userType === UserType.SubAdmin) &&
        adminLinks.map((list, index) => (
          <Link onClick={handleMenuToggle} to={list.link} key={index}>
            <MenuLink>{list.name}</MenuLink>
          </Link>
        ))}
      {authState.state.user && authState.state.user.userType === UserType.Landlord && (
        <Link onClick={handleMenuToggle} to="/building">
          <MenuLink>Dashboard</MenuLink>
          {/* (Beta) */}
        </Link>
      )}
      <Link onClick={handleMenuToggle} to="/contact-us">
        <MenuLink>Help</MenuLink>
      </Link>
      <MenuLink onClick={() => logoutHandler()}>Logout</MenuLink>
      <MenuLink>&nbsp;</MenuLink>
      <MenuLink>&nbsp;</MenuLink>
    </MobileMenu>
  )
}
