import { SelectObjType } from "../../../screens/landlord-access-building/types/index"

function createObject(
  selected: SelectObjType[],
  objects: SelectObjType[]
): { [x: string]: boolean } {
  const selectedObj: { [x: string]: boolean } = {}
  for (const object of objects) {
    const { value } = object
    selectedObj[value[0]?.toLowerCase() + value.substring(1)] = false
  }
  if (selected) {
    selected &&
      selected?.forEach(({ value }: { value: string; label: string }) => {
        selectedObj[value[0]?.toLowerCase() + value.substring(1)] = true
      })
  } else {
    objects?.forEach(({ value }: { value: string; label: string }) => {
      selectedObj[value[0].toLowerCase() + value.substring(1)] = false
    })
  }
  return selectedObj
}

export { createObject }
