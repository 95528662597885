import gql from "graphql-tag"

export const CREATE_ARTICLES = gql`
  mutation createArticle($where: ArticleData!) {
    createArticle( where: $where) {
      id
    }
  }
`

export const DELETE_ARTICLE = gql`
  mutation deleteArticle($id:String) {
    deleteArticle(id: $id) {
      id
    }
  }
`
export const UPDATE_ARTICLE = gql`
  mutation updateArticle($where: ArticleData!) {
    updateArticle( where: $where) {
      id
    }
  }
`

export const UPDATE_ARTICLE_TAG = gql`
  mutation updateArticleTags($where: ArticleData!) {
    updateArticleTags(where: $where) {
      id
    }
  }
`
export const DELETE_ARTICLE_TAG = gql`
  mutation deleteArticleTags($where: ArticleTagWhereInput!) {
    deleteArticleTags(where: $where) {
      id
    }
  }
`

