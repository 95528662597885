import React, { useMemo, memo, useState } from "react"
import { Modal } from "antd"
import "antd/lib/modal/style/css"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"

import { notify } from "src/components/notification-toast"
import {
  parseFormData,
  updateListing
} from "src/dashboards/screens/admin-access-building/utils/services"
import TenantForm from "./disable-step2-form"
import DisableForm from "./disable-step1-form"
import { MESSAGE, NOTIFICATION_MSG } from "src/constants/notification-mesage"
import { unstable_batchedUpdates } from "react-dom"
import { PermissionStatus } from "src/utils/typing.td"
import { UserType } from "src/generated/graphql"
import LandlordActiveStatus from "./for-landlord"
import ActiveStatus from "./for-admin"

interface Props {
  isHidden: boolean
  id?: string
  fetchListings: () => void
  subAdminAccess: Partial<PermissionStatus>
  isapproved?: boolean
  client: ApolloClient<NormalizedCacheObject>
  userType?: string
}

function HiddenListing({
  fetchListings,
  isHidden,
  subAdminAccess,
  id,
  isapproved,
  client,
  userType
}: Props) {
  const listingId = useMemo(() => id, [])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [stepper, setStepper] = useState<number>(1)
  const [formValues, setFormValues] = useState({})
  const [isSubmit, setIsSubmit] = useState<boolean>(false)

  const showModal = () => {
    if (subAdminAccess?.["edit"]) setIsModalVisible(true)
    else notify(NOTIFICATION_MSG.error, "You don't have an access")
  }

  const handleCancel = () => {
    if (stepper === 2) setStepper(1)
    else {
      setIsModalVisible(false)
    }
  }

  const updateActiveListing = async isHidden => {
    const payloadData = {
      id: listingId,
      isHidden,
      availablityStatus: null,
      disableListing: null,
      rentedPrice: 0
    }

    const resp = await updateListing(client, payloadData)
    if (resp && resp.data) {
      notify(NOTIFICATION_MSG.Success, "Listing is now Available!")
      fetchListings()
      setFormValues({})
    } else {
      notify(NOTIFICATION_MSG.error, resp?.toString())
    }
  }

  const onDisableListing = async () => {
    setIsSubmit(true)
    const reqData = parseFormData(listingId, formValues)
    const resp = await updateListing(client, reqData)
    if (resp?.data) {
      unstable_batchedUpdates(() => {
        setFormValues({})
        setIsModalVisible(false)
        setStepper(1)
      })
      fetchListings()
      notify(NOTIFICATION_MSG.Success, "Listing is now Unavailable!")
    } else notify(NOTIFICATION_MSG.error, resp?.toString())
    setIsSubmit(false)
  }

  const onSubmit = () => {
    if (stepper === 1) setStepper(2)
    else {
      onDisableListing()
    }
  }

  const activeListing = isHidden => {
    if (subAdminAccess?.["edit"]) updateActiveListing(isHidden)
    else notify(NOTIFICATION_MSG.error, MESSAGE.NoAccess)
  }

  const renderComponnet = (step: number) => {
    switch (step) {
      case 1:
        return <DisableForm setFormValues={setFormValues} />
      default:
        return (
          <>
            <TenantForm setFormValues={setFormValues} />
          </>
        )
    }
  }

  const hasDisable = useMemo(() => {
    if (isSubmit) return true
    else if (stepper === 1 && Object.values(formValues).length > 0) return false
    else return false
  }, [formValues, stepper, isSubmit])

  const hasApproval = useMemo(() => {
    if (!userType || userType === UserType.Landlord) return true
    else return false
  }, [userType])

  return (
    <React.Fragment>
      {hasApproval ? (
        <LandlordActiveStatus
          isapproved={isapproved}
          showModal={showModal}
          isHidden={isHidden}
          activeListing={activeListing}
        />
      ) : (
        <ActiveStatus showModal={showModal} isHidden={isHidden} activeListing={activeListing} />
      )}
      <Modal
        title={"Mark Listing as Unavailable"}
        centered={false}
        visible={isModalVisible}
        onOk={onSubmit}
        onCancel={handleCancel}
        okText={stepper === 1 ? "Next" : "Save"}
        okType="danger"
        okButtonProps={{ disabled: hasDisable }}
        cancelText={stepper === 1 ? "Cancel" : "Back"}
        closeIcon={<></>}
      >
        {renderComponnet(stepper)}
      </Modal>
    </React.Fragment>
  )
}

export default memo(HiddenListing)
