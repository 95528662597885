import React, { useState, memo } from "react"
import { InputComponent } from "src/dashboards/components/form-container-field/form-container-field"
import TextArea from "src/components/text-area"
import { Text } from "src/components/help-center-section/styled-compnent"
import colors from "src/theme/colors"
import styled from "styled-components"

type Props = {
  onChangeForm: (value: object) => void
  helpCenterId: string
}
const FormComponent = styled.div<any>`
  margin: 10px 0;
`

const FormTwo = (props: Props) => {
  const { onChangeForm, helpCenterId } = props
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value }
    } = event
    setTitle(value)

    const initiObj = {
      title: value,
      description: description,
      category: helpCenterId
    }
    onChangeForm(initiObj)
  }
  const onChangeTextArea = event => {
    const {
      target: { value }
    } = event
    const initiObj = {
      title: title,
      description: value,
      category: helpCenterId
    }
    onChangeForm(initiObj)
    setDescription(value)
  }

  return (
    <>
      <>
        <Text fontSize={16} fontWeight={600} color={colors.black}>
          Enter Title
        </Text>
        <FormComponent>
          <InputComponent
            id="title"
            name="title"
            required
            type="text"
            placeholder="Enter Title"
            // defaultValue={title}
            value={title}
            onChange={onChange}
            height={50}
            padding="0 0 0 10px"
          />
        </FormComponent>
      </>
      <>
        <Text fontSize={16} fontWeight={600} color={colors.black}>
          Enter Description
        </Text>
        <FormComponent>
          <TextArea
            id="description"
            value={description}
            name="description"
            placeholder="Enter description here..."
            onChange={onChangeTextArea}
            // defaultValue={description}
          />
        </FormComponent>
      </>
    </>
  )
}

export default memo(FormTwo)
