import React, { ReactNode } from "react"
import { Modal } from "antd"
import "antd/lib/modal/style/css"

type Props = {
  isModalVisible: boolean
  disabled?: boolean
  isSubmitting?: boolean
  handleCancel: () => void
  handleOk: () => void
  btnText: string
  title: string
  okType?: string
  children?: ReactNode
  footer?: boolean
  cancelText?: string
}

const ModalComponent = (props: Props) => {
  const {
    isSubmitting = false,
    title,
    disabled = false,
    isModalVisible,
    handleOk,
    children,
    handleCancel,
    btnText,
    cancelText,
    footer = true
  } = props
  return (
    <>
      {footer ? (
        <Modal
          title={title}
          centered
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={btnText}
          okType="danger"
          cancelText={cancelText || "cancel"}
          okButtonProps={{ disabled: disabled, loading: isSubmitting }}
          closeIcon={<></>}
        >
          {children}
        </Modal>
      ) : (
        <Modal
          title={title}
          centered
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={btnText}
          okType="danger"
          okButtonProps={{ disabled: disabled, loading: isSubmitting }}
          closeIcon={<></>}
          footer={footer}
        >
          {children}
        </Modal>
      )}
    </>
  )
}

export default ModalComponent
