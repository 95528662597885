import { createOptions } from "./createOptions"
import { UNIT_AMENITIES as unitamt } from "../../constants"
import { UNIT_APPLIANCES as unitapp } from "../../constants"
function parseStep5({ units, tenantRequirements }) {
  let unit = units[0] && units[0]
  return {
    floorplan: unit?.floorplan,
    unitAppliances: createOptions(unitapp, unit?.unitAppliances),
    unitamenities: createOptions(unitamt, unit?.unitAmenities)
  }
}

export { parseStep5 }
