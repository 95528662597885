import gql from "graphql-tag"

export const GET_FEATURE = gql`
  query features($skip: Int, $take: Int) {
    countFeatures
    features(skip: $skip, take: $take) {
      id
      name
      subtitle
      information
      createdAt
      updatedAt
      plan {
        id
      }
    }
  }
`
export const GET_COUNT_FEATURE = gql`
  query countFeatures {
    countFeatures
  }
`

export const GET_PLAN = gql`
  query plans {
    plans {
      id
      createdAt
      updatedAt
      price
      status
      unit
      name
      feature {
        id
        name
        information
      }
    }
  }
`

export const GET_SINGLE_PLAN = gql`
  query plan($id: String!) {
    plan(id: $id) {
      id
      price
      name
      status
      unit
      feature {
        id
        name
      }
    }
  }
`

export const FEATURE_PLAN = gql`
  query features {
    plans {
      id
      name
      price
      unit
      plan_group
      feature {
        id
        name
        information
      }
    }
  }
`
