import React, { useEffect } from "react"
import { FormControl } from "@material-ui/core"
import { PropType } from "./types"
import "../styles.scss"
import Text from "../../text"
// function formatDecimal(decimal) {
//   if (decimal?.length > 2) {
//     return decimal.slice(0, 2)
//   } else if (decimal) {
//     return decimal + "0"
//   } else {
//     return "00"
//   }
// }

const FormField = ({
  icon,
  form: { touched, errors, setFieldValue },
  field,
  labelTxt,
  styleInput = {},
  ...rest
}: PropType) => {
  const { value } = field
  useEffect(() => {
    if (value) {
      const [int, decimal] = value.toString().split(".")
      let finalValue = value

      if (decimal?.length > 2) finalValue = `${int}.${decimal.slice(0, 2)}`
      setFieldValue(field.name, parseFloat(finalValue))
    } else {
      setFieldValue(field.name, "")
    }
  }, [value])

  return (
    <FormControl id="custom-form" fullWidth style={{ marginBottom: 15 }}>
      {labelTxt && (
        <Text genre="labelText" mb={0}>
          {labelTxt}
        </Text>
      )}
      <div className="pricing-input">
        {(value || rest?.required) && <span>$</span>}
        <input
          margin="normal"
          className="primary-pricing"
          autoComplete="off"
          inputProps={{ min: "0" }}
          {...field}
          {...rest}
          style={styleInput}
        />
      </div>
      {errors[field.name] && touched[field.name] && (
        <div className="error-text">
          {rest.create_err_msg ? rest.create_err_msg(field.value) : errors[field.name]}
        </div>
      )}
    </FormControl>
  )
}

export default FormField
