const colors = {
  primary: "#00a857",
  primaryWithOpacity: "rgba(0, 168, 87, 0.2)",
  primaryHover: "rgba(0, 168, 87, 0.87)",
  black: "#3c4240",
  lightGrey: "#c4c6c5",
  lightGreyFaded: "#e0e0e0",
  fadedGrey: "#d1d1d1",
  darkGrey: "#3c4240",
  textFaded: "rgba(105, 91, 91, 0.5)",
  textLightGrey: "#8E958E",
  white: "#ffffff",
  blue: "#0051a8",
  orange: "#ff6d00",
  bodyBackground: "#fafafa",
  red: "#fc3d39"
}

export type ColorTypes = keyof typeof colors

export default colors
