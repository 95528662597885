import React from "react"
import Form from "../../../components/FormComponents";
import { landlordFields, landlordFieldswithOntario, landlordFieldwithLRG } from "./fields"
import FloorPlanUpload from "src/components/FormComponents/pdf-uploader"
interface Props {
    actionBtn: any;
    onSubmit: (payload: any, resetForm: any) => void;
    initialValues: { [x: string]: any };
    edit?: boolean;
    onFormChange2?: () => void
    region?: any
    user?: any
    isRentalOrTenantLeads?: any,
    isBasicFree?: boolean,
    isViewManagement?: boolean,
    isConcierge?: boolean,
    isRentalAssistance?: boolean,
    isLeadGeneartor?: boolean
}
const LandlordForm = React.memo(function LandlordForm({ actionBtn, onFormChange2, onSubmit, initialValues, edit, region, user, isRentalOrTenantLeads, isBasicFree, isViewManagement, isConcierge, isRentalAssistance, isLeadGeneartor }: Props) {


    const onFormChange = () => {
        if (onFormChange2 !== undefined)
            onFormChange2()
    }

    function getFormFields() {

        if (!edit && region === "ontario") {
            if (user === "Landlord/Owner" && isRentalOrTenantLeads) {
                return landlordFieldwithLRG.concat({
                    labelTxt: (
                        <>
                            <div>Upload proof of your property ownership.</div>
                            <div style={{ display: "flex", flexDirection: "column", opacity: 0.4, fontSize: "14px" }}><span>Such as home insurance, utility bills, executed purchase agreements, etc.</span></div>
                        </>
                    ),
                    name: "ownershipProof",
                    placeholder: "Upload proof of your property ownership",
                    type: "picture",
                    required: false,
                    component: FloorPlanUpload,
                    grid: 12,
                    styleInput: {
                        maxWidth: "100%",
                        minWidth: "100%"
                    }
                });
            } else {
                return landlordFieldswithOntario
            }
        }

        else if (edit && region === "ontario") {
            if (user === "Landlord/Owner" && (isRentalAssistance || isLeadGeneartor)) {
                return landlordFieldwithLRG.concat({
                    labelTxt: (
                        <>
                            <div>Upload proof of your property ownership.</div>
                            <div style={{ display: "flex", flexDirection: "column", opacity: 0.4, fontSize: "14px" }}><span>Such as home insurance, utility bills, executed purchase agreements, etc.</span></div>
                        </>
                    ),
                    name: "ownershipProof",
                    placeholder: "Upload proof of your property ownership",
                    type: "picture",
                    required: false,
                    component: FloorPlanUpload,
                    grid: 12,
                    styleInput: {
                        maxWidth: "100%",
                        minWidth: "100%"
                    }
                });
            } else {
                return landlordFieldswithOntario
            }
        }
        else {
            if (user === "Landlord/Owner") {
                return landlordFields.concat({
                    labelTxt: (
                        <>
                            <div>Upload proof of your property ownership.</div>
                            <div style={{ display: "flex", flexDirection: "column", opacity: 0.4, fontSize: "14px" }}><span>Such as home insurance, utility bills, executed purchase agreements, etc.</span></div>
                        </>
                    ),
                    name: "ownershipProof",
                    placeholder: "Upload proof of your property ownership",
                    type: "picture",
                    required: false,
                    component: FloorPlanUpload,
                    grid: 12,
                    styleInput: {
                        maxWidth: "100%",
                        minWidth: "100%"
                    }
                });
            } else {
                return landlordFields
            }
        }
    }


    return (
        <Form
            fields={getFormFields()}
            initialValues={initialValues}
            btnText={"submit"}
            onSubmit={onSubmit}
            renderCustomSubmit={actionBtn}
            edit={edit}
            onFormChange={onFormChange}
            giveButtonsFormikActions={true}
        />

    )
})

export default LandlordForm
