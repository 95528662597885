import gql from "graphql-tag"

export const DELETE_BUILDING = gql`
  mutation deleteBuilding($id: String!) {
    deleteBuilding(id: $id) {
      id
    }
  }
`

export const CREATE_BUILDING = gql`
  mutation createListing($data: ListingCreateInput!) {
    createListing(data: $data) {
      id
    }
  }
`
export const CREATE_LIST_WITH_US_BUILDING = gql`
  mutation createListWithUs($data: ListingCreateInput!) {
    createListWithUs(data: $data) {
      id
    }
  }
`

export const CREATE_CITY = gql`
  mutation createCity($data: CityData!) {
    createCity(data: $data) {
      id
    }
  }
`
export const CREATE_NEIGHBORHOOD = gql`
  mutation createNeighbourhood($data: NeighbourhoodCreateInput!) {
    createNeighbourhood(data: $data) {
      id
    }
  }
`

export const UPDATE_UNIT = gql`
  mutation updateUnit($where: UnitWhereUniqueInput!, $data: UnitUpdateInput!) {
    updateUnit(where: $where, data: $data) {
      id
    }
  }
`

export const UPDATE_PROPERTY = gql`
  mutation activeListing($where: where!, $data: ListingUpdateInput!) {
    activeListing(where: $where, data: $data) {
      id
    }
  }
`

export const UPDATE_BUILDING = gql`
  mutation updateBuilding($where: where!, $data: ListingUpdateInput!) {
    updateBuilding(where: $where, data: $data) {
      id
    }
  }
`

export const UPDATE_PAYMENT_STATUS = gql`
  mutation updatePayment($where: where!, $data: ListingUpdateInput!) {
    updatePayment(where: $where, data: $data) {
      id
    }
  }
`
export const UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscription($where: whereSub!, $data: SubscriptionUpdateInput!) {
    updateSubscription(where: $where, data: $data) {
      id
    }
  }
`
export const CHECKOUT = gql`

mutation createCheckoutSession($price: String!,$listingId:String!,$email:String!) {
  createCheckoutSession(price: $price,listingId:$listingId,email:$email){
    id
    url
  }
}

`;

export const DELETE_PICTURE = gql`
  mutation deletePicture($where: PictureWhereUniqueInput!) {
    deletePicture(where: $where) {
      id
    }
  }
`
