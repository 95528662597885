import gql from "graphql-tag"

export const CREATE_RENTAL = gql`
  mutation createRental($WhereInput: HelpWhereInput!) {
    createRental(WhereInput: $WhereInput) {
      id
    }
  }
`

export const DELETE_RENTAL = gql`
  mutation deleteRentalLaw($WhereInput: HelpWhereInput!) {
    deleteRentalLaw(WhereInput: $WhereInput) {
      id
    }
  }
`

export const UPDATE_RENTAL = gql`
  mutation updateRentalLaw($WhereInput: HelpWhereInput!) {
    updateRentalLaw(WhereInput: $WhereInput) {
      id
    }
  }
`
