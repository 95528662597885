import React, { Component } from "react"
import "src/dashboards/screens/admin-access-building/style.css"
import { loginRequest } from "src/hoc/authentication/lock"
import Footer from "src/components/footer"
import AuthenticationState from "src/hoc/authentication/authentication.state"
import { LandlordCreateListing } from "./create-listing"

import ReactHelmet from "src/components/react-helmet"

interface Props {
  authState: AuthenticationState
}

export default class ListWithUs extends Component<Props> {
  loginRequest = () => {
    loginRequest(true, false, this.props.authState)
  }

  render() {
    const { authState } = this.props
    return (
      <div id="list-with-us">
        <ReactHelmet
          url="list-with-us"
          title={`List With Us`}
          description="Tolobi - Please complete our Listing Form and we will reach out to you shortly!
                    If you have any questions, contact us"
        />
        <>
          <div className="imgDiv">
            <img
              alt=""
              className="imgLogo"
              src="https://img.paperform.co/fetch/w_600,f_auto/https://s3.amazonaws.com/pf-upload-01/u-22976/1/2020-05-20/ly034ue/Logo-Clear%20fv2019-05-15%201.png"
            />
          </div>

          <div className="title"> Listing Form </div>
          <div className="complete-listing">
            Please complete our Listing Form and we will reach out to you shortly!
          </div>
          {/* <div className="contact-us">
            If you have any questions, contact us at <a className="rent">rent@tolobi.com</a> or
            (647) 250-7371
          </div> */}
          <div className="buildings dashboard">
            <LandlordCreateListing authState={authState} />
          </div>
          <div className="contact-us">
            If you have any questions, contact us at <a className="rent">rent@tolobi.com</a> or
            (647) 250-7371
          </div>
          <Footer />
        </>
      </div>
    )
  }
}
