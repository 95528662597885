import gql from "graphql-tag"

export const UPDATE_FEATURE_ORDER = gql`
  mutation updateFeature($data: FeatureWhereInput) {
    updateFeature(data: $data) {
      id
    }
  }
`

export const REORDER_CONTENT = gql`
  mutation reorderContent($where: OrderType) {
    reorderContent(where: $where) {
      id
    }
  }
`
