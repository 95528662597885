import * as React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"

import { loginRequest } from "../../hoc/authentication/lock"
import Box from "../box"
import Button from "../button"
import { UserType } from "../../generated/graphql"
import Colors from "../../theme/colors"
import AuthenticationState from "../../hoc/authentication/authentication.state"
import { NavLink } from "./logged-in-section.component"
import theme from "../../theme"

const LoginSignupWrapper = styled(Box as any)`
  display: flex;
  align-items: flex-end;
`

export const NavItem = styled(Box as any)`
  align-items: center;
  justify-content: center;
  height: 85%;
  padding-right: 20px;
  a {
    height: 100%;
  }
  &:last-child {
    padding-right: 0px;
  }
  @media (max-width: 500px) {
    padding-right: 10px;
    &:last-child {
      padding-right: 0px;
    }
  }
  @media (max-width: 450px) {
    padding-right: 18px;
    &:last-child {
      padding-right: 0px;
    }
  }
  @media (min-width: 901px) {
    padding-right: 20px;
    &:last-child {
      padding-right: 0px;
    }
  }
`

const LoginButton = styled(Button as any)`
  margin-right: 16px;
  font-size: 16px;
  font-weight: 600;
  color: ${Colors.black};
  @media (max-width: 420px) {
    font-size: 14px;
    padding: 7px 7px;
    margin-right: 7px;
  }
  @media (max-width: 340px) {
    font-size: 13px;
    padding: 4px 4px;
    margin-right: 4px;
  }
`

const SignUpButton = styled(Button as any)`
  width: auto;
  @media (min-width: ${theme.breakpoints[0]}) {
    width: 200px;
  }
  @media (max-width: 420px) {
    font-size: 14px;
    padding: 7px 7px;
  }
  @media (max-width: 340px) {
    font-size: 13px;
    padding: 4px 4px;
  }
`

type Props = {
  authState: AuthenticationState
  history: any
  handleSearchClick: () => any
}

const NotLoggedInSection = ({ authState, history, handleSearchClick }: Props) => {
  const navigateUser = (userType: string | null) => () => {
    if (userType === UserType.Landlord) {
      history.push("/building")
    } else if (userType === UserType.Admin) {
      history.push("/listings")
    } else {
      history.push("/signup-modal", { next: "/" })
    }
  }

  return (
    <LoginSignupWrapper>
      <NavItem>
        <Button genre="secondary" size="small" onClick={handleSearchClick}>
          Explore Homes
        </Button>
      </NavItem>
      <NavItem>
        <Button genre="text" size="small">
          <Link to="/list-with-us">
            <NavLink>List Your Property</NavLink>
          </Link>
        </Button>
      </NavItem>

      <NavItem>
        <Button genre="text" size="small">
          <Link to="/services">
            <NavLink>Services</NavLink>
          </Link>
        </Button>
      </NavItem>

      <NavItem>
        <Button genre="text" size="small">
          <Link to="/knowledge-center">
            <NavLink>Knowledge Center</NavLink>
          </Link>
        </Button>
      </NavItem>

      <NavItem>
        <LoginButton
          genre="text"
          size="small"
          onClick={async () => {
            await loginRequest(true, false, authState)
          }}
        >
          <NavLink>Log In</NavLink>
        </LoginButton>
      </NavItem>

      <SignUpButton onClick={() => loginRequest(true, true, authState)}>Sign Up</SignUpButton>
    </LoginSignupWrapper>
  )
}
export default NotLoggedInSection
