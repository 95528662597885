import { isEqual } from "lodash"
import { PermissionAccessType } from "src/containers/blogs"

export const accessList = ["add", "edit", "delete", "view"]

const checkAccess = (data, screen, permissionType) => {
  for (let i = 0; i < data?.length; i++) {
    const { permissions, permissiontypes } = data[i]
    const { name } = permissions
    if (screen === name && permissionType === permissiontypes["name"]) return true
  }
  return false
}

export const checkAllAccess = (data, screen): PermissionAccessType => {
  let allAccess: PermissionAccessType = {
    edit: false,
    add: false,
    delete: false,
    create: false
  }
  for (let i = 0; i < accessList.length; i++) {
    let permissionType = accessList[i]
    let access = checkAccess(data, screen, permissionType)
    allAccess[permissionType] = access
  }
  return allAccess
}

export const removeExtraUrlSymbols = (url: string) => {
  const updatedUrl = url
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "")
    .replace(/:/g, "")
  return updatedUrl
}

export const getCurrentHostAddress = () => window.location.origin

export const toBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = () => reject(new Error("Failed to read file"))
    reader.readAsDataURL(file)
  })
}

export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      const base64 = (reader?.result as string)?.split(",")?.[1]
      resolve(base64)
    }

    reader.onerror = () => {
      reject(new Error("Failed to read file"))
    }

    reader.readAsDataURL(file)
  })
}

export const parseJsonWithErrorCatch = (value: any) => {
  try {
    const result = JSON.parse(value || null)
    return result
  } catch (e) {
    console.log(e)
    return null
  }
}

export const getUpdatedValues = (obj1: object, obj2: object) => {
  const pickUpdated = Object.fromEntries(
    Object.entries(obj1)
      .map(([key, value]) => {
        if (!isEqual(value, obj2[key])) {
          return [key, value]
        }
        return false
      })
      .filter(Boolean) as any[]
  )
  return pickUpdated
}

export function getNumberWithOrdinal(n: number) {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export const isValidEmail = (email: string) => {
  if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
    return true
  } else {
    return false
  }
}

export const isValidUrl = (url: string): boolean => {
  // const urlPattern = new RegExp(
  //   "^https?:\\/\\/" + // mandatory protocol
  //   "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|" + // domain name and extension
  //   "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
  //   "(\\:\\d+)?" + // port
  //   "(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*" + // path
  //   "(\\?[;&a-zA-Z0-9%._\\+~#=\\-]*)?" + // query string
  //     "(\\#[-a-zA-Z0-9_]*)?$", // fragment locator
  //   "i" // case insensitive
  // )
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$" // fragment locator
    // "i" case insensitve
  )

  return urlPattern.test(url)
}

export function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = match[1] ? "+1" : ""
    return [intlCode, "(", match[2], ")", match[3], "-", match[4]].join("")
  }
  return phoneNumberString
}

export const positiveNumberCheckOnKeyPress = (e: any): any => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault()
  }
}

export const downloadBlob = (data: any, name: string, newTab?: boolean) => {
  const blob = new Blob([data])

  // Create a URL for the Blob
  const blobURL = URL.createObjectURL(blob)

  // Create a link for download
  const downloadLink = document.createElement("a")
  downloadLink.href = blobURL
  downloadLink.style.display = "none"

  if (newTab) downloadLink.target = "_blank"
  downloadLink.download = `${name}-terms.docx` // Change the filename if needed

  // Append the link to the DOM (not necessary, but useful for some browsers)
  document.body.appendChild(downloadLink)

  // Click the link to trigger download
  downloadLink.click()
  document.body.removeChild(downloadLink)
}
export const editAltToblogImage = (content: string, alt: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(content, "text/html")
  const images = doc.getElementsByTagName("img")
  const cleanAltText = alt.replace(/[^\w\s]/gi, "")
  for (let i = 0; i < images.length; i++) {
    if (!images[i].hasAttribute("alt")) {
      images[i].setAttribute("alt", cleanAltText + (i > 0 ? " " + (i + 1) : ""))
    }
  }
  return doc.body.innerHTML
}
