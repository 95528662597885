import * as React from "react"
import styled, { keyframes } from "styled-components"
import {
  width,
  WidthProps,
  space,
  SpaceProps,
  flexDirection,
  alignItems,
  justifyContent,
  JustifyContentValue,
  flex,
  FlexProps,
  color,
  ColorProps,
  textAlign,
  TextAlignProps,
  flexWrap,
  FlexWrapProps,
  AlignItemsValue,
  FlexDirectionValue
} from "styled-system"

const displayAsLoading = (isLoading: any) => {
  if (isLoading) {
    const loadingAnimation = keyframes`
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(125%);
      }
    `
    return `
      overflow: hidden;
      background: #e8e8e8;
      border-radius: 3px;
      &::after {
        background: linear-gradient(90deg,#e8e8e8,#d0d0d0,#e8e8e8);
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        animation: ${loadingAnimation} 1.25s cubic-bezier(.36,1.14,.45,.22) infinite forwards;
      }
    `
  }
  return {}
}

interface JustifyContentProps {
  justifyContent?: JustifyContentValue
}

interface AlignItemsProps {
  alignItems?: AlignItemsValue | Array<AlignItemsValue>
}

interface FlexDirectionProps {
  flexDirection?: FlexDirectionValue | Array<FlexDirectionValue>
}

interface Props {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  height?: number
  onClick?: any
  key?: any
  isSticky?: boolean
  loading?: boolean
}

type BoxProps = Props &
  WidthProps &
  SpaceProps &
  FlexDirectionProps &
  JustifyContentProps &
  FlexProps &
  ColorProps &
  TextAlignProps &
  FlexWrapProps &
  AlignItemsProps

const Box = ({ children, ...props }: BoxProps) => <div className={props.className}>{children}</div>

const StyledBox = styled(Box as any)`
  display: flex;
  position: relative;
  box-sizing: border-box;
  ${({ height }: any) => height && `height: ${height}px`};
  ${width};
  ${space};
  ${flexDirection};
  ${justifyContent};
  ${flex};
  ${color};
  ${flexWrap};
  ${textAlign};
  ${alignItems};
  ${({ isLoading }: any) => displayAsLoading(isLoading)};
`

export default StyledBox
