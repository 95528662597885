import React, { useState } from "react"
import { FormControl } from "@material-ui/core"
import Text from "src/components/text"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import "./style.scss"

const FormDropDown = props => {
  const {
    form,
    field,
    defaultVal,
    placeholder,
    mb,
    labelTxt,
    styleInput,
    options = [],
    ...rest
  } = props

  const [select, setSelect] = useState(field.value || "")
  const [windowDimenion, detectHW] = useState(window.innerWidth)
  console.log()

  const detectSize = () => {
    detectHW(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener("resize", detectSize)
    return () => {
      window.removeEventListener("resize", detectSize)
    }
  }, [windowDimenion])

  React.useEffect(() => {
    setSelect(field.value)
  }, [field.value])

  const handleChange = ({ target: { value } }) => {
    setSelect(value)
    form.setFieldValue(field.name, value)
  }

  return (
    <FormControl id="furnised-field" style={{ marginBottom: 15 }} fullWidth>
      {labelTxt && (
        <Text genre="labelText" mb={mb && windowDimenion < 1200 ? mb : 10}>
          {labelTxt}
        </Text>
      )}
      <FormControl variant="outlined" style={{ marginTop: 2 }}>
        <Select id="demo-simple-select-outlined" value={select} onChange={handleChange}>
          {options.map(option => (
            <MenuItem value={option.value}>{option.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {form.errors[field.name] && form.touched[field.name] && (
        <div className="error-text">
          {rest.create_err_msg ? rest.create_err_msg(field.value) : form.errors[field.name]}
        </div>
      )}
    </FormControl>
  )
}

export default FormDropDown
