import React from "react"
import Form from "../../../components/FormComponents";
import { unitfields3 } from "./fields"

interface Props {
  actionBtn: any;
  onSubmit: (payload: any, resetForm: any) => void;
  initialValues: { [x: string]: any };
  edit?: boolean;
  onFormChange2?: () => void
}

const ThirdUnitForm = React.memo(function ThirdUnitForm({ actionBtn, edit, onFormChange2, onSubmit, initialValues }: Props) {
  const onFormChange = () => {
    if (onFormChange2 !== undefined)
      onFormChange2()
  }
  return (
    <Form
      fields={unitfields3}
      initialValues={initialValues}
      btnText={"submit"}
      onSubmit={onSubmit}
      renderCustomSubmit={actionBtn}
      onFormChange={onFormChange}
      giveButtonsFormikActions
    />

  )
})

export default ThirdUnitForm
