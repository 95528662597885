import { SelectObjType } from "../../../screens/landlord-access-building/types/index"

function createOptions(
  selected: SelectObjType[],
  objects: { [x: string]: boolean } | null
): SelectObjType[] {
  let options: SelectObjType[] = []
  if (objects) {
    const keys: string[] = Object.keys(objects || {})
    for (const key of keys) {
      if (objects[key]) {
        const val: SelectObjType | undefined = selected.find(
          ({ value }: SelectObjType) => key === value[0].toLowerCase() + value.substring(1)
        )
        if (val) options.push(val)
      }
    }
  }
  return options
}

export { createOptions }
