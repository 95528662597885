import * as React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { Colors } from "../../theme"
import Box from "../box"
import Avatar from "../avatar"
import Text from "../text"
import Button from "../button"
import AuthenticationState from "../../hoc/authentication/authentication.state"
// import MessageNotificationWrapper from "../message-notification-wrapper"
import Menu from "antd/lib/menu"
import Dropdown from "antd/lib/dropdown"
import "antd/lib/menu/style/css"
import "antd/lib/dropdown/style/css"
import { UserType } from "../../generated/graphql"
import { loginRequest, logoutHandler } from "src/hoc/authentication/lock"
import { useHistory } from "react-router"
const LoggedInWrapper = styled(Box as any)`
  flex-direction: row;
  align-items: center;
  height: 100%;
`
const LinkNav = styled.div`
  width: 109px;
`

const ListPropertyWrapper = styled(Box as any)`
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  width: 100%;
  height: 100%;
  padding-right: 32px;
`

export const NavLink = styled(Text as any) <any>`
  display: flex;
  width: max-content;
  align-items: center;
  color: ${Colors.black};
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  cursor: pointer;
  border-top: 4px solid white;
  a {
    height: 100%;
  }
  &:hover {
    cursor: pointer;
    ${props => props.isAvatar && `border-top: 4px solid white;`};
  }
  @media (max-width: 450px) {
    font-size: 13px;
  }
`

export const NavItem = styled(Box as any)`
  align-items: center;
  justify-content: center;
  height: 85%;
  padding-right: 40px;
  a {
    height: 100%;
  }
  &:last-child {
    padding-right: 0px;
  }
  @media (max-width: 500px) {
    padding-right: 24px;
    &:last-child {
      padding-right: 0px;
    }
  }
  @media (max-width: 450px) {
    padding-right: 18px;
    &:last-child {
      padding-right: 0px;
    }
  }
  @media (min-width: 901px) {
    padding-right: 32px;
    &:last-child {
      padding-right: 0px;
    }
  }
`

interface MyProps {
  authState: AuthenticationState
  history: any
  handleSearchClick: () => any
}

type Props = MyProps

const LoggedInSection = ({authState, handleSearchClick }: Props) => {
  const history = useHistory()

  const onListPropertyClick = () => {
    history.push("/")
  }

  const menu = (
    <Menu>
      <Menu.Item key="profile">
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      {authState.state.user &&
        (authState.state.user.userType === UserType.Admin ||
          authState.state.user.userType === UserType.SubAdmin) && (
          <Menu.Item key="dashboards">
            <Link to="/dashboards">Dashboard</Link>
            {/* (Beta) */}
          </Menu.Item>
        )}
      {authState.state.user && authState.state.user.userType === UserType.Landlord && (
        <Menu.Item key="building">
          <Link to="/building">Dashboard</Link>
          {/* (Beta) */}
        </Menu.Item>
      )}
      {authState.state.user && authState.state.user.userType === UserType.Admin && (
        <Menu.Item key="admins">
          <Link to="/admins">Admins</Link>
        </Menu.Item>
      )}
      <Menu.Item key="logout" onClick={() => logoutHandler()}>
        Logout
      </Menu.Item>
    </Menu>
  )

  const navigateUser = (userType: string | null) => () => {
    switch (userType) {
      case UserType.Landlord:
        return history.push("/building")
      case UserType.Admin:
        return history.push("/listings")
      case UserType.SubAdmin:
        return history.push("/listings")
      default:
        return () => {
          loginRequest(true, true, authState).then(() => onListPropertyClick())
        }
    }
  }

  const navigateToMsg = (userType: string | null) => () => {
    switch (userType) {
      case UserType.Landlord:
        return history.push("/message")
      case UserType.Admin:
        return history.push("/adminInbox")
      case UserType.SubAdmin:
        return history.push("/adminInbox")
      default:
        return history.push("/message")
    }
  }

  return (
    <LoggedInWrapper>
      <ListPropertyWrapper>
        <Button genre="secondary" size="small" onClick={handleSearchClick}>
          Explore Homes
        </Button>
      </ListPropertyWrapper>
      <NavItem>
        <LinkNav
          onClick={navigateUser(
            authState && authState.state && authState.state.user && authState.state.user.userType
          )}
        >
          <NavLink>List Your Property</NavLink>
        </LinkNav>
      </NavItem>
      <NavItem>
        <Link to="/services">
          <NavLink>Services</NavLink>
        </Link>
      </NavItem>{" "}
      <NavItem>
        <Link to="/knowledge-center">
          <NavLink>Knowledge Center</NavLink>
        </Link>
      </NavItem>
      {/* <NavItem>
        <Link to="/leases">
          <NavLink>Leases</NavLink>
        </Link>
      </NavItem> */}
      <NavItem>
        <div
          onClick={navigateToMsg(
            authState && authState.state && authState.state.user && authState.state.user.userType
          )}
        >
          <NavLink>Messages</NavLink>
        </div>
      </NavItem>
      <NavItem>
        <Link to="/contact-us">
          <NavLink>Help</NavLink>
        </Link>
      </NavItem>
      <NavItem>
        <Dropdown overlay={menu} placement="bottomRight">
          <Avatar
            size="small"
            imageURL={authState.state && authState.state.user && authState.state.user.avatarUrl}
          />
        </Dropdown>
      </NavItem>
    </LoggedInWrapper>
  )
}

export default LoggedInSection
