import * as React from "react"
import * as ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { LastLocationProvider } from "react-router-last-location"
import { ApolloProvider } from "react-apollo"
import TagManager from "react-gtm-module"
import { Provider } from "unstated"
import { I18nextProvider } from "react-i18next"
import "react-dates/initialize"
import { client } from "./apollo"
import "./react_dates_overrides.css"
import i18n from "./i18n"
import App from "./App"
import { unregister } from "./registerServiceWorker"
import "./index.css"
import { HelmetProvider } from "react-helmet-async"

const tagManagerArgs = {
  gtmId: "GTM-PWLNVCZ"
}
// console.log = function() {}
console.warn = function() {}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Provider>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <LastLocationProvider>
          <ApolloProvider client={client}>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </ApolloProvider>
        </LastLocationProvider>
      </BrowserRouter>
    </I18nextProvider>
  </Provider>,
  document.querySelector("#root")
)

unregister()
