import React from "react"
import Text from "src/components/text"

type Props = {
  total: number
  activeIndex: number
}

function PaginationStats({ activeIndex, total }: Props) {
  return (
    <Text>
      Showing {activeIndex}-{activeIndex + 10 > total ? total : activeIndex + 10} of {total}{" "}
      results
    </Text>
  )
}

export default PaginationStats
