import React from "react"
import styled from "styled-components"

type Props = {
  lable: string
}

const TitleTxt = styled.h4`
  font-family: "Source Sans Pro", sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #3c4240;
`

export default function Title({ lable }: Props) {
  return <TitleTxt>{lable}</TitleTxt>
}
