import React from "react"
import { FormControl } from "@material-ui/core"
import { PropType } from "./types"
import DatePickerWrapper from "src/components/single-date-picker"
import { DateField } from "../../../dashboards/components/form-container-field/form-container-field"
import Text from "../../text"
import moment from "moment"

const YearPickerComponent = ({
  icon,
  form: { touched, errors, setFieldValue, value },
  field,
  labelTxt,
  styleInput = {},
  ...rest
}: PropType) => {
  const [date, setDate] = React.useState(new Date() || null)
  const [DateRange, SetDate] = React.useState(rest.outSideRange || null)
  const handleDate = value => {
    if (moment(value && value._d, "YYYY-MM-DD").isValid()) {
      setDate(value._d)
      setFieldValue(field.name, value._d)
    }
  }
  React.useEffect(() => {
    if (field.value) {
      setDate(field.value)
    } else {
      setDate(new Date())
    }
  }, [field.value])

  return (
    <FormControl id="custom-form" style={{ marginBottom: 15 }} fullWidth>
      <Text genre="labelText">{labelTxt}</Text>
      <DateField>
        <DatePickerWrapper
          id="moveIn"
          date={moment(date)}
          onDateChange={handleDate}
          outSideRange={DateRange}
        />
      </DateField>
      {errors[field.name] && touched[field.name] && (
        <div className="error-text">
          {rest.create_err_msg ? rest.create_err_msg(field.value) : errors[field.name]}
        </div>
      )}
    </FormControl>
  )
}

export default YearPickerComponent
