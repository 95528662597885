import React, { useState } from "react"
import { FormControl } from "@material-ui/core"
import { PropType } from "./types"
import YearPicker from "react-year-picker"
import "../styles.scss"
import Text from "src/components/text"

const YearPickerComponent = ({
  icon,
  form: { touched, errors, setFieldValue },
  field,
  labelTxt,
  styleInput = {},
  ...rest
}: PropType) => {
  const yearRef: any = React.useRef()
  const [dateError, setDateError] = useState("")
  const [year, setYear] = useState(field.value || null)
  const handleChange = date => {
    const currentYear: number = new Date().getFullYear()
    if (date <= currentYear) {
      setFieldValue(field.name, date)
      setYear(date)
      setDateError("")
    } else setDateError(`${labelTxt} must be before ${currentYear}`)
  }

  const onResetYear = () => {
    yearRef?.current?.setState({ currentYear: "" })
    setFieldValue(field.name, null)
    setYear(null)
  }

  React.useEffect(() => {
    if (field.value) yearRef?.current?.setState({ currentYear: field.value })
  }, [field.value])

  return (
    <FormControl id="custom-form" fullWidth style={{ marginBottom: 15 }}>
      {labelTxt && <Text genre="labelText">{labelTxt}</Text>}
      <div style={styleInput}>
        <YearPicker onChange={handleChange} value={year} ref={yearRef} className="primary" />
        {year ? (
          <span onClick={onResetYear} id="reset-year">
            Reset
          </span>
        ) : null}
      </div>
      {dateError && <p className="error-text"> {dateError}</p>}
      {errors[field.name] && touched[field.name] && (
        <div className="error-text">
          {rest.create_err_msg ? rest.create_err_msg(field.value) : errors[field.name]}
        </div>
      )}
    </FormControl>
  )
}

export default YearPickerComponent
