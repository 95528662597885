import gql from "graphql-tag"

export const GET_BOOSTED_LISTING = gql`
  query boostedListing($first: Int, $skip: Int) {
    boostedListingCount {
      count
    }
    boostedListing(first: $first, skip: $skip) {
      id
      title
      createdAt
      updatedAt
      premiumStart
      premiumEnd
      premiumName
    }
  }
`
