import { ANALATIC_HELP_CENTER_VIEW } from "../graphql/help-center/query"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"

export const getAnalyticHelpCenterView = async (client: ApolloClient<NormalizedCacheObject>) => {
  try {
    return await client.query({
      query: ANALATIC_HELP_CENTER_VIEW
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}
