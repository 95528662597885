import { GET_BOOSTED_LISTING } from "src/services/graphql"

export const fetchBoostedListing = async (client, skipNo) => {
  try {
    const { data } = await client.query({
      query: GET_BOOSTED_LISTING,
      fetchPolicy: "network-only",
      variables: {
        first: 10,
        skip: skipNo
      }
    })
    return data
  } catch (err) {
    console.log("Error", err)
  }
}
