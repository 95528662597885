import React, { Component } from "react"
import ReactHelmet from "src/components/react-helmet"
import RentalForm from "./rental-application"

class RentalApplicationForm extends Component {
  render() {
    return (
      <React.Fragment>
        <ReactHelmet
          url=""
          title="Tolobi Rental Application"
          description="Tolobi - Rental Made Easy"
          id=""
          city=""
        />
        <RentalForm />
      </React.Fragment>
    )
  }
}

export default RentalApplicationForm
