import React from "react"
import { Helmet } from "react-helmet"

const ReactHelmet = ({ title, description, id, city, url, pageType }: { title: string; description: string; id?: string; url?: string; city?: string; pageType?: string }) => {
  let canonicalUrl;
  if (pageType === 'single-listing') {
    canonicalUrl = `https://www.tolobi.com/listing/${id}`;
  } else {
    canonicalUrl = `https://www.tolobi.com/${url}`;
  }
  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang: "en" }}
      meta={[
        {
          name: `description`,
          content: description
        },
        {
          name: 'keywords',
          content: title,
        },
      ]}
      link={[
        {
          rel: "canonical",
          href: canonicalUrl
        }
      ]}
    />
  )
}

export default ReactHelmet
