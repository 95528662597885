import { Container } from "unstated"
import { FetchCurrentUserMe } from "src/generated/graphql"

interface State {
  user: FetchCurrentUserMe | null
  firstLogin: boolean
}

class AuthenticationState extends Container<State> {
  state: State = {
    user: null,
    firstLogin: false
  }

  setUser = (user: FetchCurrentUserMe) => {
    this.setState({
      user
    })
  }

  setFirstLogin = (isOnFirstLogin: boolean) => {
    this.setState({
      firstLogin: isOnFirstLogin
    })
  }
}

export default AuthenticationState
