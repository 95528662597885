import React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}


const ICurrencyMask: any = React.forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            // @ts-ignore
            <IMaskInput
                {...other}
                mask="$ num"
                blocks={{
                    num: {
                        mask: Number,
                        thousandsSeparator: ','
                    }
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
)


export default ICurrencyMask
