import { GET_FEATURE, GET_PLAN, FEATURE_PLAN, GET_COUNT_FEATURE } from "../graphql/query"

export const fetchFeature = async (client, skip: number, take: number | null) => {
  return await client.query({
    query: GET_FEATURE,
    fetchPolicy: "network-only",
    variables: {
      skip,
      take
    }
  })
}
export const fetchTotalFeatures = async client => {
  return await client.query({
    query: GET_COUNT_FEATURE
  })
}

export const featureAndPlan = async client => {
  return await client.query({
    query: FEATURE_PLAN
  })
}

export const fetchPlan = async client => {
  try {
    return await client.query({
      query: GET_PLAN,
      fetchPolicy: "network-only"
    })
  } catch (err) {
    console.log("plan error", err)
  }
}
