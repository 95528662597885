import { StepResponseType } from "../../../screens/landlord-access-building/types/index"

function parseStep1({
  title,
  description,
  buildingConstructionYear,
  storiesInBuilding,
  totalUnitsInBuilding,
  propertyType,
  location
}: StepResponseType) {
  return {
    buildingTitle: title,
    address: {
      address: location.address !== null ? location.address : title,
      geometry: { lats: location !== null && location.lat, lngs: location !== null && location.lng }
    },
    des: description,
    units: totalUnitsInBuilding,
    floors: storiesInBuilding,
    contractions: buildingConstructionYear,
    propertyType
  }
}

export { parseStep1 }
