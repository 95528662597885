import React from "react"
import Form from "../../../components/FormComponents"
import { fields2, editFields2 } from "./fields"

interface Props {
  actionBtn: any
  onSubmit: (payload: any, resetForm: any) => void
  initialValues: { [x: string]: any }
  onFormChange2?: () => void
  editMode?: boolean
}

const BuildingPicture = ({
  onSubmit,
  actionBtn,
  onFormChange2,
  initialValues,
  editMode = false
}: Props) => {
  const onFormChange = () => {
    if (onFormChange2 !== undefined) onFormChange2()
  }
  return (
    <React.Fragment>
      <Form
        fields={editMode ? editFields2 : fields2}
        initialValues={initialValues}
        onSubmit={onSubmit}
        renderCustomSubmit={actionBtn}
        onFormChange={onFormChange}
        giveButtonsFormikActions
      />
    </React.Fragment>
  )
}

export default BuildingPicture
