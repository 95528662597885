import {
  GET_LISTINGS,
  GET_AVAILABLE_LISTINGS,
  GET_BUILDING_BY_ID,
  GET_UNAVAILABLE_LISTINGS,
  SEARCH_BUILDING,
  GET_PREMIUM_TYPE,
  GET_LISTING_ENQUIRY
} from "../graphql/query"
import {
  DELETE_BUILDING,
  PREMIMUM_LISTING,
  PREMIMUM_NOTIFICATION,
  UPDATE_PREMIUM,
  UPDATE_PROPERTY,
  UPDATE_LISTING,
  PREMIMUM_NOTIFICATION_LANDLORD,
  LISTING_ENQUIRY,
} from "../graphql/mutation"
import { radioGroupOptions } from "src/utils/auth-utils"
import moment from "moment"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"
import { QueryOptions } from "@apollo/client"

export const fetchListing = async (skipNo, client) => {
  try {
    const resp = await client.query({
      query: GET_LISTINGS,
      fetchPolicy: "network-only",
      variables: {
        first: 10,
        skip: skipNo
      }
    })
    return resp
  } catch (err) {
    console.log("Error", err)
  }
}

export const notificationToAdmin = async (client, data) => {
  try {
    await client.mutate({
      mutation: PREMIMUM_NOTIFICATION,
      variables: {
        where: { ...data }
      }
    })
  } catch (err) {
    console.log("Error++++", err)
  }
}

export const notificationToAdminFromLandlord = async (client, data) => {
  try {
    await client.mutate({
      mutation: PREMIMUM_NOTIFICATION_LANDLORD,
      variables: {
        where: { ...data }
      }
    })
  } catch (err) {
    console.log("Error++++", err)
  }
}


export const listingEnquiry = async (client, data) => {
  try {
    return await client.mutate({
      mutation: LISTING_ENQUIRY,
      variables: {
        where: { ...data }
      }
    })
  }
  catch (err) {
    console.log("Error++++", err)
    return false
  }
}

export const getListingEnquiry = async (client: ApolloClient<NormalizedCacheObject>, listingId: string, fetchPolicy?: QueryOptions['fetchPolicy']) => {
  try {
    return await client.query({
      query: GET_LISTING_ENQUIRY,
      fetchPolicy,
      variables: {
        where: {
          listing: listingId
        }
      },

    })
  } catch (err) {
    console.log("ERROR:", err)
  }
}

export const fetchAvailableListing = async (skip, client, isHidden: boolean) => {
  try {
    return await client.query({
      query: GET_AVAILABLE_LISTINGS,
      variables: {
        first: 10,
        skip,
        isHidden
      }
    })
  } catch (err) {
    console.log("ERROR:", err)
  }
}

export const fetchUnavailableListing = async (skipNo, client) => {
  try {
    return await client.query({
      query: GET_UNAVAILABLE_LISTINGS,
      variables: {
        first: 10,
        skip: skipNo
      }
    })
  } catch (err) {
    console.log("ERROR:", err)
  }
}

export const fetchSearchListingPagination = async (client, value, skip) => {
  try {
    const { data = {} }: any = await client.query({
      query: SEARCH_BUILDING,
      variables: {
        search: value,
        skip: skip,
        take: 10
      }
    })
    return data
  } catch (err) {
    console.log("Err", err)
  }
}

export const fetchSearchListing = async (client, value: string, skip: number) => {
  try {
    const { data } = await client.query({
      query: SEARCH_BUILDING,
      variables: {
        search: value,
        skip,
        take: 10
      }
    })
    return data
  } catch (err) {
    console.log("Err", err)
  }
}

export const fetchSingleListing = async (id, client) => {
  try {
    const { data = {} } = await client.query({
      query: GET_BUILDING_BY_ID,
      variables: {
        id
      }
    })
    return data
  } catch (err) {
    console.log("Error", err)
  }
}

export const fetchAdminListingData = async client => {
  try {
    return await client.query({
      query: GET_LISTINGS
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const deleteListing = async (client, id) => {
  try {
    return await client.mutate({
      mutation: DELETE_BUILDING,
      variables: {
        id
      }
    })
  } catch (err) {
    console.log({ err })
  }
}

export const premimumListing = async (client, id) => {
  try {
    return await client.mutate({
      mutation: PREMIMUM_LISTING,
      variables: {
        id,
        premiumName: "Listing boosts",
        isPremium: true
      }
    })
  } catch (err) {
    console.log({ err })
  }
}

export const premimumUpdate = async (client, value, name) => {
  try {
    return await client.mutate({
      mutation: UPDATE_PREMIUM,
      variables: {
        where: {
          name: name
        },
        data: {
          value: value
        }
      }
    })
  } catch (err) {
    console.log({ err })
  }
}

export const getPremiumType = async (client, value) => {
  try {
    return await client.query({
      query: GET_PREMIUM_TYPE,
      fetchPolicy: "network-only",
      variables: {
        where: {
          name: value
        }
      }
    })
  } catch (err) {
    console.log({ err })
  }
}

export const updateListing = async (client, data) => {
  try {
    const { id, ...rest } = data
    return await client.mutate({
      mutation: UPDATE_PROPERTY,
      variables: {
        where: {
          id: data.id
        },
        data: {
          ...rest
        }
      }
    })
  } catch (err) {
    console.log({ err })
    return err
  }
}

export const updateDeactivateSearchListing = async (client, data) => {
  try {
    const { id, ...rest } = data
    return await client.mutate({
      mutation: UPDATE_LISTING,
      variables: {
        where: {
          id: data.id
        },
        data: {
          ...rest
        }
      }
    })
  } catch (err) {
    console.log({ err })
    return err
  }
}

const matchMessage = (message: string): string => {
  switch (message) {
    case radioGroupOptions[0].value:
      return "0"
    case radioGroupOptions[1].value:
      return "1"
    default:
      return message
  }
}

export const parseFormData = (listingId, data) => {
  const { message, rentedPrice, date, tuList } = data

  return {
    id: listingId,
    signedLeaseDate: date,
    rentedPrice: parseInt(rentedPrice),
    disableListing: matchMessage(message),
    tenantForm: tuList?.map(({ value }) => ({ name: value })),
    isHidden: true,
    premiumName: null,
    premiumStart: moment(),
    premiumEnd: moment()
  }
}
