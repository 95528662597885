import { TextField, withStyles } from "@material-ui/core"

const CssTextField = withStyles({
    root: {
        "& .MuiOutlinedInput-root": {
            fontSize: '1rem',
            fontWeight: 400,
            color: "#00a857",
            "& input": {
                padding: '18px 14px',
                '&::placeholder': {
                    color: '#8d8d8d',
                    opacity: 1
                }
            },
            "& textarea": {
                '&::placeholder': {
                    color: '#8d8d8d',
                    opacity: 1
                }
            },
            "& fieldset": {
                border: "1px solid #D9D9D9",
                borderRadius: "2px"
            },
            "&:hover fieldset": {
                border: "1px solid #D9D9D9",
                borderRadius: "2px"
            },
            "&.Mui-focused fieldset": {
                border: "1px solid #D9D9D9",
                borderRadius: "2px"
            },
            "& .MuiInputAdornment-positionStart ~ input": {
                paddingLeft: 0
            },
        },
        "& .MuiInputAdornment-positionStart ~ input": {
            paddingLeft: 0
        },
        "& .MuiFormLabel-root.Mui-focused": {
            color: "#00a857",
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #D9D9D9",
        }
    }
})(TextField);

export default CssTextField;