import * as React from "react"
import Select from "react-select"

import Colors from "../../../theme/colors"
import { ValueType } from "react-select/lib/types"

interface Props {
  options: Array<Option>
  placeholder: string
  onChange: (e: any) => void
  onBlur?: (e: any) => void
  name?: string
  maxWidth?: any
  height?: any
  value?: any
  id?: string
  initialValue?: string
  defaultValue?: ValueType<Option>
  isMulti?: boolean
  select?: any
  required?: boolean
  className?: string
}

interface Option {
  value: string | boolean
  label: string
}

const CustomStyles = (maxWidth: any, height: number, select: any) => {
  return {
    control: (styles: React.CSSProperties) => ({
      ...styles,
      maxWidth: maxWidth && `${maxWidth}px`,
      width: maxWidth && `${maxWidth}px`,
      height: height ? `${height}px` : "40px",
      paddingLeft: "10px",
      paddingRight: "10px",
      borderRadius: "5.5px",
      border: "solid 1.4px rgba(51, 51, 51, 0.2)",
      transition: "250ms linear",
      "&:hover": {
        borderColor: "hsl(0, 0%, 70%)"
      },
      "&:focus": {
        backgroundColor: "#fff",
        border: "solid 1.4px rgba(51, 51, 51, 0.75)",
        outline: "none"
      },
      fontSize: "18px",
      backgroundColor: "#fafafa",
      boxShadow: "none",
      cursor: "grab"
    }),
    option: (base: React.CSSProperties) => ({
      ...base,
      color: Colors.black
    }),
    placeholder: (base: any) => {
      if (select) {
        return {
          ...base,
          letterSpacing: "0.1px",
          opacity: "1",
          fontSize: "16px",
          fontWeight: "500",
          color: "#333333"
        }
      } else {
        return {
          ...base,
          letterSpacing: "0.1px",
          opacity: "0.3",
          fontSize: "16px",
          fontWeight: "500",
          color: "#333333"
        }
      }
    },
    valueContainer: (base: React.CSSProperties) => ({
      ...base,
      paddingLeft: 0
    }),
    indicatorSeparator: (base: React.CSSProperties) => ({
      ...base,
      display: "none"
    }),
    singleValue: (base: any) => ({
      ...base,
      color: Colors.black,
      fontSize: "18px",
      fontWeight: 500
    })
  }
}

const SelectComponent = ({
  options,
  maxWidth,
  placeholder,
  onChange,
  name,
  value,
  height,
  id,
  defaultValue,
  required,
  ...rest
}: Props) => {
  return (
    <Select
      styles={CustomStyles(maxWidth && maxWidth, height && height, rest.select)}
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      name={name}
      id={id}
      setValue={value}
      defaultValue={defaultValue}
      {...rest}
    />
  )
}

export default SelectComponent
