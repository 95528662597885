import auth0 from "auth0-js"
import { client } from "../../apollo"
import AUTHENTICATE from "./authenticate"
import { removeAuth0Id, removeStoredAuthState, setStoredAuthState } from "../../utils/auth-utils"
import AuthenticationState from "./authentication.state"
import { AuthenticateMutation, AuthenticateVariables } from "../../generated/graphql"
import { FetchResult } from "react-apollo"

const webAuth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN!,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  response_type: "token id_token",
  universalLoginPage: true
})

export const loginRequest = async (
  fromLoginOrSignup: boolean,
  showSignUp: boolean,
  authState: AuthenticationState
) => {
  await webAuth.authorize({
    scope: "openid profile email user_metadata app_metadata picture",
    responseType: "token id_token",
    nonce: "1234",
    redirectUri: window.location.origin
  })
}

export const handleAuthentication = async (authState: AuthenticationState) => {
  webAuth.parseHash({ hash: window.location.hash, nonce: "1234" }, async (err, authResult) => {
    if (err) {
      console.error("Error parsing hash:", err)
      return
    }
    if (authResult && authResult.idToken) {
      try {
        const idToken = authResult.idToken
        const authResponse = await authenticateOrCreateWithPrisma({ idToken })
        if (authResponse.data && authResponse.data.authenticate) {
          const user = authResponse.data.authenticate
          setStoredAuthState(idToken, user.id)
          await authState.setUser(user)
          removeAuth0Id()
          if (user.userType === "UNVERIFIED") {
            await authState.setFirstLogin(true)
          } else {
            await authState.setFirstLogin(false)
          }
        }
        window.location.reload()
      } catch (error) {
        console.error("Error during authentication:", error)
      }
    } else {
      console.error("Authentication failed:", err)
    }
  })
}

export const logoutHandler = async () => {
  webAuth.logout({
    returnTo: window.location.origin,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID!
  })
await window.localStorage.clear()
await removeStoredAuthState()
  
}

const authenticateOrCreateWithPrisma = (
  variables: AuthenticateVariables
): Promise<FetchResult<AuthenticateMutation>> | any => {
  return client.mutate({
    mutation: AUTHENTICATE,
    variables
  })
}
