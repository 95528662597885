import gql from "graphql-tag"

export const SEND_EMAIL = gql`
  query inquiryToLandlord($data: EmailBody!) {
    inquiryToLandlord(data: $data) {
      statusCode
    }
  }
`

export const REQUEST_LISITNG_COUNT = gql`
  query requestLisitngCount {
    requestLisitngCount {
      count
    }
  }
`

export const GET_LISTINGS = gql`
  query requestedListings($first: Int, $skip: Int) {
    requestLisitngCount {
      count
    }
    requestedListings(first: $first, skip: $skip) {
      id
      title
      createdAt
      updatedAt
      isPremium
      premiumName
      user {
        firstName
        lastName
      }
      units {
        id
        title
      }
      location {
        id
        neighbourHood {
          id
          city {
            id
            name
          }
        }
      }
    }
  }
`
