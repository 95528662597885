// import { StepResponseType } from "../../../screens/building/types/index"

function parseStep4({ units, number }) {
  let unit = units[0]
  return {
    leaseTerm: unit?.leaseTerm,
    parking: unit?.parking,
    picture: unit?.pictures,
    number: unit?.number,
    parkingSpots: unit?.parkingSpots
  }
}

export { parseStep4 }
