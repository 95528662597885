import React, { useEffect, useState } from "react"
import PriceDecimalField from "src/components/FormComponents/Field/price-input-decimal"

const PriceDecimalWrapper = ({ form, ...props }) => {
  const [required, setRequired] = useState(false)

  useEffect(() => {
    if (form?.values && !form?.values?.priceEnquiry) {
      setRequired(true)
    }

    if (form?.values && form?.values?.priceEnquiry) {
      setRequired(false)
    }
  }, [form])

  return (
    <div>
      <PriceDecimalField
        form={form}
        {...props}
        required={required}
        disabled={!required}
        placeholder={required ? props?.placeholder : null}
        styleInput={{ cursor: required ? "auto" : "not-allowed" }}
      />
    </div>
  )
}

export default PriceDecimalWrapper
