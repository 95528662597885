import { Button, notification } from "antd"
import React from "react"

const close = () => {}

const openNotification = () => {
  const key = `open${Date.now()}`
  const btn = (
    <Button type="text" size="small" onClick={() => notification.close(key)}>
      Confirm
    </Button>
  )
  notification.open({
    message: "",
    description:
      "Congratulations! One of our team members will reach out to you shortly to introduce themselves and to explain how our process works. Please make sure you have the most updated email and phone number on your profile. Thank you!",
    btn,
    key,
    onClose: close,
    duration: 0
  })
}

export default openNotification
