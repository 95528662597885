import colors from "./colors"
const breakpoints = ["40em", "64em", "90em"]

// Typographic Scale (numbers are converted to px values)
const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72]
const lineHeights = [18, 22, 26, 34, 40, 50]

// Spacing Scale (used for margin and padding)
const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]

export const Colors = colors

const Indextheam=  {
  breakpoints,
  fontSizes,
  lineHeights,
  space,
  colors: Colors
}

export default Indextheam;
