import React, { useEffect } from "react"
import { Radio, RadioChangeEvent } from "antd"
import { FormControl } from "@material-ui/core"

const PriceRadioButton = ({ form, field, ...rest }) => {
  const name = field?.name
  const edit = rest?.edit
  const initials = form?.initialValues
  const setFieldValue = form?.setFieldValue

  const onChange = (e: RadioChangeEvent) => {
    const { value } = e.target
    if (value) {
      setFieldValue("monthlyPrice", "")
      setFieldValue("deposit", "")
    }

    setFieldValue(name, value)
  }

  useEffect(() => {
    if (!!edit) {
      const value = initials?.monthlyPrice
      if (value === 0 || value === "" || !value) setFieldValue(name, true)
    } else {
      setFieldValue(name, false)
    }
  }, [initials, name, edit, setFieldValue])

  return (
    <FormControl id="signup-profile-select" style={{ marginBottom: 15 }} fullWidth>
      <Radio.Group {...field} onChange={onChange}>
        <Radio value={false}>Price</Radio>
        <Radio value={true}>Contact for Pricing!</Radio>
      </Radio.Group>
    </FormControl>
  )
}

export default PriceRadioButton
