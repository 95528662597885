import gql from "graphql-tag"

export const GET_SEARCH_ADDRESS = gql`
  query searchHistories($skip: Int, $take: Int) {
    countSearchHistory
    searchHistories(skip: $skip, take: $take) {
      id
      location {
        id
        address
      }
      user {
        id
        firstName
        lastName
        avatarUrl
        gender
        email
        city
        phone
        occupation
        zip_code
        userType
      }
      createdAt
    }
  }
`

export const GET_COUNT_HISTORY = gql`
  query countSearchHistory {
    countSearchHistory
  }
`
