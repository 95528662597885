import React from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Backdrop } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > * + *": {
        marginLeft: theme.spacing(2)
      }
    },
    loaderAlign: {
      zIndex: 1000,
      position: "fixed",
      width: "100%",
      height: "100%",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#b5b5b538"
    },
    blockPage: {
      background: '#fff'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#00a857',
    },
    muiLoaderContainer: {
      textAlign: 'center',
      "& div:last-child": {
        marginTop: '8px'
      }
    }
  })
)

interface Props {
  loader: boolean,
  blockPage?: boolean
}

export default function Loader({ loader, blockPage }: Props) {
  const classes = useStyles()
  return loader ? (
    <div className={`${classes.loaderAlign} ${blockPage ? classes.blockPage : ''}`}>
      <div className={classes.root}>
        <CircularProgress />
      </div>
    </div>
  ) : null
}


export const MuiLoader = (props: MuiLoaderProps) => {
  const { isLoading, text } = props;
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={isLoading}  >
      <div className={classes.muiLoaderContainer}>
        <CircularProgress color="inherit" />
        {text && <div style={{ color: "#fff" }}>{text}</div>}
      </div>
    </Backdrop>
  )
}

interface MuiLoaderProps {
  isLoading: boolean;
  text?: string;
}