import { FetchRecommendedListingsDocument, FetchAllListings } from "src/services/graphql"
import { REORDER_PIC } from "../graphql/listing"

export const fetchRecommandedListAPI = async (client, variables) => {
  try {
    const resp = await client.query({
      query: FetchRecommendedListingsDocument,
      variables: variables
    })
    return resp
  } catch (error) {
    console.log("Error", error)
  }
}

export const fetchListingsDatas = async client => {
  try {
    return await client.query({
      query: FetchAllListings
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const picReorder = async (req, client) => {
  try {
    return await client.mutate({
      mutation: REORDER_PIC,
      variables: {
        where: { reorderPic: req }
      }
    })
  } catch (err) {
    console.log("Error", err)
    return err
  }
}

