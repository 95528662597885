import React, { useEffect } from "react"
import FooterComponent from "../../components/footer"
import ReactHelmet from "src/components/react-helmet"

const McgregorParkTownhomes = () => {
  useEffect(() => {
    try {
      var script = document.createElement("script")
      script.src = "https://paperform.co/__embed"
      script.async = true
      document.body.appendChild(script)
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <>
      <ReactHelmet
        url="mcgregor-park-townhomes"
        title={`Tolobi - McGregor Park Townhomes`}
        description="Tolobi - Rental Made Easy"
      />
      <div data-paperform-id="tolobi-mcgregor-park"></div>
      <FooterComponent />
    </>
  )
}

export default McgregorParkTownhomes
