import TolobiLoader from "src/components/tolobi-loader"
import Loadable from "react-loadable"

export const Home = Loadable({
  loader: () => import("src/containers/home") as any,
  loading: TolobiLoader as any
})

export const ConfirmPassword = Loadable({
  loader: () => import("src/containers/confirm-password") as any,
  loading: TolobiLoader as any
})

export const Search = Loadable({
  loader: () => import("src/containers/search") as any,
  loading: TolobiLoader as any
})
export const HelpCenter = Loadable({
  loader: () => import("src/containers/help-center") as any,
  loading: TolobiLoader as any
})

export const HelpColllection = Loadable({
  loader: () => import("src/containers/help-center-collection") as any,
  loading: TolobiLoader as any
})

export const Leases = Loadable({
  loader: () => import("src/containers/leases") as any,
  loading: TolobiLoader as any
})

export const Help = Loadable({
  loader: () => import("src/containers/help") as any,
  loading: TolobiLoader as any
})

export const SingleHelpArticle = Loadable({
  loader: () => import("src/containers/single-help-article") as any,
  loading: TolobiLoader as any
})

export const SingleListing = Loadable({
  loader: () => import("src/containers/single-listing") as any,
  loading: TolobiLoader as any
})

export const SignupModal = Loadable({
  loader: () => import("src/containers/signup-modal") as any,
  loading: TolobiLoader as any
})

export const PreViewingFormBeta = Loadable({
  loader: () => import("src/containers/pre-viewing-from") as any,
  loading: TolobiLoader as any
})

export const FilterListing = Loadable({
  loader: () => import("src/containers/filter-listing-on-pre-viewing") as any,
  loading: TolobiLoader as any
})

export const Booking = Loadable({
  loader: () => import("src/containers/booking"),
  loading: TolobiLoader as any
})

export const Pricing = Loadable({
  loader: () => import("src/containers/pricing"),
  loading: TolobiLoader as any
})

export const TermPolicy = Loadable({
  loader: () => import("src/containers/term-policy"),
  loading: TolobiLoader as any
})

export const TermService = Loadable({
  loader: () => import("src/containers/term-service") as any,
  loading: TolobiLoader as any
})

export const Tenant = Loadable({
  loader: () => import("src/containers/tenant") as any,
  loading: TolobiLoader as any
})

export const Dashboard = Loadable({
  loader: () => import("src/dashboards/screens/dashboard") as any,
  loading: TolobiLoader as any
})

export const Building = Loadable({
  loader: () => import("src/dashboards/screens/landlord-access-building") as any,
  loading: TolobiLoader as any
})

export const AdminBuilding = Loadable({
  loader: () => import("src/dashboards/screens/admin-access-building") as any,
  loading: TolobiLoader as any
})

export const Users = Loadable({
  loader: () => import("src/dashboards/screens/users") as any,
  loading: TolobiLoader as any
})

export const BoostListing = Loadable({
  loader: () => import("src/dashboards/screens/boostLisitng") as any,
  loading: TolobiLoader as any
})
export const InternalListing = Loadable({
  loader: () => import("src/dashboards/screens/internalListing") as any,
  loading: TolobiLoader as any
})
export const SearchHistory = Loadable({
  loader: () => import("src/dashboards/screens/search-history/index") as any,
  loading: TolobiLoader as any
})

export const UserSearchProfile = Loadable({
  loader: () => import("src/dashboards/screens/search-history/user-profile") as any,
  loading: TolobiLoader as any
})
export const RentalLawsListing = Loadable({
  loader: () => import("src/dashboards/screens/rental-laws") as any,
  loading: TolobiLoader as any
})

export const Units = Loadable({
  loader: () => import("src/dashboards/screens/units") as any,
  loading: TolobiLoader as any
})

export const Articles = Loadable({
  loader: () => import("src/dashboards/screens/article") as any,
  loading: TolobiLoader as any
})

export const Plans = Loadable({
  loader: () => import("src/dashboards/screens/plans") as any,
  loading: TolobiLoader as any
})

export const Features = Loadable({
  loader: () => import("src/dashboards/screens/features") as any,
  loading: TolobiLoader as any
})

export const AdminHelpCenter = Loadable({
  loader: () => import("src/dashboards/screens/help-center") as any,
  loading: TolobiLoader as any
})
export const DataGridTable = Loadable({
  loader: () => import("src/dashboards/screens/rental-application-table") as any,
  loading: TolobiLoader as any
})

export const DataGridTableView = Loadable({
  loader: () => import("src/dashboards/screens/datagrid-table-view") as any,
  loading: TolobiLoader as any
})

export const EMailConfiguration = Loadable({
  loader: () => import("src/dashboards/screens/e-mail-configuration") as any,
  loading: TolobiLoader as any
})

export const EMailConfigurationEdit = Loadable({
  loader: () => import("src/dashboards/screens/e-mail-configuration/edit-mail") as any,
  loading: TolobiLoader as any
})

export const RequestedListing = Loadable({
  loader: () => import("src/dashboards/screens/requestListing") as any,
  loading: TolobiLoader as any
})

export const PendingListing = Loadable({
  loader: () => import("src/dashboards/screens/singleUserListing") as any,
  loading: TolobiLoader as any
})

export const UserProfile = Loadable({
  loader: () => import("src/dashboards/screens/user-profile") as any,
  loading: TolobiLoader as any
})

export const ListingPerRoom = Loadable({
  loader: () => import("src/dashboards/screens/listingPerRoom") as any,
  loading: TolobiLoader as any
})

export const ReportRedirect = Loadable({
  loader: () => import("src/containers/report-redirect") as any,
  loading: TolobiLoader as any
})

export const PremiumRedirect = Loadable({
  loader: () => import("src/containers/premium-redirect") as any,
  loading: TolobiLoader as any
})

export const LandlordPremiumRedirect = Loadable({
  loader: () => import("src/containers/landlord-premium-redirect/landlord-premium") as any,
  loading: TolobiLoader as any
})

export const Message = Loadable({
  loader: () => import("src/dashboards/screens/messages") as any,
  loading: TolobiLoader as any
})

export const AdminInbox = Loadable({
  loader: () => import("src/dashboards/screens/adminInbox") as any,
  loading: TolobiLoader as any
})

export const PerListingMessage = Loadable({
  loader: () => import("src/dashboards/screens/listingPerRoom") as any,
  loading: TolobiLoader as any
})

export const ServicesPage = Loadable({
  loader: () => import("src/containers/services") as any,
  loading: TolobiLoader as any
})

export const Blogs = Loadable({
  loader: () => import("src/containers/blogs") as any,
  loading: TolobiLoader as any
})

export const KnowledgeCenter = Loadable({
  loader: () => import("src/containers/knowledge-center/index") as any,
  loading: TolobiLoader as any
})
export const RentalLaws = Loadable({
  loader: () => import("src/containers/rental-laws/rental-laws") as any,
  loading: TolobiLoader as any
})

export const RentalEnquiry = Loadable({
  loader: () => import("src/containers/rental-enquiry/rental-enquiry") as any,
  loading: TolobiLoader as any
})

export const RentalCollection = Loadable({
  loader: () => import("src/containers/rental-law-article/index") as any,
  loading: TolobiLoader as any
})
export const RentalCollectionSingle = Loadable({
  loader: () => import("src/containers/rental-law-collection") as any,
  loading: TolobiLoader as any
})
export const SingleRentalLaw = Loadable({
  loader: () => import("src/containers/single-rental-law") as any,
  loading: TolobiLoader as any
})

export const RssFeed = Loadable({
  loader: () => import("src/components/rss-feed") as any,
  loading: TolobiLoader as any
})

export const BlogsDetail = Loadable({
  loader: () => import("src/containers/single-blog") as any,
  loading: TolobiLoader as any
})

export const AdminBlog = Loadable({
  loader: () => import("src/dashboards/screens/blogs") as any,
  loading: TolobiLoader as any
})

export const AdminHelpGroup = Loadable({
  loader: () => import("src/dashboards/screens/help-center-group") as any,
  loading: TolobiLoader as any
})

export const AdminRentalLawGroup = Loadable({
  loader: () => import("src/dashboards/screens/rental-law-group/index") as any,
  loading: TolobiLoader as any
})

export const CreateBlog = Loadable({
  loader: () => import("src/dashboards/screens/create-blog") as any,
  loading: TolobiLoader as any
})

export const UserPermission = Loadable({
  loader: () => import("src/super-admin/users") as any,
  loading: TolobiLoader as any
})

export const UserPermissionForm = Loadable({
  loader: () => import("src/super-admin/permission-form") as any,
  loading: TolobiLoader as any
})

export const LeaseForm = Loadable({
  loader: () => import("src/dashboards/screens/lease-form") as any,
  loading: TolobiLoader as any
})

export const LeaseDetailsTable = Loadable({
  loader: () => import("src/dashboards/screens/lease-details-table") as any,
  loading: TolobiLoader as any
})
