import styled from "styled-components"

import Text from "../text"

export const MenuLink = styled(Text as any)<any>`
  font-size: 24px;
  margin-bottom: 32px;
  font-weight: 600;
  cursor: pointer;
  ${({ small }) =>
    small &&
    `
    font-weight: 500;
    font-size: 16px;
  `};
`

export const adminLinks = [
  {
    name: "Messages",
    link: "/message"
  },
  {
    name: "Dashboard",
    // (Beta)
    link: "/dashboards"
  },
  {
    name: "Properties",
    link: "/listings"
  },
  {
    name: "Requested Properties",
    link: "/requestedListing"
  },
  {
    name: "Boost Properties",
    link: "/boostListing"
  },
  {
    name: "Users",
    link: "/users"
  },
  {
    name: "Plans",
    link: "/plans"
  },
  {
    name: "Articles",
    link: "/articles"
  },
  {
    name: "Features",
    link: "/features"
  },
  {
    name: "Blogs",
    link: "/admin-blogs"
  }
]
