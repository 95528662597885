import gql from "graphql-tag"

export const SEARCH_BUILDING = gql`
  query searchBuilding($search: String!, $skip: Int, $take: Int) {
    listingSearchCount(search: $search) {
      count
    }
    searchBuilding(search: $search, skip: $skip, take: $take) {
      id
      title
      seen
      role
      createdAt
      isHidden
      activate
      isapproved
      updatedAt
      premiumStart
      premiumEnd
      premiumName
      user {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
      units {
        id
        title
        number
      }
      location {
        id
        neighbourHood {
          id
          city {
            id
            name
          }
        }
      }
    }
  }
`
export const GET_BUILDING_BY_ID = gql`
  query getSingleBuilding($id: String!) {
    getSingleBuilding(id: $id) {
      id
      title
      role
      moveInBonuses
      description
      buildingConstructionYear
      totalUnitsInBuilding
      storiesInBuilding
      developmentCompany
      propertyType
      subscriptionId
      isPremium
      premiumName
      premiumStart
      premiumEnd
      planPrice
      propertyRealtor
      partnershipStatus
      spousePartner
      lockbox
      commission
      region
      isBasicFree
      isViewManagement
      isConcierge
      isRentalAssistance
      isLeadGeneartor
      govtProof{
        id
        filename
        url
      }
      ownershipProof
      signature{
        id
        url
      }
      plan{
        id
        name
        price
        unit
        price_id
      }
      user{
        id
        userType
      }
      subscriptions{
        id
        plan_name
        plan_group
        plan_price
        plan_id
        plan_unit
        plan_type
      }
      advertiseUnit
      existingUrl
      advertiseDuration
      currentlyTenanted
      desiredRentalPrice
      minimumRentalPrice
      extraInfo
      showingUnit
      customiseLease
      basement
      location {
        id
        lat
        lng
        address
        tags {
          id
          students
          closeToTransit
          purposeBuiltRentals
          nightlife
          parksAndWater
          luxuryRentals
          shortTermRentals
          professionallyManaged
          onlinePayments
          onlineApplications
          furnishedRentals
          petFriendly
        }
        neighbourHood {
          id
        }
      }
      pet {
        id
        cat
        dog
        other
        none
      }
      utilities {
        id
        electricity
        heating
        airConditioning
        water
        garbageRemoval
        cableTv
        internet
        maintenanceFees
        gas
      }
      tenantRequirements {
        id
        exceptionalCredit
        payStub
        letterOfEmployment
        photoId
        pastReferences
        noSmoking
        extraDocumentation
      }
      pictures {
        id
        filename
        url
      }
      amenities {
        id
        onSiteLaundry
        yard
        concierge
        elevator
        dryCleaning
        gym
        swimmingPool
        storage
        guestSuites
        beanfieldWifi
        publicWifi
        bbqPermitted
        visitorsParking
        partyRoom
        bikeStorage
        wheelchairAccess
        rooftopDeck
        hotTub
        sauna
        gameRoom
        theatre
        businessCenter
        communalGarden
        petSpa
        mediaRoom
        basketballCourt
        yogaRoom
        tennisCourt
        rooftopTerrace
        coworkingSpace
        conferenceRoom
        patio
        lounge
      }
      units {
        id
        title
        number
        den
        available
        startDateAvailability
        unitSize
        bedrooms
        monthlyPrice
        bathrooms
        floorplan
        furnished
        parkingSpots
        storage
      maintenance
      smokeAlarm
        pictures {
          id
          filename
          url
          reorderPic
        }
        deposit
        leaseTerm
        parking
        unitAmenities {
          id
          ac
          centralHeat
          electricalHeating
          radiatorHeating
          fireplace
          carpet
          ceilingFan
          hardwoodFloor
          walkInCloset
          highCeilings
          bathroomFan
          balcony
        }
        unitAppliances {
          id
          fridge
          stove
          rangehood
          dishwasher
          microwave
          inunitwasher
          inunitdryer
          wineFridge
          dehumidifier
          firePlaceElectric
          firePlaceGas
          jacuzzi
        }
      }
    }
  }
`
export const GET_AVAILABLE_LISTINGS = gql`
  query adminAvailableListing($first: Int, $skip: Int, $isHidden: Boolean) {
    adminAvailableListing(first: $first, skip: $skip, isHidden: $isHidden) {
      id
      title
      premiumName
      role
      isHidden
      activate
      premiumStart
      seen
      premiumEnd
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
      }
      units {
        id
        title
      }
      location {
        id
        neighbourHood {
          id
          city {
            id
            name
          }
        }
      }
    }
  }
`
export const GET_UNAVAILABLE_LISTINGS = gql`
  query adminUnavailableListing($first: Int, $skip: Int) {
    adminUnavailableListing(first: $first, skip: $skip) {
      id
      title
      role
      premiumName
      premiumStart
      premiumEnd
      isHidden
      seen
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
      }
      units {
        id
        title
      }
      location {
        id
        neighbourHood {
          id
          city {
            id
            name
          }
        }
      }
    }
  }
`
export const GET_LISTINGS = gql`
  query adminAccessListing($first: Int, $skip: Int) {
    adminTotalListing
    activeLisitng {
      count
    }
    adminAccessListing(first: $first, skip: $skip) {
      title
      id
      role
      isHidden
      activate
      seen
      createdAt
      updatedAt
      premiumName
      premiumStart
      premiumEnd
      region
      listingEnquiry{
        id
        toAddress
        ccAddress
        bccAddress
        attachments
      }
      bookings{
        id
        updatedAt
        seeker{
          id
          email
        }
      }
      user {
        id
        firstName
        lastName
        email
      }
      updatedBy {
        id
        firstName
        lastName
      }
      units {
        id
        title
      }
      location {
        id
        address
        neighbourHood {
          id
          name
          city {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_PREMIUM_TYPE = gql`
  query getPremiumType($where: PremiumWhereInput!) {
    getPremiumType(where: $where) {
      id
      value
      name
    }
  }
`


export const GET_LISTING_ENQUIRY = gql`
  query getListingEnquiry($where: listingEnquiryArgs!) {
    getListingEnquiry(where: $where) {
      id
      fromAddress
      attachments
      bookingId
      userId
      ccAddress
      bccAddress
      isMasked
      toAddress
    }
  }
`