import React, { useState, ChangeEvent, useEffect, useRef } from "react"
import { Button } from "@material-ui/core"
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined"
import AttachmentIcon from "@material-ui/icons/Attachment"
import styles from "./styles.module.scss"

const NativeFileUpload: React.FC<UploadFileProps> = ({
    onUpload,
    onRemove,
    defaultFiles = [],
    maxFiles = 1,
    acceptedFiles = ".jpg,.jpeg,.png,.pdf"
}) => {
    const [files, setFiles] = useState<File[]>([])
    const [previews, setPreviews] = useState<NativeFileType[]>([])
    const fileInputRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        setPreviews(defaultFiles || [])
    }, [JSON.stringify(defaultFiles)])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files

        if (!fileList) {
            return
        }

        const newFiles = Array.from(fileList)
        const remaining = maxFiles - files.length
        const sliced = newFiles.slice(0, remaining)
        setFiles([...files, ...sliced])

        const promises = sliced.map(file => {
            return new Promise<NativeFileType>(resolve => {
                const reader = new FileReader()
                reader.onload = () => {
                    resolve({ filename: file.name, base64: (reader.result || "") as string })
                }
                reader.readAsDataURL(file)
            })
        })

        Promise.all(promises).then(results => {
            const values = [...previews, ...results]
            setPreviews(values)
            onUpload(values)
        })
    }

    const handleRemove = (index: number) => {
        const newFiles = [...files].filter((_, idx) => idx !== index)
        const newPreviews = [...previews].filter((_, idx) => idx !== index)
        setFiles(newFiles)
        setPreviews(newPreviews)
        const removeCallBackValue = newPreviews.map((item, idx) => ({
            filename: item.filename,
            base64: newPreviews[idx]?.base64
        }))
        onRemove(removeCallBackValue)
    }

    const isFileAnImage = (fileName: string) => {
        const extension = fileName.split(".").pop() || ""
        if (["jpeg", "png", "jpg", "webp"].includes(extension)) {
            return true
        }
        return false
    }

    return (
        <div className={styles.fileUpload__container}>
            <div className={styles.preview__container}>
                {previews.map((preview, index) => (
                    <div key={index} className={styles.file}>
                        {isFileAnImage(preview.filename) ? (
                            <img src={preview.base64} alt="Preview" />
                        ) : (
                            <p className={styles.pdf__preview}>File</p>
                        )}
                        <p className={styles.fileName}>{preview.filename || ""}</p>
                        <DeleteOutlineOutlinedIcon
                            onClick={() => handleRemove(index)}
                            className={styles.remove__icon}
                            fontSize="small"
                        />
                    </div>
                ))}
            </div>
            <div className={styles.button__container}>
                <button
                    onClick={() => fileInputRef.current && fileInputRef.current.click()}
                    className={styles.choose__btn}
                >
                    Choose file
                </button>
                <input
                    type="file"
                    id="native-file-upload-btn"
                    accept={acceptedFiles}
                    multiple
                    onChange={handleChange}
                    disabled={files.length >= maxFiles}
                    hidden
                    ref={fileInputRef}
                />
            </div>
            {files.length >= maxFiles && (
                <div className={styles.error}>Maximum number of files ({maxFiles})</div>
            )}
        </div>
    )
}

export default NativeFileUpload

export const SimpleFileUpload = (props: SimpleFileUploadProps) => {
    const {
        files,
        handleFileChange,
        labelName = "Add Attachment",
        containerProps = {},
        defaultFileURLs,
        handleDefaultAttachmentsChange = () => { },
        listTitle = "Attachments",
        maxFiles = 2,
        uploadButtonContainerProps = {},
        acceptedFiles = "*"
    } = props

    const fileInputRef = useRef<HTMLInputElement | null>(null)

    const filesArr = files || []

    const getFileExtension = (fileName?: string) => {
        const fileExtension = fileName?.split('.')?.pop();
        return fileExtension
    }

    const isFileAnImage = (extension?: string) => {
        if (["jpeg", "png", "jpg", "webp"].includes(extension || '')) {
            return true
        }
        return false
    }

    return (
        <div className={styles.simpleFileUpload__container} {...containerProps}>
            <div
                className={styles.button__container}
                {...uploadButtonContainerProps}
            >
                <Button
                    onClick={() => fileInputRef.current && fileInputRef.current.click()}
                    className={styles.choose__btn}
                    startIcon={<AttachmentIcon className={styles.attachment__icon} />}
                    disabled={filesArr.length >= maxFiles}
                >
                    {labelName}
                </Button>
                <input
                    type="file"
                    multiple
                    onChange={(e) => {
                        const filterFiles = ((e.target.files || []) as FileList[])
                        const result = [...filesArr, ...filterFiles].slice(0, maxFiles)
                        handleFileChange(result)
                    }}
                    hidden
                    disabled={filesArr.length >= maxFiles}
                    ref={fileInputRef}
                    accept={acceptedFiles}
                />
            </div>
            <div className={styles.attachments__container}>
                {((filesArr.length > 0 || ((defaultFileURLs?.length || 0) > 0)) && !!listTitle) && <p className={styles.heading}>{listTitle}</p>}
                {filesArr.length > 0 && (
                    <div className={styles.fileList__container}>
                        {filesArr.map((file: any, index) => (
                            <div className={styles.file__container} key={index}>
                                {isFileAnImage(
                                    getFileExtension(file.name)
                                ) ? <img src={window.URL.createObjectURL(file)} /> : (<p className={styles.file__preview}>{getFileExtension(file.name)}</p>)}
                                <p className={styles.filename} key={index}>
                                    {file.name}
                                </p>
                                <DeleteOutlineOutlinedIcon
                                    className={styles.delete__icon}
                                    onClick={() => {
                                        const filteredFiles = filesArr?.filter((_, idx) => idx !== index) || []
                                        handleFileChange(filteredFiles)
                                    }} />
                            </div>
                        ))}
                    </div>
                )}
                {(defaultFileURLs?.length || 0) > 0 && (
                    <div className={styles.externalURLs__container}>
                        {defaultFileURLs?.map((url, index) => (
                            <div className={styles.file__container} key={index}>
                                {
                                    isFileAnImage(
                                        getFileExtension(url)
                                    )
                                        ? <img src={url} />
                                        : (<p className={styles.file__preview}>{getFileExtension(url)}</p>)
                                }
                                <a href={url} target="_blank" className={styles.filename} key={index}>
                                    {url.split("/").pop()}
                                </a>
                                <DeleteOutlineOutlinedIcon className={styles.delete__icon} onClick={() => {
                                    const filteredFiles = defaultFileURLs.filter((_, idx) => idx != index);
                                    handleDefaultAttachmentsChange(filteredFiles);
                                }} />
                            </div>
                        ))}
                    </div>
                )}
                {filesArr.length >= maxFiles && (
                    <div className={styles.max__error}>Maximum number of files ({maxFiles}) reached</div>
                )}
            </div>
        </div>
    )
}

interface SimpleFileUploadProps {
    labelName?: string;
    containerProps?: any;
    handleFileChange: any;
    files: any[] | null;
    defaultFileURLs?: string[];
    handleDefaultAttachmentsChange?: any;
    listTitle?: string;
    maxFiles?: number;
    uploadButtonContainerProps?: any;
    acceptedFiles?: string;
}

export interface NativeFileType {
    filename: string
    base64: string
}

interface UploadFileProps {
    onUpload: (files: NativeFileType[]) => void
    onRemove: (files: NativeFileType[]) => void
    defaultFiles?: NativeFileType[]
    maxFiles?: number
    acceptedFiles?: string
}
