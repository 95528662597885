import React from "react"
import Form from "../../../components/FormComponents";
import { unitfields2 } from "./fields"

interface Props {
  actionBtn: any;
  onSubmit: (payload: any, resetForm: any) => void;
  initialValues: { [x: string]: any };
  onFormChange2?:()=>void
}

const SecondUnitForm = React.memo(function SecondUnitForm({ actionBtn,onFormChange2, onSubmit, initialValues }: Props) {

  const onFormChange=()=>{
    if(onFormChange2!== undefined)
      onFormChange2()
  }
  return (
    <Form
      fields={unitfields2}
      initialValues={initialValues}
      btnText={"submit"}
      onSubmit={onSubmit}
      renderCustomSubmit={actionBtn}
      onFormChange={onFormChange}
      giveButtonsFormikActions
    />

  )
})

export default SecondUnitForm
