import { notification } from "antd"

const close = () => {}

// type NotificationType = "success" | "info" | "warning" | "error"

export const notify = (type, message: string, description?: string) => {
  const key = `open${Date.now()}`
  notification[type]({
    message,
    description,
    key,
    onClose: close
  })
}
