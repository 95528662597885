import { CREATE_ARTICLES, DELETE_ARTICLE, DELETE_ARTICLE_TAG, UPDATE_ARTICLE, UPDATE_ARTICLE_TAG, ARTICLE_TAGS } from "src/services/graphql"
import { GET_ARTICLES, GET_COUNT_ARTICLE, GET_SINGLE_ARTICLE, SEARCH_ARTICLES_LIST, SEARCH_ARTICLE_TAG } from "src/services/graphql"

export const fetchArticles = async (client, skip, take) => {
  return await client.query({
    query: GET_ARTICLES,
    fetchPolicy: "network-only",
    variables: {
      where: {
        skip,
        take,
      }
    }
  })
}
export const fetchTotalArticles = async client => {
  return await client.query({
    query: GET_COUNT_ARTICLE
  })
}

export const deleteArticles = async (client, id) => {
  return await client.mutate({
    mutation: DELETE_ARTICLE,
    variables: {
      id: id
    }
  })
}

export const fetchSingleArticle = async (client, id) => {
  return await client.query({
    query: GET_SINGLE_ARTICLE,
    variables: {
      id: id
    }
  })
}

export const createArticle = async (reqData, client) => {
  return await client.mutate({
    mutation: CREATE_ARTICLES,
    variables: {
      where: {
        ...reqData
      }
    }
  })
}

export const updateArticle = async (reqData, id, client) => {
  return await client.mutate({
    mutation: UPDATE_ARTICLE,
    variables: {
      where: {
        id,
        ...reqData
      }
    },
  })
}
export const fetchSearchArticlesList = async (client, title) => {
  try {
    return await client.query({
      query: SEARCH_ARTICLES_LIST,
      variables: {
        where: {
          title,
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const fetchArticleTags = async (client) => {
  try {
    return await client.query({
      query: ARTICLE_TAGS
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const deleteArticleTags = async (client, id) => {
  try {
    return await client.mutate({
      mutation: DELETE_ARTICLE_TAG,
      variables: {
        where: {
          id
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const updateArticleTags = async (
  client,
  id,
  tagValue,
) => {
  try {
    return await client.mutate({
      mutation: UPDATE_ARTICLE_TAG,
      variables: {
        where: {
          id,
          title: tagValue
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}

export const searchArticleTags = async (client, name) => {
  try {
    return await client.query({
      query: SEARCH_ARTICLE_TAG,
      variables: {
        where: {
          name
        }
      }
    })
  } catch (error) {
    console.log("Error", error)
    return error
  }
}
