import React from "react"
import styled from "styled-components"

interface Props {
  src: any
  width?: number
  height?: number
}

const Image = styled.img<any>`
  width: ${props => (props.width ? props.width : 20)}px;
  height: ${props => (props.height ? props.height : 20)}px;
  &:hover {
    cursor: pointer;
  }
  cursor: pointer;
  margin-inline: 5px;
`

const ImageIcons = (props: Props) => {
  const { src } = props
  return <Image src={src} alt="" loading="lazy" />
}

export default ImageIcons
