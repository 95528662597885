// import PriceField from "src/components/FormComponents/Field/pricing-input-field"
import { FieldType } from "src/components/FormComponents/types"
import YearPicker from "src/components/FormComponents/year-picker"
import PicturePicker from "src/components/FormComponents/picture-picker"
import SelectMulti from "src/components/FormComponents/select-multi"
import SearchMapAdd from "src/components/FormComponents/mapBox-address"
import FloorPlanUpload from "src/components/FormComponents/pdf-uploader"
import DatePick from "src/components/FormComponents/year-picker/date-picker"
import {
  UTILITIES,
  TAGS,
  PETS,
  AMENITIES,
  UNIT_AMENITIES,
  UNIT_APPLIANCES,
  TENANT_REQUIREMENTS,
  BEDROOMS,
  PROPERTY_TYPE,
  AVAILABLE,
  PARKING,
  DEN,
  BASEMENT,
  SMOKE_ALARM,
  MAINTENANCE,
  STORAGE
} from "src/dashboards/components/constants"
import Select from "src/components/FormComponents/select"
import Field from "src/components/FormComponents/Field/signup-profile-Field"
import TextArea from "src/components/FormComponents/textArea/decriptionText"
import DropDown from "src/components/FormComponents/select/dropdown"
import PriceDecimalField from "src/components/FormComponents/Field/price-input-decimal"
import { BATHROOMS } from "../constants/constants"
const FURNISHED = [
  { value: true, label: "Yes, furnished" },
  { value: false, label: "No, unfurnished" }
]

const fields: FieldType[] = [
  {
    labelTxt: "Property Type",
    name: "propertyType",
    placeholder: "Select",
    type: "selects",
    component: Select,
    required: true,
    grid: 12,
    options: PROPERTY_TYPE
  },
  {
    labelTxt: "Address",
    name: "address",
    placeholder: "Address",
    type: "text",
    required: true,
    component: SearchMapAdd,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Listing Title",
    name: "buildingTitle",
    placeholder: "Title",
    type: "text",
    required: true,
    component: Field,
    styleInput: {
      maxWidth: "100%"
    }
  },
  // {
  //   labelTxt: "Property Description",
  //   name: "des",
  //   placeholder: "Description",
  //   type: "text",
  //   component: TextArea,
  //   multiline: true,
  //   rows: 4,
  //   required: true,
  //   styleInput: {
  //     maxWidth: "100%",
  //     maxHeight: "15vh"
  //   }
  // },
  {
    labelTxt: "Number of Units in the Building",
    name: "units",
    placeholder: "Number of Units in the Building",
    type: "number",
    component: Field,
    required: false,
    grid: 4,
    rows: 4,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Number of Floors in the Building",
    name: "floors",
    placeholder: "Number of Floors in the Building",
    type: "number",
    required: false,
    component: Field,
    grid: 4,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Year of Construction",
    name: "contractions",
    placeholder: "Year of Construction",
    type: "number",
    required: false,
    component: YearPicker,
    grid: 4,
    styleInput: {
      maxWidth: "100%"
    }
  }
]

const fields2: FieldType[] = [
  {
    labelTxt: "Utilities",
    name: "utilities",
    placeholder: "Utilities",
    type: "select-multi",
    required: false,
    component: SelectMulti,
    grid: 4,
    options: UTILITIES,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Amenities",
    name: "amenities",
    placeholder: "Amenities",
    type: "select-multi",
    component: SelectMulti,
    required: false,
    grid: 4,
    options: AMENITIES
  },
  {
    labelTxt: "Pets Allowed",
    name: "pets",
    placeholder: "Pets Allowed",
    type: "selects",
    component: SelectMulti,
    required: false,
    grid: 4,
    options: PETS
  },
  {
    labelTxt: "Tags",
    name: "tags",
    placeholder: "Tags",
    type: "select-multi",
    required: false,
    component: SelectMulti,
    grid: 4,
    options: TAGS
  },
  {
    labelTxt: "Tenant Requirements",
    name: "tenantRequirements",
    placeholder: "Amenities (you can add multiple)",
    type: "select-multi",
    component: SelectMulti,
    required: false,
    grid: 4,
    options: TENANT_REQUIREMENTS
  },
  {
    labelTxt: "Does your unit have a basement?",
    name: "basement",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 4,
    options: BASEMENT
  },
  // {
  //   labelTxt: "Move-in Bonuses",
  //   name: "moveInBonuses",
  //   placeholder: "Move-in Bonuses",
  //   type: "text",
  //   required: false,
  //   component: Field,
  //   disabled: true,
  //   styleInput: {
  //     maxWidth: "100%"
  //   }
  // },
  {
    labelTxt: "Pictures",
    name: "pictures",
    placeholder: "Pictures",
    type: "picture",
    required: true,
    component: PicturePicker,
    styleInput: {
      maxWidth: "100%",
      minWidth: "100%"
    }
  }
]

const unitfields: FieldType[] = [
  {
    labelTxt: "Unit Title",
    name: "title",
    placeholder: "Title",
    type: "text",
    required: true,
    component: Field,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Availability Date",
    name: "startDateAvailability",
    placeholder: "",
    type: "selects",
    component: DatePick,
    required: false,
    grid: 4
  },
  {
    labelTxt: "Bedrooms",
    name: "bedrooms",
    placeholder: "Bedrooms",
    type: "selects",
    component: Select,
    required: true,
    grid: 4,
    options: BEDROOMS
  },
  {
    labelTxt: "Bathrooms",
    name: "bathrooms",
    placeholder: "Bathrooms",
    type: "selects",
    component: Select,
    required: true,
    grid: 4,
    options: BATHROOMS,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Den",
    name: "den",
    placeholder: "Den",
    type: "number",
    component: Field,
    required: false,
    grid: 4,
    options: DEN,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Unit Size (sq. ft.)",
    name: "unitSize",
    placeholder: "Unit Size ",
    type: "number",
    required: true,
    component: Field,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },

  {
    labelTxt: "Monthly Price (CAD)",
    name: "monthlyPrice",
    placeholder: "Monthly Price (CAD)",
    type: "number",
    max: 200000,
    min: 0,
    step: 0.01,
    required: true,
    component: PriceDecimalField,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50,
      dollor: "dollor",
      backgroundColor: "#fafafa"
    }
  },
  {
    labelTxt: "Deposit (CAD)",
    name: "deposit",
    placeholder: "Deposit (CAD)",
    type: "number",
    max: 200000,
    min: 0,
    step: 0.01,
    required: true,
    component: PriceDecimalField,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50,
      dollor: "dollor",
      backgroundColor: "#fafafa"
    }
  },

  {
    labelTxt: "Furnished",
    name: "furnished",
    placeholder: "Select",
    type: "selects",
    component: DropDown,
    required: false,
    grid: 4,
    options: FURNISHED
  }
]

const unitEditfields: FieldType[] = [
  {
    labelTxt: "Unit Title",
    name: "title",
    placeholder: "Title",
    type: "text",
    required: true,
    component: Field,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Availability Date",
    name: "startDateAvailability",
    placeholder: "",
    type: "selects",
    component: DatePick,
    required: false,
    grid: 4
  },
  {
    labelTxt: "Bedrooms",
    name: "bedrooms",
    placeholder: "Bedrooms",
    type: "selects",
    component: Select,
    required: true,
    grid: 4,
    options: BEDROOMS
  },
  {
    labelTxt: "Bathrooms",
    name: "bathrooms",
    placeholder: "Bathrooms",
    type: "number",
    required: true,
    component: Field,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Den",
    name: "den",
    placeholder: "Den",
    type: "number",
    component: Field,
    required: false,
    grid: 4,
    options: DEN,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Unit Size (sq. ft.)",
    name: "unitSize",
    placeholder: "Unit Size ",
    type: "number",
    required: true,
    component: Field,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },

  {
    labelTxt: "Monthly Price (CAD)",
    name: "monthlyPrice",
    placeholder: "Monthly Price (CAD)",
    type: "number",
    max: 200000,
    min: 0,
    step: 0.01,
    required: true,
    component: PriceDecimalField,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50,
      dollor: "dollor",
      backgroundColor: "#fafafa"
    }
  },
  {
    labelTxt: "Deposit (CAD)",
    name: "deposit",
    placeholder: "Deposit (CAD)",
    type: "number",
    max: 200000,
    min: 0,
    step: 0.01,
    required: true,
    component: PriceDecimalField,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50,
      dollor: "dollor",
      backgroundColor: "#fafafa"
    }
  },
  {
    labelTxt: "Furnished",
    name: "furnished",
    placeholder: "Select",
    type: "selects",
    component: DropDown,
    required: false,
    grid: 4,
    options: FURNISHED
  },
  {
    labelTxt: "Available",
    name: "available",
    placeholder: "Select",
    type: "selects",
    component: Select,
    required: false,
    grid: 4,
    options: AVAILABLE
  }
]

const unitfields2: FieldType[] = [
  {
    labelTxt: "Number",
    name: "number",
    placeholder: "Number",
    type: "text",
    component: Field,
    required: false,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Parking",
    name: "parking",
    placeholder: "Parking",
    type: "selects",
    component: Select,
    required: true,
    grid: 4,
    options: PARKING,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Lease Term (Months)",
    name: "leaseTerm",
    placeholder: "Lease Term",
    type: "number",
    min: 0,
    max: 120,
    component: Field,
    required: false,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Parking Spots",
    name: "parkingSpots",
    placeholder: "Parking Spots",
    type: "number",
    alignment: "flex-end",
    min: 0,
    max: 20,
    component: Field,
    required: false,
    grid: 4,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Which storage/locker does the property come with?",
    name: "storage",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 4,
    options: STORAGE
  },
  {
    labelTxt: "Who will be responsible for exterior maintenance?",
    name: "maintenance",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 4,
    options: MAINTENANCE
  },
  {
    labelTxt: "Do you have working smoke and carbon monoxide alarms installed in the unit as legally required?",
    name: "smokeAlarm",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 12,
    options: SMOKE_ALARM,
    styleInput: {
      maxWidth: "100%",
      maxHeight: 50
    }
  },
  {
    labelTxt: "Picture",
    name: "picture",
    placeholder: "Picture",
    type: "picture",
    required: true,
    component: PicturePicker,
    styleInput: {
      maxWidth: "100%",
      minWidth: "100%"
    }
  }
]

const unitfields3: FieldType[] = [
  {
    labelTxt: "Unit Appliances",
    name: "unitAppliances",
    placeholder: "Unit Appliances (you can add multiple)",
    type: "select-multi",
    component: SelectMulti,
    required: false,
    grid: 6,
    options: UNIT_APPLIANCES
  },
  {
    labelTxt: "Unit Features", //Name change to Unit Features
    name: "unitamenities",
    placeholder: "Amenities (you can add multiple)",
    type: "select-multi",
    component: SelectMulti,
    required: false,
    grid: 6,
    options: UNIT_AMENITIES
  },
  {
    labelTxt: "Floor Plan",
    name: "floorplan",
    placeholder: "Pictures",
    type: "picture",
    required: false,
    component: FloorPlanUpload
  }
]

const editFields2: FieldType[] = [
  {
    labelTxt: "Utilities",
    name: "utilities",
    placeholder: "Utilities",
    type: "select-multi",
    required: false,
    component: SelectMulti,
    grid: 4,
    options: UTILITIES,
    styleInput: {
      maxWidth: "100%"
    }
  },
  {
    labelTxt: "Amenities",
    name: "amenities",
    placeholder: "Amenities",
    type: "select-multi",
    component: SelectMulti,
    required: false,
    grid: 4,
    options: AMENITIES
  },
  {
    labelTxt: "Pets Allowed",
    name: "pets",
    placeholder: "Pets Allowed",
    type: "selects",
    component: SelectMulti,
    required: false,
    grid: 4,
    options: PETS
  },
  {
    labelTxt: "Tags",
    name: "tags",
    placeholder: "Tags",
    type: "select-multi",
    required: false,
    component: SelectMulti,
    grid: 4,
    options: TAGS
  },
  {
    labelTxt: "Tenant Requirements",
    name: "tenantRequirements",
    placeholder: "Amenities (you can add multiple)",
    type: "select-multi",
    component: SelectMulti,
    required: false,
    grid: 4,
    options: TENANT_REQUIREMENTS
  },
  {
    labelTxt: "Does your unit have a basement?",
    name: "basement",
    placeholder: "Select",
    type: "selects",
    required: false,
    component: Select,
    grid: 4,
    options: BASEMENT
  },
  {
    labelTxt: "Move-in Bonuses",
    name: "moveInBonuses",
    placeholder: "Move-in Bonuses",
    type: "text",
    required: false,
    component: Field,
    grid: 12
  },
  {
    labelTxt: "Pictures",
    name: "pictures",
    placeholder: "Pictures",
    type: "picture",
    required: true,
    component: PicturePicker,
    styleInput: {
      maxWidth: "100%",
      minWidth: "100%"
    }
  }
]

export { fields, unitEditfields, fields2, unitfields, unitfields2, unitfields3, editFields2 }
