import React from 'react';
import { Grid, TextField, withStyles } from "@material-ui/core"
import CountrySelect from './country-select';


export const CssTextField = withStyles({
    root: {
        color: '#00a857',
        "& label.Mui-focused": {
            color: "#00a857"
        },
        "& .MuiInput-underline:after": {
            color: "#00A857",
            border: "1px solid #D9D9D9",
            borderRadius: "1px"
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                color: "#00A857",
                border: "1px solid #D9D9D9",
                borderRadius: "1px"
            },
            "&:hover fieldset": {
                color: "#00A857",
                border: "1px solid #D9D9D9",
                borderBottom: '1px solid #6fd621bd',
                borderRadius: "1px"
            },
            "&.Mui-focused fieldset": {
                color: "#00A857",
                border: "1px solid #D9D9D9",
                borderRadius: "1px"
            }
        },
        "& input": {
            color: '#00a857',
        }
    }
})(TextField) as any

export const AddressField = (props: AddressFieldProps) => {
    const {
        street,
        city,
        province,
        postal,
        country,
        handleStreetChange,
        handleCityChange,
        handleProvinceChange,
        handlePostalChange,
        handleCountryChange
    } = props;
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <CssTextField
                    variant="outlined"
                    placeholder="Street"
                    fullWidth
                    value={street}
                    onChange={(e: any) => handleStreetChange(e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <CssTextField
                    variant="outlined"
                    fullWidth
                    placeholder="City"
                    value={city}
                    onChange={(e: any) => handleCityChange(e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <CssTextField
                    variant="outlined"
                    fullWidth
                    placeholder="Province"
                    value={province}
                    onChange={(e: any) => handleProvinceChange(e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <CssTextField
                    variant="outlined"
                    fullWidth
                    placeholder="Postal"
                    value={postal}
                    onChange={(e: any) => handlePostalChange(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <CountrySelect
                    value={country}
                    onChange={(e) => handleCountryChange(e.target.value)}
                />
            </Grid>
        </Grid>
    )
}


interface AddressFieldProps {
    street: string;
    city: string;
    province: string;
    postal: string;
    country: string;
    handleStreetChange: (value: string) => void
    handleCityChange: (value: string) => void
    handleProvinceChange: (value: string) => void
    handlePostalChange: (value: string) => void
    handleCountryChange: (value: string) => void
}