export const ID_TOKEN = "id_token"
export const USER_ID = "user_id"
export const AUTH0_ID = "auth0id"
export const MAP_DATA = "internal_listing"

export const radioGroupOptions = [
  { value: "Yes, rented on tolobi", label: "Yes, rented on Tolobi", key: 0 },
  { value: "Yes, rented elsewhere", label: "Yes, rented elsewhere", key: 1 },
  { value: "No, haven't rented", label: "No, haven't rented", key: null },
  { value: "Other", label: "Other", key: null }
]

export const setStoredAuthState = (idToken: string, userId: string) => {
  localStorage.setItem(ID_TOKEN, idToken)
  localStorage.setItem(USER_ID, userId)
}

export const setStoredMapListingState = (mapData: string) => {
  localStorage.setItem(MAP_DATA, mapData)
}

export const getMapListingState = () => {
  const resp = localStorage.getItem(MAP_DATA)
  return JSON.parse(resp!)
}

export const setStoredAuth0Id = (auth0id: string) => {
  localStorage.setItem(AUTH0_ID, auth0id)
}

export const removeAuth0Id = () => {
  localStorage.removeItem(AUTH0_ID)
}

export const removeStoredAuthState = () => {
  localStorage.removeItem(ID_TOKEN)
  localStorage.removeItem(USER_ID)
  localStorage.removeItem(AUTH0_ID)
  return
}

export const DEFAULT_UNAUTH = {
  edit: false,
  delete: false,
  add: false,
  view: false
}

export const DEFAULT_AUTH = {
  edit: true,
  delete: true,
  add: true,
  view: true
}
