import React from 'react';
import { countryList } from 'src/utils/countries-list';
import styles from '../styles.module.scss';


export default function CountrySelect(props: CountrySelectProps) {
    const { value, onChange } = props;
    return (
        <select className={styles.select__container} value={value} onChange={onChange}>
            <option value="" disabled selected hidden>Country</option>
            {
                countryList.map((country, idx) => (
                    <option value={country} key={idx}>{country}</option>
                ))
            }
        </select>
    )
}

interface CountrySelectProps {
    value: string;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
}