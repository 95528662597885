import gql from "graphql-tag"

export const CREATE_SEARCH_ADDRESS = gql`
  mutation saveSearchHistory($WhereInput: SearchAddressInput!) {
    saveSearchHistory(WhereInput: $WhereInput) {
      id
    }
  }
`

export const DELETE_SEARCH_ADDRESS = gql`
  mutation deleteSearchHistory($WhereInput: SearchAddressInput!) {
    deleteSearchHistory(WhereInput: $WhereInput) {
      id
    }
  }
`
