import React, { useState, useEffect } from "react"
import { InputComponent } from "src/dashboards/components/form-container-field/form-container-field"
import { Text } from "src/components/help-center-section/styled-compnent"
import colors from "src/theme/colors"
import styled from "styled-components"
import { HelpDataType } from "src/dashboards/screens/help-center/utils/type"

type Props = {
  onChangeForm: (value: object) => void
  editData: HelpDataType
}
const FormComponent = styled.div<any>`
  margin: 10px 0;
`

const FormOne = (props: Props) => {
  const { onChangeForm, editData } = props
  const [title, setTitle] = useState("")

  useEffect(() => {
    setTitle(editData?.title)
  }, [editData])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value }
    } = event
    setTitle(value)

    const initObj = {
      title: value
    }
    onChangeForm(initObj)
  }

  return (
    <>
      <Text fontSize={16} fontWeight={600} color={colors.black}>
        Enter Title
      </Text>
      <FormComponent>
        <InputComponent
          id="title"
          name="title"
          required
          type="text"
          placeholder="Enter Title"
          value={title ? title : ""}
          onChange={onChange}
          height={50}
          padding="0 0 0 10px"
        />
      </FormComponent>
    </>
  )
}

export default FormOne
