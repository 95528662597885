import { GET_BUILDING_BY_ID } from "../../admin-access-building/graphql/query"
import { GET_LISTINGS, GET_AVAILABLE_LISTINGS, SEARCH_BUILDING_BY_LANDLORD } from "../graphql/query"

export const fetchListing = async (skipNo, client) => {
  try {
    const resp = await client.query({
      query: GET_LISTINGS,
      fetchPolicy: "network-only",
      variables: {
        first: 10,
        skip: skipNo
      }
    })
    return resp
  } catch (err) {
    console.log("Error", err)
  }
}

export const fetchSingleListing = async (id, client) => {
  try {
    const { data = {} } = await client.query({
      query: GET_BUILDING_BY_ID,
      fetchPolicy: "network-only",
      variables: {
        id
      }
    })
    return data
  } catch (err) {
    console.log("Error", err)
  }
}

export const fetchSearchListingLu = async (client, value, skip: number) => {
  try {
    const { data = {} }: any = await client.query({
      query: SEARCH_BUILDING_BY_LANDLORD,
      variables: {
        search: value,
        first: 10,
        skip
      }
    })
    return data
  } catch (err) {
    console.log("err", err)
  }
}

export const fetchAvailableListing = async (client, skip, isHidden: boolean) => {
  try {
    const data = await client.query({
      query: GET_AVAILABLE_LISTINGS,
      variables: {
        skip,
        isHidden,
        first: 10
      }
    })
    return data
  } catch (err) {
    console.log("Error", err)
  }
}
