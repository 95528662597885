import gql from "graphql-tag"

export const UPDATE_USERS = gql`
  mutation updateUsers($data: Data!) {
    updateUsers(data: $data) {
      id
    }
  }
`
export const UPDATE_USER_TYPE_LIST_WITH_US = gql`
  mutation updateUserTypeListWithUs($data: Data!) {
    updateUserTypeListWithUs(data: $data) {
      id
    }
  }
`
export const UPDATE_USER_TYPE = gql`
  mutation updateUserType($data: Data!) {
    updateUserType(data: $data) {
      id
      email
    }
  }
`
export const CREATE_USER = gql`
  mutation createUser($data: Data!) {
    createUser(data: $data) {
      id
    }
  }
`

export const CREATE_PERMISSION = gql`
  mutation createPermission($data: PermissionWhereInput!) {
    createPermission(data: $data) {
      id
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`
