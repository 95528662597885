import * as Yup from "yup"
import { FieldType } from "./types"
import moment from "moment"

export const createSchema = (validations: FieldType[]) => {
  let shape: any = {}
  for (let i = 0; i < validations.length; i++) {
    let _id = validations[i].id
    let _type = validations[i].type
    let _key = validations[i].name
    let _label = validations[i].labelTxt
    let _required = validations[i].required
    let _req_msg = validations[i].req_msg
    let _err_msg = validations[i].err_msg
    let _matches = validations[i].matches
    let _min = validations[i].min
    let _max = validations[i].max
    if (_type === "email") {
      shape[_key] = Yup.string().email(_err_msg || "Please provide a valid email addess")
    } else if (_key === "bathrooms") {
      shape[_key] = Yup.number()
        .typeError("Amount must be a number")
        .nullable()
        .positive()
        .min(0, "Value must be greater than 0")
    } else if (_key === "householdIncome" && _id === "householdIncome") {
      shape[_key] = Yup.number()
        .positive()
        .nullable()
        // .test(
        //   "maxDigitsAfterDecimal",
        //   "field must have two digits after decimal or less",
        //   (householdIncome) => {
        //     const isValid = /^\d*(?:\.\d{1,2})?$/.test(householdIncome);
        //     return householdIncome ? isValid : true; // if empty skip validation
        //   }
        // )
        .min(0, "Value must be greater than or equal to $0")
        .max(200000, "Please enter an amount less than or equal to $200,000")

      if (_min)
        shape[_key] = shape[_key].min(_min, "Value must be greater than or equal to $" + _min + "")
      if (_max)
        shape[_key] = shape[_key].max(
          _max,
          "Please enter an amount less than or equal to $" + _max + ""
        )
    } else if (_type === "number") {
      shape[_key] = Yup.number()
        .typeError("Amount must be a number")
        .nullable()
        .positive()
        .min(0, "Value must be greater than or equal to 0")
      if (_min) shape[_key] = shape[_key].min(_min, "Minimum number should be " + _min + "")
      if (_max) shape[_key] = shape[_key].max(_max, "Maximum number should be " + _max + "")
    } else if (_type === "text" && _key !== "des" && _key !== "description") {
      if (_required)
        shape[_key] = Yup.string().matches(/^\S.*$/, _req_msg || `${_label || _key} is required`)
    } else if (_key === "picture") {
      shape[_key] = Yup.array().min(3, "Minimum three pictures are required")
    } else if (_key === "zip_code") {
      shape[_key] = Yup.string()
        .matches(/^[a-zA-Z0-9]{0,6}$/, "Maximum length of 6 characters")
        .nullable()
    } else if (_key === "phoneNo") {
      shape[_key] = Yup.string().matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
    } else if (_type === "password") {
      shape[_key] = Yup.string()
        .required("Pleas enter your password")
        .min(8, "Password should contain : \n Minimum length of 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          "Password should contain : \n Minimum length of 8 characters \n Atleast 1 Numeric character \n Atleast 1 Uppercase letter \n Atleast 1 Lowercase letter \n Atleast 1 Special character"
        )
    } else if (_type === "date") {
      shape[_key] = Yup.mixed().test("match", `${_label || _key} is required`, function (date) {
        return moment(date, "YYYY-MM-DD").isValid()
      })
    } else if (_type === "url" && _key === "thumbnailUrl") {
      shape[_key] = Yup.mixed().test(
        "fileSize",
        "Minimum width and height must be 300px",
        async url => {
          if (!url) return false
          return new Promise((resolve, reject) => {
            try {
              var img = new Image()
              img.src = url
              img.onload = async function () {
                if (this.width < 300 || this.height < 300) {
                  resolve(false)
                } else {
                  resolve(true)
                }
              }
            } catch (e) {
              console.log("CATCH error-")
              reject(e)
            }
          })
        }
      )
    } else {
      shape[_key] = Yup.string().nullable()
      if (_matches) shape[_key] = shape[_key].matches(_matches, _err_msg || "Invalid value")
    }

    if (_required) {
      shape[_key] = shape[_key].required(_req_msg || `${_label || _key} is required`)
    }

    if (_required && _key === "pictures") {
      shape[_key] = shape[_key].required(_req_msg || `At least one picture is required`)
    }

    if (_key === "cpassword") {
      shape["cpassword"] = Yup.mixed().test(
        "match",
        "password and re-enter password does not match",
        function (password) {
          return password === this.parent.password
        }
      )
    }
    if (_required && _key === "userType") {
      shape[_key] = shape[_key].required(`This question is required`)
    }
    if (_required && (_key === "storage" || _key === "smokeAlarm" || _key === "maintenance")) {
      shape[_key] = shape[_key].required(`This question is required`)
    }
    if (_required && _key === "monthlyPrice") {
      shape[_key] = shape[_key]
        .required(_req_msg || `${_label || _key} is required`)
        .typeError("Amount must be a number")
        .nullable()
        .positive()
        .min(0, "Value must be greater than or equal to $0")
        .max(
          200000,
          "If the value here exceeds $200,000, please contact the Tolobi Rental Team at (647) 250-7371"
        )
    }

    if (_required && _key === "deposit") {
      shape[_key] = shape[_key]
        .required(_req_msg || `${_label || _key} is required`)
        .typeError("Amount must be a number")
        .nullable()
        .positive()
        .min(0, "Value must be greater than or equal to $0")
        .max(
          200000,
          "If the value here exceeds $200,000, please contact the Tolobi Rental Team at (647) 250-7371"
        )
    }

    if (_key === "monthlyPrice") {
      shape[_key] = shape[_key]
        .typeError("Amount must be a number")
        .nullable()
        .positive()
        .min(0, "Value must be greater than or equal to $0")
        .max(
          200000,
          "If the value here exceeds $200,000, please contact the Tolobi Rental Team at (647) 250-7371"
        )
    }

    if (_key === "deposit") {
      shape[_key] = shape[_key]
        .typeError("Amount must be a number")
        .nullable()
        .positive()
        .min(0, "Value must be greater than or equal to $0")
        .max(
          200000,
          "If the value here exceeds $200,000, please contact the Tolobi Rental Team at (647) 250-7371"
        )
    }

    if (_key === "desiredRentalPrice") {
      shape[_key] = shape[_key]
        .required(_req_msg || `${_label || _key} is required`)
        .typeError("Amount must be a number")
        .nullable()
        .positive()
        .min(0, "Value must be greater than or equal to $0")
    }
    if (_key === "minimumRentalPrice") {
      shape[_key] = shape[_key]
        .required(_req_msg || `${_label || _key} is required`)
        .typeError("Amount must be a number")
        .nullable()
        .positive()
        .min(0, "Value must be greater than or equal to $0")
    }
    if (_key === "phone" && _id === "phoneNumber") {
      shape[_key] = shape[_key].matches(
        /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
    }
  }

  return Yup.object().shape(shape)
}
