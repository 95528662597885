import { SEARCH_BUILDING } from "./graphql/query"

export const fetchSearchListingPagination = async (client, value, skip) => {
  try {
    const { data = {} }: any = await client.query({
      query: SEARCH_BUILDING,
      variables: {
        search: value,
        skip: skip,
        take: 10
      }
    })
    return data
  } catch (err) {
    console.log("Err", err)
  }
}
