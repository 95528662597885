import React, { FC, ReactNode } from "react"
import { Dialog, DialogProps } from "@material-ui/core"
import "./style.scss"

interface Props extends DialogProps {
  children: ReactNode
  open: boolean
  handleClose: () => void
  fullWidth?: boolean
}

const ModalComponent: FC<Props> = ({ open, handleClose, fullWidth, children, ...rest }) => {
  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        {...rest}
      >
        {children}
      </Dialog>
    </>
  )
}

export default ModalComponent
