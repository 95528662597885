import React, { useState } from "react"
import ApolloClient from "apollo-client"
import { NormalizedCacheObject } from "apollo-cache-inmemory"
import ReactExport from "react-export-excel"
import { AddButton } from "src/containers/home/UI"
import { fetchListingsDatas } from "src/services"
import { Colors } from "src/theme"
import { DTimeStamp } from "../stamptime-converter"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

interface Props {
  client: ApolloClient<NormalizedCacheObject>
}

const ExportIntoExcel = (props: Props) => {
  const { client } = props
  const [hasExport, setHasExport] = useState<boolean>(false)
  const [datas, setDatas] = useState([])

  const onExportToExcel = async () => {
    setHasExport(true)
    const resp = await fetchListingsDatas(client)
    let unitData: any = []
    if (resp && resp.data?.adminAccessListing) {
      const { adminAccessListing } = resp.data
      for (let i = 0; i < adminAccessListing?.length; i++) {
        if (adminAccessListing[i]?.signedLeaseDate) {
          adminAccessListing[i].signedLeaseDate = DTimeStamp(adminAccessListing[i]?.signedLeaseDate)
        }
        if (adminAccessListing[i]?.leadsCraigslistEnabled) {
          adminAccessListing[i].leadsCraigslistEnabled = DTimeStamp(adminAccessListing[i]?.leadsCraigslistEnabled)
        } if (adminAccessListing[i]?.leadsKijijiEnabled) {
          adminAccessListing[i].leadsKijijiEnabled = DTimeStamp(adminAccessListing[i]?.leadsKijijiEnabled)
        } if (adminAccessListing[i]?.leadsMarketplaceEnabled) {
          adminAccessListing[i].leadsMarketplaceEnabled = DTimeStamp(adminAccessListing[i]?.leadsMarketplaceEnabled)
        } if (adminAccessListing[i]?.leadsRealtorEnabled) {
          adminAccessListing[i].leadsRealtorEnabled = DTimeStamp(adminAccessListing[i]?.leadsRealtorEnabled)
        } if (adminAccessListing[i]?.leadsRentalsEnabled) {
          adminAccessListing[i].leadsRentalsEnabled = DTimeStamp(adminAccessListing[i]?.leadsRentalsEnabled)
        } if (adminAccessListing[i]?.leadsTolobiEnabled) {
          adminAccessListing[i].leadsTolobiEnabled = DTimeStamp(adminAccessListing[i]?.leadsTolobiEnabled)
        } if (adminAccessListing[i]?.leadsZillowEnabled) {
          adminAccessListing[i].leadsZillowEnabled = DTimeStamp(adminAccessListing[i]?.leadsZillowEnabled)
        }
        if (adminAccessListing[i]?.leadsZumperEnabled) {
          adminAccessListing[i].leadsZumperEnabled = DTimeStamp(adminAccessListing[i]?.leadsZumperEnabled)
        }
        if (adminAccessListing[i]?.leadsCraigslistDisabled) {
          adminAccessListing[i].leadsCraigslistDisabled = DTimeStamp(adminAccessListing[i]?.leadsCraigslistDisabled)
        }
        if (adminAccessListing[i]?.leadsKijijiDisabled) {
          adminAccessListing[i].leadsKijijiDisabled = DTimeStamp(adminAccessListing[i]?.leadsKijijiDisabled)
        }
        if (adminAccessListing[i]?.leadsMarketplaceDisabled) {
          adminAccessListing[i].leadsMarketplaceDisabled = DTimeStamp(adminAccessListing[i]?.leadsMarketplaceDisabled)
        }
        if (adminAccessListing[i]?.leadsRealtorDisabled) {
          adminAccessListing[i].leadsRealtorDisabled = DTimeStamp(adminAccessListing[i]?.leadsRealtorDisabled)
        }
        if (adminAccessListing[i]?.leadsTolobiDisabled) {
          adminAccessListing[i].leadsTolobiDisabled = DTimeStamp(adminAccessListing[i]?.leadsTolobiDisabled)
        }
        if (adminAccessListing[i]?.leadsRentalsDisabled) {
          adminAccessListing[i].leadsRentalsDisabled = DTimeStamp(adminAccessListing[i]?.leadsRentalsDisabled)
        }
        if (adminAccessListing[i]?.leadsZillowDisabled) {
          adminAccessListing[i].leadsZillowDisabled = DTimeStamp(adminAccessListing[i]?.leadsZillowDisabled)
        }
        if (adminAccessListing[i]?.leadsZumperDisabled) {
          adminAccessListing[i].leadsZumperDisabled = DTimeStamp(adminAccessListing[i]?.leadsZumperDisabled)
        }
        let allUnits = adminAccessListing[i]?.units
        for (let j = 0; j < allUnits?.length; j++) {
          if (allUnits[j]?.startDateAvailability) {
            allUnits[j].startDateAvailability = DTimeStamp(allUnits[j]?.startDateAvailability)
          }
          const data = {
            ...adminAccessListing[i],
            ...allUnits[j]
          }

          const { units, __typename, floorplan, ...rest } = data
          unitData.push(rest)
        }
      }
    }
    setDatas(unitData)
    setHasExport(false)
  }

  const filterColumns = data => {
    const columns = Object.keys(data[0])
    return columns
  }

  const camelCase = str => {
    return str.substring(0, 1).toUpperCase() + str.substring(1)
  }

  return (
    <ExcelFile
      filename="property-data"
      element={
        <AddButton
          genre="add-new"
          backgroundColor={Colors.white}
          color={Colors.primary}
          border={`2px solid ${Colors.primary}`}
          margin={"10px"}
          maxWidth={"100%"}
          width={160}
          onClick={onExportToExcel}
        >
          {datas?.length > 0 ? "Download Now" : !hasExport ? "Export Data" : "Loading...."}
        </AddButton>
      }
    >
      {datas && datas.length > 0 && (
        <ExcelSheet data={datas?.length > 0 && datas} name="Export Data">
          {filterColumns(datas).map((col, index) => {
            return <ExcelColumn label={camelCase(col)} value={col} key={index} />
          })}
        </ExcelSheet>
      )}
    </ExcelFile>
  )
}

export default ExportIntoExcel
