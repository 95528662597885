import axios from 'axios';

// Replace with your Mapbox access token
const accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

// Base URL for the Mapbox Places API
const baseURL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/landmark.json?type=poi&proximity=';

// Radius in meters to search within
// const radius = 5000;


// Make the API request using Axios

export default (latitude, longitude) => {
    // Construct the API request URL
    const requestURL = `${baseURL}${longitude},${latitude}&access_token=${accessToken}&type=poi&limit=15`;
    return axios.get(requestURL)
        .then((response) => {
            const data = response.data;

            if (data.features) {
                return data.features.map(i => i.text).toString()
            } else {
                return ""
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}

