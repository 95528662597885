import React from "react"
import { Autocomplete } from "@material-ui/lab"
import { client } from "src/apollo"
import { debounce } from "lodash"
import { fetchSearchListingPagination } from "./services"
import CssTextField from "../input-fields/textfield"

export const splitAddress = (value: string) => value?.split("#")?.filter(Boolean)?.[0] || ""

const AddressAutoComplete = (props: AddressAutoCompleteProps) => {
    const { inputValue, handleChangeInput, handleBlur } = props
    const [features, setFeatures] = React.useState<AddressSelectType[]>([])
    const [isLoading, setIsLoading] = React.useState(false)

    const fetchListing = async (query: string) => {
        try {
            setIsLoading(true)
            const locationResp = await fetchSearchListingPagination(client, query, 0)
            const appendUnitsInAddress = locationResp?.searchBuilding
                ?.map((item: any) => {
                    if (item?.isHidden) {
                        return false
                    }
                    if (item?.units && item?.units?.length) {
                        const { units: _, ...restItems } = item
                        const unitsMap = item.units
                            .filter(item => !!item.available)
                            .map((nItem: any) => ({
                                unitTitle: nItem.title,
                                unitNumber: nItem.number,
                                unitId: nItem.id,
                                ...restItems
                            }))
                        return unitsMap
                    }
                    return item
                })
                ?.flat()
                ?.filter(Boolean)

            const getUnitName = (title?: string, number?: string) => {
                let value = " (Unit"
                if (!title && !number) return ""
                if (number) {
                    value += `-${number}`
                }

                if (title) {
                    value += `-${title}`
                }
                value += ")"
                return value
            }

            const isValidUnit = (item: any) =>
                !!(item.title && (item.unitTitle || item.unitNumber) && item.id)

            const locationRespMap =
                appendUnitsInAddress
                    ?.map(item =>
                        isValidUnit(item)
                            ? {
                                title: `${item?.title}${getUnitName(item.unitTitle, item.unitNumber)}`,
                                unitId: item.unitId,
                                listingId: item.id
                            }
                            : false
                    )
                    ?.filter(Boolean) || []
            setFeatures(locationRespMap)
        } catch (e) {
            console.log("error: ", e)
        } finally {
            setIsLoading(false)
        }
    }

    const debounceSearch = React.useCallback(debounce(fetchListing, 500), [])

    const searchRegion = (query: string) => {
        if (!query) return setFeatures([])
        debounceSearch(query)
    }

    const onInputChange = (evt: any, value: string) => {
        searchRegion(value)
    }

    const onSelect = (address: AddressSelectType) => {
        handleChangeInput(address)
        setFeatures([])
    }

    const handleAutoChange = (event: any, value: any) => {
        onSelect(value)
    }

    return (
        <Autocomplete
            disableClearable
            loadingText={"Loading..."}
            loading={isLoading}
            openOnFocus
            onChange={handleAutoChange}
            value={inputValue}
            options={features}
            getOptionLabel={option => option.title}
            onInputChange={onInputChange}
            renderInput={params => (
                <CssTextField
                    {...params}
                    placeholder="Address"
                    fullWidth
                    variant="outlined"
                    onBlur={() => {
                        if (handleBlur) {
                            handleBlur()
                        }
                        if (!inputValue) {
                            setFeatures([])
                        }
                    }}
                />
            )}
        />
    )
}

export default AddressAutoComplete

interface AddressAutoCompleteProps {
    handleChangeInput: (v: AddressSelectType) => void
    inputValue: AddressSelectType
    handleBlur?: any
}

export interface AddressSelectType {
    title: string
    unitId: string
    listingId: string
}
