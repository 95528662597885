import gql from "graphql-tag"

export const UPLOAD_FILES = gql`
  mutation MultipleFileUpload($where: MultiFileUploadData) {
    MultipleFileUpload(where: $where) {
      files
      error
      message
    }
  }
`
