import * as React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import Box from "../box"

const LogoSearchWrapper = styled(Box as any)`
  align-items: center;
`

const Logo = styled.div`
  width: 40px;
  height: 40px;
  background-image: url("https://s3.amazonaws.com/tb-app-assets/Misc/tolobi-web-logo.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 20px;
`

// const Divider = styled.div`
//   display: block;
//   width: 2px;
//   height: 40px;
//   opacity: 0.25;
//   background-color: ${Colors.black};
//   margin-right: 11px;
// }
// `

// const SearchButton = styled(Button as any)`
//   color: ${theme.colors.lightGrey};
//   max-width: 100px;
//   border: none;
//   text-align: left;
//   padding-left: 37px;
//   transition-duration: 0;
//   &::before {
//     transition-duration: 0.2s;
//     left: 8px;
//   }
//   &:hover {
//     background-color: ${theme.colors.lightGreyFaded};
//     color: ${theme.colors.darkGrey};
//     &::before {
//       background-color: ${theme.colors.darkGrey};
//     }
//   }
// `

interface Props {
  handleSearchClick: () => any
}

const LogoSearchSection = (props: Props) => (
  <LogoSearchWrapper>
    <Link to="/" aria-label="move-home-screen">
      <Logo />
    </Link>
    {/* <Divider /> */}
    {/* <SearchButton
      genre="secondary"
      size="small"
      icon="/images/search.svg"
      onClick={props.handleSearchClick}
    >
      Search
    </SearchButton> */}
  </LogoSearchWrapper>
)

export default LogoSearchSection
