import React, { useMemo } from "react"
import { Redirect } from "react-router-dom"
import { UserType } from "src/generated/graphql"
import { FetchCurrentUserMe } from "src/generated/graphql"

interface Props {
  user: FetchCurrentUserMe | null
  children: any
}
function PrivateRoute({ children, user }: Props) {
  const isAuthUser = useMemo(() => {
    return (
      (user && user.userType === UserType.Admin) || (user && user.userType === UserType.SubAdmin)
    )
  }, [user])
  return isAuthUser ? children : <Redirect to="/" />
}

export default PrivateRoute
