export const NOTIFICATION_MSG = {
  Success: "success",
  Info: "info",
  Warning: "warning",
  error: "error"
}

export const MESSAGE = {
  SomethingWrong: "Something went wrong.",
  AlreadyExist: "This email is already exists!",
  BookingExist: "Booking already exist!",
  NoAccess: "You don't have an Access",
  AllAccess: "Admin have all Access"
}

// notify(NOTIFICATION_MSG.error, MESSAGE.SomethingWrong)
