import { createOptions } from "./createOptions"
import { StepResponseType } from "../../../screens/landlord-access-building/types/index"
import {
  UTILITIES as ut,
  AMENITIES as amt,
  TAGS as tag,
  TENANT_REQUIREMENTS as tenant,
  PETS as pt
} from "../../constants/constants"
function parseStep2({
  pet,
  amenities,
  pictures,
  utilities,
  location,
  tenantRequirements,
  moveInBonuses
}: StepResponseType) {
  return {
    utilities: createOptions(ut, utilities),
    amenities: createOptions(amt, amenities),
    tags: createOptions(tag, location !== null && location.tags),
    pets: createOptions(pt, pet),
    tenantRequirements: createOptions(tenant, tenantRequirements),
    pictures,
    moveInBonuses: moveInBonuses
  }
}

export { parseStep2 }
