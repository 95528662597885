import { CREATE_HELP_CENTER, DELETE_HELP_CENTER, UPDATE_HELP_CENTER } from "../graphql/mutation"
import { SINGLE_HELP_CENTER } from "../graphql/query"

export const createHelpCenter = async (client, data) => {
  try {
    return await client.mutate({
      mutation: CREATE_HELP_CENTER,
      variables: {
        WhereInput: data
      }
    })
  } catch (error) {
    console.log("Error", error)
  }
}
// SINGLE_HELP_CENTER

export const updatedHelpCenter = async (client, data) => {
  try {
    return await client.mutate({
      mutation: UPDATE_HELP_CENTER,
      variables: {
        WhereInput: data
      }
    })
  } catch (error) {
    console.log("Error", error)
  }
}

export const deleteHelpCenter = async (client, id: string) => {
  try {
    return await client.mutate({
      mutation: DELETE_HELP_CENTER,
      variables: {
        WhereInput: { id }
      }
    })
  } catch (error) {
    console.log("Error", error)
  }
}

export const getByIDHelpCenter = async (client, data) => {
  try {
    return await client.query({
      query: SINGLE_HELP_CENTER,
      variables: {
        WhereInput: data
      }
    })
  } catch (error) {
    console.log("Error", error)
  }
}
