import * as React from "react"
import { SingleDatePicker } from "react-dates"
import moment from "moment"
import "./single-date-picker.css"
import "react-dates/lib/css/_datepicker.css"

interface IProps {
  date: moment.Moment | null
  naturallyFocused?: boolean
  onDateChange: (date: moment.Moment | null) => void
  id: string
  placeholder?: string
  outSideRange?: any
}

interface IState {
  focused: boolean | null
}

class DatePickerWrapper extends React.PureComponent<IProps, IState> {
  state: IState = {
    focused: false
  }
  render() {
    return (
      <SingleDatePicker
        numberOfMonths={1}
        withPortal={window.matchMedia("(max-width:250px)").matches}
        focused={this.state.focused || false}
        // date={this.props.date}
        // onDateChange={this.props.onDateChange}
        onFocusChange={({ focused }) => this.setState({ focused })}
        horizontalMargin={50}
        isOutsideRange={() => this.props.outSideRange}
        {...this.props}
      />
    )
  }
}

export default DatePickerWrapper
