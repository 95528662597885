import * as React from "react"
import uploadImg from "../picture-picker/image-upload.png"
import "./style.scss"
import cancelIcon from "./cancel.png"
import Text from "../../text"
import Compressor from "compressorjs"

const PdfUploader = props => {
  const { form, field, labelTxt } = props
  const [pdf, setPdf] = React.useState<any>(field.value || "")
  const [type, setType] = React.useState<any>("")
  const curItemType = getType(pdf);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [itemUrlForPreview, setItemUrlForPreview] = React.useState<string | null>(null);

  const handleDelete = () => {
    setPdf("");
    if (inputRef.current?.value) inputRef.current.value = "";
    form.setFieldValue(field.name, "")
  }

  const handlePdfChange: React.ChangeEventHandler<HTMLInputElement> = async e => {
    let file = e.target.files;
    if (!file?.length) return;
    setItemUrlForPreview(URL.createObjectURL(file[0]))

    let type = file[0]["type"].split("/")[1];
    setType(type)
    let fileReader = new FileReader()
    if (!type.startsWith('pdf')) {
      await new Compressor(file[0], {
        quality: 0.6,
        convertSize: 3000000, //3mb file
        success: compressedResult => {
          fileReader.readAsDataURL(compressedResult)
          fileReader.onload = (e: any) => {
            if (e.target.result) setPdf(e.target.result)
            form.setFieldValue(field.name, e.target.result)
          }
        },
        error(err) {
          console.log(err.message)
        }
      })
    } else {
      fileReader.readAsDataURL(file[0])
      fileReader.onload = (e: any) => {
        if (e.target?.result) setPdf(e.target.result)
        form.setFieldValue(field.name, e.target.result)
      }
    }
  }

  return (
    <div className="files" id="files">
      {labelTxt && <Text genre="labelText">{labelTxt}</Text>}
      <div className="files-upload">
        <input type="file" onChange={handlePdfChange} accept=".pdf,image/*" ref={inputRef} />
        <div className="files-type">
          <img loading="lazy" src={uploadImg} alt="" />
          <span> Click here to choose and upload</span>
          <span>Supported formats: PNG, JPEG, JPG, PDF</span>
        </div>
      </div>
      {(curItemType === 'IMAGE') ? (
        <div className="buildings-image-preview">
          <a href={(/^https?:\/\/.*\.(jpeg|png|jpg)$/).test(pdf) ? pdf : itemUrlForPreview} target="_blank" rel="noreferrer">
            <img loading="lazy" src={pdf} alt="" className="buildings-imageIcon" />
          </a>
          <img
            src={cancelIcon}
            loading="lazy"
            className="buildings-cancelIcon"
            alt="remove resource"
            onClick={handleDelete}
          />
          <div className="buildings-between-margin"></div>
        </div>
      )
        : (curItemType === 'PDF') ? (
          <div className="buildings-image-preview">

            <a href={(/https?:\/\/.*\.pdf$/).test(pdf) ? pdf : itemUrlForPreview} target="_blank" rel="noreferrer">
              <img
                src={"https://www.iconpacks.net/icons/2/free-pdf-icon-3375-thumb.png"}
                alt="pdf-files"
                loading="lazy"
                className="buildings-imageIcon"
              />
            </a>

            <div className="buildings-between-margin"></div>
          </div>
        ) : null}
    </div>
  )
}

export default PdfUploader


const getType = (url: string | undefined | null) => {
  if (typeof url === 'undefined' || (!url && typeof url === 'object')) return 'NIL';

  if (url.startsWith('data:image/') || (/^https?:\/\/.*\.(jpeg|png|jpg)$/).test(url)) return 'IMAGE';
  if (url.startsWith('data:application/pdf') || (/https?:\/\/.*\.pdf$/).test(url)) return 'PDF';

  return 'NIL';
}