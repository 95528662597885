import React from 'react'
import styled from "styled-components"
import { Spin } from "antd"

export const SpinWrapper = styled.div<any>`
  display: flex
  justify-content: center
  align-items: center
  height:${props => props.height+"%"}
`

export default function Spinner({height}) {
  return (
    <SpinWrapper height={height}>
    <Spin size={"large"}></Spin>
  </SpinWrapper>
  )
}
