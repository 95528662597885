import * as React from "react"
import styled from "styled-components"

import Box from "../box"
import Button from "../button"

const NavItem = styled(Box as any)`
  align-items: center;
  justify-content: center;
  height: 85%;
  padding-right: 40px;
  a {
    height: 100%;
  }
  &:last-child {
    padding-right: 0px;
  }
  @media (max-width: 500px) {
    padding-right: 24px;
    &:last-child {
      padding-right: 0px;
    }
  }
  @media (max-width: 450px) {
    padding-right: 18px;
    &:last-child {
      padding-right: 0px;
    }
  }
  @media (min-width: 901px) {
    padding-right: 48px;
    &:last-child {
      padding-right: 0px;
    }
  }
`

type Props = {
  isMenuOpen: boolean
  handleMenuToggle: () => void
}

const TabletDown = ({ isMenuOpen, handleMenuToggle }: Props) => (
  <Box>
    <NavItem>
      <Button onClick={() => handleMenuToggle()}>{isMenuOpen ? "Close" : "Menu"}</Button>
    </NavItem>
  </Box>
)

export default TabletDown
