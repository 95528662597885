import gql from "graphql-tag"

export const ANALATIC_HELP_CENTER_VIEW = gql`
  query helpCenterAnalytic {
    helpCenterAnalytic {
      totalPublished
      totalCount
      totalSeen
      totalAvgSeen
      totalPerDayPublished
      totalReadingAvg
    }
  }
`
