import React, { PureComponent, ReactNode } from "react"
import { Title } from "src/dashboards/components"
import { client } from "src/apollo"
import "src/dashboards/components/landlord-create-listing/style.css"
import { withRouter, RouteComponentProps } from "react-router-dom"
import Modal from "src/dashboards/components/modalComponent"
import {
  notificationToAdmin,
  notificationToAdminFromLandlord
} from "src/dashboards/screens/admin-access-building/utils/services"
import { notify } from "src/components/notification-toast"
import { MESSAGE, NOTIFICATION_MSG } from "src/constants/notification-mesage"
import { createPortal, unstable_batchedUpdates } from "react-dom"
import { AddButton } from "src/containers/home/UI"
import Loader from "src/dashboards/components/loader"
import Row from "src/components/row"
import { Colors } from "src/theme"
import { loadStripe } from "@stripe/stripe-js"
import { CHECKOUT } from "src/dashboards/screens/landlord-access-building/graphql/mutation"
import { formProps } from "src/components/FormComponents/types"
import { FormikProps } from "formik"
import { Elements } from "@stripe/react-stripe-js"
import "./style.scss"
import BuildingInput from "./stepper-form/building-info-input"
import BuildingPicture from "./stepper-form/building-picture-input"
import LandlordForm from "./stepper-form/landlord-form"
import CheckoutForm from "./stepper-form/checkout"
import { ActionBtn } from "src/dashboards/components/landlord-create-listing/utils/actionBtn"
import {
  createCity,
  createListWithUsProperty,
  createNeighborhood,
  createProperty,
  updateProperty
} from "src/dashboards/screens/landlord-access-building/utils/createUpdateProperty"
import { parseReqData } from "./parseReqData"
import { FetchCurrentUserMe, UserType } from "src/generated/graphql"
import { StateType } from "src/dashboards/screens/landlord-access-building/types"
import {
  getDeletedImages,
  getDeletedUnitImages
} from "src/dashboards/components/landlord-create-listing/utils/deleteImage"
import FirstUnitForm from "./stepper-form/unit-first-form"
import SecondUnitForm from "./stepper-form/unit-second-form"
import ThirdUnitForm from "./stepper-form/unit-third-form"
import BasicPlan from "./basic-plan"
import FinalSteps from "./stepper-form/final-step"
import { loginRequest } from "src/hoc/authentication/lock"
import AuthenticationState from "src/hoc/authentication/authentication.state"
import { updateUserTypeListWithUs } from "src/services"

import LdgService from "src/components/ldg-ai"
import DisplayLdg from "src/components/ldg-ai/display"
import { DISABLE } from "src/components/ldg-ai/const.js"
const stripeApiKey = process.env.REACT_APP_STRIPE_API_KEY as string
type CityType = {
  name: string
  lat: number
  lng: number
}
interface Props {
  // client: ApolloClient<NormalizedCacheObject>
  id?: string
  edit?: boolean
  child?: ReactNode
  user?: FetchCurrentUserMe
  fetchListings?: () => void
  showBtn?: boolean
  authState?: AuthenticationState
  createdFrom?: string
  ldg: any
}

type FormikActions = Omit<
  formProps,
  "fields" | "renderCustomSubmit" | "btnText" | "giveButtonsFormikActions"
> &
  FormikProps<any>
type ActionsProps = {
  payLater?: LandlordCreateListingRoot["payLater"]
  /**
   * will be given to us by the form using this `Actions` component.
   * See Landlord form for more.
   */
  __formikActions?: FormikActions
}

var saveNContinue = false
var isEdit = false
class LandlordCreateListingRoot extends PureComponent<Props & RouteComponentProps, StateType> {
  state = {
    updatedListingId: "",
    open: false,
    isEditListing: this.props.edit,
    fullWidth: true,
    step: 1,
    step1: {
      propertyType: "",
      address: "",
      buildingTitle: "",
      des: "",
      units: null,
      floors: null,
      contractions: "",
      basement: "",
      role: ""
    },
    step2: {
      utilities: [],
      amenities: [],
      pets: [],
      tenantRequirements: [],
      tags: [],
      pictures: []
    },
    step3: {
      title: "",
      startDateAvailability: "",
      bathrooms: "",
      bedrooms: "",
      den: 0,
      deposit: "",
      monthlyPrice: "",
      furnished: false
    },
    step4: {
      number: "",
      parking: "",
      leaseTerm: 0,
      parkingSpots: 0,
      storage: "",
      maintenance: "",
      smokeAlarm: "",
      picture: []
    },
    step5: {
      floorPlan: "",
      unitamenities: [],
      unitAppliances: []
    },
    step6: {
      subscriptions: []
    },
    step7: {
      advertiseUnit: "",
      existingUrl: "",
      advertiseDuration: "",
      currentlyTenanted: "",
      desiredRentalPrice: 0,
      minimumRentalPrice: 0,
      extraInfo: "",
      showingUnit: "",
      customiseLease: "",
      signature: [],
      govtProof: [],
      ownershipProof: "",
      propertyRealtor: "",
      partnershipStatus: "",
      spousePartner: "",
      lockbox: "",
      commission: ""
      // view_management_deposit: false
    },
    title: "",
    unitId: "",
    neighbourhood: {},
    updateState1: {},
    loading: false,
    picture: [],
    confirOpen: false,
    unitPicture: [],
    listingsData: {},
    openAlert: false,
    step8: {},
    saveChanges: false,
    isUpdateListing: false,
    listingId: "",
    allAccess: {},
    planSelected: "",
    stripe: null,
    price_id: "",
    unit: "",
    submit: false,
    isSubscriptionClicked: false,
    payingNow: true,
    charge_id: "",
    amount: "",
    payment_status: "",
    setDisabled: true,
    isRentalOrTenantLeads: false,
    isBasicFree: false,
    isViewManagement: false,
    isConcierge: false,
    isRentalAssistance: false,
    isLeadGeneartor: false,
    region: "",
    isPremium: false,
    premiumName: "",
    userType: "LANDLORD",
    formData: {},
    phone: ""
  }

  handleClickOpen = async () => {
    const { showBtn } = this.props
    if (showBtn || this.state.allAccess?.["add"])
      this.setState({ open: true, isUpdateListing: true })
    else notify(NOTIFICATION_MSG.error, MESSAGE.NoAccess)
  }

  handleEditClick = async () => {
    const { showBtn } = this.props
    if (showBtn || this.state.allAccess?.["edit"])
      this.setState({ open: true, isUpdateListing: true })
    else notify(NOTIFICATION_MSG.error, MESSAGE.NoAccess)
  }

  handleNext = step => {
    step = step + 1
    this.setState({ step })
  }

  handleBack = step => {
    step = step - 1
    this.setState({ step })
  }

  onSaveNContinue = () => {
    saveNContinue = true
    setTimeout(async () => {
      saveNContinue = false
      this.setState({ loading: true })
      const { step1, step2, step3, step4, step5, step6, step8 } = this.state
      const { user, createdFrom, ldg } = this.props
      const data: any = {
        ...step1,
        ...step2,
        ...step3,
        ...step4,
        ...step5,
        ...step6,
        ...step8,
        des: !DISABLE ? ldg.aiDesc : step1.des
      }
      if (!this.props.edit) {
        const { address } = data
        const cityReqData: CityType = {
          name: address.city,
          lat: address?.geometry.lats,
          lng: address?.geometry.lngs
        }
        const respData = await createCity(cityReqData, client)
        if (respData)
          var resp = await createNeighborhood(
            client,
            data?.address.title.split(",")[2],
            respData?.id
          )
      }
      const req = parseReqData(data, this.props.edit, resp?.id, user)
      const {
        data: { createListing }
      } = await createProperty(req, client, createdFrom)
      if (this.props.history)
        this.props.history.push({
          pathname: `/unit/${createListing.id}`,
          state: {
            showModal: true
          }
        })
      this.setState({ loading: false })
    }, 400)
  }

  updateListingEveryPage = async (data: any) => {
    if (!DISABLE) {
      data.des = this.props.ldg.aiDesc
    }

    const { updatedListingId, isEditListing, picture, unitPicture, step7 } = this.state
    const { govtProof, signature } = step7
    const { user, history } = this.props
    let neighborhoodId: any = null
    if (data?.address?.city) {
      const { address } = data
      const cityReqData: CityType = {
        name: address.city,
        lat: address?.geometry.lats,
        lng: address?.geometry.lngs
      }
      let respData = await createCity(cityReqData, client)
      if (respData) {
        neighborhoodId = await createNeighborhood(client, data.address.neighborhood, respData?.id)
      }
    }
    const req = parseReqData(data, isEditListing, neighborhoodId?.id, user)

    if (picture?.length > 0) getDeletedImages(picture, req.pictures, client)
    if (unitPicture.length > 0) getDeletedUnitImages(unitPicture, req.picture, client)
    if (govtProof?.length > 0) getDeletedUnitImages(govtProof, req.govtProof, client)
    if (signature?.length > 0) getDeletedUnitImages(signature, req.signature, client)
    const newImages = [] as any
    const unitnewImages = [] as any

    const govtProofnewImages = [] as any
    const signaturenewImages = [] as any
    req.pictures?.map(img => {
      if (!img.id) {
        delete img["id"]
        newImages?.push(img)
      }
      return null
    })
    req.picture?.map(img => {
      if (!img.id) {
        delete img["compress"]
        unitnewImages.push(img)
      }
      return null
    })

    req.govtProof?.map(img => {
      if (!img.id) {
        delete img["id"]
        govtProofnewImages?.push(img)
      }
      return null
    })

    req.signature?.map(img => {
      if (!img.id) {
        delete img["id"]
        signaturenewImages?.push(img)
      }
      return null
    })
    const { unitId, neighbourhood } = this.state
    const updateResp = await updateProperty(
      updatedListingId,
      client,
      req,
      newImages,
      unitId,
      unitnewImages,
      neighborhoodId?.id,
      neighbourhood,
      govtProofnewImages,
      signaturenewImages
    )

    if (updateResp && updateResp.data) {
      unstable_batchedUpdates(() => {
        this.closeModal()
        this.setState({
          saveChanges: false
        })
      })
      if (user && user.userType === UserType.Landlord)
        history.push({
          pathname: "/building"
        })
      else if (user && user.userType === UserType.SubAdmin)
        history.push({
          pathname: "/listings"
        })
      else {
        history.push({
          pathname: isEdit ? "/building" : "/listings"
        })
      }
      isEdit = false
      notify(NOTIFICATION_MSG.Success, "Building has been updated successfully")
      if (this.props.fetchListings) this.props.fetchListings()
    } else {
      notify(NOTIFICATION_MSG.error, updateResp?.toString() || MESSAGE.SomethingWrong)
    }
    this.setState({ loading: false })
  }

  handleSaveOnEveryPage = (formikActions: FormikActions | undefined) => {
    if (formikActions) formikActions.submitForm()

    this.setState({ loading: true })
    saveNContinue = true
    setTimeout(async () => {
      saveNContinue = false
      const { step2, step3, step4, step5, step6, step7, updateState1, step1, step } = this.state
      let data: any = null
      switch (step) {
        case 1:
          data = { ...updateState1, ...step2, ...step3, ...step4, ...step5, ...step6, ...step7 }
          this.updateListingEveryPage(data)
          break
        case 2:
          data = { ...updateState1, ...step1, ...step3, ...step4, ...step5, ...step6, ...step7 }
          this.updateListingEveryPage(data)
          break
        case 3:
          data = { ...updateState1, ...step1, ...step2, ...step4, ...step5, ...step6, ...step7 }
          this.updateListingEveryPage(data)
          break
        case 4:
          data = { ...updateState1, ...step1, ...step2, ...step3, ...step5, ...step6, ...step7 }
          this.updateListingEveryPage(data)
          break
        case 5:
          data = { ...updateState1, ...step1, ...step2, ...step3, ...step4, ...step6, ...step7 }
          this.updateListingEveryPage(data)
          break
        case 6:
          data = { ...updateState1, ...step1, ...step2, ...step3, ...step4, ...step5, ...step7 }
          this.updateListingEveryPage(data)
          break
        case 7:
          data = { ...updateState1, ...step1, ...step2, ...step3, ...step4, ...step5, ...step6 }
          this.updateListingEveryPage(data)
          break
      }
    })
  }

  handleClose = () => {
    if (!this.state.saveChanges) {
      this.setState({ confirOpen: false, open: false, step: 1 })
    } else {
      this.setState({ confirOpen: true })
    }
  }

  onClose = () => {
    this.setState({ confirOpen: false })
  }

  handleOk = () => {
    this.setState({
      open: false,
      step1: {},
      step2: {},
      step3: {},
      step4: {},
      step5: {},
      step6: {},
      step7: {},
      step: 1,
      confirOpen: false
    })
  }

  handleStepOne = (payload: any, resetForm: any) => {
    const { region, isEditListing, step1 } = this.state
    this.setState({ region: !isEditListing ? payload?.address?.region?.toLowerCase() : region })
    this.setState({ phone: !isEditListing ? payload?.phone : null })
    this.setState({ step1: payload, step: 2 })
  }

  handleStepTwo = (payload: any, resetForm: any) => {
    this.setState({ step2: payload, step: 3 })
  }

  handleStepThree = (payload: any, resetForm: any) => {
    const finalPayload = {
      ...payload,
      deposit: payload.deposit ? payload.deposit : 0,
      monthlyPrice: payload.monthlyPrice ? payload.monthlyPrice : 0
    }
    this.setState({ step3: finalPayload, step: 4 })
  }

  handleStepFour = (payload: any, resetForm: any) => {
    this.setState({ step4: payload, step: 5 })
  }

  handleStepFive = (payload: any, resetForm: any) => {
    this.setState({ step5: payload, step: 6 })
  }

  handleStepSix = (payload: any) => {
    const { step1, step2, step3, step4, step5, step6, isEditListing } = this.state
    const data = { ...step1, ...step2, ...step3, ...step4, ...step5, ...payload }
    this.setState({ step8: data, step6: payload })
  }

  submitListing = async (payload: any, resetForm: any) => {
    const {
      step1,
      step2,
      step3,
      step4,
      step5,
      step6,
      isEditListing,
      region,
      isPremium,
      premiumName,
      isBasicFree,
      isViewManagement,
      isConcierge,
      isRentalAssistance,
      isLeadGeneartor,
      phone
    } = this.state
    const { user, createdFrom, ldg } = this.props

    this.setState({ step7: payload, loading: true })
    let data
    if (isEditListing) {
      data = {
        ...step1,
        ...step2,
        ...step3,
        ...step4,
        ...step5,
        ...step6,
        ...payload
      }
    } else {
      data = {
        ...step1,
        ...step2,
        ...step3,
        ...step4,
        ...step5,
        ...step6,
        ...payload,
        region,
        isPremium,
        premiumName,
        isBasicFree,
        isViewManagement,
        isConcierge,
        isRentalAssistance,
        isLeadGeneartor
      }
    }
    if (isEditListing) this.updateListingEveryPage(data)
    else {
      if (!DISABLE) {
        data.des = ldg.aiDesc
      }
      this.setState({ submit: true })
      if (this.props?.authState?.state?.user?.id)
        await updateUserTypeListWithUs(client, {
          id: this.props?.authState?.state?.user?.id,
          phone: phone
        })

      let neighborhoodId: any = null
      if (data?.address?.city) {
        const { address } = data
        const cityReqData: CityType = {
          name: address.city,
          lat: address?.geometry.lats,
          lng: address?.geometry.lngs
        }
        let respData = await createCity(cityReqData, client)
        if (respData) {
          neighborhoodId = await createNeighborhood(client, data.address.neighborhood, respData?.id)
        }
      }
      const req = parseReqData(data, isEditListing, neighborhoodId?.id, user)
      const resp = await createListWithUsProperty(req, client, createdFrom)
      if (resp && resp.data) {
        const { id } = resp.data.createListWithUs
        this.setState({ listingId: id })
        this.closeModal()
        if (this.props.fetchListings) this.props.fetchListings()
        notify(NOTIFICATION_MSG.Success, "Building has been created successfully")
      } else {
        notify(NOTIFICATION_MSG.error, resp?.tostring() || MESSAGE.SomethingWrong)
      }

      this.setState({ loading: false })
    }
    const address: any = step1.address
    const payloadData = {
      address: address?.title,
      premiumName: premiumName
    }
    if (this.props.authState?.state.user?.userType !== UserType.Admin)
      notificationToAdminFromLandlord(client, payloadData)
    else notificationToAdmin(client, payloadData)

    // window.location.href = '/'
  }
  onFormChange = () => {
    if (!this.state.saveChanges) {
      this.setState({ saveChanges: true })
    }
  }

  payLater = async (formikActions?: FormikActions) => {
    this.setState({ payingNow: false })

    // console.group('FORMIK ACTIONS');
    // console.info(formikActions);
    // console.groupEnd();

    formikActions?.submitForm()
    // await this.submitListing(data, payload)
  }

  redirectToCheckout = async (price, listingId, email) => {
    try {
      const { data } = await client.mutate({
        mutation: CHECKOUT,
        variables: { price: price, listingId: listingId, email: email }
      })
      const url = data.createCheckoutSession.url
      window.location.href = `${url}`
    } catch (error) {
      console.error("Error redirecting to checkout:", error)
    }
  }

  handleSubscription = async (
    subscriptions: {
      id: number
      type: string
      price: string
      title: string
      plan_group: string
      unit: string
    }[]
  ) => {
    if (subscriptions.length) {
      const hasViewManagement = subscriptions.some(item => {
        return item.id.toString() === "7"
      })

      if (hasViewManagement)
        this.setState({
          isViewManagement: hasViewManagement,
          isPremium: true,
          premiumName: "Viewings Management"
        })
      else {
        this.setState({ isViewManagement: false })
      }

      const hasBasicFree = subscriptions.some(item => {
        return item.id.toString() === "5"
      })

      if (hasBasicFree) {
        this.setState({ isBasicFree: hasBasicFree, isPremium: false, premiumName: "Basic(Beta)" })
      } else {
        this.setState({ isBasicFree: false })
      }

      const hasConcierge = subscriptions.some(item => {
        return item.id.toString() === "3"
      })

      if (hasConcierge)
        this.setState({
          isConcierge: hasConcierge,
          isPremium: true,
          premiumName: "Concierge(Property Management)"
        })
      else {
        this.setState({ isConcierge: false })
      }

      const hasRentalAssistance = subscriptions.some(item => {
        return item.id.toString() === "4"
      })

      const hasLeadGenerator = subscriptions.some(item => {
        return item.id.toString() === "6"
      })

      if (hasRentalAssistance)
        this.setState({
          isPremium: true,
          premiumName: "Rental Assistance(Tenant Placement)",
          isRentalAssistance: hasRentalAssistance
        })
      else {
        this.setState({ isRentalAssistance: false })
      }

      if (hasLeadGenerator)
        this.setState({
          isPremium: true,
          premiumName: "Tenant Lead Generator(Qualified lead Referral)",
          isLeadGeneartor: hasLeadGenerator
        })
      else {
        this.setState({ isLeadGeneartor: false })
      }
      if (hasRentalAssistance || hasLeadGenerator) {
        this.setState({ isRentalOrTenantLeads: true })
      } else {
        this.setState({ isRentalOrTenantLeads: false })
      }
      this.setState({ setDisabled: false })
    } else {
      this.setState({
        setDisabled: true,
        isBasicFree: false,
        isViewManagement: false,
        isRentalOrTenantLeads: false,
        isConcierge: false,
        isLeadGeneartor: false,
        isRentalAssistance: false
      })
    }

    let payload = { subscriptions }

    this.handleStepSix(payload)
  }

  onClickStepSixNext = async () => {
    const {
      step1,
      step2,
      step3,
      step4,
      step5,
      step6,
      isEditListing,
      step,
      isBasicFree,
      isConcierge,
      isViewManagement,
      isRentalAssistance,
      isLeadGeneartor,
      userType
    } = this.state
    const { user } = this.props.authState!.state
    const data = {
      ...step1,
      ...step2,
      ...step3,
      ...step4,
      ...step5,
      ...step6
    }

    let payload = {}

    if (data.subscriptions.length) {
      const { subscriptions } = data
      const Concierge = subscriptions.some(item => {
        return item.id.toString() === "3"
      })

      const BasicFree = subscriptions.some(item => {
        return item.id.toString() === "5"
      })

      if (Concierge) {
        payload = {
          isConcierge: Concierge,
          isPremium: true,
          premiumName: "Concierge(Property Management)"
        }
      } else if (BasicFree) {
        payload = { isBasicFree: BasicFree, isPremium: false, premiumName: "Basic(Beta)" }
      }
    }

    const submitData = { data, ...payload }

    if (
      !isEditListing &&
      (isBasicFree || isConcierge) &&
      !isViewManagement &&
      !isRentalAssistance &&
      !isLeadGeneartor
    ) {
      if (!user) {
        loginRequest(true, false, this.props.authState!).then(() => {
          if (this.props.authState?.state.user.userType === "UNVERIFIED") {
            updateUserTypeListWithUs(client, {
              id: this.props.authState.state.user.id,
              userType: userType
            })
            // @ts-expect-error
            this.props.authState?.setState(prev => ({
              ...prev,
              user: { ...(prev.user ?? {}), userType: step1.userType as UserType }
            }))
            this.submitListing(submitData, {})
          } else if (this.props.authState?.state.user.userType === "RENTER") {
            if (step1.role === "Lease_Assignor" || step1.role === "Sub-Lessor") {
              updateUserTypeListWithUs(client, {
                id: this.props.authState.state.user.id,
                userType: userType
              })
              // @ts-expect-error
              this.props.authState?.setState(prev => ({
                ...prev,
                user: { ...(prev.user ?? {}), userType: step1.userType as UserType }
              }))
              this.submitListing(submitData, {})
            } else {
              notify(NOTIFICATION_MSG.error, `User Unauthorized`)
              return false
            }
          }
        })
      } else if (user!.userType === "RENTER" || user!.userType === "UNVERIFIED") {
        if (step1.role === "Lease_Assignor" || step1.role === "Sub-Lessor") {
          this.submitListing(submitData, {})
        } else {
          notify(NOTIFICATION_MSG.error, `User Unauthorized`)
          return false
        }
      } else {
        this.submitListing(submitData, {})
      }
    } else {
      this.handleNext(step)
    }
  }

  renderHeader = (step: number, edit: boolean) => {
    if (!edit) {
      switch (step) {
        case 1 || 2 || 3:
          return <Title lable="Add Property" />
        case 4 || 5 || 7:
          return <Title lable="Add Units" />
        case 6:
          return <Title lable="Choose Plan" />
        case 8:
          return <Title lable="" />
        case 9:
          return <Title lable="Payment" />
        default:
          return <Title lable="Add Units" />
      }
    } else {
      switch (step) {
        case 1 || 2 || 3:
          return <Title lable="Update Property" />
        case 4 || 5 || 7:
          return <Title lable="Update Units" />
        case 6:
          return <Title lable="Plan Selected" />
        case 8:
          return <Title lable="" />
        case 9:
          return <Title lable="Payment" />
        default:
          return <Title lable="Update Units" />
      }
    }
  }

  renderForms = (step: number) => {
    const {
      step1,
      step2,
      step3,
      step4,
      step5,
      step6,
      step7,
      isEditListing,
      step8,
      loading,
      price_id,
      setDisabled,
      isRentalOrTenantLeads,
      isBasicFree,
      isViewManagement,
      isConcierge,
      isRentalAssistance,
      isLeadGeneartor,
      region,
      isPremium,
      premiumName,
      userType,
      formData
    } = this.state
    const { user } = this.props.authState!.state
    const { ldg } = this.props
    const Actions = ({ payLater, __formikActions }: ActionsProps & ReactNode) => {
      return (
        <ActionBtn
          loading={this.state.loading}
          step={step}
          handleBack={this.handleBack}
          edit={isEditListing}
          onSaveNContinue={this.onSaveNContinue}
          onSaveContinueFirst={this.handleSaveOnEveryPage.bind(this, __formikActions)}
          handleClose={this.handleClose}
          payLater={payLater?.bind(this, __formikActions)}
        />
      )
    }

    switch (step) {
      case 1:
        return (
          <BuildingInput
            initialValues={step1}
            actionBtn={<Actions />}
            onSubmit={(data, payload) => {
              ldg.collectData(data, "Basic")
              if (saveNContinue) {
                this.setState({ updateState1: data })
              } else {
                this.handleStepOne(data, payload)
              }
            }}
            client={client}
            onFormChange2={this.onFormChange}
          />
        )
      case 2:
        return (
          <BuildingPicture
            initialValues={step2}
            actionBtn={<Actions />}
            onSubmit={(data, payload) => {
              ldg.collectData(data, "Building")
              if (saveNContinue) {
                this.setState({ updateState1: data })
              } else {
                this.handleStepTwo(data, payload)
              }
            }}
            onFormChange2={this.onFormChange}
            editMode={isEditListing}
          />
        )
      case 3:
        return (
          <FirstUnitForm
            initialValues={step3}
            actionBtn={<Actions />}
            onSubmit={(data, payload) => {
              ldg.collectData(data, "FirstUnit")
              if (saveNContinue) {
                this.setState({ updateState1: data })
              } else {
                this.handleStepThree(data, payload)
              }
            }}
            edit={isEditListing}
            onFormChange2={this.onFormChange}
          />
        )

      case 4:
        return (
          <SecondUnitForm
            initialValues={step4}
            actionBtn={<Actions />}
            onSubmit={(data, payload) => {
              ldg.collectData(data, "SecondUnit")
              if (saveNContinue) {
                this.setState({ updateState1: data })
              } else {
                this.handleStepFour(data, payload)
              }
            }}
            onFormChange2={this.onFormChange}
          />
        )
      case 5:
        return (
          <ThirdUnitForm
            initialValues={step5}
            actionBtn={<Actions />}
            onSubmit={(data, payload) => {
              ldg.collectData(data, "ThirdUnit")
              if (saveNContinue) {
                this.setState({ updateState1: data })
              } else {
                this.handleStepFive(data, payload)
              }
            }}
            onFormChange2={this.onFormChange}
          />
        )
      case 6:
        return (
          <DisplayLdg {...ldg} onPrev={() => this.handleBack(step)}>
            {showDisplay => (
              <>
                <BasicPlan
                  handleSubscription={this.handleSubscription}
                  region={region}
                  client={client}
                />
                <Row my={3} width={"auto"} justifyContent="space-between" flexWrap="wrap-reverse">
                  <AddButton
                    genre="add-new"
                    width={130}
                    border={`1px solid ${Colors.primary}`}
                    color={Colors.primary}
                    mx={0}
                    backgroundColor="transparent"
                    onClick={showDisplay}
                  >
                    Previous
                  </AddButton>

                  <AddButton width={160} onClick={this.onClickStepSixNext} disabled={setDisabled}>
                    {!isEditListing &&
                      (isBasicFree || isConcierge) &&
                      !isViewManagement &&
                      !isRentalAssistance &&
                      !isLeadGeneartor
                      ? !user
                        ? "Login or Sign Up"
                        : "Submit"
                      : "Next"}
                  </AddButton>
                </Row>
              </>
            )}
          </DisplayLdg>
        )
      case 7:
        return (
          <LandlordForm
            initialValues={step7}
            actionBtn={
              <Actions
                payLater={this.payLater}
                isBasicFree={isBasicFree}
                isViewManagement={isViewManagement}
                isConcierge={isConcierge}
                isRentalAssistance={isRentalAssistance}
                isLeadGeneartor={isLeadGeneartor}
              />
            }
            onSubmit={async (data, payload) => {
              if (saveNContinue) {
                this.setState({ step8: data })
              } else {
                this.setState({ step7: data })
                const formData = {
                  ...step1,
                  ...step2,
                  ...step3,
                  ...step4,
                  ...step5,
                  ...step6,
                  ...data
                }
                if (isEditListing || !this.state.payingNow) {
                  this.submitListing(formData, payload)
                } else {
                  if (
                    !isEditListing &&
                    (isBasicFree || isConcierge) &&
                    !isViewManagement &&
                    !isRentalAssistance &&
                    !isLeadGeneartor
                  ) {
                    this.submitListing(formData, payload)
                  }
                  if (
                    !isEditListing &&
                    (isViewManagement || isRentalAssistance || isLeadGeneartor)
                  ) {
                    formData.price_id = price_id
                    formData.user = user
                    this.setState({ formData: formData })
                    this.handleNext(step)
                  }
                }
              }
            }}
            edit={isEditListing}
            onFormChange2={this.onFormChange}
            region={region}
            isRentalOrTenantLeads={isRentalOrTenantLeads}
            user={step1.role}
            isBasicFree={isBasicFree}
            isViewManagement={isViewManagement}
            isConcierge={isConcierge}
            isRentalAssistance={isRentalAssistance}
            isLeadGeneartor={isLeadGeneartor}
          />
        )
      case 8:
        return (
          <>
            <FinalSteps />
            <Row my={3} width={"auto"} justifyContent="space-between" flexWrap="wrap-reverse">
              <AddButton
                genre="add-new"
                width={120}
                border={`1px solid ${Colors.primary}`}
                color={Colors.primary}
                mx={0}
                backgroundColor="transparent"
                onClick={() => this.handleBack(step)}
              >
                Previous
              </AddButton>

              <AddButton
                width={160}
                type="button"
                onClick={() => {
                  if (!user) {
                    loginRequest(true, false, this.props.authState!).then(() => {
                      if (this.props.authState?.state.user.userType === "UNVERIFIED") {
                        updateUserTypeListWithUs(client, {
                          id: this.props.authState.state.user.id,
                          userType: userType
                        })

                        // @ts-expect-error
                        this.props.authState?.setState(prev => ({
                          ...prev,
                          user: {
                            ...(prev.user ?? {}),
                            userType: userType as UserType
                          }
                        }))
                        this.handleNext(step)
                      } else if (this.props.authState?.state.user.userType === "RENTER") {
                        if (step1.role === "Lease_Assignor" || step1.role === "Sub-Lessor") {
                          updateUserTypeListWithUs(client, {
                            id: this.props.authState.state.user.id,
                            userType: userType
                          })

                          // @ts-expect-error
                          this.props.authState?.setState(prev => ({
                            ...prev,
                            user: {
                              ...(prev.user ?? {}),
                              userType: userType as UserType
                            }
                          }))
                          this.handleNext(step)
                        } else {
                          notify(NOTIFICATION_MSG.error, "User Unauthorized")
                          return false
                        }
                      }
                    })
                  } else if (user!.userType === "RENTER" || user!.userType === "UNVERIFIED") {
                    if (step1.role === "Lease_Assignor" || step1.role === "Sub-Lessor") {
                      this.handleNext(step)
                    } else {
                      notify(NOTIFICATION_MSG.error, `User Unauthorized`)
                      return false
                    }
                  } else {
                    this.handleNext(step)
                  }
                }}
              >
                {!user ? "Login or Sign Up" : "Pay"}
              </AddButton>
            </Row>
          </>
        )
      case 9:
        const stripePromise = loadStripe(stripeApiKey)
        return (
          <>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                submitListing={this.submitListing}
                user={user}
                region={region}
                payData={formData}
              />
            </Elements>
            <AddButton
              genre="add-new"
              width={120}
              border={`1px solid ${Colors.primary}`}
              color={Colors.primary}
              mx={0}
              backgroundColor="transparent"
              onClick={() => this.handleBack(step)}
            >
              Previous
            </AddButton>
          </>
          // <CheckoutForm
          //   initialValues={step5}
          //   actionBtn={<Actions />}
          //   onSubmit={(data, payload) => {
          //     if (saveNContinue) {
          //       this.setState({ updateState1: data })
          //     } else {
          //       this.handleStepFive(data, payload)
          //     }
          //   }}
          //   onFormChange2={this.onFormChange}
          // />
        )
      default:
        return (
          <BasicPlan handleSubscription={this.handleSubscription} region={region} client={client} />
        )
    }
  }

  closeModal = () => {
    this.setState({
      open: false,
      step1: {},
      step2: {},
      step3: {},
      step4: {},
      step5: {},
      step6: {},
      step7: {},
      step: 1
    })
  }

  render() {
    const { step, loading, confirOpen, isEditListing } = this.state

    return (
      <>
        <div className="buildings-form">
          <div className="buildings-form-title">
            {step === 9 ? (
              <p>
                Please enter all the details and click{" "}
                <span className="next-btn-bold">Continue</span> to pay.
              </p>
            ) : step === 8 ? null : step === 6 ? null : step === 7 ? (
              <p>
                Please enter all the details and click
                <span className="next-btn-bold">&nbsp;Final Step&nbsp;</span>to proceed.
              </p>
            ) : (
              <p>
                Please enter all the details and click
                <span className="next-btn-bold">&nbsp;Next&nbsp;</span>to proceed.
              </p>
            )}
          </div>
          {this.renderForms(step)}
        </div>

        <Modal
          isModalVisible={confirOpen}
          disabled={false}
          isSubmitting={false}
          handleCancel={this.onClose}
          handleOk={this.handleOk}
          btnText={"Confirm"}
          title={""}
        >
          <h4 style={{ textAlign: "center", fontSize: 20 }}>
            If you would like to cancel, all your changes will be discarded. Continue?
          </h4>
        </Modal>
        {createPortal(<Loader loader={loading} />, document.body)}
      </>
    )
  }
}

const LdgWrapper = props => (
  <LdgService>{ldgProps => <LandlordCreateListingRoot {...props} {...ldgProps} />}</LdgService>
)

// @ts-ignore will fix it later
export default withRouter(LdgWrapper)
