import React, { useState, useEffect } from 'react';
import axios from "axios";

import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';

import { FetchCurrentUserMe, UserType } from 'src/generated/graphql';

const stripeApiKey = process.env.REACT_APP_SECRET_API_KEY;

const PayButton1 = styled.button`
color:white;
background-color: rgba(112, 214, 33, 1);
border: 1px solid rgba(112,214,33,1);
padding:10px;
font-size: 16px;
font-weight: 400 !important;
margin-top: 20px;
margin-bottom: 30px;
border-radius: 1000px;
width:100%;
  cursor: pointer;
  &:hover {
    box-shadow: ${(props) =>
        props.disabled ? "none" : "2px 5px 15px 2px rgba(0, 0, 0, 0.2)"};
  }
`;
const FormInput = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;
const StyledCardElement1 = styled(CardElement)`
  background-color: white;
  background: transparent;
  border: none;
  font-size:16px;
  border-bottom: 1px solid #c2c2c2;
  padding: 10px 10px 10px;
  width: 100%;
  margin-top: 10px;
  @media (max-width: 768px) {
    padding: 10px 10px 10px;
  }
`;

const Input = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid #c2c2c2;
  width: 100%;
  padding: 10px;
  font-size:16px;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Table = styled.table`
  width: 100%;
  background-color: #f8f8f8;
  padding: 10px;
  font-size:16px;
  border-collapse: collapse;
  color: #424242;
`;

const Label = styled.label`
font-size: 17px;
line-height: 25.2px;
font-weight: 400;
font-family: Lato, 'Helvetica Neue', Helvetica, Georgia, Roboto, sans-serif;
color: #424242;

`;

const OuterDiv = styled.div`
padding:10px;
`;


const Tr = styled.tr`
  border-bottom: 1px solid #c2c2c2;
`;

const Td = styled.td`
padding: .5em;

`;

const ErrorMessage = styled.div`
  color: red;
`;


const CheckoutForm = ({
    submitListing,
    user,
    region,
    payData
}: {
    submitListing?: (data: any, resetForm: any) => void
    user?: any,
    region?: any,
    payData?: any
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [nameError, setNameError] = useState("");
    let amount = 7500;
    let totalAmount
    let taxRatePer
    let initialAmount = amount
    switch (region.toLowerCase()) {
        case "alberta":
        case "british columbia":
        case "manitoba":
        case "northwest territories":
        case "nunavut":
        case "quebec":
        case "saskatchewan":
        case "yukon":
            taxRatePer = 5;
            break;
        case "new brunswick":
        case "newfoundland and labrador":
        case "nova scotia":
        case "prince edward island":
            taxRatePer = 15;
            break;
        case "ontario":
            taxRatePer = 13;
            break;
        default:
            taxRatePer = 13;
    }
    totalAmount = (((taxRatePer + 100) * amount) / 100).toFixed(2)
    amount = parseFloat(totalAmount)

    payData.subscriptions.map((item) => {
        if (item.title.toLowerCase() === 'viewings management') {
            const totalAmountInCents = 30000; // in cents
            amount = totalAmountInCents
            initialAmount = totalAmountInCents
            totalAmount = (((taxRatePer + 100) * amount) / 100).toFixed(2)


            amount = parseFloat(totalAmount)

        }

    })
    payData.taxRatePer = taxRatePer
    payData.totalAmount = totalAmount
    payData.initialAmount = initialAmount

    const handleErrorMessage = (error) => {
        setMessage(error);
        setTimeout(() => {
            setMessage("");
        }, 3000);
    };


    const makeApiRequest = async (url, data, headers) => {
        try {
            const response = await axios.post(url, data, {
                headers: {
                    ...headers,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            return response.data;
        } catch (error) {
            handleErrorMessage('Something went wrong');
            throw error.response?.data?.error || error;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!customerName) {
            setNameError("Name is required");
            setTimeout(() => {
                setNameError("");
            }, 3000);
            return;
        }

        if (!stripe || !elements) {
            return;
        }
        setPaymentLoading(true);
        const cardElement = elements.getElement(CardElement);
        try {
            if (canContinue(user, payData)) {
                const { token, error } = await stripe.createToken(cardElement, {
                    name: customerName,
                });

                if (token?.id && !error) {
                    const requestDataCustomer = {
                        email: payData?.user?.email || user.email,
                        name: payData?.user?.firstName || user.user?.firstName || "",
                        source: token?.id,
                    }



                    const customerResponse = await makeApiRequest("https://api.stripe.com/v1/customers", requestDataCustomer, {
                        Authorization: `Bearer ${stripeApiKey}`,
                    });
                    const customerID = customerResponse.id;

                    const requestDataCharge = {
                        amount: amount,
                        currency: 'cad',
                        customer: customerID,

                    };
                    const response = await makeApiRequest("https://api.stripe.com/v1/charges", requestDataCharge, {
                        Authorization: `Bearer ${stripeApiKey}`,
                    });

                    const charge_id = response.id
                    const paid = response.paid
                    const status = response.status
                    const _amount = response.amount / 100
                    payData.charge_id = charge_id
                    payData.payment_status = status
                    payData.amount = _amount.toString()
                    submitListing(payData, "")
                }
                else {
                    handleErrorMessage(error?.message ? error.message : 'Something went wrong');
                }
            }
            else {
                setPaymentLoading(false)
                handleErrorMessage('Unauthorized Access');
                return false
            }



        } catch (error: any) {
            handleErrorMessage(typeof error === "string" ? error : error.message)
            console.error(error.message);
            setPaymentLoading(false);

            return false

        }

        setPaymentLoading(false)

    };

    return (
        <>

            <FormInput>
                <form onSubmit={handleSubmit}>
                    <div>
                        <span>
                            <OuterDiv>
                                <div>
                                    <Label>Name on Card</Label>
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Name on Card"
                                        value={customerName}
                                        onChange={(e) => setCustomerName(e.target.value)}

                                    />
                                    {nameError && <ErrorMessage>{nameError}</ErrorMessage>}
                                </div>
                            </OuterDiv>
                            <OuterDiv>
                                <div>
                                    <Label>Receipt of Email</Label>
                                </div>
                                <div>
                                    <Input value={payData?.user?.email || user.email} />
                                </div>
                            </OuterDiv>
                            <OuterDiv >
                                <div>
                                    <Label>Card Details</Label>
                                </div>
                                <div>
                                    <StyledCardElement1 />
                                </div>
                            </OuterDiv>
                        </span>
                        <span>
                            <OuterDiv>
                                <Table>
                                    <tbody>
                                        {payData.subscriptions.map((item, index) => (
                                            <Tr key={index}>
                                                <Td>{item.title}</Td>
                                                <Td>${item.price} * 1</Td>
                                                <Td>${parseFloat(item.price).toFixed(2)}</Td>
                                            </Tr>
                                        ))}
                                        <Tr>
                                            <Td>On-Boarding Deposit</Td>
                                            <Td></Td>
                                            <Td>${(payData.initialAmount / 100).toFixed(2)}</Td>
                                        </Tr>
                                        <Tr>
                                            <Td >Tax</Td>
                                            <Td >{parseFloat(payData?.taxRatePer)}%</Td>
                                            <Td>
                                                ${(parseFloat(payData?.taxRatePer) * (parseFloat(payData.initialAmount) / 100) / 100).toFixed(2)}
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td >Total Amount</Td>
                                            <Td ></Td>
                                            <Td>
                                                ${(payData.totalAmount / 100).toFixed(2)}
                                            </Td>

                                        </Tr>
                                    </tbody>
                                </Table>
                            </OuterDiv>
                            <PayButton1 disabled={isPaymentLoading} type="submit">
                                {isPaymentLoading ? "Loading..." : `Continue — $ ${(parseFloat(payData.totalAmount) / 100).toFixed(2)}`}
                            </PayButton1>

                        </span>
                    </div>
                    {message && <ErrorMessage>{JSON.stringify(message)}</ErrorMessage>}
                </form>
            </FormInput>
        </>
    );
};

export default CheckoutForm

const canContinue = (user: FetchCurrentUserMe | null, payData) => {
    if (!user) return false;

    switch (user.userType) {
        case UserType.Admin:
        case UserType.SubAdmin:
        case UserType.Landlord:
            return true;
        case UserType.Renter:
        case UserType.Unverified:
            if (payData.role === "Lease_Assignor" || payData.role === "Sub-Lessor") return true;
            return false;
        default:
            return true;
    }
} 