export type Maybe<T> = T | null

export interface UserWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<UserWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<UserWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<UserWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. *avatarUrl/
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  email?: Maybe<string>
  /** All values that are not equal to given value. */
  email_not?: Maybe<string>
  /** All values that are contained in given list. */
  email_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  email_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  email_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  email_lte?: Maybe<string>
  /** All values greater than the given value. */
  email_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  email_gte?: Maybe<string>
  /** All values containing the given string. */
  email_contains?: Maybe<string>
  /** All values not containing the given string. */
  email_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  email_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  email_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  email_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  email_not_ends_with?: Maybe<string>

  auth0id?: Maybe<string>
  /** All values that are not equal to given value. */
  auth0id_not?: Maybe<string>
  /** All values that are contained in given list. */
  auth0id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  auth0id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  auth0id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  auth0id_lte?: Maybe<string>
  /** All values greater than the given value. */
  auth0id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  auth0id_gte?: Maybe<string>
  /** All values containing the given string. */
  auth0id_contains?: Maybe<string>
  /** All values not containing the given string. */
  auth0id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  auth0id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  auth0id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  auth0id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  auth0id_not_ends_with?: Maybe<string>

  firstName?: Maybe<string>
  /** All values that are not equal to given value. */
  firstName_not?: Maybe<string>
  /** All values that are contained in given list. */
  firstName_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  firstName_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  firstName_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  firstName_lte?: Maybe<string>
  /** All values greater than the given value. */
  firstName_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  firstName_gte?: Maybe<string>
  /** All values containing the given string. */
  firstName_contains?: Maybe<string>
  /** All values not containing the given string. */
  firstName_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  firstName_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  firstName_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  firstName_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  firstName_not_ends_with?: Maybe<string>

  lastName?: Maybe<string>
  /** All values that are not equal to given value. */
  lastName_not?: Maybe<string>
  /** All values that are contained in given list. */
  lastName_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  lastName_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  lastName_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  lastName_lte?: Maybe<string>
  /** All values greater than the given value. */
  lastName_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  lastName_gte?: Maybe<string>
  /** All values containing the given string. */
  lastName_contains?: Maybe<string>
  /** All values not containing the given string. */
  lastName_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  lastName_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  lastName_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  lastName_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  lastName_not_ends_with?: Maybe<string>

  legalName?: Maybe<string>
  /** All values that are not equal to given value. */
  legalName_not?: Maybe<string>
  /** All values that are contained in given list. */
  legalName_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  legalName_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  legalName_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  legalName_lte?: Maybe<string>
  /** All values greater than the given value. */
  legalName_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  legalName_gte?: Maybe<string>
  /** All values containing the given string. */
  legalName_contains?: Maybe<string>
  /** All values not containing the given string. */
  legalName_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  legalName_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  legalName_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  legalName_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  legalName_not_ends_with?: Maybe<string>

  userType?: Maybe<UserType>
  /** All values that are not equal to given value. */
  userType_not?: Maybe<UserType>
  /** All values that are contained in given list. */
  userType_in?: Maybe<UserType[]>
  /** All values that are not contained in given list. */
  userType_not_in?: Maybe<UserType[]>

  avatarUrl?: Maybe<string>
  /** All values that are not equal to given value. */
  avatarUrl_not?: Maybe<string>
  /** All values that are contained in given list. */
  avatarUrl_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  avatarUrl_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  avatarUrl_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  avatarUrl_lte?: Maybe<string>
  /** All values greater than the given value. */
  avatarUrl_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  avatarUrl_gte?: Maybe<string>
  /** All values containing the given string. */
  avatarUrl_contains?: Maybe<string>
  /** All values not containing the given string. */
  avatarUrl_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  avatarUrl_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  avatarUrl_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  avatarUrl_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  avatarUrl_not_ends_with?: Maybe<string>

  industry?: Maybe<string>
  /** All values that are not equal to given value. */
  industry_not?: Maybe<string>
  /** All values that are contained in given list. */
  industry_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  industry_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  industry_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  industry_lte?: Maybe<string>
  /** All values greater than the given value. */
  industry_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  industry_gte?: Maybe<string>
  /** All values containing the given string. */
  industry_contains?: Maybe<string>
  /** All values not containing the given string. */
  industry_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  industry_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  industry_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  industry_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  industry_not_ends_with?: Maybe<string>

  bio?: Maybe<string>
  /** All values that are not equal to given value. */
  bio_not?: Maybe<string>
  /** All values that are contained in given list. */
  bio_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  bio_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  bio_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  bio_lte?: Maybe<string>
  /** All values greater than the given value. */
  bio_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  bio_gte?: Maybe<string>
  /** All values containing the given string. */
  bio_contains?: Maybe<string>
  /** All values not containing the given string. */
  bio_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  bio_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  bio_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  bio_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  bio_not_ends_with?: Maybe<string>

  locale?: Maybe<string>
  /** All values that are not equal to given value. */
  locale_not?: Maybe<string>
  /** All values that are contained in given list. */
  locale_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  locale_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  locale_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  locale_lte?: Maybe<string>
  /** All values greater than the given value. */
  locale_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  locale_gte?: Maybe<string>
  /** All values containing the given string. */
  locale_contains?: Maybe<string>
  /** All values not containing the given string. */
  locale_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  locale_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  locale_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  locale_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  locale_not_ends_with?: Maybe<string>

  gender?: Maybe<string>
  /** All values that are not equal to given value. */
  gender_not?: Maybe<string>
  /** All values that are contained in given list. */
  gender_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  gender_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  gender_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  gender_lte?: Maybe<string>
  /** All values greater than the given value. */
  gender_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  gender_gte?: Maybe<string>
  /** All values containing the given string. */
  gender_contains?: Maybe<string>
  /** All values not containing the given string. */
  gender_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  gender_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  gender_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  gender_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  gender_not_ends_with?: Maybe<string>

  phone?: Maybe<string>
  /** All values that are not equal to given value. */
  phone_not?: Maybe<string>
  /** All values that are contained in given list. */
  phone_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  phone_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  phone_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  phone_lte?: Maybe<string>
  /** All values greater than the given value. */
  phone_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  phone_gte?: Maybe<string>
  /** All values containing the given string. */
  phone_contains?: Maybe<string>
  /** All values not containing the given string. */
  phone_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  phone_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  phone_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  phone_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  phone_not_ends_with?: Maybe<string>

  verified?: Maybe<boolean>
  /** All values that are not equal to given value. */
  verified_not?: Maybe<boolean>

  city?: Maybe<string>
  /** All values that are not equal to given value. */
  city_not?: Maybe<string>
  /** All values that are contained in given list. */
  city_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  city_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  city_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  city_lte?: Maybe<string>
  /** All values greater than the given value. */
  city_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  city_gte?: Maybe<string>
  /** All values containing the given string. */
  city_contains?: Maybe<string>
  /** All values not containing the given string. */
  city_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  city_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  city_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  city_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  city_not_ends_with?: Maybe<string>

  moveIn?: Maybe<string>
  /** All values that are not equal to given value. */
  moveIn_not?: Maybe<string>
  /** All values that are contained in given list. */
  moveIn_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  moveIn_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  moveIn_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  moveIn_lte?: Maybe<string>
  /** All values greater than the given value. */
  moveIn_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  moveIn_gte?: Maybe<string>
  /** All values containing the given string. */
  moveIn_contains?: Maybe<string>
  /** All values not containing the given string. */
  moveIn_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  moveIn_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  moveIn_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  moveIn_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  moveIn_not_ends_with?: Maybe<string>

  budget?: Maybe<string>
  /** All values that are not equal to given value. */
  budget_not?: Maybe<string>
  /** All values that are contained in given list. */
  budget_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  budget_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  budget_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  budget_lte?: Maybe<string>
  /** All values greater than the given value. */
  budget_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  budget_gte?: Maybe<string>
  /** All values containing the given string. */
  budget_contains?: Maybe<string>
  /** All values not containing the given string. */
  budget_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  budget_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  budget_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  budget_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  budget_not_ends_with?: Maybe<string>

  roommate?: Maybe<boolean>
  /** All values that are not equal to given value. */
  roommate_not?: Maybe<boolean>

  stripeId?: Maybe<string>
  /** All values that are not equal to given value. */
  stripeId_not?: Maybe<string>
  /** All values that are contained in given list. */
  stripeId_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  stripeId_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  stripeId_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  stripeId_lte?: Maybe<string>
  /** All values greater than the given value. */
  stripeId_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  stripeId_gte?: Maybe<string>
  /** All values containing the given string. */
  stripeId_contains?: Maybe<string>
  /** All values not containing the given string. */
  stripeId_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  stripeId_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  stripeId_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  stripeId_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  stripeId_not_ends_with?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  lastNewMessageNotificationTime_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  lastNewMessageNotificationTime_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  lastNewMessageNotificationTime_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  lastNewMessageNotificationTime_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  lastNewMessageNotificationTime_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  lastNewMessageNotificationTime_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  lastNewMessageNotificationTime_gte?: Maybe<DateTime>

  listings_every?: Maybe<ListingWhereInput>

  listings_some?: Maybe<ListingWhereInput>

  listings_none?: Maybe<ListingWhereInput>

  rooms_every?: Maybe<RoomWhereInput>

  rooms_some?: Maybe<RoomWhereInput>

  rooms_none?: Maybe<RoomWhereInput>

  createdRooms_every?: Maybe<RoomWhereInput>

  createdRooms_some?: Maybe<RoomWhereInput>

  createdRooms_none?: Maybe<RoomWhereInput>

  bookings_every?: Maybe<BookingWhereInput>

  bookings_some?: Maybe<BookingWhereInput>

  bookings_none?: Maybe<BookingWhereInput>

  booking_every?: Maybe<BookingWhereInput>

  booking_some?: Maybe<BookingWhereInput>

  booking_none?: Maybe<BookingWhereInput>

  leaseAsLandlord_every?: Maybe<LeaseWhereInput>

  leaseAsLandlord_some?: Maybe<LeaseWhereInput>

  leaseAsLandlord_none?: Maybe<LeaseWhereInput>

  leaseAsTenant_every?: Maybe<LeaseWhereInput>

  leaseAsTenant_some?: Maybe<LeaseWhereInput>

  leaseAsTenant_none?: Maybe<LeaseWhereInput>
}

export interface ListingWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<ListingWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<ListingWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<ListingWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  pet?: Maybe<PetWhereInput>

  title?: Maybe<string>
  /** All values that are not equal to given value. */
  title_not?: Maybe<string>
  /** All values that are contained in given list. */
  title_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  title_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  title_lte?: Maybe<string>
  /** All values greater than the given value. */
  title_gt?: Maybe<string>

  disableListing?: Maybe<string>
  /** All values greater than or equal the given value. */
  title_gte?: Maybe<string>
  /** All values containing the given string. */
  title_contains?: Maybe<string>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  title_not_ends_with?: Maybe<string>

  description?: Maybe<string>
  /** All values that are not equal to given value. */
  description_not?: Maybe<string>
  /** All values that are contained in given list. */
  description_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  description_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  description_lte?: Maybe<string>
  /** All values greater than the given value. */
  description_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  description_gte?: Maybe<string>
  /** All values containing the given string. */
  description_contains?: Maybe<string>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  description_not_ends_with?: Maybe<string>

  propertyType?: Maybe<PropertyType>
  /** All values that are not equal to given value. */
  propertyType_not?: Maybe<PropertyType>
  /** All values that are contained in given list. */
  propertyType_in?: Maybe<PropertyType[]>
  /** All values that are not contained in given list. */
  propertyType_not_in?: Maybe<PropertyType[]>

  pets?: Maybe<PetWhereInput>
  /** All values that are not equal to given value. */
  pets_not?: Maybe<PetWhereInput>
  /** All values that are contained in given list. */
  pets_in?: Maybe<PetWhereInput>
  /** All values that are not contained in given list. */
  pets_not_in?: Maybe<PetWhereInput>

  buildingConstructionYear?: Maybe<string>
  /** All values that are not equal to given value. */
  buildingConstructionYear_not?: Maybe<string>
  /** All values that are contained in given list. */
  buildingConstructionYear_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  buildingConstructionYear_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  buildingConstructionYear_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  buildingConstructionYear_lte?: Maybe<string>
  /** All values greater than the given value. */
  buildingConstructionYear_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  buildingConstructionYear_gte?: Maybe<string>
  /** All values containing the given string. */
  buildingConstructionYear_contains?: Maybe<string>
  /** All values not containing the given string. */
  buildingConstructionYear_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  buildingConstructionYear_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  buildingConstructionYear_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  buildingConstructionYear_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  buildingConstructionYear_not_ends_with?: Maybe<string>

  tuDetails?: Maybe<string>

  leadsTolobi?: Maybe<string>

  leadsZumper?: Maybe<string>

  leadsRentals?: Maybe<string>

  leadsZillow?: Maybe<string>

  leadsKijiji?: Maybe<string>

  leadsCraigslist?: Maybe<string>

  leadsRealtor?: Maybe<string>

  leadsMarketplace?: Maybe<string>

  leadsTolobiDisabled?: Maybe<string>

  leadsZumperDisabled?: Maybe<string>

  leadsRentalsDisabled?: Maybe<string>

  leadsZillowDisabled?: Maybe<string>

  leadsKijijiDisabled?: Maybe<string>

  leadsCraigslistDisabled?: Maybe<string>

  leadsRealtorDisabled?: Maybe<string>

  leadsMarketplaceDisabled?: Maybe<string>

  leadsTolobiEnabled?: Maybe<string>

  leadsZumperEnabled?: Maybe<string>

  leadsRentalsEnabled?: Maybe<string>

  leadsZillowEnabled?: Maybe<string>

  leadsKijijiEnabled?: Maybe<string>

  leadsCraigslistEnabled?: Maybe<string>

  leadsRealtorEnabled?: Maybe<string>

  leadsMarketplaceEnabled?: Maybe<string>

  totalUnitsInBuilding?: Maybe<number>
  /** All values that are not equal to given value. */
  totalUnitsInBuilding_not?: Maybe<number>
  /** All values that are contained in given list. */
  totalUnitsInBuilding_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  totalUnitsInBuilding_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  totalUnitsInBuilding_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  totalUnitsInBuilding_lte?: Maybe<number>
  /** All values greater than the given value. */
  totalUnitsInBuilding_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  totalUnitsInBuilding_gte?: Maybe<number>

  storiesInBuilding?: Maybe<number>
  /** All values that are not equal to given value. */
  storiesInBuilding_not?: Maybe<number>
  /** All values that are contained in given list. */
  storiesInBuilding_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  storiesInBuilding_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  storiesInBuilding_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  storiesInBuilding_lte?: Maybe<number>
  /** All values greater than the given value. */
  storiesInBuilding_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  storiesInBuilding_gte?: Maybe<number>

  developmentCompany?: Maybe<string>
  /** All values that are not equal to given value. */
  developmentCompany_not?: Maybe<string>
  /** All values that are contained in given list. */
  developmentCompany_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  developmentCompany_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  developmentCompany_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  developmentCompany_lte?: Maybe<string>

  rentedPrice?: Maybe<string>

  signedLeaseDate?: Maybe<string>

  developmentCompany_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  developmentCompany_gte?: Maybe<string>
  /** All values containing the given string. */
  developmentCompany_contains?: Maybe<string>
  /** All values not containing the given string. */
  developmentCompany_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  developmentCompany_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  developmentCompany_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  developmentCompany_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  developmentCompany_not_ends_with?: Maybe<string>

  premiumStart?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  premiumStart_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  premiumStart_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  premiumStart_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  premiumStart_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  premiumStart_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  premiumStart_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  premiumStart_gte?: Maybe<DateTime>

  premiumEnd?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  premiumEnd_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  premiumEnd_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  premiumEnd_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  premiumEnd_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  premiumEnd_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  premiumEnd_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  premiumEnd_gte?: Maybe<DateTime>

  isHidden?: Maybe<boolean>

  isapproved?: Maybe<boolean>
  /** All values that are not equal to given value. */
  isHidden_not?: Maybe<boolean>

  owner?: Maybe<UserWhereInput>

  location?: Maybe<LocationWhereInput>

  utilities?: Maybe<UtilitiesWhereInput>

  amenities?: Maybe<ListingAmenitiesWhereInput>

  tenantRequirements?: Maybe<TenantRequirementsWhereInput>

  pictures_every?: Maybe<PictureWhereInput>

  pictures?: Maybe<PictureWhereInput>

  pictures_some?: Maybe<PictureWhereInput>

  pictures_none?: Maybe<PictureWhereInput>

  units_every?: Maybe<UnitWhereInput>

  units_some?: Maybe<UnitWhereInput>

  units?: Maybe<UnitWhereInput>

  tenantForm?: Maybe<PictureWhereInput>

  units_none?: Maybe<UnitWhereInput>

  bookings_every?: Maybe<BookingWhereInput>

  bookings_some?: Maybe<BookingWhereInput>

  bookings_none?: Maybe<BookingWhereInput>

  tuListings?: Maybe<TenantListingsWhereInput[]>
}

export interface TenantFormWhereInput {
  id?: Maybe<number>
  value?: Maybe<string>
}
export interface TenantListingsWhereInput {
  id?: Maybe<number>
  tenantForm?: Maybe<TenantFormWhereInput>
}
export interface LocationWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<LocationWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<LocationWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<LocationWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  lat?: Maybe<number>
  /** All values that are not equal to given value. */
  lat_not?: Maybe<number>
  /** All values that are contained in given list. */
  lat_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  lat_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  lat_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  lat_lte?: Maybe<number>
  /** All values greater than the given value. */
  lat_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  lat_gte?: Maybe<number>

  lng?: Maybe<number>
  /** All values that are not equal to given value. */
  lng_not?: Maybe<number>
  /** All values that are contained in given list. */
  lng_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  lng_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  lng_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  lng_lte?: Maybe<number>
  /** All values greater than the given value. */
  lng_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  lng_gte?: Maybe<number>

  address?: Maybe<string>
  /** All values that are not equal to given value. */
  address_not?: Maybe<string>
  /** All values that are contained in given list. */
  address_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  address_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  address_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  address_lte?: Maybe<string>
  /** All values greater than the given value. */
  address_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  address_gte?: Maybe<string>
  /** All values containing the given string. */
  address_contains?: Maybe<string>
  /** All values not containing the given string. */
  address_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  address_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  address_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  address_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  address_not_ends_with?: Maybe<string>

  apt?: Maybe<string>
  /** All values that are not equal to given value. */
  apt_not?: Maybe<string>
  /** All values that are contained in given list. */
  apt_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  apt_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  apt_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  apt_lte?: Maybe<string>
  /** All values greater than the given value. */
  apt_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  apt_gte?: Maybe<string>
  /** All values containing the given string. */
  apt_contains?: Maybe<string>
  /** All values not containing the given string. */
  apt_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  apt_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  apt_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  apt_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  apt_not_ends_with?: Maybe<string>

  directions?: Maybe<string>
  /** All values that are not equal to given value. */
  directions_not?: Maybe<string>
  /** All values that are contained in given list. */
  directions_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  directions_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  directions_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  directions_lte?: Maybe<string>
  /** All values greater than the given value. */
  directions_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  directions_gte?: Maybe<string>
  /** All values containing the given string. */
  directions_contains?: Maybe<string>
  /** All values not containing the given string. */
  directions_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  directions_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  directions_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  directions_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  directions_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  neighbourHood?: Maybe<NeighbourhoodWhereInput>

  tags?: Maybe<NeighbourhoodTagsWhereInput>

  user?: Maybe<UserWhereInput>

  listings_every?: Maybe<ListingWhereInput>

  listings_some?: Maybe<ListingWhereInput>

  listings_none?: Maybe<ListingWhereInput>
}

export interface NeighbourhoodWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<NeighbourhoodWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<NeighbourhoodWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<NeighbourhoodWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  name?: Maybe<string>
  /** All values that are not equal to given value. */
  name_not?: Maybe<string>
  /** All values that are contained in given list. */
  name_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  name_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  name_lte?: Maybe<string>
  /** All values greater than the given value. */
  name_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<string>
  /** All values containing the given string. */
  name_contains?: Maybe<string>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<string>

  slug?: Maybe<string>
  /** All values that are not equal to given value. */
  slug_not?: Maybe<string>
  /** All values that are contained in given list. */
  slug_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  slug_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  slug_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  slug_lte?: Maybe<string>
  /** All values greater than the given value. */
  slug_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  slug_gte?: Maybe<string>
  /** All values containing the given string. */
  slug_contains?: Maybe<string>
  /** All values not containing the given string. */
  slug_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  slug_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  slug_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  slug_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  slug_not_ends_with?: Maybe<string>

  featured?: Maybe<boolean>
  /** All values that are not equal to given value. */
  featured_not?: Maybe<boolean>

  popularity?: Maybe<number>
  /** All values that are not equal to given value. */
  popularity_not?: Maybe<number>
  /** All values that are contained in given list. */
  popularity_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  popularity_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  popularity_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  popularity_lte?: Maybe<number>
  /** All values greater than the given value. */
  popularity_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  popularity_gte?: Maybe<number>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  locations_every?: Maybe<LocationWhereInput>

  locations_some?: Maybe<LocationWhereInput>

  locations_none?: Maybe<LocationWhereInput>

  homePreview?: Maybe<PictureWhereInput>

  city?: Maybe<CityWhereInput>

  cities?: Maybe<CityWhereInput[]>
}

export interface PictureWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<PictureWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<PictureWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<PictureWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  filename?: Maybe<string>
  /** All values that are not equal to given value. */
  filename_not?: Maybe<string>
  /** All values that are contained in given list. */
  filename_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  filename_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  filename_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  filename_lte?: Maybe<string>
  /** All values greater than the given value. */
  filename_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  filename_gte?: Maybe<string>
  /** All values containing the given string. */
  filename_contains?: Maybe<string>
  /** All values not containing the given string. */
  filename_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  filename_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  filename_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  filename_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  filename_not_ends_with?: Maybe<string>

  name?: Maybe<string>

  url?: Maybe<string>
  /** All values that are not equal to given value. */
  url_not?: Maybe<string>
  /** All values that are contained in given list. */
  url_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  url_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  url_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  url_lte?: Maybe<string>
  /** All values greater than the given value. */
  url_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  url_gte?: Maybe<string>
  /** All values containing the given string. */
  url_contains?: Maybe<string>
  /** All values not containing the given string. */
  url_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  url_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  url_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  url_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  url_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>
}

export interface CityWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<CityWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<CityWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<CityWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  name?: Maybe<string>
  /** All values that are not equal to given value. */
  name_not?: Maybe<string>
  /** All values that are contained in given list. */
  name_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  name_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  name_lte?: Maybe<string>
  /** All values greater than the given value. */
  name_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<string>
  /** All values containing the given string. */
  name_contains?: Maybe<string>
  /** All values not containing the given string. */
  name_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  name_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  name_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  neighbourhoods_every?: Maybe<NeighbourhoodWhereInput>

  neighbourhoods_some?: Maybe<NeighbourhoodWhereInput>

  neighbourhoods_none?: Maybe<NeighbourhoodWhereInput>
}

export interface NeighbourhoodTagsWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<NeighbourhoodTagsWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<NeighbourhoodTagsWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<NeighbourhoodTagsWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  closeToTransit?: Maybe<boolean>
  /** All values that are not equal to given value. */
  closeToTransit_not?: Maybe<boolean>

  purposeBuiltRentals?: Maybe<boolean>
  /** All values that are not equal to given value. */
  purposeBuiltRentals_not?: Maybe<boolean>

  students?: Maybe<boolean>
  /** All values that are not equal to given value. */
  students_not?: Maybe<boolean>

  parksAndWater?: Maybe<boolean>
  /** All values that are not equal to given value. */
  parksAndWater_not?: Maybe<boolean>

  nightlife?: Maybe<boolean>
  /** All values that are not equal to given value. */
  nightlife_not?: Maybe<boolean>

  luxuryRentals?: Maybe<boolean>
  luxuryRentals_not?: Maybe<boolean>

  shortTermRentals_not?: Maybe<boolean>
  shortTermRentals?: Maybe<boolean>

  professionallyManaged?: Maybe<boolean>

  professionallyManaged_not?: Maybe<boolean>

  onlinePayments?: Maybe<boolean>
  onlinePayments_not?: Maybe<boolean>

  onlineApplications?: Maybe<boolean>
  onlineApplications_not?: Maybe<boolean>

  furnishedRentals?: Maybe<boolean>
  furnishedRentals_not?: Maybe<boolean>

  petFriendly?: Maybe<boolean>
  petFriendly_not?: Maybe<boolean>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>
}

export interface UtilitiesWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<UtilitiesWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<UtilitiesWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<UtilitiesWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  electricity?: Maybe<boolean>
  /** All values that are not equal to given value. */
  electricity_not?: Maybe<boolean>

  heating?: Maybe<boolean>
  /** All values that are not equal to given value. */
  heating_not?: Maybe<boolean>

  airConditioning?: Maybe<boolean>
  /** All values that are not equal to given value. */
  airConditioning_not?: Maybe<boolean>

  gas?: Maybe<boolean>
  /** All values that are not equal to given value. */
  gas_not?: Maybe<boolean>

  water?: Maybe<boolean>
  /** All values that are not equal to given value. */
  water_not?: Maybe<boolean>

  garbageRemoval?: Maybe<boolean>
  /** All values that are not equal to given value. */
  garbageRemoval_not?: Maybe<boolean>

  cableTv?: Maybe<boolean>
  /** All values that are not equal to given value. */
  cableTv_not?: Maybe<boolean>

  internet?: Maybe<boolean>
  /** All values that are not equal to given value. */
  internet_not?: Maybe<boolean>

  maintenanceFees?: Maybe<boolean>
  /** All values that are not equal to given value. */
  maintenanceFees_not?: Maybe<boolean>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  listing?: Maybe<ListingWhereInput>
}

export interface PetWhereInput {
  cat?: Maybe<boolean>
  dog?: Maybe<boolean>
  other?: Maybe<boolean>
}

export interface ListingAmenitiesWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<ListingAmenitiesWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<ListingAmenitiesWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<ListingAmenitiesWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  onSiteLaundry?: Maybe<boolean>
  /** All values that are not equal to given value. */
  onSiteLaundry_not?: Maybe<boolean>

  yard?: Maybe<boolean>
  /** All values that are not equal to given value. */
  yard_not?: Maybe<boolean>

  concierge?: Maybe<boolean>
  /** All values that are not equal to given value. */
  concierge_not?: Maybe<boolean>

  elevator?: Maybe<boolean>
  /** All values that are not equal to given value. */
  elevator_not?: Maybe<boolean>

  doorPerson?: Maybe<boolean>
  /** All values that are not equal to given value. */
  doorPerson_not?: Maybe<boolean>

  dryCleaning?: Maybe<boolean>
  /** All values that are not equal to given value. */
  dryCleaning_not?: Maybe<boolean>

  gym?: Maybe<boolean>
  /** All values that are not equal to given value. */
  gym_not?: Maybe<boolean>

  swimmingPool?: Maybe<boolean>
  /** All values that are not equal to given value. */
  swimmingPool_not?: Maybe<boolean>

  storage?: Maybe<boolean>
  /** All values that are not equal to given value. */
  storage_not?: Maybe<boolean>

  guestSuites?: Maybe<boolean>
  /** All values that are not equal to given value. */
  guestSuites_not?: Maybe<boolean>

  beanfieldWifi?: Maybe<boolean>
  /** All values that are not equal to given value. */
  beanfieldWifi_not?: Maybe<boolean>

  publicWifi?: Maybe<boolean>
  /** All values that are not equal to given value. */
  publicWifi_not?: Maybe<boolean>

  bbqPermitted?: Maybe<boolean>
  /** All values that are not equal to given value. */
  bbqPermitted_not?: Maybe<boolean>

  visitorsParking?: Maybe<boolean>
  /** All values that are not equal to given value. */
  visitorsParking_not?: Maybe<boolean>

  partyRoom?: Maybe<boolean>
  /** All values that are not equal to given value. */
  partyRoom_not?: Maybe<boolean>

  bikeStorage?: Maybe<boolean>
  /** All values that are not equal to given value. */
  bikeStorage_not?: Maybe<boolean>

  wheelchairAccess?: Maybe<boolean>
  /** All values that are not equal to given value. */
  wheelchairAccess_not?: Maybe<boolean>

  rooftopDeck?: Maybe<boolean>
  /** All values that are not equal to given value. */
  rooftopDeck_not?: Maybe<boolean>

  hotTub?: Maybe<boolean>
  /** All values that are not equal to given value. */
  hotTub_not?: Maybe<boolean>

  sauna?: Maybe<boolean>
  /** All values that are not equal to given value. */
  sauna_not?: Maybe<boolean>

  gameRoom?: Maybe<boolean>
  /** All values that are not equal to given value. */
  gameRoom_not?: Maybe<boolean>

  theatre?: Maybe<boolean>
  /** All values that are not equal to given value. */
  theatre_not?: Maybe<boolean>

  businessCenter?: Maybe<boolean>
  /** All values that are not equal to given value. */
  businessCenter_not?: Maybe<boolean>

  communalGarden?: Maybe<boolean>
  /** All values that are not equal to given value. */
  communalGarden_not?: Maybe<boolean>

  petSpa?: Maybe<boolean>

  petSpa_not?: Maybe<boolean>

  mediaRoom?: Maybe<boolean>

  mediaRoom_not?: Maybe<boolean>

  basketballCourt?: Maybe<boolean>

  basketballCourt_not?: Maybe<boolean>

  yogaRoom?: Maybe<boolean>

  yogaRoom_not?: Maybe<boolean>

  tennisCourt?: Maybe<boolean>

  tennisCourt_not?: Maybe<boolean>

  rooftopTerrace?: Maybe<boolean>

  rooftopTerrace_not?: Maybe<boolean>

  coworkingSpace?: Maybe<boolean>

  coworkingSpace_not?: Maybe<boolean>

  conferenceRoom?: Maybe<boolean>

  conferenceRoom_not?: Maybe<boolean>

  patio?: Maybe<boolean>

  patio_not?: Maybe<boolean>

  lounge?: Maybe<boolean>

  lounge_not?: Maybe<boolean>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  listing?: Maybe<ListingWhereInput>
}

export interface TenantRequirementsWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<TenantRequirementsWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<TenantRequirementsWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<TenantRequirementsWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  exceptionalCredit?: Maybe<boolean>
  /** All values that are not equal to given value. */
  exceptionalCredit_not?: Maybe<boolean>

  payStub?: Maybe<boolean>
  /** All values that are not equal to given value. */
  payStub_not?: Maybe<boolean>

  letterOfEmployment?: Maybe<boolean>
  /** All values that are not equal to given value. */
  letterOfEmployment_not?: Maybe<boolean>

  pastReferences?: Maybe<boolean>
  /** All values that are not equal to given value. */
  pastReferences_not?: Maybe<boolean>

  noSmoking?: Maybe<boolean>
  /** All values that are not equal to given value. */
  noSmoking_not?: Maybe<boolean>

  extraDocumentation?: Maybe<boolean>
  /** All values that are not equal to given value. */
  extraDocumentation_not?: Maybe<boolean>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>
}

export interface UnitWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<UnitWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<UnitWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<UnitWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  title?: Maybe<string>
  /** All values that are not equal to given value. */
  title_not?: Maybe<string>
  /** All values that are contained in given list. */
  title_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  title_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  title_lte?: Maybe<string>
  /** All values greater than the given value. */
  title_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  title_gte?: Maybe<string>
  /** All values containing the given string. */
  title_contains?: Maybe<string>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  title_not_ends_with?: Maybe<string>

  number?: Maybe<string>
  /** All values that are not equal to given value. */
  number_not?: Maybe<string>
  /** All values that are contained in given list. */
  number_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  number_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  number_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  number_lte?: Maybe<string>
  /** All values greater than the given value. */
  number_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  number_gte?: Maybe<string>
  /** All values containing the given string. */
  number_contains?: Maybe<string>
  /** All values not containing the given string. */
  number_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  number_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  number_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  number_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  number_not_ends_with?: Maybe<string>

  den?: Maybe<number>
  /** All values that are not equal to given value. */
  den_not?: Maybe<number>

  available?: Maybe<boolean>
  /** All values that are not equal to given value. */
  available_not?: Maybe<boolean>

  startDateAvailability?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  startDateAvailability_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  startDateAvailability_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  startDateAvailability_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  startDateAvailability_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  startDateAvailability_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  startDateAvailability_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  startDateAvailability_gte?: Maybe<DateTime>

  unitSize?: Maybe<number>
  /** All values that are not equal to given value. */
  unitSize_not?: Maybe<number>
  /** All values that are contained in given list. */
  unitSize_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  unitSize_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  unitSize_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  unitSize_lte?: Maybe<number>
  /** All values greater than the given value. */
  unitSize_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  unitSize_gte?: Maybe<number>

  bedrooms?: Maybe<Bedrooms[]>
  /** All values that are not equal to given value. */
  bedrooms_not?: Maybe<Bedrooms>
  /** All values that are contained in given list. */
  bedrooms_in?: Maybe<Bedrooms[]>
  /** All values that are not contained in given list. */
  bedrooms_not_in?: Maybe<Bedrooms[]>

  monthlyPrice?: Maybe<number>
  /** All values that are not equal to given value. */
  monthlyPrice_not?: Maybe<number>
  /** All values that are contained in given list. */
  monthlyPrice_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  monthlyPrice_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  monthlyPrice_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  monthlyPrice_lte?: Maybe<number>
  /** All values greater than the given value. */
  monthlyPrice_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  monthlyPrice_gte?: Maybe<number>

  bathrooms?: Maybe<number>
  /** All values that are not equal to given value. */
  bathrooms_not?: Maybe<number>
  /** All values that are contained in given list. */
  bathrooms_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  bathrooms_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  bathrooms_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  bathrooms_lte?: Maybe<number>
  /** All values greater than the given value. */
  bathrooms_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  bathrooms_gte?: Maybe<number>

  floorplan?: Maybe<string>
  /** All values that are not equal to given value. */
  floorplan_not?: Maybe<string>
  /** All values that are contained in given list. */
  floorplan_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  floorplan_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  floorplan_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  floorplan_lte?: Maybe<string>
  /** All values greater than the given value. */
  floorplan_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  floorplan_gte?: Maybe<string>
  /** All values containing the given string. */
  floorplan_contains?: Maybe<string>
  /** All values not containing the given string. */
  floorplan_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  floorplan_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  floorplan_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  floorplan_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  floorplan_not_ends_with?: Maybe<string>

  furnished?: Maybe<boolean>
  /** All values that are not equal to given value. */
  furnished_not?: Maybe<boolean>

  deposit?: Maybe<number>
  /** All values that are not equal to given value. */
  deposit_not?: Maybe<number>
  /** All values that are contained in given list. */
  deposit_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  deposit_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  deposit_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  deposit_lte?: Maybe<number>
  /** All values greater than the given value. */
  deposit_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  deposit_gte?: Maybe<number>

  leaseTerm?: Maybe<number>
  /** All values that are not equal to given value. */
  leaseTerm_not?: Maybe<number>
  /** All values that are contained in given list. */
  leaseTerm_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  leaseTerm_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  leaseTerm_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  leaseTerm_lte?: Maybe<number>
  /** All values greater than the given value. */
  leaseTerm_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  leaseTerm_gte?: Maybe<number>

  parking?: Maybe<Parking>
  /** All values that are not equal to given value. */
  parking_not?: Maybe<Parking>
  /** All values that are contained in given list. */
  parking_in?: Maybe<Parking[]>
  /** All values that are not contained in given list. */
  parking_not_in?: Maybe<Parking[]>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  amenities?: Maybe<UnitAmenitiesWhereInput>

  pictures_every?: Maybe<PictureWhereInput>

  pictures_some?: Maybe<PictureWhereInput>

  pictures_none?: Maybe<PictureWhereInput>

  listings_every?: Maybe<ListingWhereInput>

  listings_some?: Maybe<ListingWhereInput>

  listings_none?: Maybe<ListingWhereInput>
}

export interface UnitAmenitiesWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<UnitAmenitiesWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<UnitAmenitiesWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<UnitAmenitiesWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  ac?: Maybe<boolean>
  /** All values that are not equal to given value. */
  ac_not?: Maybe<boolean>

  centralHeat?: Maybe<boolean>
  /** All values that are not equal to given value. */
  centralHeat_not?: Maybe<boolean>

  electricalHeating?: Maybe<boolean>
  /** All values that are not equal to given value. */
  electricalHeating_not?: Maybe<boolean>

  radiatorHeating?: Maybe<boolean>
  /** All values that are not equal to given value. */
  radiatorHeating_not?: Maybe<boolean>

  fireplace?: Maybe<boolean>
  /** All values that are not equal to given value. */
  fireplace_not?: Maybe<boolean>

  carpet?: Maybe<boolean>
  /** All values that are not equal to given value. */
  carpet_not?: Maybe<boolean>

  ceilingFan?: Maybe<boolean>
  /** All values that are not equal to given value. */
  ceilingFan_not?: Maybe<boolean>

  hardwoodFloor?: Maybe<boolean>
  /** All values that are not equal to given value. */
  hardwoodFloor_not?: Maybe<boolean>

  walkInCloset?: Maybe<boolean>
  /** All values that are not equal to given value. */
  walkInCloset_not?: Maybe<boolean>

  highCeilings?: Maybe<boolean>
  /** All values that are not equal to given value. */
  highCeilings_not?: Maybe<boolean>

  bathroomFan?: Maybe<boolean>
  /** All values that are not equal to given value. */
  bathroomFan_not?: Maybe<boolean>

  balcony?: Maybe<boolean>
  /** All values that are not equal to given value. */
  balcony_not?: Maybe<boolean>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  unit?: Maybe<UnitWhereInput>
}

export interface BookingWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<BookingWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<BookingWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<BookingWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  roommate?: Maybe<boolean>
  /** All values that are not equal to given value. */
  roommate_not?: Maybe<boolean>

  introduction?: Maybe<string>
  /** All values that are not equal to given value. */
  introduction_not?: Maybe<string>
  /** All values that are contained in given list. */
  introduction_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  introduction_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  introduction_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  introduction_lte?: Maybe<string>
  /** All values greater than the given value. */
  introduction_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  introduction_gte?: Maybe<string>
  /** All values containing the given string. */
  introduction_contains?: Maybe<string>
  /** All values not containing the given string. */
  introduction_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  introduction_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  introduction_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  introduction_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  introduction_not_ends_with?: Maybe<string>

  moveInDate?: Maybe<string>
  /** All values that are not equal to given value. */
  moveInDate_not?: Maybe<string>
  /** All values that are contained in given list. */
  moveInDate_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  moveInDate_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  moveInDate_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  moveInDate_lte?: Maybe<string>
  /** All values greater than the given value. */
  moveInDate_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  moveInDate_gte?: Maybe<string>
  /** All values containing the given string. */
  moveInDate_contains?: Maybe<string>
  /** All values not containing the given string. */
  moveInDate_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  moveInDate_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  moveInDate_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  moveInDate_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  moveInDate_not_ends_with?: Maybe<string>

  leaseTerm?: Maybe<string>
  /** All values that are not equal to given value. */
  leaseTerm_not?: Maybe<string>
  /** All values that are contained in given list. */
  leaseTerm_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  leaseTerm_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  leaseTerm_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  leaseTerm_lte?: Maybe<string>
  /** All values greater than the given value. */
  leaseTerm_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  leaseTerm_gte?: Maybe<string>
  /** All values containing the given string. */
  leaseTerm_contains?: Maybe<string>
  /** All values not containing the given string. */
  leaseTerm_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  leaseTerm_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  leaseTerm_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  leaseTerm_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  leaseTerm_not_ends_with?: Maybe<string>

  unit?: Maybe<string>
  /** All values that are not equal to given value. */
  unit_not?: Maybe<string>
  /** All values that are contained in given list. */
  unit_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  unit_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  unit_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  unit_lte?: Maybe<string>
  /** All values greater than the given value. */
  unit_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  unit_gte?: Maybe<string>
  /** All values containing the given string. */
  unit_contains?: Maybe<string>
  /** All values not containing the given string. */
  unit_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  unit_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  unit_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  unit_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  unit_not_ends_with?: Maybe<string>

  occupancyRate?: Maybe<OccupancyRate>
  /** All values that are not equal to given value. */
  occupancyRate_not?: Maybe<OccupancyRate>
  /** All values that are contained in given list. */
  occupancyRate_in?: Maybe<OccupancyRate[]>
  /** All values that are not contained in given list. */
  occupancyRate_not_in?: Maybe<OccupancyRate[]>

  certnApplicantId?: Maybe<string>
  /** All values that are not equal to given value. */
  certnApplicantId_not?: Maybe<string>
  /** All values that are contained in given list. */
  certnApplicantId_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  certnApplicantId_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  certnApplicantId_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  certnApplicantId_lte?: Maybe<string>
  /** All values greater than the given value. */
  certnApplicantId_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  certnApplicantId_gte?: Maybe<string>
  /** All values containing the given string. */
  certnApplicantId_contains?: Maybe<string>
  /** All values not containing the given string. */
  certnApplicantId_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  certnApplicantId_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  certnApplicantId_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  certnApplicantId_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  certnApplicantId_not_ends_with?: Maybe<string>

  Seeker?: Maybe<UserWhereInput>

  Listing?: Maybe<ListingWhereInput>

  user_every?: Maybe<UserWhereInput>

  user_some?: Maybe<UserWhereInput>

  user_none?: Maybe<UserWhereInput>

  room?: Maybe<RoomWhereInput>
}

export interface UnreadWhereInput {
  roomId?: Maybe<string>
}
export interface RoomWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<RoomWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<RoomWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<RoomWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  lastMessageTime?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  lastMessageTime_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  lastMessageTime_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  lastMessageTime_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  lastMessageTime_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  lastMessageTime_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  lastMessageTime_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  lastMessageTime_gte?: Maybe<DateTime>

  creator?: Maybe<UserWhereInput>

  userType?: Maybe<string>

  take?: Maybe<number>

  skip?: Maybe<number>

  searchQuery?: Maybe<string>

  listingId?: Maybe<string>

  participants_every?: Maybe<UserWhereInput>

  participants_some?: Maybe<UserWhereInput>

  participants_none?: Maybe<UserWhereInput>

  rental?: Maybe<RentalWhereInput>

  messages_every?: Maybe<MessageWhereInput>

  messages_some?: Maybe<MessageWhereInput>

  messages_none?: Maybe<MessageWhereInput>

  booking?: Maybe<BookingWhereInput>
}

export interface RentalWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<RentalWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<RentalWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<RentalWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  rooms_every?: Maybe<RoomWhereInput>

  rooms_some?: Maybe<RoomWhereInput>

  rooms_none?: Maybe<RoomWhereInput>
}

export interface MessageWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<MessageWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<MessageWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<MessageWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  text?: Maybe<string>
  /** All values that are not equal to given value. */
  text_not?: Maybe<string>
  /** All values that are contained in given list. */
  text_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  text_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  text_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  text_lte?: Maybe<string>
  /** All values greater than the given value. */
  text_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  text_gte?: Maybe<string>
  /** All values containing the given string. */
  text_contains?: Maybe<string>
  /** All values not containing the given string. */
  text_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  text_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  text_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  text_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  text_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  isRead?: Maybe<boolean>
  /** All values that are not equal to given value. */
  isRead_not?: Maybe<boolean>

  type?: Maybe<MessageType>
  /** All values that are not equal to given value. */
  type_not?: Maybe<MessageType>
  /** All values that are contained in given list. */
  type_in?: Maybe<MessageType[]>
  /** All values that are not contained in given list. */
  type_not_in?: Maybe<MessageType[]>

  url?: Maybe<string>
  /** All values that are not equal to given value. */
  url_not?: Maybe<string>
  /** All values that are contained in given list. */
  url_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  url_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  url_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  url_lte?: Maybe<string>
  /** All values greater than the given value. */
  url_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  url_gte?: Maybe<string>
  /** All values containing the given string. */
  url_contains?: Maybe<string>
  /** All values not containing the given string. */
  url_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  url_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  url_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  url_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  url_not_ends_with?: Maybe<string>

  emailNotificationSent?: Maybe<boolean>
  /** All values that are not equal to given value. */
  emailNotificationSent_not?: Maybe<boolean>

  sender?: Maybe<UserWhereInput>

  room?: Maybe<RoomWhereInput>
}

export interface LeaseWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<LeaseWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<LeaseWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<LeaseWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  docusignEnvelopeUri?: Maybe<string>
  /** All values that are not equal to given value. */
  docusignEnvelopeUri_not?: Maybe<string>
  /** All values that are contained in given list. */
  docusignEnvelopeUri_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  docusignEnvelopeUri_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  docusignEnvelopeUri_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  docusignEnvelopeUri_lte?: Maybe<string>
  /** All values greater than the given value. */
  docusignEnvelopeUri_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  docusignEnvelopeUri_gte?: Maybe<string>
  /** All values containing the given string. */
  docusignEnvelopeUri_contains?: Maybe<string>
  /** All values not containing the given string. */
  docusignEnvelopeUri_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  docusignEnvelopeUri_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  docusignEnvelopeUri_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  docusignEnvelopeUri_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  docusignEnvelopeUri_not_ends_with?: Maybe<string>

  docusignEnvelopeId?: Maybe<string>
  /** All values that are not equal to given value. */
  docusignEnvelopeId_not?: Maybe<string>
  /** All values that are contained in given list. */
  docusignEnvelopeId_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  docusignEnvelopeId_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  docusignEnvelopeId_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  docusignEnvelopeId_lte?: Maybe<string>
  /** All values greater than the given value. */
  docusignEnvelopeId_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  docusignEnvelopeId_gte?: Maybe<string>
  /** All values containing the given string. */
  docusignEnvelopeId_contains?: Maybe<string>
  /** All values not containing the given string. */
  docusignEnvelopeId_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  docusignEnvelopeId_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  docusignEnvelopeId_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  docusignEnvelopeId_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  docusignEnvelopeId_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  status?: Maybe<DocusignEnvelopeStatus>
  /** All values that are not equal to given value. */
  status_not?: Maybe<DocusignEnvelopeStatus>
  /** All values that are contained in given list. */
  status_in?: Maybe<DocusignEnvelopeStatus[]>
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<DocusignEnvelopeStatus[]>

  subject?: Maybe<string>
  /** All values that are not equal to given value. */
  subject_not?: Maybe<string>
  /** All values that are contained in given list. */
  subject_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  subject_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  subject_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  subject_lte?: Maybe<string>
  /** All values greater than the given value. */
  subject_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  subject_gte?: Maybe<string>
  /** All values containing the given string. */
  subject_contains?: Maybe<string>
  /** All values not containing the given string. */
  subject_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  subject_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  subject_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  subject_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  subject_not_ends_with?: Maybe<string>

  message?: Maybe<string>
  /** All values that are not equal to given value. */
  message_not?: Maybe<string>
  /** All values that are contained in given list. */
  message_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  message_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  message_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  message_lte?: Maybe<string>
  /** All values greater than the given value. */
  message_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  message_gte?: Maybe<string>
  /** All values containing the given string. */
  message_contains?: Maybe<string>
  /** All values not containing the given string. */
  message_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  message_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  message_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  message_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  message_not_ends_with?: Maybe<string>

  landlordSigners_every?: Maybe<UserWhereInput>

  landlordSigners_some?: Maybe<UserWhereInput>

  landlordSigners_none?: Maybe<UserWhereInput>

  tenantSigners_every?: Maybe<UserWhereInput>

  tenantSigners_some?: Maybe<UserWhereInput>

  tenantSigners_none?: Maybe<UserWhereInput>

  room?: Maybe<RoomWhereInput>
}

export interface ListingWhereUniqueInput {
  id?: Maybe<string>
}

export interface LeaseWhereUniqueInput {
  id?: Maybe<string>

  docusignEnvelopeId?: Maybe<string>
}

export interface ArticleWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<ArticleWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<ArticleWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<ArticleWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  imageUrl?: Maybe<string>
  /** All values that are not equal to given value. */
  imageUrl_not?: Maybe<string>
  /** All values that are contained in given list. */
  imageUrl_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  imageUrl_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  imageUrl_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  imageUrl_lte?: Maybe<string>
  /** All values greater than the given value. */
  imageUrl_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  imageUrl_gte?: Maybe<string>
  /** All values containing the given string. */
  imageUrl_contains?: Maybe<string>
  /** All values not containing the given string. */
  imageUrl_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  imageUrl_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  imageUrl_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  imageUrl_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  imageUrl_not_ends_with?: Maybe<string>

  title?: Maybe<string>
  /** All values that are not equal to given value. */
  title_not?: Maybe<string>
  /** All values that are contained in given list. */
  title_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  title_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  title_lte?: Maybe<string>
  /** All values greater than the given value. */
  title_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  title_gte?: Maybe<string>
  /** All values containing the given string. */
  title_contains?: Maybe<string>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  title_not_ends_with?: Maybe<string>

  status?: Maybe<boolean>

  subtitle?: Maybe<string>
  /** All values that are not equal to given value. */
  subtitle_not?: Maybe<string>
  /** All values that are contained in given list. */
  subtitle_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  subtitle_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  subtitle_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  subtitle_lte?: Maybe<string>
  /** All values greater than the given value. */
  subtitle_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  subtitle_gte?: Maybe<string>
  /** All values containing the given string. */
  subtitle_contains?: Maybe<string>
  /** All values not containing the given string. */
  subtitle_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  subtitle_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  subtitle_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  subtitle_not_ends_with?: Maybe<string>

  description?: Maybe<string>
  /** All values that are not equal to given value. */
  description_not?: Maybe<string>
  /** All values that are contained in given list. */
  description_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  description_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  description_lte?: Maybe<string>
  /** All values greater than the given value. */
  description_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  description_gte?: Maybe<string>
  /** All values containing the given string. */
  description_contains?: Maybe<string>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  description_not_ends_with?: Maybe<string>

  articleUrl?: Maybe<string>
  /** All values that are not equal to given value. */
  articleUrl_not?: Maybe<string>
  /** All values that are contained in given list. */
  articleUrl_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  articleUrl_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  articleUrl_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  articleUrl_lte?: Maybe<string>
  /** All values greater than the given value. */
  articleUrl_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  articleUrl_gte?: Maybe<string>
  /** All values containing the given string. */
  articleUrl_contains?: Maybe<string>
  /** All values not containing the given string. */
  articleUrl_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  articleUrl_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  articleUrl_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  articleUrl_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  articleUrl_not_ends_with?: Maybe<string>

  articleType?: Maybe<ArticleType>
  /** All values that are not equal to given value. */
  articleType_not?: Maybe<ArticleType>
  /** All values that are contained in given list. */
  articleType_in?: Maybe<ArticleType[]>
  /** All values that are not contained in given list. */
  articleType_not_in?: Maybe<ArticleType[]>
}

export interface UserUpdateInput {
  email?: Maybe<string>

  auth0id?: Maybe<string>

  firstName?: Maybe<string>

  lastName?: Maybe<string>

  legalName?: Maybe<string>

  userType?: Maybe<UserType>

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  min_budget?: Maybe<string>

  roommate?: Maybe<boolean>

  role?: Maybe<string>

  lu_from?: Maybe<string>

  rental_properties?: Maybe<number>

  rental_properties_permonth?: Maybe<number>

  lu_type?: Maybe<string>

  bedrooms?: Maybe<string>

  previewingCity?: Maybe<string>

  bedroom?: Maybe<string>

  laundaryType?: Maybe<string>

  furnished?: Maybe<boolean>

  furnish?: Maybe<string>

  notify?: Maybe<boolean>

  zip_code?: Maybe<string>

  view_property?: Maybe<string>

  pets?: Maybe<string>

  credit_score?: Maybe<string>

  occupation?: Maybe<string>

  flatmates?: Maybe<number>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingUpdateManyWithoutOwnerInput>

  rooms?: Maybe<RoomUpdateManyWithoutParticipantsInput>

  createdRooms?: Maybe<RoomUpdateManyWithoutCreatorInput>

  bookings?: Maybe<BookingUpdateManyWithoutUserInput>

  booking?: Maybe<BookingUpdateManyWithoutSeekerInput>

  leaseAsLandlord?: Maybe<LeaseUpdateManyWithoutLandlordSignersInput>

  leaseAsTenant?: Maybe<LeaseUpdateManyWithoutTenantSignersInput>
}

export interface ListingUpdateManyWithoutOwnerInput {
  create?: Maybe<ListingCreateWithoutOwnerInput[]>

  connect?: Maybe<ListingWhereUniqueInput[]>

  set?: Maybe<ListingWhereUniqueInput[]>

  disconnect?: Maybe<ListingWhereUniqueInput[]>

  delete?: Maybe<ListingWhereUniqueInput[]>

  update?: Maybe<ListingUpdateWithWhereUniqueWithoutOwnerInput[]>

  updateMany?: Maybe<ListingUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<ListingScalarWhereInput[]>

  upsert?: Maybe<ListingUpsertWithWhereUniqueWithoutOwnerInput[]>
}

export interface ListingCreateWithoutOwnerInput {
  id?: Maybe<string>

  title: string

  description: string

  propertyType: PropertyType

  pets: Pets

  buildingConstructionYear?: Maybe<string>

  totalUnitsInBuilding?: Maybe<number>

  storiesInBuilding?: Maybe<number>

  developmentCompany?: Maybe<string>

  premiumStart?: Maybe<DateTime>

  premiumEnd?: Maybe<DateTime>

  isHidden?: Maybe<boolean>

  location?: Maybe<LocationCreateOneWithoutListingsInput>

  utilities?: Maybe<UtilitiesCreateOneWithoutListingInput>

  unitAppliances?: Maybe<UnitAppliancesCreateOneWithoutListingInput>

  amenities?: Maybe<ListingAmenitiesCreateOneWithoutListingInput>

  tenantRequirements?: Maybe<TenantRequirementsCreateOneInput>

  pictures?: Maybe<PictureCreateManyInput>

  units?: Maybe<UnitCreateManyWithoutListingsInput>

  bookings?: Maybe<BookingCreateManyWithoutListingInput>
}

export interface LocationCreateOneWithoutListingsInput {
  create?: Maybe<LocationCreateWithoutListingsInput>

  connect?: Maybe<LocationWhereUniqueInput>
}

export interface LocationCreateWithoutListingsInput {
  id?: Maybe<string>

  lat: number

  lng: number

  address?: Maybe<string>

  apt?: Maybe<string>

  directions?: Maybe<string>

  neighbourHood?: Maybe<NeighbourhoodCreateOneWithoutLocationsInput>

  tags?: Maybe<NeighbourhoodTagsCreateOneInput>

  user?: Maybe<UserCreateOneInput>
}

export interface NeighbourhoodCreateOneWithoutLocationsInput {
  create?: Maybe<NeighbourhoodCreateWithoutLocationsInput>

  connect?: Maybe<NeighbourhoodWhereUniqueInput>
}

export interface NeighbourhoodCreateWithoutLocationsInput {
  id?: Maybe<string>

  name: string

  slug?: Maybe<string>

  featured?: Maybe<boolean>

  popularity?: Maybe<number>

  homePreview?: Maybe<PictureCreateOneInput>

  city: CityCreateOneWithoutNeighbourhoodsInput
}

export interface PictureCreateOneInput {
  create?: Maybe<PictureCreateInput>

  connect?: Maybe<PictureWhereUniqueInput>
}

export interface PictureCreateInput {
  id?: Maybe<string>

  filename?: Maybe<string>

  url: string
}

export interface PictureWhereUniqueInput {
  id?: Maybe<string>
}

export interface CityCreateOneWithoutNeighbourhoodsInput {
  create?: Maybe<CityCreateWithoutNeighbourhoodsInput>

  connect?: Maybe<CityWhereUniqueInput>
}

export interface CityCreateWithoutNeighbourhoodsInput {
  id?: Maybe<string>

  name: string
}

export interface CityWhereUniqueInput {
  id?: Maybe<string>
}

export interface NeighbourhoodWhereUniqueInput {
  id?: Maybe<string>
}

export interface NeighbourhoodTagsCreateOneInput {
  create?: Maybe<NeighbourhoodTagsCreateInput>

  connect?: Maybe<NeighbourhoodTagsWhereUniqueInput>
}

export interface NeighbourhoodTagsCreateInput {
  id?: Maybe<string>

  closeToTransit?: Maybe<boolean>

  purposeBuiltRentals?: Maybe<boolean>

  students?: Maybe<boolean>

  parksAndWater?: Maybe<boolean>

  nightlife?: Maybe<boolean>

  luxuryRentals?: Maybe<boolean>

  shortTermRentals?: Maybe<boolean>

  professionallyManaged?: Maybe<boolean>

  onlinePayments?: Maybe<boolean>

  onlineApplications?: Maybe<boolean>

  furnishedRentals?: Maybe<boolean>

  petFriendly?: Maybe<boolean>
}

export interface NeighbourhoodTagsWhereUniqueInput {
  id?: Maybe<string>
}

export interface UserCreateOneInput {
  create?: Maybe<UserCreateInput>

  connect?: Maybe<UserWhereUniqueInput>
}

export interface UserCreateInput {
  id?: Maybe<string>

  email: string

  auth0id: string

  firstName: string

  lastName: string

  legalName?: Maybe<string>

  userType: UserType

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingCreateManyWithoutOwnerInput>

  rooms?: Maybe<RoomCreateManyWithoutParticipantsInput>

  createdRooms?: Maybe<RoomCreateManyWithoutCreatorInput>

  bookings?: Maybe<BookingCreateManyWithoutUserInput>

  booking?: Maybe<BookingCreateManyWithoutSeekerInput>

  leaseAsLandlord?: Maybe<LeaseCreateManyWithoutLandlordSignersInput>

  leaseAsTenant?: Maybe<LeaseCreateManyWithoutTenantSignersInput>
}

export interface ListingCreateManyWithoutOwnerInput {
  create?: Maybe<ListingCreateWithoutOwnerInput[]>

  connect?: Maybe<ListingWhereUniqueInput[]>
}

export interface RoomCreateManyWithoutParticipantsInput {
  create?: Maybe<RoomCreateWithoutParticipantsInput[]>

  connect?: Maybe<RoomWhereUniqueInput[]>
}

export interface RoomCreateWithoutParticipantsInput {
  id?: Maybe<string>

  lastMessageTime: DateTime

  name?: Maybe<RoomCreatenameInput>

  creator: UserCreateOneWithoutCreatedRoomsInput

  rental?: Maybe<RentalCreateOneWithoutRoomsInput>

  messages?: Maybe<MessageCreateManyWithoutRoomInput>

  booking?: Maybe<BookingCreateOneWithoutRoomInput>
}

export interface RoomCreatenameInput {
  set?: Maybe<string[]>
}

export interface UserCreateOneWithoutCreatedRoomsInput {
  create?: Maybe<UserCreateWithoutCreatedRoomsInput>

  connect?: Maybe<UserWhereUniqueInput>
}

export interface UserCreateWithoutCreatedRoomsInput {
  id?: Maybe<string>

  email: string

  auth0id: string

  firstName: string

  lastName: string

  legalName?: Maybe<string>

  userType: UserType

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingCreateManyWithoutOwnerInput>

  rooms?: Maybe<RoomCreateManyWithoutParticipantsInput>

  bookings?: Maybe<BookingCreateManyWithoutUserInput>

  booking?: Maybe<BookingCreateManyWithoutSeekerInput>

  leaseAsLandlord?: Maybe<LeaseCreateManyWithoutLandlordSignersInput>

  leaseAsTenant?: Maybe<LeaseCreateManyWithoutTenantSignersInput>
}

export interface BookingCreateManyWithoutUserInput {
  create?: Maybe<BookingCreateWithoutUserInput[]>

  connect?: Maybe<BookingWhereUniqueInput[]>
}

export interface BookingCreateWithoutUserInput {
  id?: Maybe<string>

  roommate?: Maybe<boolean>

  introduction: string

  moveInDate?: Maybe<string>

  leaseTerm?: Maybe<string>

  unit?: Maybe<string>

  occupancyRate?: Maybe<OccupancyRate>

  certnApplicantId?: Maybe<string>

  Seeker: UserCreateOneWithoutBookingInput

  Listing: ListingCreateOneWithoutBookingsInput

  room?: Maybe<RoomCreateOneWithoutBookingInput>
}

export interface UserCreateOneWithoutBookingInput {
  create?: Maybe<UserCreateWithoutBookingInput>

  connect?: Maybe<UserWhereUniqueInput>
}

export interface UserCreateWithoutBookingInput {
  id?: Maybe<string>

  email: string

  auth0id: string

  firstName: string

  lastName: string

  legalName?: Maybe<string>

  userType: UserType

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingCreateManyWithoutOwnerInput>

  rooms?: Maybe<RoomCreateManyWithoutParticipantsInput>

  createdRooms?: Maybe<RoomCreateManyWithoutCreatorInput>

  bookings?: Maybe<BookingCreateManyWithoutUserInput>

  leaseAsLandlord?: Maybe<LeaseCreateManyWithoutLandlordSignersInput>

  leaseAsTenant?: Maybe<LeaseCreateManyWithoutTenantSignersInput>
}

export interface RoomCreateManyWithoutCreatorInput {
  create?: Maybe<RoomCreateWithoutCreatorInput[]>

  connect?: Maybe<RoomWhereUniqueInput[]>
}

export interface RoomCreateWithoutCreatorInput {
  id?: Maybe<string>

  lastMessageTime: DateTime

  name?: Maybe<RoomCreatenameInput>

  participants?: Maybe<UserCreateManyWithoutRoomsInput>

  rental?: Maybe<RentalCreateOneWithoutRoomsInput>

  messages?: Maybe<MessageCreateManyWithoutRoomInput>

  booking?: Maybe<BookingCreateOneWithoutRoomInput>
}

export interface UserCreateManyWithoutRoomsInput {
  create?: Maybe<UserCreateWithoutRoomsInput[]>

  connect?: Maybe<UserWhereUniqueInput[]>
}

export interface UserCreateWithoutRoomsInput {
  id?: Maybe<string>

  email: string

  auth0id: string

  firstName: string

  lastName: string

  legalName?: Maybe<string>

  userType: UserType

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingCreateManyWithoutOwnerInput>

  createdRooms?: Maybe<RoomCreateManyWithoutCreatorInput>

  bookings?: Maybe<BookingCreateManyWithoutUserInput>

  booking?: Maybe<BookingCreateManyWithoutSeekerInput>

  leaseAsLandlord?: Maybe<LeaseCreateManyWithoutLandlordSignersInput>

  leaseAsTenant?: Maybe<LeaseCreateManyWithoutTenantSignersInput>
}

export interface BookingCreateManyWithoutSeekerInput {
  create?: Maybe<BookingCreateWithoutSeekerInput[]>

  connect?: Maybe<BookingWhereUniqueInput[]>
}

export interface BookingCreateWithoutSeekerInput {
  id?: Maybe<string>

  roommate?: Maybe<boolean>

  introduction: string

  moveInDate?: Maybe<string>

  leaseTerm?: Maybe<string>

  unit?: Maybe<string>

  occupancyRate?: Maybe<OccupancyRate>

  certnApplicantId?: Maybe<string>

  Listing: ListingCreateOneWithoutBookingsInput

  user?: Maybe<UserCreateManyWithoutBookingsInput>

  room?: Maybe<RoomCreateOneWithoutBookingInput>
}

export interface ListingCreateOneWithoutBookingsInput {
  create?: Maybe<ListingCreateWithoutBookingsInput>

  connect?: Maybe<ListingWhereUniqueInput>
}

export interface ListingCreateWithoutBookingsInput {
  id?: Maybe<string>

  title: string

  description: string

  propertyType: PropertyType

  pets: Pets

  buildingConstructionYear?: Maybe<string>

  totalUnitsInBuilding?: Maybe<number>

  storiesInBuilding?: Maybe<number>

  developmentCompany?: Maybe<string>

  premiumStart?: Maybe<DateTime>

  premiumEnd?: Maybe<DateTime>

  isHidden?: Maybe<boolean>

  owner: UserCreateOneWithoutListingsInput

  location?: Maybe<LocationCreateOneWithoutListingsInput>

  utilities?: Maybe<UtilitiesCreateOneWithoutListingInput>

  unitAppliances?: Maybe<UnitAppliancesCreateOneWithoutListingInput>

  amenities?: Maybe<ListingAmenitiesCreateOneWithoutListingInput>

  tenantRequirements?: Maybe<TenantRequirementsCreateOneInput>

  pictures?: Maybe<PictureCreateManyInput>

  units?: Maybe<UnitCreateManyWithoutListingsInput>
}

export interface UserCreateOneWithoutListingsInput {
  create?: Maybe<UserCreateWithoutListingsInput>

  connect?: Maybe<UserWhereUniqueInput>
}

export interface UserCreateWithoutListingsInput {
  id?: Maybe<string>

  email: string

  auth0id: string

  firstName: string

  lastName: string

  legalName?: Maybe<string>

  userType: UserType

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  rooms?: Maybe<RoomCreateManyWithoutParticipantsInput>

  createdRooms?: Maybe<RoomCreateManyWithoutCreatorInput>

  bookings?: Maybe<BookingCreateManyWithoutUserInput>

  booking?: Maybe<BookingCreateManyWithoutSeekerInput>

  leaseAsLandlord?: Maybe<LeaseCreateManyWithoutLandlordSignersInput>

  leaseAsTenant?: Maybe<LeaseCreateManyWithoutTenantSignersInput>
}

export interface LeaseCreateManyWithoutLandlordSignersInput {
  create?: Maybe<LeaseCreateWithoutLandlordSignersInput[]>

  connect?: Maybe<LeaseWhereUniqueInput[]>
}

export interface LeaseCreateWithoutLandlordSignersInput {
  id?: Maybe<string>

  docusignEnvelopeUri?: Maybe<string>

  docusignEnvelopeId?: Maybe<string>

  status?: Maybe<DocusignEnvelopeStatus>

  subject?: Maybe<string>

  message?: Maybe<string>

  tenantSigners?: Maybe<UserCreateManyWithoutLeaseAsTenantInput>

  room?: Maybe<RoomCreateOneInput>
}

export interface UserCreateManyWithoutLeaseAsTenantInput {
  create?: Maybe<UserCreateWithoutLeaseAsTenantInput[]>

  connect?: Maybe<UserWhereUniqueInput[]>
}

export interface UserCreateWithoutLeaseAsTenantInput {
  id?: Maybe<string>

  email: string

  auth0id: string

  firstName: string

  lastName: string

  legalName?: Maybe<string>

  userType: UserType

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingCreateManyWithoutOwnerInput>

  rooms?: Maybe<RoomCreateManyWithoutParticipantsInput>

  createdRooms?: Maybe<RoomCreateManyWithoutCreatorInput>

  bookings?: Maybe<BookingCreateManyWithoutUserInput>

  booking?: Maybe<BookingCreateManyWithoutSeekerInput>

  leaseAsLandlord?: Maybe<LeaseCreateManyWithoutLandlordSignersInput>
}

export interface UserWhereUniqueInput {
  id?: Maybe<string>

  email?: Maybe<string>

  auth0id?: Maybe<string>
}

export interface RoomCreateOneInput {
  create?: Maybe<RoomCreateInput>

  connect?: Maybe<RoomWhereUniqueInput>
}

export interface RoomCreateInput {
  id?: Maybe<string>

  lastMessageTime: DateTime

  name?: Maybe<RoomCreatenameInput>

  creator: UserCreateOneWithoutCreatedRoomsInput

  participants?: Maybe<UserCreateManyWithoutRoomsInput>

  rental?: Maybe<RentalCreateOneWithoutRoomsInput>

  messages?: Maybe<MessageCreateManyWithoutRoomInput>

  booking?: Maybe<BookingCreateOneWithoutRoomInput>
}

export interface RentalCreateOneWithoutRoomsInput {
  create?: Maybe<RentalCreateWithoutRoomsInput>

  connect?: Maybe<RentalWhereUniqueInput>
}

export interface RentalCreateWithoutRoomsInput {
  id?: Maybe<string>
}

export interface RentalWhereUniqueInput {
  id?: Maybe<string>
}

export interface MessageCreateManyWithoutRoomInput {
  create?: Maybe<MessageCreateWithoutRoomInput[]>

  connect?: Maybe<MessageWhereUniqueInput[]>
}

export interface MessageCreateWithoutRoomInput {
  id?: Maybe<string>

  text?: Maybe<string>

  isRead?: Maybe<boolean>

  type?: Maybe<MessageType>

  url?: Maybe<string>

  emailNotificationSent?: Maybe<boolean>

  sender?: Maybe<UserCreateOneInput>
}

export interface MessageWhereUniqueInput {
  id?: Maybe<string>
}

export interface BookingCreateOneWithoutRoomInput {
  create?: Maybe<BookingCreateWithoutRoomInput>

  connect?: Maybe<BookingWhereUniqueInput>
}

export interface BookingCreateWithoutRoomInput {
  id?: Maybe<string>

  roommate?: Maybe<boolean>

  introduction: string

  moveInDate?: Maybe<string>

  leaseTerm?: Maybe<string>

  unit?: Maybe<string>

  occupancyRate?: Maybe<OccupancyRate>

  certnApplicantId?: Maybe<string>

  Seeker: UserCreateOneWithoutBookingInput

  Listing: ListingCreateOneWithoutBookingsInput

  user?: Maybe<UserCreateManyWithoutBookingsInput>
}

export interface UserCreateManyWithoutBookingsInput {
  create?: Maybe<UserCreateWithoutBookingsInput[]>

  connect?: Maybe<UserWhereUniqueInput[]>
}

export interface UserCreateWithoutBookingsInput {
  id?: Maybe<string>

  email: string

  auth0id: string

  firstName: string

  lastName: string

  legalName?: Maybe<string>

  userType: UserType

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingCreateManyWithoutOwnerInput>

  rooms?: Maybe<RoomCreateManyWithoutParticipantsInput>

  createdRooms?: Maybe<RoomCreateManyWithoutCreatorInput>

  booking?: Maybe<BookingCreateManyWithoutSeekerInput>

  leaseAsLandlord?: Maybe<LeaseCreateManyWithoutLandlordSignersInput>

  leaseAsTenant?: Maybe<LeaseCreateManyWithoutTenantSignersInput>
}

export interface LeaseCreateManyWithoutTenantSignersInput {
  create?: Maybe<LeaseCreateWithoutTenantSignersInput[]>

  connect?: Maybe<LeaseWhereUniqueInput[]>
}

export interface LeaseCreateWithoutTenantSignersInput {
  id?: Maybe<string>

  docusignEnvelopeUri?: Maybe<string>

  docusignEnvelopeId?: Maybe<string>

  status?: Maybe<DocusignEnvelopeStatus>

  subject?: Maybe<string>

  message?: Maybe<string>

  landlordSigners?: Maybe<UserCreateManyWithoutLeaseAsLandlordInput>

  room?: Maybe<RoomCreateOneInput>
}

export interface UserCreateManyWithoutLeaseAsLandlordInput {
  create?: Maybe<UserCreateWithoutLeaseAsLandlordInput[]>

  connect?: Maybe<UserWhereUniqueInput[]>
}

export interface UserCreateWithoutLeaseAsLandlordInput {
  id?: Maybe<string>

  email: string

  auth0id: string

  firstName: string

  lastName: string

  legalName?: Maybe<string>

  userType: UserType

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingCreateManyWithoutOwnerInput>

  rooms?: Maybe<RoomCreateManyWithoutParticipantsInput>

  createdRooms?: Maybe<RoomCreateManyWithoutCreatorInput>

  bookings?: Maybe<BookingCreateManyWithoutUserInput>

  booking?: Maybe<BookingCreateManyWithoutSeekerInput>

  leaseAsTenant?: Maybe<LeaseCreateManyWithoutTenantSignersInput>
}

export interface BookingWhereUniqueInput {
  id?: Maybe<string>

  certnApplicantId?: Maybe<string>
}

export interface RoomWhereUniqueInput {
  id?: Maybe<string>
}

export interface UtilitiesCreateOneWithoutListingInput {
  create?: Maybe<UtilitiesCreateWithoutListingInput>

  connect?: Maybe<UtilitiesWhereUniqueInput>
}

export interface UtilitiesCreateWithoutListingInput {
  id?: Maybe<string>

  electricity?: Maybe<boolean>

  heating?: Maybe<boolean>

  airConditioning?: Maybe<boolean>

  water?: Maybe<boolean>

  garbageRemoval?: Maybe<boolean>

  cableTv?: Maybe<boolean>

  internet?: Maybe<boolean>

  maintenanceFees?: Maybe<boolean>

  gas?: Maybe<boolean>
}

export interface UtilitiesWhereUniqueInput {
  id?: Maybe<string>
}

export interface ListingAmenitiesCreateOneWithoutListingInput {
  create?: Maybe<ListingAmenitiesCreateWithoutListingInput>

  connect?: Maybe<ListingAmenitiesWhereUniqueInput>
}

export interface ListingAmenitiesCreateWithoutListingInput {
  id?: Maybe<string>

  onSiteLaundry?: Maybe<boolean>

  yard?: Maybe<boolean>

  concierge?: Maybe<boolean>

  elevator?: Maybe<boolean>

  doorPerson?: Maybe<boolean>

  dryCleaning?: Maybe<boolean>

  gym?: Maybe<boolean>

  swimmingPool?: Maybe<boolean>

  storage?: Maybe<boolean>

  guestSuites?: Maybe<boolean>

  beanfieldWifi?: Maybe<boolean>

  publicWifi?: Maybe<boolean>

  bbqPermitted?: Maybe<boolean>

  visitorsParking?: Maybe<boolean>

  partyRoom?: Maybe<boolean>

  bikeStorage?: Maybe<boolean>

  wheelchairAccess?: Maybe<boolean>

  rooftopDeck?: Maybe<boolean>

  hotTub?: Maybe<boolean>

  sauna?: Maybe<boolean>

  gameRoom?: Maybe<boolean>

  theatre?: Maybe<boolean>

  businessCenter?: Maybe<boolean>

  communalGarden?: Maybe<boolean>

  petSpa?: Maybe<boolean>

  mediaRoom?: Maybe<boolean>

  basketballCourt?: Maybe<boolean>

  yogaRoom?: Maybe<boolean>

  tennisCourt?: Maybe<boolean>

  rooftopTerrace?: Maybe<boolean>

  coworkingSpace?: Maybe<boolean>

  conferenceRoom?: Maybe<boolean>

  patio?: Maybe<boolean>

  lounge?: Maybe<boolean>
}

export interface ListingAmenitiesWhereUniqueInput {
  id?: Maybe<string>
}

export interface TenantRequirementsCreateOneInput {
  create?: Maybe<TenantRequirementsCreateInput>

  connect?: Maybe<TenantRequirementsWhereUniqueInput>
}

export interface TenantRequirementsCreateInput {
  id?: Maybe<string>

  exceptionalCredit?: Maybe<boolean>

  payStub?: Maybe<boolean>

  letterOfEmployment?: Maybe<boolean>

  pastReferences?: Maybe<boolean>

  noSmoking?: Maybe<boolean>

  extraDocumentation?: Maybe<boolean>

  photoId?: Maybe<boolean>
}

export interface TenantRequirementsWhereUniqueInput {
  id?: Maybe<string>
}

export interface PictureCreateManyInput {
  create?: Maybe<PictureCreateInput[]>

  connect?: Maybe<PictureWhereUniqueInput[]>
}

export interface UnitCreateManyWithoutListingsInput {
  create?: Maybe<UnitCreateWithoutListingsInput[]>

  connect?: Maybe<UnitWhereUniqueInput[]>
}

export interface UnitCreateWithoutListingsInput {
  id?: Maybe<string>

  title: string

  number?: Maybe<string>

  den: Maybe<number>

  available?: Maybe<boolean>

  startDateAvailability?: Maybe<DateTime>

  unitSize?: Maybe<number>

  bedrooms: Bedrooms

  monthlyPrice: number

  bathrooms: number

  floorplan?: Maybe<string>

  furnished: boolean

  deposit?: Maybe<number>

  leaseTerm?: Maybe<number>

  parking: Parking

  amenities?: Maybe<UnitAmenitiesCreateOneWithoutUnitInput>

  pictures?: Maybe<PictureCreateManyInput>
}

export interface UnitAmenitiesCreateOneWithoutUnitInput {
  create?: Maybe<UnitAmenitiesCreateWithoutUnitInput>

  connect?: Maybe<UnitAmenitiesWhereUniqueInput>
}

export interface UnitAmenitiesCreateWithoutUnitInput {
  id?: Maybe<string>

  ac?: Maybe<boolean>

  centralHeat?: Maybe<boolean>

  electricalHeating?: Maybe<boolean>

  radiatorHeating?: Maybe<boolean>

  fireplace?: Maybe<boolean>

  carpet?: Maybe<boolean>

  ceilingFan?: Maybe<boolean>

  hardwoodFloor?: Maybe<boolean>

  walkInCloset?: Maybe<boolean>

  highCeilings?: Maybe<boolean>

  bathroomFan?: Maybe<boolean>

  balcony?: Maybe<boolean>
}

export interface UnitAppliancesCreateOneWithoutUnitInput {
  create?: Maybe<UnitAppliancesCreateWithoutUnitInput>

  connect?: Maybe<UnitAppliancesWhereUniqueInput>
}

export interface UnitAppliancesCreateWithoutUnitInput {
  id?: Maybe<string>

  fridge?: Maybe<boolean>

  stove?: Maybe<boolean>

  rangehood?: Maybe<boolean>

  dishwasher?: Maybe<boolean>

  microwave?: Maybe<boolean>

  inunitwasher?: Maybe<boolean>

  inunitdryer?: Maybe<boolean>

  wineFridge?: Maybe<boolean>

  dehumidifier?: Maybe<boolean>

  firePlaceElectric?: Maybe<boolean>

  firePlaceGas?: Maybe<boolean>

  jacuzzi?: Maybe<boolean>
}

export interface UnitAmenitiesWhereUniqueInput {
  id?: Maybe<string>
}

export interface UnitAppliancesWhereUniqueInput {
  id?: Maybe<string>
}

export interface UnitWhereUniqueInput {
  id?: Maybe<string>
}

export interface RoomCreateOneWithoutBookingInput {
  create?: Maybe<RoomCreateWithoutBookingInput>

  connect?: Maybe<RoomWhereUniqueInput>
}

export interface RoomCreateWithoutBookingInput {
  id?: Maybe<string>

  lastMessageTime: DateTime

  name?: Maybe<RoomCreatenameInput>

  creator: UserCreateOneWithoutCreatedRoomsInput

  participants?: Maybe<UserCreateManyWithoutRoomsInput>

  rental?: Maybe<RentalCreateOneWithoutRoomsInput>

  messages?: Maybe<MessageCreateManyWithoutRoomInput>
}

export interface LocationWhereUniqueInput {
  id?: Maybe<string>
}

export interface BookingCreateManyWithoutListingInput {
  create?: Maybe<BookingCreateWithoutListingInput[]>

  connect?: Maybe<BookingWhereUniqueInput[]>
}

export interface BookingCreateWithoutListingInput {
  id?: Maybe<string>

  roommate?: Maybe<boolean>

  introduction: string

  moveInDate?: Maybe<string>

  leaseTerm?: Maybe<string>

  unit?: Maybe<string>

  occupancyRate?: Maybe<OccupancyRate>

  certnApplicantId?: Maybe<string>

  Seeker: UserCreateOneWithoutBookingInput

  user?: Maybe<UserCreateManyWithoutBookingsInput>

  room?: Maybe<RoomCreateOneWithoutBookingInput>
}

export interface ListingUpdateWithWhereUniqueWithoutOwnerInput {
  where: ListingWhereUniqueInput

  data: ListingUpdateWithoutOwnerDataInput
}

export interface ListingUpdateWithoutOwnerDataInput {
  title?: Maybe<string>

  description?: Maybe<string>

  propertyType?: Maybe<PropertyType>

  pets?: Maybe<Pets>

  buildingConstructionYear?: Maybe<string>

  totalUnitsInBuilding?: Maybe<number>

  storiesInBuilding?: Maybe<number>

  developmentCompany?: Maybe<string>

  disableListing?: Maybe<string>

  premiumStart?: Maybe<DateTime>

  premiumEnd?: Maybe<DateTime>

  isHidden?: Maybe<boolean>

  location?: Maybe<LocationUpdateOneWithoutListingsInput>

  utilities?: Maybe<UtilitiesUpdateOneWithoutListingInput>

  amenities?: Maybe<ListingAmenitiesUpdateOneWithoutListingInput>

  tenantRequirements?: Maybe<TenantRequirementsUpdateOneInput>

  pictures?: Maybe<PictureUpdateManyInput>

  units?: Maybe<UnitUpdateManyWithoutListingsInput>

  bookings?: Maybe<BookingUpdateManyWithoutListingInput>
}

export interface LocationUpdateOneWithoutListingsInput {
  create?: Maybe<LocationCreateWithoutListingsInput>

  connect?: Maybe<LocationWhereUniqueInput>

  disconnect?: Maybe<boolean>

  delete?: Maybe<boolean>

  update?: Maybe<LocationUpdateWithoutListingsDataInput>

  upsert?: Maybe<LocationUpsertWithoutListingsInput>
}

export interface LocationUpdateWithoutListingsDataInput {
  lat?: Maybe<number>

  lng?: Maybe<number>

  address?: Maybe<string>

  apt?: Maybe<string>

  directions?: Maybe<string>

  neighbourHood?: Maybe<NeighbourhoodUpdateOneWithoutLocationsInput>

  tags?: Maybe<NeighbourhoodTagsUpdateOneInput>

  user?: Maybe<UserUpdateOneInput>
}

export interface NeighbourhoodUpdateOneWithoutLocationsInput {
  create?: Maybe<NeighbourhoodCreateWithoutLocationsInput>

  connect?: Maybe<NeighbourhoodWhereUniqueInput>

  disconnect?: Maybe<boolean>

  delete?: Maybe<boolean>

  update?: Maybe<NeighbourhoodUpdateWithoutLocationsDataInput>

  upsert?: Maybe<NeighbourhoodUpsertWithoutLocationsInput>
}

export interface NeighbourhoodUpdateWithoutLocationsDataInput {
  name?: Maybe<string>

  slug?: Maybe<string>

  featured?: Maybe<boolean>

  popularity?: Maybe<number>

  homePreview?: Maybe<PictureUpdateOneInput>

  city?: Maybe<CityUpdateOneRequiredWithoutNeighbourhoodsInput>
}

export interface PictureUpdateOneInput {
  create?: Maybe<PictureCreateInput>

  connect?: Maybe<PictureWhereUniqueInput>

  disconnect?: Maybe<boolean>

  delete?: Maybe<boolean>

  update?: Maybe<PictureUpdateDataInput>

  upsert?: Maybe<PictureUpsertNestedInput>
}

export interface PictureUpdateDataInput {
  filename?: Maybe<string>

  url?: Maybe<string>
}

export interface PictureUpsertNestedInput {
  update: PictureUpdateDataInput

  create: PictureCreateInput
}

export interface CityUpdateOneRequiredWithoutNeighbourhoodsInput {
  create?: Maybe<CityCreateWithoutNeighbourhoodsInput>

  connect?: Maybe<CityWhereUniqueInput>

  update?: Maybe<CityUpdateWithoutNeighbourhoodsDataInput>

  upsert?: Maybe<CityUpsertWithoutNeighbourhoodsInput>
}

export interface CityUpdateWithoutNeighbourhoodsDataInput {
  name?: Maybe<string>
}

export interface CityUpsertWithoutNeighbourhoodsInput {
  update: CityUpdateWithoutNeighbourhoodsDataInput

  create: CityCreateWithoutNeighbourhoodsInput
}

export interface NeighbourhoodUpsertWithoutLocationsInput {
  update: NeighbourhoodUpdateWithoutLocationsDataInput

  create: NeighbourhoodCreateWithoutLocationsInput
}

export interface NeighbourhoodTagsUpdateOneInput {
  create?: Maybe<NeighbourhoodTagsCreateInput>

  connect?: Maybe<NeighbourhoodTagsWhereUniqueInput>

  disconnect?: Maybe<boolean>

  delete?: Maybe<boolean>

  update?: Maybe<NeighbourhoodTagsUpdateDataInput>

  upsert?: Maybe<NeighbourhoodTagsUpsertNestedInput>
}

export interface NeighbourhoodTagsUpdateDataInput {
  closeToTransit?: Maybe<boolean>

  purposeBuiltRentals?: Maybe<boolean>

  students?: Maybe<boolean>

  parksAndWater?: Maybe<boolean>

  nightlife?: Maybe<boolean>

  luxuryRentals?: Maybe<boolean>

  shortTermRentals?: Maybe<boolean>

  professionallyManaged?: Maybe<boolean>

  onlinePayments?: Maybe<boolean>

  onlineApplications?: Maybe<boolean>

  furnishedRentals?: Maybe<boolean>

  petFriendly?: Maybe<boolean>
}

export interface NeighbourhoodTagsUpsertNestedInput {
  update: NeighbourhoodTagsUpdateDataInput

  create: NeighbourhoodTagsCreateInput
}

export interface UserUpdateOneInput {
  create?: Maybe<UserCreateInput>

  connect?: Maybe<UserWhereUniqueInput>

  disconnect?: Maybe<boolean>

  delete?: Maybe<boolean>

  update?: Maybe<UserUpdateDataInput>

  upsert?: Maybe<UserUpsertNestedInput>
}

export interface UserUpdateDataInput {
  email?: Maybe<string>

  auth0id?: Maybe<string>

  firstName?: Maybe<string>

  lastName?: Maybe<string>

  legalName?: Maybe<string>

  userType?: Maybe<UserType>

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingUpdateManyWithoutOwnerInput>

  rooms?: Maybe<RoomUpdateManyWithoutParticipantsInput>

  createdRooms?: Maybe<RoomUpdateManyWithoutCreatorInput>

  bookings?: Maybe<BookingUpdateManyWithoutUserInput>

  booking?: Maybe<BookingUpdateManyWithoutSeekerInput>

  leaseAsLandlord?: Maybe<LeaseUpdateManyWithoutLandlordSignersInput>

  leaseAsTenant?: Maybe<LeaseUpdateManyWithoutTenantSignersInput>
}

export interface RoomUpdateManyWithoutParticipantsInput {
  create?: Maybe<RoomCreateWithoutParticipantsInput[]>

  connect?: Maybe<RoomWhereUniqueInput[]>

  set?: Maybe<RoomWhereUniqueInput[]>

  disconnect?: Maybe<RoomWhereUniqueInput[]>

  delete?: Maybe<RoomWhereUniqueInput[]>

  update?: Maybe<RoomUpdateWithWhereUniqueWithoutParticipantsInput[]>

  updateMany?: Maybe<RoomUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<RoomScalarWhereInput[]>

  upsert?: Maybe<RoomUpsertWithWhereUniqueWithoutParticipantsInput[]>
}

export interface RoomUpdateWithWhereUniqueWithoutParticipantsInput {
  where: RoomWhereUniqueInput

  data: RoomUpdateWithoutParticipantsDataInput
}

export interface RoomUpdateWithoutParticipantsDataInput {
  lastMessageTime?: Maybe<DateTime>

  name?: Maybe<RoomUpdatenameInput>

  creator?: Maybe<UserUpdateOneRequiredWithoutCreatedRoomsInput>

  rental?: Maybe<RentalUpdateOneWithoutRoomsInput>

  messages?: Maybe<MessageUpdateManyWithoutRoomInput>

  booking?: Maybe<BookingUpdateOneWithoutRoomInput>
}

export interface RoomUpdatenameInput {
  set?: Maybe<string[]>
}

export interface UserUpdateOneRequiredWithoutCreatedRoomsInput {
  create?: Maybe<UserCreateWithoutCreatedRoomsInput>

  connect?: Maybe<UserWhereUniqueInput>

  update?: Maybe<UserUpdateWithoutCreatedRoomsDataInput>

  upsert?: Maybe<UserUpsertWithoutCreatedRoomsInput>
}

export interface UserUpdateWithoutCreatedRoomsDataInput {
  email?: Maybe<string>

  auth0id?: Maybe<string>

  firstName?: Maybe<string>

  lastName?: Maybe<string>

  legalName?: Maybe<string>

  userType?: Maybe<UserType>

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingUpdateManyWithoutOwnerInput>

  rooms?: Maybe<RoomUpdateManyWithoutParticipantsInput>

  bookings?: Maybe<BookingUpdateManyWithoutUserInput>

  booking?: Maybe<BookingUpdateManyWithoutSeekerInput>

  leaseAsLandlord?: Maybe<LeaseUpdateManyWithoutLandlordSignersInput>

  leaseAsTenant?: Maybe<LeaseUpdateManyWithoutTenantSignersInput>
}

export interface BookingUpdateManyWithoutUserInput {
  create?: Maybe<BookingCreateWithoutUserInput[]>

  connect?: Maybe<BookingWhereUniqueInput[]>

  set?: Maybe<BookingWhereUniqueInput[]>

  disconnect?: Maybe<BookingWhereUniqueInput[]>

  delete?: Maybe<BookingWhereUniqueInput[]>

  update?: Maybe<BookingUpdateWithWhereUniqueWithoutUserInput[]>

  updateMany?: Maybe<BookingUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<BookingScalarWhereInput[]>

  upsert?: Maybe<BookingUpsertWithWhereUniqueWithoutUserInput[]>
}

export interface BookingUpdateWithWhereUniqueWithoutUserInput {
  where: BookingWhereUniqueInput

  data: BookingUpdateWithoutUserDataInput
}

export interface BookingUpdateWithoutUserDataInput {
  roommate?: Maybe<boolean>

  introduction?: Maybe<string>

  moveInDate?: Maybe<string>

  leaseTerm?: Maybe<string>

  unit?: Maybe<string>

  occupancyRate?: Maybe<OccupancyRate>

  certnApplicantId?: Maybe<string>

  Seeker?: Maybe<UserUpdateOneRequiredWithoutBookingInput>

  Listing?: Maybe<ListingUpdateOneRequiredWithoutBookingsInput>

  room?: Maybe<RoomUpdateOneWithoutBookingInput>
}

export interface UserUpdateOneRequiredWithoutBookingInput {
  create?: Maybe<UserCreateWithoutBookingInput>

  connect?: Maybe<UserWhereUniqueInput>

  update?: Maybe<UserUpdateWithoutBookingDataInput>

  upsert?: Maybe<UserUpsertWithoutBookingInput>
}

export interface UserUpdateWithoutBookingDataInput {
  email?: Maybe<string>

  auth0id?: Maybe<string>

  firstName?: Maybe<string>

  lastName?: Maybe<string>

  legalName?: Maybe<string>

  userType?: Maybe<UserType>

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingUpdateManyWithoutOwnerInput>

  rooms?: Maybe<RoomUpdateManyWithoutParticipantsInput>

  createdRooms?: Maybe<RoomUpdateManyWithoutCreatorInput>

  bookings?: Maybe<BookingUpdateManyWithoutUserInput>

  leaseAsLandlord?: Maybe<LeaseUpdateManyWithoutLandlordSignersInput>

  leaseAsTenant?: Maybe<LeaseUpdateManyWithoutTenantSignersInput>
}

export interface RoomUpdateManyWithoutCreatorInput {
  create?: Maybe<RoomCreateWithoutCreatorInput[]>

  connect?: Maybe<RoomWhereUniqueInput[]>

  set?: Maybe<RoomWhereUniqueInput[]>

  disconnect?: Maybe<RoomWhereUniqueInput[]>

  delete?: Maybe<RoomWhereUniqueInput[]>

  update?: Maybe<RoomUpdateWithWhereUniqueWithoutCreatorInput[]>

  updateMany?: Maybe<RoomUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<RoomScalarWhereInput[]>

  upsert?: Maybe<RoomUpsertWithWhereUniqueWithoutCreatorInput[]>
}

export interface RoomUpdateWithWhereUniqueWithoutCreatorInput {
  where: RoomWhereUniqueInput

  data: RoomUpdateWithoutCreatorDataInput
}

export interface RoomUpdateWithoutCreatorDataInput {
  lastMessageTime?: Maybe<DateTime>

  name?: Maybe<RoomUpdatenameInput>

  participants?: Maybe<UserUpdateManyWithoutRoomsInput>

  rental?: Maybe<RentalUpdateOneWithoutRoomsInput>

  messages?: Maybe<MessageUpdateManyWithoutRoomInput>

  booking?: Maybe<BookingUpdateOneWithoutRoomInput>
}

export interface UserUpdateManyWithoutRoomsInput {
  create?: Maybe<UserCreateWithoutRoomsInput[]>

  connect?: Maybe<UserWhereUniqueInput[]>

  set?: Maybe<UserWhereUniqueInput[]>

  disconnect?: Maybe<UserWhereUniqueInput[]>

  delete?: Maybe<UserWhereUniqueInput[]>

  update?: Maybe<UserUpdateWithWhereUniqueWithoutRoomsInput[]>

  updateMany?: Maybe<UserUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<UserScalarWhereInput[]>

  upsert?: Maybe<UserUpsertWithWhereUniqueWithoutRoomsInput[]>
}

export interface UserUpdateWithWhereUniqueWithoutRoomsInput {
  where: UserWhereUniqueInput

  data: UserUpdateWithoutRoomsDataInput
}

export interface UserUpdateWithoutRoomsDataInput {
  email?: Maybe<string>

  auth0id?: Maybe<string>

  firstName?: Maybe<string>

  lastName?: Maybe<string>

  legalName?: Maybe<string>

  userType?: Maybe<UserType>

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingUpdateManyWithoutOwnerInput>

  createdRooms?: Maybe<RoomUpdateManyWithoutCreatorInput>

  bookings?: Maybe<BookingUpdateManyWithoutUserInput>

  booking?: Maybe<BookingUpdateManyWithoutSeekerInput>

  leaseAsLandlord?: Maybe<LeaseUpdateManyWithoutLandlordSignersInput>

  leaseAsTenant?: Maybe<LeaseUpdateManyWithoutTenantSignersInput>
}

export interface BookingUpdateManyWithoutSeekerInput {
  create?: Maybe<BookingCreateWithoutSeekerInput[]>

  connect?: Maybe<BookingWhereUniqueInput[]>

  set?: Maybe<BookingWhereUniqueInput[]>

  disconnect?: Maybe<BookingWhereUniqueInput[]>

  delete?: Maybe<BookingWhereUniqueInput[]>

  update?: Maybe<BookingUpdateWithWhereUniqueWithoutSeekerInput[]>

  updateMany?: Maybe<BookingUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<BookingScalarWhereInput[]>

  upsert?: Maybe<BookingUpsertWithWhereUniqueWithoutSeekerInput[]>
}

export interface BookingUpdateWithWhereUniqueWithoutSeekerInput {
  where: BookingWhereUniqueInput

  data: BookingUpdateWithoutSeekerDataInput
}

export interface BookingUpdateWithoutSeekerDataInput {
  roommate?: Maybe<boolean>

  introduction?: Maybe<string>

  moveInDate?: Maybe<string>

  leaseTerm?: Maybe<string>

  unit?: Maybe<string>

  occupancyRate?: Maybe<OccupancyRate>

  certnApplicantId?: Maybe<string>

  Listing?: Maybe<ListingUpdateOneRequiredWithoutBookingsInput>

  user?: Maybe<UserUpdateManyWithoutBookingsInput>

  room?: Maybe<RoomUpdateOneWithoutBookingInput>
}

export interface ListingUpdateOneRequiredWithoutBookingsInput {
  create?: Maybe<ListingCreateWithoutBookingsInput>

  connect?: Maybe<ListingWhereUniqueInput>

  update?: Maybe<ListingUpdateWithoutBookingsDataInput>

  upsert?: Maybe<ListingUpsertWithoutBookingsInput>
}

export interface ListingUpdateWithoutBookingsDataInput {
  title?: Maybe<string>

  description?: Maybe<string>

  propertyType?: Maybe<PropertyType>

  pets?: Maybe<Pets>

  buildingConstructionYear?: Maybe<string>

  totalUnitsInBuilding?: Maybe<number>

  storiesInBuilding?: Maybe<number>

  developmentCompany?: Maybe<string>

  premiumStart?: Maybe<DateTime>

  premiumEnd?: Maybe<DateTime>

  isHidden?: Maybe<boolean>

  owner?: Maybe<UserUpdateOneRequiredWithoutListingsInput>

  location?: Maybe<LocationUpdateOneWithoutListingsInput>

  utilities?: Maybe<UtilitiesUpdateOneWithoutListingInput>

  amenities?: Maybe<ListingAmenitiesUpdateOneWithoutListingInput>

  tenantRequirements?: Maybe<TenantRequirementsUpdateOneInput>

  pictures?: Maybe<PictureUpdateManyInput>

  units?: Maybe<UnitUpdateManyWithoutListingsInput>
}

export interface UserUpdateOneRequiredWithoutListingsInput {
  create?: Maybe<UserCreateWithoutListingsInput>

  connect?: Maybe<UserWhereUniqueInput>

  update?: Maybe<UserUpdateWithoutListingsDataInput>

  upsert?: Maybe<UserUpsertWithoutListingsInput>
}

export interface UserUpdateWithoutListingsDataInput {
  email?: Maybe<string>

  auth0id?: Maybe<string>

  firstName?: Maybe<string>

  lastName?: Maybe<string>

  legalName?: Maybe<string>

  userType?: Maybe<UserType>

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  rooms?: Maybe<RoomUpdateManyWithoutParticipantsInput>

  createdRooms?: Maybe<RoomUpdateManyWithoutCreatorInput>

  bookings?: Maybe<BookingUpdateManyWithoutUserInput>

  booking?: Maybe<BookingUpdateManyWithoutSeekerInput>

  leaseAsLandlord?: Maybe<LeaseUpdateManyWithoutLandlordSignersInput>

  leaseAsTenant?: Maybe<LeaseUpdateManyWithoutTenantSignersInput>
}

export interface LeaseUpdateManyWithoutLandlordSignersInput {
  create?: Maybe<LeaseCreateWithoutLandlordSignersInput[]>

  connect?: Maybe<LeaseWhereUniqueInput[]>

  set?: Maybe<LeaseWhereUniqueInput[]>

  disconnect?: Maybe<LeaseWhereUniqueInput[]>

  delete?: Maybe<LeaseWhereUniqueInput[]>

  update?: Maybe<LeaseUpdateWithWhereUniqueWithoutLandlordSignersInput[]>

  updateMany?: Maybe<LeaseUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<LeaseScalarWhereInput[]>

  upsert?: Maybe<LeaseUpsertWithWhereUniqueWithoutLandlordSignersInput[]>
}

export interface LeaseUpdateWithWhereUniqueWithoutLandlordSignersInput {
  where: LeaseWhereUniqueInput

  data: LeaseUpdateWithoutLandlordSignersDataInput
}

export interface LeaseUpdateWithoutLandlordSignersDataInput {
  docusignEnvelopeUri?: Maybe<string>

  docusignEnvelopeId?: Maybe<string>

  status?: Maybe<DocusignEnvelopeStatus>

  subject?: Maybe<string>

  message?: Maybe<string>

  tenantSigners?: Maybe<UserUpdateManyWithoutLeaseAsTenantInput>

  room?: Maybe<RoomUpdateOneInput>
}

export interface UserUpdateManyWithoutLeaseAsTenantInput {
  create?: Maybe<UserCreateWithoutLeaseAsTenantInput[]>

  connect?: Maybe<UserWhereUniqueInput[]>

  set?: Maybe<UserWhereUniqueInput[]>

  disconnect?: Maybe<UserWhereUniqueInput[]>

  delete?: Maybe<UserWhereUniqueInput[]>

  update?: Maybe<UserUpdateWithWhereUniqueWithoutLeaseAsTenantInput[]>

  updateMany?: Maybe<UserUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<UserScalarWhereInput[]>

  upsert?: Maybe<UserUpsertWithWhereUniqueWithoutLeaseAsTenantInput[]>
}

export interface UserUpdateWithWhereUniqueWithoutLeaseAsTenantInput {
  where: UserWhereUniqueInput

  data: UserUpdateWithoutLeaseAsTenantDataInput
}

export interface UserUpdateWithoutLeaseAsTenantDataInput {
  email?: Maybe<string>

  auth0id?: Maybe<string>

  firstName?: Maybe<string>

  lastName?: Maybe<string>

  legalName?: Maybe<string>

  userType?: Maybe<UserType>

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingUpdateManyWithoutOwnerInput>

  rooms?: Maybe<RoomUpdateManyWithoutParticipantsInput>

  createdRooms?: Maybe<RoomUpdateManyWithoutCreatorInput>

  bookings?: Maybe<BookingUpdateManyWithoutUserInput>

  booking?: Maybe<BookingUpdateManyWithoutSeekerInput>

  leaseAsLandlord?: Maybe<LeaseUpdateManyWithoutLandlordSignersInput>
}

export interface UserUpdateManyWithWhereNestedInput {
  where: UserScalarWhereInput

  data: UserUpdateManyDataInput
}

export interface UserScalarWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<UserScalarWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<UserScalarWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<UserScalarWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  email?: Maybe<string>
  /** All values that are not equal to given value. */
  email_not?: Maybe<string>
  /** All values that are contained in given list. */
  email_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  email_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  email_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  email_lte?: Maybe<string>
  /** All values greater than the given value. */
  email_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  email_gte?: Maybe<string>
  /** All values containing the given string. */
  email_contains?: Maybe<string>
  /** All values not containing the given string. */
  email_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  email_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  email_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  email_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  email_not_ends_with?: Maybe<string>

  auth0id?: Maybe<string>
  /** All values that are not equal to given value. */
  auth0id_not?: Maybe<string>
  /** All values that are contained in given list. */
  auth0id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  auth0id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  auth0id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  auth0id_lte?: Maybe<string>
  /** All values greater than the given value. */
  auth0id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  auth0id_gte?: Maybe<string>
  /** All values containing the given string. */
  auth0id_contains?: Maybe<string>
  /** All values not containing the given string. */
  auth0id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  auth0id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  auth0id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  auth0id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  auth0id_not_ends_with?: Maybe<string>

  firstName?: Maybe<string>
  /** All values that are not equal to given value. */
  firstName_not?: Maybe<string>
  /** All values that are contained in given list. */
  firstName_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  firstName_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  firstName_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  firstName_lte?: Maybe<string>
  /** All values greater than the given value. */
  firstName_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  firstName_gte?: Maybe<string>
  /** All values containing the given string. */
  firstName_contains?: Maybe<string>
  /** All values not containing the given string. */
  firstName_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  firstName_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  firstName_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  firstName_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  firstName_not_ends_with?: Maybe<string>

  lastName?: Maybe<string>
  /** All values that are not equal to given value. */
  lastName_not?: Maybe<string>
  /** All values that are contained in given list. */
  lastName_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  lastName_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  lastName_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  lastName_lte?: Maybe<string>
  /** All values greater than the given value. */
  lastName_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  lastName_gte?: Maybe<string>
  /** All values containing the given string. */
  lastName_contains?: Maybe<string>
  /** All values not containing the given string. */
  lastName_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  lastName_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  lastName_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  lastName_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  lastName_not_ends_with?: Maybe<string>

  legalName?: Maybe<string>
  /** All values that are not equal to given value. */
  legalName_not?: Maybe<string>
  /** All values that are contained in given list. */
  legalName_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  legalName_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  legalName_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  legalName_lte?: Maybe<string>
  /** All values greater than the given value. */
  legalName_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  legalName_gte?: Maybe<string>
  /** All values containing the given string. */
  legalName_contains?: Maybe<string>
  /** All values not containing the given string. */
  legalName_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  legalName_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  legalName_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  legalName_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  legalName_not_ends_with?: Maybe<string>

  userType?: Maybe<UserType>
  /** All values that are not equal to given value. */
  userType_not?: Maybe<UserType>
  /** All values that are contained in given list. */
  userType_in?: Maybe<UserType[]>
  /** All values that are not contained in given list. */
  userType_not_in?: Maybe<UserType[]>

  avatarUrl?: Maybe<string>
  /** All values that are not equal to given value. */
  avatarUrl_not?: Maybe<string>
  /** All values that are contained in given list. */
  avatarUrl_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  avatarUrl_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  avatarUrl_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  avatarUrl_lte?: Maybe<string>
  /** All values greater than the given value. */
  avatarUrl_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  avatarUrl_gte?: Maybe<string>
  /** All values containing the given string. */
  avatarUrl_contains?: Maybe<string>
  /** All values not containing the given string. */
  avatarUrl_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  avatarUrl_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  avatarUrl_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  avatarUrl_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  avatarUrl_not_ends_with?: Maybe<string>

  industry?: Maybe<string>
  /** All values that are not equal to given value. */
  industry_not?: Maybe<string>
  /** All values that are contained in given list. */
  industry_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  industry_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  industry_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  industry_lte?: Maybe<string>
  /** All values greater than the given value. */
  industry_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  industry_gte?: Maybe<string>
  /** All values containing the given string. */
  industry_contains?: Maybe<string>
  /** All values not containing the given string. */
  industry_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  industry_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  industry_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  industry_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  industry_not_ends_with?: Maybe<string>

  bio?: Maybe<string>
  /** All values that are not equal to given value. */
  bio_not?: Maybe<string>
  /** All values that are contained in given list. */
  bio_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  bio_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  bio_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  bio_lte?: Maybe<string>
  /** All values greater than the given value. */
  bio_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  bio_gte?: Maybe<string>
  /** All values containing the given string. */
  bio_contains?: Maybe<string>
  /** All values not containing the given string. */
  bio_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  bio_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  bio_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  bio_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  bio_not_ends_with?: Maybe<string>

  locale?: Maybe<string>
  /** All values that are not equal to given value. */
  locale_not?: Maybe<string>
  /** All values that are contained in given list. */
  locale_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  locale_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  locale_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  locale_lte?: Maybe<string>
  /** All values greater than the given value. */
  locale_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  locale_gte?: Maybe<string>
  /** All values containing the given string. */
  locale_contains?: Maybe<string>
  /** All values not containing the given string. */
  locale_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  locale_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  locale_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  locale_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  locale_not_ends_with?: Maybe<string>

  gender?: Maybe<string>
  /** All values that are not equal to given value. */
  gender_not?: Maybe<string>
  /** All values that are contained in given list. */
  gender_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  gender_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  gender_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  gender_lte?: Maybe<string>
  /** All values greater than the given value. */
  gender_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  gender_gte?: Maybe<string>
  /** All values containing the given string. */
  gender_contains?: Maybe<string>
  /** All values not containing the given string. */
  gender_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  gender_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  gender_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  gender_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  gender_not_ends_with?: Maybe<string>

  phone?: Maybe<string>
  /** All values that are not equal to given value. */
  phone_not?: Maybe<string>
  /** All values that are contained in given list. */
  phone_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  phone_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  phone_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  phone_lte?: Maybe<string>
  /** All values greater than the given value. */
  phone_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  phone_gte?: Maybe<string>
  /** All values containing the given string. */
  phone_contains?: Maybe<string>
  /** All values not containing the given string. */
  phone_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  phone_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  phone_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  phone_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  phone_not_ends_with?: Maybe<string>

  verified?: Maybe<boolean>
  /** All values that are not equal to given value. */
  verified_not?: Maybe<boolean>

  city?: Maybe<string>
  /** All values that are not equal to given value. */
  city_not?: Maybe<string>
  /** All values that are contained in given list. */
  city_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  city_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  city_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  city_lte?: Maybe<string>
  /** All values greater than the given value. */
  city_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  city_gte?: Maybe<string>
  /** All values containing the given string. */
  city_contains?: Maybe<string>
  /** All values not containing the given string. */
  city_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  city_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  city_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  city_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  city_not_ends_with?: Maybe<string>

  moveIn?: Maybe<string>
  /** All values that are not equal to given value. */
  moveIn_not?: Maybe<string>
  /** All values that are contained in given list. */
  moveIn_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  moveIn_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  moveIn_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  moveIn_lte?: Maybe<string>
  /** All values greater than the given value. */
  moveIn_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  moveIn_gte?: Maybe<string>
  /** All values containing the given string. */
  moveIn_contains?: Maybe<string>
  /** All values not containing the given string. */
  moveIn_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  moveIn_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  moveIn_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  moveIn_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  moveIn_not_ends_with?: Maybe<string>

  budget?: Maybe<string>
  /** All values that are not equal to given value. */
  budget_not?: Maybe<string>
  /** All values that are contained in given list. */
  budget_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  budget_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  budget_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  budget_lte?: Maybe<string>
  /** All values greater than the given value. */
  budget_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  budget_gte?: Maybe<string>
  /** All values containing the given string. */
  budget_contains?: Maybe<string>
  /** All values not containing the given string. */
  budget_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  budget_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  budget_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  budget_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  budget_not_ends_with?: Maybe<string>

  roommate?: Maybe<boolean>
  /** All values that are not equal to given value. */
  roommate_not?: Maybe<boolean>

  stripeId?: Maybe<string>
  /** All values that are not equal to given value. */
  stripeId_not?: Maybe<string>
  /** All values that are contained in given list. */
  stripeId_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  stripeId_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  stripeId_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  stripeId_lte?: Maybe<string>
  /** All values greater than the given value. */
  stripeId_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  stripeId_gte?: Maybe<string>
  /** All values containing the given string. */
  stripeId_contains?: Maybe<string>
  /** All values not containing the given string. */
  stripeId_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  stripeId_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  stripeId_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  stripeId_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  stripeId_not_ends_with?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  lastNewMessageNotificationTime_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  lastNewMessageNotificationTime_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  lastNewMessageNotificationTime_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  lastNewMessageNotificationTime_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  lastNewMessageNotificationTime_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  lastNewMessageNotificationTime_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  lastNewMessageNotificationTime_gte?: Maybe<DateTime>
}

export interface UserUpdateManyDataInput {
  email?: Maybe<string>

  auth0id?: Maybe<string>

  firstName?: Maybe<string>

  lastName?: Maybe<string>

  legalName?: Maybe<string>

  userType?: Maybe<UserType>

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>
}

export interface UserUpsertWithWhereUniqueWithoutLeaseAsTenantInput {
  where: UserWhereUniqueInput

  update: UserUpdateWithoutLeaseAsTenantDataInput

  create: UserCreateWithoutLeaseAsTenantInput
}

export interface RoomUpdateOneInput {
  create?: Maybe<RoomCreateInput>

  connect?: Maybe<RoomWhereUniqueInput>

  disconnect?: Maybe<boolean>

  delete?: Maybe<boolean>

  update?: Maybe<RoomUpdateDataInput>

  upsert?: Maybe<RoomUpsertNestedInput>
}

export interface RoomUpdateDataInput {
  lastMessageTime?: Maybe<DateTime>

  name?: Maybe<RoomUpdatenameInput>

  creator?: Maybe<UserUpdateOneRequiredWithoutCreatedRoomsInput>

  participants?: Maybe<UserUpdateManyWithoutRoomsInput>

  rental?: Maybe<RentalUpdateOneWithoutRoomsInput>

  messages?: Maybe<MessageUpdateManyWithoutRoomInput>

  booking?: Maybe<BookingUpdateOneWithoutRoomInput>
}

export interface RentalUpdateOneWithoutRoomsInput {
  create?: Maybe<RentalCreateWithoutRoomsInput>

  connect?: Maybe<RentalWhereUniqueInput>

  disconnect?: Maybe<boolean>

  delete?: Maybe<boolean>
}

export interface MessageUpdateManyWithoutRoomInput {
  create?: Maybe<MessageCreateWithoutRoomInput[]>

  connect?: Maybe<MessageWhereUniqueInput[]>

  set?: Maybe<MessageWhereUniqueInput[]>

  disconnect?: Maybe<MessageWhereUniqueInput[]>

  delete?: Maybe<MessageWhereUniqueInput[]>

  update?: Maybe<MessageUpdateWithWhereUniqueWithoutRoomInput[]>

  updateMany?: Maybe<MessageUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<MessageScalarWhereInput[]>

  upsert?: Maybe<MessageUpsertWithWhereUniqueWithoutRoomInput[]>
}

export interface MessageUpdateWithWhereUniqueWithoutRoomInput {
  where: MessageWhereUniqueInput

  data: MessageUpdateWithoutRoomDataInput
}

export interface MessageUpdateWithoutRoomDataInput {
  text?: Maybe<string>

  isRead?: Maybe<boolean>

  type?: Maybe<MessageType>

  url?: Maybe<string>

  emailNotificationSent?: Maybe<boolean>

  sender?: Maybe<UserUpdateOneInput>
}

export interface MessageUpdateManyWithWhereNestedInput {
  where: MessageScalarWhereInput

  data: MessageUpdateManyDataInput
}

export interface MessageScalarWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<MessageScalarWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<MessageScalarWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<MessageScalarWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  text?: Maybe<string>
  /** All values that are not equal to given value. */
  text_not?: Maybe<string>
  /** All values that are contained in given list. */
  text_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  text_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  text_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  text_lte?: Maybe<string>
  /** All values greater than the given value. */
  text_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  text_gte?: Maybe<string>
  /** All values containing the given string. */
  text_contains?: Maybe<string>
  /** All values not containing the given string. */
  text_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  text_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  text_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  text_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  text_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  isRead?: Maybe<boolean>
  /** All values that are not equal to given value. */
  isRead_not?: Maybe<boolean>

  type?: Maybe<MessageType>
  /** All values that are not equal to given value. */
  type_not?: Maybe<MessageType>
  /** All values that are contained in given list. */
  type_in?: Maybe<MessageType[]>
  /** All values that are not contained in given list. */
  type_not_in?: Maybe<MessageType[]>

  url?: Maybe<string>
  /** All values that are not equal to given value. */
  url_not?: Maybe<string>
  /** All values that are contained in given list. */
  url_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  url_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  url_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  url_lte?: Maybe<string>
  /** All values greater than the given value. */
  url_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  url_gte?: Maybe<string>
  /** All values containing the given string. */
  url_contains?: Maybe<string>
  /** All values not containing the given string. */
  url_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  url_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  url_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  url_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  url_not_ends_with?: Maybe<string>

  emailNotificationSent?: Maybe<boolean>
  /** All values that are not equal to given value. */
  emailNotificationSent_not?: Maybe<boolean>
}

export interface MessageUpdateManyDataInput {
  text?: Maybe<string>

  isRead?: Maybe<boolean>

  type?: Maybe<MessageType>

  url?: Maybe<string>

  emailNotificationSent?: Maybe<boolean>
}

export interface MessageUpsertWithWhereUniqueWithoutRoomInput {
  where: MessageWhereUniqueInput

  update: MessageUpdateWithoutRoomDataInput

  create: MessageCreateWithoutRoomInput
}

export interface BookingUpdateOneWithoutRoomInput {
  create?: Maybe<BookingCreateWithoutRoomInput>

  connect?: Maybe<BookingWhereUniqueInput>

  disconnect?: Maybe<boolean>

  delete?: Maybe<boolean>

  update?: Maybe<BookingUpdateWithoutRoomDataInput>

  upsert?: Maybe<BookingUpsertWithoutRoomInput>
}

export interface BookingUpdateWithoutRoomDataInput {
  roommate?: Maybe<boolean>

  introduction?: Maybe<string>

  moveInDate?: Maybe<string>

  leaseTerm?: Maybe<string>

  unit?: Maybe<string>

  occupancyRate?: Maybe<OccupancyRate>

  certnApplicantId?: Maybe<string>

  Seeker?: Maybe<UserUpdateOneRequiredWithoutBookingInput>

  Listing?: Maybe<ListingUpdateOneRequiredWithoutBookingsInput>

  user?: Maybe<UserUpdateManyWithoutBookingsInput>
}

export interface UserUpdateManyWithoutBookingsInput {
  create?: Maybe<UserCreateWithoutBookingsInput[]>

  connect?: Maybe<UserWhereUniqueInput[]>

  set?: Maybe<UserWhereUniqueInput[]>

  disconnect?: Maybe<UserWhereUniqueInput[]>

  delete?: Maybe<UserWhereUniqueInput[]>

  update?: Maybe<UserUpdateWithWhereUniqueWithoutBookingsInput[]>

  updateMany?: Maybe<UserUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<UserScalarWhereInput[]>

  upsert?: Maybe<UserUpsertWithWhereUniqueWithoutBookingsInput[]>
}

export interface UserUpdateWithWhereUniqueWithoutBookingsInput {
  where: UserWhereUniqueInput

  data: UserUpdateWithoutBookingsDataInput
}

export interface UserUpdateWithoutBookingsDataInput {
  email?: Maybe<string>

  auth0id?: Maybe<string>

  firstName?: Maybe<string>

  lastName?: Maybe<string>

  legalName?: Maybe<string>

  userType?: Maybe<UserType>

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingUpdateManyWithoutOwnerInput>

  rooms?: Maybe<RoomUpdateManyWithoutParticipantsInput>

  createdRooms?: Maybe<RoomUpdateManyWithoutCreatorInput>

  booking?: Maybe<BookingUpdateManyWithoutSeekerInput>

  leaseAsLandlord?: Maybe<LeaseUpdateManyWithoutLandlordSignersInput>

  leaseAsTenant?: Maybe<LeaseUpdateManyWithoutTenantSignersInput>
}

export interface LeaseUpdateManyWithoutTenantSignersInput {
  create?: Maybe<LeaseCreateWithoutTenantSignersInput[]>

  connect?: Maybe<LeaseWhereUniqueInput[]>

  set?: Maybe<LeaseWhereUniqueInput[]>

  disconnect?: Maybe<LeaseWhereUniqueInput[]>

  delete?: Maybe<LeaseWhereUniqueInput[]>

  update?: Maybe<LeaseUpdateWithWhereUniqueWithoutTenantSignersInput[]>

  updateMany?: Maybe<LeaseUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<LeaseScalarWhereInput[]>

  upsert?: Maybe<LeaseUpsertWithWhereUniqueWithoutTenantSignersInput[]>
}

export interface LeaseUpdateWithWhereUniqueWithoutTenantSignersInput {
  where: LeaseWhereUniqueInput

  data: LeaseUpdateWithoutTenantSignersDataInput
}

export interface LeaseUpdateWithoutTenantSignersDataInput {
  docusignEnvelopeUri?: Maybe<string>

  docusignEnvelopeId?: Maybe<string>

  status?: Maybe<DocusignEnvelopeStatus>

  subject?: Maybe<string>

  message?: Maybe<string>

  landlordSigners?: Maybe<UserUpdateManyWithoutLeaseAsLandlordInput>

  room?: Maybe<RoomUpdateOneInput>
}

export interface UserUpdateManyWithoutLeaseAsLandlordInput {
  create?: Maybe<UserCreateWithoutLeaseAsLandlordInput[]>

  connect?: Maybe<UserWhereUniqueInput[]>

  set?: Maybe<UserWhereUniqueInput[]>

  disconnect?: Maybe<UserWhereUniqueInput[]>

  delete?: Maybe<UserWhereUniqueInput[]>

  update?: Maybe<UserUpdateWithWhereUniqueWithoutLeaseAsLandlordInput[]>

  updateMany?: Maybe<UserUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<UserScalarWhereInput[]>

  upsert?: Maybe<UserUpsertWithWhereUniqueWithoutLeaseAsLandlordInput[]>
}

export interface UserUpdateWithWhereUniqueWithoutLeaseAsLandlordInput {
  where: UserWhereUniqueInput

  data: UserUpdateWithoutLeaseAsLandlordDataInput
}

export interface UserUpdateWithoutLeaseAsLandlordDataInput {
  email?: Maybe<string>

  auth0id?: Maybe<string>

  firstName?: Maybe<string>

  lastName?: Maybe<string>

  legalName?: Maybe<string>

  userType?: Maybe<UserType>

  avatarUrl?: Maybe<string>

  industry?: Maybe<string>

  bio?: Maybe<string>

  locale?: Maybe<string>

  gender?: Maybe<string>

  phone?: Maybe<string>

  verified?: Maybe<boolean>

  city?: Maybe<string>

  moveIn?: Maybe<string>

  budget?: Maybe<string>

  roommate?: Maybe<boolean>

  stripeId?: Maybe<string>

  lastNewMessageNotificationTime?: Maybe<DateTime>

  listings?: Maybe<ListingUpdateManyWithoutOwnerInput>

  rooms?: Maybe<RoomUpdateManyWithoutParticipantsInput>

  createdRooms?: Maybe<RoomUpdateManyWithoutCreatorInput>

  bookings?: Maybe<BookingUpdateManyWithoutUserInput>

  booking?: Maybe<BookingUpdateManyWithoutSeekerInput>

  leaseAsTenant?: Maybe<LeaseUpdateManyWithoutTenantSignersInput>
}

export interface UserUpsertWithWhereUniqueWithoutLeaseAsLandlordInput {
  where: UserWhereUniqueInput

  update: UserUpdateWithoutLeaseAsLandlordDataInput

  create: UserCreateWithoutLeaseAsLandlordInput
}

export interface LeaseUpdateManyWithWhereNestedInput {
  where: LeaseScalarWhereInput

  data: LeaseUpdateManyDataInput
}

export interface LeaseScalarWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<LeaseScalarWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<LeaseScalarWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<LeaseScalarWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  docusignEnvelopeUri?: Maybe<string>
  /** All values that are not equal to given value. */
  docusignEnvelopeUri_not?: Maybe<string>
  /** All values that are contained in given list. */
  docusignEnvelopeUri_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  docusignEnvelopeUri_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  docusignEnvelopeUri_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  docusignEnvelopeUri_lte?: Maybe<string>
  /** All values greater than the given value. */
  docusignEnvelopeUri_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  docusignEnvelopeUri_gte?: Maybe<string>
  /** All values containing the given string. */
  docusignEnvelopeUri_contains?: Maybe<string>
  /** All values not containing the given string. */
  docusignEnvelopeUri_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  docusignEnvelopeUri_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  docusignEnvelopeUri_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  docusignEnvelopeUri_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  docusignEnvelopeUri_not_ends_with?: Maybe<string>

  docusignEnvelopeId?: Maybe<string>
  /** All values that are not equal to given value. */
  docusignEnvelopeId_not?: Maybe<string>
  /** All values that are contained in given list. */
  docusignEnvelopeId_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  docusignEnvelopeId_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  docusignEnvelopeId_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  docusignEnvelopeId_lte?: Maybe<string>
  /** All values greater than the given value. */
  docusignEnvelopeId_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  docusignEnvelopeId_gte?: Maybe<string>
  /** All values containing the given string. */
  docusignEnvelopeId_contains?: Maybe<string>
  /** All values not containing the given string. */
  docusignEnvelopeId_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  docusignEnvelopeId_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  docusignEnvelopeId_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  docusignEnvelopeId_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  docusignEnvelopeId_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  status?: Maybe<DocusignEnvelopeStatus>
  /** All values that are not equal to given value. */
  status_not?: Maybe<DocusignEnvelopeStatus>
  /** All values that are contained in given list. */
  status_in?: Maybe<DocusignEnvelopeStatus[]>
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<DocusignEnvelopeStatus[]>

  subject?: Maybe<string>
  /** All values that are not equal to given value. */
  subject_not?: Maybe<string>
  /** All values that are contained in given list. */
  subject_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  subject_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  subject_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  subject_lte?: Maybe<string>
  /** All values greater than the given value. */
  subject_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  subject_gte?: Maybe<string>
  /** All values containing the given string. */
  subject_contains?: Maybe<string>
  /** All values not containing the given string. */
  subject_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  subject_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  subject_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  subject_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  subject_not_ends_with?: Maybe<string>

  message?: Maybe<string>
  /** All values that are not equal to given value. */
  message_not?: Maybe<string>
  /** All values that are contained in given list. */
  message_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  message_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  message_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  message_lte?: Maybe<string>
  /** All values greater than the given value. */
  message_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  message_gte?: Maybe<string>
  /** All values containing the given string. */
  message_contains?: Maybe<string>
  /** All values not containing the given string. */
  message_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  message_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  message_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  message_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  message_not_ends_with?: Maybe<string>
}

export interface LeaseUpdateManyDataInput {
  docusignEnvelopeUri?: Maybe<string>

  docusignEnvelopeId?: Maybe<string>

  status?: Maybe<DocusignEnvelopeStatus>

  subject?: Maybe<string>

  message?: Maybe<string>
}

export interface LeaseUpsertWithWhereUniqueWithoutTenantSignersInput {
  where: LeaseWhereUniqueInput

  update: LeaseUpdateWithoutTenantSignersDataInput

  create: LeaseCreateWithoutTenantSignersInput
}

export interface UserUpsertWithWhereUniqueWithoutBookingsInput {
  where: UserWhereUniqueInput

  update: UserUpdateWithoutBookingsDataInput

  create: UserCreateWithoutBookingsInput
}

export interface BookingUpsertWithoutRoomInput {
  update: BookingUpdateWithoutRoomDataInput

  create: BookingCreateWithoutRoomInput
}

export interface RoomUpsertNestedInput {
  update: RoomUpdateDataInput

  create: RoomCreateInput
}

export interface LeaseUpsertWithWhereUniqueWithoutLandlordSignersInput {
  where: LeaseWhereUniqueInput

  update: LeaseUpdateWithoutLandlordSignersDataInput

  create: LeaseCreateWithoutLandlordSignersInput
}

export interface UserUpsertWithoutListingsInput {
  update: UserUpdateWithoutListingsDataInput

  create: UserCreateWithoutListingsInput
}

export interface UtilitiesUpdateOneWithoutListingInput {
  create?: Maybe<UtilitiesCreateWithoutListingInput>

  connect?: Maybe<UtilitiesWhereUniqueInput>

  disconnect?: Maybe<boolean>

  delete?: Maybe<boolean>

  update?: Maybe<UtilitiesUpdateWithoutListingDataInput>

  upsert?: Maybe<UtilitiesUpsertWithoutListingInput>
}

export interface UtilitiesUpdateWithoutListingDataInput {
  electricity?: Maybe<boolean>

  heating?: Maybe<boolean>

  airConditioning?: Maybe<boolean>

  water?: Maybe<boolean>

  garbageRemoval?: Maybe<boolean>

  cableTv?: Maybe<boolean>

  internet?: Maybe<boolean>

  maintenanceFees?: Maybe<boolean>

  gas?: Maybe<boolean>
}

export interface UtilitiesUpsertWithoutListingInput {
  update: UtilitiesUpdateWithoutListingDataInput

  create: UtilitiesCreateWithoutListingInput
}

export interface ListingAmenitiesUpdateOneWithoutListingInput {
  create?: Maybe<ListingAmenitiesCreateWithoutListingInput>

  connect?: Maybe<ListingAmenitiesWhereUniqueInput>

  disconnect?: Maybe<boolean>

  delete?: Maybe<boolean>

  update?: Maybe<ListingAmenitiesUpdateWithoutListingDataInput>

  upsert?: Maybe<ListingAmenitiesUpsertWithoutListingInput>
}

export interface ListingAmenitiesUpdateWithoutListingDataInput {
  onSiteLaundry?: Maybe<boolean>

  yard?: Maybe<boolean>

  concierge?: Maybe<boolean>

  elevator?: Maybe<boolean>

  doorPerson?: Maybe<boolean>

  dryCleaning?: Maybe<boolean>

  gym?: Maybe<boolean>

  swimmingPool?: Maybe<boolean>

  storage?: Maybe<boolean>

  guestSuites?: Maybe<boolean>

  beanfieldWifi?: Maybe<boolean>

  publicWifi?: Maybe<boolean>

  bbqPermitted?: Maybe<boolean>

  visitorsParking?: Maybe<boolean>

  partyRoom?: Maybe<boolean>

  bikeStorage?: Maybe<boolean>

  wheelchairAccess?: Maybe<boolean>

  rooftopDeck?: Maybe<boolean>

  hotTub?: Maybe<boolean>

  sauna?: Maybe<boolean>

  gameRoom?: Maybe<boolean>

  theatre?: Maybe<boolean>

  businessCenter?: Maybe<boolean>

  communalGarden?: Maybe<boolean>

  petSpa?: Maybe<boolean>

  mediaRoom?: Maybe<boolean>

  basketballCourt?: Maybe<boolean>

  yogaRoom?: Maybe<boolean>

  tennisCourt?: Maybe<boolean>

  rooftopTerrace?: Maybe<boolean>

  coworkingSpace?: Maybe<boolean>

  conferenceRoom?: Maybe<boolean>

  patio?: Maybe<boolean>

  lounge?: Maybe<boolean>
}

export interface ListingAmenitiesUpsertWithoutListingInput {
  update: ListingAmenitiesUpdateWithoutListingDataInput

  create: ListingAmenitiesCreateWithoutListingInput
}

export interface TenantRequirementsUpdateOneInput {
  create?: Maybe<TenantRequirementsCreateInput>

  connect?: Maybe<TenantRequirementsWhereUniqueInput>

  disconnect?: Maybe<boolean>

  delete?: Maybe<boolean>

  update?: Maybe<TenantRequirementsUpdateDataInput>

  upsert?: Maybe<TenantRequirementsUpsertNestedInput>
}

export interface TenantRequirementsUpdateDataInput {
  exceptionalCredit?: Maybe<boolean>

  payStub?: Maybe<boolean>

  letterOfEmployment?: Maybe<boolean>

  photoId?: Maybe<boolean>

  pastReferences?: Maybe<boolean>

  noSmoking?: Maybe<boolean>

  extraDocumentation?: Maybe<boolean>
}

export interface TenantRequirementsUpsertNestedInput {
  update: TenantRequirementsUpdateDataInput

  create: TenantRequirementsCreateInput
}

export interface PictureUpdateManyInput {
  create?: Maybe<PictureCreateInput[]>

  connect?: Maybe<PictureWhereUniqueInput[]>

  set?: Maybe<PictureWhereUniqueInput[]>

  disconnect?: Maybe<PictureWhereUniqueInput[]>

  delete?: Maybe<PictureWhereUniqueInput[]>

  update?: Maybe<PictureUpdateWithWhereUniqueNestedInput[]>

  updateMany?: Maybe<PictureUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<PictureScalarWhereInput[]>

  upsert?: Maybe<PictureUpsertWithWhereUniqueNestedInput[]>
}

export interface PictureUpdateWithWhereUniqueNestedInput {
  where: PictureWhereUniqueInput

  data: PictureUpdateDataInput
}

export interface PictureUpdateManyWithWhereNestedInput {
  where: PictureScalarWhereInput

  data: PictureUpdateManyDataInput
}

export interface PictureScalarWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<PictureScalarWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<PictureScalarWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<PictureScalarWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  filename?: Maybe<string>
  /** All values that are not equal to given value. */
  filename_not?: Maybe<string>
  /** All values that are contained in given list. */
  filename_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  filename_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  filename_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  filename_lte?: Maybe<string>
  /** All values greater than the given value. */
  filename_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  filename_gte?: Maybe<string>
  /** All values containing the given string. */
  filename_contains?: Maybe<string>
  /** All values not containing the given string. */
  filename_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  filename_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  filename_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  filename_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  filename_not_ends_with?: Maybe<string>

  url?: Maybe<string>
  /** All values that are not equal to given value. */
  url_not?: Maybe<string>
  /** All values that are contained in given list. */
  url_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  url_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  url_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  url_lte?: Maybe<string>
  /** All values greater than the given value. */
  url_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  url_gte?: Maybe<string>
  /** All values containing the given string. */
  url_contains?: Maybe<string>
  /** All values not containing the given string. */
  url_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  url_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  url_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  url_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  url_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>
}

export interface PictureUpdateManyDataInput {
  filename?: Maybe<string>

  url?: Maybe<string>
}

export interface PictureUpsertWithWhereUniqueNestedInput {
  where: PictureWhereUniqueInput

  update: PictureUpdateDataInput

  create: PictureCreateInput
}

export interface UnitUpdateManyWithoutListingsInput {
  create?: Maybe<UnitCreateWithoutListingsInput[]>

  connect?: Maybe<UnitWhereUniqueInput[]>

  set?: Maybe<UnitWhereUniqueInput[]>

  disconnect?: Maybe<UnitWhereUniqueInput[]>

  delete?: Maybe<UnitWhereUniqueInput[]>

  update?: Maybe<UnitUpdateWithWhereUniqueWithoutListingsInput[]>

  updateMany?: Maybe<UnitUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<UnitScalarWhereInput[]>

  upsert?: Maybe<UnitUpsertWithWhereUniqueWithoutListingsInput[]>
}

export interface UnitUpdateWithWhereUniqueWithoutListingsInput {
  where: UnitWhereUniqueInput

  data: UnitUpdateWithoutListingsDataInput
}

export interface UnitUpdateWithoutListingsDataInput {
  title?: Maybe<string>

  number?: Maybe<string>

  den?: Maybe<number>

  available?: Maybe<boolean>

  startDateAvailability?: Maybe<DateTime>

  unitSize?: Maybe<number>

  bedrooms?: Maybe<Bedrooms>

  monthlyPrice?: Maybe<number>

  bathrooms?: Maybe<number>

  floorplan?: Maybe<string>

  furnished?: Maybe<boolean>

  deposit?: Maybe<number>

  leaseTerm?: Maybe<number>

  parking?: Maybe<Parking>

  amenities?: Maybe<UnitAmenitiesUpdateOneWithoutUnitInput>

  pictures?: Maybe<PictureUpdateManyInput>
}

export interface UnitAmenitiesUpdateOneWithoutUnitInput {
  create?: Maybe<UnitAmenitiesCreateWithoutUnitInput>

  connect?: Maybe<UnitAmenitiesWhereUniqueInput>

  disconnect?: Maybe<boolean>

  delete?: Maybe<boolean>

  update?: Maybe<UnitAmenitiesUpdateWithoutUnitDataInput>

  upsert?: Maybe<UnitAmenitiesUpsertWithoutUnitInput>
}

export interface UnitAmenitiesUpdateWithoutUnitDataInput {
  ac?: Maybe<boolean>

  centralHeat?: Maybe<boolean>

  electricalHeating?: Maybe<boolean>

  radiatorHeating?: Maybe<boolean>

  fireplace?: Maybe<boolean>

  carpet?: Maybe<boolean>

  ceilingFan?: Maybe<boolean>

  hardwoodFloor?: Maybe<boolean>

  walkInCloset?: Maybe<boolean>

  highCeilings?: Maybe<boolean>

  bathroomFan?: Maybe<boolean>

  balcony?: Maybe<boolean>
}

export interface UnitAmenitiesUpsertWithoutUnitInput {
  update: UnitAmenitiesUpdateWithoutUnitDataInput

  create: UnitAmenitiesCreateWithoutUnitInput
}

export interface UnitAppliancesUpdateWithoutUnitDataInput {
  fridge?: Maybe<boolean>

  stove?: Maybe<boolean>

  rangehood?: Maybe<boolean>

  dishwasher?: Maybe<boolean>

  microwave?: Maybe<boolean>

  inunitwasher?: Maybe<boolean>

  inunitdryer?: Maybe<boolean>

  wineFridge?: Maybe<boolean>

  dehumidifier?: Maybe<boolean>

  firePlaceElectric?: Maybe<boolean>

  firePlaceGas?: Maybe<boolean>

  jacuzzi?: Maybe<boolean>
}

export interface UnitAppliancesUpsertWithoutUnitInput {
  update: UnitAppliancesUpdateWithoutUnitDataInput

  create: UnitAppliancesCreateWithoutUnitInput
}

export interface UnitUpdateManyWithWhereNestedInput {
  where: UnitScalarWhereInput

  data: UnitUpdateManyDataInput
}

export interface UnitScalarWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<UnitScalarWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<UnitScalarWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<UnitScalarWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  title?: Maybe<string>
  /** All values that are not equal to given value. */
  title_not?: Maybe<string>
  /** All values that are contained in given list. */
  title_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  title_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  title_lte?: Maybe<string>
  /** All values greater than the given value. */
  title_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  title_gte?: Maybe<string>
  /** All values containing the given string. */
  title_contains?: Maybe<string>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  title_not_ends_with?: Maybe<string>

  number?: Maybe<string>
  /** All values that are not equal to given value. */
  number_not?: Maybe<string>
  /** All values that are contained in given list. */
  number_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  number_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  number_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  number_lte?: Maybe<string>
  /** All values greater than the given value. */
  number_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  number_gte?: Maybe<string>
  /** All values containing the given string. */
  number_contains?: Maybe<string>
  /** All values not containing the given string. */
  number_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  number_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  number_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  number_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  number_not_ends_with?: Maybe<string>

  den?: Maybe<boolean>
  /** All values that are not equal to given value. */
  den_not?: Maybe<boolean>

  available?: Maybe<boolean>
  /** All values that are not equal to given value. */
  available_not?: Maybe<boolean>

  startDateAvailability?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  startDateAvailability_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  startDateAvailability_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  startDateAvailability_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  startDateAvailability_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  startDateAvailability_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  startDateAvailability_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  startDateAvailability_gte?: Maybe<DateTime>

  unitSize?: Maybe<number>
  /** All values that are not equal to given value. */
  unitSize_not?: Maybe<number>
  /** All values that are contained in given list. */
  unitSize_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  unitSize_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  unitSize_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  unitSize_lte?: Maybe<number>
  /** All values greater than the given value. */
  unitSize_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  unitSize_gte?: Maybe<number>

  bedrooms?: Maybe<Bedrooms>
  /** All values that are not equal to given value. */
  bedrooms_not?: Maybe<Bedrooms>
  /** All values that are contained in given list. */
  bedrooms_in?: Maybe<Bedrooms[]>
  /** All values that are not contained in given list. */
  bedrooms_not_in?: Maybe<Bedrooms[]>

  monthlyPrice?: Maybe<number>
  /** All values that are not equal to given value. */
  monthlyPrice_not?: Maybe<number>
  /** All values that are contained in given list. */
  monthlyPrice_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  monthlyPrice_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  monthlyPrice_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  monthlyPrice_lte?: Maybe<number>
  /** All values greater than the given value. */
  monthlyPrice_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  monthlyPrice_gte?: Maybe<number>

  bathrooms?: Maybe<number>
  /** All values that are not equal to given value. */
  bathrooms_not?: Maybe<number>
  /** All values that are contained in given list. */
  bathrooms_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  bathrooms_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  bathrooms_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  bathrooms_lte?: Maybe<number>
  /** All values greater than the given value. */
  bathrooms_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  bathrooms_gte?: Maybe<number>

  floorplan?: Maybe<string>
  /** All values that are not equal to given value. */
  floorplan_not?: Maybe<string>
  /** All values that are contained in given list. */
  floorplan_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  floorplan_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  floorplan_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  floorplan_lte?: Maybe<string>
  /** All values greater than the given value. */
  floorplan_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  floorplan_gte?: Maybe<string>
  /** All values containing the given string. */
  floorplan_contains?: Maybe<string>
  /** All values not containing the given string. */
  floorplan_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  floorplan_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  floorplan_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  floorplan_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  floorplan_not_ends_with?: Maybe<string>

  furnished?: Maybe<boolean>
  /** All values that are not equal to given value. */
  furnished_not?: Maybe<boolean>

  deposit?: Maybe<number>
  /** All values that are not equal to given value. */
  deposit_not?: Maybe<number>
  /** All values that are contained in given list. */
  deposit_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  deposit_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  deposit_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  deposit_lte?: Maybe<number>
  /** All values greater than the given value. */
  deposit_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  deposit_gte?: Maybe<number>

  leaseTerm?: Maybe<number>
  /** All values that are not equal to given value. */
  leaseTerm_not?: Maybe<number>
  /** All values that are contained in given list. */
  leaseTerm_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  leaseTerm_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  leaseTerm_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  leaseTerm_lte?: Maybe<number>
  /** All values greater than the given value. */
  leaseTerm_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  leaseTerm_gte?: Maybe<number>

  parking?: Maybe<Parking>
  /** All values that are not equal to given value. */
  parking_not?: Maybe<Parking>
  /** All values that are contained in given list. */
  parking_in?: Maybe<Parking[]>
  /** All values that are not contained in given list. */
  parking_not_in?: Maybe<Parking[]>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>
}

export interface UnitUpdateManyDataInput {
  title?: Maybe<string>

  number?: Maybe<string>

  den?: Maybe<number>

  available?: Maybe<boolean>

  startDateAvailability?: Maybe<DateTime>

  unitSize?: Maybe<number>

  bedrooms?: Maybe<Bedrooms>

  monthlyPrice?: Maybe<number>

  bathrooms?: Maybe<number>

  floorplan?: Maybe<string>

  furnished?: Maybe<boolean>

  deposit?: Maybe<number>

  leaseTerm?: Maybe<number>

  parking?: Maybe<Parking>
}

export interface UnitUpsertWithWhereUniqueWithoutListingsInput {
  where: UnitWhereUniqueInput

  update: UnitUpdateWithoutListingsDataInput

  create: UnitCreateWithoutListingsInput
}

export interface ListingUpsertWithoutBookingsInput {
  update: ListingUpdateWithoutBookingsDataInput

  create: ListingCreateWithoutBookingsInput
}

export interface RoomUpdateOneWithoutBookingInput {
  create?: Maybe<RoomCreateWithoutBookingInput>

  connect?: Maybe<RoomWhereUniqueInput>

  disconnect?: Maybe<boolean>

  delete?: Maybe<boolean>

  update?: Maybe<RoomUpdateWithoutBookingDataInput>

  upsert?: Maybe<RoomUpsertWithoutBookingInput>
}

export interface RoomUpdateWithoutBookingDataInput {
  lastMessageTime?: Maybe<DateTime>

  name?: Maybe<RoomUpdatenameInput>

  creator?: Maybe<UserUpdateOneRequiredWithoutCreatedRoomsInput>

  participants?: Maybe<UserUpdateManyWithoutRoomsInput>

  rental?: Maybe<RentalUpdateOneWithoutRoomsInput>

  messages?: Maybe<MessageUpdateManyWithoutRoomInput>
}

export interface RoomUpsertWithoutBookingInput {
  update: RoomUpdateWithoutBookingDataInput

  create: RoomCreateWithoutBookingInput
}

export interface BookingUpdateManyWithWhereNestedInput {
  where: BookingScalarWhereInput

  data: BookingUpdateManyDataInput
}

export interface BookingScalarWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<BookingScalarWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<BookingScalarWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<BookingScalarWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  roommate?: Maybe<boolean>
  /** All values that are not equal to given value. */
  roommate_not?: Maybe<boolean>

  introduction?: Maybe<string>
  /** All values that are not equal to given value. */
  introduction_not?: Maybe<string>
  /** All values that are contained in given list. */
  introduction_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  introduction_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  introduction_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  introduction_lte?: Maybe<string>
  /** All values greater than the given value. */
  introduction_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  introduction_gte?: Maybe<string>
  /** All values containing the given string. */
  introduction_contains?: Maybe<string>
  /** All values not containing the given string. */
  introduction_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  introduction_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  introduction_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  introduction_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  introduction_not_ends_with?: Maybe<string>

  moveInDate?: Maybe<string>
  /** All values that are not equal to given value. */
  moveInDate_not?: Maybe<string>
  /** All values that are contained in given list. */
  moveInDate_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  moveInDate_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  moveInDate_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  moveInDate_lte?: Maybe<string>
  /** All values greater than the given value. */
  moveInDate_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  moveInDate_gte?: Maybe<string>
  /** All values containing the given string. */
  moveInDate_contains?: Maybe<string>
  /** All values not containing the given string. */
  moveInDate_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  moveInDate_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  moveInDate_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  moveInDate_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  moveInDate_not_ends_with?: Maybe<string>

  leaseTerm?: Maybe<string>
  /** All values that are not equal to given value. */
  leaseTerm_not?: Maybe<string>
  /** All values that are contained in given list. */
  leaseTerm_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  leaseTerm_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  leaseTerm_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  leaseTerm_lte?: Maybe<string>
  /** All values greater than the given value. */
  leaseTerm_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  leaseTerm_gte?: Maybe<string>
  /** All values containing the given string. */
  leaseTerm_contains?: Maybe<string>
  /** All values not containing the given string. */
  leaseTerm_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  leaseTerm_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  leaseTerm_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  leaseTerm_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  leaseTerm_not_ends_with?: Maybe<string>

  unit?: Maybe<string>
  /** All values that are not equal to given value. */
  unit_not?: Maybe<string>
  /** All values that are contained in given list. */
  unit_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  unit_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  unit_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  unit_lte?: Maybe<string>
  /** All values greater than the given value. */
  unit_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  unit_gte?: Maybe<string>
  /** All values containing the given string. */
  unit_contains?: Maybe<string>
  /** All values not containing the given string. */
  unit_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  unit_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  unit_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  unit_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  unit_not_ends_with?: Maybe<string>

  occupancyRate?: Maybe<OccupancyRate>
  /** All values that are not equal to given value. */
  occupancyRate_not?: Maybe<OccupancyRate>
  /** All values that are contained in given list. */
  occupancyRate_in?: Maybe<OccupancyRate[]>
  /** All values that are not contained in given list. */
  occupancyRate_not_in?: Maybe<OccupancyRate[]>

  certnApplicantId?: Maybe<string>
  /** All values that are not equal to given value. */
  certnApplicantId_not?: Maybe<string>
  /** All values that are contained in given list. */
  certnApplicantId_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  certnApplicantId_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  certnApplicantId_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  certnApplicantId_lte?: Maybe<string>
  /** All values greater than the given value. */
  certnApplicantId_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  certnApplicantId_gte?: Maybe<string>
  /** All values containing the given string. */
  certnApplicantId_contains?: Maybe<string>
  /** All values not containing the given string. */
  certnApplicantId_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  certnApplicantId_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  certnApplicantId_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  certnApplicantId_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  certnApplicantId_not_ends_with?: Maybe<string>
}

export interface BookingUpdateManyDataInput {
  roommate?: Maybe<boolean>

  introduction?: Maybe<string>

  moveInDate?: Maybe<string>

  leaseTerm?: Maybe<string>

  unit?: Maybe<string>

  occupancyRate?: Maybe<OccupancyRate>

  certnApplicantId?: Maybe<string>
}

export interface BookingUpsertWithWhereUniqueWithoutSeekerInput {
  where: BookingWhereUniqueInput

  update: BookingUpdateWithoutSeekerDataInput

  create: BookingCreateWithoutSeekerInput
}

export interface UserUpsertWithWhereUniqueWithoutRoomsInput {
  where: UserWhereUniqueInput

  update: UserUpdateWithoutRoomsDataInput

  create: UserCreateWithoutRoomsInput
}

export interface RoomUpdateManyWithWhereNestedInput {
  where: RoomScalarWhereInput

  data: RoomUpdateManyDataInput
}

export interface RoomScalarWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<RoomScalarWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<RoomScalarWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<RoomScalarWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  lastMessageTime?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  lastMessageTime_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  lastMessageTime_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  lastMessageTime_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  lastMessageTime_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  lastMessageTime_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  lastMessageTime_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  lastMessageTime_gte?: Maybe<DateTime>
}

export interface RoomUpdateManyDataInput {
  lastMessageTime?: Maybe<DateTime>

  name?: Maybe<RoomUpdatenameInput>
}

export interface RoomUpsertWithWhereUniqueWithoutCreatorInput {
  where: RoomWhereUniqueInput

  update: RoomUpdateWithoutCreatorDataInput

  create: RoomCreateWithoutCreatorInput
}

export interface UserUpsertWithoutBookingInput {
  update: UserUpdateWithoutBookingDataInput

  create: UserCreateWithoutBookingInput
}

export interface BookingUpsertWithWhereUniqueWithoutUserInput {
  where: BookingWhereUniqueInput

  update: BookingUpdateWithoutUserDataInput

  create: BookingCreateWithoutUserInput
}

export interface UserUpsertWithoutCreatedRoomsInput {
  update: UserUpdateWithoutCreatedRoomsDataInput

  create: UserCreateWithoutCreatedRoomsInput
}

export interface RoomUpsertWithWhereUniqueWithoutParticipantsInput {
  where: RoomWhereUniqueInput

  update: RoomUpdateWithoutParticipantsDataInput

  create: RoomCreateWithoutParticipantsInput
}

export interface UserUpsertNestedInput {
  update: UserUpdateDataInput

  create: UserCreateInput
}

export interface LocationUpsertWithoutListingsInput {
  update: LocationUpdateWithoutListingsDataInput

  create: LocationCreateWithoutListingsInput
}

export interface BookingUpdateManyWithoutListingInput {
  create?: Maybe<BookingCreateWithoutListingInput[]>

  connect?: Maybe<BookingWhereUniqueInput[]>

  set?: Maybe<BookingWhereUniqueInput[]>

  disconnect?: Maybe<BookingWhereUniqueInput[]>

  delete?: Maybe<BookingWhereUniqueInput[]>

  update?: Maybe<BookingUpdateWithWhereUniqueWithoutListingInput[]>

  updateMany?: Maybe<BookingUpdateManyWithWhereNestedInput[]>

  deleteMany?: Maybe<BookingScalarWhereInput[]>

  upsert?: Maybe<BookingUpsertWithWhereUniqueWithoutListingInput[]>
}

export interface BookingUpdateWithWhereUniqueWithoutListingInput {
  where: BookingWhereUniqueInput

  data: BookingUpdateWithoutListingDataInput
}

export interface BookingUpdateWithoutListingDataInput {
  roommate?: Maybe<boolean>

  introduction?: Maybe<string>

  moveInDate?: Maybe<string>

  leaseTerm?: Maybe<string>

  unit?: Maybe<string>

  occupancyRate?: Maybe<OccupancyRate>

  certnApplicantId?: Maybe<string>

  Seeker?: Maybe<UserUpdateOneRequiredWithoutBookingInput>

  user?: Maybe<UserUpdateManyWithoutBookingsInput>

  room?: Maybe<RoomUpdateOneWithoutBookingInput>
}

export interface BookingUpsertWithWhereUniqueWithoutListingInput {
  where: BookingWhereUniqueInput

  update: BookingUpdateWithoutListingDataInput

  create: BookingCreateWithoutListingInput
}

export interface ListingUpdateManyWithWhereNestedInput {
  where: ListingScalarWhereInput

  data: ListingUpdateManyDataInput
}

export interface ListingScalarWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<ListingScalarWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<ListingScalarWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<ListingScalarWhereInput[]>

  id?: Maybe<string>
  /** All values that are not equal to given value. */
  id_not?: Maybe<string>
  /** All values that are contained in given list. */
  id_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  id_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  id_lte?: Maybe<string>
  /** All values greater than the given value. */
  id_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<string>
  /** All values containing the given string. */
  id_contains?: Maybe<string>
  /** All values not containing the given string. */
  id_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  id_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  id_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<string>

  createdAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  createdAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<DateTime>

  updatedAt?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<DateTime>

  title?: Maybe<string>
  /** All values that are not equal to given value. */
  title_not?: Maybe<string>
  /** All values that are contained in given list. */
  title_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  title_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  title_lte?: Maybe<string>
  /** All values greater than the given value. */
  title_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  title_gte?: Maybe<string>
  /** All values containing the given string. */
  title_contains?: Maybe<string>
  /** All values not containing the given string. */
  title_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  title_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  title_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  title_not_ends_with?: Maybe<string>

  description?: Maybe<string>
  /** All values that are not equal to given value. */
  description_not?: Maybe<string>
  /** All values that are contained in given list. */
  description_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  description_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  description_lte?: Maybe<string>
  /** All values greater than the given value. */
  description_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  description_gte?: Maybe<string>
  /** All values containing the given string. */
  description_contains?: Maybe<string>
  /** All values not containing the given string. */
  description_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  description_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  description_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  description_not_ends_with?: Maybe<string>

  propertyType?: Maybe<PropertyType>
  /** All values that are not equal to given value. */
  propertyType_not?: Maybe<PropertyType>
  /** All values that are contained in given list. */
  propertyType_in?: Maybe<PropertyType[]>
  /** All values that are not contained in given list. */
  propertyType_not_in?: Maybe<PropertyType[]>

  pets?: Maybe<Pets>
  /** All values that are not equal to given value. */
  pets_not?: Maybe<Pets>
  /** All values that are contained in given list. */
  pets_in?: Maybe<Pets[]>
  /** All values that are not contained in given list. */
  pets_not_in?: Maybe<Pets[]>

  buildingConstructionYear?: Maybe<string>
  /** All values that are not equal to given value. */
  buildingConstructionYear_not?: Maybe<string>
  /** All values that are contained in given list. */
  buildingConstructionYear_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  buildingConstructionYear_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  buildingConstructionYear_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  buildingConstructionYear_lte?: Maybe<string>
  /** All values greater than the given value. */
  buildingConstructionYear_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  buildingConstructionYear_gte?: Maybe<string>
  /** All values containing the given string. */
  buildingConstructionYear_contains?: Maybe<string>
  /** All values not containing the given string. */
  buildingConstructionYear_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  buildingConstructionYear_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  buildingConstructionYear_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  buildingConstructionYear_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  buildingConstructionYear_not_ends_with?: Maybe<string>

  totalUnitsInBuilding?: Maybe<number>
  /** All values that are not equal to given value. */
  totalUnitsInBuilding_not?: Maybe<number>
  /** All values that are contained in given list. */
  totalUnitsInBuilding_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  totalUnitsInBuilding_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  totalUnitsInBuilding_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  totalUnitsInBuilding_lte?: Maybe<number>
  /** All values greater than the given value. */
  totalUnitsInBuilding_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  totalUnitsInBuilding_gte?: Maybe<number>

  storiesInBuilding?: Maybe<number>
  /** All values that are not equal to given value. */
  storiesInBuilding_not?: Maybe<number>
  /** All values that are contained in given list. */
  storiesInBuilding_in?: Maybe<number[]>
  /** All values that are not contained in given list. */
  storiesInBuilding_not_in?: Maybe<number[]>
  /** All values less than the given value. */
  storiesInBuilding_lt?: Maybe<number>
  /** All values less than or equal the given value. */
  storiesInBuilding_lte?: Maybe<number>
  /** All values greater than the given value. */
  storiesInBuilding_gt?: Maybe<number>
  /** All values greater than or equal the given value. */
  storiesInBuilding_gte?: Maybe<number>

  developmentCompany?: Maybe<string>
  /** All values that are not equal to given value. */
  developmentCompany_not?: Maybe<string>
  /** All values that are contained in given list. */
  developmentCompany_in?: Maybe<string[]>
  /** All values that are not contained in given list. */
  developmentCompany_not_in?: Maybe<string[]>
  /** All values less than the given value. */
  developmentCompany_lt?: Maybe<string>
  /** All values less than or equal the given value. */
  developmentCompany_lte?: Maybe<string>
  /** All values greater than the given value. */
  developmentCompany_gt?: Maybe<string>
  /** All values greater than or equal the given value. */
  developmentCompany_gte?: Maybe<string>
  /** All values containing the given string. */
  developmentCompany_contains?: Maybe<string>
  /** All values not containing the given string. */
  developmentCompany_not_contains?: Maybe<string>
  /** All values starting with the given string. */
  developmentCompany_starts_with?: Maybe<string>
  /** All values not starting with the given string. */
  developmentCompany_not_starts_with?: Maybe<string>
  /** All values ending with the given string. */
  developmentCompany_ends_with?: Maybe<string>
  /** All values not ending with the given string. */
  developmentCompany_not_ends_with?: Maybe<string>

  premiumStart?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  premiumStart_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  premiumStart_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  premiumStart_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  premiumStart_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  premiumStart_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  premiumStart_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  premiumStart_gte?: Maybe<DateTime>

  premiumEnd?: Maybe<DateTime>
  /** All values that are not equal to given value. */
  premiumEnd_not?: Maybe<DateTime>
  /** All values that are contained in given list. */
  premiumEnd_in?: Maybe<DateTime[]>
  /** All values that are not contained in given list. */
  premiumEnd_not_in?: Maybe<DateTime[]>
  /** All values less than the given value. */
  premiumEnd_lt?: Maybe<DateTime>
  /** All values less than or equal the given value. */
  premiumEnd_lte?: Maybe<DateTime>
  /** All values greater than the given value. */
  premiumEnd_gt?: Maybe<DateTime>
  /** All values greater than or equal the given value. */
  premiumEnd_gte?: Maybe<DateTime>

  isHidden?: Maybe<boolean>
  /** All values that are not equal to given value. */
  isHidden_not?: Maybe<boolean>
}

export interface ListingUpdateManyDataInput {
  title?: Maybe<string>

  description?: Maybe<string>

  propertyType?: Maybe<PropertyType>

  pets?: Maybe<Pets>

  buildingConstructionYear?: Maybe<string>

  totalUnitsInBuilding?: Maybe<number>

  storiesInBuilding?: Maybe<number>

  developmentCompany?: Maybe<string>

  premiumStart?: Maybe<DateTime>

  premiumEnd?: Maybe<DateTime>

  isHidden?: Maybe<boolean>
}

export interface ListingUpsertWithWhereUniqueWithoutOwnerInput {
  where: ListingWhereUniqueInput

  update: ListingUpdateWithoutOwnerDataInput

  create: ListingCreateWithoutOwnerInput
}

export interface BookingCreateInputWithoutSeeker {
  roommate?: Maybe<boolean>

  introduction: string

  occupancyRate?: Maybe<OccupancyRate>

  Listing: ListingCreateOneWithoutBookingsInput

  moveInDate?: Maybe<string>

  leaseTerm?: Maybe<string>

  unit?: Maybe<string>
}

export interface LeaseCreateInput {
  id?: Maybe<string>

  docusignEnvelopeUri?: Maybe<string>

  docusignEnvelopeId?: Maybe<string>

  status?: Maybe<DocusignEnvelopeStatus>

  subject?: Maybe<string>

  message?: Maybe<string>

  landlordSigners?: Maybe<UserCreateManyWithoutLeaseAsLandlordInput>

  tenantSigners?: Maybe<UserCreateManyWithoutLeaseAsTenantInput>

  room?: Maybe<RoomCreateOneInput>
}

export interface ListingUpdateInput {
  title?: Maybe<string>

  signedLeaseDate?: Maybe<string>

  description?: Maybe<string>

  propertyType?: Maybe<PropertyType>

  pets?: Maybe<Pets>

  buildingConstructionYear?: Maybe<string>

  totalUnitsInBuilding?: Maybe<number>

  storiesInBuilding?: Maybe<number>

  developmentCompany?: Maybe<string>

  premiumStart?: Maybe<DateTime>

  premiumEnd?: Maybe<DateTime>

  isHidden?: Maybe<boolean>

  owner?: Maybe<UserUpdateOneRequiredWithoutListingsInput>

  location?: Maybe<LocationUpdateOneWithoutListingsInput>

  utilities?: Maybe<UtilitiesUpdateOneWithoutListingInput>

  amenities?: Maybe<ListingAmenitiesUpdateOneWithoutListingInput>

  tenantRequirements?: Maybe<TenantRequirementsUpdateOneInput>

  pictures?: Maybe<PictureUpdateManyInput>

  units?: Maybe<UnitUpdateManyWithoutListingsInput>

  bookings?: Maybe<BookingUpdateManyWithoutListingInput>
}

export interface LeaseUpdateInput {
  docusignEnvelopeUri?: Maybe<string>

  docusignEnvelopeId?: Maybe<string>

  status?: Maybe<DocusignEnvelopeStatus>

  subject?: Maybe<string>

  message?: Maybe<string>

  landlordSigners?: Maybe<UserUpdateManyWithoutLeaseAsLandlordInput>

  tenantSigners?: Maybe<UserUpdateManyWithoutLeaseAsTenantInput>

  room?: Maybe<RoomUpdateOneInput>
}

export interface RoomSubscriptionWhereInput {
  /** Logical AND on all given filters. */
  AND?: Maybe<RoomSubscriptionWhereInput[]>
  /** Logical OR on all given filters. */
  OR?: Maybe<RoomSubscriptionWhereInput[]>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<RoomSubscriptionWhereInput[]>
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<MutationType[]>
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<string>
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<string[]>
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<string[]>

  node?: Maybe<RoomWhereInput>
}

export enum DocusignEnvelopeStatus {
  Completed = "COMPLETED",
  Created = "CREATED",
  Declined = "DECLINED",
  Delivered = "DELIVERED",
  Sent = "SENT",
  Signed = "SIGNED",
  Voided = "VOIDED"
}

export enum UserType {
  Unverified = "UNVERIFIED",
  Landlord = "LANDLORD",
  Renter = "RENTER",
  Admin = "ADMIN",
  Guest = "GUEST",
  SubAdmin = "SUB_ADMIN"
}

export enum PropertyType {
  Condo = "CONDO",
  Apartment = "APARTMENT",
  HouseOrTownhouse = "HOUSE_OR_TOWNHOUSE",
  Room = "ROOM",
  Basement = "BASEMENT",
  Other = "OTHER",
  Townhouse = "TOWNHOUSE"
}

export enum Pets {
  Cats = "CATS",
  Dogs = "DOGS",
  CatsAndDogs = "CATS_AND_DOGS",
  None = "NONE"
}

export interface Pet {
  id: string
  cat: boolean
  dog: boolean
  other: boolean
}

export enum Bedrooms {
  Studio = "STUDIO",
  OneBedroom = "ONE_BEDROOM",
  TwoBedrooms = "TWO_BEDROOMS",
  ThreeBedrooms = "THREE_BEDROOMS",
  RoomSharedHome = "ROOM_SHARED_HOME",
  FourOrMoreBedrooms = "FOUR_OR_MORE_BEDROOMS"
}

export enum Parking {
  Assigned = "ASSIGNED",
  Shared = "SHARED",
  Garage = "GARAGE",
  Other = "OTHER",
  None = "NONE"
}

export enum OccupancyRate {
  One = "ONE",
  Two = "TWO",
  Three = "THREE",
  FourPlus = "FOUR_PLUS"
}

export enum MessageType {
  Text = "TEXT",
  File = "FILE"
}

export enum UserOrderByInput {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  EmailAsc = "email_ASC",
  EmailDesc = "email_DESC",
  Auth0idAsc = "auth0id_ASC",
  Auth0idDesc = "auth0id_DESC",
  FirstNameAsc = "firstName_ASC",
  FirstNameDesc = "firstName_DESC",
  LastNameAsc = "lastName_ASC",
  LastNameDesc = "lastName_DESC",
  LegalNameAsc = "legalName_ASC",
  LegalNameDesc = "legalName_DESC",
  UserTypeAsc = "userType_ASC",
  UserTypeDesc = "userType_DESC",
  AvatarUrlAsc = "avatarUrl_ASC",
  AvatarUrlDesc = "avatarUrl_DESC",
  IndustryAsc = "industry_ASC",
  IndustryDesc = "industry_DESC",
  BioAsc = "bio_ASC",
  BioDesc = "bio_DESC",
  LocaleAsc = "locale_ASC",
  LocaleDesc = "locale_DESC",
  GenderAsc = "gender_ASC",
  GenderDesc = "gender_DESC",
  PhoneAsc = "phone_ASC",
  PhoneDesc = "phone_DESC",
  VerifiedAsc = "verified_ASC",
  VerifiedDesc = "verified_DESC",
  CityAsc = "city_ASC",
  CityDesc = "city_DESC",
  MoveInAsc = "moveIn_ASC",
  MoveInDesc = "moveIn_DESC",
  BudgetAsc = "budget_ASC",
  BudgetDesc = "budget_DESC",
  RoommateAsc = "roommate_ASC",
  RoommateDesc = "roommate_DESC",
  StripeIdAsc = "stripeId_ASC",
  StripeIdDesc = "stripeId_DESC",
  LastNewMessageNotificationTimeAsc = "lastNewMessageNotificationTime_ASC",
  LastNewMessageNotificationTimeDesc = "lastNewMessageNotificationTime_DESC"
}

export enum ListingOrderByInput {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  PropertyTypeAsc = "propertyType_ASC",
  PropertyTypeDesc = "propertyType_DESC",
  PetsAsc = "pets_ASC",
  PetsDesc = "pets_DESC",
  BuildingConstructionYearAsc = "buildingConstructionYear_ASC",
  BuildingConstructionYearDesc = "buildingConstructionYear_DESC",
  TotalUnitsInBuildingAsc = "totalUnitsInBuilding_ASC",
  TotalUnitsInBuildingDesc = "totalUnitsInBuilding_DESC",
  StoriesInBuildingAsc = "storiesInBuilding_ASC",
  StoriesInBuildingDesc = "storiesInBuilding_DESC",
  DevelopmentCompanyAsc = "developmentCompany_ASC",
  DevelopmentCompanyDesc = "developmentCompany_DESC",
  PremiumStartAsc = "premiumStart_ASC",
  PremiumStartDesc = "premiumStart_DESC",
  PremiumEndAsc = "premiumEnd_ASC",
  PremiumEndDesc = "premiumEnd_DESC",
  IsHiddenAsc = "isHidden_ASC",
  IsHiddenDesc = "isHidden_DESC"
}

export enum LocationOrderByInput {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  LatAsc = "lat_ASC",
  LatDesc = "lat_DESC",
  LngAsc = "lng_ASC",
  LngDesc = "lng_DESC",
  AddressAsc = "address_ASC",
  AddressDesc = "address_DESC",
  AptAsc = "apt_ASC",
  AptDesc = "apt_DESC",
  DirectionsAsc = "directions_ASC",
  DirectionsDesc = "directions_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC"
}

export enum NeighbourhoodOrderByInput {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  FeaturedAsc = "featured_ASC",
  FeaturedDesc = "featured_DESC",
  PopularityAsc = "popularity_ASC",
  PopularityDesc = "popularity_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC"
}

export enum PictureOrderByInput {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  FilenameAsc = "filename_ASC",
  FilenameDesc = "filename_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC"
}

export enum UnitOrderByInput {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  NumberAsc = "number_ASC",
  NumberDesc = "number_DESC",
  DenAsc = "den_ASC",
  DenDesc = "den_DESC",
  AvailableAsc = "available_ASC",
  AvailableDesc = "available_DESC",
  StartDateAvailabilityAsc = "startDateAvailability_ASC",
  StartDateAvailabilityDesc = "startDateAvailability_DESC",
  UnitSizeAsc = "unitSize_ASC",
  UnitSizeDesc = "unitSize_DESC",
  BedroomsAsc = "bedrooms_ASC",
  BedroomsDesc = "bedrooms_DESC",
  MonthlyPriceAsc = "monthlyPrice_ASC",
  MonthlyPriceDesc = "monthlyPrice_DESC",
  BathroomsAsc = "bathrooms_ASC",
  BathroomsDesc = "bathrooms_DESC",
  FloorplanAsc = "floorplan_ASC",
  FloorplanDesc = "floorplan_DESC",
  FurnishedAsc = "furnished_ASC",
  FurnishedDesc = "furnished_DESC",
  DepositAsc = "deposit_ASC",
  DepositDesc = "deposit_DESC",
  LeaseTermAsc = "leaseTerm_ASC",
  LeaseTermDesc = "leaseTerm_DESC",
  ParkingAsc = "parking_ASC",
  ParkingDesc = "parking_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC"
}

export enum BookingOrderByInput {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  RoommateAsc = "roommate_ASC",
  RoommateDesc = "roommate_DESC",
  IntroductionAsc = "introduction_ASC",
  IntroductionDesc = "introduction_DESC",
  MoveInDateAsc = "moveInDate_ASC",
  MoveInDateDesc = "moveInDate_DESC",
  LeaseTermAsc = "leaseTerm_ASC",
  LeaseTermDesc = "leaseTerm_DESC",
  UnitAsc = "unit_ASC",
  UnitDesc = "unit_DESC",
  OccupancyRateAsc = "occupancyRate_ASC",
  OccupancyRateDesc = "occupancyRate_DESC",
  CertnApplicantIdAsc = "certnApplicantId_ASC",
  CertnApplicantIdDesc = "certnApplicantId_DESC"
}

export enum RoomOrderByInput {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  LastMessageTimeAsc = "lastMessageTime_ASC",
  LastMessageTimeDesc = "lastMessageTime_DESC"
}

export enum MessageOrderByInput {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  IsReadAsc = "isRead_ASC",
  IsReadDesc = "isRead_DESC",
  TypeAsc = "type_ASC",
  TypeDesc = "type_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC",
  EmailNotificationSentAsc = "emailNotificationSent_ASC",
  EmailNotificationSentDesc = "emailNotificationSent_DESC"
}

export enum LeaseOrderByInput {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  DocusignEnvelopeUriAsc = "docusignEnvelopeUri_ASC",
  DocusignEnvelopeUriDesc = "docusignEnvelopeUri_DESC",
  DocusignEnvelopeIdAsc = "docusignEnvelopeId_ASC",
  DocusignEnvelopeIdDesc = "docusignEnvelopeId_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  StatusAsc = "status_ASC",
  StatusDesc = "status_DESC",
  SubjectAsc = "subject_ASC",
  SubjectDesc = "subject_DESC",
  MessageAsc = "message_ASC",
  MessageDesc = "message_DESC"
}

export enum TestimonialOrderByInput {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  ImageUrlAsc = "imageUrl_ASC",
  ImageUrlDesc = "imageUrl_DESC",
  KeywordAsc = "keyword_ASC",
  KeywordDesc = "keyword_DESC",
  QuoteAsc = "quote_ASC",
  QuoteDesc = "quote_DESC",
  FullNameAsc = "fullName_ASC",
  FullNameDesc = "fullName_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC"
}

export enum ArticleType {
  Trending = "TRENDING",
  EstateScoop = "ESTATE_SCOOP"
}

export enum ArticleOrderByInput {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  ImageUrlAsc = "imageUrl_ASC",
  ImageUrlDesc = "imageUrl_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  ArticleUrlAsc = "articleUrl_ASC",
  ArticleUrlDesc = "articleUrl_DESC",
  ArticleTypeAsc = "articleType_ASC",
  ArticleTypeDesc = "articleType_DESC"
}

export enum MutationType {
  Created = "CREATED",
  Updated = "UPDATED",
  Deleted = "DELETED"
}

export type DateTime = any

// ====================================================
// Documents
// ====================================================

export type FetchArticlesVariables = {
  where?: Maybe<ArticleWhereInput>
  orderBy?: Maybe<ArticleOrderByInput>
  first?: Maybe<number>
}

export type FetchArticlesQuery = {
  __typename?: "Query"

  articles: Maybe<FetchArticlesArticles>[]
}

export type FetchArticlesArticles = {
  __typename?: "Article"

  title: string

  imageUrl: string

  subtitle: string

  description: string

  articleUrl: string
}

export type CreateBookingVariables = {
  data: BookingCreateInputWithoutSeeker
}

export type CreateBookingMutation = {
  __typename?: "Mutation"

  createBooking: CreateBookingCreateBooking
}

export type CreateBookingCreateBooking = {
  __typename?: "Booking"

  id: string
}

export type CreateMessageVariables = {
  text: string
  room: string
  type?: Maybe<MessageType>
  url?: Maybe<string>
}

export type CreateMessageMutation = {
  __typename?: "Mutation"

  createMessage: CreateMessageCreateMessage
}

export type CreateMessageCreateMessage = {
  __typename?: "Message"

  text: Maybe<string>

  isRead: Maybe<boolean>

  type: Maybe<MessageType>

  url: Maybe<string>

  createdAt: DateTime

  sender: Maybe<CreateMessageSender>
}

export type CreateMessageSender = {
  __typename?: "User"

  id: string
}

export type MessagesInRoomVariables = {
  roomId: string
}

export type MessagesInRoomQuery = {
  __typename?: "Query"

  messagesInRoom: MessagesInRoomMessagesInRoom[]
}

export type MessagesInRoomMessagesInRoom = {
  __typename?: "Message"

  id: string

  text: Maybe<string>

  isRead: Maybe<boolean>

  type: Maybe<MessageType>

  url: Maybe<string>

  user: Maybe<MessagesInRoomSender>
}

export type MessagesInRoomSender = {
  __typename?: "User"

  id: string
}

export type MessagesOfRoomVariables = {
  roomId: string
}

export type MessagesOfRoomSubscription = {
  __typename?: "Subscription"

  messages: Maybe<MessagesOfRoomMessages>
}

export type MessagesOfRoomMessages = {
  __typename?: "MessageSubscriptionPayload"

  mutation: MutationType

  node: Maybe<MessagesOfRoomNode>
}

export type MessagesOfRoomNode = {
  __typename?: "Message"

  id: string

  text: Maybe<string>

  isRead: Maybe<boolean>

  type: Maybe<MessageType>

  url: Maybe<string>

  sender: Maybe<MessagesOfRoomSender>
}

export type MessagesOfRoomSender = {
  __typename?: "User"

  id: string
}

export type LeasesConnectionVariables = {
  where?: Maybe<LeaseWhereInput>
}

export type LeasesConnectionQuery = {
  __typename?: "Query"

  leasesConnection: LeasesConnectionLeasesConnection
}

export type LeasesConnectionLeasesConnection = {
  __typename?: "LeaseConnection"

  aggregate: LeasesConnectionAggregate
}

export type LeasesConnectionAggregate = {
  __typename?: "AggregateLease"

  count: number
}

export type TestimonialsVariables = {
  orderBy?: Maybe<TestimonialOrderByInput>
  first?: Maybe<number>
}

export type TestimonialsQuery = {
  __typename?: "Query"

  testimonials: TestimonialsTestimonials[]
}

export type TestimonialsTestimonials = {
  __typename?: "Testimonial"

  imageUrl: string

  fullName: string

  keyword: string

  quote: string
}

export type MessagesConnectionVariables = {
  where?: Maybe<MessageWhereInput>
}

export type MessagesConnectionQuery = {
  __typename?: "Query"

  messagesConnection: MessagesConnectionMessagesConnection
}

export type MessagesConnectionMessagesConnection = {
  __typename?: "MessageConnection"

  count: number
}

export type MessagesConnectionAggregate = {
  __typename?: "AggregateMessage"

  count: number
}

export type FetchMeVariables = {}

export type FetchMeQuery = {
  __typename?: "Query"

  viewer: Maybe<FetchMeViewer>
}

export type FetchMeViewer = {
  __typename?: "Viewer"

  me: FetchMeMe
}

export type FetchMeMe = {
  __typename?: "User"

  id: string

  auth0id: string

  firstName: string

  lastName: string

  email: string

  userType: UserType
}

export type RoommatesForListingVariables = {
  id: string
}

export type RoommatesForListingQuery = {
  __typename?: "Query"

  roommatesForListing: RoommatesForListingRoommatesForListing[]
}

export type RoommatesForListingRoommatesForListing = {
  __typename?: "Booking"

  id: string

  seeker: RoommatesForListingSeeker
}

export type RoommatesForListingSeeker = {
  __typename?: "User"

  id: string

  firstName: string

  avatarUrl: Maybe<string>
}

export type SimilarListingsVariables = {
  id: string
}

export type SimilarListingsQuery = {
  __typename?: "Query"

  similarListings: Maybe<SimilarListingsSimilarListings>[]
}

export type SimilarListingsSimilarListings = {
  __typename?: "Listing"

  id: string

  title: string

  description: string

  location: Maybe<SimilarListingsLocation>

  pictures: Maybe<SimilarListingsPictures[]>

  units: Maybe<SimilarListingsUnits[]>
}

export type SimilarListingsLocation = {
  __typename?: "Location"

  id: string

  neighbourHood: Maybe<SimilarListingsNeighbourHood>
}

export type SimilarListingsNeighbourHood = {
  __typename?: "Neighbourhood"

  id: string

  name: string
}

export type SimilarListingsPictures = {
  __typename?: "Picture"

  id: string

  url: string
}

export type SimilarListingsUnits = {
  __typename?: "Unit"

  id: string

  monthlyPrice: number
}

export type BookingsForUserVariables = {
  userId: string
}

export type BookingsForUserQuery = {
  __typename?: "Query"

  bookingsForUser: BookingsForUserBookingsForUser[]
}

export type BookingsForUserBookingsForUser = {
  __typename?: "Booking"

  id: string

  listing: BookingsForUserListing
}

export type BookingsForUserListing = {
  __typename?: "Listing"

  id: string

  title: string

  description: string

  location: Maybe<BookingsForUserLocation>

  pictures: Maybe<BookingsForUserPictures[]>
}

export type BookingsForUserLocation = {
  __typename?: "Location"

  neighbourHood: Maybe<BookingsForUserNeighbourHood>
}

export type BookingsForUserNeighbourHood = {
  __typename?: "Neighbourhood"

  name: string
}

export type BookingsForUserPictures = {
  __typename?: "Picture"

  url: string
}

export type FetchListingInfoVariables = {
  where: ListingWhereUniqueInput
}

export type FetchListingInfoQuery = {
  __typename?: "Query"

  listing: FetchListingInfoListing
}

export type FetchListingInfoListing = {
  __typename?: "Listing"

  id: string

  title: string

  description: string

  pictures: Maybe<FetchListingInfoPictures[]>

  location: Maybe<FetchListingInfoLocation>

  propertyType: PropertyType

  pets: Pets

  utilities: Maybe<FetchListingInfoUtilities>

  amenities: Maybe<FetchListingInfoAmenities>

  user: FetchListingInfoOwner

  buildingConstructionYear: Maybe<string>

  totalUnitsInBuilding: Maybe<number>

  storiesInBuilding: Maybe<number>

  developmentCompany: Maybe<string>

  tenantRequirements: Maybe<FetchListingInfoTenantRequirements>

  units: Maybe<FetchListingInfoUnits[]>
}

export type FetchListingInfoPictures = {
  __typename?: "Picture"

  id: string

  filename: Maybe<string>

  url: string
}

export type FetchListingInfoLocation = {
  __typename?: "Location"

  id: string

  tags: Maybe<FetchListingInfoTags>

  neighbourHood: Maybe<FetchListingInfoNeighbourHood>
}

export type FetchListingInfoTags = {
  __typename?: "NeighbourhoodTags"

  id: string

  closeToTransit: boolean

  purposeBuiltRentals: boolean

  students: boolean

  parksAndWater: boolean

  nightlife: boolean

  luxuryRentals: boolean

  shortTermRentals: boolean

  professionallyManaged: boolean

  onlinePayments: boolean

  onlineApplications: boolean

  furnishedRentals: boolean

  petFriendly: boolean
}

export type FetchListingInfoNeighbourHood = {
  __typename?: "Neighbourhood"

  id: string

  city: FetchListingInfoCity
}

export type FetchListingInfoCity = {
  __typename?: "City"

  name: string
}

export type FetchListingInfoUtilities = {
  __typename?: "Utilities"

  id: string

  electricity: boolean

  heating: boolean

  airConditioning: boolean

  water: boolean

  garbageRemoval: boolean

  cableTv: boolean

  internet: boolean

  maintenanceFees: boolean

  gas: boolean
}

export type FetchListingInfoAmenities = {
  __typename?: "ListingAmenities"

  id: string

  onSiteLaundry: boolean

  yard: boolean

  concierge: boolean

  elevator: boolean

  doorPerson: boolean

  dryCleaning: boolean

  gym: boolean

  swimmingPool: boolean

  storage: boolean

  guestSuites: boolean

  beanfieldWifi: boolean

  publicWifi: boolean

  bbqPermitted: boolean

  visitorsParking: boolean

  partyRoom: boolean

  bikeStorage: boolean

  wheelchairAccess: boolean

  rooftopDeck: boolean

  hotTub: boolean

  sauna: boolean

  gameRoom: boolean

  theatre: boolean

  businessCenter: boolean

  communalGarden: boolean

  petSpa: boolean

  mediaRoom: boolean

  basketballCourt: boolean

  yogaRoom: boolean

  tennisCourt: boolean

  rooftopTerrace: boolean

  coworkingSpace: boolean

  conferenceRoom: boolean

  patio: boolean

  lounge: boolean
}

export type FetchListingInfoOwner = {
  __typename?: "User"

  id: string

  phone: Maybe<string>
}

export type FetchListingInfoTenantRequirements = {
  __typename?: "TenantRequirements"

  id: string

  exceptionalCredit: boolean

  payStub: boolean

  letterOfEmployment: boolean

  pastReferences: boolean

  noSmoking: boolean

  extraDocumentation: boolean

  photoId: boolean
}

export type FetchListingInfoUnits = {
  __typename?: "Unit"

  id: string

  title: string

  bedrooms: Bedrooms

  number: Maybe<string>

  den: Maybe<number>

  available: boolean

  floorplan: Maybe<string>

  startDateAvailability: Maybe<DateTime>

  unitSize: Maybe<number>

  parking: Parking

  bathrooms: number

  furnished: boolean

  monthlyPrice: number

  deposit: Maybe<number>

  pictures: Maybe<FetchListingInfo_Pictures[]>

  amenities: Maybe<FetchListingInfo_Amenities>

  unitAppliances: Maybe<FetchListingInfo_UnitAppliances>

  leaseTerm: Maybe<number>
}

export type FetchListingInfo_Pictures = {
  __typename?: "Picture"

  id: string

  filename: Maybe<string>

  url: string
}

export type FetchListingInfo_Amenities = {
  __typename?: "UnitAmenities"

  id: string

  ac: boolean

  centralHeat: boolean

  electricalHeating: boolean

  radiatorHeating: boolean

  fireplace: boolean

  carpet: boolean

  ceilingFan: boolean

  hardwoodFloor: boolean

  walkInCloset: boolean

  highCeilings: boolean

  bathroomFan: boolean

  balcony: boolean
}

export type FetchListingInfo_UnitAppliances = {
  __typename?: "UnitAppliances"

  id: string

  fridge: boolean

  stove: boolean

  rangehood: boolean

  dishwasher: boolean

  microwave: boolean

  inunitwasher: boolean

  inunitdryer: boolean

  wineFridge: boolean

  dehumidifier: boolean

  firePlaceElectric: boolean

  firePlaceGas: boolean

  jacuzzi: boolean
}

export type UpdateListingVariables = {
  where: ListingWhereUniqueInput
  data: ListingUpdateInput
}

export type UpdateListingMutation = {
  __typename?: "Mutation"

  updateListing: UpdateListingUpdateListing
}

export type UpdateListingUpdateListing = {
  __typename?: "Listing"

  id: string

  units: Maybe<UpdateListingUnits[]>
}

export type UpdateListingUnits = {
  __typename?: "Unit"

  id: string

  title: string
}

export type FetchTrendingListingsVariables = {
  where?: Maybe<ListingWhereInput>
  orderBy?: Maybe<ListingOrderByInput>
  first?: Maybe<number>
  skip?: Maybe<number>
}

export type FetchTrendingListingsQuery = {
  __typename?: "Query"
  trendingListings: Maybe<FetchTrendingListingsTrendingListings>[]
  recommendListings: Maybe<FetchTrendingListingsTrendingListings>[]
}

export type FetchTrendingListingsTrendingListings = {
  __typename?: "Listing"

  id: string

  title: string

  description: string

  units: Maybe<FetchTrendingListingsUnits[]>

  bookings: Maybe<FetchTrendingListingsBookings[]>

  location: Maybe<FetchTrendingListingsLocation>

  pictures: Maybe<FetchTrendingListingsPictures[]>
}

export type FetchTrendingListingsUnits = {
  __typename?: "Unit"

  id: string

  monthlyPrice: number
}

export type FetchTrendingListingsBookings = {
  __typename?: "Booking"

  id: string

  createdAt: DateTime
}

export type FetchTrendingListingsLocation = {
  __typename?: "Location"

  id: string

  neighbourHood: Maybe<FetchTrendingListingsNeighbourHood>

  address?: string
}

export type FetchTrendingListingsNeighbourHood = {
  __typename?: "Neighbourhood"

  id: string

  name: string

  city: any
}

export type FetchTrendingListingsPictures = {
  __typename?: "Picture"

  id: string

  url: string
}

export type CreateLeaseVariables = {
  data: LeaseCreateInput
  redirectUrl: string
  landlordLegalName: string
}

export type CreateLeaseMutation = {
  __typename?: "Mutation"

  createLease: CreateLeaseCreateLease
}

export type CreateLeaseCreateLease = {
  __typename?: "Lease"

  id: string

  docusignEnvelopeUri: Maybe<string>

  docusignEnvelopeId: Maybe<string>

  status: Maybe<DocusignEnvelopeStatus>

  subject: Maybe<string>

  message: Maybe<string>
}

export type RoomsPerUserVariables = {
  where: RoomWhereInput
  // orderBy: RoomOrderByInput
}

export type UnreadMessageVariables = {
  where: UnreadWhereInput
}

export type RoomsPerUserQuery = {
  __typename?: "Query"

  roomsPerUser: RoomsPerUserRoomsPerUser[]
}

export type ListingPerBookingQuery = {
  __typename?: "Query"

  listingPerBookings: RoomsPerUserRoomsPerUser[]
}

export type UnReadMessagesQuery = {
  __typename?: "Query"

  unreadMessages: UnreadMessagesPerRooms
}

export type UnreadMessagesPerRooms = {
  aggregate: FetchListingsAggregate
}

export type RoomsPerUserRoomsPerUser = {
  __typename?: "Room"

  id: string

  booking: Maybe<RoomsPerUserBooking>

  messages: Maybe<RoomsPerUserMessages[]>

  participants: Maybe<RoomsPerUserParticipants[]>
}

export type RoomsPerUserBooking = {
  __typename?: "Booking"

  id: string

  Listing: RoomsPerUserListing
}

export type RoomsPerUserListing = {
  __typename?: "Listing"

  id: string

  title: string

  monthlyPrice: any

  deposit: any
}

export type RoomsPerUserMessages = {
  __typename?: "Message"

  text: Maybe<string>

  isRead: Maybe<boolean>

  createdAt: DateTime

  sender: Maybe<RoomsPerUserSender>
}

export type RoomsPerUserSender = {
  __typename?: "User"

  id: string
}
export type RoomsPerUserParticipants = {
  __typename?: "User"

  id: string

  name: string

  participant: UserParticipants
}
export type UserParticipants = {
  __typename?: "User"

  id: string

  firstName: string

  lastName: string

  userType: UserType

  avatarUrl: Maybe<string>
}

export type RoomsOfUserVariables = {
  where: RoomSubscriptionWhereInput
}

export type RoomsOfUserSubscription = {
  __typename?: "Subscription"

  rooms: Maybe<RoomsOfUserRooms>
}

export type RoomsOfUserRooms = {
  __typename?: "RoomSubscriptionPayload"

  mutation: MutationType

  node: Maybe<RoomsOfUserNode>
}

export type RoomsOfUserNode = {
  __typename?: "Room"

  id: string

  booking: Maybe<RoomsOfUserBooking>

  messages: Maybe<RoomsOfUserMessages[]>

  participants: Maybe<RoomsOfUserParticipants[]>
}

export type RoomsOfUserBooking = {
  __typename?: "Booking"

  id: string

  Listing: RoomsOfUserListing
}

export type RoomsOfUserListing = {
  __typename?: "Listing"

  id: string

  title: string
}

export type RoomsOfUserMessages = {
  __typename?: "Message"

  text: Maybe<string>

  isRead: Maybe<boolean>

  createdAt: DateTime

  sender: Maybe<RoomsOfUserSender>
}

export type RoomsOfUserSender = {
  __typename?: "User"

  id: string
}

export type RoomsOfUserParticipants = {
  __typename?: "User"

  id: string

  firstName: string

  lastName: string

  userType: UserType

  avatarUrl: Maybe<string>
}

export type FetchLeaseViewVariables = {
  leaseId: string
  redirectUrl: string
}

export type FetchLeaseViewQuery = {
  __typename?: "Query"

  leaseView: FetchLeaseViewLeaseView
}

export type FetchLeaseViewLeaseView = {
  __typename?: "Lease"

  id: string

  docusignEnvelopeUri: Maybe<string>
}

export type FetchLeasesVariables = {
  where?: Maybe<LeaseWhereInput>
  orderBy?: Maybe<LeaseOrderByInput>
  skip?: Maybe<number>
  after?: Maybe<string>
  before?: Maybe<string>
  first?: Maybe<number>
  last?: Maybe<number>
}

export type FetchLeasesQuery = {
  __typename?: "Query"

  leases: Maybe<FetchLeasesLeases>[]
}

export type FetchLeasesLeases = {
  __typename?: "Lease"

  id: string

  createdAt: DateTime

  updatedAt: DateTime

  status: Maybe<DocusignEnvelopeStatus>

  subject: Maybe<string>

  message: Maybe<string>

  room: Maybe<FetchLeasesRoom>

  landlordSigners: Maybe<FetchLeasesLandlordSigners[]>

  tenantSigners: Maybe<FetchLeasesTenantSigners[]>
}

export type FetchLeasesRoom = {
  __typename?: "Room"

  id: string

  booking: Maybe<FetchLeasesBooking>
}

export type FetchLeasesBooking = {
  __typename?: "Booking"

  id: string

  Listing: FetchLeasesListing
}

export type FetchLeasesListing = {
  __typename?: "Listing"

  id: string

  title: string
}

export type FetchLeasesLandlordSigners = {
  __typename?: "User"

  id: string

  firstName: string

  lastName: string
}

export type FetchLeasesTenantSigners = {
  __typename?: "User"

  id: string

  firstName: string

  lastName: string
}

export type FetchListingsVariables = {
  where?: Maybe<ListingWhereInput>
  orderBy?: Maybe<UnitOrderByInput>
  first?: Maybe<number>
  skip?: Maybe<number>
}

export type FetchListingsQuery = {
  __typename?: "Query"

  listings: Maybe<FetchListingsListings>[]

  listingsConnection: FetchListingsListingsConnection
}

export type FetchListingsListings = {
  __typename?: "Listing"

  id: string

  title: string

  createdAt: DateTime

  premiumStart: Maybe<DateTime>

  premiumEnd: Maybe<DateTime>

  pictures: Maybe<FetchListingsPictures[]>

  units: Maybe<FetchListingsUnits[]>

  location: Maybe<FetchListingsLocation>
}

export type FetchListingsPictures = {
  __typename?: "Picture"

  id: string

  url: string
}

export type FetchListingsUnits = {
  __typename?: "Unit"

  monthlyPrice: number
}

export type FetchListingsLocation = {
  __typename?: "Location"

  id: string

  lat: number

  lng: number
}

export type FetchListingsListingsConnection = {
  __typename?: "ListingConnection"

  aggregate: FetchListingsAggregate
}

export type FetchListingsAggregate = {
  __typename?: "AggregateListing"

  count: number
}

export type FetchSearchListingsVariables = {
  where?: Maybe<UnitWhereInput>
  orderBy?: Maybe<UnitOrderByInput>
  whereListings?: Maybe<ListingWhereInput>
  first?: Maybe<number>
  skip?: Maybe<number>
}

export type FetchSearchListingsQuery = {
  __typename?: "Query"

  searchListings: Maybe<FetchSearchListingsSearchListings>[]

  listingsConnection: FetchSearchListingsListingsConnection
}

export type FetchSearchListingsSearchListings = {
  __typename?: "Listing"

  id: string

  title: string

  createdAt: DateTime

  premiumStart: Maybe<DateTime>

  premiumEnd: Maybe<DateTime>

  pictures: Maybe<FetchSearchListingsPictures[]>

  units: Maybe<FetchSearchListingsUnits[]>

  location: Maybe<FetchSearchListingsLocation>
}

export type FetchSearchListingsPictures = {
  __typename?: "Picture"

  id: string

  url: string
}

export type FetchSearchListingsUnits = {
  __typename?: "Unit"

  monthlyPrice: number
}

export type FetchSearchListingsLocation = {
  __typename?: "Location"

  id: string

  lat: number

  lng: number
}

export type FetchSearchListingsListingsConnection = {
  __typename?: "ListingConnection"

  aggregate: FetchSearchListingsAggregate
}

export type FetchSearchListingsAggregate = {
  __typename?: "AggregateListing"

  count: number
}

export type UpdateUserVariables = {
  data: UserUpdateInput
}

export type UpdateUserMutation = {
  __typename?: "Mutation"

  updateUser: UpdateUserUpdateUser
}

export type UpdateUserUpdateUser = {
  __typename?: "User"

  id: string

  email: string

  firstName: string

  lastName: string

  userType: UserType

  city: Maybe<string>

  industry: Maybe<string>

  moveIn: Maybe<string>

  budget: Maybe<string>

  roommate: Maybe<boolean>

  bio: Maybe<string>

  avatarUrl: Maybe<string>
}

export type FetchSingleListingVariables = {
  where: ListingWhereUniqueInput
}

export type FetchSingleListingQuery = {
  __typename?: "Query"

  listing: FetchSingleListingListing
}

export type FetchSingleListingListing = {
  __typename?: "Listing"

  id: string

  updatedAt: DateTime

  title: string

  moveInBonuses: string

  description: string

  premiumStart: DateTime

  premiumEnd: DateTime

  isHidden: Maybe<boolean>

  activate?: Maybe<boolean>

  user: FetchSingleListingOwner

  utilities: Maybe<FetchSingleListingUtilities>

  amenities: Maybe<FetchSingleListingAmenities>

  unitAppliances: Maybe<FetchSingleListing_UnitAppliances>

  propertyType: PropertyType

  pet: Pet

  buildingConstructionYear: Maybe<string>

  totalUnitsInBuilding: Maybe<number>

  storiesInBuilding: Maybe<number>

  developmentCompany: Maybe<string>

  tenantRequirements: Maybe<FetchSingleListingTenantRequirements>

  pictures: Maybe<FetchSingleListingPictures[]>

  units: Maybe<FetchSingleListingUnits[]>

  location: Maybe<FetchSingleListingLocation>
}

export type FetchSingleListingOwner = {
  __typename?: "User"

  id: string

  firstName: string

  lastName: string

  avatarUrl: Maybe<string>

  city: Maybe<string>

  industry: Maybe<string>

  bio: Maybe<string>

  createdAt: DateTime

  phone: Maybe<string>
}

export type FetchSingleListingUtilities = {
  __typename?: "Utilities"

  id: string

  electricity: boolean

  heating: boolean

  airConditioning: boolean

  water: boolean

  garbageRemoval: boolean

  cableTv: boolean

  internet: boolean

  maintenanceFees: boolean

  gas: boolean
}

export type FetchSingleListingAmenities = {
  __typename?: "ListingAmenities"

  id: string

  onSiteLaundry: boolean

  yard: boolean

  concierge: boolean

  elevator: boolean

  doorPerson: boolean

  dryCleaning: boolean

  gym: boolean

  swimmingPool: boolean

  storage: boolean

  guestSuites: boolean

  beanfieldWifi: boolean

  publicWifi: boolean

  bbqPermitted: boolean

  visitorsParking: boolean

  partyRoom: boolean

  bikeStorage: boolean

  wheelchairAccess: boolean

  rooftopDeck: boolean

  hotTub: boolean

  sauna: boolean

  gameRoom: boolean

  theatre: boolean

  businessCenter: boolean

  communalGarden: boolean

  petSpa: boolean

  mediaRoom: boolean

  basketballCourt: boolean

  yogaRoom: boolean

  tennisCourt: boolean

  rooftopTerrace: boolean

  coworkingSpace: boolean

  conferenceRoom: boolean

  patio: boolean

  lounge: boolean
}

export type FetchSingleListingTenantRequirements = {
  __typename?: "TenantRequirements"

  id: string

  exceptionalCredit: boolean

  payStub: boolean

  letterOfEmployment: boolean

  pastReferences: boolean

  noSmoking: boolean

  extraDocumentation: boolean

  photoId: boolean
}

export type FetchSingleListingPictures = {
  __typename?: "Picture"

  id: string

  url: string
}

export type FetchPreViewingCity = {
  __typename?: "pre_view_city_type"

  id: string

  city: Maybe<string>
}

export type FetchSingleListingUnits = {
  __typename?: "Unit"

  id: string

  title: string

  number: Maybe<string>

  den: Maybe<number>

  available: boolean

  startDateAvailability: Maybe<DateTime>

  unitSize: Maybe<number>

  bedrooms: Bedrooms

  monthlyPrice: number

  bathrooms: number

  floorplan: Maybe<string>

  furnished: boolean

  deposit: Maybe<number>

  unitAmenities: Maybe<FetchSingleListing_Amenities>

  unitAppliances: Maybe<FetchSingleListing_UnitAppliances>

  leaseTerm: Maybe<number>

  parking: Parking

  pictures: Maybe<FetchSingleListing_Pictures[]>

  parkingSpots: Maybe<number>
}

export type FetchSingleListing_Amenities = {
  __typename?: "UnitAmenities"

  id: string

  ac: boolean

  centralHeat: boolean

  electricalHeating: boolean

  radiatorHeating: boolean

  fireplace: boolean

  carpet: boolean

  ceilingFan: boolean

  hardwoodFloor: boolean

  walkInCloset: boolean

  highCeilings: boolean

  bathroomFan: boolean

  balcony: boolean
}
export type FetchSingleListing_UnitAppliances = {
  __typename?: "UnitAppliances"

  id: string

  fridge: boolean

  stove: boolean

  rangehood: boolean

  dishwasher: boolean

  microwave: boolean

  inunitwasher: boolean

  inunitdryer: boolean

  wineFridge: boolean

  dehumidifier: boolean

  firePlaceElectric: boolean

  firePlaceGas: boolean

  jacuzzi: boolean
}

export type FetchSingleListing_Pictures = {
  __typename?: "Picture"

  id: string

  url: string
}

export type FetchSingleListingLocation = {
  __typename?: "Location"

  id: string

  lat: number

  lng: number

  tags: Maybe<FetchSingleListingTags>

  neighbourHood: Maybe<FetchSingleListingNeighbourHood>
}

export type FetchSingleListingTags = {
  __typename?: "NeighbourhoodTags"

  id: string

  closeToTransit: boolean

  purposeBuiltRentals: boolean

  students: boolean

  parksAndWater: boolean

  nightlife: boolean

  luxuryRentals: boolean

  shortTermRentals: boolean

  professionallyManaged: boolean

  onlinePayments: boolean

  onlineApplications: boolean

  furnishedRentals: boolean

  petFriendly: boolean
}

export type FetchSingleListingNeighbourHood = {
  __typename?: "Neighbourhood"

  id: string

  name: string

  city: FetchSingleListingCity

  homePreview: Maybe<FetchSingleListingHomePreview>
}

export type FetchSingleListingCity = {
  __typename?: "City"

  id: string

  name: string
}

export type FetchSingleListingHomePreview = {
  __typename?: "Picture"

  id: string

  url: string
}

export type FetchPublicTenantVariables = {
  idToken: string
}

export type FetchPublicTenantQuery = {
  __typename?: "Query"

  user: FetchPublicTenantUser
}

export type FetchPublicTenantUser = {
  __typename?: "User"

  id: string

  avatarUrl: Maybe<string>

  firstName: string

  lastName: string

  city: Maybe<string>

  createdAt: DateTime

  moveIn: Maybe<string>

  industry: Maybe<string>

  budget: Maybe<string>

  roommate: Maybe<boolean>

  bio: Maybe<string>
}

export type AuthenticateVariables = {
  idToken: string
}

export type AuthenticateMutation = {
  __typename?: "Mutation"

  authenticate: AuthenticateAuthenticate
}

export type AuthenticateAuthenticate = {
  __typename?: "User"

  id: string

  auth0id: string

  firstName: string

  lastName: string

  legalName: Maybe<string>

  email: string

  userType: UserType

  avatarUrl: Maybe<string>

  city: Maybe<string>

  industry: Maybe<string>

  bio: Maybe<string>

  locale: Maybe<string>

  gender: Maybe<string>

  budget: Maybe<string>

  moveIn: Maybe<string>

  roommate: Maybe<boolean>
}

export type FetchCurrentUserVariables = {}

export type FetchCurrentUserQuery = {
  __typename?: "Query"

  viewer: Maybe<FetchCurrentUserViewer>
}

export type FetchCurrentUserViewer = {
  __typename?: "Viewer"

  me: FetchCurrentUserMe
}

type Name = {
  name: string
}

type SubAdminPermissions = {
  id: string
  permissions: Name
  __typename: "PermissionTypeDef"
  permissiontypes: Name
}
export type FetchCurrentUserMe = {
  __typename?: "User"

  id: string

  auth0id: string

  firstName: string

  lastName: string

  legalName: Maybe<string>

  email: string

  userType: UserType

  bio: Maybe<string>

  permissions: Maybe<SubAdminPermissions[]>

  city: Maybe<string>

  industry: Maybe<string>

  budget: Maybe<string>

  moveIn: Maybe<string>

  roommate: Maybe<boolean>

  notify: Maybe<boolean>

  furnished: Maybe<boolean>

  avatarUrl: Maybe<string>

  occupation: Maybe<string>

  phone: Maybe<string>

  pets: Maybe<string>

  credit_score: Maybe<string>

  flatmates: Maybe<number>

  view_property: Maybe<string>

  zip_code: Maybe<string>

  lu_from: Maybe<string>

  role: Maybe<string>

  lu_type: Maybe<string>

  bedrooms: Maybe<string>

  rental_properties: Maybe<number>

  rental_properties_permonth: Maybe<number>
}

export type DeleteListingVariables = {
  where: ListingWhereUniqueInput
}

export type DeleteListingMutation = {
  __typename?: "Mutation"

  deleteListing: DeleteListingDeleteListing
}

export type DeleteListingDeleteListing = {
  __typename?: "Listing"

  id: string
}

export type FetchUserProfileVariables = {}

export type FetchUserProfileQuery = {
  __typename?: "Query"

  currentUser: FetchUserProfileMe
}

export type FetchUserProfileViewer = {
  __typename?: "Viewer"

  me: FetchUserProfileMe
}

export type FetchUserProfileMe = {
  __typename?: "User"

  id: string

  auth0id: string

  firstName: string

  lastName: string

  legalName: Maybe<string>

  email: string

  userType: UserType

  bio: Maybe<string>

  city: Maybe<string>

  industry: Maybe<string>

  budget: Maybe<string>

  min_budget: Maybe<string>

  moveIn: Maybe<string>

  roommate: Maybe<boolean>

  notify: Maybe<boolean>

  furnished: Maybe<boolean>

  avatarUrl: Maybe<string>

  occupation: Maybe<string>

  phone: Maybe<string>

  credit_score: Maybe<string>

  flatmates: Maybe<number>

  view_property: Maybe<string>

  zip_code: Maybe<string>

  lu_from: Maybe<string>

  availabilityDays: Maybe<string>

  pre_view_city: Maybe<FetchPreViewingCity[]>

  pre_viewing_city: Maybe<FetchPreViewingCity[]>

  role: Maybe<string>

  lu_type: Maybe<string>

  bedrooms: Maybe<string>

  bedroom: Maybe<string>

  furnish: Maybe<string>

  previewingCity: Maybe<string>

  laundaryType: Maybe<string>

  rental_properties: Maybe<number>

  rental_properties_permonth: Maybe<number>

  pet: Maybe<number>

  householdIncome: Maybe<any>
}

import gql from "graphql-tag"
import * as React from "react"
import * as ReactApollo from "react-apollo"
import * as ReactApolloHooks from "react-apollo-hooks"
import { client } from "src/apollo"

// ====================================================
// Components
// ====================================================

export const FetchArticlesDocument = gql`
  query fetchArticles($where: ArticleWhereInput, $orderBy: ArticleOrderByInput, $first: Int) {
    articles(where: $where, orderBy: $orderBy, first: $first) {
      title
      imageUrl
      subtitle
      description
      articleUrl
    }
  }
`
export class FetchArticlesComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchArticlesQuery, FetchArticlesVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<FetchArticlesQuery, FetchArticlesVariables>
        query={FetchArticlesDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type FetchArticlesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FetchArticlesQuery, FetchArticlesVariables>
> &
  TChildProps
export function FetchArticlesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      FetchArticlesQuery,
      FetchArticlesVariables,
      FetchArticlesProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FetchArticlesQuery,
    FetchArticlesVariables,
    FetchArticlesProps<TChildProps>
  >(FetchArticlesDocument, operationOptions)
}
export function useFetchArticles(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FetchArticlesVariables>
) {
  return ReactApolloHooks.useQuery<FetchArticlesQuery, FetchArticlesVariables>(
    FetchArticlesDocument,
    baseOptions
  )
}
export const CreateBookingDocument = gql`
  mutation CreateBooking($data: BookingCreateInputWithoutSeeker!) {
    createBooking(data: $data) {
      id
    }
  }
`
export class CreateBookingComponent extends React.Component<
  Partial<ReactApollo.MutationProps<CreateBookingMutation, CreateBookingVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateBookingMutation, CreateBookingVariables>
        mutation={CreateBookingDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type CreateBookingProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateBookingMutation, CreateBookingVariables>
> &
  TChildProps
export type CreateBookingMutationFn = ReactApollo.MutationFn<
  CreateBookingMutation,
  CreateBookingVariables
>
export function CreateBookingHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      CreateBookingMutation,
      CreateBookingVariables,
      CreateBookingProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateBookingMutation,
    CreateBookingVariables,
    CreateBookingProps<TChildProps>
  >(CreateBookingDocument, operationOptions)
}
export function useCreateBooking(
  baseOptions?: ReactApolloHooks.MutationHookOptions<CreateBookingMutation, CreateBookingVariables>
) {
  return ReactApolloHooks.useMutation<CreateBookingMutation, CreateBookingVariables>(
    CreateBookingDocument,
    baseOptions
  )
}
export const CreateMessageDocument = gql`
  mutation CreateMessage($text: String!, $room: String!, $type: String, $url: String) {
    createMessage(text: $text, room: $room, type: $type, url: $url) {
      text
      isRead
      type
      url
      createdAt
      sender {
        id
      }
    }
  }
`
export class CreateMessageComponent extends React.Component<
  Partial<ReactApollo.MutationProps<CreateMessageMutation, CreateMessageVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateMessageMutation, CreateMessageVariables>
        mutation={CreateMessageDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type CreateMessageProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateMessageMutation, CreateMessageVariables>
> &
  TChildProps
export type CreateMessageMutationFn = ReactApollo.MutationFn<
  CreateMessageMutation,
  CreateMessageVariables
>
export function CreateMessageHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      CreateMessageMutation,
      CreateMessageVariables,
      CreateMessageProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateMessageMutation,
    CreateMessageVariables,
    CreateMessageProps<TChildProps>
  >(CreateMessageDocument, operationOptions)
}
export function useCreateMessage(
  baseOptions?: ReactApolloHooks.MutationHookOptions<CreateMessageMutation, CreateMessageVariables>
) {
  return ReactApolloHooks.useMutation<CreateMessageMutation, CreateMessageVariables>(
    CreateMessageDocument,
    baseOptions
  )
}
export const MessagesInRoomDocument = gql`
  query messagesInRoom($roomId: String!) {
    messagesInRoom(roomId: $roomId) {
      id
      text
      isRead
      type
      url
      user {
        id
        avatarUrl
        firstName
        lastName
      }
    }
  }
`
export class MessagesInRoomComponent extends React.Component<
  Partial<ReactApollo.QueryProps<MessagesInRoomQuery, MessagesInRoomVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<MessagesInRoomQuery, MessagesInRoomVariables>
        query={MessagesInRoomDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type MessagesInRoomProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<MessagesInRoomQuery, MessagesInRoomVariables>
> &
  TChildProps
export function MessagesInRoomHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      MessagesInRoomQuery,
      MessagesInRoomVariables,
      MessagesInRoomProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    MessagesInRoomQuery,
    MessagesInRoomVariables,
    MessagesInRoomProps<TChildProps>
  >(MessagesInRoomDocument, operationOptions)
}
export function useMessagesInRoom(
  baseOptions?: ReactApolloHooks.QueryHookOptions<MessagesInRoomVariables>
) {
  return ReactApolloHooks.useQuery<MessagesInRoomQuery, MessagesInRoomVariables>(
    MessagesInRoomDocument,
    baseOptions
  )
}
export const MessagesOfRoomDocument = gql`
  subscription messagesOfRoom($roomId: String!) {
    messages(roomId: $roomId) {
      mutation
      node {
        id
        text
        isRead
        type
        url
        user {
          id
        }
      }
    }
  }
`
export class MessagesOfRoomComponent extends React.Component<
  Partial<ReactApollo.SubscriptionProps<MessagesOfRoomSubscription, MessagesOfRoomVariables>>
> {
  render() {
    return (
      <ReactApollo.Subscription<MessagesOfRoomSubscription, MessagesOfRoomVariables>
        subscription={MessagesOfRoomDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type MessagesOfRoomProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<MessagesOfRoomSubscription, MessagesOfRoomVariables>
> &
  TChildProps
export function MessagesOfRoomHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      MessagesOfRoomSubscription,
      MessagesOfRoomVariables,
      MessagesOfRoomProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    MessagesOfRoomSubscription,
    MessagesOfRoomVariables,
    MessagesOfRoomProps<TChildProps>
  >(MessagesOfRoomDocument, operationOptions)
}
export function useMessagesOfRoom(
  baseOptions?: ReactApolloHooks.SubscriptionHookOptions<
    MessagesOfRoomSubscription,
    MessagesOfRoomVariables
  >
) {
  return ReactApolloHooks.useSubscription<MessagesOfRoomSubscription, MessagesOfRoomVariables>(
    MessagesOfRoomDocument,
    baseOptions
  )
}
export const LeasesConnectionDocument = gql`
  query leasesConnection($where: LeaseWhereInput) {
    leasesConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export class LeasesConnectionComponent extends React.Component<
  Partial<ReactApollo.QueryProps<LeasesConnectionQuery, LeasesConnectionVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<LeasesConnectionQuery, LeasesConnectionVariables>
        query={LeasesConnectionDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type LeasesConnectionProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<LeasesConnectionQuery, LeasesConnectionVariables>
> &
  TChildProps
export function LeasesConnectionHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      LeasesConnectionQuery,
      LeasesConnectionVariables,
      LeasesConnectionProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    LeasesConnectionQuery,
    LeasesConnectionVariables,
    LeasesConnectionProps<TChildProps>
  >(LeasesConnectionDocument, operationOptions)
}
export function useLeasesConnection(
  baseOptions?: ReactApolloHooks.QueryHookOptions<LeasesConnectionVariables>
) {
  return ReactApolloHooks.useQuery<LeasesConnectionQuery, LeasesConnectionVariables>(
    LeasesConnectionDocument,
    baseOptions
  )
}
export const TestimonialsDocument = gql`
  query Testimonials {
    testimonials {
      imageUrl
      fullName
      keyword
      quote
    }
  }
`
export class TestimonialsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<TestimonialsQuery, TestimonialsVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<TestimonialsQuery, TestimonialsVariables>
        query={TestimonialsDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type TestimonialsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<TestimonialsQuery, TestimonialsVariables>
> &
  TChildProps
export function TestimonialsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      TestimonialsQuery,
      TestimonialsVariables,
      TestimonialsProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    TestimonialsQuery,
    TestimonialsVariables,
    TestimonialsProps<TChildProps>
  >(TestimonialsDocument, operationOptions)
}
export function useTestimonials(
  baseOptions?: ReactApolloHooks.QueryHookOptions<TestimonialsVariables>
) {
  return ReactApolloHooks.useQuery<TestimonialsQuery, TestimonialsVariables>(
    TestimonialsDocument,
    baseOptions
  )
}
export const MessagesConnectionDocument = gql`
  query MessagesConnection($where: MessageWhereInput) {
    messagesConnection(where: $where) {
      count
    }
  }
`
export class MessagesConnectionComponent extends React.Component<
  Partial<ReactApollo.QueryProps<MessagesConnectionQuery, MessagesConnectionVariables>>
> {
  render() {
    return null
    // (
    //   <ReactApollo.Query<MessagesConnectionQuery, MessagesConnectionVariables>
    //     query={MessagesConnectionDocument}
    //     {...((this as any)["props"] as any)}
    //   />
    // )
  }
}
export type MessagesConnectionProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<MessagesConnectionQuery, MessagesConnectionVariables>
> &
  TChildProps
export function MessagesConnectionHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      MessagesConnectionQuery,
      MessagesConnectionVariables,
      MessagesConnectionProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    MessagesConnectionQuery,
    MessagesConnectionVariables,
    MessagesConnectionProps<TChildProps>
  >(MessagesConnectionDocument, operationOptions)
}
export function useMessagesConnection(
  baseOptions?: ReactApolloHooks.QueryHookOptions<MessagesConnectionVariables>
) {
  return ReactApolloHooks.useQuery<MessagesConnectionQuery, MessagesConnectionVariables>(
    MessagesConnectionDocument,
    baseOptions
  )
}
export const FetchMeDocument = gql`
  query fetchMe {
    viewer {
      me {
        id
        auth0id
        firstName
        lastName
        email
        userType
      }
    }
  }
`
export class FetchMeComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchMeQuery, FetchMeVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<FetchMeQuery, FetchMeVariables>
        query={FetchMeDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type FetchMeProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FetchMeQuery, FetchMeVariables>
> &
  TChildProps
export function FetchMeHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<TProps, FetchMeQuery, FetchMeVariables, FetchMeProps<TChildProps>>
    | undefined
) {
  return ReactApollo.graphql<TProps, FetchMeQuery, FetchMeVariables, FetchMeProps<TChildProps>>(
    FetchMeDocument,
    operationOptions
  )
}
export function useFetchMe(baseOptions?: ReactApolloHooks.QueryHookOptions<FetchMeVariables>) {
  return ReactApolloHooks.useQuery<FetchMeQuery, FetchMeVariables>(FetchMeDocument, baseOptions)
}
export const RoommatesForListingDocument = gql`
  query RoommatesForListing($id: String!) {
    roommatesForListing(id: $id) {
      id
      seeker {
        id
        firstName
        avatarUrl
      }
    }
  }
`
export class RoommatesForListingComponent extends React.Component<
  Partial<ReactApollo.QueryProps<RoommatesForListingQuery, RoommatesForListingVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<RoommatesForListingQuery, RoommatesForListingVariables>
        query={RoommatesForListingDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type RoommatesForListingProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<RoommatesForListingQuery, RoommatesForListingVariables>
> &
  TChildProps
export function RoommatesForListingHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      RoommatesForListingQuery,
      RoommatesForListingVariables,
      RoommatesForListingProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RoommatesForListingQuery,
    RoommatesForListingVariables,
    RoommatesForListingProps<TChildProps>
  >(RoommatesForListingDocument, operationOptions)
}
export function useRoommatesForListing(
  baseOptions?: ReactApolloHooks.QueryHookOptions<RoommatesForListingVariables>
) {
  return ReactApolloHooks.useQuery<RoommatesForListingQuery, RoommatesForListingVariables>(
    RoommatesForListingDocument,
    baseOptions
  )
}
export const SimilarListingsDocument = gql`
  query similarListings($id: String!) {
    similarListings(id: $id) {
      id
      title
      description
      location {
        id
        neighbourHood {
          id
          name
        }
      }
      pictures {
        id
        url
      }
      units {
        id
        monthlyPrice
      }
    }
  }
`
export class SimilarListingsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<SimilarListingsQuery, SimilarListingsVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<SimilarListingsQuery, SimilarListingsVariables>
        query={SimilarListingsDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type SimilarListingsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SimilarListingsQuery, SimilarListingsVariables>
> &
  TChildProps
export function SimilarListingsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      SimilarListingsQuery,
      SimilarListingsVariables,
      SimilarListingsProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SimilarListingsQuery,
    SimilarListingsVariables,
    SimilarListingsProps<TChildProps>
  >(SimilarListingsDocument, operationOptions)
}
export function useSimilarListings(
  baseOptions?: ReactApolloHooks.QueryHookOptions<SimilarListingsVariables>
) {
  return ReactApolloHooks.useQuery<SimilarListingsQuery, SimilarListingsVariables>(
    SimilarListingsDocument,
    baseOptions
  )
}
export const BookingsForUserDocument = gql`
  query bookingsForUser($userId: String!) {
    bookingsForUser(userId: $userId) {
      id
      listing {
        id
        title
        description
        location {
          neighbourHood {
            name
          }
        }
        pictures {
          url
        }
      }
    }
  }
`
export class BookingsForUserComponent extends React.Component<
  Partial<ReactApollo.QueryProps<BookingsForUserQuery, BookingsForUserVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<BookingsForUserQuery, BookingsForUserVariables>
        query={BookingsForUserDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type BookingsForUserProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<BookingsForUserQuery, BookingsForUserVariables>
> &
  TChildProps
export function BookingsForUserHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      BookingsForUserQuery,
      BookingsForUserVariables,
      BookingsForUserProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    BookingsForUserQuery,
    BookingsForUserVariables,
    BookingsForUserProps<TChildProps>
  >(BookingsForUserDocument, operationOptions)
}
export function useBookingsForUser(
  baseOptions?: ReactApolloHooks.QueryHookOptions<BookingsForUserVariables>
) {
  return ReactApolloHooks.useQuery<BookingsForUserQuery, BookingsForUserVariables>(
    BookingsForUserDocument,
    baseOptions
  )
}
export const FetchListingInfoDocument = gql`
  query fetchListingInfo($where: ListingWhereUniqueInput!) {
    listing(where: $where) {
      id
      title
      description
      pictures {
        id
        filename
        url
      }
      location {
        id
        tags {
          id
          closeToTransit
          purposeBuiltRentals
          students
          parksAndWater
          nightlife
          luxuryRentals
          shortTermRentals
          professionallyManaged
          onlinePayments
          onlineApplications
          furnishedRentals
          petFriendly
        }
        neighbourHood {
          id
          city {
            name
          }
        }
      }
      propertyType
      pets
      utilities {
        id
        electricity
        heating
        airConditioning
        water
        garbageRemoval
        cableTv
        internet
        maintenanceFees
        gas
      }
      amenities {
        id
        onSiteLaundry
        yard
        concierge
        elevator
        dryCleaning
        gym
        swimmingPool
        storage
        guestSuites
        beanfieldWifi
        publicWifi
        bbqPermitted
        visitorsParking
        partyRoom
        bikeStorage
        wheelchairAccess
        rooftopDeck
        hotTub
        sauna
        gameRoom
        theatre
        businessCenter
        communalGarden
        petSpa
        mediaRoom
        basketballCourt
        yogaRoom
        tennisCourt
        rooftopTerrace
        coworkingSpace
        conferenceRoom
        patio
        lounge
      }
      user {
        id
        phone
      }
      buildingConstructionYear
      totalUnitsInBuilding
      storiesInBuilding
      developmentCompany
      tenantRequirements {
        id
        exceptionalCredit
        payStub
        letterOfEmployment
        photoId
        pastReferences
        noSmoking
        extraDocumentation
      }
      units {
        id
        title
        bedrooms
        number
        den
        available
        floorplan
        startDateAvailability
        unitSize
        parking
        bathrooms
        furnished
        monthlyPrice
        deposit
        pictures {
          id
          filename
          url
        }
        unitAmenities {
          id
          ac
          centralHeat
          electricalHeating
          radiatorHeating
          fireplace
          carpet
          ceilingFan
          hardwoodFloor
          walkInCloset
          highCeilings
          bathroomFan
          balcony
        }
        unitAppliances {
          id
          fridge
          stove
          rangehood
          dishwasher
          microwave
          inunitwasher
          inunitdryer
          wineFridge
          dehumidifier
          firePlaceElectric
          firePlaceGas
          jacuzzi
        }
        leaseTerm
      }
    }
  }
`
export class FetchListingInfoComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchListingInfoQuery, FetchListingInfoVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<FetchListingInfoQuery, FetchListingInfoVariables>
        query={FetchListingInfoDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type FetchListingInfoProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FetchListingInfoQuery, FetchListingInfoVariables>
> &
  TChildProps
export function FetchListingInfoHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      FetchListingInfoQuery,
      FetchListingInfoVariables,
      FetchListingInfoProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FetchListingInfoQuery,
    FetchListingInfoVariables,
    FetchListingInfoProps<TChildProps>
  >(FetchListingInfoDocument, operationOptions)
}
export function useFetchListingInfo(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FetchListingInfoVariables>
) {
  return ReactApolloHooks.useQuery<FetchListingInfoQuery, FetchListingInfoVariables>(
    FetchListingInfoDocument,
    baseOptions
  )
}
export const UpdateListingDocument = gql`
  mutation UpdateListing($where: ListingWhereUniqueInput!, $data: ListingUpdateInput!) {
    updateListing(where: $where, data: $data) {
      id
      units {
        id
        title
      }
    }
  }
`
export class UpdateListingComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UpdateListingMutation, UpdateListingVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateListingMutation, UpdateListingVariables>
        mutation={UpdateListingDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type UpdateListingProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateListingMutation, UpdateListingVariables>
> &
  TChildProps
export type UpdateListingMutationFn = ReactApollo.MutationFn<
  UpdateListingMutation,
  UpdateListingVariables
>
export function UpdateListingHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      UpdateListingMutation,
      UpdateListingVariables,
      UpdateListingProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateListingMutation,
    UpdateListingVariables,
    UpdateListingProps<TChildProps>
  >(UpdateListingDocument, operationOptions)
}
export function useUpdateListing(
  baseOptions?: ReactApolloHooks.MutationHookOptions<UpdateListingMutation, UpdateListingVariables>
) {
  return ReactApolloHooks.useMutation<UpdateListingMutation, UpdateListingVariables>(
    UpdateListingDocument,
    baseOptions
  )
}
export const FetchTrendingListingsDocument = gql`
  query fetchTrendingListings($where: String, $orderBy: String, $first: Int, $skip: Int) {
    trendingListings(orderBy: $orderBy, where: $where, first: $first, skip: $skip) {
      id
      title
      description
      units {
        id
        monthlyPrice
      }
      bookings {
        id
        createdAt
      }
      location {
        id
        address
        neighbourHood {
          id
          name
          city{
            id
            name
          }
        }
      }
      pictures {
        id
        url
      }
    }
  }
`
export const FetchRecommandedListingsDocument = gql`
  query fetchRecommandedListings($where: ListingWhereInput) {
    recommendListings(where: $where) {
      id
      title
      units {
        id
        monthlyPrice
      }

      pictures {
        id
        url
      }
    }
  }
`

export class FetchTrendingListingsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchTrendingListingsQuery, FetchTrendingListingsVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<FetchTrendingListingsQuery, FetchTrendingListingsVariables>
        query={FetchTrendingListingsDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}

export class FetchRecommandedListingsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchTrendingListingsQuery, FetchTrendingListingsVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<FetchTrendingListingsQuery, FetchTrendingListingsVariables>
        query={FetchRecommandedListingsDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}

export type FetchTrendingListingsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FetchTrendingListingsQuery, FetchTrendingListingsVariables>
> &
  TChildProps
export function FetchTrendingListingsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      FetchTrendingListingsQuery,
      FetchTrendingListingsVariables,
      FetchTrendingListingsProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FetchTrendingListingsQuery,
    FetchTrendingListingsVariables,
    FetchTrendingListingsProps<TChildProps>
  >(FetchTrendingListingsDocument, operationOptions)
}
export function useFetchTrendingListings(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FetchTrendingListingsVariables>
) {
  return ReactApolloHooks.useQuery<FetchTrendingListingsQuery, FetchTrendingListingsVariables>(
    FetchTrendingListingsDocument,
    baseOptions
  )
}
export const CreateLeaseDocument = gql`
  mutation createLease(
    $data: LeaseCreateInput!
    $redirectUrl: String!
    $landlordLegalName: String!
  ) {
    createLease(data: $data, redirectUrl: $redirectUrl, landlordLegalName: $landlordLegalName) {
      id
      docusignEnvelopeUri
      docusignEnvelopeId
      status
      subject
      message
    }
  }
`
export class CreateLeaseComponent extends React.Component<
  Partial<ReactApollo.MutationProps<CreateLeaseMutation, CreateLeaseVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateLeaseMutation, CreateLeaseVariables>
        mutation={CreateLeaseDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type CreateLeaseProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateLeaseMutation, CreateLeaseVariables>
> &
  TChildProps
export type CreateLeaseMutationFn = ReactApollo.MutationFn<
  CreateLeaseMutation,
  CreateLeaseVariables
>
export function CreateLeaseHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      CreateLeaseMutation,
      CreateLeaseVariables,
      CreateLeaseProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateLeaseMutation,
    CreateLeaseVariables,
    CreateLeaseProps<TChildProps>
  >(CreateLeaseDocument, operationOptions)
}
export function useCreateLease(
  baseOptions?: ReactApolloHooks.MutationHookOptions<CreateLeaseMutation, CreateLeaseVariables>
) {
  return ReactApolloHooks.useMutation<CreateLeaseMutation, CreateLeaseVariables>(
    CreateLeaseDocument,
    baseOptions
  )
}
export const RoomsPerUserDocument = gql`
  query roomsPerUser($where: RoomWhereInput!) {
    roomsPerUser(where: $where) {
      id
      booking {
        id
        listing {
          id
          title
          units{
            id
            monthlyPrice
            deposit
          }
      }
    }
      message {
        text
        isRead
        createdAt
        user {
          id
        }
      }
      participants {
        id
        name
        participant {
          id
          firstName
          lastName
          userType
          avatarUrl
        }
      }
    }
    roomsConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const ListingPerBookingDocument = gql`
  query listingPerBookings($where: RoomWhereInput!) {
    listingPerBookings(where: $where) {
      id
      booking {
        id
        listing {
          id
          title
        }
      }
      message {
        text
        isRead
        createdAt
        user {
          id
        }
      }
      participants {
        id
        name
        participant {
          id
          firstName
          lastName
          userType
          avatarUrl
        }
      }
    }
    roomsConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const UnReadMessageDocument = gql`
  query unreadMessages($where: unreadMessages!) {
    unreadMessages(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export class RoomsPerUserComponent extends React.Component<
  Partial<ReactApollo.QueryProps<RoomsPerUserQuery, RoomsPerUserVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<RoomsPerUserQuery, RoomsPerUserVariables>
        query={RoomsPerUserDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}

export class ListingPerBookingComponent extends React.Component<
  Partial<ReactApollo.QueryProps<ListingPerBookingQuery, RoomsPerUserVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<ListingPerBookingQuery, RoomsPerUserVariables>
        query={ListingPerBookingDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}

export class UnReadMessages extends React.Component<
  Partial<ReactApollo.QueryProps<UnReadMessagesQuery, UnreadMessageVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<UnReadMessagesQuery, UnreadMessageVariables>
        query={UnReadMessageDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}

export type RoomsPerUserProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<RoomsPerUserQuery, RoomsPerUserVariables>
> &
  TChildProps
export function RoomsPerUserHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      RoomsPerUserQuery,
      RoomsPerUserVariables,
      RoomsPerUserProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RoomsPerUserQuery,
    RoomsPerUserVariables,
    RoomsPerUserProps<TChildProps>
  >(RoomsPerUserDocument, operationOptions)
}
export function useRoomsPerUser(
  baseOptions?: ReactApolloHooks.QueryHookOptions<RoomsPerUserVariables>
) {
  return ReactApolloHooks.useQuery<RoomsPerUserQuery, RoomsPerUserVariables>(
    RoomsPerUserDocument,
    baseOptions
  )
}
export const RoomsOfUserDocument = gql`
  subscription roomsOfUser($where: RoomSubscriptionWhereInput!) {
    rooms(where: $where) {
      mutation
      node {
        id
        booking {
          id
          Listing {
            id
            title
          }
        }
        messages {
          text
          isRead
          createdAt
          sender {
            id
          }
        }
        participants {
          id
          firstName
          lastName
          userType
          avatarUrl
        }
      }
    }
  }
`
export class RoomsOfUserComponent extends React.Component<
  Partial<ReactApollo.SubscriptionProps<RoomsOfUserSubscription, RoomsOfUserVariables>>
> {
  render() {
    return (
      <ReactApollo.Subscription<RoomsOfUserSubscription, RoomsOfUserVariables>
        subscription={RoomsOfUserDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type RoomsOfUserProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<RoomsOfUserSubscription, RoomsOfUserVariables>
> &
  TChildProps
export function RoomsOfUserHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      RoomsOfUserSubscription,
      RoomsOfUserVariables,
      RoomsOfUserProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RoomsOfUserSubscription,
    RoomsOfUserVariables,
    RoomsOfUserProps<TChildProps>
  >(RoomsOfUserDocument, operationOptions)
}
export function useRoomsOfUser(
  baseOptions?: ReactApolloHooks.SubscriptionHookOptions<
    RoomsOfUserSubscription,
    RoomsOfUserVariables
  >
) {
  return ReactApolloHooks.useSubscription<RoomsOfUserSubscription, RoomsOfUserVariables>(
    RoomsOfUserDocument,
    baseOptions
  )
}
export const FetchLeaseViewDocument = gql`
  query fetchLeaseView($leaseId: String!, $redirectUrl: String!) {
    leaseView(leaseId: $leaseId, redirectUrl: $redirectUrl) {
      id
      docusignEnvelopeUri
    }
  }
`
export class FetchLeaseViewComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchLeaseViewQuery, FetchLeaseViewVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<FetchLeaseViewQuery, FetchLeaseViewVariables>
        query={FetchLeaseViewDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type FetchLeaseViewProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FetchLeaseViewQuery, FetchLeaseViewVariables>
> &
  TChildProps
export function FetchLeaseViewHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      FetchLeaseViewQuery,
      FetchLeaseViewVariables,
      FetchLeaseViewProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FetchLeaseViewQuery,
    FetchLeaseViewVariables,
    FetchLeaseViewProps<TChildProps>
  >(FetchLeaseViewDocument, operationOptions)
}
export function useFetchLeaseView(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FetchLeaseViewVariables>
) {
  return ReactApolloHooks.useQuery<FetchLeaseViewQuery, FetchLeaseViewVariables>(
    FetchLeaseViewDocument,
    baseOptions
  )
}
export const FetchLeasesDocument = gql`
  query fetchLeases(
    $where: LeaseWhereInput
    $orderBy: LeaseOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    leases(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      createdAt
      updatedAt
      status
      subject
      message
      landlordSigners {
        id
        firstName
        lastName
      }
      tenantSigners {
        id
        firstName
        lastName
      }
      room {
        id
        booking {
          id
          listing {
            id
            title
          }
        }
      }
    }
  }
`
export class FetchLeasesComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchLeasesQuery, FetchLeasesVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<FetchLeasesQuery, FetchLeasesVariables>
        query={FetchLeasesDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type FetchLeasesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FetchLeasesQuery, FetchLeasesVariables>
> &
  TChildProps
export function FetchLeasesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      FetchLeasesQuery,
      FetchLeasesVariables,
      FetchLeasesProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FetchLeasesQuery,
    FetchLeasesVariables,
    FetchLeasesProps<TChildProps>
  >(FetchLeasesDocument, operationOptions)
}
export function useFetchLeases(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FetchLeasesVariables>
) {
  return ReactApolloHooks.useQuery<FetchLeasesQuery, FetchLeasesVariables>(
    FetchLeasesDocument,
    baseOptions
  )
}
export const FetchListingsDocument = gql`
  query fetchListings(
    $where: ListingWhereInput
    $orderBy: UnitOrderByInput
    $first: Int
    $skip: Int
  ) {
    listings(where: $where, first: $first, skip: $skip) {
      id
      title
      createdAt
      premiumStart
      premiumEnd
      pictures {
        id
        url
      }
      units(orderBy: $orderBy) {
        monthlyPrice
      }
      location {
        id
        lat
        lng
      }
    }
    listingsConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export class FetchListingsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchListingsQuery, FetchListingsVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<FetchListingsQuery, FetchListingsVariables>
        query={FetchListingsDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type FetchListingsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FetchListingsQuery, FetchListingsVariables>
> &
  TChildProps
export function FetchListingsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      FetchListingsQuery,
      FetchListingsVariables,
      FetchListingsProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FetchListingsQuery,
    FetchListingsVariables,
    FetchListingsProps<TChildProps>
  >(FetchListingsDocument, operationOptions)
}
export function useFetchListings(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FetchListingsVariables>
) {
  return ReactApolloHooks.useQuery<FetchListingsQuery, FetchListingsVariables>(
    FetchListingsDocument,
    baseOptions
  )
}
export const FetchSearchListingsDocument = gql`
  query fetchSearchListings(
    $where: UnitWhereInput
    $orderBy: String
    $first: Int
    $skip: Int
    $userId: String
  ) {
    searchListings(where: $where, first: $first, skip: $skip, orderBy: $orderBy, userId: $userId) {
      id
      title
      createdAt
      premiumStart
      premiumEnd
      pictures {
        id
        url
      }
      units {
        monthlyPrice
      }
      location {
        id
        lat
        lng
      }
    }
    listingsConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`

function debounce<F extends (...args: any[]) => any>(func: F, delayInMs: number) {
  let timeoutId: ReturnType<typeof setTimeout>

  return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    const context = this
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func.apply(context, args)
    }, delayInMs)
  } as F
}

export class FetchSearchListingsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchSearchListingsQuery, FetchSearchListingsVariables>>,
  any
> {
  constructor(props) {
    super(props)

    this.state = {
      data: undefined,
      loading: true,
      error: undefined
    }
  }

  fetcher = async () => {
    const resp = await client.query({
      query: FetchSearchListingsDocument,
      variables: this.props.variables
    })
    if (resp) {
      this.setState(resp)
    }
  }

  // componentWillReceiveProps(
  //   nextProps: Readonly<
  //     Partial<ReactApollo.QueryProps<FetchSearchListingsQuery, FetchSearchListingsVariables>>
  //   >,
  //   nextContext: any
  // ): void {
  //   this.fetcher()
  // }

  render() {
    // if (!this.props.children) return <></>
    // return this.props.children(this.state as any)

    return (
      <ReactApollo.Query<FetchSearchListingsQuery, FetchSearchListingsVariables>
        query={FetchSearchListingsDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type FetchSearchListingsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FetchSearchListingsQuery, FetchSearchListingsVariables>
> &
  TChildProps
export function FetchSearchListingsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      FetchSearchListingsQuery,
      FetchSearchListingsVariables,
      FetchSearchListingsProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FetchSearchListingsQuery,
    FetchSearchListingsVariables,
    FetchSearchListingsProps<TChildProps>
  >(FetchSearchListingsDocument, operationOptions)
}
export function useFetchSearchListings(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FetchSearchListingsVariables>
) {
  return ReactApolloHooks.useQuery<FetchSearchListingsQuery, FetchSearchListingsVariables>(
    FetchSearchListingsDocument,
    baseOptions
  )
}
export const UpdateUserDocument = gql`
  mutation updateUser($data: Data!) {
    updateUser(data: $data) {
      id
      auth0id
      firstName
      lastName
      email
      userType
      bio
      city
      industry
      budget
      min_budget
      moveIn
      roommate
      avatarUrl
      phone
      occupation
      credit_score
      availabilityDays
      householdIncome
      view_property
      flatmates
      zip_code
      furnished
      notify
      lu_from
      lu_type
      bedrooms
      rental_properties
      rental_properties_permonth
      role
      pre_viewing_city {
        id
        city {
          id
          name
        }
      }
      furnish {
        id
        furnished
        unFurnished
      }
      bedroom {
        id
        one
        two
        three
        fourplus
        room
        studio
      }
      laundaryType {
        id
        inUnit
        inBuilding
        none
      }
      pet {
        id
        dog
        cat
        other
      }
    }
  }
`
export class UpdateUserComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UpdateUserMutation, UpdateUserVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateUserMutation, UpdateUserVariables>
        mutation={UpdateUserDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type UpdateUserProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateUserMutation, UpdateUserVariables>
> &
  TChildProps
export type UpdateUserMutationFn = ReactApollo.MutationFn<UpdateUserMutation, UpdateUserVariables>
export function UpdateUserHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      UpdateUserMutation,
      UpdateUserVariables,
      UpdateUserProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateUserMutation,
    UpdateUserVariables,
    UpdateUserProps<TChildProps>
  >(UpdateUserDocument, operationOptions)
}
export function useUpdateUser(
  baseOptions?: ReactApolloHooks.MutationHookOptions<UpdateUserMutation, UpdateUserVariables>
) {
  return ReactApolloHooks.useMutation<UpdateUserMutation, UpdateUserVariables>(
    UpdateUserDocument,
    baseOptions
  )
}
export const FetchSingleListingDocument = gql`
  query fetchSingleListing($where: ListingWhereUniqueInput!) {
    listing(where: $where) {
      id
      updatedAt
      seen
      title
      moveInBonuses
      description
      isHidden
      activate
      premiumStart
      premiumEnd
      user {
        id
        firstName
        lastName
        avatarUrl
        city
        industry
        bio
        createdAt
        phone
      }
      utilities {
        id
        electricity
        heating
        airConditioning
        water
        garbageRemoval
        cableTv
        internet
        maintenanceFees
        gas
      }
      amenities {
        id
        onSiteLaundry
        yard
        concierge
        elevator
        dryCleaning
        gym
        swimmingPool
        storage
        guestSuites
        beanfieldWifi
        publicWifi
        bbqPermitted
        visitorsParking
        partyRoom
        bikeStorage
        wheelchairAccess
        rooftopDeck
        hotTub
        sauna
        gameRoom
        theatre
        businessCenter
        communalGarden
        petSpa
        mediaRoom
        basketballCourt
        yogaRoom
        tennisCourt
        rooftopTerrace
        coworkingSpace
        conferenceRoom
        patio
        lounge
      }
      pet {
        id
        cat
        dog
        other
      }
      propertyType
      buildingConstructionYear
      totalUnitsInBuilding
      storiesInBuilding
      developmentCompany
      tenantRequirements {
        id
        exceptionalCredit
        payStub
        letterOfEmployment
        photoId
        pastReferences
        noSmoking
        extraDocumentation
      }
      pictures {
        id
        url
      }
      units {
        id
        title
        number
        den
        available
        startDateAvailability
        unitSize
        bedrooms
        monthlyPrice
        bathrooms
        floorplan
        furnished
        deposit
        parkingSpots
        unitAmenities {
          id
          ac
          centralHeat
          electricalHeating
          radiatorHeating
          fireplace
          carpet
          ceilingFan
          hardwoodFloor
          walkInCloset
          highCeilings
          bathroomFan
          balcony
        }
        unitAppliances {
          id
          fridge
          stove
          rangehood
          dishwasher
          microwave
          inunitwasher
          inunitdryer
          wineFridge
          dehumidifier
          firePlaceElectric
          firePlaceGas
          jacuzzi
        }
        leaseTerm
        parking
        pictures {
          id
          url
        }
      }
      location {
        id
        lat
        lng
        address
        tags {
          id
          closeToTransit
          purposeBuiltRentals
          students
          parksAndWater
          nightlife
          luxuryRentals
          shortTermRentals
          professionallyManaged
          onlinePayments
          onlineApplications
          furnishedRentals
          petFriendly
        }
        neighbourHood {
          id
          name
          city {
            id
            name
          }
          homePreview {
            id
            url
          }
        }
      }
    }
  }
`
export class FetchSingleListingComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchSingleListingQuery, FetchSingleListingVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<FetchSingleListingQuery, FetchSingleListingVariables>
        query={FetchSingleListingDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type FetchSingleListingProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FetchSingleListingQuery, FetchSingleListingVariables>
> &
  TChildProps
export function FetchSingleListingHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      FetchSingleListingQuery,
      FetchSingleListingVariables,
      FetchSingleListingProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FetchSingleListingQuery,
    FetchSingleListingVariables,
    FetchSingleListingProps<TChildProps>
  >(FetchSingleListingDocument, operationOptions)
}
export function useFetchSingleListing(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FetchSingleListingVariables>
) {
  return ReactApolloHooks.useQuery<FetchSingleListingQuery, FetchSingleListingVariables>(
    FetchSingleListingDocument,
    baseOptions
  )
}
export const FetchPublicTenantDocument = gql`
  query fetchPublicTenant($idToken: String!) {
    user(id: $idToken) {
      id
      avatarUrl
      firstName
      lastName
      city
      createdAt
      moveIn
      industry
      budget
      roommate
      bio
    }
  }
`
export class FetchPublicTenantComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchPublicTenantQuery, FetchPublicTenantVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<FetchPublicTenantQuery, FetchPublicTenantVariables>
        query={FetchPublicTenantDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type FetchPublicTenantProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FetchPublicTenantQuery, FetchPublicTenantVariables>
> &
  TChildProps
export function FetchPublicTenantHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      FetchPublicTenantQuery,
      FetchPublicTenantVariables,
      FetchPublicTenantProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FetchPublicTenantQuery,
    FetchPublicTenantVariables,
    FetchPublicTenantProps<TChildProps>
  >(FetchPublicTenantDocument, operationOptions)
}
export function useFetchPublicTenant(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FetchPublicTenantVariables>
) {
  return ReactApolloHooks.useQuery<FetchPublicTenantQuery, FetchPublicTenantVariables>(
    FetchPublicTenantDocument,
    baseOptions
  )
}
export const AuthenticateDocument = gql`
  mutation authenticate($idToken: String!) {
    authenticate(idToken: $idToken) {
      id
      auth0id
      firstName
      lastName
      email
      phone
      userType
      avatarUrl
      city
      industry
      bio
      locale
      gender
      budget
      moveIn
      roommate
    }
  }
`
export class AuthenticateComponent extends React.Component<
  Partial<ReactApollo.MutationProps<AuthenticateMutation, AuthenticateVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<AuthenticateMutation, AuthenticateVariables>
        mutation={AuthenticateDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type AuthenticateProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<AuthenticateMutation, AuthenticateVariables>
> &
  TChildProps
export type AuthenticateMutationFn = ReactApollo.MutationFn<
  AuthenticateMutation,
  AuthenticateVariables
>
export function AuthenticateHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      AuthenticateMutation,
      AuthenticateVariables,
      AuthenticateProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AuthenticateMutation,
    AuthenticateVariables,
    AuthenticateProps<TChildProps>
  >(AuthenticateDocument, operationOptions)
}
export function useAuthenticate(
  baseOptions?: ReactApolloHooks.MutationHookOptions<AuthenticateMutation, AuthenticateVariables>
) {
  return ReactApolloHooks.useMutation<AuthenticateMutation, AuthenticateVariables>(
    AuthenticateDocument,
    baseOptions
  )
}

export const FetchCurrentUserDocument = gql`
  query fetchCurrentUser {
    viewer {
      me {
        id
        auth0id
        firstName
        lastName
        legalName
        email
        userType
        avatarUrl
        city
        industry
        bio
        locale
        gender
        budget
        moveIn
        roommate
      }
    }
  }
`
export class FetchCurrentUserComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchCurrentUserQuery, FetchCurrentUserVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<FetchCurrentUserQuery, FetchCurrentUserVariables>
        query={FetchCurrentUserDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type FetchCurrentUserProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FetchCurrentUserQuery, FetchCurrentUserVariables>
> &
  TChildProps
export function FetchCurrentUserHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      FetchCurrentUserQuery,
      FetchCurrentUserVariables,
      FetchCurrentUserProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FetchCurrentUserQuery,
    FetchCurrentUserVariables,
    FetchCurrentUserProps<TChildProps>
  >(FetchCurrentUserDocument, operationOptions)
}
export function useFetchCurrentUser(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FetchCurrentUserVariables>
) {
  return ReactApolloHooks.useQuery<FetchCurrentUserQuery, FetchCurrentUserVariables>(
    FetchCurrentUserDocument,
    baseOptions
  )
}
export const DeleteListingDocument = gql`
  mutation deleteListing($where: ListingWhereUniqueInput!) {
    deleteListing(where: $where) {
      id
    }
  }
`
export class DeleteListingComponent extends React.Component<
  Partial<ReactApollo.MutationProps<DeleteListingMutation, DeleteListingVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteListingMutation, DeleteListingVariables>
        mutation={DeleteListingDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type DeleteListingProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteListingMutation, DeleteListingVariables>
> &
  TChildProps
export type DeleteListingMutationFn = ReactApollo.MutationFn<
  DeleteListingMutation,
  DeleteListingVariables
>
export function DeleteListingHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      DeleteListingMutation,
      DeleteListingVariables,
      DeleteListingProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteListingMutation,
    DeleteListingVariables,
    DeleteListingProps<TChildProps>
  >(DeleteListingDocument, operationOptions)
}
export function useDeleteListing(
  baseOptions?: ReactApolloHooks.MutationHookOptions<DeleteListingMutation, DeleteListingVariables>
) {
  return ReactApolloHooks.useMutation<DeleteListingMutation, DeleteListingVariables>(
    DeleteListingDocument,
    baseOptions
  )
}
export const FetchUserProfileDocument = gql`
  query FetchUserProfile {
    currentUser {
      id
      auth0id
      firstName
      lastName
      email
      userType
      bio
      city
      industry
      budget
      min_budget
      moveIn
      roommate
      avatarUrl
      phone
      occupation
      credit_score
      availabilityDays
      view_property
      flatmates
      zip_code
      furnished
      notify
      lu_from
      lu_type
      bedrooms
      householdIncome
      rental_properties
      rental_properties_permonth
      role
      pre_viewing_city {
        id
        city {
          id
          name
          province
        }
      }
      furnish {
        id
        furnished
        unFurnished
      }
      bedroom {
        id
        one
        two
        three
        fourplus
        room
        studio
      }
      laundaryType {
        id
        inUnit
        inBuilding
        none
      }
      pet {
        id
        dog
        cat
        other
      }
    }
  }
`
export class FetchUserProfileComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchUserProfileQuery, FetchUserProfileVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<FetchUserProfileQuery, FetchUserProfileVariables>
        query={FetchUserProfileDocument}
        {...((this as any)["props"] as any)}
      />
    )
  }
}
export type FetchUserProfileProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FetchUserProfileQuery, FetchUserProfileVariables>
> &
  TChildProps
export function FetchUserProfileHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
      TProps,
      FetchUserProfileQuery,
      FetchUserProfileVariables,
      FetchUserProfileProps<TChildProps>
    >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FetchUserProfileQuery,
    FetchUserProfileVariables,
    FetchUserProfileProps<TChildProps>
  >(FetchUserProfileDocument, operationOptions)
}
export function useFetchUserProfile(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FetchUserProfileVariables>
) {
  return ReactApolloHooks.useQuery<FetchUserProfileQuery, FetchUserProfileVariables>(
    FetchUserProfileDocument,
    baseOptions
  )
}
