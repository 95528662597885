import gql from "graphql-tag"

export const CREATE_RENTAL = gql`
  mutation CreateRental(
    $applicationDate: String!
    $commencementDate: String!
    $rentalPropertyAddress: String!
    $applyingAs: String!
    $propertySeen_viewing: Boolean
    $propertySeen_scheduled: Boolean
    $propertySeen_providedPictures: Boolean
    $propertySeen_other: Boolean
    $otherPropertySeen: String
    $guaranteeFullName: String
    $yourOffer: String!
    $leaseTerm: String!
    $firstName: String
    $lastName: String
    $dateOfBirth: String
    $phone: String
    $userEmail: String
    $address_street: String
    $address_city: String
    $address_province: String
    $address_postal: String
    $address_country: String
    $livingDate: String
    $renting: Boolean
    $currentLandlordName: String
    $currentLandlordPhone: String
    $currentLandlordEmail: String
    $reachingTime_weekdayMorning: Boolean
    $reachingTime_weekdayAfternoon: Boolean
    $reachingTime_weekendMorning: Boolean
    $reachingTime_weekendAfternoon: Boolean
    $reachingTime_notSure: Boolean
    $leaseAgreementFile: String
    $reasonLeaving: String
    $previousAddress_street: String
    $previousAddress_city: String
    $previousAddress_province: String
    $previousAddress_postal: String
    $previousAddress_country: String
    $previousLivingDate: String
    $previousRenting: Boolean
    $previousLandlordName: String
    $previousLandlordPhone: String
    $previousLandlordEmail: String
    $smoker: Boolean
    $grossIncome: String
    $selfEmployed: Boolean
    $website: String
    $expenseFigures: String
    $revenueSalesFigures: String
    $selfEmployedDocs_bankStatement: Boolean
    $selfEmployedDocs_taxReturns: Boolean
    $selfEmployedDocs_accountantContact: Boolean
    $selfEmployedDocs_invoices: Boolean
    $bankStatementFiles: String
    $taxReturnsFiles: String
    $invoicesFiles: String
    $accountant_phone: String
    $accountant_email: String
    $occupation: String
    $previousEmployer: String
    $previousEmployerAddress_street: String
    $previousEmployerAddress_city: String
    $previousEmployerAddress_province: String
    $previousEmployerAddress_postal: String
    $previousEmployerAddress_country: String
    $previousPositionTitle: String
    $currentEmployer: String
    $currentEmployerAddress_street: String
    $currentEmployerAddress_city: String
    $currentEmployerAddress_province: String
    $currentEmployerAddress_postal: String
    $currentEmployerAddress_country: String
    $employmentTerm: String
    $currentEmployer_phone: String
    $linkedIn: String
    $financialObligation_none: Boolean
    $financialObligation_house: Boolean
    $financialObligation_vehicles: Boolean
    $financialObligation_medical: Boolean
    $financialObligation_education: Boolean
    $monthlyPayment: String
    $entities: String
    $personalReference_name: String
    $personalReference_phone: String
    $personalReference_email: String
    $employmentReference_name: String
    $employmentStatus_temporary: Boolean
    $employmentStatus_permanent: Boolean
    $employmentStatus_partTime: Boolean
    $employmentStatus_fullTime: Boolean
    $employmentStatus_contract: Boolean
    $currentEmploymentEndDate: String
    $referenceStatus: String
    $firstEmployer: Boolean
    $employmentReference_phone: String
    $employmentReference_email: String
    $referenceRelationship_friend: Boolean
    $referenceRelationship_neighbour: Boolean
    $referenceRelationship_colleague: Boolean
    $referenceRelationship_relative: Boolean
    $referenceReachingTime_weekdayMorning: Boolean
    $referenceReachingTime_weekdayAfternoon: Boolean
    $referenceReachingTime_weekendMorning: Boolean
    $referenceReachingTime_weekendAfternoon: Boolean
    $referenceReachingTime_notSure: Boolean
    $pets: Boolean
    $breed: String
    $weight: String
    $petDescription: String
    $petType_dog: Boolean
    $petType_cat: Boolean
    $petType_fish: Boolean
    $petType_bird: Boolean
    $petType_other: Boolean
    $otherPetType: String
    $petFiles: String
    $vehicle: Boolean
    $makeOne: String
    $modelOne: String
    $anotherVehicle: Boolean
    $makeTwo: String
    $modelTwo: String
    $co_occupants: String
    $occupant_one_name: String
    $occupant_two_name: String
    $occupant_three_name: String
    $occupant_four_name: String
    $occupant_five_name: String
    $creditScoreFile: String
    $sin: String
    $proofOfIncomeFile: String
    $govIdsFile: String
    $supplementaryDocFile: String
    $intro: String
    $signature: String
    $createdAt: String
  ) {
    CreateRental(
      applicationDate: $applicationDate
      commencementDate: $commencementDate
      rentalPropertyAddress: $rentalPropertyAddress
      propertySeen_viewing: $propertySeen_viewing
      propertySeen_scheduled: $propertySeen_scheduled
      propertySeen_providedPictures: $propertySeen_providedPictures
      propertySeen_other: $propertySeen_other
      applyingAs: $applyingAs
      otherPropertySeen: $otherPropertySeen
      guaranteeFullName: $guaranteeFullName
      yourOffer: $yourOffer
      leaseTerm: $leaseTerm
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      phone: $phone
      userEmail: $userEmail
      address_street: $address_street
      address_city: $address_city
      address_province: $address_province
      address_postal: $address_postal
      address_country: $address_country
      livingDate: $livingDate
      renting: $renting
      currentLandlordName: $currentLandlordName
      currentLandlordPhone: $currentLandlordPhone
      currentLandlordEmail: $currentLandlordEmail
      reachingTime_weekdayMorning: $reachingTime_weekdayMorning
      reachingTime_weekdayAfternoon: $reachingTime_weekdayAfternoon
      reachingTime_weekendMorning: $reachingTime_weekendMorning
      reachingTime_weekendAfternoon: $reachingTime_weekendAfternoon
      reachingTime_notSure: $reachingTime_notSure
      leaseAgreementFile: $leaseAgreementFile
      reasonLeaving: $reasonLeaving
      previousAddress_street: $previousAddress_street
      previousAddress_city: $previousAddress_city
      previousAddress_province: $previousAddress_province
      previousAddress_postal: $previousAddress_postal
      previousAddress_country: $previousAddress_country
      previousLivingDate: $previousLivingDate
      previousRenting: $previousRenting
      previousLandlordName: $previousLandlordName
      previousLandlordPhone: $previousLandlordPhone
      previousLandlordEmail: $previousLandlordEmail
      smoker: $smoker
      grossIncome: $grossIncome
      selfEmployed: $selfEmployed
      website: $website
      expenseFigures: $expenseFigures
      revenueSalesFigures: $revenueSalesFigures
      selfEmployedDocs_bankStatement: $selfEmployedDocs_bankStatement
      selfEmployedDocs_taxReturns: $selfEmployedDocs_taxReturns
      selfEmployedDocs_accountantContact: $selfEmployedDocs_accountantContact
      selfEmployedDocs_invoices: $selfEmployedDocs_invoices
      bankStatementFiles: $bankStatementFiles
      taxReturnsFiles: $taxReturnsFiles
      invoicesFiles: $invoicesFiles
      accountant_phone: $accountant_phone
      accountant_email: $accountant_email
      occupation: $occupation
      previousEmployer: $previousEmployer
      previousEmployerAddress_street: $previousEmployerAddress_street
      previousEmployerAddress_city: $previousEmployerAddress_city
      previousEmployerAddress_province: $previousEmployerAddress_province
      previousEmployerAddress_postal: $previousEmployerAddress_postal
      previousEmployerAddress_country: $previousEmployerAddress_country
      previousPositionTitle: $previousPositionTitle
      currentEmployer: $currentEmployer
      currentEmployerAddress_street: $currentEmployerAddress_street
      currentEmployerAddress_city: $currentEmployerAddress_city
      currentEmployerAddress_province: $currentEmployerAddress_province
      currentEmployerAddress_postal: $currentEmployerAddress_postal
      currentEmployerAddress_country: $currentEmployerAddress_country
      employmentTerm: $employmentTerm
      currentEmployer_phone: $currentEmployer_phone
      linkedIn: $linkedIn
      financialObligation_none: $financialObligation_none
      financialObligation_house: $financialObligation_house
      financialObligation_vehicles: $financialObligation_vehicles
      financialObligation_medical: $financialObligation_medical
      financialObligation_education: $financialObligation_education
      monthlyPayment: $monthlyPayment
      entities: $entities
      personalReference_name: $personalReference_name
      personalReference_phone: $personalReference_phone
      personalReference_email: $personalReference_email
      employmentReference_name: $employmentReference_name
      employmentStatus_temporary: $employmentStatus_temporary
      employmentStatus_permanent: $employmentStatus_permanent
      employmentStatus_partTime: $employmentStatus_partTime
      employmentStatus_fullTime: $employmentStatus_fullTime
      employmentStatus_contract: $employmentStatus_contract
      currentEmploymentEndDate: $currentEmploymentEndDate
      referenceStatus: $referenceStatus
      firstEmployer: $firstEmployer
      employmentReference_phone: $employmentReference_phone
      employmentReference_email: $employmentReference_email
      referenceRelationship_friend: $referenceRelationship_friend
      referenceRelationship_neighbour: $referenceRelationship_neighbour
      referenceRelationship_colleague: $referenceRelationship_colleague
      referenceRelationship_relative: $referenceRelationship_relative
      referenceReachingTime_weekdayMorning: $referenceReachingTime_weekdayMorning
      referenceReachingTime_weekdayAfternoon: $referenceReachingTime_weekdayAfternoon
      referenceReachingTime_weekendMorning: $referenceReachingTime_weekendMorning
      referenceReachingTime_weekendAfternoon: $referenceReachingTime_weekendAfternoon
      referenceReachingTime_notSure: $referenceReachingTime_notSure
      pets: $pets
      breed: $breed
      weight: $weight
      petDescription: $petDescription
      petType_dog: $petType_dog
      petType_cat: $petType_cat
      petType_fish: $petType_fish
      petType_bird: $petType_bird
      petType_other: $petType_other
      otherPetType: $otherPetType
      petFiles: $petFiles
      vehicle: $vehicle
      makeOne: $makeOne
      modelOne: $modelOne
      anotherVehicle: $anotherVehicle
      makeTwo: $makeTwo
      modelTwo: $modelTwo
      co_occupants: $co_occupants
      occupant_one_name: $occupant_one_name
      occupant_two_name: $occupant_two_name
      occupant_three_name: $occupant_three_name
      occupant_four_name: $occupant_four_name
      occupant_five_name: $occupant_five_name
      creditScoreFile: $creditScoreFile
      sin: $sin
      proofOfIncomeFile: $proofOfIncomeFile
      govIdsFile: $govIdsFile
      supplementaryDocFile: $supplementaryDocFile
      intro: $intro
      signature: $signature
      createdAt: $createdAt
    ) {
      error
      message
    }
  }
`
