import { useState, useCallback } from "react"
import axios from "axios"
import placeFinder from "./nearby-finder"
import moment from "moment"
import { DISABLE } from "./const"

const URL = process.env.REACT_APP_GRAPHQL_URI + "/api/listdescription";
const SCAN_URL = process.env.REACT_APP_GRAPHQL_URI + "/api/scanImage";

const parseBedRoomsNum = str => {
    switch (str) {
        case "ROOM_SHARED_HOME":
            return "Room shared home"
        case "STUDIO":
            return "Studio"
        case "ONE_BEDROOM":
            return "1 bedroom"
        case "TWO_BEDROOMS":
            return "2 bedrooms"
        case "THREE_BEDROOMS":
            return "3 bedrooms"
        case "FOUR_OR_MORE_BEDROOMS":
            return "4 bedrooms"
        case "FIVE_BEDROOMS":
            return "5 bedrooms"
        case "SIX_BEDROOMS":
            return "6 bedrooms"
        case "SEVEN_BEDROOMS":
            return "7 bedrooms"
        case "EIGHT_BEDROOMS":
            return "8 bedrooms"
        case "NINE_BEDROOMS":
            return "9 bedrooms"
        case "TEN_BEDROOMS":
            return "10 bedrooms"

        default:
            return "1 bedroom"
    }
}

var imageResults: any = {},
    rawData: any = { amenities: [] }
var previousPayload = {}
var pendingApiCall = false

const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const AiLdg = ({ children }) => {
    const [aiDesc, setAiDesc] = useState("")
    const [loading, setLoading] = useState(false)
    const [nearby, setNearby] = useState("")
    const [editMode, setEditMode] = useState(false)

    const apiCall = useCallback(
        (rawData, imageResults, regenrate = false) => {
            pendingApiCall = true
            let features: any = []
            setLoading(true)
            for (let key in imageResults) {
                imageResults[key].forEach((obj: any) => {
                    if (!Object.keys(obj).length) return
                    let data = [...obj.kitchen, ...obj.general]

                    if (rawData.furnished == "Yes") {
                        data = [...data, ...obj.furniture]
                    }

                    features = features.concat(data)
                })
            }
            features = [...new Set(features)].join(",")

            let payload = {
                ...rawData,
                features,
                amenities: rawData.amenities + (rawData.unitamenities ? "," + rawData.unitamenities : ""),
                landmarks: nearby
            }

            if (JSON.stringify(previousPayload) == JSON.stringify(payload)) {
                if (!regenrate) {
                    return setLoading(false)
                }
            }
            previousPayload = payload
            console.log("Calling api...")
            axios.post(URL, payload).then(res => {
                if (res.status) {
                    if (res?.data?.data?.output) {
                        setAiDesc(res?.data?.data?.output)
                    } else {
                        setAiDesc(res?.data?.data?.final_output || "Failed to create Description")
                    }
                }
                imageResults = {}
                rawData = {}
                setLoading(false)
            })
                .catch((err) => {
                    setAiDesc("Failed to create Description")
                    setLoading(false)
            })

        },
        [nearby]
    )

    const setLdgData = useCallback((data) => {
        // console.log("data: :", data)
        // setAiDesc(data?.step1?.des || "")
    }, [])

    const collectData = useCallback(
        async (data, key) => {
            const pictures = data.picture || data.pictures || []
            if (pictures.length) {
                axios.post(SCAN_URL, {
                    list: pictures.map(({ url }) => ({ url }))
                }).then(res => {
                    if (Array.isArray(res.data)) {
                        const imageResult = res.data
                        imageResults = {
                            ...imageResults,
                            [key]: imageResult
                        }
                    }
                    if (key === "ThirdUnit" && !editMode) {
                        apiCall(rawData, imageResults)
                    }
                })

            }
            else if (key === "ThirdUnit") {
                pendingApiCall = true
            }

            if (key === "Basic") {
                rawData = {
                    ...rawData,
                    address: data.address.title,
                    neighbor: data.address?.neighborhood || "",
                    property_type: data?.propertyType?.toLowerCase() || "",
                    num_floors: data?.floors?.toString() || "",
                    year_built: data?.contractions?.toString() || ""
                }

                const { lats, lngs } = data.address.geometry
                const nearbyPlace = await placeFinder(lats, lngs)
                setNearby(nearbyPlace)
            }
            if (key === "Building") {
                rawData = {
                    ...rawData,
                    amenities: data?.amenities?.map(i => i.label)?.toString() || "",
                    utilities: data?.utilities?.map(i => i.label)?.toString() || "",
                    tags: data?.tags?.map(i => i.label)?.toString() || "",
                    tenant_reqs: data?.tenantRequirements?.map(i => i.label)?.toString() || "",
                    pets_allowed: data?.pets?.map(i => i.label)?.toString() || ""
                }
            }
            if (key === "FirstUnit") {
                const date = data.startDateAvailability
                let finalDate: string = ""

                if (date instanceof Date || typeof date === "string") {
                    const dateFormat = "YYYY MM DD HH:mm:ss"
                    finalDate = moment(date).format(dateFormat)
                }
                rawData = {
                    ...rawData,
                    bedrooms: parseBedRoomsNum(data.bedrooms),
                    bathrooms: data?.bathrooms?.toString() || "",
                    furnished: data.furnished ? "Yes" : "No",
                    monthly_price: "$" + numberWithCommas(data.monthlyPrice.toString()),
                    deposit: "$" + numberWithCommas(data.deposit.toString()),
                    avail_date: finalDate,
                    area: numberWithCommas(data?.unitSize?.toString() || "")
                }
            }
            if (key === "SecondUnit") {
                rawData = {
                    ...rawData,
                    lease_term: data?.leaseTerm?.toString() || "",
                    parking_type: data.parking,
                    num_spots: data?.parkingSpots?.toString() || "",
                    storage: data?.storage || ""
                }
            }
            if (key === "ThirdUnit") {
                rawData = {
                    ...rawData,
                    unitamenities: (data.unitamenities || []).map(i => i.label).toString(),
                    appliances: data?.unitAppliances?.map(i => i.label)?.toString() || ""
                }
                if (pendingApiCall && !editMode)
                    apiCall(rawData, imageResults)

            }
        },
        [apiCall, editMode]
    )

    const updateValue = useCallback((event) => {
        setAiDesc(event.target.value)
    }, [])

    const reGenerate = useCallback(() => {
        apiCall(rawData, imageResults, true)
    }, [apiCall])

    return children({
        ldg: {
            collectData: DISABLE ? () => { } : collectData,
            aiDesc: DISABLE ? "" : aiDesc,
            loading: DISABLE ? false : loading,
            nearby: DISABLE ? "" : nearby,
            editMode,
            updateValue,
            setEditMode: setEditMode,
            setLdgData,
            setAiDesc,
            reGenerate
        }
    })
}

export default AiLdg
