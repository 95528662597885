import React from "react"
import Form from "../../../components/FormComponents"
import { fields } from "./fields"
interface Props {
  actionBtn: any
  onSubmit: (payload: any, resetForm: any) => void
  initialValues: { [x: string]: any }
  client?: any
  onFormChange2?: () => void
}

const BuildingInput = React.memo(function BuildingInput({
  actionBtn,
  client,
  onFormChange2,
  onSubmit,
  initialValues
}: Props) {
  const onFormChange = () => {
    if (onFormChange2 !== undefined) onFormChange2()
  }
  return (
    <Form
      fields={fields}
      initialValues={initialValues}
      btnText={"submit"}
      onSubmit={onSubmit}
      renderCustomSubmit={actionBtn}
      client={client}
      onFormChange={onFormChange}
      giveButtonsFormikActions
    />
  )
})

export default BuildingInput
