import * as React from "react"
import Select from "react-select"

import Colors from "../../theme/colors"
import { ValueType } from "react-select/lib/types"

interface Props {
  options: Array<any>
  placeholder: string
  onChange: (e: any) => void
  name?: string
  maxWidth?: any
  value?: any
  id?: string
  initialValue?: string
  defaultValue?: ValueType<Option>
}

interface Option {
  value: string
  label: string
}

const CustomStyles = (maxWidth: any) => {
  return {
    control: (styles: React.CSSProperties) => ({
      ...styles,
      maxWidth: maxWidth && `${maxWidth}px`,
      width: maxWidth && `${maxWidth}px`,
      height: "55px",
      paddingLeft: "16px",
      paddingRight: "16px",
      borderRadius: "5.5px",
      border: "solid 1.4px rgba(51, 51, 51, 0.2)",
      transition: "250ms linear",
      "&:hover": {
        borderColor: "hsl(0, 0%, 70%)"
      },
      fontSize: "18px",
      backgroundColor: "transparent",
      boxShadow: "none",
      cursor: "grab"
    }),
    option: (base: React.CSSProperties) => ({
      ...base,
      color: Colors.black
    }),
    placeholder: (base: any) => ({
      ...base,
      letterSpacing: "0.1px",
      opacity: "0.3",
      fontSize: "18px",
      fontWeight: "600",
      color: "#333333"
    }),
    valueContainer: (base: React.CSSProperties) => ({
      ...base,
      paddingLeft: 0
    }),
    indicatorSeparator: (base: React.CSSProperties) => ({
      ...base,
      display: "none"
    }),
    singleValue: (base: any) => ({
      ...base,
      color: Colors.black,
      fontSize: "18px",
      fontWeight: 500
    })
  }
}

const MessageCustomStyles = (maxWidth: any) => {
  return {
    control: (styles: React.CSSProperties) => ({
      ...styles,
      maxWidth: maxWidth && `${maxWidth}px`,
      width: maxWidth && `${maxWidth}px`,
      height: "20px",
      padding: "0px 16px !important",
      borderRadius: "5.5px",
      border: "solid 1.4px rgba(51, 51, 51, 0.2)",
      transition: "250ms linear",
      "&:hover": {
        borderColor: "hsl(0, 0%, 70%)"
      },
      fontSize: "18px",
      backgroundColor: "transparent",
      boxShadow: "none",
      cursor: "grab"
    }),
    option: (base: React.CSSProperties) => ({
      ...base,
      color: Colors.black
    }),
    placeholder: (base: any) => ({
      ...base,
      letterSpacing: "0.1px",
      opacity: "0.3",
      fontSize: "18px",
      fontWeight: "600",
      color: "#333333"
    }),
    valueContainer: (base: React.CSSProperties) => ({
      ...base,
      paddingLeft: 0
    }),
    indicatorSeparator: (base: React.CSSProperties) => ({
      ...base,
      display: "none"
    }),
    singleValue: (base: any) => ({
      ...base,
      color: Colors.black,
      fontSize: "18px",
      fontWeight: 500
    })
  }
}

const SelectComponent = ({
  options,
  maxWidth,
  placeholder,
  onChange,
  name,
  value,
  id,
  defaultValue,
  ...rest
}: Props) => (
  <Select
    styles={CustomStyles(maxWidth && maxWidth)}
    options={options}
    placeholder={placeholder}
    onChange={onChange}
    name={name}
    id={id}
    setValue={value}
    defaultValue={defaultValue}
    {...rest}
  />
)

export const MessageSelectComponent = ({
  options,
  maxWidth,
  placeholder,
  onChange,
  name,
  value,
  id,
  defaultValue,
  ...rest
}: Props) => (
  <Select
    styles={MessageCustomStyles(maxWidth && maxWidth)}
    options={options}
    placeholder={placeholder}
    onChange={onChange}
    name={name}
    id={id}
    setValue={value}
    defaultValue={defaultValue}
    {...rest}
  />
)

export default SelectComponent
