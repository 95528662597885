import * as en from "./en.json"
import * as fr from "./fr.json"
import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import backend from "i18next-xhr-backend"
import { initReactI18next } from "react-i18next"

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: { translation: { ...en } },
      fr: { translation: { ...fr } }
    },

    debug: true,
    lng: "en",
    fallbackLng: "en"
  })

export default i18n
