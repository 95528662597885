import { ListingWhereData } from "src/dashboards/screens/internalListing"
import { INTERNAL_LISTING } from "../graphql"
import { SEARCH_MAP_LISTING, SINGLE_LISTING } from "../graphql/internal-listing/query"

export const fetchListingData = async (
  client,
  skip: number,
  first: number
): Promise<ListingWhereData[] | unknown> => {
  try {
    return await client.query({
      query: INTERNAL_LISTING,
      variables: {
        first,
        skip
      }
    })
  } catch (err) {
    console.log("Error", err)
    return err
  }
}

export const fetchSingleListing = async (id: number, isMap: boolean, client) => {
  try {
    return await client.query({
      query: SINGLE_LISTING,
      fetchPolicy: "network-only",
      variables: {
        id,
        isMap
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}

export const fetchSearchMapListing = async (client, address: string) => {
  try {
    return await client.query({
      query: SEARCH_MAP_LISTING,
      variables: {
        address
      }
    })
  } catch (err) {
    console.log("Error", err)
  }
}
