const colorList = [
  "#001f3f",
  "#0074D9",
  "#7FDBFF",
  "#39CCCC",
  "#3D9970",
  "#2ECC40",
  "#01FF70",
  "#FFDC00",
  "#FF851B",
  "#FF4136",
  "#85144b",
  "#F012BE",
  "#B10DC9",
  "#111111",
  "#AAAAAA",
  "#DDDDDD",
  "#FFFFFF"
]

export const size = [
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
  "30px",
  "32px",
  "34px",
  "36px",
  "38px",
  "40px",
  "42px",
  "48px"
]
export const fontFamily = ["times new roman", "roboto", "mirza", "arial"]

export const toolbar = [
  [{ font: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ size: size }],
  ["bold", "italic", "underline", "strike", "blockquote"],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  [{ color: [...colorList] }, { background: [...colorList] }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ direction: "rtl" }], // text direction
  ["link", "image", "video"],
  ["clean"],
  ["blockquote", "code-block"]
]

export const autoDetectUrl = {
  globalRegularExpression: /(https?:\/\/|www\.|([\w-\.]+@[\w-\.]))[\w-\.]+\.[\w-\.]+(\/([\S]+)?)?/i,
  urlRegularExpression: /(https?:\/\/|www\.|([\w-\.]+@[\w-\.]))[\w-\.]+\.[\w-\.]+(\/([\S]+)?)?/i,
  globalMailRegularExpression: /([\w-\.]+@[\w-\.]+\.[\w-\.]+)/i,
  mailRegularExpression: /([\w-\.]+@[\w-\.]+\.[\w-\.]+)/i,
  normalizeRegularExpression: /(https?:\/\/|www\.)[\S]+/i
}
