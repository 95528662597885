export const USER_TYPE = [
  { value: "ADMIN", label: "Admin" },
  { value: "LANDLORD", label: "Landlord" },
  { value: "RENTER", label: "Renter" },
  { value: "UNVERIFIED", label: "Unverified" }
]

export const UTILITIES = [
  { value: "AirConditioning", label: "Air Conditioning" },
  { value: "CableTv", label: "Cable TV" },
  { value: "Electricity", label: "Electricity" },
  { value: "GarbageRemoval", label: "Garbage Removal" },
  { value: "Heating", label: "Heating" },
  { value: "Internet", label: "Internet" },
  { value: "MaintenanceFees", label: "Maintenance Fees" },
  { value: "Gas", label: "Gas" },
  { value: "Water", label: "Water" }
]

export const PREMIUM_TYPE = {
  Free: "FREE",
  Paid: "PAID"
}

export const SUBSCRIPTION = {
  Basic: "BASIC",
  Boost: "BOOST",
  Rental: "RENTAL",
  Property: "PROPERTY",
  Premium: "PREMIUM"
}
export const PREMIUM_VALUE = {
  Premium: "PREMIUM",
  Boost: "BOOST_PRICE"
}

export const PREMIUM_OPTION = [
  { value: "FREE", label: "Free" },
  { value: "PAID", label: "Paid" }
]

export const CREDIT_OPTION = [
  { value: "Exceeds 650", label: "Exceeds 650", disabled: false },
  { value: "Is not available", disabled: false, label: "Is not available" },
  { value: "Other", disabled: false, label: "Other" }
]

export const YOU_ARE = [
  { value: "Landlord/Owner", label: "Landlord/Owner" },
  { value: "Real_Estate_Agent", label: "Real Estate Agent" },
  { value: "Lease_Assignor", label: "Lease Assignor" },
  { value: "Sub-Lessor", label: "Sub-lessor" }
]

export const VIEW_OPTION = [
  { value: "In-person", label: "In Person", disabled: false },
  { value: "Virtually", label: "Virtually", disabled: false }
]

export const TAGS = [
  { value: "CloseToTransit", label: "Close To Transit" },
  { value: "Nightlife", label: "Nightlife" },
  { value: "PurposeBuiltRentals", label: "Purpose Built Rentals" },
  { value: "ParksAndWater", label: "Parklands" },//name change to Parklands
  { value: "Students", label: "Students" },
  { value: "LuxuryRentals", label: "Luxury Rentals" },
  { value: "ShortTermRentals", label: "Short-term Rentals" },
  { value: "ProfessionallyManaged", label: "Professionally Managed" },
  { value: "OnlinePayments", label: "Online Payments" },
  { value: "OnlineApplications", label: "Online Applications" },
  { value: "PetFriendly", label: "Pet-friendly" },
  { value: "FurnishedRentals", label: "Furnished Rentals" },
]

export const LAUNDRY = [
  { value: "inUnit", label: "In-unit Laundry" },
  { value: "inBuilding", label: "In-building Laundry" },
  { value: "none", label: "None" }
]

export const NOTIFY_CRITARIA = [
  { value: true, label: "Yes please!" },
  { value: false, label: "No, thanks" }
]

export const PETS = [
  { value: "cat", label: "Cats" },
  { value: "dog", label: "Dogs" },
  { value: "other", label: "Other" },
  { value: "none", label: "None" }
]

export const AMENITIES = [
  { value: "BbqPermitted", label: "BBQ Permitted" },
  { value: "BeanfieldWifi", label: "Beanfield Metroconnect" },
  { value: "BikeStorage", label: "Bike Storage" },
  { value: "BusinessCenter", label: "Business Center" },
  { value: "CommunalGarden", label: "Communal Garden" },
  { value: "Concierge", label: "Concierge" },
  { value: "DryCleaning", label: "Dry Cleaning" },
  { value: "Elevator", label: "Elevator" },
  { value: "GameRoom", label: "Game Room" },
  { value: "GuestSuites", label: "Guest Suites" },
  { value: "Gym", label: "Fitness Facility" },
  { value: "HotTub", label: "Hot Tub" },
  { value: "OnSiteLaundry", label: "On-Site Laundry" },
  { value: "PartyRoom", label: "Party Room" },
  { value: "PublicWifi", label: "Public Wi-Fi" },
  { value: "RooftopDeck", label: "Rooftop Deck" },
  { value: "Storage", label: "Storage" },
  { value: "Sauna", label: "Sauna" },
  { value: "SwimmingPool", label: "Swimming Pool" },
  { value: "Theatre", label: "Theatre" },
  { value: "VisitorsParking", label: "Visitors Parking" },
  { value: "WheelchairAccess", label: "Wheelchair Accessible" },
  { value: "Yard", label: "Yard" },
  { value: "PetSpa", label: "Pet Spa" },
  { value: "MediaRoom", label: "Media Room" },
  { value: "BasketballCourt", label: "Basketball Court" },
  { value: "YogaRoom", label: "Yoga Room" },
  { value: "TennisCourt", label: "Tennis Court" },
  { value: "RooftopTerrace", label: "Rooftop Terrace" },
  { value: "CoworkingSpace", label: "Coworking Space" },
  { value: "ConferenceRoom", label: "Conference Room" },
  { value: "Patio", label: "Patio" },
  { value: "Lounge", label: "Lounge" }
]

export const UNIT_AMENITIES = [
  { value: "Ac", label: "Air Conditioner" },
  { value: "BathroomFan", label: "Bathroom Fan" },
  { value: "Balcony", label: "Balcony" },
  { value: "CentralHeat", label: "Central Heating" },
  { value: "Carpet", label: "Carpet" },
  { value: "CeilingFan", label: "Ceiling Fan" },
  { value: "ElectricalHeating", label: "Electrical Heating" },
  { value: "Fireplace", label: "Fireplace" },
  { value: "HardwoodFloor", label: "Hardwood Floor" },
  { value: "HighCeilings", label: "High Ceilings" },
  { value: "RadiatorHeating", label: "Radiator Heating" },
  { value: "WalkInCloset", label: "Walk-In Closet" }
]
export const UNIT_APPLIANCES = [
  { value: "Fridge", label: "Fridge" },
  { value: "stove", label: "Stove" },
  { value: "Rangehood", label: "Range Hood" },
  { value: "Dishwasher", label: "Dishwasher" },
  { value: "Microwave", label: "Microwave" },
  { value: "inunitwasher", label: "In-unit Washer" },
  { value: "inunitdryer", label: "In-unit Dryer" },
  { value: "WineFridge", label: "Wine Fridge" },
  { value: "Dehumidifier", label: "Dehumidifier" },
  { value: "firePlaceElectric", label: "Fireplace-Electric" },
  { value: "firePlaceGas", label: "Fireplace-Gas" },
  { value: "Jacuzzi", label: "Jacuzzi" }
]

export const TENANT_REQUIREMENTS = [
  { value: "exceptionalCredit", label: "Credit Check" },
  // { value: "extraDocumentation", label: "Extra Documentation" },
  { value: "letterOfEmployment", label: "Proof of Income" },
  { value: "noSmoking", label: "No Smoking" },
  { value: "pastReferences", label: "Landlord Reference" },
  { value: "photoId", label: "Photo ID" },
  // { value: "payStub", label: "Pay Stub" }
]

export const BEDROOMS = [
  { value: "ROOM_SHARED_HOME", label: "Room/Shared Home" },
  { value: "STUDIO", label: "Bachelor/Studio" },
  { value: "ONE_BEDROOM", label: "1" },
  { value: "TWO_BEDROOMS", label: "2" },
  { value: "THREE_BEDROOMS", label: "3" },
  { value: "FOUR_OR_MORE_BEDROOMS", label: "4" },
  { value: "FIVE_BEDROOMS", label: "5" },
  { value: "SIX_BEDROOMS", label: "6" },
  { value: "SEVEN_BEDROOMS", label: "7" },
  { value: "EIGHT_BEDROOMS", label: "8" },
  { value: "NINE_BEDROOMS", label: "9" },
  { value: "TEN_BEDROOMS", label: "10" }
]
export const BASEMENT = [
  { value: "No", label: "No" },
  { value: "Yes-it's not furnished", label: "Yes, it's not finished" },
  { value: "Yes-it's furnished", label: "Yes, it's finished" },
  { value: "Yes-it's separately tenanted", label: "Yes, it's separately tenanted" },
  { value: "Other", label: "Other" }
]

export const ADVERTISE = [
  { value: "Yes", label: "Yes" },
  { value: "Not yet", label: "Not yet" },
  { value: "Other", label: "Other" },
]

export const ADVERTISE_DURATION = [
  { value: "Less Than 3 days", label: "Less Than 3 days" },
  { value: "Less Than 1 week", label: "Less Than 1 week" },
  { value: "Less Than 2 weeks", label: "Less Than 2 weeks" },
  { value: "Less Than 1 month", label: "Less Than 1 month" },
  { value: "Less Than 2 months", label: "Less Than 2 months" },
  { value: "More Than 2 months", label: "More Than 2 months" },
]



export const CURRENTLY_TENANTED = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
]


export const CUSTOMISE_LEASE = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
]

export const INTERESTED_IN_REALATOR = [
  { value: "Yes", label: "Yes, please list my property on Realtor.ca" },
  { value: "No", label: "No, I’m not interested at this time" },
]

export const LOCKBOX = [
  { value: "Yes", label: "Yes" },
  { value: "No, I don't have a lockbox installed on-site", label: "No, I don't have a lockbox installed on-site" },
  { value: "No, but I have a keypad door lock", label: "No, but I have a keypad door lock" },
]


export const COMMISSION = [
  { value: "1/2 month's rent + HST", label: "1/2 month's rent + HST" },
  { value: "Other", label: "Other" },
]



export const SHOWING_UNIT = [
  { value: "Me", label: "Me" },
  { value: "My agent", label: "My agent" },
  { value: "My tenant", label: "My tenant" },
  { value: "Other", label: "Other" },
]

export const SMOKE_ALARM = [
  { value: "Yes-both working smoke & carbon monoide alarms", label: "Yes-both working smoke & carbon monoide alarms" },
  { value: "Yes-working smoke alarm only", label: "Yes-working smoke alarm only" },
  { value: "No-they need to be installed", label: "No-they need to be installed" },
]
export const STORAGE = [
  { value: "Locker", label: "Locker" },
  { value: "Shed", label: "Shed" },
  { value: "Shared Storage", label: "Shared Storage" },
  { value: "Excluded", label: "Excluded" },
  { value: "Other", label: "Other" }
]
export const MAINTENANCE = [
  { value: "Tenants", label: "Tenants" },
  { value: "Landlords", label: "Landlords" },
  { value: "Other", label: "Other" }
]
export const IDEAL_LEASE_TEARM = [
  { value: "12-month", label: "12-month" },
  { value: "6-month", label: "6-month" },
  { value: "Month-to-month", label: "Month-to-month" },
  { value: "Other", label: "Other" }
]
export const PROPERTY_TYPE = [
  { value: "APARTMENT", label: "Apartment" },
  { value: "BASEMENT", label: "Basement" },
  { value: "CONDO", label: "Condo" },
  { value: "HOUSE_OR_TOWNHOUSE", label: "House" },
  { value: "TOWNHOUSE", label: "Townhouse" },
  { value: "ROOM", label: "Room" },
  { value: "OTHER", label: "Other" }
]

export const DEN = [
  { value: true, label: "Yes" },
  { value: false, label: "None" }
]

export const AVAILABLE = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }
]

export const FURNISHED = [
  { value: "unFurnished", label: "No, unfurnished" },
  { value: "furnished", label: "Yes, furnished" }
]

export const STATUS_OF_PROPERTY = [
  { value: true, label: "True" },
  { value: false, label: "False" }
]

export const PARKING = [
  { value: "ASSIGNED", label: "Assigned" },
  { value: "SHARED", label: "Shared" },
  { value: "GARAGE", label: "Garage" },
  { value: "OTHER", label: "Other" },
  { value: "NONE", label: "None" }
]

export const ARTICLE_TYPE = [
  { value: "TRENDING", label: "Trending" },
  { value: "ESTATE_SCOOP", label: "Estate Scoop" }
]

export const STATUS = [
  { value: true, label: "True" },
  { value: false, label: "False" }
]
