import React from "react";
import styled from "styled-components";

const Main = styled.div`
  margin: 20px;
`;
const FinalStep = styled.h1`
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoxContainer = styled.div`
  display: block;
  justify-content: left;
  align-items: center;
  flex-flow: wrap;
  box-shadow: 0 0 3px #515151;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  border: 1px solid grey;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const BoxHeading = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 27px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
const BoxContent = styled.div`
 
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

const BoxContent1 = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: left;
  font-size: 15px;
  @media (max-width: 768px) {
    display:block;
    font-size: 13px;
  }
`;
function FinalSteps() {
  return (
    <Main>
      <FinalStep>Final Step!</FinalStep>
      <BoxContainer>
        <BoxHeading>
          <span>
            You will be required to pay a one-time $75.00 + taxes in deposit upfront for the onboarding of your property by credit card via <a href="https://stripe.com/en-ca">Stripe</a>.</span>
        </BoxHeading>
        <BoxContent dangerouslySetInnerHTML={{
          __html: `  <ul>
          <li> 
            This non-refundable deposit will be used to cover our research, consultation and marketing costs associated with the onboarding of your property.
          </li>
          <li>
            This prepaid deposit will be deducted from your final service fee after we secure you a tenant that you're happy with. 
           </li>
          </ul>`}}>

        </BoxContent>
        <BoxContent1 dangerouslySetInnerHTML={{ __html: `For more details, please review our&nbsp<a href ="https://www.tolobi.com/services " style="color:#00a857;">Services</a>&nbsppage.` }}>
        </BoxContent1>
      </BoxContainer>
      <BoxContent style={{ marginTop: "30px" }} dangerouslySetInnerHTML={{
        __html: `By submitting this Listing Form, you're agreeing to be bound by our&nbsp<a href ="https://www.tolobi.com/terms " style="color:#00a857;">Terms of Service</a>&nbspand&nbsp<a href ="https://www.tolobi.com/privacy " style="color:#00a857;">Privacy Statement.</a>`
      }}>
      </BoxContent>
      <BoxContent style={{ marginTop: "20px", marginBottom: "20px", fontStyle: "italic" }} dangerouslySetInnerHTML={{
        __html: `A representative from the Tolobi Rental Team will reach out to you in the next two business days to discuss your property in detail. Thank you and have a nice day!`
      }}>
      </BoxContent>
    </Main >
  );
}

export default FinalSteps;
